import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import successfulIcon from '../assets/successful.svg';
import failedIcon from '../assets/not-supported.svg';
const SuccessModal = ({ show, onSubmit, successText, size, errorIcon }) => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      onSubmit();
    }, 3000);
  }, []);
  return (
    <Modal show={show} size={size} className={windowDimensions.width > 480 ? null : "p-4"} aria-labelledby="add-fund-modal" onHide={onSubmit} centered backdrop="static" keyboard={false}>
      <Modal.Body className="text-center">
        <div className="img-container mb-4">
          <img src={errorIcon ? failedIcon : successfulIcon} alt="successful icon" className={windowDimensions.width > 480 ? "w-150" : "w-30"} />
        </div>
        <p className="font-weight-medium">{successText}</p>
      </Modal.Body>
    </Modal>
  );
};
SuccessModal.propTypes = {
  successText: PropTypes.string,
  size: PropTypes.string,
  show: PropTypes.bool,
  onSubmit: PropTypes.func,
  errorIcon: PropTypes.bool,
};
export default SuccessModal;
