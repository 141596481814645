import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserBanks, bankUserMandate, getMandateById } from '../../api/bank';
import { getHomeData } from '../../api/nav';
import greenCheckIcon from '../../assets/green-check-white.svg';
import { decryptData } from '../../utility/DecryptHelper';
import DataMsg from '../../common/data-msg/DataMsg';
import { useHistory } from 'react-router-dom';
import MobileNavigation from '../../common/footermobile/MobileNavigation';
import { updateBankIDAction } from '../../redux/reducers/mandate-reducer';

const BankAccountMobile = () => {
  const userId = useSelector((state) => state.authReducer.user_id);
  const { ready_to_invest } = useSelector((state) => state.authReducer);
  const [bankList, setBankList] = useState([]);
  const dispatch = useDispatch();
  const [, setHomeData] = useState({});
  const history = useHistory();
  const [mandateData, setMandateData] = useState();

  useEffect(() => {
    getUserBanks(userId).then((res) => {
      if (res && res.data) {
        const data = decryptData(res.data.data);
        setBankList(data?.data?.bank_details);
      }
    });
    getHomeData().then((res) => {
      if (res && res.data) {
        const homeData = decryptData(res.data.data);
        if (homeData) {
          setHomeData(homeData);
        }
      }
    });
    getBankUserMandate();
  }, []);
  const getStatus = (status) => {
    let curStatus = { status: 'Verified', class: 'c-green' };
    if (status === 'REJECTED') {
      curStatus = { status: 'Rejected', class: 'c-red' };
    } else if (status === 'UPLOADED') {
      curStatus = { status: 'Pending', class: 'c-yellow' };
    } else if (status === 'UPLOADEDTOBSE') {
      curStatus = { status: 'Verified', class: 'c-green' };
    } else if (status === 'VERIFIEDBYADMIN') {
      curStatus = { status: 'Verified', class: 'c-green' };
    }
    return curStatus;
  };

  const getBankUserMandate = () => {
    bankUserMandate(userId).then((res) => {
      if (res && res.data) {
        const mandate = decryptData(res.data.data);
        setMandateData(mandate.data);
      }
      getUserBanks(userId).then((res) => {
        if (res && res.data) {
          const data = decryptData(res.data.data);
          setBankList(data?.data?.bank_details);
        }
      });
    });
  };

  const getGetBankMandateDetails = (bankId) => {
    if (mandateData) {
      let result = mandateData.filter((mandateObject) => {
        return mandateObject.bank_details.bank_id === bankId ? mandateObject : null;
      });
      result.sort((a, b) => {
        return a.id - b.id;
      });
      return result[result.length - 1];
    }
  };

  const getMandateType = (mandateType) => {
    if (mandateType === 'N') {
      return 'e-NACH';
    } else if (mandateType === 'X') {
      return 'NACH';
    } else {
      return 'I';
    }
  };

  const goToBankAutoPay = (bankId, status, url) => {
    if (status === 'autopay') {
      dispatch(updateBankIDAction(bankId));
      history.push({ pathname: `/profile/bank-autopay/${bankId}/${userId}` });
    } else {
      window.location.href = url;
    }
  };
  const completeNachMandate = (bankId, mandateId, type, maxautopayLimit) => {
    if (type === 'NACH') {
      history.push({ pathname: `/bank-mandate/${bankId}/${userId}`, state: { mandateID: mandateId, status: 'mandate-completed', flow: type } });
    } else if (type === 'e-NACH') {
      getMandateById(mandateId).then((response) => {
        const data = response.data;
        if (data.redirection_url) {
          history.push({
            pathname: `/bank-mandate/${bankId}/${userId}`,
            state: {
              mandateID: mandateId,
              status: 'mandate-completed',
              flow: type,
              maxautopaylimit: maxautopayLimit,
              redirectionUrl: data.redirection_url,
            },
          });
        } else {
          history.push({
            pathname: `/bank-mandate/${bankId}/${userId}`,
            state: { mandateID: mandateId, status: 'mandate-completed', flow: type, maxautopaylimit: maxautopayLimit },
          });
        }
      });
    }
  };

  return (
    <div className="bank-account-section bg-light-grey p-4">
      <div className="h5 font-weight-bold text-center mb-2">Bank Accounts</div>
      <div className="colored-box p-4">
        <p className="small opacity-50 mb-1">Bank Account(s), from which investments are made</p>
        <p className="small opacity-50">Any investments you redeem, will be directly credited to your linked bank account(s)</p>
        <div>
          {bankList && bankList.length ? (
            bankList.map((bankDetail) => {
              const statusObj = getStatus(bankDetail.status);
              const mandateObject = getGetBankMandateDetails(bankDetail.id);
              return (
                <section key={'bank-detail-' + bankDetail.id}>
                  <div className="border-bottom p-1  py-2 rounded my-2" key={'bank-detail-' + bankDetail.id}>
                    <div className="d-flex justify-content-between mb-2 ">
                      <span className="small font-weight-bold">{bankDetail.branch__bank_id__bank_name}</span>
                      {bankDetail.is_default ? <span className="small c-blue mx-0 mt-1 px-0 ">(Primary Account)</span> : null}
                    </div>
                    <div className="d-flex justify-content-between mb-2 ">
                      <span className="small opacity-50"> {bankDetail.branch__branch_name}</span>
                    </div>
                    <div className="h6  mb-2">
                      <span className="small opacity-50">{bankDetail.account_type_bse === 'SB' ? 'Savings A/c No :' : 'Current A/c No :'}</span>
                      <span className="font-weight-bold mx-1">{bankDetail.account_number}</span>
                      {bankDetail.is_verified ? <img src={greenCheckIcon} alt="account default" className="ml-2" /> : null}
                    </div>
                    <div className="small mb-2 ">
                      <span className="opacity-50 px-0">Bank Account Proof :</span>
                      <span className="font-weight-bold px-0 mx-1">
                        Other
                        {/* <img src={keys.media_base_url + bankDetail.verification_document} alt="bank document" /> */}
                      </span>
                    </div>
                    <div className="small ">
                      <span className="opacity-50 px-0">IFSC Code :</span>
                      <span className="font-weight-bold px-0 mx-1">{bankDetail.branch__ifsc_code}</span>
                    </div>
                    <div className="status-container pt-0">
                      <span className="small opacity-50">Status:</span>{' '}
                      <span className={'ml-1 font-weight-bold ' + statusObj.class}>{statusObj.status}</span>
                    </div>
                    {mandateObject?.amount && mandateObject?.mandate_type && (
                      <span className="small opacity-50">
                        Auto Pay Limit :{' '}
                        {mandateObject?.amount.toLocaleString('en-IN', {
                          style: 'currency',
                          currency: 'INR',
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0,
                        })}{' '}
                        (
                        {mandateObject?.status !== 'UNDER PROCESSING' &&
                          mandateObject?.status !== 'INITIAL REJECTION' &&
                          getMandateType(mandateObject.mandate_type)}{' '}
                        {mandateObject?.status === 'FAILED'
                          ? 'Autopay Status is Failed'
                          : mandateObject?.status === 'REJECTED'
                          ? 'Rejected'
                          : mandateObject?.status === 'REGISTERED BY MEMBER'
                          ? 'In Progress'
                          : mandateObject?.status === 'SCAN IMAGE NOT UPLOADED'
                          ? 'In Progress'
                          : mandateObject?.status === 'UNDER PROCESSING'
                          ? 'Your application is being processed'
                          : mandateObject?.status === 'INITIAL REJECTION'
                          ? 'Your application is being processed'
                          : ''}{' '}
                        ){' '}
                      </span>
                    )}
                    {statusObj.status === 'Verified' && (
                      <div className="d-flex justify-content-between mb-2 px-0 small">
                        {/*<span className={'ml-1 font-weight-bold ' + statusObj.class}>{statusObj.status}</span>*/}
                        {mandateObject?.status === 'APPROVED' && <span className={'h6 ml-1 font-weight-bold c-green '}>Approved</span>}
                        {mandateObject?.status === 'REJECTED' && ready_to_invest && (
                          <button
                            type="button"
                            className="btn btn-outline-primary ml-auto w-100 mt-1"
                            onClick={() => {
                              goToBankAutoPay(bankDetail.id, 'autopay', '');
                            }}>
                            {' '}
                            Setup AutoPay{' '}
                          </button>
                        )}{' '}
                        {/*Rejected is not the response needs to check */}
                        {mandateObject?.status === 'REGISTERED BY MEMBER' &&
                          !mandateObject?.is_mandate_uploaded &&
                          mandateObject?.status === 'INITIAL REJECTION' &&
                          !mandateObject.is_mandate_uploaded &&
                          getMandateType(mandateObject.mandate_type) === 'NACH' && (
                            <button
                              type="button"
                              className="btn btn-outline-primary ml-auto w-100 mt-1"
                              onClick={() =>
                                completeNachMandate(bankDetail.id, mandateObject.id, getMandateType(mandateObject.mandate_type), mandateObject.amount)
                              }>
                              {' '}
                              Complete AutoPay{' '}
                            </button>
                          )}
                        {mandateObject?.status === 'REGISTERED BY MEMBER' && getMandateType(mandateObject.mandate_type) === 'e-NACH' && (
                          <button
                            type="button"
                            className="btn btn-outline-primary ml-auto w-100 mt-1"
                            onClick={() =>
                              completeNachMandate(bankDetail.id, mandateObject.id, getMandateType(mandateObject.mandate_type), mandateObject.amount)
                            }>
                            {' '}
                            Complete AutoPay{' '}
                          </button>
                        )}
                        {mandateObject?.status === 'INITIAL REJECTION' && mandateObject?.mandate_type === 'I' && (
                          <button
                            type="button"
                            className="btn btn-outline-primary w-100 mt-1"
                            onClick={() =>
                              completeNachMandate(bankDetail.id, mandateObject.id, getMandateType(mandateObject.mandate_type), mandateObject.amount)
                            }>
                            {' '}
                            Complete AutoPay{' '}
                          </button>
                        )}
                        {mandateObject?.status === 'SCAN IMAGE NOT UPLOADED' && mandateObject?.mandate_type === 'I' && (
                          <button
                            type="button"
                            className="btn btn-outline-primary w-100 mt-1"
                            onClick={() =>
                              completeNachMandate(bankDetail.id, mandateObject.id, getMandateType(mandateObject.mandate_type), mandateObject.amount)
                            }>
                            {' '}
                            Complete AutoPay{' '}
                          </button>
                        )}
                        {mandateObject?.status === '' && mandateObject?.mandate_type === '' && ready_to_invest && (
                          <button type="button" className="btn btn-outline-primary w-100 mt-1" disabled>
                            {' '}
                            Setup AutoPay{' '}
                          </button>
                        )}
                        {(!mandateObject || mandateObject?.status === 'FAILED') && ready_to_invest && (
                          <button
                            type="button"
                            className="btn btn-outline-primary w-100 mt-1"
                            onClick={() => {
                              goToBankAutoPay(bankDetail.id, 'autopay', '');
                            }}>
                            {' '}
                            Setup AutoPay{' '}
                          </button>
                        )}
                      </div>
                    )}
                    {bankDetail.status === 'REJECTED' && <div className="c-red">Remarks : {bankDetail.remarks}</div>}
                  </div>
                  {bankDetail.status === 'REJECTED' ? (
                    <div className="bank-actions ">
                      <button
                        type="button"
                        className="btn btn-primary mt-3 w-100 mt-1"
                        onClick={() => {
                          history.push({ pathname: '/mobileprofile/add-bank', state: { bankDetail } });
                        }}>
                        Re-Submit
                      </button>
                    </div>
                  ) : null}
                </section>
              );
            })
          ) : (
            <DataMsg message="No Default bank found " />
          )}
        </div>
        {ready_to_invest && (
          <div className="bank-actions text-center">
            <button
              type="button"
              className="btn btn-primary mt-3 w-100 "
              onClick={() => {
                history.push({ pathname: '/mobileprofile/add-bank', state: {} });
              }}>
              Add Bank
            </button>
          </div>
        )}
      </div>
      <MobileNavigation />
    </div>
  );
};
export default BankAccountMobile;
