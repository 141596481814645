import React, { useEffect, useRef, useState } from 'react';
import { InputBox, InputDate, RadioGroups, SelectBox } from '../../../common/form';
import { useSelector } from 'react-redux';
import { DocumentUpload } from '../../../common/on-boarding';
import { uploadSingleDocument } from '../../../api/kyc';
import Toast from '../../../common/toast/Toast';
import { decryptData } from '../../../utility/DecryptHelper';
import EditBankAccount from '../../../component/bank-accounts/EditBankAccount';
import { AnnualIncomeList, wealthSourceList} from '../../../utility/utility';
import { placeofbirthRegex } from '../../../utility/constant';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { getInvestorDetails, saveInvestorDetails, updateInvestorDetails } from '../../../api/onboarding.api';



const MinorOnboarding = ({ kycData, setKycData }) => {
  const history = useHistory();
  const { pan, pan_kyc_status } = useSelector((state) => state.onboardingReducer);
  const userId = useSelector((state) => state.authReducer.user_id);
  const [fullName, setFullName] = useState();
  const [dobMinor, setDobMinor] = useState();
  const [date, setDate] = useState();
  const [updateFullName, setUpdateFullName] = useState(false);
  const [updateDob, setUpdateDob] = useState(false);
  const [bankUpdated, setBankUpdated] = useState(false);
  const [fatcaUpdated, setFatcaUpdated] = useState(false);
  const [errors, setErrors] = useState({});
  const [placeOfBirth, setPlace] = useState('');
  const [annualIncome, setAnnualIncome] = useState();
  const [wealthSource, setWealthSource] = useState();
  const [birthCountry, setBirthCountry] = useState('India');
  const [placeOfBirthErr, setPlaceOfBirthErr] = useState();
  const [checked,setChecked]= useState(false);
  const [mobileDeclaration,setMobileDeclaration]= useState(false);
  const [emailDeclaration,setEmailDeclaration]= useState(false);
  const [declarationError, setDeclarationError] = useState();
  const [notPoliticallyExposedCheck, setNotPoliticallyExposedCheck]= useState(false);
  const [politicallyExposedError, setPoliticallyExposedError] = useState();

  const childRef = useRef();
  const childRefDoc = useRef();

  useEffect(() => {
    setfatcaData(kycData)
  }, [kycData]);

  useEffect(() => {
    getKycData();
  }, [])

  const getKycData = () => {
    getInvestorDetails(userId).then(res => {
      setAnnualIncome(res.data.fatca_detail.income_slab);
      setPlace(res.data.fatca_detail.birth_place);
      setWealthSource(res.data.fatca_detail.income_source);
      setBirthCountry(res.data.fatca_detail.birth_country);
    })
  }

  const handleChangeFullName = (e) => {
    let value = e.target.value;
    setFullName(value);
  };
  const handleFullNameClick = () => {
    setUpdateFullName(true);
  };
  const handleChangeDOB = (date) => {
    setDobMinor(date);
  };
  const handleDobClick = () => {
    setUpdateDob(true);
  };
  const multiDocUpload = () => {
    // getUserKycData();
  };
  const uploadSingleDocuments = (docObj, files) => {
    const postObj = new FormData();
    postObj.append('doc', files);
    postObj.append('doc_type', docObj.id);
    uploadSingleDocument(postObj).then((res) => {
      if (res && res.data) {
        if (res.data.status.message && res.data.status.code === 1) {
          Toast.success(res.data.status.message);
        }
        const data = decryptData(res.data.data);
        if (data && data.data && data.data.length) {
          setKycData(data.data[0]);
        }
      }
    });
  };

  const onBankSubmitted = () => {
    setBankUpdated(true);
  };

  const setfatcaData = (data) => {
    setNotPoliticallyExposedCheck(!data.is_entity_politically_exposed_person);
    if(data.mobile_number && data.mobile_declaration && data.email_declaration){
      setChecked(true);
      setMobileDeclaration(true)
      setEmailDeclaration(true)
    }
    if(!data.mobile_number && data.email_declaration){
      setChecked(true);
      setEmailDeclaration(true)
    }
  };
  useEffect(() => {
    let tempDate = dobMinor;
    if (tempDate !== null) {
      const date = new Date(tempDate);
      tempDate = date.toLocaleDateString('en-GB');
    }

    setDate(tempDate);
  }, [dobMinor]);
  const saveFormData = () => {
    const minorDetails = {
      fullName: fullName === '' ? kycData.full_name : fullName,
      dobMinor: dobMinor === '' ? kycData.dob_minor : date,
    };
    const formData = new FormData();
    formData.append('place_of_birth', placeOfBirth);
    formData.append('country_of_birth', birthCountry);
    if (wealthSource !== undefined || !wealthSource) {
      formData.append('wealth_src', wealthSource);
    }
    formData.append('income_slab', annualIncome);
    formData.append('full_name', minorDetails.fullName);
    formData.append('dob_minor', minorDetails.dobMinor);
    formData.append('mobile_declaration', mobileDeclaration);
    formData.append('email_declaration', emailDeclaration);
    formData.append('is_entity_politically_exposed_person', !notPoliticallyExposedCheck);
    // updateFatcaData(formData).then((res) => {
    //   if (res.data.status.code === 1) {
    //     history.push('/home');
    //   }
    // });

    const req = {
      "investor_type": "minor",
      "first_name": minorDetails.fullName,
      "email_declaration": emailDeclaration ? 'guardian' : undefined,
      "mobile_declaration": mobileDeclaration ? 'guardian' : undefined,
      "dob": minorDetails.dobMinor,
      "guardian_detail": {
        "first_name": kycData.pan_name,
        "pan": pan,
        "email": kycData.email,
        "mobile": kycData.mobile_number,
        "fatca_detail": {
          "income_source": wealthSource,
          "income_slab": annualIncome,
          "birth_place": placeOfBirth,
          "birth_country": birthCountry
        }
        }
      }

    saveInvestorDetails(userId, req).then(response => {
      history.push('/home');
    }).catch(error => {
      console.error(error)
    })

    onSubmit()
  };
  const onSubmit = () => {
    if (kycData.bank_details.id) {
      childRef.current.updateNewBank();
    } else {
      childRef.current.addNewBank();
    }
  };
  const submitFormData = () => {
    const minorDetails = {
      fullName: fullName === '' ? kycData.full_name : fullName,
      dobMinor: dobMinor === '' ? kycData.dob_minor : date,
    };
    if(validateData()) {
      const formData = new FormData();
      formData.append('place_of_birth', placeOfBirth);
      formData.append('country_of_birth', birthCountry);
      formData.append('wealth_src', wealthSource);
      formData.append('income_slab', annualIncome);
      formData.append('full_name', minorDetails.fullName);
      formData.append('dob_minor', minorDetails.dobMinor);
      formData.append('is_submit', true);
      formData.append('mobile_declaration', mobileDeclaration);
      formData.append('email_declaration', emailDeclaration);
      formData.append('is_entity_politically_exposed_person', !notPoliticallyExposedCheck);
      const req = {
        "investor_type": "minor",
        "first_name": minorDetails.fullName,
        "email_declaration": emailDeclaration ? 'guardian' : '',
        "mobile_declaration": mobileDeclaration ? 'guardian' : '',
        "dob": minorDetails.dobMinor,
        "guardian_detail": {
          "first_name": kycData.pan_name,
          "pan": pan,
          "email": kycData.email,
          "mobile": kycData.mobile_number,
          "dob": "",
          "fatca_detail": {
            "income_source": wealthSource,
            "income_slab": annualIncome,
            "birth_place": placeOfBirth,
            "birth_country": birthCountry
          }
        }
      }
      if(!notPoliticallyExposedCheck) {
        setPoliticallyExposedError('Please confirm you are not politically exposed')
      }
      if(!emailDeclaration) {
        setDeclarationError(`Please confirm your Email ${kycData?.mobile_number ? 'and Mobile ' : ''}Declaration.`);
      }
      if (emailDeclaration && notPoliticallyExposedCheck) {
        updateInvestorDetails(userId, req).then(response => {
          setFatcaUpdated(true);
        }).catch(error => {
          console.error(error)
        })
        onSubmit()
      }
    }
  };
  useEffect(() => {
    if (fatcaUpdated && bankUpdated) {
      history.push('/home');
    }
  }, [fatcaUpdated, bankUpdated]);

  const validateData = () => {
    let errors = [];
    if (!wealthSource || wealthSource.length === 0) {
      errors['wealthSource'] = 'Wealth Source is required.';
    }
    if (!birthCountry || birthCountry.length === 0) {
      errors['birthCountry'] = 'Country of Birth is required.';
    }
    if (!placeOfBirth || placeOfBirth.length === 0) {
      setPlaceOfBirthErr('Place of Birth is required.');
    }
    setErrors(errors);
    let status = handleErrorsBank();
    if((errors && Object.keys(errors).length > 0) || !status) {
      return false;
    }
    return true;
  }

  const handleErrorsBank = () => {
    let status = childRef.current.validate()
    let status1 = childRefDoc.current.validateDoc()
    if(status && status1) {
      return true
    }
    return false;
  }

  const onBirthPlaceChange = (event) => {
    let inputValue = event.target.value || '',
      placeErr = '';
    if (!inputValue) {
      setPlace('');
    } else if (!placeofbirthRegex.test(inputValue)) {
      placeErr = 'Please enter a Place of birth without spaces or characters like("-", "&", "@", etc)';
    } else {
      placeErr = '';
      setPlace(inputValue);
    }
    setPlaceOfBirthErr(placeErr);
  };

  const checkboxHandleChange = (event) => {
    const { checked } = event.target;
    setChecked(checked);
    if(checked){
      setEmailDeclaration(true);
      if(kycData?.mobile_number) {
        setMobileDeclaration(true);
      }
      setDeclarationError(undefined);
    }else{
      setEmailDeclaration(false);
      setMobileDeclaration(false);
    }
  };

  const countryOfBirthChange = (value) => {
    setBirthCountry(value);
    errors['birthCountry'] = '';
    setErrors(errors)
  };

  const wealthSourceChange = (value) => {
    setWealthSource(value);
    errors['wealthSource'] = '';
    setErrors(errors)
  };

  const politicallyExposedChange = (event) => {
    const { checked } = event.target;
    setNotPoliticallyExposedCheck(checked)
    if(checked) {
      setPoliticallyExposedError(undefined)
    }
  };

  const onRadioChange = (event) => {
    const { value } = event.target;
    setAnnualIncome(value);
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          {updateFullName ? (
            <InputBox
              id="onboarding-fullname"
              title={"Minor's Name"}
              placeholder="Enter Minor's Full Name"
              autoComplete="none"
              name="text"
              value={fullName}
              onChange={handleChangeFullName}
            />
          ) : (
            <InputBox
              id="onboarding-fullname"
              title={"Minor's Name"}
              placeholder="Enter Minor's Full Name"
              autoComplete="none"
              name="text"
              value={kycData.full_name}
              onClick={handleFullNameClick}
            />
          )}
        </div>
        <div className="col-sm-12">
          {updateDob ? (
            <>
              <InputDate
                id="date_picker_id"
                title={"Minor's Date of Birth"}
                placeholder="yyyy/mm/dd"
                value={dobMinor}
                onChange={handleChangeDOB}
                maxDate={new Date()}
              />
            </>
          ) : (
            <>
              <InputDate
                id="onboarding-dob"
                title={"Minor's Date of Birth"}
                placeholder="yyyy/mm/dd"
                value={kycData.dob_minor === '' ? new Date() : new Date(kycData.dob_minor)}
                onClick={handleDobClick}
                onSelect={handleDobClick}
                onChange={handleChangeDOB}
              />
            </>
          )}
        </div>
        <div className="col-sm-12">
          <InputBox
            id="onboarding-name"
            title={"Guardian's Full Name"}
            value={kycData.pan_name}
            disabled
          />
        </div>
        <div className="col-sm-12 mt-3">
          <InputBox
            id="onboarding-address"
            title={'Address'}
            value={kycData.address}
            disabled
          />
        </div>
        <div className="col-sm-12 mt-3">
          <h6 className="font-weight-bold my-4">Documents</h6>
          <DocumentUpload
            kycData={kycData}
            documentList={kycData.document_list}
            panStatus={pan_kyc_status}
            singleDocUpload={uploadSingleDocuments}
            multiDocUpload={multiDocUpload}
            title={""}
            ref={childRefDoc}
          />
        </div>
        <section className="col-sm-12">
          <h6 className="font-weight-bold my-4">Bank Details</h6>
          <div className="border p-4 rounded">
            {kycData.bank_details && (
              <EditBankAccount
                alignment="center"
                ref={childRef}
                buttonText="Submit"
                panStatus={pan_kyc_status}
                bankDetail={kycData.bank_details}
                onBankSubmitted={onBankSubmitted}
                onboarding={true}
              />
            )}
          </div>
        </section>
        <section className="col-sm-12">
          <h6 className="font-weight-bold mt-4">Details for FATCA</h6>
          <div className="border p-4 rounded w-100 my-3">
            <InputBox
              id="PlaceofBirth"
              type="text"
              className="col-sm-5"
              onChange={onBirthPlaceChange}
              title="Place of Birth"
              value={placeOfBirth}
              defaultValue={placeOfBirth}
              maxLength={40}
              err={placeOfBirthErr}
            />
          </div>
          <div className="border p-4 rounded w-100 my-3">
            <SelectBox
              title="Country of Birth"
              value={birthCountry}
              id="countryBirth"
              classes={{ root: 'col-sm-3 px-0' }}
              onUpdate={countryOfBirthChange}
              options={[{ label: 'India', value: 'India' }]}
              err={errors && errors['birthCountry']}
            />
          </div>
          <div className="border p-4 rounded w-100 my-3">
            <SelectBox
              title="Wealth Source"
              value={wealthSource}
              id="WealthSource"
              classes={{ root: 'col-sm-3 px-0' }}
              onUpdate={wealthSourceChange}
              options={wealthSourceList}
              placeholder="Select wealth source"
              err={errors && errors['wealthSource']}
            />
          </div>
          {/*<AnnualIncome value={annualIncome} onChange={setAnnualIncome} />*/}
          <div className="border p-4 rounded w-100 my-3">
            <RadioGroups
              title="Annual Income"
              id="annualIncome"
              radioList={AnnualIncomeList}
              selectedVal={annualIncome}
              onChange={onRadioChange}
            />
          </div>
          <div className="py-2 rounded w-100 ">
            <Form.Check>
              <Form.Check.Input id="declaration" type="checkbox" checked={checked} onChange={checkboxHandleChange} style={{ cursor: 'pointer' }} />
              <Form.Check.Label for="declaration" onChange={checkboxHandleChange} style={{ cursor: 'pointer' }}>
                {kycData?.mobile_number
                  ? 'I confirm that mobile number ' + kycData?.mobile_number + ' and email ' + kycData?.email + ' belong to ' + kycData?.pan_name
                  : 'I confirm that the email id ' + kycData?.email + ' belong to ' + kycData?.pan_name}
              </Form.Check.Label>
            </Form.Check>
            {declarationError && <div className={'invalid-feedback d-block mt-2'}>{declarationError}</div>}
            <Form.Check className={"mt-2"}>
              <Form.Check.Input id="politicallyExposed" type="checkbox" checked={notPoliticallyExposedCheck} onChange={politicallyExposedChange} style={{cursor: 'pointer'}}/>
              <Form.Check.Label for="politicallyExposed" onChange={politicallyExposedChange} style={{cursor: 'pointer'}}>{'I confirm I am not politically exposed'}</Form.Check.Label>
            </Form.Check>
            {politicallyExposedError && <div className={'invalid-feedback d-block mt-2'}>{politicallyExposedError}</div>}
          </div>
          <div className="btn-container text-center my-4 col-sm-12">
            <button type="button" className="btn btn-outline-primary m-2" onClick={saveFormData}>
              Save As Draft
            </button>
            <button type="button" className="btn btn-primary m-2 btn-width" onClick={submitFormData}>
              Submit
            </button>
          </div>
        </section>
      </div>
    </>
  );
};

export default MinorOnboarding;
