// TODO: remove once decision has been made for user group detail page

import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link, useParams } from 'react-router-dom';
import { fdApi } from '../../../api/fdApi';
import { UserGroupsInterface } from '../../../api/fdApi/services/user_groups.service';
import Toast from '../../../common/toast/Toast';
import { MainLoader } from '../../shared/component/MainLoader';
import BrowserSearch from '../Transactions/icons/BrowserSearch.svg';
import './UserGroupDetailPage.scss';

export const UserGroupsDetailPage = () => {
  const [searchbarValue, setSearchBarValue] = useState<string>('');
  const { id: groupId } = useParams<any>();

  const { data: listOfUsers, isFetching: isLoadingUsers } = useQuery(
    ['ListOfUsersInGroup'],
    async () => {
      const res = await fdApi.UserGroups.getUsersByGroupId(groupId);
      return res;
    }
  );

  const { data: groupDetail, isFetching: isLoadingGroupDetail } = useQuery(
    ['GroupDetail'],
    async (): Promise<UserGroupsInterface | undefined> => {
      const res = await fdApi.UserGroups.getUserGroups('');
      const groupDetail = res.filter((e) => {
        return Number(e.id) === +groupId;
      });
      if (groupDetail.length > 0) {
        return {
          name: groupDetail[0].name,
          id: groupDetail[0].id,
        };
      }
    }
  );

  const handleDeleteUser = async (user_id: string, group_id: string) => {
    fdApi.UserGroups.deleteUserFromGroup(user_id, group_id)
      .then(() => {
        Toast.success('User removed from the group.');
      })
      .catch(() => {
        Toast.error('Not able to remove the user from group.');
      });
  };

  if (isLoadingUsers || isLoadingGroupDetail) {
    return <MainLoader />;
  }

  if (!groupDetail) {
    return (
      <>
        <div>
          <h1>Group Not found!</h1>
        </div>
      </>
    );
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={'/user-groups'}>User Groups</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Group Details</Breadcrumb.Item>
      </Breadcrumb>
      <div>
        <div className='GroupDetail_header'>
          <div className='GroupDetail_header_section1_wrapper'>
            <span className='GroupDetail_header_title'>{groupDetail.name}</span>
            {/* <span className='GroupDetail_header_created_by_text'>
              <i>- Created by XYZ</i>
            </span> */}
          </div>
          <div className='GroupDetail_search_container'>
            <img src={BrowserSearch} alt='searchicon' />
            <input
              placeholder='Enter your text'
              value={searchbarValue as string}
              onChange={(event) => setSearchBarValue(event.target.value)}
            />
          </div>
        </div>
        <div className='GroupDetail_body'>
          <div className='GroupDetail_horizontal_divider'></div>
          <div className='GroupDetail_table_container'>
            {true ? (
              <div className='GroupDetail_table'>
                <div className='GroupDetail_table_header'>
                  <div>First Name</div>
                  <div>Last Name</div>
                  <div>Email</div>
                  <div>Mobile</div>
                  <div>Actions</div>
                </div>
                <div className='GroupDetail_table_rows'>
                  {(listOfUsers || [])?.length !== 0 ? (
                    (listOfUsers || [])?.map((user: any) => {
                      if (!user) {
                        return null;
                      }
                      return (
                        <>
                          {/* First name, last name, email, mobile, pan, */}
                          <div
                            className='GroupDetail_table_row'
                            key={Math.random()}
                          >
                            <div>
                              {Boolean(user.first_name) ? user.first_name : '-'}
                            </div>
                            <div>
                              {Boolean(user.last_name) ? user.last_name : '-'}
                            </div>
                            <div>{user.email}</div>
                            <div>{user.mobile}</div>
                            <div className='GroupDetail_table_row_field_actions'>
                              <button
                                onClick={() =>
                                  handleDeleteUser(user.id, groupId)
                                }
                                className='GroupDetail_table_edit_button'
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <span className='GroupDetail_table_no_data'>
                        No Users Found.
                      </span>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <span>Loading Group Users...</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
