import auth_apis from "./auth_apis";

const getFreshChatStatus = () => {
  if (process.env.NODE_ENV === 'development') {
    return auth_apis.get('elevo/app-config');
  }
  return auth_apis.get(`/elevo/app-config`);
};

export { getFreshChatStatus };
