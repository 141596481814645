import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LayoutWrapper from './layout/LayoutWrapper';

import * as Sentry from '@sentry/react';
import { getFreshChatStatus } from '../api/freshchat';
import OTPVerificationModal from '../modal/OTPVerificationModal';
import { useAppDispatch } from '../redux/hooks';
import { setFdAppURL } from '../redux/reducers/auth-reducer';
import { setShowOTPModal } from '../redux/reducers/header-reducer';
import { getInvestorsStatus } from '../utility/investorStatus';
import Routes from './Routes';
import './scss/app.scss';

const App = () => {
  document.body.classList.add('v2');

  const dispatch = useDispatch();
  const dispatchHook = useAppDispatch();
  const {
    user_id,
    email,
    mobile_number,
    scopes,
    nomineeAuthenticationStatus,
    is_kyc_verified,
    is_mobile_verified,
    is_email_activated,
  } = useSelector((state) => state.authReducer);
  const showOTPModal = useSelector((state) => state.headerReducer.showOTPModal);

  useEffect(() => {
    Sentry.setUser({
      email,
      mobile_number,
      scopes,
      user_id,
      nomineeAuthenticationStatus,
      is_kyc_verified,
      is_mobile_verified,
      is_email_activated,
    });
    return () => {
      Sentry.setUser(null);
    };
  }, []);

  const otpId =
    useSelector((state) => state.headerReducer.otpId) ||
    localStorage.getItem('otpId');

  const handleSubmitOTP = () => {
    dispatch(setShowOTPModal(false));
    getInvestorsStatus(user_id, dispatch);
    localStorage.removeItem('otpId');
  };

  useEffect(() => {
    getFreshChatStatus().then((res) => {
      dispatchHook(setFdAppURL(res.data));
      localStorage.setItem('app-config', JSON.stringify(res.data));
    });
  }, []);

  return (
    <>
      <LayoutWrapper>
        <div className={'container-fluid'}>
          <Routes />
        </div>
        {showOTPModal ? (
          <OTPVerificationModal
            show={showOTPModal}
            onSubmit={() => handleSubmitOTP()}
            otpId={otpId}
            setShowOTPModal={() => dispatch(setShowOTPModal(false))}
          />
        ) : null}
      </LayoutWrapper>
    </>
  );
};

export default App;
