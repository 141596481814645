import React, { useState, useEffect } from 'react';
import './status.css';
import SuccessIcon from '../../assets/tickmark.png';
import FailureIcon from '../../assets/closemark.png';
import { userMandateDetails } from '../../api/bank';
import { decryptData } from '../../utility/DecryptHelper';
import { useHistory } from 'react-router-dom';
const Status = (statusProps) => {
  const [mandateData, setMandateDate] = useState({});
  const history = useHistory();
  useEffect(() => {
    userMandateDetails(statusProps.mandateId).then((res) => {
      if ((res.status = 200 && res.data && res.data.status.code === 1)) {
        let data = decryptData(res.data.data);
        setMandateDate(data.data.bank_details);
      }
    });
  }, []);

  const continueButtonHandler = () => {
    history.push('/');
  };
  return (
    <>
      {mandateData && mandateData.bank_name && (
        <div className="wrapper-status">
          <h3 className="bank-text">Bank Mandate</h3>
          <div className="status-card">
            <img className="icon-img" alt="" src={statusProps.status === 'success' ? SuccessIcon : FailureIcon} />
            <h5 className="status-card-title-text">{statusProps.status === 'success' ? 'Successfully Submitted' : 'Submission Failed'}</h5>
            <p className="status-card-subtitle-text">
              {statusProps.status === 'success'
                ? 'Your mandate request has been successfully created. It can take your bank up to 30 days in the case of Nach and up to 7 days in the case of E-Nach to approve the mandate. You can check the mandate status from the bank details section under my profile.'
                : 'Your request has failed. Please try again.'}
            </p>
          </div>
          <div className="bank-details-card">
            <div className="bank-details-text-wrapper">
              <p className="bank-details-title">{mandateData.bank_name}</p>
            </div>
            <div className="divider"></div>
            <div className="bank-details-subtitles-wrapper">
              <div>
                <p className="bank-details-title-text">Account number</p>
                <p className="bank-details-title">{mandateData.account_number}</p>
              </div>
              <div>
                <p className="bank-details-title-text">Branch name</p>
                <p className="bank-details-title">{mandateData.branch_name}</p>
              </div>
            </div>
          </div>
          <div
            className="continue-btn-bank-status"
            onClick={() => {
              continueButtonHandler();
            }}>
            Continue
          </div>
        </div>
      )}
    </>
  );
};

export default Status;
