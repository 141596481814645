import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { fdApi } from '../../../api/fdApi';
import { UserGroupsInterface } from '../../../api/fdApi/services/user_groups.service';
import { MainLoader } from '../../shared/component/MainLoader';

interface AssignPermissionsToUserModalProps {
  user: any;
  isShowModal: boolean;
  listOfGroups: UserGroupsInterface[];
  setIsShowModal: (value: boolean) => void;
}

export const AssignPermissionsToUserModal = ({
  user,
  isShowModal,
  listOfGroups,
  setIsShowModal,
}: AssignPermissionsToUserModalProps) => {
  // Local State variables
  const [grantedPermissions, setGrantedPermissions] = useState<any>([]);
  const [isLoadingGrantedPermissions, setIsLoadingGrantedPermissions] =
    useState(false);

  // API calls
  const { data: allPermissions, isFetching: isLoadingGetPermissions } =
    useQuery(
      ['Permissions', user.id],
      fdApi.permissionsService.getAllPermissions
    );

  useEffect(() => {
    setIsLoadingGrantedPermissions(true);
    fdApi.investors
      .getGrantedPermissions(user.id)
      .then((res) => {
        /**
         * Creating the key value pair of granted permissions
         * {
         *  permission_id: list of user groups ids
         * }
         */
        let transformed_grantedPermissions: any = {};
        for (let key in res) {
          if (res[key]) {
            for (let item of res[key]) {
              transformed_grantedPermissions[item.id] = item.user_group_ids;
            }
          }
        }
        setGrantedPermissions(transformed_grantedPermissions);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoadingGrantedPermissions(false);
      });
  }, []);

  const permissionTypes = useMemo(() => {
    return Object.keys(allPermissions || []);
  }, [allPermissions]);

  if (isLoadingGrantedPermissions || isLoadingGetPermissions) {
    return <MainLoader />;
  }

  return (
    <>
      {!isLoadingGrantedPermissions && (
        <Modal
          show={isShowModal}
          centered
          backdrop='static'
          keyboard={false}
          onHide={() => setIsShowModal(false)}
          dialogClassName='modal-8w'
          aria-labelledby='example-custom-modal-styling-title'
        >
          <Modal.Header className='justify-content-center' closeButton>
            <span className='font-weight-medium h5'>Permissions</span>
          </Modal.Header>
          <Modal.Body className=' '>
            <div className='d-flex flex-wrap '>
              <div className='my-2 w-100'>
                <label htmlFor='permissions' className='opacity-50 mb-1 medium'>
                  Permissions
                </label>

                <div className='d-flex flex-column small mx-2'>
                  {permissionTypes.map((type: string, index) => {
                    if (type !== 'assist') return null;
                    return (
                      <>
                        <GrantPermissionRows
                          type={type}
                          listOfGroups={listOfGroups}
                          user_id={user.id}
                          key={index + type}
                          setIsShowModal={setIsShowModal}
                          allPermissions={allPermissions}
                          granted_permission={grantedPermissions ?? []}
                        />
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

interface GrantPermissionRowsProps {
  allPermissions: any;
  granted_permission: any;
  type: string;
  listOfGroups: any;
  user_id: string;
  setIsShowModal: (value: boolean) => void;
}

const GrantPermissionRows = ({
  type,
  user_id,
  listOfGroups,
  setIsShowModal,
  allPermissions,
  granted_permission,
}: GrantPermissionRowsProps) => {
  // Component state variables
  const defaultSelectedID = useMemo(() => {
    const ids = Object.keys(granted_permission);
    let defaultId = '';
    for (let id of ids) {
      if (id.includes(type)) {
        defaultId = id;
      }
    }
    return defaultId;
  }, [granted_permission]);

  const defaultSelectedGroups = useMemo(() => {
    const ids = Object.keys(granted_permission);
    for (let id of ids) {
      if (id.includes(type) && id.includes('select_groups')) {
        return granted_permission[id];
      }
    }
    return [];
  }, [granted_permission]);

  const [selectedUserGroups, setSelectedUserGroups] = useState<any>(
    defaultSelectedGroups
  );
  const [selectedPermissionId, setSelectedPermissionId] =
    useState<any>(defaultSelectedID);
  const [isLoading, setIsLoading] = useState(false);
  const [canAssistUsers, setCanAssistUsers] = useState<boolean>(
    defaultSelectedID.includes('assist')
  );
  const [permissionUpdatedMessage, setPermissionUpdatedMessage] = useState('');
  const [error, setError] = useState(false);
  // Extracting the IDs
  const grantedPermissionIds = useMemo(
    () => Object.keys(granted_permission),
    [granted_permission]
  );

  // API to grant permission
  const handleGrantPermission = () => {
    setIsLoading(true);
    let user_group_ids = (selectedUserGroups || []).map((o: any) => o?.id);
    const postPermissionsInput = {
      id: selectedPermissionId,
      user_group_ids: user_group_ids,
    };
    if (canAssistUsers && selectedPermissionId.includes('all')) {
      postPermissionsInput.user_group_ids = [];
    }
    // To revoke granted permission
    if (!canAssistUsers) {
      postPermissionsInput['id'] = 'tenant::users:assist:select_groups';
      postPermissionsInput['user_group_ids'] = [];
    }

    fdApi.investors
      .postPermissionsToBeGranted(user_id, postPermissionsInput)
      .then(() => {
        setPermissionUpdatedMessage('Permisssions updated successfully.');
      })
      .catch((err) => {
        setPermissionUpdatedMessage(
          'Something went wrong, Please try again after sometime.'
        );

        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Methods
  const handleCanAssist = (): void => {
    setCanAssistUsers((prev) => !prev);
    setSelectedPermissionId('');
    setSelectedUserGroups([]);
  };

  useEffect(() => {
    if (error || permissionUpdatedMessage) {
      setPermissionUpdatedMessage('');
      setError(false);
    }
  }, [canAssistUsers, selectedPermissionId, selectedUserGroups]);

  return (
    <>
      <div className='d-flex flex-column'>
        <div className='d-flex align-items-center my-3 small'>
          <input
            type='checkbox'
            value={'canAssistUsers'}
            id='can_assist_users'
            checked={canAssistUsers}
            className='mr-2'
            onChange={() => handleCanAssist()}
            style={{ cursor: 'pointer' }}
          />
          <label
            htmlFor='can_assist_users'
            className='m-0 medium'
            style={{ cursor: 'pointer' }}
          >
            Can Assist Users
          </label>
        </div>

        <>
          <div className=' d-flex flex-row'>
            {allPermissions &&
              (allPermissions[type] || []).map((perm: any) => {
                return (
                  <>
                    <div
                      onClick={() => setSelectedPermissionId(perm.id)}
                      className='Investors_permissions_row d-flex align-items-start mr-3'
                    >
                      <input
                        type='radio'
                        id={perm.id}
                        name={type}
                        value={perm.id}
                        onChange={() => setSelectedPermissionId(perm.id)}
                        checked={perm.id === selectedPermissionId}
                        defaultChecked={grantedPermissionIds.includes(perm.id)}
                        className='cursor-pointer mt-1'
                      />
                      <label htmlFor={perm.id} className='pl-2 cursor-pointer'>
                        {perm.name}
                      </label>
                    </div>
                  </>
                );
              })}
          </div>

          <div className='mb-2 w-50'>
            <label htmlFor='user-groups-list' className='opacity-50 mb-1 small'>
              User Group
            </label>

            <Typeahead
              id='UserGroupsForPermissions'
              labelKey={'name'}
              options={listOfGroups}
              placeholder='Enter Group Name'
              onChange={setSelectedUserGroups}
              defaultSelected={listOfGroups.filter((option: any) =>
                selectedUserGroups.includes(option.id)
              )}
              disabled={
                !selectedPermissionId ||
                !selectedPermissionId.includes('select_group')
              }
              multiple
              size='sm'
            ></Typeahead>
          </div>
        </>
      </div>
      {permissionUpdatedMessage && !error && (
        <div className={`${error ? 'text-danger' : 'text-success'}`}>
          {permissionUpdatedMessage}
        </div>
      )}
      <div className='d-flex flex-row align-items-center'>
        <Button
          variant='primary'
          size='sm'
          disabled={isLoading}
          onClick={handleGrantPermission}
          className='mt-2 
          mr-2'
        >
          {isLoading ? 'Loading...' : 'Save'}
        </Button>
        <Button
          variant='outline-primary'
          size='sm'
          className='mt-2 mr-2'
          onClick={() => setIsShowModal(false)}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};
