import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Toast from '../common/toast/Toast';
import BankAccountMobile from '../component/bank-accounts/BankAccountMobile';
import BankAccountUpdateMobile from '../component/bank-accounts/BankAccountUpdateMobile';
import BankAutoPayLimit from '../component/bank-accounts/BankAutoPayLimit';
import DigitalSignature from '../component/digital-signature/DigitalSignature';
import DigitalSignaturePreview from '../component/digital-signature/DigitalSignaturePreview';
import MobileFundListFilter from '../component/invest-fund-list/MobileFundListFilter';
import MySipsMobile from '../component/my-sips/MySipsMobile';
import ProfileComponentMobile from '../component/profile/ProfileComponentMobile';
import RedeemConfirmationModal from '../modal/RedeemConfirmationModal';
import RedemptionStatus from '../modal/RedemptionStatusModal';
import FundDetail from '../page/fund/FundDetail';
import InvestFundAll from '../page/fund/InvestFundAll';
import TopFunds from '../page/fund/TopFunds';
import SelectMandate from '../page/mandate/SelectMandate';
import StausMandate from '../page/mandate/StatusMandate';
import Home from '../page/nav/Home';
import InvestPage from '../page/nav/InvestPage';
import MobileProfile from '../page/nav/MobileProfile';
import Onboarding from '../page/nav/Onboarding';
import PanStatus from '../page/nav/PanStatus';
import PortfolioPage from '../page/nav/PortfolioPage';
import Profile from '../page/nav/Profile';
import Transactions from '../page/nav/Transactions';
import TransactionsMobile from '../page/nav/TransactionsMobile';
import Onboarding2 from '../page/onboarding2';
import OrderComplete from '../page/order/OrderComplete';
import OrderSummary from '../page/order/OrderSummary';
import { decryptData } from '../utility/DecryptHelper';
import { saveToken } from '../utility/utility';
import ProtectedRouter from './ProtectedRouter';

const Routers = () => {
  const { rise, token, is_kyc_verified } = useSelector(
    (state) => state.authReducer
  );
  const [isKycVerified, setIsKycVerified] = useState();
  const data = useSelector((state) => state.authReducer);
  let encodedData = null;
  let uD;
  let l = localStorage.getItem('lD');
  let lD = JSON.parse(l);
  if (encodedData) {
    let code = encodedData.slice(1, encodedData.length);
    let decodeData = decodeURIComponent(escape(window.atob(code)));
    if (decodeData) {
      uD = JSON.parse(decodeData);
      saveToken(uD?.token);
      localStorage.setItem('lD', JSON.stringify(uD));
    }
  } else if (!data.email) {
    uD = JSON.parse(l);
    saveToken(uD?.token);
  }

  let pan = localStorage.getItem('panData');
  let panName;
  useEffect(() => {
    if (rise) {
      if (rise.redirect_screen) {
        setIsKycVerified(rise.redirect_screen);
      } else if (pan) {
        panName = decryptData(pan);
        if (panName.pan) {
          setIsKycVerified(true);
        }
      } else {
        setIsKycVerified(is_kyc_verified);
      }
    }
  }, [is_kyc_verified]);
  Toast.destroy();
  return (
    <Switch>
      {uD && uD.user_id ? (
        <ProtectedRouter
          isKycVerified={isKycVerified}
          token={lD?.token}
          exact
          path='/'
          component={isKycVerified ? Home : PanStatus}
        />
      ) : null}
      {!isKycVerified ? <Route exact path='/' component={PanStatus} /> : null}
      <Route
        exact
        path='/redemptionconfirmation'
        component={RedeemConfirmationModal}
      />
      <Route exact path='/redemptionstatus' component={RedemptionStatus} />
      <Route
        exact
        path='/digitalsignature/:data'
        component={DigitalSignature}
      />
      <Route
        exact
        path='/digitalsignpreview'
        component={DigitalSignaturePreview}
      />
      <ProtectedRouter
        isKycVerified={isKycVerified}
        token={lD?.token}
        exact
        path='/panstatus'
        component={PanStatus}
      />
      <Route
        path='/profile'
        render={({ match: { url } }) => (
          <>
            <ProtectedRouter
              isKycVerified={isKycVerified}
              token={token}
              path={`${url}/detail`}
              component={Profile}
              exact
            />
            <ProtectedRouter
              isKycVerified={isKycVerified}
              token={token}
              path={`${url}/bank-list`}
              component={Profile}
            />
            <ProtectedRouter
              isKycVerified={isKycVerified}
              token={token}
              path={`${url}/add-bank`}
              component={Profile}
            />
            <ProtectedRouter
              isKycVerified={isKycVerified}
              token={token}
              path={`${url}/bank-autopay/:bankId/:userId`}
              component={BankAutoPayLimit}
            />
            <ProtectedRouter
              isKycVerified={isKycVerified}
              token={token}
              path={`${url}/my-sips`}
              component={Profile}
            />
          </>
        )}
      />
      <Route
        path='/bank-mandate/:bankId/:userId'
        component={SelectMandate}
        exact
      />
      <Route exact path='/bank-autopay-status' component={StausMandate} />
      <ProtectedRouter
        isKycVerified={isKycVerified}
        token={token}
        path={`/userprofile`}
        component={MobileProfile}
        exact
      />
      <ProtectedRouter
        isKycVerified={isKycVerified}
        token={token}
        path={`/mobileprofile/detail`}
        component={ProfileComponentMobile}
        exact
      />
      <ProtectedRouter
        isKycVerified={isKycVerified}
        token={token}
        path={`/mobileprofile/bank-list`}
        component={BankAccountMobile}
        exact
      />
      <ProtectedRouter
        isKycVerified={isKycVerified}
        token={token}
        path={`/mobileprofile/add-bank`}
        component={BankAccountUpdateMobile}
        exact
      />
      <ProtectedRouter
        isKycVerified={isKycVerified}
        token={token}
        path={`/mobileprofile/my-sips`}
        component={MySipsMobile}
        exact
      />
      <ProtectedRouter
        isKycVerified={isKycVerified}
        token={token}
        path='/invest'
        component={InvestPage}
      />
      <ProtectedRouter
        isKycVerified={isKycVerified}
        token={token}
        path='/portfolio'
        component={PortfolioPage}
      />
      <ProtectedRouter
        isKycVerified={isKycVerified}
        token={token}
        path='/transactions'
        component={Transactions}
      />
      <ProtectedRouter
        isKycVerified={isKycVerified}
        token={token}
        path='/transactionsmobile'
        component={TransactionsMobile}
      />
      <ProtectedRouter
        isKycVerified={isKycVerified}
        token={token}
        path='/topfunds/:id'
        component={TopFunds}
      />
      <ProtectedRouter
        isKycVerified={isKycVerified}
        token={token}
        path='/fund/detail/:name/:id'
        component={FundDetail}
      />
      <ProtectedRouter
        isKycVerified={isKycVerified}
        token={token}
        path='/funds/all'
        component={InvestFundAll}
      />
      <ProtectedRouter
        isKycVerified={isKycVerified}
        token={token}
        path='/funds/mobilefundlist'
        component={MobileFundListFilter}
      />
      <ProtectedRouter
        isKycVerified={isKycVerified}
        token={token}
        path='/order/summary'
        component={OrderSummary}
        key='OrderSummary'
      />
      ,
      <ProtectedRouter
        isKycVerified={isKycVerified}
        token={token}
        path='/order/complete/:orders/:mode'
        component={OrderComplete}
        key='OrderComplete'
      />
      <ProtectedRouter
        isKycVerified={isKycVerified}
        token={lD?.token}
        path='/boarding'
        component={Onboarding}
      />
      <ProtectedRouter
        isKycVerified={isKycVerified}
        token={lD?.token}
        path='/onboarding'
        component={Onboarding2}
      />
      <ProtectedRouter
        isKycVerified={isKycVerified}
        token={token}
        path='*'
        component={Home}
      />
    </Switch>
  );
};
export default Routers;
