import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { fdApi } from '../../../api/fdApi';
import { UsersOut } from '../../../api/fdApi/services/investors.service';
import { UserGroupsInterface } from '../../../api/fdApi/services/user_groups.service';
import Toast from '../../../common/toast/Toast';
import { MainLoader } from '../../shared/component/MainLoader';

interface AssignUserGroupsModalProps {
  user: UsersOut;
  isShow: boolean;
  userGroups: UserGroupsInterface[];
  setIsShow: (value: boolean) => void;
  onHide: () => void;
}

export const AssignUserGroupsModal = ({
  user,
  isShow,
  setIsShow,
  userGroups,
  onHide,
}: AssignUserGroupsModalProps): JSX.Element => {
  // Local state variables
  const [selectedUserGroups, setSelectedUserGroups] = useState<any>([]);
  const [groupsByUserId, setGroupsByUserId] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // APIs
  useEffect(() => {
    setIsLoading(true);
    fdApi.investors
      .getGroupsByUserId(user.id)
      .then((res) => {
        setGroupsByUserId(res);
      })
      .catch()
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <MainLoader />;
  }

  const addUserToGroups = async () => {
    fdApi.investors
      .updateInvestor(user.id, {
        group_ids: selectedUserGroups.map((group: any) => group.id),
      })
      .then(() => {
        const length = selectedUserGroups.length;
        let toast_message = '';
        if (length === 0) {
          toast_message = 'Removed user from all groups.';
        } else if (length === 1) {
          toast_message = 'User added to the group.';
        } else if (length > 1) {
          toast_message = 'User added to groups.';
        }

        Toast.success(toast_message);
        onHide();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <Modal
        show={isShow}
        size='sm'
        aria-labelledby='confirmation-modal'
        centered
        backdrop='static'
        keyboard={false}
        onHide={() => {
          setIsShow(false);
        }}
      >
        <Modal.Header className='justify-content-center' closeButton>
          <span className='font-weight-medium h5'>User Details</span>
        </Modal.Header>
        <Modal.Body className=''>
          <div>
            <div className='mb-2 w-100'>
              <label htmlFor='Group Name' className='opacity-50 mb-1 small'>
                Email
              </label>
              <input
                type='text'
                value={user.email}
                name='Group Name'
                className='w-100 input-group-sm rounded border border-light px-2 py-1'
                style={{ outline: 'none', cursor: 'not-allowed' }}
                readOnly
              />
            </div>
            <div className='mb-2 w-100'>
              <label
                htmlFor='user-groups-list'
                className='opacity-50 mb-1 small'
              >
                Select User Groups
              </label>

              <div className='d-flex small'>
                <Typeahead
                  id='UserGroupsToAdd'
                  size='sm'
                  labelKey={'name'}
                  options={userGroups}
                  placeholder='Enter Group Name'
                  onChange={setSelectedUserGroups}
                  className='flex-grow-1'
                  defaultSelected={groupsByUserId}
                  multiple
                ></Typeahead>
              </div>
            </div>
            <div className='d-flex flex-row align-items-center column-gap-2 mt-3'>
              <Button
                variant='primary'
                size='sm'
                onClick={addUserToGroups}
                className='align-self-stretch'
              >
                Save
              </Button>
              <Button
                variant='outline-primary'
                size='sm'
                onClick={() => setIsShow(false)}
                className='mx-1 align-self-stretch'
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
