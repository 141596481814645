import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../../common/page-header/PageHeader';
import { getInvestmentCategoryFund } from '../../api/fund';
import { formatNumber, formatPrice, regexString } from '../../utility/utility';
import MobileNavigation from '../../common/footermobile/MobileNavigation';
const TopFunds = ({ match, history }) => {
  const [topFundData, setFundData] = useState({});
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  useEffect(() => {
    getInvestmentCategoryFund(match.params.id).then((res) => {
      if (res && res.data) {
        const topFundsData = res.data.results;
        setFundData(topFundsData);
      }
    });
     
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const onInvestClick = (event, id, name) => {
    event.preventDefault();
    let url = `/fund/detail/${name}/${id}`
    history.push({pathname:url});
  };
  return (
    <>
    {windowDimensions.width > 480 ? (
      <div className="container top-funds-container colored-box">
        <PageHeader headerName={`Top ${topFundData.category_name ? topFundData.category_name.toLowerCase() : ''} funds to invest`} />
        <div className="row h6 font-weight-medium top-funds-row-header py-3">
          <div className="col-sm-6"></div>
          <div className="col-sm-1  text-right">AUM (Cr.)</div>
          <div className="col-sm-1 text-center">6 M</div>
          <div className="col-sm-1  text-center">1 Y</div>
          <div className="col-sm-1  text-center">3 Y</div>
          <div className="col-sm-2"></div>
        </div>
        <div className="top-fund-list">
          {topFundData &&
            topFundData.length &&
              topFundData.map((topFund) => (
              <div className="top-funds-row row" key={topFund.id}>
                <div className="col-sm-5 py-2 cursor-pointer" onClick={(event)=>onInvestClick(event, topFund.id, regexString(topFund.name))}>{topFund.name}</div>
                <div className="col-sm-2  py-2  text-right border-right">{formatPrice(topFund.aum / 10000000)}</div>
                <div className="col-sm-1  py-2 text-center border-right">{topFund.return_6_mth !== 0 ? (formatNumber(topFund.return_6_mth, 1))+"%" : "NA"}</div>
                <div className="col-sm-1  py-2  text-center border-right">{topFund.return_1_yr !== 0 ? (formatNumber(topFund.return_1_yr, 1))+"%" : "NA"}</div>
                <div className="col-sm-1  py-2  text-center">{topFund.return_3_yr !== 0 ? (formatNumber(topFund.return_3_yr, 1))+"%" : "NA"}</div>
                <div className="col-sm-2  py-2">
                  <button
                    type="button"
                    className="btn btn-primary py-1 w-75"
                    onClick={(event) => {
                      onInvestClick(event, topFund.id, regexString(topFund.name));
                    }}>
                    Invest
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
    ) : (
       <div className="container top-funds-container bg-light-grey ">
        <div className="fund-list-title pt-4 pb-2 row font-weight-bold">
          <div className="col-sm-10">
              <h3 className="text-center font-weight-medium c-blue">{topFundData.category_name ? topFundData.category_name : ''}</h3>
           </div>
        </div>
        <div className="pb-4">
          {topFundData &&
            topFundData.length &&
              topFundData.map((topFund) => (
              <div key={topFund.id} className='colored-box p-3 my-3 mx-4'>
                <h5 className='cursor-pointer' onClick={(event)=>onInvestClick(event, topFund.id, regexString(topFund.name))}>{topFund.name}</h5>
                <div className='d-flex '>
                <div className='mx-2'>
                  <p className='opacity-50 mb-2'>1 Year Return</p>
                  <p className='font-weight-bold font-12 '>{formatNumber(topFund.return_1_yr, 1)}%</p>
                </div>
                <div className='mx-2'>
                  <p className='opacity-50 mb-2'>3 Year Return</p>
                  <p className='font-weight-bold font-12'>{formatNumber(topFund.return_3_yr, 1)}%</p>
                </div>
                <div className="w-30 mx-2 ">
                    <button
                      type="button"
                      id={'fund-list-item-' + topFund.id}
                      className="btn btn-primary py-1 w-100 br-light mt-4"
                      onClick={(event) => {
                              onInvestClick(event, topFund.id, regexString(topFund.name));
                              }}>
                        Invest
                    </button>
                </div>
                </div>
                </div> 
              ))}
        </div>
        <MobileNavigation />
      </div>
    )}
      
    </>
  );
};
TopFunds.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};
export default TopFunds;
