import PropTypes from 'prop-types';
import { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useHistory, useLocation } from 'react-router-dom';
import { uploadSingleDocument } from '../../api/kyc';
import { TOKEN_KEY } from '../../utility/utility';
import SignSuccessModal from './SignSuccessModal';
import './signCanvas.scss';

const DigitalSignaturePreview = ({ onClose }) => {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const data = location.state;
  let token = JSON.parse(data.decodeData).data;
  localStorage.setItem(TOKEN_KEY, token);
  const submitSign = () => {
    let file = Math.random().toString(36).slice(2, 7);
    const fileName = file + '.jpg';
    fetch(data.url).then(async (response) => {
      const contentType = response.headers.get('content-type');
      const blob = await response.blob();
      const file = new File([blob], fileName, { contentType });
      const postObj = new FormData();
      postObj.append('doc', file);
      postObj.append('doc_type', '06');
      uploadSingleDocument(postObj).then((res) => {
        if (res && res.data && res.data.status.code === 1) {
          setShowModal(true);
        }
      });
    });
  };

  const goToSignPad = () => history.push('/digitalsignature/data');
  const onCloseSign = () => {
    onClose();
  };
  return (
    <>
      <div className='border m-4'>
        <h4 className='font-weight-medium  mt-3 mb-3 sign-head'>
          Digital Signature Preview
        </h4>
        <button id='cross' onClick={onCloseSign}>
          <AiOutlineClose className='cross' />
        </button>
        <div className='mt-2 col-sm-12 text-center p-4 m-4'>
          <img src={data.url} alt='signature' />
        </div>
        <div className='text-center mb-4'>
          <button
            onClick={submitSign}
            className='btn btn-outline-primary mr-3 w-40'
          >
            Confirm
          </button>
          <button onClick={goToSignPad} className='btn btn-primary ml-3 w-40'>
            Retry
          </button>
        </div>
      </div>
      {showModal ? <SignSuccessModal show={showModal} /> : null}
    </>
  );
};
DigitalSignaturePreview.propTypes = {
  successText: PropTypes.string,
  size: PropTypes.string,
  show: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  errorIcon: PropTypes.bool,
  id: PropTypes.string,
  match: PropTypes.object,
};
export default DigitalSignaturePreview;
