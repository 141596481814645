import React from 'react';
import { useLocation } from 'react-router-dom';
import Status from '../../component/bank-accounts/Status';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const StatusMandate = () => {
  let query = useQuery();

  return (
    <>
      <Status status={query.get('status')} mandateId={query.get('mandate_id')} />{' '}
    </>
  );
};

export default StatusMandate;
