import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
const RadioGroups = ({ onChange, selectedVal, radioList, title, id , classes, err}) => {
  return (
    <fieldset>
      <Form.Group as="div">
        <Form.Label as="legend" className={`h6 opacity-75 mb-3 ${classes}`}>
          {title}
        </Form.Label>
        {radioList &&
          radioList.length &&
          radioList.map((item, index) => (
            <Form.Check
              key={id + index}
              type="radio"
              label={item.label}
              name={id + 'Radio'}
              className="my-2"
              checked={item.value === selectedVal}
              value={item.value}
              id={id + index}
              onChange={onChange}
            />
          ))}
        {err && <div className={'invalid-feedback d-block'}>{err}</div>}
      </Form.Group>
    </fieldset>
  );
};
RadioGroups.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  selectedVal: PropTypes.string,
  radioList: PropTypes.array,
  onChange: PropTypes.func,
  classes: PropTypes.string,
};
export default RadioGroups;
