import { useEffect, useState } from 'react';
import SwitchFundForm from './Components/SwitchFundForm';
import { PortfolioItem } from '../../../types';
import { useQuery } from '@tanstack/react-query';
import { getProtfolio } from '../../../api/portfolio';
import { decryptData } from '../../../utility/DecryptHelper';
import { useAppSelector } from '../../../redux/hooks';
import { useHistory, useLocation } from 'react-router-dom';

const SwitchFunds = () => {
  const history = useHistory();
  const user = useAppSelector((s) => s.authReducer);
  const location = useLocation();
  const qs = new URLSearchParams(location.search);
  const id = qs.get('id');
  const [selectedFund, setSelectedFund] = useState<PortfolioItem | undefined>();

  const { data: portfolioMFItems } = useQuery<PortfolioItem[]>(
    ['PortfolioItems', user?.user_id],
    async () => {
      const res = await getProtfolio(user.user_id);
      const portfolioData: {
        [key: string]: {
          [key: string]: PortfolioItem[];
        };
      } = decryptData(res.data.data);

      return portfolioData.data ? portfolioData.data?.investment : [];
    }
  );

  useEffect(() => {
    if (id) {
      const cur = (portfolioMFItems || []).find(
        (fund) => String(fund.fund_id) === id
      );
      setSelectedFund(cur);
      qs.delete('id');
      history.replace({
        pathname: location.pathname,
        search: qs.toString(),
      });
    }
  }, [id]);

  return (
    <>
      <div className={'section_title'}>
        <span>My Portfolio |</span> <span>Switch Fund</span>
      </div>
      <div className={'switch-fund-card'}>
        <div className={'switch-fund-form'}>
          <SwitchFundForm
            selectedFund={selectedFund}
            portfolioList={portfolioMFItems || []}
          />
        </div>
        <div className={'switch-fund-info'}>
          <div className={'switch-fund-info-details'}>
            <div className={'switch-fund-info-icon'}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='71.543'
                height='71.519'
                viewBox='0 0 71.543 71.519'
              >
                <g id='conversion' transform='translate(0 -0.009)'>
                  <path
                    id='Path_3826'
                    data-name='Path 3826'
                    d='M55.113,18.448a9.982,9.982,0,0,0-1.49.074V12.49A1.49,1.49,0,0,0,52.133,11H1.49A1.49,1.49,0,0,0,0,12.49V42.28a1.49,1.49,0,0,0,1.49,1.49H41.424A16.385,16.385,0,1,0,55.113,18.448ZM2.979,13.979H50.644v5.109a15.3,15.3,0,0,0-3.351,1.385,1.49,1.49,0,0,0-1.117-.536,1.49,1.49,0,0,1-1.49-1.49,1.49,1.49,0,0,0-1.49-1.49H10.427a1.49,1.49,0,0,0-1.49,1.49,1.49,1.49,0,0,1-1.49,1.49,1.49,1.49,0,0,0-1.49,1.49V33.343a1.49,1.49,0,0,0,1.49,1.49,1.49,1.49,0,0,1,1.49,1.49,1.49,1.49,0,0,0,1.49,1.49H39.011a16.206,16.206,0,0,0,.864,2.979H2.979ZM38.728,34.832H34.1a10.427,10.427,0,0,0,0-14.9H41.96a4.469,4.469,0,0,0,2.413,2.577A16.161,16.161,0,0,0,38.728,34.832ZM19.364,27.385a7.448,7.448,0,1,1,7.448,7.448A7.448,7.448,0,0,1,19.364,27.385Zm.164,7.448H11.663a4.469,4.469,0,0,0-2.726-2.726V22.663a4.469,4.469,0,0,0,2.726-2.726h7.865a10.427,10.427,0,0,0,0,14.9ZM55.113,48.238a13.406,13.406,0,0,1-11.6-6.7,13.212,13.212,0,0,1-1.728-5.347,13.393,13.393,0,1,1,13.331,12.05Z'
                    transform='translate(0 5.38)'
                    fill='#00cca4'
                  />
                  <path
                    id='Path_3828'
                    data-name='Path 3828'
                    d='M53.071,10.422H47.217V13.4h8.937a1.49,1.49,0,0,0,1.49-1.49V4.464H54.665v3.4A35.868,35.868,0,0,0,7,10.422l2.16,2.16a32.859,32.859,0,0,1,43.911-2.16Z'
                    transform='translate(3.427 0)'
                    fill='#00cca4'
                  />
                  <path
                    id='Path_3829'
                    data-name='Path 3829'
                    d='M32.812,49.427a32.5,32.5,0,0,1-20.853-7.448h4.469V39H7.49A1.49,1.49,0,0,0,6,40.49v7.448H8.979V43.29a35.749,35.749,0,0,0,49.154-1.311l-2.115-2.115A32.487,32.487,0,0,1,32.812,49.427Z'
                    transform='translate(2.937 19.087)'
                    fill='#00cca4'
                  />
                  <path
                    id='Path_3835'
                    data-name='Path 3835'
                    d='M13.428-17.9l-1.221,2.7h-2.8a3.233,3.233,0,0,1,.4.629,2.59,2.59,0,0,1,.256.775h3.357l-1.221,2.7H10.071a4.2,4.2,0,0,1-.6,1.593A4.858,4.858,0,0,1,8.325-8.264a5.933,5.933,0,0,1-1.55.848,6.971,6.971,0,0,1-1.831.409L11.584,0H7.263L1.135-6.812v-2.7H2.942q.342,0,.867-.049a4.956,4.956,0,0,0,1.044-.214,3.282,3.282,0,0,0,.946-.476,1.565,1.565,0,0,0,.586-.848H.525l1.221-2.7H6.372a1.419,1.419,0,0,0-.537-.769,2.771,2.771,0,0,0-.934-.421,6.22,6.22,0,0,0-1.215-.177Q3.015-15.2,2.295-15.2H.525l1.221-2.7Z'
                    transform='translate(49 50.009)'
                    fill='#00cca4'
                  />
                </g>
              </svg>
            </div>
            <div className={'switch-fund-info-title'}>
              Switch From One Mutual Fund To Another Mutual Fund
            </div>
            <div className={'switch-fund-info-description'}>
              A switch transaction is one that allows you to switch or transfer
              money from one scheme to another scheme within the same mutual
              fund company.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SwitchFunds;
