import { encryptData, decryptData } from '../../utility/DecryptHelper';

const saveInfoAndPush = (info, nextStep, encrypted, history) => {
  saveInfo(info, nextStep, encrypted);
  history.push(nextStep);
};

const saveInfo = (info, nextStep, encrypted) => {
  saveInfoWithKey('flowData', info, nextStep, encrypted);
};

const saveInfoWithKey = (key, info, nextStep, encrypted) => {
  let finalInfo = info;
  if (encrypted) {
    finalInfo = decryptData(info);
  }
  const data = { nextStep: nextStep, data: finalInfo };
  const encryptedData = encryptData(data);
  localStorage.setItem(key, encryptedData);
};

const getInfo = () => {
  return getInfoWithKey('flowData');
};

const getInfoWithKey = (key) => {
  const encryptedData = localStorage.getItem(key);
  if (encryptedData) {
    const data = decryptData(encryptedData);
    return data;
  } else {
    return null;
  }
};

export { saveInfo, getInfo, saveInfoAndPush, saveInfoWithKey, getInfoWithKey };
