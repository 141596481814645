import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { InputBox, RadioGroups, SelectBox } from '../../../common/form';
import Toast from '../../../common/toast/Toast';
import { AnnualIncomeListNri, wealthSourceListNri , checkAddressDetails } from '../../../utility/utility';
import { Form } from 'react-bootstrap';
import { placeofbirthRegex } from '../../../utility/constant';
import {
  getKycDataNri,
  setFatcaDataNri,
  updateFatcaDataNri,
  uploadDocumentNri,
} from '../../../api/kyc';
import DocumentUploadNri from '../../../common/on-boarding/DocumentUploadNri';
import NewBankNri from '../../../component/bank-accounts/NewBankNri';
import NominationField from '../../../component/nomination-field';
import { addNominee, updateNominee } from '../../../api/nominee';

const NriOnboarding = ({kycData, setKycData}) => {
  const history = useHistory();
  const { pan_kyc_status } = useSelector((state) => state.onboardingReducer);
  const userId = useSelector((state) => state.authReducer.user_id);
  const [,setKycDataNri] = useState({});
  const [cityName, setCityName] = useState('');
  const [pinCode, setPinCode] = useState('');
  const [stateName, setStateName] = useState('');
  const [documentSuccessNri, setDocumentSuccessNri] = useState({});
  const [documentType] = useState('signature');
  const [nriAddress, setNriUserAddress] = useState('');
  const [, setBankUpdated] = useState(false);
  const [errors, setErrors] = useState([]);
  const [annualIncome, setAnnualIncome] = useState('');
  const [placeOfBirth, setPlace] = useState('');
  const [wealthSource, setWealthSource] = useState('');
  const [birthCountry, setBirthCountry] = useState('India');
  const [placeOfBirthErr, setPlaceOfBirthErr] = useState('');
  const [checked, setChecked] = useState(false);
  const [mobileDeclaration, setMobileDeclaration] = useState(false);
  const [emailDeclaration, setEmailDeclaration] = useState(false);
  const [declarationError, setDeclarationError] = useState();
  const [nomineeDetails, setNomineeDetails] = useState();
  const [nomineeErrors, setNomineeErrors] = useState([]);
  const [commonNomineeError, setCommonNomineeError] = useState();
  const [, setFatcaSubmited] = useState(false)
  const [nomineeSubmited, setNomineeSubmited] = useState(false);


  const childRef = useRef();
  const childRefDoc = useRef();

  useEffect(() => {
    getUserKycDataNri();
  }, [pan_kyc_status]);

  useEffect(() => {
    setFatcaData(kycData)
  }, [kycData])

  const getUserKycDataNri = () => {
    getKycDataNri(userId).then((res) => {
      if (res && res.data) {
        let investorType = res.data.investor_type;
        if (investorType === 'nri' || investorType === 'nre' || investorType === 'nro') {
          setKycDataNri(res.data);
          setNriUserAddress(res.data.contact_address.address);
          setCityName(res.data.contact_address.city);
          setPinCode(res.data.contact_address.pincode);
          setStateName(res.data.contact_address.state);
          setAnnualIncome(res.data.fatca_detail.income_slab);
          setPlace(res.data.fatca_detail.birth_place);
          setWealthSource(res.data.fatca_detail.income_source);
          setBirthCountry(res.data.fatca_detail.birth_country);
        }
      }
    });
  };

  const setFatcaData = (data) => {
    setPlace(data?.birth_place);
    setWealthSource(data?.income_source);
    if (data.mobile && data.email_declaration && data.mobile_declaration) {
      setChecked(true);
    }
    setEmailDeclaration(data.email_declaration ? 'self' : '');
    setMobileDeclaration(data.mobile_declaration ? 'self' : '');
    if (!data.mobile && data.email_declaration) {
      setChecked(true);
    }
  };

  const onNriAddressChange = (event) => {
    setNriUserAddress(event.target.value);
    
  };
  const onCityPlaceChange = (event) => {
    setCityName(event.target.value);
  
  };
  const onPinCodeChange = (event) => {
    setPinCode(event.target.value);
  
  };
  const onStateNameChange = (event) => {
    setStateName(event.target.value);
  };

  const handleBirthCountryChange = (value) => {
    setBirthCountry(value)
    let error = errors;
    if (value && value.length > 0) {
      error['birthCountry'] = undefined;
      setErrors(error)
    }
  };

  const onBirthPlaceChange = (event) => {
    let inputValue = event.target.value || '',
      placeErr = '';
    if (!inputValue) {
      setPlace('');
    } else if (!placeofbirthRegex.test(inputValue)) {
      placeErr = 'Please enter a Place of birth without spaces or characters like("-", "&", "@", etc)';
    } else {
      placeErr = undefined;
      setPlace(inputValue);
    }
    setPlaceOfBirthErr(placeErr);
  };

  const handleWealthSourceChange = (value) => {
    setWealthSource(value)
    let error = errors;
    if (value && value.length) {
      error['wealthSource'] = undefined;
      setErrors(error)
    }
  };

  const onRadioChange = (event) => {
    const { value } = event.target;
    setAnnualIncome(value);
  };

  const uploadSingleDocumentNri = (files) => {
    const postObj = new FormData();
    postObj.append('document_type', documentType);
    postObj.append('file', files);

    uploadDocumentNri(userId, postObj).then(() => {
      setDocumentSuccessNri({ status: true });
    });
  };


  const onBankAddNri = () => {
    getUserKycDataNri();
  };

  const onBankSubmitted = () => {
    setBankUpdated(true);
  };

  const checkboxHandleChange = (event) => {
    const { checked } = event.target;
    setChecked(checked);
    if (checked) {
      setEmailDeclaration(true);
      if (kycData.mobile) {
        setMobileDeclaration(true);
      }
      setDeclarationError(undefined);
    } else {
      setEmailDeclaration(false);
      setMobileDeclaration(false);
    }
    // onChange({ value, checked });
  };

  const validateNomineeDetails = (isSubmit) => {
    let isValid = true;
    nomineeDetails.forEach((item, index) => {
      nomineeErrors[index] = {};
      if(item.name) {
        if(!item.relation) {
          isValid = false;
          nomineeErrors[index].relation = "Relation is required"
        }
        if(!item.relation) {
          isValid = false;
          nomineeErrors[index].share = "Relation is required"
        }
      }
    })
    setNomineeErrors([...nomineeErrors]);
    if(getNomineeTotal() > 100) {
      setCommonNomineeError("Total of share for all nominee should be not be greater than 100%");
      isValid = false
    }
    if(isSubmit && getNomineeTotal() < 100) {
      setCommonNomineeError("Total of share for all nominee should be 100%");
      isValid = false
    }
    return isValid;
  };


  const handleNomineeDetailChange = (data) => {
    setNomineeDetails([...data]);
    nomineeErrors.forEach((err, index) => {
      if(err && err.relation) {
        if(data[index].relation) {
          err.relation = undefined
        }
      }
      if(err && err.share) {
        if(data[index].share) {
          err.share = undefined
        }
      }
    });
    setCommonNomineeError(undefined)
    setNomineeErrors([...nomineeErrors]);
  };

  const getNomineeTotal = () => {
    let shareCount = 0;
    nomineeDetails.forEach(item => {
      shareCount = shareCount + parseInt(item.share);
    });
    return shareCount;
  }

  const submitNomineeDetails = () => {
    let shareCount = 0;
    nomineeDetails.forEach(item => {
      shareCount = shareCount + item.share;
    });
    if(shareCount < 100) {
      setCommonNomineeError("Total of share for all nominee should be 100%");
    } else {
      saveNomineeDetails();
    }
  };

  const saveNomineeDetails = () => {
    let shareCount = 0;
    nomineeDetails.forEach(item => {
      shareCount = shareCount + parseInt(item.share);
    });
    if(shareCount > 100) {
      setCommonNomineeError("Total of share for all nominee should be not be greater than 100%");
    } else {
      for (let i in nomineeDetails) {
        if (nomineeDetails[i].id) {
          updateNomineeDetails(nomineeDetails[i])
        } else {
          addNomineeDetails(nomineeDetails[i]);
        }
      }
    }
  };

  const updateNomineeDetails = (data) => {
    if(data?.name) {
      let request = {
        name: data.name,
        relation: data.relation,
        share: parseInt(data.share),
        minor: false
      };
      updateNominee(userId, data.id, request).then(response => {
        setNomineeSubmited(true)
      })
    } else {
      setNomineeSubmited(true)
    }
  };

  const addNomineeDetails = (data) => {
    if(data?.name) {
      let request = {
        name: data.name,
        relation: data.relation,
        share: parseInt(data.share),
        minor: false
      }
      addNominee(userId, request).then(response => {
        setNomineeSubmited(true)
      })
    } else {
      setNomineeSubmited(true)
    }
  };

  const saveFormData = () => {
    const fatcaDetails = {
      income_source: wealthSource,
      income_slab: annualIncome,
      birth_place: placeOfBirth,
      birth_country: birthCountry,
    };
    const nriUserAddress = {
      address: nriAddress,
      city: cityName,
      state: stateName,
      pincode: pinCode,
    };
    if(validateNomineeDetails()) {
      let uD = {
        investor_type: kycData?.investor_type,
        first_name: kycData?.first_name,
        last_name: kycData?.last_name,
        pan: kycData?.pan,
        email: kycData?.email,
        mobile: kycData?.mobile,

        fatca_detail: fatcaDetails,
        mobile_declaration: mobileDeclaration ? 'self' : null,
        email_declaration: emailDeclaration ? 'self' : null,
      };
      if(Object.keys(checkAddressDetails(nriUserAddress)).length>0){
        uD["contact_address"]=checkAddressDetails(nriUserAddress);
      }
      setFatcaDataNri(userId, JSON.stringify(uD)).then((res) => {
        Toast.success(res.data.message);
        history.push('/home');
        onSubmit();
      });
      saveNomineeDetails()
    }
  };

  const validateData = () => {

    let errors = [];
    if (!wealthSource || wealthSource.length === 0) {
      errors['wealthSource'] = 'Wealth Source is required.';
    }
    if (!birthCountry || birthCountry.length === 0) {
      errors['birthCountry'] = 'Country of Birth is required.';
    }
    if (!placeOfBirth || placeOfBirth.length === 0) {
      setPlaceOfBirthErr('Place of Birth is required.');
    }
    setErrors(errors);
    let status = handleErrorsBankNri();
    if((errors && Object.keys(errors).length > 0) || !status) {
      return false;
    }
    return true;
  };

  const handleErrorsBankNri = () => {
    let status = childRef.current.validate()
    let status1 = childRefDoc.current.validateDoc()
    if(status && status1) {
      return true
    }
    return false;
  };



  const submitFormData = () => {
    const fatcaDetails = {
      income_source: wealthSource,
      income_slab: annualIncome,
      birth_place: placeOfBirth,
      birth_country: birthCountry,
    };
    const nriUserAddress = {
      address: nriAddress,
      city: cityName,
      state: stateName,
      pincode: pinCode,
    };
    let errStatus = validateData();
    let isNomineeValid = validateNomineeDetails(true);
    if (errStatus && isNomineeValid) {
      let uD = {
        investor_type: kycData?.investor_type,
        first_name: kycData?.first_name,
        last_name: kycData?.last_name,
        pan: kycData?.pan,
        email: kycData?.email,
        mobile: kycData?.mobile,
        fatca_detail: fatcaDetails,
        mobile_declaration: mobileDeclaration ? 'self' : null,
        email_declaration: emailDeclaration ? 'self' : null,
      };
      if(Object.keys(checkAddressDetails(nriUserAddress)).length>0){
        uD["contact_address"]=checkAddressDetails(nriUserAddress);
      }
      if (emailDeclaration) {
        updateFatcaDataNri(userId, JSON.stringify(uD)).then((res) => {
          Toast.success(res.data.message);
          // history.push('/home');
          setFatcaSubmited(true)
          onSubmit();
        });
        submitNomineeDetails()
      } else {
        // Toast.error(`Please confirm your Email ${kycData?.mobile ? 'and Mobile ' : ''}Declaration.`);
        setDeclarationError(`Please confirm your Email ${kycData?.mobile_number ? 'and Mobile ' : ''}Declaration.`);
      }
    }
  };

  const onSubmit = () => {
    if (kycData.bank_details.id) {
      childRef.current.updateNewBank();
    } else {
      childRef.current.addNewBank();
    }
  };

  return (
    <>
      <div className="col-sm-12">
        <InputBox id="onboarding-name" title={'Full Name'} value={kycData.pan_name} disabled />
      </div>
      <div className="col-sm-12 mt-3">
        <InputBox
          id="onboarding-address"
          title={'Indian Contact Address'}
          value={nriAddress}
          onChange={onNriAddressChange}
          type="text"
        
        />
      </div>
      <div className="d-flex">
        <div className=" rounded w-100 mx-3 my-3">
          <InputBox
            id="City"
            className="col-sm-12"
            labelClass="opacity-75 h6 py-2"
            onChange={onCityPlaceChange}
            title="City"
            value={cityName}
            type="text"
          />
        </div>
        <div className="rounded w-100 mx-3 my-3">
          <InputBox
            title="State"
            value={stateName}
            className="col-sm-12"
            labelClass="opacity-75 h6 py-2"
            id="countryBirth"
            onChange={onStateNameChange}
            type="text"
          />
        </div>
        <div className=" rounded w-100 mx-3 my-3">
          <InputBox
            id="pincode"
            className="col-sm-12"
            labelClass="opacity-75 h6 py-2"
            onChange={onPinCodeChange}
            title="Pincode"
            value={pinCode}
            inputMode={'numeric'}
            type="number"
          />
        </div>
      </div>
      <div className="col-sm-12 mt-3">
        <h6 className="font-weight-bold mt-4">Documents</h6>
        <DocumentUploadNri
          kycData={kycData}
          documentList={kycData}
          panStatus={pan_kyc_status}
          singleDocUpload={uploadSingleDocumentNri}
          successMessage={documentSuccessNri}
          title={""}
          ref={childRefDoc}
        />
      </div>
      <section className="col-sm-12">
        <h6 className="font-weight-bold mt-5">Bank Details</h6>
        <div className="border p-4 rounded">
          <NewBankNri
            alignment="center"
            ref={childRef}
            buttonText="Submit"
            bankDetail={kycData?.bank_details}
            onBankAdd={onBankAddNri}
            onBankSubmitted={onBankSubmitted}
          />
        </div>
      </section>
      <section className="col-sm-12">
        <h6 className="font-weight-bold mt-5">Details for FATCA</h6>
        <div className="border p-4 rounded w-100 my-3">
          <SelectBox
            title="Wealth Source"
            value={wealthSource}
            id="WealthSource"
            classes={{ root: 'col-sm-3 px-0 ' }}
            onUpdate={handleWealthSourceChange}
            options={wealthSourceListNri}
            placeholder="Select wealth source"
            err={errors && errors['wealthSource']}
          />
        </div>
        <div className="border p-4 rounded w-100 my-3">
          <RadioGroups
            title="Annual Income"
            id="annualIncome"
            radioList={AnnualIncomeListNri}
            selectedVal={annualIncome}
            onChange={onRadioChange}
          />
        </div>
        <div className="border p-4 rounded w-100 my-3">
          <InputBox
            id="PlaceofBirth"
            className="col-sm-5"
            labelClass="opacity-75 h6"
            onChange={onBirthPlaceChange}
            title="Place of Birth"
            value={placeOfBirth}
            type="text"
            maxLength={40}
            err={placeOfBirthErr}
          />
        </div>
        <div className="border p-4 rounded w-100 my-3">
          <NominationField nomineeDetailChange={handleNomineeDetailChange} errors={nomineeErrors} commonError={commonNomineeError} nomineeSubmited={nomineeSubmited} />
        </div>
        <div className="border p-4 rounded w-100 my-3">
          <SelectBox
            title="Country of Birth"
            value={birthCountry}
            id="countryBirth"
            classes={{ root: 'col-sm-3 px-0' }}
            onUpdate={handleBirthCountryChange}
            options={[{ label: 'India', value: 'India' }]}
            placeholder="Select country of birth"
            err={errors && errors['birthCountry']}
          />
        </div>
        <div className="border p-4 rounded w-100 my-3">
          <Form.Check>
            <Form.Check.Input id="declaration" type="checkbox" checked={checked} onChange={checkboxHandleChange} style={{ cursor: 'pointer' }} />
            <Form.Check.Label for="declaration" onChange={checkboxHandleChange} style={{ cursor: 'pointer' }}>
              {kycData?.mobile
                ? 'I confirm that mobile number ' + kycData?.mobile + ' and email ' + kycData?.email + ' belong to ' + kycData.pan_name
                : 'I confirm that the email id ' + kycData?.email + ' belong to ' + kycData.pan_name}
            </Form.Check.Label>
          </Form.Check>
          {declarationError && <div className={'invalid-feedback d-block mt-2'}>{declarationError}</div>}
        </div>
      </section>
      <div className=" btn-container text-center my-4  col-sm-12">
        <button type="button" className="btn btn-outline-primary m-2" onClick={saveFormData}>
          Save As Draft
        </button>
        <button type="button" className="btn btn-primary m-2 btn-width" onClick={submitFormData}>
          Submit
        </button>
      </div>
    </>
  );
};

export default NriOnboarding;
