import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import downloadIcon from '../../../assets/download.svg';
import Toast from '../../toast/Toast';
const DownloadBox = ({ id, title, value, url }) => {
  const preventDownload = (event) => {
    if (!url) {
      event.preventDefault();
      Toast.error('No file available for download');
      return false;
    }
  };
  return (
    <Form.Group>
      <Form.Label className="opacity-50 mb-1">{title}</Form.Label>
      <div className="file-field" id={id}>
        <Form.Control type="text" id={id} readOnly value={value} className={'updated-input font-weight-medium pr-5 pl-0'} />
        <a
          className="btn btn-sm waves-effect waves-light upload-container"
          download
          href={url}
          onClick={preventDownload}
          target="_blank"
          rel="noreferrer">
          <img src={downloadIcon} alt="upload icon" className="icon" />
        </a>
      </div>
    </Form.Group>
  );
};
DownloadBox.propTypes = {
  id: PropTypes.string,
  err: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
  url: PropTypes.string,
  onChange: PropTypes.func,
};
export default DownloadBox;
