import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getTransactionSuccess } from '../../api/transactions';
import AlertModal from '../../modal/AlertModal';
import { decryptData } from '../../utility/DecryptHelper';
import { formatPrice } from '../../utility/utility';
import Payment from './Payment';
import { useLocation } from 'react-router-dom';
import { updatePaymentData } from '../../redux/reducers/payment-reducer';
import './OrderComplete.scss';
const OrderComplete = () => {
  const userId = useSelector((state) => state.authReducer.user_id);
  const [transictions, setTrensaction] = useState([]);
  const [paymentData, setPaymentData] = useState({});
  const [status, setStatus] = useState();
  const { orders, mode } = useParams();
  const [alertModalData, setAlertModalData] = useState({ show: false });
  const location = useLocation();
  const dispatch = useDispatch();
  let data = location.state;
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    let searchParam = window.location.search;
    if (searchParam) {
      let statusValue = new URLSearchParams(searchParam).get('status');
      if (statusValue) {
        setStatus(statusValue.toLocaleUpperCase());
      }
    }
    if (mode && mode === 'RTGS') {
      setAlertModalData({
        show: true,
        message: 'If you have transferred money using NEFT/RTGS, it can take up-to 24 hours to update the transaction from Unpaid to Paid',
      });
    }
    if (data) {
      dispatch(updatePaymentData((data.payment_mode = null)));
      return setTrensaction([data]);
    } else {
      if (orders && orders.trim()) {
        getTransactionSuccess(userId, JSON.parse(atob(orders))).then((res) => {
          if (res && res.data) {
            const orderData = decryptData(res.data.data);
            setTrensaction(orderData.data);
            if (orderData.data) {
              dispatch(updatePaymentData(orderData.data[0]));
            }
            if (orderData && orderData.data && orderData.data.filter((item) => item.payment_status === 'Pending').length && mode !== 'RTGS') {
              setAlertModalData({
                show: true,
                message:
                  'If funds were debited from your account, please do not pay again. It can take upto 48 hours to receive the fund confirmation',
              });
            }
          }
        });
      }
    }
  }, []);
  // Pay now button payment process
  const showBankModal = (data) => {
    setPaymentData({ ...data, show: true });
  };
  const onpaymentCompleted = () => {
    setPaymentData({ show: false });
  };
  const handleAlertClose = () => {
    setAlertModalData({ show: false });
  };
  const getClass = (value) => {
    if (value) {
      return value.replace(' ', '').toLowerCase();
    }
    return '';
  };
  const getClassButton = (value) => {
    if (value) {
      let status = value.replace(' ', '').toLowerCase();
      if (status === 'success') {
        return 'btn-success';
      } else if (status === 'pending') {
        return 'btn-warning';
      } else if (status === 'failed') {
        return 'btn-danger';
      } else if (status === 'approved( upi )') {
        return 'btn-success';
      }
    }
  };

  return (
    <>
      {windowDimensions.width > 480 ? (
        <div className="order-complete-container container">
          <div className="order-complete-header p-4 colored-box border-none-bottom">
            <h3 className="font-weight-bold">Thank You!</h3>
            <p>Your purchase request has been received</p>
          </div>
          {transictions && transictions.length
            ? transictions.map((transItem) => (
                <div key={transItem.order_number}>
                  <div className="order-status-container py-4 bg-white p-4 colored-box border-none-top">
                    <div className="pr-4 pl-2 pb-3 font-weight-bold justify-content-between">
                      <span className="c-blue px-0 ml-0">{transItem.scheme_name}</span>
                      <span className="float-right px-0 mx-0">&#8377; {formatPrice(transItem.amount)}</span>
                    </div>
                    <div className="row  px-4 pb-3 border-bottom justify-content-between font-weight-bold">
                      <span>
                        {' '}
                        Type: <span className="ml-1">{transItem.type}</span>
                      </span>
                      <span className="text-right">
                        Status:{' '}
                        <span className={`ml-1  ${getClass(transItem.payment_status || status || 'Success')}`}>
                          {transItem.payment_status === 'APPROVED ( DIRECT )' ? 'APPROVED' : transItem.payment_status || status || 'SUCCESS'}
                        </span>
                      </span>
                    </div>
                    <div className="py-4">
                      <div className="list-item-wrapper pb-3 d-flex">
                        <div className="list-line"></div>
                        <div className="list-item-section col-sm-9 pl-0">
                          <div className={`list-bullet ${getClass(transItem.payment)}`}></div>
                          <div className="list-text">Mutual Fund Payment</div>
                          {transItem.payment === 'In progress' && (
                            <button
                              className="btn btn-success ml-2 py-1"
                              onClick={() => {
                                showBankModal(transItem);
                              }}>
                              Pay Now
                            </button>
                          )}
                        </div>
                        <div className="btn-section">
                          <span className={`list-status ${getClass(transItem.payment)}`}>{transItem.payment}</span>
                        </div>
                      </div>
                      <div className="list-item-wrapper pb-3 d-flex">
                        <div className="list-line"></div>
                        <div className="list-item-section col-sm-9 pl-0">
                          <div className={`list-bullet ${getClass(transItem.order_placed)}`}></div>
                          <div className="list-text">Order Placed with AMC</div>
                        </div>
                        <div className="btn-section">
                          <span className={`list-status ${getClass(transItem.order_placed)}`}>{transItem.order_placed}</span>
                        </div>
                      </div>
                      <div className="list-item-wrapper pb-3 d-flex">
                        <div className="list-item-section col-sm-9 pl-0">
                          <div className={`list-bullet ${getClass(transItem.units_alloted)}`}></div>
                          <div className="list-text">Units Allotted</div>
                        </div>
                        <div className="btn-section">
                          <span className={`list-status ${getClass(transItem.units_alloted)}`}>{transItem.units_alloted}</span>
                        </div>
                      </div>
                    </div>

                    {transItem.payment === 'In progress' && (
                      <div className="opacity-75 small font-italic">
                        *Use the Pay Now button only if the funds have not been debited from your account.
                      </div>
                    )}
                  </div>
                </div>
              ))
            : null}
        </div>
      ) : (
        <div className="py-4 bg-light-grey">
          <div className="order-complete-header m-4 p-4 colored-box">
            <h3 className="font-weight-bold">Thank You!</h3>
            <p>Your purchase request has been received</p>
          </div>
          {transictions && transictions.length
            ? transictions.map((transItem) => (
                <div key={transItem.order_number}>
                  <div className="order-status-container  bg-white m-4 p-4 colored-box">
                    <div className="d-flex pb-3">
                      <h5 className="c-black px-0 font-weight-bold">{transItem.scheme_name}</h5>
                      <span className="text-right">
                        <div className="btn-section ">
                          <span className={`btn  br-light ${getClassButton(transItem.payment_status)}`}>
                            {transItem.payment_status === 'APPROVED ( DIRECT )' ? 'APPROVED' : transItem.payment_status || status || 'SUCCESS'}
                          </span>
                        </div>
                      </span>
                    </div>
                    <div className="row px-4 pb-3 justify-content-between font-weight-bold">
                      <div>
                        <span className="ml-0 opacity-50">Amount :</span>
                        <span className="ml-1">&#8377; {formatPrice(transItem.amount)}</span>
                      </div>
                      <div>
                        <span className="ml-0 opacity-50">Type: </span>
                        <span className="ml-1">{transItem.type}</span>
                      </div>
                    </div>
                    <hr></hr>
                    <div>
                      <div className="list-item-wrapper pb-3">
                        <div className="list-line"></div>
                        <div className="list-item-section col-sm-9 pl-0">
                          <div className={`list-bullet ${getClass(transItem.payment)}`}></div>
                          <div className="list-text">Mutual Fund Payment</div>
                          <span style={{ marginLeft: '6px' }} className={`font-italic ${getClass(transItem.payment)}`}>
                            ({transItem.payment})
                          </span>
                          {transItem.payment === 'In progress' && (
                            <button
                              className="btn btn-primary ml-2 py-1 br-light py-1"
                              onClick={() => {
                                showBankModal(transItem);
                              }}>
                              Pay Now
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="list-item-wrapper pb-3">
                        <div className="list-line"></div>
                        <div className="list-item-section col-sm-9 pl-0">
                          <div className={`list-bullet ${getClass(transItem.order_placed)}`}></div>
                          <div className="list-text">Order Placed with AMC</div>
                          <span style={{ marginLeft: '6px' }} className={`font-italic ${getClass(transItem.order_placed)}`}>
                            ({transItem.order_placed})
                          </span>
                        </div>
                      </div>
                      <div className="list-item-wrapper pb-3">
                        <div className="list-item-section  pl-0">
                          <div className={`list-bullet ${getClass(transItem.units_alloted)}`}></div>
                          <div className="list-text">Units Allotted</div>
                          <span style={{ marginLeft: '6px' }} className={`font-italic ${getClass(transItem.units_alloted)}`}>
                            ({transItem.units_alloted})
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {transItem.payment === 'In progress' && (
                    <div className="opacity-75 small font-italic">
                      *Use the Pay Now button only if the funds have not been debited from your account.
                    </div>
                  )}
                </div>
              ))
            : null}
        </div>
      )}

      {paymentData.show ? (
        <Payment
          onpaymentCompleted={onpaymentCompleted}
          show={paymentData.show}
          userId={userId}
          totalAmount={paymentData.amount}
          orders={[paymentData.transaction_id]}
        />
      ) : null}
      {alertModalData.show && <AlertModal show={alertModalData.show} message={alertModalData.message} onClose={handleAlertClose} />}
    </>
  );
};
export default OrderComplete;
