import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DropdownList, InputBox } from '../form';
import { Form } from 'react-bootstrap';
import { getYearOption } from '../../utility/utility';
const NetWorth = ({ onNetWorthChange, onNetWorthInrChange, networthObj, amount, err }) => {
  const [networthInr, setInrData] = useState('');
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const yearOption = getYearOption()
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    setInrData(amount);
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [amount]);
  const onNetWorthhandler = (event) => {
    const { value } = event.target;
    setInrData(value);
    if (onNetWorthInrChange) {
      onNetWorthInrChange(value);
    }
  };
  return (
    <>
      {windowDimensions.width > 480 ? (
        <>
          <div className="border p-4 rounded w-100 my-3 ">
            <div className={'d-flex align-items-center'}>
              <span>Net worth as on 31st March</span>
              <Form.Group className="mx-3 mb-0 col-sm-2">
                <Form.Label className="opacity-50 mb-3" srOnly>
                  year
                </Form.Label>
                <DropdownList
                  selectedOption={networthObj.value}
                  id="net-worth"
                  classes={{ input: 'font-weight-medium form-control' }}
                  controlFunc={onNetWorthChange}
                  options={yearOption}
                />
              </Form.Group>
              <span className="mr-3">INR</span>
              <InputBox
                id="onboarding-networth"
                containerClass="mb-0"
                onChange={onNetWorthhandler}
                title="Address of the entity"
                value={networthInr}
                srOnly={true}
              />
            </div>
            {err && <div className={'invalid-feedback d-block'}>{err}</div>}
          </div>
        </>
      ) : (
        <div className=" py-2 rounded w-100 my-3  ">
          <div className="d-flex">
            <span className="ml-0 mt-3 font-weight-medium">Net worth as on 31st March</span>
            <Form.Group className="my-3 mb-0 ml-4 w-30 float-right ">
              <Form.Label className="opacity-50 mb-3" srOnly>
                year
              </Form.Label>
              <DropdownList
                selectedOption={networthObj.value}
                id="net-worth"
                classes={{ input: 'font-weight-medium form-control bg-light-navy br-light' }}
                controlFunc={onNetWorthChange}
                options={yearOption}
              />
            </Form.Group>
          </div>
          <span className="ml-0 opacity-50">INR</span>
          <InputBox
            id="onboarding-networth"
            containerClass="mb-0 mt-0"
            onChange={onNetWorthhandler}
            title="Address of the entity"
            value={networthInr}
            srOnly={true}
          />
          {err && <div className={'invalid-feedback d-block'}>{err}</div>}
        </div>
      )}
    </>
  );
};
NetWorth.propTypes = {
  onNetWorthChange: PropTypes.func,
  onNetWorthInrChange: PropTypes.func,
  networthObj: PropTypes.object,
  amount: PropTypes.string,
};
export default NetWorth;
