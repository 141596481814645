import { useState } from 'react';
import { useDebounce } from './useDebounce';

type Tab = 'Mutual Funds' | 'Deposits';
type DateValues = Date | null | undefined;
type Comparator = 'VALIDATE_FROM_DATE' | 'VALIDATE_TO_DATE';
enum DatesInputTypeEnum {
  from_date = 'VALIDATE_FROM_DATE',
  to_date = 'VALIDATE_TO_DATE',
}

const validateFromDatesInput = (
  inputDate: DateValues,
  baseDate: DateValues,
  comparator: Comparator
) => {
  if (baseDate && inputDate) {
    if (comparator === 'VALIDATE_FROM_DATE' && inputDate > baseDate) {
      return 'From Date should not be greater than To date!';
    }
    if (comparator === 'VALIDATE_TO_DATE' && inputDate < baseDate) {
      return 'To Date should not be less than From Date!';
    }
  } else {
    return null;
  }
};

export const useFiltersForTransactions = () => {
  const [mfSearchvalue, setMfSearchValue] = useState<string>('');
  const [fdSearchValue, setFdSearchValue] = useState<string>('');
  const [datesErrorMsg, setDatesErrorMsg] = useState<string | null>(null);
  const [mfFromDate, setMfFromDate] = useState<DateValues>(null);
  const [fdFromDate, setFdFromDate] = useState<DateValues>(null);
  const [mfToDate, setMfToDate] = useState<DateValues>(null);
  const [fdToDate, setFdToDate] = useState<DateValues>(null);

  const fromDateHandler = (date: any, activeTab: Tab) => {
    if (activeTab === 'Mutual Funds') {
      setMfFromDate(date);
      setDatesErrorMsg(
        validateFromDatesInput(date, mfFromDate, DatesInputTypeEnum.from_date)!
      );
    }
    if (activeTab === 'Deposits') {
      setFdFromDate(date);
      setDatesErrorMsg(
        validateFromDatesInput(date, fdFromDate, DatesInputTypeEnum.from_date)!
      );
    }
  };

  const toDateHandler = (date: any, activeTab: Tab) => {
    if (activeTab === 'Mutual Funds') {
      setMfToDate(date);
      setDatesErrorMsg(
        validateFromDatesInput(date, mfFromDate, DatesInputTypeEnum.to_date)!
      );
    }
    if (activeTab === 'Deposits') {
      setFdToDate(date);
      console.log(date, fdToDate);

      setDatesErrorMsg(
        validateFromDatesInput(date, fdFromDate, DatesInputTypeEnum.to_date)!
      );
    }
  };

  //   Debounced value for search input

  const debounced_mf_search_value = useDebounce(mfSearchvalue);
  const debounced_fd_search_value = useDebounce(fdSearchValue);

  const searchInputHandler = (inputString: any, activeTab: Tab) => {
    if (activeTab === 'Mutual Funds') {
      setMfSearchValue(inputString);
    }

    if (activeTab === 'Deposits') {
      setFdSearchValue(inputString);
    }
  };

  return {
    mfSearchvalue,
    fdSearchValue,
    datesErrorMsg,
    mfFromDate,
    fdFromDate,
    mfToDate,
    fdToDate,
    debounced_mf_search_value,
    debounced_fd_search_value,
    searchInputHandler,
    fromDateHandler,
    toDateHandler,
  };
};
