import axios from './axios';
import elevo_apis from './elevo_apis';

const getAllSips = (userID,limit,offset) => {
  return axios.get(`transactions/my-sip/${userID}/?limit=${limit}&offset=${offset}`);
};
const stopSelectedSip = (data,transactionId) => {
  return elevo_apis.put(`sips/${transactionId}`,data);
};

export { getAllSips, stopSelectedSip };
