import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { InputBox, UploadBox } from '../common/form';
import { getPanAndAddresses, uploadPanAndAddresses, updatePanAndAddresses } from '../api/kyc';
import { decryptData } from '../utility/DecryptHelper';
import Toast from '../common/toast/Toast';
import { useSelector } from 'react-redux';
const DocUploadModal = ({ show, title, onSubmit }) => {
  const [docNumbers, setDocNumber] = useState(1);
  const [uploadedFiles, setuploadedFiles] = useState({});
  const [uploadData, setUploadData] = useState(false)
  const userId = useSelector((state) => state.authReducer.user_id);
  useEffect(() => {
    getPanAndAddresses().then((res) => {
      if (res && res.data) {
        const docsData = decryptData(res.data.data);
         if (docsData && docsData.data && docsData.data.min_members) {
           if(docsData.data.proof_list.length>0){
            setUploadData(true)
            setDocNumber(docsData.data.proof_list.length);   
           }else {
            setDocNumber(docsData.data.min_members);
           }
          const tempObj = {};
          docsData.data.proof_list.forEach((item, index) => {
            const count = index + 1;
            tempObj['pan_' + count] = item.pan_card;
            tempObj['address_' + count] = item.address_proof;
          });
          setuploadedFiles(tempObj);
        }
      }
    });
  }, []);
  const onNumberChange = (event) => {
    let va = event.target.value;
    if (va) {
      if (va > 5) {
        setDocNumber(5);
      } else if (va < 1) {
        setDocNumber(1);
      } else {
        setDocNumber(va);
      }
    } else {
      setDocNumber('');
    }
  };
  const onUploadDoc = (name, file) => {
    setuploadedFiles({ ...uploadedFiles, [name]: file });
  };
  const onDataUpload = () => {
    if (docNumbers >= 1 && docNumbers <= 5) {
      const postObj = new FormData();
      postObj.append('members', docNumbers);
      Object.keys(uploadedFiles).forEach((key) => {
        if (uploadedFiles[key]!==undefined){
          postObj.append(key, uploadedFiles[key]);
        }
      });
      uploadPanAndAddresses(postObj).then((res) => {
        if (res && res.data && res.data.status && res.data.status.code === 1) {
          setUploadData(true)
          onSubmit();
        }
      });
    } else {
      Toast.error('Number of docs should be in between 1 and 5');
    }
  };
  const onDataUpdate = () => {
    if (docNumbers >= 1 && docNumbers <= 5) {
      const postObj = new FormData();
      postObj.append('members', docNumbers);
      Object.keys(uploadedFiles).forEach((key) => {
        if (uploadedFiles[key]!==undefined){
          postObj.append(key, uploadedFiles[key]);
        }
      });
      updatePanAndAddresses(userId, postObj).then((res) => {
        if (res && res.data && res.data.status && res.data.status.code === 1) {
          onSubmit();
        }
      });
    } else {
      Toast.error('Number of docs should be in between 1 and 5');
    }
  };
   const onDataSubmit = () =>{
     if(uploadData){
       onDataUpdate()
     }else{
       onDataUpload()
     }
   };
  const getFileName = (type) => {
    if (uploadedFiles[type] && typeof uploadedFiles[type] === 'object') {
      return uploadedFiles[type].name;
    }
    return uploadedFiles[type];
  };
  return (
    <Modal show={show} size="lg" aria-labelledby="kyc-verified-modal" onHide={onSubmit} centered backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <div className="d-flex align-items-baseline">
          <span className="mr-3 font-weight-bold">{title}</span>
          <InputBox
            id="onboarding-networth"
            className="col-sm-2"
            onChange={onNumberChange}
            title="Address of the entity"
            value={docNumbers.toString()}
            srOnly={true}
          />
        </div>
      </Modal.Header>
      <Modal.Body className="small mx-3">
        {Array.apply(null, { length: docNumbers }).map((value, index) => (
          <div className="row align-items-center" key={'document-' + index + '-' + value}>
            <div className="col-sm-1">{index + 1}.</div>
            <div className="col-sm-5">
              <UploadBox
                deleteIcon={true}
                id={'UploadPANCard-' + index + 1}
                title="Upload PAN Card"
                value={getFileName(`pan_${index + 1}`)}
                accept={['pdf', 'jpg', 'jpeg', 'png', 'doc']}
                onChange={(file) => {
                  const count = index + 1;
                  onUploadDoc('pan_' + count, file);
                }}
              />
            </div>
            <div className="col-sm-5">
              <UploadBox
                deleteIcon={true}
                id={'UploadAddressproof-' + index + 1}
                title="Upload Address proof"
                value={getFileName(`address_${index + 1}`)}
                accept={['pdf', 'jpg', 'jpeg', 'png', 'doc']}
                onChange={(file) => {
                  const count = +index + 1;
                  onUploadDoc('address_' + count, file);
                }}
              />
            </div>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer className="btn-container mb-3 justify-content-center">
        <Button onClick={onDataSubmit} className="btn btn-primary">
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
DocUploadModal.propTypes = {
  show: PropTypes.bool,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
};
export default DocUploadModal;
