import elevo_apis, { elevoApiClient } from './elevo_apis';
import Toast from '../common/toast/Toast';

const getQuestion = (userId) => {
  return elevo_apis.get(`/investors/${userId}/risk-assessment-questions`);
};

const submitAssessment = (userId, req) => {
  return elevo_apis.post(`/investors/${userId}/risk-assessment`, req);
};

const getAssessmentreport = (userId) => {
  const makeApiCall = elevoApiClient(riskManagmentErrorHandler);
  return makeApiCall.get(`/investors/${userId}/risk-assessment`)
    .then((res) => res)
    .catch((error) => {
      console.error(error);
    });
};

function riskManagmentErrorHandler(error) {
  if (error?.response?.status === 400 && error.response?.data.message !== 'Risk assessment report does not exist') {
    Toast.error(error.response?.data.message);
  }
}

export {getQuestion, submitAssessment, getAssessmentreport };