import './Amount.scss';

interface Props {
  value: number;
  className?: string;
  options?: Intl.NumberFormatOptions;
}

export default function Amount({ value, className, options }: Props) {
  return (
    <span className={`Amount ${className}`}>
      {new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        ...(options || {}),
      })
        .format(value)
        .replace('₹', '₹ ')}
    </span>
  );
}
