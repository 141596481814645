import { Modal } from 'react-bootstrap';
import './DownloadCASInstructionModal.scss';
import { ReactComponent as IonicIosGlobe } from '../../../assets/ionic-ios-globe.svg';
import { ReactComponent as IonicMdPaper } from '../../../assets/ionic-md-paper.svg';
import { ReactComponent as ResumeIcon } from '../../../assets/resume.svg';
import { formatDateToLongFormat } from '../../../utility/utility';

interface Props {
  show: boolean;
  onHide: () => void;
  additionalData: {
    [key: string]: any;
  };
}

// JSX component
const DownloadCASPdfLink =
  'https://www.camsonline.com/Investors/Statements/Consolidated-Account-Statement';

export const DownloadCASInstructionModal = ({
  show,
  onHide,
  additionalData,
}: Props) => {
  const date = new Date();
  const startDate = formatDateToLongFormat('1990-01-01');
  const currentDate = formatDateToLongFormat(date.toDateString());

  const userEmail = additionalData?.email;
  const userPan = additionalData?.pan;

  return (
    <Modal show={show} onHide={onHide} size='lg' centered backdrop='static'>
      <Modal.Header closeButton className='DownloadCASModal__header'>
        Steps to get the Consolidated Account Statement (CAS)
      </Modal.Header>
      <Modal.Body>
        <ol className='timeline'>
          <li className='timeline-item'>
            <span className='timeline-item-icon'>
              <IonicIosGlobe />
            </span>
            <div className='timeline-item-description'>
              <span className='timeline-item-heading'>
                Visit{' '}
                <a
                  href={DownloadCASPdfLink}
                  target='_blank'
                  rel='noreferrer'
                  className='DownloadCASModal__link'
                >
                  CAMS Website
                </a>
              </span>
            </div>
          </li>
          <li className='timeline-item'>
            <span className='timeline-item-icon'>
              <IonicMdPaper />
            </span>
            <div className='timeline-item-description'>
              <span className='timeline-item-heading'>
                Enter the details and password as listed below and click on
                “Submit”
              </span>
              <div className='two-col-grid-layout'>
                <span className='timeline-sub-item-key'>StateMent Type</span>
                <span className='timeline-sub-item-value'>Detailed</span>
                <span className='timeline-sub-item-key'>Period</span>
                <span className='timeline-sub-item-value'>Specific Period</span>
                <span className='timeline-sub-item-key'>From Date</span>
                <span className='timeline-sub-item-value'>{startDate}</span>
                <span className='timeline-sub-item-key'>To Date</span>
                <span className='timeline-sub-item-value'>{currentDate}</span>
                <span className='timeline-sub-item-key'>Folio Listing</span>
                <span className='timeline-sub-item-value'>
                  With Zero Balance Folios
                </span>
                <span className='timeline-sub-item-key'>Email</span>
                <span className='timeline-sub-item-value'>{userEmail}</span>
                <span className='timeline-sub-item-key'>PAN</span>
                <span className='timeline-sub-item-value'>{userPan}</span>
                <span className='timeline-sub-item-key'>Password</span>
                <span className='timeline-sub-item-value'>{userPan}</span>
              </div>
            </div>
          </li>
          <li className='timeline-item'>
            <span className='timeline-item-icon'>
              <ResumeIcon />
            </span>
            <div className='timeline-item-description'>
              <span className='timeline-item-heading'>
                Once you receive the email from <b>donotreply@camsonline.com</b>
                &nbsp;you can upload your CAS.
              </span>
            </div>
          </li>
        </ol>
      </Modal.Body>
    </Modal>
  );
};
