import { IssuerOut } from '../../api/fdApi/services/issuer.service';
import { useMedia } from '../../hooks/useMedia';
import { useAppSelector } from '../../redux/hooks';
import { constructFdAppURL } from '../../utility/utility';
import { FDRow } from './FDRow';
import './FixedDeposits.scss';
interface Props {
  issuers: IssuerOut[];
}
const FixedDepositsHeadersFieldsJSX = (
  <>
    <div className='FixedDeposits__gridCell d-flex align-items-center'>
      Interest Rate
    </div>
    <div className='FixedDeposits__gridCell d-flex align-items-center'>
      Tenure
    </div>
    <div className='FixedDeposits__gridCell d-flex align-items-center'>
      Min. Deposit
    </div>
    <div className='FixedDeposits__gridCell d-flex align-items-center'>
      Max. Deposit
    </div>
    <div className='FixedDeposits__gridCell d-flex align-items-center'></div>
  </>
);

export const FixedDeposits = ({ issuers }: Props) => {
  const { fdAppURL } = useAppSelector((state) => state.authReducer);
  const isMobile = useMedia(['(min-width: 992px)'], [false], true);

  return (
    <div className='FixedDeposits bg-white'>
      <div className='FixedDeposits__header d-flex align-items-center justify-content-between'>
        <div className='text-lg leading-tight'>Top Deposits</div>
        <a
          href={constructFdAppURL(`${fdAppURL}`, {
            activeTab: 'Deposits',
          })}
          className='FixedDeposits__exploreAll cursor-pointer text-xs leading-tight text-blue-500 transition hover:opacity-50'
        >
          Explore All
        </a>
      </div>
      {!isMobile ? (
        <div className='FixedDeposits__grid d-grid'>
          <div className='d-flex align-items-center'>Deposit Name</div>
          {FixedDepositsHeadersFieldsJSX}
        </div>
      ) : null}
      {isMobile && issuers
        ? issuers?.map((f) => (
            <a href={`${fdAppURL}/issuers/${f.id}`} className='cursor-pointer'>
              <FDRow issuer={f} key={f.id} isMobile={isMobile} />
            </a>
          ))
        : issuers?.map((f) => (
            <FDRow issuer={f} key={f.id} isMobile={isMobile} />
          ))}
    </div>
  );
};
