import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Modal } from 'react-bootstrap';
import { BrowserView, MobileView } from 'react-device-detect';
import OTPInputComponent from '../component/otp-input-component';
import OTPTimer from '../component/otp-component/Timer';
import { resendOTP, verifyOTP } from '../api/otp';
import { getNominee } from '../api/nominee';
import { setOtpId } from '../redux/reducers/header-reducer';

const OTPVerificationModal = ({ show, onSubmit, otpId, payment, setShowOTPModal }) => {
  const userId = useSelector((state) => state.authReducer.user_id);
  const dispatch = useDispatch();
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [, setNominee] = useState([]);
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const [otp, setOTP] = useState('');
  const [submitBtnState, setBtnState] = useState(true);
  const [apiError, setApiError] = useState('');
  const [showTimer, setShowTimer] = useState(true);

  const otpChange = (event) => {
    let otp = event || '',
      submitBtnState = false;
    const pattern = /^([0-9]{0,6})?$/;
    setApiError('');
    if (!otp) {
      submitBtnState = false;
    } else if (!pattern.test(otp)) {
      submitBtnState = true;
    } else {
      submitBtnState = false;
    }
    setBtnState(submitBtnState);
    setOTP(otp);
  };

  const verify = () => {
    if (otp === '' || otp === undefined) {
      setApiError('otp is required');
    } else if (otp.length !== 6) {
      setApiError('otp must be 6 digit');
    } else {
      setApiError('');
    }
    if (otpId && otp) {
      let data = {
        otp: otp,
        otp_type: payment ? 'cart' : 'nominee',
      };
      verifyOTP(data, otpId).then((res) => {
        onSubmit();
        if (payment) {
          localStorage.removeItem('otpIdOrder');
          localStorage.removeItem('otpId');
          dispatch(setOtpId(''));
        }
      });
    }
  };

  const resendOtp = (voice) => {
    if (otpId) {
      resendOTP(otpId, voice).then((res) => {
        setShowTimer(true);
      });
    }
  };
  const getNominees = () => {
    getNominee(userId)
      .then((response) => {
        setNominee(response.data.results);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    getNominees();
  }, []);

  return (
    <Modal
      show={show}
      size="md"
      className={windowDimensions.width > 480 ? null : 'p-4'}
      aria-labelledby="add-fund-modal"
      onHide={() => setShowOTPModal(false)}
      centered
      backdrop="static"
      keyboard={false}>
      <Modal.Header className="pb-0 " closeButton></Modal.Header>
      <Modal.Body className="pt-0">
        <Form.Group className="my-3">
          <BrowserView>
            <div className={windowDimensions.width > 480 ? 'p-5 colored-box' : 'colored-box'}>
              {/* <HeaderLogo />   */}
              <div className="h4 font-weight-bold text-center">Enter OTP</div>
              <p className="text-center mt-3">Please enter the OTP received on your registered Mobile Number/Email ID</p>
              <OTPInputComponent
                autoFocus={true}
                isNumberInput
                length={6}
                className="mt-5 d-flex"
                inputClassName={windowDimensions.width > 480 ? 'form-control otp-input' : 'form-control otp-mobile otp-input'}
                onChangeOTP={otpChange}
              />
              <label className={'font-weight-medium  ' + (apiError ? 'error-message' : '')}>{apiError}</label>
              {payment && (
                <div className="h6 font-weight-bold text-center" style={{ marginTop: '20px' }}>
                  By entering this OTP you confirm to place the order present in cart
                </div>
              )}
              <div className="mt-5 d-flex justify-content-between align-items-center">
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    {showTimer ? <OTPTimer className="enabled" initialSeconds={payment ? '60' : '30'} onComplete={setShowTimer}></OTPTimer> : ''}
                    &nbsp;&nbsp;
                    <button
                      type="button"
                      className={showTimer ? 'btn link-disabled' : 'btn link-enabled'}
                      style={showTimer ? { pointerEvents: 'none' } : null}
                      onClick={() => resendOtp(false)}>
                      Resend OTP
                    </button>
                  </div>
                </div>

                <button type="button" data-cy="submitOtpButton" className="btn btn-primary" onClick={verify} disabled={submitBtnState}>
                  {payment ? 'PROCEED' : 'Verify'}
                </button>
              </div>
            </div>
          </BrowserView>
          <MobileView>
            <div className="ml-2 mr-2">
              <div className="h4 font-weight-bold text-center">Enter OTP</div>
              <p className="text-center mt-3 opacity-75">Please enter the OTP received on your registered Mobile Number/Email ID</p>
              <OTPInputComponent
                autoFocus={true}
                isNumberInput
                length={6}
                className="mt-5 d-flex"
                inputClassName="otp-mobile  form-control otp-input"
                onChangeOTP={otpChange}
              />
              {payment && (
                <div className="h6 font-weight-bold text-center" style={{ marginTop: '20px' }}>
                  By entering this OTP you confirm to place the order present in cart
                </div>
              )}
              <label className={'font-weight-medium ' + (setApiError ? 'is-invalid' : '')}>{apiError}</label>
              <div>
                <div className="mt-2 mb-2 d-flex justify-content-between align-items-center">
                  <button
                    type="button"
                    className={showTimer ? 'btn link-disabled ' : 'btn link-enabled '}
                    style={showTimer ? { pointerEvents: 'none' } : null}
                    onClick={() => resendOtp(false)}>
                    Resend OTP
                  </button>
                  {showTimer ? <OTPTimer className="enabled" initialSeconds="30" onComplete={setShowTimer}></OTPTimer> : ''}
                </div>
              </div>
              <button type="button" data-cy="submitOtpButton" className="btn btn-primary w-100" onClick={verify} disabled={submitBtnState}>
                {payment ? 'PROCEED' : 'Verify'}
              </button>
            </div>
          </MobileView>
        </Form.Group>
      </Modal.Body>
    </Modal>
  );
};
OTPVerificationModal.propTypes = {
  show: PropTypes.bool,
  otpId: PropTypes.number,
  onSubmit: PropTypes.func,
  saveAsDraft: PropTypes.func,
};
export default OTPVerificationModal;
