import React, { useEffect, useState } from 'react';
import Stepper from './Stepper';
import {FiChevronLeft, FiChevronRight} from 'react-icons/fi'
import Options from './Options';
import { getQuestion, submitAssessment } from '../../../../../api/assessment';
import { useSelector } from 'react-redux';
import Toast from '../../../../../common/toast/Toast';

const Assessment = ( { onSubmit } ) => {
  const userId = useSelector((state) => state.authReducer.user_id);
  const [step, setStep] = useState(0);
  const [questions, setQuestions] = useState();
  const [answerJson, setAnswerJson] = useState([]);
  const [errors, setErrors] = useState({});

  const handleNext = () => {
    if(step === 9) {
      if (validate()) {
        const req = {
          answers: answerJson
        }
        submitAssessment(userId, req).then(response => {
          onSubmit()
        }).catch(error => {
          Toast.error(error?.response?.data?.message || 'Failed to submit assessment')
        })
      }
    } else {
      if (validate()) {
        setStep(step + 1);
      }
    }
  };

  const validate = () => {
    let isValid = true;
    const ansJson = answerJson[step];
    if(step === 0 || step === 4) {
      if(!ansJson || !ansJson.options || ansJson?.options?.length < 2) {
        isValid = false;
        errors[step] = "Please select an option from each categories."
      } else if((ansJson?.options && !ansJson.options[0]) || (ansJson?.options && !ansJson.options[1])) {
        isValid = false;
        errors[step] = "Please select an option from each categories."
      }
    }
    if(step === 1) {
      if(!ansJson || !ansJson.options || ansJson?.options?.length === 0) {
        isValid = false;
        errors[step] = "Please select at least one option."
      } else if (!ansJson || !ansJson.options || ansJson?.inputs?.length === 0 || !ansJson?.inputs[0].value || ansJson?.inputs[0].value.length === 0) {
        isValid = false;
        errors[step] = "Please enter amount."
      }
    }
    if(step === 2 || step === 3 || step === 5 || step === 6 || step === 8 || step === 9) {
      if(!ansJson || !ansJson.options || ansJson?.options?.length === 0) {
        isValid = false;
        errors[step] = "Please select an option."
      }
    }
    if(step === 7) {
      if(!ansJson || !ansJson.options || ansJson?.options?.length === 0) {
        isValid = false;
        errors[step] = "Please add at least one goal."
      } else {
        let opts = ansJson?.options;
        opts.forEach(op => {
          if(op?.inputs && op.inputs[2] && (!op.inputs[2]?.value || op.inputs[2]?.value?.trim()?.length === 0)) {
            isValid = false;
            errors[step] = "Please enter other goal."
          }
        })
      }
    }
    setErrors({...errors});
    return isValid;
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  useEffect(() => {
    getQuestionList()
  }, []);

  const getQuestionList = () => {
    getQuestion(userId).then(response => {
      setQuestions(response.data)
    })
  };

  const updateAnswerJson = (data) => {
    answerJson[step] = data;
    setAnswerJson([...answerJson])
    errors[step] = undefined
    setErrors({...errors})
  };

  return (
    <>
      <div className={'section_title'}>Risk Assessment</div>
      <div className={'assessment-progress-card'}>
        <div className={'text-center w-100'}>
          <div className={'assessment-test-title mb-4'}>Investment risk tolerance test</div>
          <Stepper currentStep={step} questions={questions} />
        </div>
      </div>
      {questions &&
        <div className={'assessment-questions-card'}>
          <div className={'question-title'}>{questions[step].question}</div>
          <Options currentStep={step} question={questions[step]} updateAnswerJson={updateAnswerJson} errors={errors} value={answerJson[step]} />
          <div className={"assessment-actions d-flex mt-5"}>
            <button className={"assessment-btn primary outlined"} disabled={step === 0} onClick={handlePrevious}>
              <FiChevronLeft style={{ height: '16px', width: '16px' }}/> previous
            </button>
            <button className={"assessment-btn primary ml-auto"} onClick={handleNext}>
              {step === 9 ? 'submit' : <>next <FiChevronRight style={{ height: '16px', width: '16px' }}/> </>}
            </button>
          </div>
        </div>
      }
    </>
  );
};

export default Assessment;
