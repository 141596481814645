import React, { useState, useEffect } from 'react';
import PageHeader from '../../common/page-header/PageHeader';
import { formatDate, formatPrice, formatNumber, handleFileDownload, regexString } from '../../utility/utility';
import { Button, Dropdown, Form, FormControl, InputGroup } from 'react-bootstrap';
import { decryptData } from '../../utility/DecryptHelper';
import { getUserTransictions } from '../../api/nav';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { exportTransiction } from '../../api/transactions';
import downloadIcon from '../../assets/download.svg';
import DataMsg from '../../common/data-msg/DataMsg';
import { DropdownList } from '../../common/form';
import serachIcon from '../../assets/search.svg';
import DatePicker from 'react-datepicker';
import closeIcon from '../../assets/close_without-circle.svg';
import Payment from '../order/Payment';

const Transactions = () => {
  const userId = useSelector((state) => state.authReducer.user_id);
  const [limit] = useState(5)
  const [transactions, setTransaction] = useState([]);
  const [transTypeObj, setTransactionVal] = useState({ value: 'all', label: 'All' });
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [paymentData, setPaymentData] = useState({ show: false });
  const [apiData, setApiData] = useState({});
  const [apiCall, setApiCall] = useState(false);
  const getTransiction = (transValue, startDate, endDate, offset) => {
    getUserTransictions(userId, {
      limit: limit,
      offset: offset,
      transaction_type: transValue,
      start_date: startDate,
      end_date: endDate,
      search_by_name: encodeURIComponent(searchValue),
    }).then((res) => {
      if (res && res.data) {
        const data = decryptData(res.data.data);
        if (offset) {
          setTransaction([...transactions, ...data.data]);
        } else {
          setTransaction(data.data);
        }
        setApiCall(false);
        setApiData({ total: data.total_count, offset: data.offset });
      }
    });
  };
  const getDateValues = () => {
    let primeDate = startDate;
    if (primeDate) {
      const date = new Date(primeDate);
      primeDate = date.toLocaleDateString('en-GB');
    }
    let secondDate = endDate;
    if (secondDate) {
      const date = new Date(secondDate);
      secondDate = date.toLocaleDateString('en-GB');
    }
    return {primeDate,secondDate}
  }
  useEffect(() => {
    let dates = getDateValues()
    getTransiction(transTypeObj.value, dates.primeDate, dates.secondDate);
  }, [transTypeObj.value, startDate, endDate]);

  const getStatus = (status) => {
    let curStatus = { status: 'Completed', class: 'c-green' };
    if (status === 'In Progress') {
      curStatus = { status: 'In Progress', class: 'c-yellow' };
    } else if (status === 'Failed') {
      curStatus = { status: 'Failed', class: 'c-red' };
    } else if (status === 'Completed') {
      curStatus = { status: 'Completed', class: 'c-green' };
    } else if (status === 'Unpaid') {
      curStatus = { status: 'Pending', class: 'c-yellow' };
    }
    return curStatus;
  };
  const onTransTypeChange = (event, object) => {
    event.preventDefault();
    setTransactionVal(object);
  };
  const onStartDateChange = (date) => {
      setStartDate(date);
  };
  const onEndDateChange = (dateValue) => { 
    setEndDate(dateValue);
  };
  const onSearchChange = (event) => {
    const { value } = event.target;
    setSearchValue(value);
    setApiCall(true);
  };
  const enterHandler = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      onSearchClick();
    }
  };
  const onSearchClick = () => {
    if (apiCall) {
      let dates = getDateValues()
      getTransiction(transTypeObj.value, dates.primeDate,  dates.secondDate);
    }
  };

  const handlePagination = () => {
    let dates = getDateValues()
    getTransiction(transTypeObj.value, dates.primeDate, dates.secondDate, apiData.offset + 5);
  };

  const downloadtransiction = (eventKey) => {
    let dates = getDateValues()
    exportTransiction(userId, `${eventKey}-gen`, {
      transaction_type: transTypeObj.value,
      start_date: dates.primeDate,
      end_date: dates.secondDate,
      search_by_name: searchValue,
      limit: limit,
      offset: apiData.offset,
    }).then((res) => {
      handleFileDownload(eventKey, res.data);
    });
  };
  // Pay now button payment process

  const showBankModal = (data) => {
    setPaymentData({ ...data, show: true });
  };

  const onpaymentCompleted = () => {
    setPaymentData({ show: false });
  };

  return (
    <>
      <div className="container">
        <PageHeader headerName="My Transactions" className="row" />
        <div className="transaction-filter-section row align-items-center mb-4">
          <div className="col-sm-6">
            <InputGroup className="new-input-group">
              <img src={serachIcon} alt="searchIcon" className="search-input icon" />
              <FormControl
                aria-label="search term"
                className="pl-5"
                aria-describedby="basic-addon2"
                onChange={onSearchChange}
                onKeyDown={enterHandler}
              />
              <InputGroup.Append>
                <Button variant="primary" onClick={onSearchClick}>
                  Search
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </div>
          <div className="col-sm-5 d-flex ">
            <Form.Group className="col-sm-5 mb-0">
              <Form.Label className="opacity-50" srOnly>
                select Type
              </Form.Label>
              <DropdownList
                selectedOption={transTypeObj.label}
                id="return-type"
                classes={{ input: 'form-control py-2' }}
                controlFunc={onTransTypeChange}
                options={[
                  { value: 'all', label: 'All' },
                  { value: 'P', label: 'Purchase' },
                  { value: 'R', label: 'Redeem' },
                ]}
              />
            </Form.Group>
            <Dropdown className="ml-2" onSelect={downloadtransiction}>
              <Dropdown.Toggle
                id="dropdown-profile"
                className={'border-0  p-0 download-icon ' + (transactions && transactions.length ? 'cursor-pointer' : 'cursor-disabled')}
                disabled={transactions && transactions.length ? false : true}>
                <img src={downloadIcon} alt="downloadIcon" className="icon" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="csv">
                  <div className="profile-list">
                    <span className="ml-3">.CSV</span>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="pdf">
                  <div className="profile-list">
                    <span className="ml-3">.PDF</span>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="excel">
                  <div className="profile-list">
                    <span className="ml-3">.XLS</span>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="transaction-filter-section row align-items-center mb-4 mt-2">
        <Form.Group className="col-sm-3 mb-0 d-flex">
        <span className='opacity-75 mr-2 mt-2'>From:</span>
              <DatePicker
                id="transictaionDate"
                selected={startDate}
                onChange={onStartDateChange}
                dateFormat="dd/MM/yyyy"
                placeholderText="dd/mm/yyyy"
                autoComplete="off"
                className={`form-control font-weight-medium ${startDate ? 'selected' : ''}`}
              />
              {startDate? (
                <img
                  src={closeIcon}
                  alt="ClearIcon"
                  className="date-clear-icon cursor-pointer"
                  onClick={() => {
                    setStartDate('');
                  }}
                />
              ) : null}
            </Form.Group>
            <Form.Group className="col-sm-3 mb-0 d-flex">
            <span className='opacity-75 mr-2 mt-2'>To:</span>
              <DatePicker
                id="transictaionDate"
                selected={endDate}
                onChange={onEndDateChange}
                dateFormat="dd/MM/yyyy"
                placeholderText="dd/mm/yyyy"
                autoComplete="off"
                className={`form-control font-weight-medium ${endDate ? 'selected' : ''}`}
              />
              {endDate ? (
                <img
                  src={closeIcon}
                  alt="ClearIcon"
                  className="date-clear-icon cursor-pointer"
                  onClick={() => {
                    setEndDate('');
                  }}
                />
              ) : null}
            </Form.Group>
        </div>

        <div className="transactions-container rounded">
          <div className="row font-weight-medium py-2 text-align-center  border-bottom mt-3 mx-0">
            <span className="col-sm-2 mx-0  pr-0">Date</span>
            <span className="col-sm-5 mx-0 pr-0">Fund</span>
            <span className="col-sm-1 mx-0 text-center pr-0">Type</span>
            <span className="col-sm-1 mx-0 text-center pr-0">Units</span>
            <span className="col-sm-1 mx-0 text-center pr-0">NAV</span>
            <span className="col-sm-2 ml-0 text-center pr-0 ">Amount</span>
          </div>
          <div className="transaction-list-section position-relative">
            {transactions && transactions.length ? (
              transactions.map((data) => {
                const statusObj = getStatus(data.status);
                data = data.tx_details;
                return (
                  <div className="tx_details-row row" key={data.id}>
                    <div className="col-sm-2 border-right  text-center pt-2 pl-0">{formatDate(new Date(data.created))}</div>
                    <div className="col-sm-5  border-right pt-2">
                      <Link to={{pathname:`/fund/detail/${regexString(data.name)}/${data.fund_id}`}} className="transaction-title  c-blue font-weight-medium">
                        {data.name}
                      </Link>
                      <div className="transaction-folio-num small py-2">
                        <span>Folio Number:</span> <span className="ml-1">{data.folio_number}</span>
                      </div>
                      <div className="transaction-order-num small py-2">
                        <span>Order Number:</span> <span className="ml-1">{data.order_number}</span>
                      </div>
                      <div className="transaction-status-num small align-items-center py-1 d-flex mb-2">
                        <div className="status-container">
                          <span>Status:</span> <span className={'ml-1 font-weight-bold ' + statusObj.class}>{statusObj.status}</span>
                        </div>
                        {statusObj.status === 'Pending' ? (
                          <div className="status-btn ml-2">
                            <button
                              type="button"
                              className="btn btn-sm btn-success"
                              onClick={() => {
                                showBankModal(data);
                              }}>
                              Pay Now
                            </button>
                          </div>
                        ) : null}
                      </div>
                      {statusObj.status === 'Pending' ? (
                        <div className="opacity-75 small font-italic my-2">
                          *Use the Pay Now button only if the funds have not been debited from your account.
                        </div>
                      ) : null}
                    </div>
                    <div className="col-sm-1 border-right px-0 text-center pt-2">{data.order_type}</div>
                    <div className="col-sm-1 border-right  text-right pt-2">{formatNumber(data.units, 2)}</div>
                    <div className="col-sm-1 border-right text-right pt-2">{formatNumber(data.nav, 2)}</div>
                    <div className="col-sm-2 text-right pr-5 pt-2">{formatPrice(data.amount, 2)}</div>
                  </div>
                );
              })
            ) : (
              <DataMsg message="No Transactaion Available" button="Invest" url="/invest" />
            )}
            {apiData.total > apiData.offset + limit ? (
              <div className="pagination-container p-4 text-center">
                <Button type="button" variant="primary" id="loadMore" onClick={handlePagination}>
                  Load More
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {paymentData.show ? (
        <Payment
          onpaymentCompleted={onpaymentCompleted}
          show={paymentData.show}
          userId={userId}
          totalAmount={paymentData.amount}
          orders={[paymentData.id]}
        />
      ) : null}
    </>
  );
};
export default Transactions;