import React, { useEffect, useState } from 'react';
import {  getUserMandateDetails } from '../api/bank';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import './LoaderModel.scss';
import FadeLoader from 'react-spinners/FadeLoader';
import DisableRefreshIcons from '../assets/refresh-disable.png';
import EnableRefeshIcons from '../assets/refresh-enable.png';
import CloseModal from '../assets/close-modal-icon.png';

const Loader = ({ show, postObj, mandateId, showLoaderHandler }) => {
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      getUserMandateDetails(mandateId)
        .then((res) => {
          if (res && res.data && res.data.redirection_url) {
            window.location = res.data.redirection_url;
            clearInterval(myInterval);
          } else {
            if (seconds > 0) {
              setSeconds(seconds - 1);
            }
          }
        })
        .catch((error) => {
          showLoaderHandler();
          clearInterval(myInterval);
        });
      if (minutes === 0 && seconds === 0) {
        refreshHandler();
      } else if (seconds === 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const refreshHandler = () => {
    setMinutes(1);
    setSeconds(30);
    setRefresh(false);
  };

  const closeModalHandler = () => {
    showLoaderHandler();
  };

  return (
    <>
      <Modal show={show} size="md" aria-labelledby="timer-loader-modal" centered backdrop="static" keyboard={false}>
        <Modal.Body className="modal-class small text-center">
          <img
            onClick={() => {
              closeModalHandler();
            }}
            className="close-modal-icon"
            src={CloseModal}
            alt=''
          />
          {!refresh && (
            <>
              <div className="loader-wrapper">
                {' '}
                <FadeLoader color="rgb(74,165,213)" height="10" w="55" />
              </div>
              <h3>Loading...</h3>
              <div className="subtitle-text-wrapper">
                <p className="subtitle-text">Please do not refresh the page</p>
                <p className="subtitle-text">Mandate setup in progress</p>
              </div>
            </>
          )}
          <span>
            <p className="time-text">
              {' '}
              {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
              {refresh && (
                <img
                  className="refresh-icons"
                  onClick={() => {
                    refreshHandler();
                  }}
                  src={EnableRefeshIcons}
                  alt=''
                />
              )}
              {!refresh && <img className="refresh-icons" src={DisableRefreshIcons} alt='' />}
            </p>
          </span>
        </Modal.Body>
      </Modal>
    </>
  );
};

Loader.propTypes = {
  show: PropTypes.bool,
  postObj: PropTypes.object,
  mandateId: PropTypes.string,
  showLoaderHandler: PropTypes.func,
};

export default Loader;
