import { useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import Amount from '../../../component/Amount/Amount';
import { PureDropdown } from '../../../component/PureDropdown/PureDropdown';
import { ReturnsTypeEnum } from '../../../enums';
import { useMedia } from '../../../hooks/useMedia';
import AddFundModal from '../../../modal/AddFundModal';
import PlanDirectModal from '../../../modal/PlanDirectModal';
import ReedemFundModal from '../../../modal/ReedemFundModal';
import { Folio, PortfolioItem } from '../../../types';
import { ReactComponent as AddIcon } from '../../assets/PortfolioMutualFunds/add.svg';
import { ReactComponent as RedeemIcon } from '../../assets/PortfolioMutualFunds/redeem.svg';
import { ReactComponent as SwitchIcon } from '../../assets/PortfolioMutualFunds/switch.svg';
import { FolioBtn } from './FolioBtn';
import { FolioCell } from './FolioCell';
import './PortfolioMutualFunds.scss';
import { ReturnsCell } from './ReturnsCell';
import { usePortfolio } from './usePortfolio';

const OPTIONS: (keyof typeof ReturnsTypeEnum)[] = ['Absolute', 'XIRR'];

interface Props {
  funds: PortfolioItem[];
}

export const PortfolioMutualFunds = ({ funds }: Props) => {
  const [returnType, setReturnType] =
    useState<keyof typeof ReturnsTypeEnum>('Absolute');
  const isMobile = useMedia(['(min-width: 768px)'], [false], true);

  const {
    addModalData,
    directPlan,
    add,
    reedemModalData,
    onAddFundModalClose,
    onClosePopUp,
    onRedeemSubmit,
    reedemFundFunc,
    addFundFunc,
  } = usePortfolio();

  if (funds.length <= 0) {
    return null;
  }

  return (
    <div className='PortfolioMutualFunds__tile'>
      <div className='MutualFunds__header text-lg leading-tight mb-4'>
        Mutual Funds
      </div>
      <div
        style={{
          padding: '0 20px',
        }}
      >
        <div className='PortfolioMutualFunds__grid d-grid pb-3'>
          <div
            className='PortfolioMutualFunds__gray fw-600 d-flex align-items-center'
            style={{
              gridColumn: 'span 2',
            }}
          >
            Scheme Name
          </div>
          <div className='PortfolioMutualFunds__gray fw-600 d-flex align-items-center justify-content-center'>
            Asset Class
          </div>
          <div className='PortfolioMutualFunds__gray fw-600 d-flex align-items-center justify-content-center'>
            Returns&nbsp;:&nbsp;
            <PureDropdown
              value={returnType}
              items={OPTIONS}
              onClick={(v: string) => {
                setReturnType(v as keyof typeof ReturnsTypeEnum);
              }}
            />
          </div>
          <div />
        </div>
      </div>
      {(funds || []).map((fund) => {
        return (
          <div
            className='PortfolioMutualFunds__row PortfolioMutualFunds__dark mb-3'
            key={fund.fund_id}
          >
            <div className='PortfolioMutualFunds__row_grid d-grid pb-1 mb-2'>
              <div
                className='fw-500 d-flex align-items-center'
                style={{
                  gridColumn: 'span 2',
                }}
              >
                <img
                  src={fund.logo}
                  alt={fund.fund_name}
                  className='h-auto w-10 PortfolioMutualFunds__logo rounded-circle mr-3'
                />
                <div className='font-medium PortfolioMutualFunds__row_grid__fund_name'>
                  {fund.fund_name}
                </div>
              </div>
              {!isMobile && (
                <div className='fw-500 d-flex align-items-center justify-content-center'>
                  Mutual Fund
                </div>
              )}
              {isMobile ? (
                <ReturnsCellWithDropdownForMobile fund={fund} />
              ) : (
                <ReturnsCell returnType={returnType} folios={fund.folio_list} />
              )}
              <div />
            </div>
            <div className='PortfolioMutualFunds__folios mb-2'>
              {fund?.folio_list?.map((folio) => {
                return folio ? (
                  <FolioRow
                    fund={fund}
                    folio={folio}
                    reedemFundFunc={reedemFundFunc}
                    addFundFunc={addFundFunc}
                  />
                ) : null;
              })}
            </div>
          </div>
        );
      })}
      {addModalData.show ? (
        <AddFundModal
          show={addModalData.show}
          minAmountNew={addModalData.minAmountNew}
          minAmountCurrent={addModalData.minAmountCurrent}
          folioList={addModalData.folioList}
          onSubmit={onAddFundModalClose}
          fundId={addModalData.fundId}
          // status={addModalData.isRegular}
          frequencyDates={addModalData.frequencyDates}
          canInvestLumpsum={addModalData.canInvestLumpsum}
          canInvestSip={addModalData.canInvestSip}
          isEmpanelled={addModalData.isEmpanelled}
          manageAmc={true}
        />
      ) : null}
      {reedemModalData.show ? (
        <ReedemFundModal
          show={reedemModalData.show ?? true}
          onSubmit={onRedeemSubmit}
          options={reedemModalData.options}
        />
      ) : null}
      {directPlan ? (
        <PlanDirectModal show={directPlan} add={add} onClose={onClosePopUp} />
      ) : null}
    </div>
  );
};

const ReturnsCellWithDropdownForMobile = ({
  fund,
}: {
  fund: PortfolioItem;
}) => {
  const [returnType, setReturnType] =
    useState<keyof typeof ReturnsTypeEnum>('Absolute');
  return (
    <div className='d-flex justify-content-start' key={fund.fund_id}>
      <ReturnsCell folios={fund.folio_list} returnType={returnType}>
        <PureDropdown
          value={returnType}
          items={OPTIONS}
          onClick={(v: string) => {
            setReturnType(v as keyof typeof ReturnsTypeEnum);
          }}
        />
      </ReturnsCell>
    </div>
  );
};

const FolioRow = ({
  fund,
  folio,
  reedemFundFunc,
  addFundFunc,
}: {
  fund: PortfolioItem;
  folio: Folio;
  addFundFunc: any;
  reedemFundFunc: any;
}) => {
  const isMobile = useMedia(['(min-width: 768px)'], [false], true);
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleFolioActions = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <>
      <div
        className='PortfolioMutualFunds__subrow_grid PortfolioMutualFunds__folio d-grid bg-white rounded'
        key={folio.folio_no}
      >
        <FolioCell
          label='Folio'
          className={`PortfolioMutualFunds__folio__number ${
            isMobile && 'flex-column'
          }`}
        >
          {folio.folio_no}
        </FolioCell>
        <FolioCell
          label='Investment '
          className={`PortfolioMutualFunds__folio__investment ${
            isMobile && 'flex-column'
          }`}
        >
          <Amount
            className='text-nowrap'
            value={folio.total_investment}
            options={{
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }}
          />
        </FolioCell>
        <FolioCell
          label='Current Value'
          className={`justify-content-start ${
            isMobile && 'flex-column'
          } PortfolioMutualFunds__folio__curr_value`}
        >
          <Amount
            className='text-nowrap '
            value={folio.current_value}
            options={{
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }}
          />
        </FolioCell>
        <FolioCell
          label='Units'
          className={`justify-content-start ${
            isMobile && 'flex-column'
          } PortfolioMutualFunds__folio__units`}
        >
          {folio.units}
        </FolioCell>
        {!isMobile ? (
          <FolioRowActions
            fund={fund || {}}
            reedemFundFunc={reedemFundFunc}
            addFundFunc={addFundFunc}
          />
        ) : (
          <div
            className={`justify-content-center d-flex PortfolioMutualFunds__folio__icons ${
              isExpanded && 'PortfolioMutualFunds__folio__icons__clicked'
            }`}
            onClick={toggleFolioActions}
          >
            <BsThreeDotsVertical />
          </div>
        )}
        {isMobile && isExpanded ? (
          <FolioRowActions
            fund={fund}
            reedemFundFunc={reedemFundFunc}
            addFundFunc={addFundFunc}
          />
        ) : null}
      </div>
    </>
  );
};

const FolioRowActions = ({
  fund,
  reedemFundFunc,
  addFundFunc,
}: {
  fund: PortfolioItem;
  reedemFundFunc: any;
  addFundFunc: any;
}) => {
  return (
    <div
      className='d-flex align-items-center justify-content-end PortfolioMutualFunds__folio__actions'
      style={{
        margin: '0 -10px',
      }}
    >
      <FolioBtn
        icon={<AddIcon />}
        className='PortfolioMutualFunds__green'
        onClick={() => addFundFunc(fund)}
      >
        Add Fund
      </FolioBtn>
      <FolioBtn
        icon={<RedeemIcon />}
        className='PortfolioMutualFunds__red'
        onClick={() => reedemFundFunc(fund)}
      >
        Redeem Fund
      </FolioBtn>
      <Link
        to={{
          pathname: '/switch-funds',
          search: `id=${fund.fund_id}`,
        }}
        className={`FolioBtn bg-transparent border-0 PortfolioMutualFunds__orangeLink`}
      >
        <SwitchIcon />
        <div
          style={{
            paddingTop: 6,
          }}
        >
          Switch Fund
        </div>
      </Link>
    </div>
  );
};
