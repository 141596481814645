import elevo_apis from './elevo_apis';

function createInvestor(data) {
  return elevo_apis.post('/investors', data);
}
function updateInvestorDetails(id, data) {
  return elevo_apis.put(`/investors/${id}`, data);
}
function saveInvestorDetails(id, data) {
  return elevo_apis.patch(`/investors/${id}`, data);
}
function getInvestorDetails(id) {
  return elevo_apis.get(`/investors/${id}`);
}

export { createInvestor, saveInvestorDetails, updateInvestorDetails, getInvestorDetails };