import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import OTPTimer from '../../../../component/otp-component/Timer';
import LoaderImg from './loader.png';
import { switchFundOrderStatus } from '../../../../api/switchFund';
const SwitchFundsOrderStatus = () => {
  const { id } = useParams();
  const [isProcessing, setIsProcessing] = useState(true);
  const [orderData, setOrderData] = useState(true);

  useEffect(() => {
    getOrderStatus(id)
  }, []);

  const getOrderStatus = (id) => {
    switchFundOrderStatus(id).then(response => {
      let data = response.data;
      if(data.status === "success") {
        setIsProcessing(false)
        setOrderData(data)
      }
    })
  }

  const onTimerComplete = () => {};

  return (
    <>
      {!isProcessing ?
        <div>
          Fund Name: {orderData.fund_name} <br />
          Status :{orderData.status}
        </div> :

        <div className={'processing-div'}>
          <div className={'processing-block'}>
            <div className={"processing-block-sub"}>
              <div className={'processing-loader'}>
                <img src={LoaderImg} alt="loarder"/>
              </div>
              <div className={'processing-text'}>Loading...</div>
              <div className={'processing-text-sub mt-1'}>Please do not refresh the page. Mandate setup in progress.
              </div>
              <div className={'d-flex justify-content-center mt-3'}>
                <OTPTimer className={"text-danger"} initialSeconds={90} onComplete={onTimerComplete}/>
                <span className={'ml-1'} style={{ cursor: 'pointer' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15.744" height="12.698" viewBox="0 0 15.744 12.698">
                  <g id="Icon_feather-refresh-ccw" data-name="Icon feather-refresh-ccw"
                     transform="translate(-0.44 -3.726)">
                    <path
                      id="Path_3832"
                      data-name="Path 3832"
                      d="M1.5,6V9.716H5.216"
                      transform="translate(0 -0.879)"
                      fill="none"
                      stroke="#a7a7a7"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Path_3833"
                      data-name="Path 3833"
                      d="M29.216,24.716V21H25.5"
                      transform="translate(-14.092 -9.686)"
                      fill="none"
                      stroke="#a7a7a7"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Path_3834"
                      data-name="Path 3834"
                      d="M13.57,8.218a5.573,5.573,0,0,0-9.2-2.081L1.5,8.837m13.624,2.477-2.873,2.7a5.573,5.573,0,0,1-9.2-2.081"
                      transform="translate(0)"
                      fill="none"
                      stroke="#a7a7a7"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </g>
                </svg>
              </span>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default SwitchFundsOrderStatus;
