import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface HeaderState {
  showCart: boolean;
  showPages: boolean;
  showProfile: boolean;
  showIcon: boolean;
  showProfileIcon: boolean;
  activeLink: string;
  showOTPModal: boolean;
  otpId: string;
}

const initialState: HeaderState = {
  showCart: true,
  showPages: true,
  showProfile: true,
  showIcon: true,
  showProfileIcon: true,
  activeLink: 'home',
  showOTPModal: false,
  otpId: '',
};

const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    updateHeaderOption: (state, action: PayloadAction<Partial<HeaderState>>) => {
      Object.assign(state, action.payload);
    },
    updateSelectedNav: (state, action: PayloadAction<string>) => {
      state.activeLink = action.payload;
    },
    setShowOTPModal: (state, action: PayloadAction<boolean>) => {
      state.showOTPModal = action.payload;
    },
    setOtpId: (state, action: PayloadAction<string>) => {
      state.otpId = action.payload;
    },
  },
});

export const { updateHeaderOption, updateSelectedNav, setShowOTPModal, setOtpId } = headerSlice.actions;

export default headerSlice.reducer;
