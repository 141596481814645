import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { OccupationList } from '../../utility/utility';
import { InputBox, RadioGroups } from '../form';

const Occupation = ({ onRadioChange, value, otherValue, onInputChange, err }) => {
  const [business, setData] = useState('');
  const [otherVal, setOtherVal] = useState('');
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    setData(value);
    setOtherVal(otherValue);
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [value, otherValue]);
  const onRadioupdate = (event) => {
    const { value } = event.target;
    setData(value);
    onRadioChange(value);
    onInputChange('');
  };
  const onOcccupationChanage = (event) => {
    const { value } = event.target;
    if (onInputChange) {
      setOtherVal(value);
      if (business === 'O') {
        onInputChange(value);
      }
    }
  };
  return (
    <>
      {windowDimensions.width > 480 ? (
        <div className="border p-4 rounded w-100 my-3">
          <RadioGroups title="Occupation" id="occupation" radioList={OccupationList} selectedVal={business} onChange={onRadioupdate} />
          {business === 'O' ? (
            <InputBox
              id="onboarding-occupation"
              onChange={onOcccupationChanage}
              title="Other Occupation"
              value={otherVal}
              className="col-sm-3"
              srOnly={true}
              err={err}
            />
          ) : (
            err && <div className={'invalid-feedback d-block'}>{err}</div>
          )}
        </div>
      ) : (
        <div className=" py-2 rounded w-100 my-3">
          <RadioGroups title="Occupation" id="occupation" radioList={OccupationList} selectedVal={business} onChange={onRadioupdate} />
          {business === 'O' ? (
            <InputBox
              id="onboarding-occupation"
              onChange={onOcccupationChanage}
              title="Other Occupation"
              value={otherVal}
              className="col-sm-3"
              srOnly={true}
              err={err}
            />
          ) : (
            err && <div className={'invalid-feedback d-block'}>{err}</div>
          )}
        </div>
      )}
    </>
  );
};
Occupation.propTypes = {
  onRadioChange: PropTypes.func,
  onInputChange: PropTypes.func,
  value: PropTypes.string,
  otherValue: PropTypes.string,
};
export default Occupation;
