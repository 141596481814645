import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getKycData, getSignzyUrl, getTaxStatus } from '../../api/kyc';
import InfoIcon from '../../assets/info-blue.svg';
import { RadioGroups } from '../../common/form';
import KycVerifiedModal from '../../modal/KycVerifiedModal';
import SuccessModal from '../../modal/SuccessModal';
import {
  removeAuthData,
  updateKycStatus,
} from '../../redux/reducers/auth-reducer';
import { updateSelectedNav } from '../../redux/reducers/header-reducer';
import { updateKycInfo } from '../../redux/reducers/onboarding-reducer';
import { decryptData, encryptData } from '../../utility/DecryptHelper';
import {
  isEmptyObject,
  redirectUserForAuthentication,
} from '../../utility/utility';

const PanStatus = ({ history }) => {
  const userId = useSelector((state) => state.authReducer.user_id);
  const [pageHtmlData, setHtmlData] = useState({});
  const [panData, setPanData] = useState({});
  const [, setKycData] = useState({});
  const [showKycPopup, setKycPopup] = useState({ show: false, message: null });
  const [showSuccessPopup, setSuccessPopup] = useState({
    show: false,
    message: null,
  });
  const [applicableFor, setApplicableFor] = useState('');
  const [panInputType, setPanInputType] = useState('text');
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const dispatch = useDispatch();
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onRadioButtonChange = (event) => {
    const { value } = event.target;
    setApplicableFor(value);
    dispatch(updateKycInfo({ pan: panData.value, pan_kyc_status: value }));
    localStorage.setItem(
      'panData',
      encryptData({ pan: panData.value, pan_kyc_status: value })
    );
  };
  const panNumberChange = (event) => {
    const panCardRegex = /[A-Z]{5}\d{4}[A-Z]{1}/i;
    let value = event.target.value?.trim();
    if (value !== panData?.value) {
      if (value.length < 5 || value.length > 8 || !value) {
        setPanInputType('text');
      } else {
        setPanInputType('numeric');
      }
      const panData = { value: value?.toUpperCase(), err: '' };
      if (!value && !value.trim()) {
        panData['err'] = 'Pan card cannot be empty';
      } else if (!panCardRegex.test(value)) {
        panData['err'] = 'Invalid Pan Card';
      }
      setPanData(panData);
      if (value && value.length && !panData.err) {
        getTaxStatusApi(value.toUpperCase());
      }
    }
  };
  const getTaxStatusApi = (value) => {
    setKycPopup({ show: false });
    getTaxStatus(value.toUpperCase()).then((res) => {
      if (res && res.data) {
        const htmlData = decryptData(res.data.data);
        if (res.data.status.code === 1) {
          setHtmlData(htmlData.data);
          if (
            htmlData.data &&
            htmlData.data.tax_status &&
            htmlData.data.tax_status.id
          ) {
            dispatch(
              updateKycInfo({
                pan: value.toUpperCase(),
                pan_kyc_status: htmlData.data.tax_status.id,
              })
            );
            localStorage.setItem(
              'panData',
              encryptData({
                pan: value,
                pan_kyc_status: htmlData.data.tax_status.id,
              })
            );
          }
        } else if (res.data.status.code === 5) {
          setHtmlData(htmlData.data);
          setKycPopup({ show: true, message: res.data.status.message });
        } else if (res.data.status.code === 0) {
          setKycPopup({ show: true });
        }
      }
    });
  };
  const getUserKycData = () => {
    const data = new FormData();
    data.append('pan', panData.value);
    data.append('tax_status', '11');
    getKycData(data).then((res) => {
      if (res && res.data) {
        const data = decryptData(res.data.data);
        if (data && data.data && data.data.length) {
          setKycData(data.data[0]);
        }
      }
    });
  };

  const onSubmit = () => {
    if (!isEmptyObject(pageHtmlData)) {
      if (pageHtmlData?.tax_status?.id === '01' && applicableFor === '11') {
        setKycPopup({
          show: true,
          isNri: true,
          header: 'Resident Indian tax status detected',
          message:
            'In order to invest from your NRE/NRO account, kindly contact support to change the tax status in your PAN to NRI.',
        });
        setPanData({ value: panData.value, err: '' });
      } else if (
        panData.value &&
        panData.value.length &&
        pageHtmlData &&
        pageHtmlData.tax_status &&
        pageHtmlData.tax_status.id
      ) {
        dispatch(updateKycStatus(true));
        history.push('/boarding/');
      } else {
        setPanData({ value: panData.value, err: 'Invalid Pan Card' });
      }
    }
  };

  const getRadioOption = (objArr) => {
    const tempArr = [];
    objArr.forEach((item) => {
      tempArr.push({ label: item.tax_status, value: item.id });
    });
    return tempArr;
  };
  const redirectToLogin = () => {
    setKycPopup(false);
    localStorage.removeItem('panData');
    localStorage.removeItem('uD');
    dispatch(removeAuthData());
    redirectUserForAuthentication();
  };
  const onPopupClose = () => {
    if (
      pageHtmlData &&
      pageHtmlData.tax_status &&
      pageHtmlData.tax_status.id === '09'
    ) {
      getSignzyUrl(userId)
        .then((res) => {
          const data = decryptData(res.data.data);
          if (data && data.data) {
            window.open(data.data.mobile_auto_login_url, '_blank');
          }
          redirectToLogin();
        })
        .catch(() => {
          redirectToLogin();
        });
    } else {
      if (applicableFor === '11') {
        getUserKycData();
        setTimeout(() => {
          dispatch(updateKycStatus(true));
        }, 500);
      }
      dispatch(updateSelectedNav('home'));
      history.push('/home');
    }
  };
  const onSuccessPopupClose = () => {
    setSuccessPopup({ show: false, message: null });
  };
  return (
    <>
      {windowDimensions.width > 480 ? (
        <div
          className='p-5 col-sm-5 colored-box center'
          style={{ position: 'absolute' }}
        >
          <Form.Group className='my-3'>
            <Form.Label className='opacity-75 mb-1'>
              Enter PAN Number
            </Form.Label>
            <Form.Control
              type='text'
              id='contact-number'
              placeholder=''
              inputMode={panInputType}
              onChange={panNumberChange}
              onFocus={panNumberChange}
              value={panData.value}
              data-cy='panNumber'
              className={
                'updated-input font-weight-medium pl-0 ' +
                (panData.err ? 'is-invalid' : '')
              }
            />
            <Form.Control.Feedback type='invalid'>
              {panData.err}
            </Form.Control.Feedback>
          </Form.Group>
          {pageHtmlData.tax_status ? (
            <Form.Group className='mb-3'>
              <Form.Label className='opacity-75'>
                Select Your Tax Status
              </Form.Label>
              <Form.Control
                className={'updated-input font-weight-medium '}
                value={pageHtmlData.tax_status.tax_status}
                readOnly
              />
            </Form.Group>
          ) : null}
          {pageHtmlData.radio_button_options &&
          pageHtmlData.radio_button_options.length ? (
            <div>
              {pageHtmlData.tax_status.id === '01' && (
                <div className='mt-3 small d-flex align-items-center'>
                  <span>Please select your investment profile</span>
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Popover id='popover-recomandation'>
                        <Popover.Content>
                          <div className='my-2 c-grey small'>
                            Select Invest for yourself, If you are an individual
                            creating your own account
                          </div>
                          <div className='my-2 c-grey small'>
                            Select Invest on behalf of a minor, If you are a
                            guardian who is investing on behalf of a minor
                          </div>
                          <div className='my-2 c-grey small'>
                            Select Invest as a proprietorship firm, If your
                            proprietorship is linked to your individual PAN and
                            you plan to invest using your proprietorship firms
                            bank account.
                          </div>
                        </Popover.Content>
                      </Popover>
                    }
                  >
                    <img src={InfoIcon} alt='info icon' className='icon ml-2' />
                  </OverlayTrigger>
                </div>
              )}
              <RadioGroups
                id='panStatusRadio'
                selectedVal={applicableFor || pageHtmlData.tax_status.id}
                radioList={getRadioOption(pageHtmlData.radio_button_options)}
                onChange={onRadioButtonChange}
              />
            </div>
          ) : null}
          <div className='btn-container text-center my-4'>
            <button
              type='button'
              data-cy='submitButton'
              className='w-100 btn btn-primary big-btn'
              onClick={onSubmit}
            >
              Continue
            </button>
          </div>
        </div>
      ) : (
        <div className='bg-light-grey '>
          <h3 className=' text-center pt-4'>PAN Details</h3>
          <div className='mx-4 colored-box'>
            <Form.Group className='my-3 p-4'>
              <Form.Label className='opacity-75 mb-1'>
                Enter PAN Number
              </Form.Label>
              <Form.Control
                type='text'
                inputMode={panInputType}
                id='contact-number'
                placeholder=''
                onChange={panNumberChange}
                value={panData.value}
                data-cy='panNumber'
                className={
                  'updated-input font-weight-medium pl-0 ' +
                  (panData.err ? 'is-invalid' : '')
                }
              />
              <Form.Control.Feedback type='invalid'>
                {panData.err}
              </Form.Control.Feedback>
            </Form.Group>
            {pageHtmlData.tax_status ? (
              <Form.Group className='mb-3 px-4'>
                <Form.Label className='opacity-75'>
                  Select Your Tax Status
                </Form.Label>
                <Form.Control
                  className={'updated-input font-weight-medium '}
                  value={pageHtmlData.tax_status.tax_status}
                  readOnly
                />
              </Form.Group>
            ) : null}
            {pageHtmlData.radio_button_options &&
            pageHtmlData.radio_button_options.length ? (
              <div className='px-4'>
                {pageHtmlData.tax_status.id === '01' && (
                  <div className='mt-3 medium d-flex align-items-center '>
                    <span className='ml-0'>
                      Please select your investment profile
                    </span>
                    <OverlayTrigger
                      placement='top'
                      overlay={
                        <Popover id='popover-recomandation'>
                          <Popover.Content>
                            <div className='my-2 c-grey small'>
                              Select Invest for yourself, If you are an
                              individual creating your own account
                            </div>
                            <div className='my-2 c-grey small'>
                              Select Invest on behalf of a minor, If you are a
                              guardian who is investing on behalf of a minor
                            </div>
                            <div className='my-2 c-grey small'>
                              Select Invest as a proprietorship firm, If your
                              proprietorship is linked to your individual PAN
                              and you plan to invest using your proprietorship
                              firms bank account.
                            </div>
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      <img
                        src={InfoIcon}
                        alt='info icon'
                        className='icon ml-2'
                      />
                    </OverlayTrigger>
                  </div>
                )}
                <RadioGroups
                  id='panStatusRadio'
                  selectedVal={applicableFor || pageHtmlData.tax_status.id}
                  radioList={getRadioOption(pageHtmlData.radio_button_options)}
                  onChange={onRadioButtonChange}
                />
              </div>
            ) : null}
            <div
              className={`text-center my-4 p-4 ${
                pageHtmlData.tax_status ? 'bottom-30' : 'bottom-60'
              }`}
            >
              <button
                type='button'
                data-cy='submitButton'
                className='w-100 btn btn-primary big-btn '
                onClick={onSubmit}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}

      {showKycPopup.show ? (
        <KycVerifiedModal
          isNri={showKycPopup.isNri}
          show={showKycPopup.show}
          message={showKycPopup.message}
          header={showKycPopup.header}
          onSubmit={onPopupClose}
        />
      ) : null}
      {showSuccessPopup.show ? (
        <SuccessModal
          successText={showSuccessPopup.message}
          show={showSuccessPopup.show}
          onSubmit={onSuccessPopupClose}
        />
      ) : null}
    </>
  );
};
PanStatus.propTypes = {
  history: PropTypes.object,
};
export default PanStatus;
