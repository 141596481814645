/**
 * NOTE: This file is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 * Use models.sh bash script
 */
/* tslint:disable */
/* eslint-disable */
/**
 * 
 * @export
 * @interface About
 */
export interface About {
}
/**
 * 
 * @export
 * @interface AccountNumber
 */
export interface AccountNumber {
}

/**
 * 
 * @export
 */
export const AccountTypeEnum = {
    Savings: 'savings',
    Current: 'current'
} as const;
export type AccountTypeEnum = typeof AccountTypeEnum[keyof typeof AccountTypeEnum];

/**
 * 
 * @export
 * @interface AddressInDB
 */
export interface AddressInDB {
    /**
     * 
     * @type {string}
     * @memberof AddressInDB
     */
    address_line_1: string;
    /**
     * 
     * @type {string}
     * @memberof AddressInDB
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof AddressInDB
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof AddressInDB
     */
    pincode: string;
    /**
     * 
     * @type {string}
     * @memberof AddressInDB
     */
    country: string;
    /**
     * 
     * @type {AddressLine2}
     * @memberof AddressInDB
     */
    address_line_2?: AddressLine2;
    /**
     * 
     * @type {AddressLine3}
     * @memberof AddressInDB
     */
    address_line_3?: AddressLine3;
}
/**
 * 
 * @export
 * @interface AddressLine2
 */
export interface AddressLine2 {
}
/**
 * 
 * @export
 * @interface AddressLine3
 */
export interface AddressLine3 {
}
/**
 * 
 * @export
 * @interface Amount
 */
export interface Amount {
}

/**
 * 
 * @export
 */
export const AnnualIncomeEnum = {
    Upto5Lakh: 'upto_5_lakh',
    Above5LakhUpto10Lakh: 'above_5_lakh_upto_10_lakh',
    Above10LakhUpto25Lakh: 'above_10_lakh_upto_25_lakh',
    Above25LakhUpto50Lakh: 'above_25_lakh_upto_50_lakh',
    Above50LakhUpto1Crore: 'above_50_lakh_upto_1_crore',
    Above1Crore: 'above_1_crore'
} as const;
export type AnnualIncomeEnum = typeof AnnualIncomeEnum[keyof typeof AnnualIncomeEnum];

/**
 * 
 * @export
 * @interface ApplicantCreate
 */
export interface ApplicantCreate {
  /**
   *
   * @type {number}
   * @memberof ApplicantCreate
   */
  application_id: number;
  /**
   *
   * @type {MaritalStatusEnum}
   * @memberof ApplicantCreate
   */
  marital_status: MaritalStatusEnum;
  /**
   *
   * @type {AnnualIncomeEnum}
   * @memberof ApplicantCreate
   */
  annual_income: AnnualIncomeEnum;
  /**
   *
   * @type {OccupationEnum}
   * @memberof ApplicantCreate
   */
  occupation: OccupationEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicantCreate
   */
  birth_place: string;
  /**
   *
   * @type {string}
   * @memberof ApplicantCreate
   */
  birth_country: string;
  /**
   *
   * @type {string}
   * @memberof ApplicantCreate
   */
  father_first_name: string;
  /**
   *
   * @type {string}
   * @memberof ApplicantCreate
   */
  father_last_name: string;
}
/**
 * 
 * @export
 * @interface ApplicantId
 */
export interface ApplicantId {
}
/**
 * 
 * @export
 * @interface ApplicantInDB
 */
export interface ApplicantInDB {
  /**
   *
   * @type {number}
   * @memberof ApplicantInDB
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ApplicantInDB
   */
  email: string;
  /**
   *
   * @type {number}
   * @memberof ApplicantInDB
   */
  user_id: number;
  /**
   *
   * @type {ApplicantInDBSalutation}
   * @memberof ApplicantInDB
   */
  salutation?: ApplicantInDBSalutation;
  /**
   *
   * @type {FirstName}
   * @memberof ApplicantInDB
   */
  first_name?: FirstName;
  /**
   *
   * @type {MiddleName}
   * @memberof ApplicantInDB
   */
  middle_name?: MiddleName;
  /**
   *
   * @type {LastName}
   * @memberof ApplicantInDB
   */
  last_name?: LastName;
  /**
   *
   * @type {string}
   * @memberof ApplicantInDB
   */
  date_of_birth?: string;
  /**
   *
   * @type {Gender}
   * @memberof ApplicantInDB
   */
  gender?: Gender;
  /**
   *
   * @type {Mobile}
   * @memberof ApplicantInDB
   */
  mobile?: Mobile;
  /**
   *
   * @type {CkycNumber}
   * @memberof ApplicantInDB
   */
  ckyc_number?: CkycNumber;
  /**
   *
   * @type {ApplicantInDBMaritalStatus}
   * @memberof ApplicantInDB
   */
  marital_status?: ApplicantInDBMaritalStatus;
  /**
   *
   * @type {ApplicantInDBAnnualIncome}
   * @memberof ApplicantInDB
   */
  annual_income?: ApplicantInDBAnnualIncome;
  /**
   *
   * @type {ApplicantInDBOccupation}
   * @memberof ApplicantInDB
   */
  occupation?: ApplicantInDBOccupation;
  /**
   *
   * @type {BirthPlace}
   * @memberof ApplicantInDB
   */
  birth_place?: BirthPlace;
  /**
   *
   * @type {BirthCountry}
   * @memberof ApplicantInDB
   */
  birth_country?: BirthCountry;
  /**
   *
   * @type {Nationality}
   * @memberof ApplicantInDB
   */
  nationality?: Nationality;
  /**
   *
   * @type {PermanentAddressSameAsResidential}
   * @memberof ApplicantInDB
   */
  permanent_address_same_as_residential?: PermanentAddressSameAsResidential;
  /**
   *
   * @type {father_first_name}
   * @memberof ApplicantInDB
   */
  father_first_name: string;
  /**
   *
   * @type {father_last_name}
   * @memberof ApplicantInDB
   */
  father_last_name: string;
}
/**
 * 
 * @export
 * @interface ApplicantInDBAnnualIncome
 */
export interface ApplicantInDBAnnualIncome {
}
/**
 * 
 * @export
 * @interface ApplicantInDBMaritalStatus
 */
export interface ApplicantInDBMaritalStatus {
}
/**
 * 
 * @export
 * @interface ApplicantInDBOccupation
 */
export interface ApplicantInDBOccupation {
}
/**
 * 
 * @export
 * @interface ApplicantInDBSalutation
 */
export interface ApplicantInDBSalutation {
}
/**
 * 
 * @export
 * @interface ApplicantOut
 */
export interface ApplicantOut {
    /**
     * 
     * @type {number}
     * @memberof ApplicantOut
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicantOut
     */
    email: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicantOut
     */
    user_id: number;
    /**
     * 
     * @type {ApplicantInDBSalutation}
     * @memberof ApplicantOut
     */
    salutation?: ApplicantInDBSalutation;
    /**
     * 
     * @type {FirstName}
     * @memberof ApplicantOut
     */
    first_name?: FirstName;
    /**
     * 
     * @type {MiddleName}
     * @memberof ApplicantOut
     */
    middle_name?: MiddleName;
    /**
     * 
     * @type {LastName}
     * @memberof ApplicantOut
     */
    last_name?: LastName;
    /**
     * 
     * @type {string}
     * @memberof ApplicantOut
     */
    date_of_birth?: string;
    /**
     * 
     * @type {Gender}
     * @memberof ApplicantOut
     */
    gender?: Gender;
    /**
     * 
     * @type {Mobile}
     * @memberof ApplicantOut
     */
    mobile?: Mobile;
    /**
     * 
     * @type {CkycNumber}
     * @memberof ApplicantOut
     */
    ckyc_number?: CkycNumber;
    /**
     * 
     * @type {ApplicantInDBMaritalStatus}
     * @memberof ApplicantOut
     */
    marital_status?: ApplicantInDBMaritalStatus;
    /**
     * 
     * @type {ApplicantInDBAnnualIncome}
     * @memberof ApplicantOut
     */
    annual_income?: ApplicantInDBAnnualIncome;
    /**
     * 
     * @type {ApplicantInDBOccupation}
     * @memberof ApplicantOut
     */
    occupation?: ApplicantInDBOccupation;
    /**
     * 
     * @type {BirthPlace}
     * @memberof ApplicantOut
     */
    birth_place?: BirthPlace;
    /**
     * 
     * @type {BirthCountry}
     * @memberof ApplicantOut
     */
    birth_country?: BirthCountry;
    /**
     * 
     * @type {Nationality}
     * @memberof ApplicantOut
     */
    nationality?: Nationality;
    /**
     * 
     * @type {PermanentAddressSameAsResidential}
     * @memberof ApplicantOut
     */
    permanent_address_same_as_residential?: PermanentAddressSameAsResidential;
    /**
     * 
     * @type {ApplicantOutResidentialAddress}
     * @memberof ApplicantOut
     */
    residential_address?: ApplicantOutResidentialAddress;
}
/**
 * 
 * @export
 * @interface ApplicantOutResidentialAddress
 */
export interface ApplicantOutResidentialAddress {
    /**
     * 
     * @type {string}
     * @memberof ApplicantOutResidentialAddress
     */
    address_line_1: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantOutResidentialAddress
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantOutResidentialAddress
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantOutResidentialAddress
     */
    pincode: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantOutResidentialAddress
     */
    country: string;
    /**
     * 
     * @type {AddressLine2}
     * @memberof ApplicantOutResidentialAddress
     */
    address_line_2?: AddressLine2;
    /**
     * 
     * @type {AddressLine3}
     * @memberof ApplicantOutResidentialAddress
     */
    address_line_3?: AddressLine3;
}
/**
 * 
 * @export
 * @interface ApplicantUpdate
 */
export interface ApplicantUpdate {
    /**
     * 
     * @type {ApplicantInDBMaritalStatus}
     * @memberof ApplicantUpdate
     */
    marital_status?: ApplicantInDBMaritalStatus;
    /**
     * 
     * @type {ApplicantInDBAnnualIncome}
     * @memberof ApplicantUpdate
     */
    annual_income?: ApplicantInDBAnnualIncome;
    /**
     * 
     * @type {ApplicantInDBOccupation}
     * @memberof ApplicantUpdate
     */
    occupation?: ApplicantInDBOccupation;
    /**
     * 
     * @type {BirthPlace}
     * @memberof ApplicantUpdate
     */
    birth_place?: BirthPlace;
    /**
     * 
     * @type {BirthCountry}
     * @memberof ApplicantUpdate
     */
    birth_country?: BirthCountry;
}

export enum ApplicationCreationModeEnum {
  diy = 'diy',
  assisted = 'assisted',
}

/**
 * 
 * @export
 * @interface ApplicationCreate
 */
export interface ApplicationCreate {
  mode: ApplicationCreationModeEnum;
  user_id: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationCreate
   */
  amount: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationCreate
   */
  interest_rate: number;
  /**
   *
   * @type {SchemeCategoryEnum}
   * @memberof ApplicationCreate
   */
  scheme_category: SchemeCategoryEnum;
  /**
   *
   * @type {InterestPayoutFrequencyEnum}
   * @memberof ApplicationCreate
   */
  interest_payout_frequency: InterestPayoutFrequencyEnum;
  /**
   *
   * @type {number}
   * @memberof ApplicationCreate
   */
  tenure: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationCreate
   */
  scheme_id: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationCreate
   */
  issuer_id: number;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationCreate
   */
  women_special: boolean;
  /**
   *
   * @type {ApplicantId}
   * @memberof ApplicationCreate
   */
  applicant_id?: ApplicantId;
  /**
   *
   * @type {HasNominee}
   * @memberof ApplicationCreate
   */
  has_nominee?: HasNominee;
  /**
   *
   * @type {TotalInterestAmount}
   * @memberof ApplicationCreate
   */
  calculated_maturity_amount?: TotalInterestAmount;
}
/**
 *
 * @export
 * @interface ApplicationInDB
 */
export interface ApplicationInDB {
  /**
   *
   * @type {number}
   * @memberof ApplicationInDB
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationInDB
   */
  user_id: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationInDB
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationInDB
   */
  updated_at: string;
  /**
   *
   * @type {TarrakkiId}
   * @memberof ApplicationInDB
   */
  tarrakki_id?: TarrakkiId;
  /**
   *
   * @type {PayableTo}
   * @memberof ApplicationInDB
   */
  payable_to?: PayableTo;
  /**
   *
   * @type {IpAddress}
   * @memberof ApplicationInDB
   */
  ip_address?: IpAddress;
  /**
   *
   * @type {ApplicationInDBTarrakkiStatus}
   * @memberof ApplicationInDB
   */
  tarrakki_status?: ApplicationInDBTarrakkiStatus;
  /**
   *
   * @type {number}
   * @memberof ApplicationInDB
   */
  amount: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationInDB
   */
  interest_rate: number;
  /**
   *
   * @type {SchemeCategoryEnum}
   * @memberof ApplicationInDB
   */
  scheme_category: SchemeCategoryEnum;
  /**
   *
   * @type {InterestPayoutFrequencyEnum}
   * @memberof ApplicationInDB
   */
  interest_payout_frequency: InterestPayoutFrequencyEnum;
  /**
   *
   * @type {number}
   * @memberof ApplicationInDB
   */
  tenure: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationInDB
   */
  scheme_id: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationInDB
   */
  issuer_id: number;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationInDB
   */
  women_special: boolean;
  /**
   *
   * @type {ApplicantId}
   * @memberof ApplicationInDB
   */
  applicant_id?: ApplicantId;
  /**
   *
   * @type {HasNominee}
   * @memberof ApplicationInDB
   */
  has_nominee?: HasNominee;
}
/**
 *
 * @export
 * @interface ApplicationInDBTarrakkiStatus
 */
export interface ApplicationInDBTarrakkiStatus {}
/**
 *
 * @export
 * @interface ApplicationOut
 */
export interface ApplicationOut {
  /**
   *
   * @type {number}
   * @memberof ApplicationOut
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationOut
   */
  user_id: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationOut
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationOut
   */
  updated_at: string;
  /**
   *
   * @type {TarrakkiId}
   * @memberof ApplicationOut
   */
  tarrakki_id?: TarrakkiId;
  /**
   *
   * @type {PayableTo}
   * @memberof ApplicationOut
   */
  payable_to?: PayableTo;
  /**
   *
   * @type {IpAddress}
   * @memberof ApplicationOut
   */
  ip_address?: IpAddress;
  /**
   *
   * @type {ApplicationInDBTarrakkiStatus}
   * @memberof ApplicationOut
   */
  tarrakki_status?: ApplicationInDBTarrakkiStatus;
  /**
   *
   * @type {number}
   * @memberof ApplicationOut
   */
  amount: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationOut
   */
  interest_rate: number;
  /**
   *
   * @type {SchemeCategoryEnum}
   * @memberof ApplicationOut
   */
  scheme_category: SchemeCategoryEnum;
  /**
   *
   * @type {InterestPayoutFrequencyEnum}
   * @memberof ApplicationOut
   */
  interest_payout_frequency: InterestPayoutFrequencyEnum;
  /**
   *
   * @type {number}
   * @memberof ApplicationOut
   */
  calculated_maturity_amount: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationOut
   */
  tenure: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationOut
   */
  scheme_id: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationOut
   */
  issuer_id: number;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationOut
   */
  women_special: boolean;
  /**
   *
   * @type {ApplicantId}
   * @memberof ApplicationOut
   */
  applicant_id?: ApplicantId;
  /**
   *
   * @type {HasNominee}
   * @memberof ApplicationOut
   */
  has_nominee?: HasNominee;
  /**
   *
   * @type {Array<DocumentOut>}
   * @memberof ApplicationOut
   */
  documents?: Array<DocumentOut>;
  /**
   *
   * @type {ApplicationOutPhoto}
   * @memberof ApplicationOut
   */
  photo?: ApplicationOutPhoto;
  /**
   *
   * @type {ApplicationOutBank}
   * @memberof ApplicationOut
   */
  bank?: ApplicationOutBank;
  /**
   *
   * @type {ApplicationOutNominee}
   * @memberof ApplicationOut
   */
  nominee?: ApplicationOutNominee;
  /**
   *
   * @type {Array<PaymentInDB>}
   * @memberof ApplicationOut
   */
  payments: Array<PaymentInDB>;
}
/**
 * 
 * @export
 * @interface ApplicationOutBank
 */
export interface ApplicationOutBank {
    /**
     * 
     * @type {AccountTypeEnum}
     * @memberof ApplicationOutBank
     */
    account_type: AccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationOutBank
     */
    ifsc: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationOutBank
     */
    account_number: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationOutBank
     */
    applicant_id: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationOutBank
     */
    id: number;
}
/**
 * 
 * @export
 * @interface ApplicationOutNominee
 */
export interface ApplicationOutNominee {
    /**
     * 
     * @type {number}
     * @memberof ApplicationOutNominee
     */
    applicant_id: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationOutNominee
     */
    salutation: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationOutNominee
     */
    first_name: string;
    /**
     * 
     * @type {MiddleName}
     * @memberof ApplicationOutNominee
     */
    middle_name?: MiddleName;
    /**
     * 
     * @type {string}
     * @memberof ApplicationOutNominee
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationOutNominee
     */
    date_of_birth: string;
    /**
     * 
     * @type {RelationEnum}
     * @memberof ApplicationOutNominee
     */
    relation: RelationEnum;
    /**
     * 
     * @type {number}
     * @memberof ApplicationOutNominee
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationOutNominee
     */
    user_id: number;
    /**
     * 
     * @type {NomineeOutGuardian}
     * @memberof ApplicationOutNominee
     */
    guardian?: NomineeOutGuardian;
}
/**
 * 
 * @export
 * @interface ApplicationOutPhoto
 */
export interface ApplicationOutPhoto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationOutPhoto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationOutPhoto
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationOutPhoto
     */
    updated_at: string;
    /**
     * 
     * @type {ApplicantId}
     * @memberof ApplicationOutPhoto
     */
    applicant_id?: ApplicantId;
    /**
     * 
     * @type {number}
     * @memberof ApplicationOutPhoto
     */
    user_id: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationOutPhoto
     */
    link: string;
}

/**
 * 
 * @export
 */
export const ApplicationStatus = {
    New: 'new',
    AwaitingPayment: 'awaiting_payment',
    AwaitingDocuments: 'awaiting_documents',
    UnderReview: 'under_review',
    Active: 'active',
    Matured: 'matured',
    Rejected: 'rejected'
} as const;
export type ApplicationStatus = typeof ApplicationStatus[keyof typeof ApplicationStatus];

/**
 * 
 * @export
 * @interface ApplicationUpdate
 */
export interface ApplicationUpdate {
    /**
     * 
     * @type {ApplicantId}
     * @memberof ApplicationUpdate
     */
    applicant_id?: ApplicantId;
    /**
     * 
     * @type {Amount}
     * @memberof ApplicationUpdate
     */
    amount?: Amount;
    /**
     * 
     * @type {InterestRate}
     * @memberof ApplicationUpdate
     */
    interest_rate?: InterestRate;
    /**
     * 
     * @type {ApplicationUpdateSchemeCategory}
     * @memberof ApplicationUpdate
     */
    scheme_category?: ApplicationUpdateSchemeCategory;
    /**
     * 
     * @type {ApplicationUpdateInterestPayoutFrequency}
     * @memberof ApplicationUpdate
     */
    interest_payout_frequency?: ApplicationUpdateInterestPayoutFrequency;
    /**
     * 
     * @type {Tenure}
     * @memberof ApplicationUpdate
     */
    tenure?: Tenure;
    /**
     * 
     * @type {WomenSpecial1}
     * @memberof ApplicationUpdate
     */
    women_special?: WomenSpecial1;
    /**
     * 
     * @type {SchemeId1}
     * @memberof ApplicationUpdate
     */
    scheme_id?: SchemeId1;
    /**
     * 
     * @type {TarrakkiId}
     * @memberof ApplicationUpdate
     */
    tarrakki_id?: TarrakkiId;
    /**
     * 
     * @type {PayableTo}
     * @memberof ApplicationUpdate
     */
    payable_to?: PayableTo;
    /**
     * 
     * @type {IpAddress}
     * @memberof ApplicationUpdate
     */
    ip_address?: IpAddress;
    /**
     * 
     * @type {ApplicationInDBTarrakkiStatus}
     * @memberof ApplicationUpdate
     */
    tarrakki_status?: ApplicationInDBTarrakkiStatus;
    /**
     * 
     * @type {HasNominee}
     * @memberof ApplicationUpdate
     */
    has_nominee?: HasNominee;
}
/**
 * 
 * @export
 * @interface ApplicationUpdateInterestPayoutFrequency
 */
export interface ApplicationUpdateInterestPayoutFrequency {
}
/**
 * 
 * @export
 * @interface ApplicationUpdateSchemeCategory
 */
export interface ApplicationUpdateSchemeCategory {
}
/**
 * 
 * @export
 * @interface BankCreateDTO
 */
export interface BankCreateDTO {
    /**
     * 
     * @type {AccountTypeEnum}
     * @memberof BankCreateDTO
     */
    account_type: AccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BankCreateDTO
     */
    ifsc: string;
    /**
     * 
     * @type {string}
     * @memberof BankCreateDTO
     */
    account_number: string;
    /**
     * 
     * @type {number}
     * @memberof BankCreateDTO
     */
    applicant_id: number;
    /**
     * 
     * @type {number}
     * @memberof BankCreateDTO
     */
    application_id: number;
}
/**
 * 
 * @export
 * @interface BankInDB
 */
export interface BankInDB {
    /**
     * 
     * @type {AccountTypeEnum}
     * @memberof BankInDB
     */
    account_type: AccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BankInDB
     */
    ifsc: string;
    /**
     * 
     * @type {string}
     * @memberof BankInDB
     */
    account_number: string;
    /**
     * 
     * @type {number}
     * @memberof BankInDB
     */
    applicant_id: number;
    /**
     * 
     * @type {number}
     * @memberof BankInDB
     */
    id: number;
}
/**
 * 
 * @export
 * @interface BankUpdate
 */
export interface BankUpdate {
    /**
     * 
     * @type {BankUpdateAccountType}
     * @memberof BankUpdate
     */
    account_type?: BankUpdateAccountType;
    /**
     * 
     * @type {Ifsc}
     * @memberof BankUpdate
     */
    ifsc?: Ifsc;
    /**
     * 
     * @type {AccountNumber}
     * @memberof BankUpdate
     */
    account_number?: AccountNumber;
}
/**
 * 
 * @export
 * @interface BankUpdateAccountType
 */
export interface BankUpdateAccountType {
}
/**
 * 
 * @export
 * @interface BirthCountry
 */
export interface BirthCountry {
}
/**
 * 
 * @export
 * @interface BirthPlace
 */
export interface BirthPlace {
}
/**
 * 
 * @export
 * @interface CalculationInput
 */
export interface CalculationInput {
    /**
     * 
     * @type {number}
     * @memberof CalculationInput
     */
    amount: number;
    /**
     * 
     * @type {SchemeCategoryEnum}
     * @memberof CalculationInput
     */
    scheme_category: SchemeCategoryEnum;
    /**
     * 
     * @type {InterestPayoutFrequencyEnum}
     * @memberof CalculationInput
     */
    interest_payout_frequency: InterestPayoutFrequencyEnum;
    /**
     * 
     * @type {number}
     * @memberof CalculationInput
     */
    tenure: number;
    /**
     * 
     * @type {IssuerId1}
     * @memberof CalculationInput
     */
    issuer_id?: IssuerId1;
    /**
     * 
     * @type {WomenSpecial}
     * @memberof CalculationInput
     */
    women_special?: WomenSpecial;
}
/**
 * 
 * @export
 * @interface CalculationResponse
 */
export interface CalculationResponse {
    /**
     * 
     * @type {number}
     * @memberof CalculationResponse
     */
    amount: number;
    /**
     * 
     * @type {SchemeCategoryEnum}
     * @memberof CalculationResponse
     */
    scheme_category: SchemeCategoryEnum;
    /**
     * 
     * @type {InterestPayoutFrequencyEnum}
     * @memberof CalculationResponse
     */
    interest_payout_frequency: InterestPayoutFrequencyEnum;
    /**
     * 
     * @type {number}
     * @memberof CalculationResponse
     */
    tenure: number;
    /**
     * 
     * @type {IssuerId1}
     * @memberof CalculationResponse
     */
    issuer_id?: IssuerId1;
    /**
     * 
     * @type {WomenSpecial}
     * @memberof CalculationResponse
     */
    women_special?: WomenSpecial;
    /**
     * 
     * @type {GetSchemesType}
     * @memberof CalculationResponse
     */
    scheme_type: GetSchemesType;
    /**
     * 
     * @type {Array<CalculationResult>}
     * @memberof CalculationResponse
     */
    results: Array<CalculationResult>;
}
/**
 * 
 * @export
 * @interface CalculationResult
 */
export interface CalculationResult {
    /**
     * 
     * @type {string}
     * @memberof CalculationResult
     */
    issuer_id: string;
    /**
     * 
     * @type {string}
     * @memberof CalculationResult
     */
    scheme_id: string;
    /**
     * 
     * @type {number}
     * @memberof CalculationResult
     */
    maturity_amount: number;
    /**
     * 
     * @type {number}
     * @memberof CalculationResult
     */
    interest_rate: number;
    /**
     * 
     * @type {number}
     * @memberof CalculationResult
     */
    interest_amount: number;
    /**
     * 
     * @type {TotalInterestAmount}
     * @memberof CalculationResult
     */
    total_interest_amount?: TotalInterestAmount;
    /**
     * 
     * @type {boolean}
     * @memberof CalculationResult
     */
    women_special: boolean;
    /**
     * 
     * @type {Amount}
     * @memberof CalculationResult
     */
    amount?: Amount;
    /**
     * 
     * @type {InterestYield}
     * @memberof CalculationResult
     */
    interest_yield?: InterestYield;
}
/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
}
/**
 * 
 * @export
 * @interface CkycNumber
 */
export interface CkycNumber {
}
/**
 * 
 * @export
 * @interface ConsentCreate
 */
export interface ConsentCreate {
    /**
     * 
     * @type {number}
     * @memberof ConsentCreate
     */
    application_id: number;
    /**
     * 
     * @type {string}
     * @memberof ConsentCreate
     */
    text: string;
}
/**
 * 
 * @export
 * @interface ConsentOut
 */
export interface ConsentOut {
    /**
     * 
     * @type {number}
     * @memberof ConsentOut
     */
    application_id: number;
    /**
     * 
     * @type {string}
     * @memberof ConsentOut
     */
    text: string;
    /**
     * 
     * @type {number}
     * @memberof ConsentOut
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ConsentOut
     */
    user_id: number;
    /**
     * 
     * @type {number}
     * @memberof ConsentOut
     */
    applicant_id: number;
    /**
     * 
     * @type {number}
     * @memberof ConsentOut
     */
    issuer_id: number;
    /**
     * 
     * @type {string}
     * @memberof ConsentOut
     */
    ip_address: string;
}

/**
 * 
 * @export
 */
export const CreatePaymentJsonBodyPaymentMethod = {
  Netbanking: 'netbanking',
  Upi: 'upi',
  NEFT_RTGS: 'neft_rtgs',
} as const;
export type CreatePaymentJsonBodyPaymentMethod =
  (typeof CreatePaymentJsonBodyPaymentMethod)[keyof typeof CreatePaymentJsonBodyPaymentMethod];

/**
 *
 * @export
 * @interface DateOfBirth
 */
export interface DateOfBirth {}
/**
 *
 * @export
 * @interface DocumentOut
 */
export interface DocumentOut {
  /**
   *
   * @type {number}
   * @memberof DocumentOut
   */
  id: number;
  /**
   *
   * @type {ApplicantId}
   * @memberof DocumentOut
   */
  applicant_id?: ApplicantId;
  /**
   *
   * @type {number}
   * @memberof DocumentOut
   */
  user_id: number;
  /**
   *
   * @type {Link}
   * @memberof DocumentOut
   */
  link?: Link;
  /**
   *
   * @type {DocumentTypeEnum}
   * @memberof DocumentOut
   */
  type: DocumentTypeEnum;
  /**
   *
   * @type {ExpiryDate}
   * @memberof DocumentOut
   */
  expiry_date?: ExpiryDate;
  /**
   *
   * @type {string}
   * @memberof DocumentOut
   */
  document_number: string;
}

/**
 *
 * @export
 */
export const DocumentTypeEnum = {
  Pan: 'PAN',
  DrivingLicense: 'DRIVING_LICENSE',
  Voterid: 'VOTERID',
  Aadhaar: 'AADHAAR',
  AadhaarFront: 'AADHAAR_FRONT',
  AadhaarBack: 'AADHAAR_BACK',
  Passport: 'PASSPORT',
} as const;
export type DocumentTypeEnum =
  (typeof DocumentTypeEnum)[keyof typeof DocumentTypeEnum];

/**
 *
 * @export
 * @interface ExpiryDate
 */
export interface ExpiryDate {}
/**
 *
 * @export
 * @interface FAQInDB
 */
export interface FAQInDB {
  /**
   *
   * @type {number}
   * @memberof FAQInDB
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof FAQInDB
   */
  question: string;
  /**
   *
   * @type {string}
   * @memberof FAQInDB
   */
  answer: string;
}
/**
 *
 * @export
 * @interface FirstName
 */
export interface FirstName {}
/**
 *
 * @export
 * @interface Gender
 */
export interface Gender {}

/**
 *
 * @export
 */
export const GetSchemesCategory = {
  General: 'general',
  SeniorCitizen: 'senior_citizen',
} as const;
export type GetSchemesCategory =
  (typeof GetSchemesCategory)[keyof typeof GetSchemesCategory];

/**
 *
 * @export
 */
export const GetSchemesResponse200SchemesItemStatus = {
  Active: 'active',
  Inactive: 'inactive',
} as const;
export type GetSchemesResponse200SchemesItemStatus =
  (typeof GetSchemesResponse200SchemesItemStatus)[keyof typeof GetSchemesResponse200SchemesItemStatus];

/**
 *
 * @export
 */
export const GetSchemesStatus = {
  Active: 'active',
  Inactive: 'inactive',
} as const;
export type GetSchemesStatus =
  (typeof GetSchemesStatus)[keyof typeof GetSchemesStatus];

/**
 *
 * @export
 */
export const GetSchemesType = {
  Cumulative: 'cumulative',
  NonCumulative: 'non_cumulative',
} as const;
export type GetSchemesType =
  (typeof GetSchemesType)[keyof typeof GetSchemesType];

/**
 *
 * @export
 * @interface GuardianCreate
 */
export interface GuardianCreate {
  /**
   *
   * @type {number}
   * @memberof GuardianCreate
   */
  nominee_id: number;
  /**
   *
   * @type {string}
   * @memberof GuardianCreate
   */
  salutation: string;
  /**
   *
   * @type {string}
   * @memberof GuardianCreate
   */
  first_name: string;
  /**
   *
   * @type {MiddleName}
   * @memberof GuardianCreate
   */
  middle_name?: MiddleName;
  /**
   *
   * @type {string}
   * @memberof GuardianCreate
   */
  last_name: string;
}
/**
 *
 * @export
 * @interface GuardianInDB
 */
export interface GuardianInDB {
  /**
   *
   * @type {number}
   * @memberof GuardianInDB
   */
  nominee_id: number;
  /**
   *
   * @type {string}
   * @memberof GuardianInDB
   */
  salutation: string;
  /**
   *
   * @type {string}
   * @memberof GuardianInDB
   */
  first_name: string;
  /**
   *
   * @type {MiddleName}
   * @memberof GuardianInDB
   */
  middle_name?: MiddleName;
  /**
   *
   * @type {string}
   * @memberof GuardianInDB
   */
  last_name: string;
  /**
   *
   * @type {number}
   * @memberof GuardianInDB
   */
  id: number;
}
/**
 *
 * @export
 * @interface GuardianUpdate
 */
export interface GuardianUpdate {
  /**
   *
   * @type {Salutation}
   * @memberof GuardianUpdate
   */
  salutation?: Salutation;
  /**
   *
   * @type {FirstName}
   * @memberof GuardianUpdate
   */
  first_name?: FirstName;
  /**
   *
   * @type {MiddleName}
   * @memberof GuardianUpdate
   */
  middle_name?: MiddleName;
  /**
   *
   * @type {LastName}
   * @memberof GuardianUpdate
   */
  last_name?: LastName;
}
/**
 *
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
  /**
   *
   * @type {Array<ValidationError>}
   * @memberof HTTPValidationError
   */
  detail?: Array<ValidationError>;
}
/**
 *
 * @export
 * @interface HasNominee
 */
export interface HasNominee {}
/**
 *
 * @export
 * @interface Ifsc
 */
export interface Ifsc {}

/**
 *
 * @export
 */
export const InterestPayoutFrequencyEnum = {
  HalfYearly: 'half_yearly',
  Maturity: 'maturity',
  Monthly: 'monthly',
  Quarterly: 'quarterly',
  Yearly: 'yearly',
} as const;
export type InterestPayoutFrequencyEnum =
  (typeof InterestPayoutFrequencyEnum)[keyof typeof InterestPayoutFrequencyEnum];

/**
 *
 * @export
 * @interface InterestRate
 */
export interface InterestRate {}
/**
 *
 * @export
 * @interface InterestYield
 */
export interface InterestYield {}
/**
 *
 * @export
 * @interface IpAddress
 */
export interface IpAddress {}
/**
 *
 * @export
 * @interface IssuerConsentInDB
 */
export interface IssuerConsentInDB {
  /**
   *
   * @type {number}
   * @memberof IssuerConsentInDB
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof IssuerConsentInDB
   */
  issuer_id: number;
  /**
   *
   * @type {string}
   * @memberof IssuerConsentInDB
   */
  text: string;
  /**
   *
   * @type {string}
   * @memberof IssuerConsentInDB
   */
  link: string;
  /**
   *
   * @type {string}
   * @memberof IssuerConsentInDB
   */
  link_label: string;
}
/**
 * Optional issuer ID
 * @export
 * @interface IssuerId
 */
export interface IssuerId {}
/**
 *
 * @export
 * @interface IssuerId1
 */
export interface IssuerId1 {}
/**
 *
 * @export
 * @interface IssuerOut
 */
export interface IssuerOut {
  /**
   *
   * @type {number}
   * @memberof IssuerOut
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof IssuerOut
   */
  tarraki_id: string;
  /**
   *
   * @type {string}
   * @memberof IssuerOut
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof IssuerOut
   */
  logo: string;
  /**
   *
   * @type {string}
   * @memberof IssuerOut
   */
  website: string;
  /**
   *
   * @type {number}
   * @memberof IssuerOut
   */
  min_interest_rate: number;
  /**
   *
   * @type {number}
   * @memberof IssuerOut
   */
  max_interest_rate: number;
  /**
   *
   * @type {number}
   * @memberof IssuerOut
   */
  min_amount: number;
  /**
   *
   * @type {number}
   * @memberof IssuerOut
   */
  max_amount: number;
  /**
   *
   * @type {number}
   * @memberof IssuerOut
   */
  min_tenure: number;
  /**
   *
   * @type {number}
   * @memberof IssuerOut
   */
  mul_amount: number;
  /**
   *
   * @type {number}
   * @memberof IssuerOut
   */
  max_tenure: number;
  /**
   *
   * @type {number}
   * @memberof IssuerOut
   */
  lock_in_min: number;
  /**
   *
   * @type {number}
   * @memberof IssuerOut
   */
  lock_in_max: number;
  /**
   *
   * @type {number}
   * @memberof IssuerOut
   */
  min_age_min: number;
  /**
   *
   * @type {number}
   * @memberof IssuerOut
   */
  min_age_max: number;
  /**
   *
   * @type {About}
   * @memberof IssuerOut
   */
  about?: About;
  /**
   *
   * @type {SeniorCitizenBenfit}
   * @memberof IssuerOut
   */
  senior_citizen_benfit?: SeniorCitizenBenfit;
  /**
   * Line no.1983 and 1984 is added manually, these field's interfaces should be auto
   */
  senior_citizen_additional_interest_rate?: number | null;
  woman_additional_interest_rate?: number | null;
  neft_rtgs_instructions: string;
  /**
   *
   * @type {Array<TagInDB>}
   * @memberof IssuerOut
   */
  tags: Array<TagInDB>;
  /**
   *
   * @type {Array<FAQInDB>}
   * @memberof IssuerOut
   */
  faq: Array<FAQInDB>;
}
/**
 *
 * @export
 * @interface LastName
 */
export interface LastName {}
/**
 *
 * @export
 * @interface Link
 */
export interface Link {}
/**
 *
 * @export
 * @interface LocationInner
 */
export interface LocationInner {}

/**
 *
 * @export
 */
export const MaritalStatusEnum = {
  Married: 'married',
  Unmarried: 'unmarried',
  Other: 'other',
} as const;
export type MaritalStatusEnum =
  (typeof MaritalStatusEnum)[keyof typeof MaritalStatusEnum];

/**
 *
 * @export
 * @interface MiddleName
 */
export interface MiddleName {}
/**
 *
 * @export
 * @interface Mobile
 */
export interface Mobile {}
/**
 *
 * @export
 * @interface Nationality
 */
export interface Nationality {}
/**
 *
 * @export
 * @interface NomineeCreate
 */
export interface NomineeCreate {
  user_id: string;
  /**
   *
   * @type {number}
   * @memberof NomineeCreate
   */
  application_id: number;
  /**
   *
   * @type {number}
   * @memberof NomineeCreate
   */
  applicant_id: number;
  /**
   *
   * @type {string}
   * @memberof NomineeCreate
   */
  salutation: string;
  /**
   *
   * @type {string}
   * @memberof NomineeCreate
   */
  first_name: string;
  /**
   *
   * @type {MiddleName}
   * @memberof NomineeCreate
   */
  middle_name?: MiddleName;
  /**
   *
   * @type {string}
   * @memberof NomineeCreate
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof NomineeCreate
   */
  date_of_birth: string;
  /**
   *
   * @type {RelationEnum}
   * @memberof NomineeCreate
   */
  relation: RelationEnum;
}
/**
 *
 * @export
 * @interface NomineeOut
 */
export interface NomineeOut {
  /**
   *
   * @type {number}
   * @memberof NomineeOut
   */
  applicant_id: number;
  /**
   *
   * @type {string}
   * @memberof NomineeOut
   */
  salutation: string;
  /**
   *
   * @type {string}
   * @memberof NomineeOut
   */
  first_name: string;
  /**
   *
   * @type {MiddleName}
   * @memberof NomineeOut
   */
  middle_name?: MiddleName;
  /**
   *
   * @type {string}
   * @memberof NomineeOut
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof NomineeOut
   */
  date_of_birth: string;
  /**
   *
   * @type {RelationEnum}
   * @memberof NomineeOut
   */
  relation: RelationEnum;
  /**
   *
   * @type {number}
   * @memberof NomineeOut
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof NomineeOut
   */
  user_id: number;
  /**
   *
   * @type {NomineeOutGuardian}
   * @memberof NomineeOut
   */
  guardian?: NomineeOutGuardian;
}
/**
 *
 * @export
 * @interface NomineeOutGuardian
 */
export interface NomineeOutGuardian {
  /**
   *
   * @type {number}
   * @memberof NomineeOutGuardian
   */
  nominee_id: number;
  /**
   *
   * @type {string}
   * @memberof NomineeOutGuardian
   */
  salutation: string;
  /**
   *
   * @type {string}
   * @memberof NomineeOutGuardian
   */
  first_name: string;
  /**
   *
   * @type {MiddleName}
   * @memberof NomineeOutGuardian
   */
  middle_name?: MiddleName;
  /**
   *
   * @type {string}
   * @memberof NomineeOutGuardian
   */
  last_name: string;
  /**
   *
   * @type {number}
   * @memberof NomineeOutGuardian
   */
  id: number;
}
/**
 *
 * @export
 * @interface NomineeUpdate
 */
export interface NomineeUpdate {
  /**
   *
   * @type {Salutation}
   * @memberof NomineeUpdate
   */
  salutation?: Salutation;
  /**
   *
   * @type {FirstName}
   * @memberof NomineeUpdate
   */
  first_name?: FirstName;
  /**
   *
   * @type {MiddleName}
   * @memberof NomineeUpdate
   */
  middle_name?: MiddleName;
  /**
   *
   * @type {LastName}
   * @memberof NomineeUpdate
   */
  last_name?: LastName;
  /**
   *
   * @type {DateOfBirth}
   * @memberof NomineeUpdate
   */
  date_of_birth?: DateOfBirth;
  /**
   *
   * @type {NomineeUpdateRelation}
   * @memberof NomineeUpdate
   */
  relation?: NomineeUpdateRelation;
}
/**
 *
 * @export
 * @interface NomineeUpdateRelation
 */
export interface NomineeUpdateRelation {}

/**
 *
 * @export
 */
export const OccupationEnum = {
  Business: 'business',
  Agriculture: 'agriculture',
  ForexDealer: 'forex_dealer',
  ServicePublicSector: 'service_public_sector',
  ServicePrivateSector: 'service_private_sector',
  ServiceGovernmentSector: 'service_government_sector',
  OthersProfessional: 'others_professional',
  OthersSelfEmployed: 'others_self_employed',
  OthersRetired: 'others_retired',
  OthersHousewife: 'others_housewife',
  OthersStudent: 'others_student',
  Others: 'others',
} as const;
export type OccupationEnum =
  (typeof OccupationEnum)[keyof typeof OccupationEnum];

/**
 *
 * @export
 * @interface PDFData
 */
export interface PDFData {
  /**
   *
   * @type {string}
   * @memberof PDFData
   */
  data_url: string;
}
/**
 *
 * @export
 * @interface PayableTo
 */
export interface PayableTo {}
/**
 *
 * @export
 * @interface PaymentInDB
 */
export interface PaymentInDB {
  redirect_url: string;
  /**
   *
   * @type {number}
   * @memberof PaymentInDB
   */
  user_id: number;
  /**
   *
   * @type {number}
   * @memberof PaymentInDB
   */
  application_id: number;
  /**
   *
   * @type {CreatePaymentJsonBodyPaymentMethod}
   * @memberof PaymentInDB
   */
  payment_method: CreatePaymentJsonBodyPaymentMethod;
  /**
   *
   * @type {string}
   * @memberof PaymentInDB
   */
  tarrakki_id: string;
  /**
   *
   * @type {number}
   * @memberof PaymentInDB
   */
  id: number;
  /**
   *
   * @type {PaymentInDBStatus}
   * @memberof PaymentInDB
   */
  status: PaymentInDBStatus;
  /**
   *
   * @type {string}
   * @memberof PaymentInDB
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof PaymentInDB
   */
  updated_at: string;
}
/**
 *
 * @export
 * @interface PaymentInDBStatus
 */
export interface PaymentInDBStatus {}

/**
 *
 * @export
 */
export const PaymentStatusEnum = {
  New: 'new',
  Success: 'success',
  Failed: 'failed',
} as const;
export type PaymentStatusEnum =
  (typeof PaymentStatusEnum)[keyof typeof PaymentStatusEnum];

/**
 *
 * @export
 * @interface PaymentUpdate
 */
export interface PaymentUpdate {
  /**
   *
   * @type {string}
   * @memberof PaymentUpdate
   */
  status: string;
}
/**
 *
 * @export
 * @interface PermanentAddressSameAsResidential
 */
export interface PermanentAddressSameAsResidential {}
/**
 *
 * @export
 * @interface PhotoOut
 */
export interface PhotoOut {
  /**
   *
   * @type {number}
   * @memberof PhotoOut
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof PhotoOut
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof PhotoOut
   */
  updated_at: string;
  /**
   *
   * @type {ApplicantId}
   * @memberof PhotoOut
   */
  applicant_id?: ApplicantId;
  /**
   *
   * @type {number}
   * @memberof PhotoOut
   */
  user_id: number;
  /**
   *
   * @type {string}
   * @memberof PhotoOut
   */
  link: string;
}

export enum PaymentLinkInstructionEnum {
  'share_via_email' = 'share_via_email',
}
/**
 *
 * @export
 * @interface PostPaymentInput
 */
export interface PostPaymentInput {
  payment_link_instruction?: PaymentLinkInstructionEnum[];
  review_url?: string;
  /**
   *
   * @type {number}
   * @memberof PostPaymentInput
   */
  application_id: number;
  /**
   *
   * @type {CreatePaymentJsonBodyPaymentMethod}
   * @memberof PostPaymentInput
   */
  payment_method: CreatePaymentJsonBodyPaymentMethod;
  /**
   *
   * @type {string}
   * @memberof PostPaymentInput
   */
  callback_url: string;
  utr_number?: string;
}

/**
 * 
 * @export
 */
export const RelationEnum = {
    Brother: 'brother',
    Sister: 'sister',
    Father: 'father',
    Mother: 'mother',
    Son: 'son',
    Daughter: 'daughter',
    Husband: 'husband',
    Wife: 'wife',
    Other: 'other'
} as const;
export type RelationEnum = typeof RelationEnum[keyof typeof RelationEnum];

/**
 * 
 * @export
 * @interface Salutation
 */
export interface Salutation {
}

/**
 * 
 * @export
 */
export const SalutationEnum = {
    Mr: 'mr',
    Mrs: 'mrs',
    Ms: 'ms'
} as const;
export type SalutationEnum = typeof SalutationEnum[keyof typeof SalutationEnum];

/**
 * 
 * @export
 * @interface Scheme
 */
export interface Scheme {
    /**
     * 
     * @type {string}
     * @memberof Scheme
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Scheme
     */
    issuer_id: string;
    /**
     * 
     * @type {GetSchemesType}
     * @memberof Scheme
     */
    type: GetSchemesType;
    /**
     * 
     * @type {InterestPayoutFrequencyEnum}
     * @memberof Scheme
     */
    interest_payout_frequency: InterestPayoutFrequencyEnum;
    /**
     * 
     * @type {number}
     * @memberof Scheme
     */
    interest_rate: number;
    /**
     * 
     * @type {SchemeCategoryEnum}
     * @memberof Scheme
     */
    category: SchemeCategoryEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Scheme
     */
    women_special: boolean;
    /**
     * 
     * @type {number}
     * @memberof Scheme
     */
    min_amount: number;
    /**
     * 
     * @type {number}
     * @memberof Scheme
     */
    max_amount: number;
    /**
     * 
     * @type {number}
     * @memberof Scheme
     */
    min_tenure: number;
    /**
     * 
     * @type {number}
     * @memberof Scheme
     */
    max_tenure: number;
    /**
     * 
     * @type {number}
     * @memberof Scheme
     */
    lock_in_period: number;
    /**
     * 
     * @type {number}
     * @memberof Scheme
     */
    min_age: number;
    /**
     * 
     * @type {GetSchemesResponse200SchemesItemStatus}
     * @memberof Scheme
     */
    status: GetSchemesResponse200SchemesItemStatus;
}

/**
 * 
 * @export
 */
export const SchemeCategoryEnum = {
    General: 'general',
    SeniorCitizen: 'senior_citizen'
} as const;
export type SchemeCategoryEnum = typeof SchemeCategoryEnum[keyof typeof SchemeCategoryEnum];

/**
 * 
 * @export
 * @interface SchemeId
 */
export interface SchemeId {
}
/**
 * 
 * @export
 * @interface SchemeId1
 */
export interface SchemeId1 {
}
/**
 * 
 * @export
 * @interface SchemesResponse
 */
export interface SchemesResponse {
    /**
     * 
     * @type {number}
     * @memberof SchemesResponse
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof SchemesResponse
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof SchemesResponse
     */
    offset: number;
    /**
     * 
     * @type {Array<Scheme>}
     * @memberof SchemesResponse
     */
    schemes: Array<Scheme>;
}
/**
 * 
 * @export
 * @interface SeniorCitizenBenfit
 */
export interface SeniorCitizenBenfit {
}
/**
 * 
 * @export
 * @interface TagInDB
 */
export interface TagInDB {
    /**
     * 
     * @type {number}
     * @memberof TagInDB
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TagInDB
     */
    name: string;
}
/**
 * 
 * @export
 * @interface TarrakkiId
 */
export interface TarrakkiId {
}
/**
 * 
 * @export
 * @interface Tenure
 */
export interface Tenure {
}
/**
 * 
 * @export
 * @interface TotalInterestAmount
 */
export interface TotalInterestAmount {
}
/**
 * 
 * @export
 * @interface Type
 */
export interface Type {
}
/**
 * 
 * @export 
 * @interface ScopesInDB
 */
export type ScopesInDB = 'asset_class:mutual_fund' | 'asset_class:deposit';
/**
 *
 * @export
 * @interface UserInDB
 */
export interface UserInDB {
  /**
   *
   * @type {number}
   * @memberof UserInDB
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof UserInDB
   */
  user_id: number;
  /**
   *
   * @type {string}
   * @memberof UserInDB
   */
  email: string;
  /**
   *
   * @type {Mobile}
   * @memberof UserInDB
   */
  mobile?: Mobile;
  /**
   *
   * @type {string}
   * @memberof UserInDB
   */
  auth_username: string;
  /**
   *
   * @type {ScopesInDB}
   * @memberof UserInDB
   */
  scopes: ScopesInDB[];
  /**
   * @type {string}
   * @memberof UserInDB
   */
  first_name: string;
  /**
   * @type {string}
   * @memberof UserInDB
   */
  last_name: string;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<LocationInner>}
     * @memberof ValidationError
     */
    loc: Array<LocationInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    msg: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    type: string;
}
/**
 * 
 * @export
 * @interface WomenSpecial
 */
export interface WomenSpecial {
}
/**
 * 
 * @export
 * @interface WomenSpecial1
 */
export interface WomenSpecial1 {
}
