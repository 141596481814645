import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DropdownList from '../dropdown-list/DropdownList';
const SelectBox = ({ onUpdate, id, title, value, options = [], placeholder = '', classes = {}, err }) => {
  const onDropDownChange = (event, obj) => {
    const { value } = obj;
    if (onUpdate) onUpdate(value);
  };
  const getLabel = (value) => {
    const filterData = options.filter((item) => item.value === value);
    return filterData && filterData.length ? filterData[0].label : '';
  };
  return (
    <Form.Group className="my-3">
      <Form.Label>{title}</Form.Label>
      <DropdownList
        classes={{ input: 'font-weight-medium updated-select', ...classes }}
        selectedOption={getLabel(value)}
        id={id}
        placeholder={placeholder}
        controlFunc={onDropDownChange}
        options={options}
      />
      {err && <div className={'invalid-feedback d-block'}>{err}</div>}
    </Form.Group>
  );
};
SelectBox.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.object,
  title: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onUpdate: PropTypes.func,
  options: PropTypes.array,
};
export default SelectBox;
