import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bankUserMandate, getMandateById, getUserBanks } from '../../api/bank';
import { getHomeData } from '../../api/nav';
import greenCheckIcon from '../../assets/green-check-white.svg';
import { decryptData } from '../../utility/DecryptHelper';
import DataMsg from '../../common/data-msg/DataMsg';
import { useHistory } from 'react-router-dom';
import { updateBankIDAction } from '../../redux/reducers/mandate-reducer';
const BankAccounts = () => {
  const userId = useSelector((state) => state.authReducer.user_id);
  const { ready_to_invest } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const [bankList, setBankList] = useState([]);
  const [, setHomeData] = useState({});
  const [mandateData, setMandateData] = useState();

  const history = useHistory();
  useEffect(() => {
    getHomeData().then((res) => {
      if (res && res.data) {
        const homeData = decryptData(res.data.data);
        if (homeData) {
          setHomeData(homeData);
        }
      }
    });
    getBankUserMandate();
  }, []);

  const getStatus = (status) => {
    let curStatus = { status: 'Verified', class: 'c-green' };
    if (status === 'REJECTED') {
      curStatus = { status: 'Rejected', class: 'c-red' };
    } else if (status === 'UPLOADED') {
      curStatus = { status: 'Pending', class: 'c-yellow' };
    } else if (status === 'UPLOADEDTOBSE') {
      curStatus = { status: 'Verified', class: 'c-green' };
    } else if (status === 'VERIFIEDBYADMIN') {
      curStatus = { status: 'Verified', class: 'c-green' };
    }
    return curStatus;
  };

  const getBankUserMandate = () => {
    bankUserMandate(userId).then((res) => {
      if (res && res.data) {
        const mandate = decryptData(res.data.data);
        setMandateData(mandate.data);
      }
      getUserBanks(userId).then((res) => {
        if (res && res.data) {
          const data = decryptData(res.data.data);
          setBankList(data?.data?.bank_details);
        }
      });
    });
  };

  const getGetBankMandateDetails = (bankId) => {
    let result = mandateData.filter((mandateObject) => {
      return mandateObject.bank_details.bank_id === bankId ? mandateObject : null;
    });
    result.sort((a, b) => {
      return a.id - b.id;
    });
    return result[result.length - 1];
  };

  const getMandateType = (mandateType) => {
    if (mandateType === 'N') {
      return 'e-NACH';
    } else if (mandateType === 'X') {
      return 'NACH';
    } else {
      return 'I';
    }
  };

  const goToBankAutoPay = (bankId, status, url) => {
    if (status === 'autopay') {
      dispatch(updateBankIDAction(bankId));
      history.push({ pathname: `/profile/bank-autopay/${bankId}/${userId}` });
    } else {
      window.location.href = url;
    }
  };

  const completeNachMandate = (bankId, mandateId, type, maxautopayLimit) => {
    if (type === 'NACH') {
      history.push({ pathname: `/bank-mandate/${bankId}/${userId}`, state: { mandateID: mandateId, status: 'mandate-completed', flow: type } });
    } else if (type === 'e-NACH') {
      getMandateById(mandateId).then((response) => {
        const data = response.data;
        if (data.redirection_url) {
          history.push({
            pathname: `/bank-mandate/${bankId}/${userId}`,
            state: {
              mandateID: mandateId,
              status: 'mandate-completed',
              flow: type,
              maxautopaylimit: maxautopayLimit,
              redirectionUrl: data.redirection_url,
            },
          });
        } else {
          history.push({
            pathname: `/bank-mandate/${bankId}/${userId}`,
            state: { mandateID: mandateId, status: 'mandate-completed', flow: type, maxautopaylimit: maxautopayLimit },
          });
        }
      });
    }
  };

  return (
    <div className="bank-account-section p-4">
      <div className="h5 font-weight-bold">Bank Accounts</div>
      <p className="small opacity-50 mb-1">Bank Account(s), from which investments are made</p>
      <p className="small opacity-50">Any investments you redeem, will be directly credited to your linked bank account(s)</p>
      <div className="position-relative user-bank-list">
        {bankList && bankList.length ? (
          bankList.map((bankDetail, index) => {
            const statusObj = getStatus(bankDetail.status);
            const mandateObject = getGetBankMandateDetails(bankDetail.id);
            return (
              <section key={'bank-detail-' + bankDetail.id}>
                <div className="border p-4 rounded my-2" key={'bank-detail-' + bankDetail.id}>
                  <div className="d-flex justify-content-between mb-2 small opacity-50">
                    <span>
                      {bankDetail.branch__bank_id__bank_name}, {bankDetail.branch__branch_name}
                    </span>
                    {bankDetail.is_default ? <span className="c-blue">Primary Account</span> : null}
                  </div>
                  <div className="h6 font-weight-bold d-flex justify-content-between mb-2">
                    <span>
                      {bankDetail.account_type_bse === 'SB' ? 'Savings Account' : 'Current Account'} {bankDetail.account_number}
                    </span>
                    {bankDetail.is_verified ? <img src={greenCheckIcon} alt="account default" className="ml-2" /> : null}
                    <span className={'ml-1 font-weight-bold ' + statusObj.class}>{statusObj.status}</span>
                  </div>
                  <div className="col-sm-6 d-flex justify-content-between mb-2 px-0 small opacity-50">
                    <span className="col-sm-6 px-0">Bank Account Proof</span>
                    <span className="col-sm-6 px-0">IFSC Code</span>
                  </div>
                  <div className="col-sm-6 d-flex justify-content-between mb-3 px-0 small opacity-50">
                    <span className="col-sm-6 px-0">
                      Other
                      {/* <img src={keys.media_base_url + bankDetail.verification_document} alt="bank document" /> */}
                    </span>
                    <span className="col-sm-6 px-0">{bankDetail.branch__ifsc_code}</span>
                  </div>
                  {statusObj.status === 'Verified' && (
                    <div className="d-flex justify-content-between mb-2 px-0 small">
                      {mandateObject?.amount && mandateObject?.mandate_type && (
                        <span className="opacity-50">
                          Auto Pay Limit :{' '}
                          {mandateObject?.amount.toLocaleString('en-IN', {
                            style: 'currency',
                            currency: 'INR',
                            maximumFractionDigits: 0,
                          })}{' '}
                          ({' '}
                          {mandateObject?.status !== 'UNDER PROCESSING' &&
                            mandateObject?.status !== 'INITIAL REJECTION' &&
                            getMandateType(mandateObject.mandate_type)}{' '}
                          {mandateObject?.status === 'FAILED'
                            ? 'Autopay Status is Failed'
                            : mandateObject?.status === 'REJECTED'
                            ? 'Rejected'
                            : mandateObject?.status === 'REGISTERED BY MEMBER'
                            ? 'In Progress'
                            : mandateObject?.status === 'WAITING FOR CLIENT AUTHENTICATION'
                            ? 'In Progress'
                            : mandateObject?.status === 'SCAN IMAGE NOT UPLOADED'
                            ? 'In Progress'
                            : mandateObject?.status === 'UNDER PROCESSING'
                            ? 'Your application is being processed'
                            : mandateObject?.status === 'INITIAL REJECTION'
                            ? 'Your application is being processed'
                            : ''}{' '}
                          ){' '}
                        </span>
                      )}
                      {/*<span className={'ml-1 font-weight-bold ' + statusObj.class}>{statusObj.status}</span>*/}
                      {mandateObject?.status === 'APPROVED' && <span className={'h6 ml-1 font-weight-bold c-green '}>Approved</span>}
                      {mandateObject?.status === 'REJECTED' && ready_to_invest && (
                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          onClick={() => {
                            goToBankAutoPay(bankDetail.id, 'autopay', '');
                          }}>
                          {' '}
                          Setup AutoPay{' '}
                        </button>
                      )}{' '}
                      {/*Rejected is not the response needs to check */}
                      {((mandateObject?.status === 'REGISTERED BY MEMBER' && !mandateObject?.is_mandate_uploaded) ||
                        mandateObject?.status === 'SCAN IMAGE NOT UPLOADED' ||
                        mandateObject?.status === 'WAITING FOR CLIENT AUTHENTICATION' ||
                        (mandateObject?.status === 'INITIAL REJECTION' && !mandateObject.is_mandate_uploaded)) &&
                        getMandateType(mandateObject.mandate_type) === 'NACH' && (
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={() =>
                              completeNachMandate(bankDetail.id, mandateObject.id, getMandateType(mandateObject.mandate_type), mandateObject.amount)
                            }>
                            {' '}
                            Complete AutoPay{' '}
                          </button>
                        )}
                      {(mandateObject?.status === 'REGISTERED BY MEMBER' || mandateObject?.status === 'WAITING FOR CLIENT AUTHENTICATION') &&
                        getMandateType(mandateObject.mandate_type) === 'e-NACH' && (
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={() =>
                              completeNachMandate(bankDetail.id, mandateObject.id, getMandateType(mandateObject.mandate_type), mandateObject.amount)
                            }>
                            {' '}
                            Complete AutoPay{' '}
                          </button>
                        )}
                      {mandateObject?.status === 'INITIAL REJECTION' && mandateObject?.mandate_type === 'I' && (
                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          onClick={() =>
                            completeNachMandate(bankDetail.id, mandateObject.id, getMandateType(mandateObject.mandate_type), mandateObject.amount)
                          }>
                          {' '}
                          Complete AutoPay{' '}
                        </button>
                      )}
                      {mandateObject?.status === 'SCAN IMAGE NOT UPLOADED' && mandateObject?.mandate_type === 'I' && (
                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          onClick={() =>
                            completeNachMandate(bankDetail.id, mandateObject.id, getMandateType(mandateObject.mandate_type), mandateObject.amount)
                          }>
                          {' '}
                          Complete AutoPay{' '}
                        </button>
                      )}
                      {mandateObject?.status === '' && mandateObject?.mandate_type === '' && ready_to_invest && (
                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          disabled
                          onClick={() => {
                            goToBankAutoPay(bankDetail.id, 'autopay', '');
                          }}>
                          {' '}
                          Setup AutoPay{' '}
                        </button>
                      )}
                      {(!mandateObject || mandateObject?.status === 'FAILED') && ready_to_invest && (
                        <button
                          type="button"
                          className="btn btn-outline-primary ml-auto"
                          onClick={() => {
                            goToBankAutoPay(bankDetail.id, 'autopay', '');
                          }}>
                          {' '}
                          Setup AutoPay{' '}
                        </button>
                      )}
                    </div>
                  )}
                  {bankDetail.status === 'REJECTED' && <span className="c-red">Remarks : {bankDetail.remarks}</span>}
                </div>
                {bankDetail.status === 'REJECTED' ? (
                  <div className="bank-actions ">
                    <button
                      type="button"
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        history.push({ pathname: '/profile/add-bank', state: { bankDetail } });
                      }}>
                      Re-Submit
                    </button>
                  </div>
                ) : null}
              </section>
            );
          })
        ) : (
          <DataMsg message="No Default bank found " />
        )}
      </div>
    </div>
  );
};
export default BankAccounts;
