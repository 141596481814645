import React, { useState, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import uploadIcon from '../../../assets/upload.svg';
import deleteImage from '../../../assets/close.svg';
import { GetFileType } from '../../../utility/utility';
import Toast from '../../toast/Toast';

const MAX_FILE_SIZE_IN_MB = 5 * 1024 * 1024;

const UploadBox = ({
  onChange,
  id,
  title,
  value,
  iconImg,
  accept = [],
  icon = false,
  deleteIcon = false,
  err,
  size = MAX_FILE_SIZE_IN_MB,
}) => {
  const [filename, setFileName] = useState('');
  const inputRef = useRef(null);
  useEffect(() => {
    setFileName(value || '');
  }, [value]);
  const onFileDelete = () => {
    setFileName('');
    onChange(undefined);
    inputRef.current.value = null;
  };
  const onFileChange = (event) => {
    const fileList = event.target.files;
    const fileSize = fileList[0]?.size;
    const acceptFileTypes = getFileMimeType(accept);
    if (fileList && fileList.length) {
      if (!size || fileSize <= size) {
        if (
          acceptFileTypes &&
          acceptFileTypes.length > 0 &&
          !acceptFileTypes.includes(fileList[0].type)
        ) {
          Toast.error('Invalid file type.');
        } else {
          setFileName(fileList[0].name);
          onChange(fileList[0]);
        }
      } else {
        event.preventDefault();
        Toast.error(
          `File size should be less than ${
            MAX_FILE_SIZE_IN_MB / 1024 / 1024
          } MB`
        );
      }
    }
  };
  const getFileMimeType = (fileArray) => {
    if (fileArray && fileArray.length) {
      return fileArray.map((item) => GetFileType(item)).join(',');
    }
  };
  return (
    <>
      {icon ? (
        <>
          <div className='btn btn-sm waves-effect waves-light file-field'>
            <img
              src={iconImg || uploadIcon}
              alt='upload icon'
              className='icon'
            />
          </div>
          <input
            data-cy='signature'
            name={'document-upload'}
            className={'file-field-input'}
            type='file'
            onChange={onFileChange}
            multiple
            accept={getFileMimeType(accept)}
          />
        </>
      ) : (
        <Form.Group>
          <Form.Label className='opacity-50 mb-1'>{title}</Form.Label>
          <div className='file-field' id={'file' + id}>
            <Form.Control
              ref={inputRef}
              type='text'
              id={id}
              value={filename}
              readOnly
              className={'updated-input font-weight-medium pl-0 pr-5'}
            />
            <div className='btn btn-sm waves-effect waves-light upload-container'>
              <img src={uploadIcon} alt='upload icon' className='icon' />
              {filename && deleteIcon && (
                <img
                  src={deleteImage}
                  onClick={onFileDelete}
                  alt='delete icon'
                  className='icon-del'
                />
              )}
            </div>
            <input
              className={filename && 'document-upload'}
              name={'document-upload'}
              type='file'
              onChange={onFileChange}
              multiple
              accept={getFileMimeType(accept)}
            />
            {err && (
              <div
                className={'invalid-feedback d-block'}
                style={{ position: 'absolute' }}
              >
                {err}
              </div>
            )}
          </div>
        </Form.Group>
      )}
    </>
  );
};
UploadBox.propTypes = {
  id: PropTypes.string,
  iconImg: PropTypes.string,
  icon: PropTypes.bool,
  title: PropTypes.string,
  value: PropTypes.string,
  accept: PropTypes.array,
  onChange: PropTypes.func,
};
export default UploadBox;
