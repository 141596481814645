import * as Sentry from '@sentry/react';
import axios from 'axios';
import Toast from '../common/toast/Toast';
import { getInfoWithKey } from '../page/store/LocalStorageInfoManager';
import {
  TOKEN_KEY,
  abortController,
  getLoader,
  redirectUserForAuthentication,
  removeLoader,
  removeToken,
} from '../utility/utility';
import { API_ERROR_MESSAGES } from '../utility/constant';

const fetchClient = (errorHandler) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_TARRAKKI_API_URL,
    signal: abortController.signal,
  });

  // Add a request interceptor
  instance.interceptors.request.use((config) => {
    // Need to check if we do not want to show loader for recalling api for create mandate
    document.body.appendChild(getLoader());
    const uD = getInfoWithKey('uD');
    const newToken = localStorage.getItem(TOKEN_KEY);
    if (newToken) {
      config.headers.Authorization = `Bearer ${newToken}`;
    }
    const token = uD && uD.data ? uD.data?.token : null;
    if (token) {
      config.headers.authorization = `bearer ${token}`;
    }
    return config;
  });

  // Add a response interceptor
  instance.interceptors.response.use(
    (res) => {
      removeLoader();
      if (res && res.data && res.data.status) {
        if (res.data.status.message && res.data.status.code === 0) {
          Toast.error(res.data.status.message);
        }
      }
      return res;
    },
    (error) => {
      removeLoader();
      if (error?.response && error.response?.status !== 401) {
        Sentry.withScope(function (scope) {
          scope.setLevel('error');
          scope.setFingerprint([
            '{{ default }}',
            `http_status:${error?.response?.status}`,
          ]);
          scope.setTransactionName(
            `Request failed with status code ${error?.response?.status}`
          );
          Sentry.captureException({
            error: error,
            response: error?.response,
            status_message: error?.response?.data,
          });
        });
      }
      if (
        error.response &&
        error.response.status >= 500 &&
        error.response.status <= 599
      ) {
        Toast.error(API_ERROR_MESSAGES.SERVER_ERROR);
      }
      if (
        !abortController.signal.aborted &&
        error.response &&
        error.response.status === 401
      ) {
        Toast.error(API_ERROR_MESSAGES.UNAUTHORIZED_USER_ERR_MSG);
        localStorage.removeItem('uD');
        localStorage.removeItem('lD');
        removeToken();
        abortController.abort();
        redirectUserForAuthentication();
      }
      if (errorHandler) {
        errorHandler(error);
      } else {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status <= 499
        ) {
          if (error.response && error.response.data.message) {
            Toast.error(error.response.data.message);
          } else {
            Toast.error(API_ERROR_MESSAGES.CLIENT_ERROR);
          }
        }
      }
      return Promise.reject(error);
    }
  );
  return instance;
};
export default fetchClient();

export const elevoApiClient = (params) => {
  return fetchClient(params);
};
