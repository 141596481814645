import { useEffect, useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReactComponent as BookAnFDIcon } from '../../../assets/book_an_fd.svg';
import FAILED_ICON from '../../../assets/not-supported.svg';
import SUCCESS_ICON from '../../../assets/successful.svg';
import { useAppSelector } from '../../../redux/hooks';
import {
  constructFdAppURL,
  getWindowDimensions,
  setCookie,
} from '../../../utility/utility';

enum PANLinkVerifierStepsEnum {
  'VERIFYING',
  'PAN_IS_NOT_LINKED',
  'SYSTEM_ERROR',
  'PAN_IS_LINKED',
  'NOT_STARTED',
}

const PANLinkVerifierSteps: {
  [key: string]: { resultLabel: string; icon?: string };
} = {
  [PANLinkVerifierStepsEnum.VERIFYING]: {
    resultLabel: 'Verifying...',
  },
  [PANLinkVerifierStepsEnum.PAN_IS_NOT_LINKED]: {
    resultLabel: 'PAN is not linked with account!',
    icon: FAILED_ICON,
  },
  [PANLinkVerifierStepsEnum.SYSTEM_ERROR]: {
    resultLabel:
      'Sorry, we are facing some issues. Please try again after sometime.',
    icon: FAILED_ICON,
  },
  [PANLinkVerifierStepsEnum.PAN_IS_LINKED]: {
    resultLabel: 'Your PAN is linked.',
    icon: SUCCESS_ICON,
  },
  [PANLinkVerifierStepsEnum.NOT_STARTED]: {
    resultLabel: 'Starting your PAN verification.',
  },
};

export const PANLinkVerifier = ({ investor }: { investor: any }) => {
  const { fdAppURL } = useAppSelector((state) => state.authReducer);
  const fdRedirectionUrl = (user_id: string) =>
    constructFdAppURL(fdAppURL!, {
      activeTab: 'Deposits',
      investor_id: user_id,
    });

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // States - Component Variables
  const [showModal, setShowModal] = useState(false);
  const [verificationStep] = useState<PANLinkVerifierStepsEnum>(
    PANLinkVerifierStepsEnum.NOT_STARTED
  );

  // Methods
  const handleBookAnFD = () => {
    // event.preventDefault();
    // NOTE: Currently not enabled
    // TODO: Implement pan linked status check
    // Group:1
    // setVerificationStep(PANLinkVerifierStepsEnum.PAN_IS_NOT_LINKED);
    // setShowModal(true);
    // Group:2
    // setVerificationStep(PANLinkVerifierStepsEnum.PAN_IS_LINKED);
    setCookie('INVESTORS_DETAIL', JSON.stringify(investor));
  };

  if (verificationStep === PANLinkVerifierStepsEnum.PAN_IS_LINKED) {
    window.location.href = fdRedirectionUrl(investor.id as any);
  }

  return (
    <>
      <a href={fdRedirectionUrl(investor.id as any)} onClick={handleBookAnFD}>
        <OverlayTrigger
          placement='top'
          delay={{ show: 100, hide: 200 }}
          overlay={<Tooltip id='fixed-deposits-icon'>Book an FD</Tooltip>}
        >
          {/* <FaSackDollar size={25} color='#a7aaab' /> */}
          <BookAnFDIcon />
        </OverlayTrigger>
      </a>
      {verificationStep !== PANLinkVerifierStepsEnum.NOT_STARTED && (
        <>
          <Modal
            show={showModal}
            size={'sm'}
            className={windowDimensions.width > 480 ? '' : 'p-4'}
            aria-labelledby='add-fund-modal'
            onHide={() => setShowModal(false)}
            centered
            backdrop='static'
            keyboard={false}
          >
            <Modal.Header className='py-2 ' closeButton />
            <Modal.Body className='text-center'>
              <div className='img-container mb-4'>
                <img
                  src={PANLinkVerifierSteps[verificationStep]?.icon}
                  alt='successful icon'
                  className={windowDimensions.width > 480 ? 'w-150' : 'w-30'}
                />
              </div>
              <p className='font-weight-medium'>
                {PANLinkVerifierSteps[verificationStep].resultLabel}
              </p>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};


