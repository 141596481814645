import React, { useEffect, useState } from 'react';
import PaymentInitiate from '../../modal/PaymentInitiate';
import SelectBankModal from '../../modal/SelectBankModal';
import SelectPaymentModeModal from '../../modal/SelectPaymentModeModal';
import SuccessModal from '../../modal/SuccessModal';
import { orderPayment, orderPaymentNew, validateOrderPayment } from '../../api/cart';
import { decryptData } from '../../utility/DecryptHelper';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const Payment = ({ orders, totalAmount, userId, onpaymentCompleted }) => {
  const [accountObj, setAccountObj] = useState({});
  const [bankModal, setBankModalInfo] = useState({ show: false, data: null });
  const [paymentModeModal, setPaymentModal] = useState({ show: false, data: null });
  const [successfulModal, setSuccessfulModal] = useState({ show: false, data: null });
  const [payStartModal, setPayStartModal] = useState(false);
  const [upi, setUpi] = useState('');
  const [utrNumber, setUtrNumber] = useState('');
  const [param, setParam] = useState('');
  const [paymentMode, setPaymentMode] = useState('DIRECT');
  const newChekout = localStorage.getItem('newCheckout')
  const history = useHistory();
  useEffect(() => {
    validateOrderPaymentFunc(orders);
    setParam(btoa(JSON.stringify(orders)));
  }, [JSON.stringify(orders)]);
  
  const validateOrderPaymentFunc = (orderIdArr) => {
    const formData = new FormData();
    formData.append('order_ids', JSON.stringify(orderIdArr));
    validateOrderPayment(userId, formData).then((validateRes) => {
      const validatedData = decryptData(validateRes.data.data);
      if (validatedData && validatedData.data) {
        setAccountObj(validatedData.data.bank_details);
        setUpi(validatedData.data.vpa_id);
        setUtrNumber(validatedData.data.utrNumber);
        setBankModalInfo({ show: true });
      }
    });
  };
  const accountNoChange = () => {
    setPaymentModal({ show: false });
    setBankModalInfo({ show: true });
  };
  const closeBankModal = (accountObject) => {
    setAccountObj(accountObject);
    setBankModalInfo({ show: false });
    setPaymentModal({ show: true });
  };
  const closePaymenModeModal = (mode, paymentInfo) => {
    if (newChekout) {
      const curMode = mode === 'NEFT/RTGS' ? 'RTGS' : mode;
      setPaymentMode(curMode);
      if (mode !== 'DIRECT' && !paymentInfo) {
        return false;
      }
      let formData = {};
      let ordersPayment = []
      orders.map((orderItem) => ordersPayment.push({ 'order_id': orderItem }) )
      formData = {
        payment_method: mode === 'NEFT/RTGS' ? 'neft_rtgs' : mode === 'DIRECT' ? 'netbanking' : 'upi',
        investor_id: userId,
        orders: ordersPayment,
        amount: totalAmount,
        bank_id: accountObj.id,
      }

      if (mode === 'UPI') {
        formData = {
          ...formData,
          upi_id: paymentInfo,
          callback_url: window.location.origin + `/order/complete/${param}/${mode}/`

        }
      } else if (mode === 'NEFT/RTGS') {
        formData = {
          ...formData,
          payment_reference: paymentInfo.split('-').join('')
        }
      }
      else {
        formData = {
          ...formData,
          callback_url: window.location.origin + `/order/complete/${param}/${mode}/`

        }
      }
      setPaymentModal({ show: false });
      orderPaymentNew(formData)
        .then((res) => {
          if (res && res.data && res.status === 201) {
            if (mode === 'NEFT/RTGS') {
              setSuccessfulModal({ show: true });
            }
            else {
              setPayStartModal(true);
              window.location.href = res.data.redirect_url
            }
          } else {
            onpaymentCompleted();
          }
        })
        .catch(() => {
          onpaymentCompleted();
        });
    }
    else {
      const curMode = mode === 'NEFT/RTGS' ? 'RTGS' : mode;
      setPaymentMode(curMode);
      if (mode !== 'DIRECT' && !paymentInfo) {
        return false;
      }
      const formData = new FormData();
      formData.append('total_payable_amount', totalAmount);
      formData.append('account_number', accountObj.account_number);
      formData.append('user_id', userId);
      formData.append('payment_mode', mode);
      formData.append('transaction_ids', JSON.stringify(orders));
      formData.append('redirect_url', window.location.origin);

      if (mode === 'UPI') {
        formData.append('upi_id', paymentInfo);
      } else if (mode === 'NEFT/RTGS') {
        formData.append('utr_number', paymentInfo.split('-').join(''));
      }
      setPaymentModal({ show: false });
      orderPayment(formData)
        .then((res) => {
          if (res && res.data && res.data.status && res.data.status.code === 1) {
            if (mode === 'DIRECT') {
              if (res.data && res.data.data) {
                const HtmlData = decryptData(res.data.data);
                const myWindow = window.open('', '_self');
                myWindow.document.write(HtmlData.data);
                myWindow.focus();
                setPayStartModal(true);
              }
            } else if (mode === 'UPI') {
              setPayStartModal(true);
            } else {
              setSuccessfulModal({ show: true });
            }
          } else {
            onpaymentCompleted();
          }
        })
        .catch(() => {
          onpaymentCompleted();
        });
    }
  };

  const onpaymentCompletedClose = () => {
    onpaymentCompleted();
    setPayStartModal(false);
    history.push(`/order/complete/${param}/${paymentMode}`);
  };
  const closeSuccessFulModal = () => {
    onpaymentCompleted();
    setSuccessfulModal({ show: false });
    history.push(`/order/complete/${param}/${paymentMode}`);
  };
  const closeModal = (modaltype) => {
    if (modaltype === 'bank') {
      setBankModalInfo({ show: false });
    } else if (modaltype === 'payment') {
      setPaymentModal({ show: false });
    } else if (modaltype === 'upi') {
      setPayStartModal(false);
    }
    onpaymentCompleted()
  };
  return (
    <>
      {bankModal.show ? <SelectBankModal show={bankModal.show} onSubmit={closeBankModal} closeModal={closeModal} accountInfo={accountObj} /> : null}
      {paymentModeModal.show ? (
        <SelectPaymentModeModal
          show={paymentModeModal.show}
          accountNoChange={accountNoChange}
          onSubmit={closePaymenModeModal}
          accountObj={accountObj}
          upi={upi}
          utrNumber={utrNumber}
          closeModal={closeModal}
        />
      ) : null}
      {successfulModal.show ? (
        <SuccessModal show={successfulModal.show} onSubmit={closeSuccessFulModal} successText="Payment Successful" size="sm" />
      ) : null}
      {payStartModal ? (
        <PaymentInitiate show={payStartModal} onSubmit={onpaymentCompletedClose} userId={userId} orderIds={orders} isNetBanking={paymentMode === 'DIRECT'}></PaymentInitiate>
      ) : null}
    </>
  );
};
Payment.propTypes = {
  history: PropTypes.object,
  totalAmount: PropTypes.number,
  userId: PropTypes.number,
  orders: PropTypes.array,
  onpaymentCompleted: PropTypes.func,
};
export default React.memo(Payment);
