import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getInvestorStatus, getKYCStatus } from '../../../api/kyc';
import { getHomeData } from '../../../api/nav';
import { sendOTP } from '../../../api/otp';
import { getUserDetails } from '../../../api/profile';
import InfoIcon from '../../../assets/info.svg';
import Toast from '../../../common/toast/Toast';
import {
  setProfileObj,
  updateNomineeStatus,
  updateinvestorStatus,
} from '../../../redux/reducers/auth-reducer';
import {
  setOtpId,
  setShowOTPModal,
} from '../../../redux/reducers/header-reducer';
import { updateKycInfo } from '../../../redux/reducers/onboarding-reducer';
import { setPortfolioDetails } from '../../../redux/reducers/portfolio-reducer';
import { decryptData, encryptData } from '../../../utility/DecryptHelper';
import { KycStatusEnum } from '../../../enums';
import { useAppSelector } from '../../../redux/hooks';
import { useKyc } from '../../../hooks/useKyc';
import { KRA_Enum } from '../../types';

const StatusBanner = () => {
  const LLP = 'LLP';
  const PARTNERSHIP = 'Partnership';
  const PRIVATE_LIMITED = 'Private-Limited';
  const LIMITED = 'Limited';
  const HUF = 'HUF';
  const TRUST = 'Trust';
  const BANK = 'Bank';
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    user_id,
    ready_to_invest,
    nomineeAuthenticationStatus,
    profileObj: userDetail,
  } = useSelector((state: any) => state.authReducer);
  // const [homeData, setHomeData] = React.useState(false);
  const { portfolioDetails: homeData } = useSelector(
    (state: any) => state.portfolioReducer
  );
  const { supportEmail } = useAppSelector((state) => state.whitelabelReducers);

  const [status, setInvestorStatus] = React.useState('');
  const [kycStatus, setKYCStatus] = React.useState<KycStatusEnum>();
  // const [userDetail, setUserDetail] = useState();
  const [bankRejected, setBankRejected] = React.useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const isSaveAsDraft = localStorage.getItem('saveAsDraft');
  const isEmailDeclaration = localStorage.getItem('emailDeclaration');
  const [isShowSubmitMessage, setIsShowSubmitMessage] = useState(false);
  const [kycLink, setKycLink] = useState('');
  const { initiateKyc } = useKyc();

  // Derived condition
  const isInvestorVerificationCompleted =
    status === 'ready_to_invest' &&
    !homeData.has_transacted &&
    (kycStatus === KycStatusEnum.registered ||
      kycStatus === KycStatusEnum.validated);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const checkStatusForIndivisuals = (value: any) => {
    if (value) {
      const groupArr = [
        HUF,
        LLP,
        PRIVATE_LIMITED,
        LIMITED,
        HUF,
        TRUST,
        BANK,
        PARTNERSHIP,
      ];
      return groupArr.indexOf(value) > -1;
    }
    return false;
  };
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (userDetail?.data?.pan) {
      getKYCStatus(userDetail?.data?.pan)
        .then((res) => {
          setKYCStatus(res?.data?.status);
          if (Object.keys(KRA_Enum).includes(res?.data?.kra)) {
            const kra = res?.data?.kra;
            setKycLink(KRA_Enum[kra as keyof typeof KRA_Enum]);
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message?.length > 0) {
            Toast.error(error?.response?.data?.message);
          }
        });
    }
  }, [userDetail?.data?.pan]);

  useEffect(() => {
    getHomeDataFunction();
  }, [ready_to_invest, nomineeAuthenticationStatus, userDetail]);

  useEffect(() => {
    getUserDocumentStatus();
  }, [ready_to_invest, nomineeAuthenticationStatus, userDetail, homeData]);

  const getUserDocumentStatus = async () => {
    if (user_id !== undefined) {
      await getInvestorStatus(user_id).then((res) => {
        if (res && res?.data) {
          if (
            isSaveAsDraft === 'true' &&
            isEmailDeclaration === 'true' &&
            res.data?.message?.length === 0 &&
            res.data?.status === 'pending_nominee_authentication'
          ) {
            setIsShowSubmitMessage(true);
          } else if (
            isSaveAsDraft === 'true' &&
            isEmailDeclaration === 'true' &&
            res.data?.message?.length === 0 &&
            (userDetail?.data?.tax_status?.tax_status === 'Minor' ||
              checkStatusForIndivisuals(
                userDetail?.data?.tax_status?.tax_status
              )) &&
            res.data?.status === 'under_review'
          ) {
            setIsShowSubmitMessage(true);
          } else if (res.data?.status === 'under_review') {
            if (homeData?.is_draft === true) {
              setIsShowSubmitMessage(true);
            } else {
              setIsShowSubmitMessage(false);
              localStorage.setItem('saveAsDraft', JSON.stringify(false));
            }
          } 
          setInvestorStatus(res.data.status);
          if (res.data?.message?.indexOf('bank_rejected') !== -1) {
            setBankRejected(true);
          }
          if (res?.data?.status === 'ready_to_invest') {
            dispatch(updateinvestorStatus(true));
          } else {
            dispatch(updateinvestorStatus(false));
          }
          if (res?.data?.status === 'pending_nominee_authentication') {
            dispatch(updateNomineeStatus(true));
          } else {
            dispatch(updateNomineeStatus(false));
          }
        }
      });
    }
  };

  const getHomeDataFunction = () => {
    getHomeData().then((res) => {
      if (res && res.data) {
        const homeData = decryptData(res.data.data);
        if (homeData) {
          dispatch(setPortfolioDetails(homeData));
        }
      }
    });
  };

  const updateOnboardingPan = () => {
    history.push('/panstatus');
  };

  const updateOnboarding = () => {
    getUserDetails()
      .then((res) => {
        if (res && res.data) {
          const profileObj = decryptData(res.data.data);
          dispatch(setProfileObj(profileObj));
          if (
            profileObj.data &&
            profileObj.data.pan &&
            profileObj.data.tax_status &&
            profileObj.data.tax_status.id
          ) {
            const panObj = {
              pan: profileObj.data.pan,
              pan_kyc_status: profileObj.data.tax_status.id,
            };
            dispatch(updateKycInfo(panObj));
            localStorage.setItem('panData', encryptData(panObj));
            setTimeout(() => {
              history.push('/boarding');
            }, 0);
          } else {
            setTimeout(() => {
              history.push('/panstatus');
            }, 0);
          }
        }
      })
      .catch((e) => {
        console.log('Error caught in catch', e);
        Toast.error(
          'Unable to perform the requested action, please try after some time'
        );
      });
  };
  const goToInvest = () => {
    history.push('/invest');
  };
  const resubmitBank = () => {
    if (true) {
      history.push({ pathname: '/boarding' });
    }
  };
  const handleSendOtp = () => {
    if (user_id) {
      const otpData = {
        otp_type: 'nominee',
        investor_id: user_id.toString(),
      };
      sendOTP(otpData).then((res) => {
        if (res?.data?.otp_id) {
          dispatch(setOtpId(res?.data?.otp_id));
          localStorage.setItem('otpId', res?.data?.otp_id);
        }
        dispatch(setShowOTPModal(true));
      });
    }
  };
  const onCallInitiateKYC = () => initiateKyc(userDetail);
  const redirectToKra = () => {
    if (kycLink) window.open(kycLink, '_blank');
  };
  if (status === 'ready_to_invest' && kycStatus === KycStatusEnum.registered) {
    return (
      <>
        <div className={'mb-0 alert text-center bg-light-red'} role='alert'>
          <span className='d-flex justify-content-center'>
            <img src={InfoIcon} className='icon mr-2 c-red' alt='info' />
            {kycLink ? (
              <>
                Your KYC validation is pending. Please click
                <button
                  className='btn btn-link alert-link ml-1 p-0 mr-1'
                  onClick={redirectToKra}
                >
                  here
                </button>
                to complete your validation.
              </>
            ) : (
              <>
                Your KYC validation is pending. Please Contact
                {supportEmail ? ` ${supportEmail}` : ' Customer Support'} for
                any assistance.
              </>
            )}
          </span>
        </div>
      </>
    );
  }

  return (
    <>
      {status === 'incomplete_detail' &&
        (homeData.is_individual_nri ? (
          <div
            className={'alert text-center alert-danger bg-light-red mb-0'}
            role='alert'
          >
            <span className='d-flex justify-content-center'>
              <img src={InfoIcon} className='icon mr-2 c-red' alt='info' />
              {`Your PAN tax status is still Resident Individual. In order to continue investing with your NRE/NRO account, kindly contact ${
                supportEmail ? `us at ${supportEmail}` : 'Customer Support'
              } to
                update your tax status to NRI.`}
            </span>
          </div>
        ) : bankRejected ? (
          <>
            <div
              className={'mb-0 alert text-center alert-danger bg-light-red'}
              role='alert'
            >
              <span className='d-flex justify-content-center'>
                <img src={InfoIcon} className='icon mr-2 c-red' alt='info' />
                Your bank account was not verified, please fill in the details{' '}
                <button
                  className='btn btn-link alert-link ml-1 p-0 mr-1'
                  onClick={resubmitBank}
                >
                  here
                </button>
              </span>
            </div>
          </>
        ) : homeData.is_kyc_verified &&
          [KycStatusEnum.registered, KycStatusEnum.validated].includes(
            kycStatus as any
          ) &&
          userDetail?.data &&
          userDetail?.data?.pan &&
          userDetail?.data?.tax_status ? (
          <>
            <div
              className={'mb-0 alert text-center alert-danger bg-light-red'}
              role='alert'
            >
              <span className='d-flex justify-content-center'>
                <img src={InfoIcon} className='icon mr-2 c-red' alt='info' />
                Please complete your verification
                <button
                  className='btn btn-link alert-link ml-1 p-0'
                  onClick={updateOnboarding}
                >
                  here
                </button>
              </span>
            </div>
          </>
        ) : // CASE: when PAN data like tax_status is not available, then redirect to /panstatus
        !userDetail?.data?.pan ||
          !userDetail?.data?.tax_status ||
          !userDetail?.data?.tax_status.id ||
          (kycStatus !== KycStatusEnum.pending &&
            kycStatus !== KycStatusEnum.initiated &&
            kycStatus !== KycStatusEnum.registered &&
            kycStatus !== KycStatusEnum.validated &&
            kycStatus !== KycStatusEnum.processing) ? (
          <>
            <div
              className={'mb-0 alert text-center alert-danger bg-light-red'}
              role='alert'
            >
              <span className='d-flex justify-content-center'>
                <img src={InfoIcon} className='icon mr-2 c-red' alt='info' />
                Get investment ready! Click{' '}
                <button
                  className='btn btn-link alert-link ml-1 p-0'
                  onClick={updateOnboardingPan}
                >
                  here
                </button>
                &nbsp;to set up your account.
              </span>
            </div>
          </>
        ) : null)}
      {status === 'pending_nominee_authentication' && isShowSubmitMessage && (
        <div
          className={'mb-0 alert text-center alert-danger bg-light-red'}
          role='alert'
        >
          <span>
            <img src={InfoIcon} className='icon mr-2 c-red' alt='info' />
            Please submit your onboarding form to start investing. Click
            <button
              className='btn btn-link alert-link ml-1 p-0'
              onClick={updateOnboarding}
            >
              here
            </button>
            &nbsp;{` to submit`}
          </span>
        </div>
      )}
      {status === 'pending_nominee_authentication' && !isShowSubmitMessage && (
        <>
          <div
            className={'alert text-center alert-danger bg-light-red mb-0'}
            role='alert'
          >
            <span className='d-flex justify-content-center'>
              <img src={InfoIcon} className='icon mr-2 c-red' alt='info' />
              Please complete OTP verification to Confirm your Nomination
              details
              <button
                className='btn btn-link alert-link ml-1 p-0'
                onClick={handleSendOtp}
              >
                here
              </button>
            </span>
          </div>
        </>
      )}
      {status === 'under_review' && isShowSubmitMessage && (
        <>
          <div
            className={'mb-0 alert text-center alert-danger bg-light-red'}
            role='alert'
          >
            <span>
              <img src={InfoIcon} className='icon mr-2 c-red' alt='info' />
              Please submit your onboarding form to start investing. Click
              <button
                className='btn btn-link alert-link ml-1 p-0'
                onClick={updateOnboarding}
              >
                here
              </button>
              &nbsp;{` to submit`}
            </span>
          </div>
        </>
      )}
      {status === 'under_review' && !isShowSubmitMessage && (
        <>
          <div
            className={'mb-0 alert text-center bg-light-orange'}
            role='alert'
          >
            <span className='d-flex justify-content-center'>
              <img src={InfoIcon} className='icon mr-2 c-red' alt='info' />
              Your application is submitted and is in verification process. It
              will be verified in next 24 hrs
            </span>
          </div>
        </>
      )}
      {isInvestorVerificationCompleted && windowDimensions.width > 480 ? (
        <>
          <div className={'mb-0 alert text-center bg-light-green'} role='alert'>
            <span className='d-flex justify-content-center'>
              <img src={InfoIcon} className='icon mr-2 c-red' alt='info' />
              Your account verification is completed. Click
              <button
                className='btn btn-link alert-link ml-1 p-0 mr-1'
                onClick={goToInvest}
              >
                here
              </button>
              to start your investment journey
            </span>
          </div>
        </>
      ) : (
        ''
      )}
      {isInvestorVerificationCompleted && windowDimensions.width <= 480 && (
        <>
          <div
            style={{ position: 'relative' }}
            className={'mb-0 alert text-center bg-light-green'}
            role='alert'
          >
            <span className=''>
              <img
                src={InfoIcon}
                style={{ position: 'absolute', top: '2px', left: '4px' }}
                className='icon c-red'
                alt='info'
              />
              Your account verification is completed. Click
              <a
                style={{
                  color: '#08aeea',
                  fontWeight: 800,
                  cursor: 'pointer',
                }}
                onClick={goToInvest}
              >
                here
              </a>
              to start your investment journey
            </span>
          </div>
        </>
      )}

      {kycStatus &&
        kycStatus !== KycStatusEnum.registered &&
        kycStatus !== KycStatusEnum.validated && (
          <>
            <div
              className={'alert text-center alert-danger bg-light-red mb-0'}
              role='alert'
            >
              <span>
                <img src={InfoIcon} className='icon mr-2 c-red' alt='info' />
                {kycStatus === KycStatusEnum.pending && (
                  <>
                    Your KYC is incomplete, please complete your KYC and click
                    <button
                      className='btn btn-link alert-link ml-1 p-0'
                      onClick={onCallInitiateKYC}
                    >
                      here
                    </button>
                    &nbsp;to continue. Contact&nbsp;
                    {supportEmail ? `${supportEmail}` : 'Customer Support'} for
                    any assistance.
                  </>
                )}
                {kycStatus === KycStatusEnum.initiated && (
                  <>
                    Your KYC registration is &nbsp;<strong>Incomplete</strong>,
                    Please complete your application
                    <button
                      className='btn btn-link alert-link ml-1 p-0'
                      onClick={onCallInitiateKYC}
                    >
                      here
                    </button>
                    &nbsp; to continue.
                  </>
                )}
                {kycStatus === KycStatusEnum.processing && (
                  <>
                    Your KYC application is in process, it can take from 3 to 10
                    days to get your PAN KYC registered.
                  </>
                )}
                {kycStatus === KycStatusEnum.rejected && (
                  <>
                    Your KYC application was &nbsp;<strong>Rejected</strong>,
                    Please Re-submit your KYC application
                    <button
                      className='btn btn-link alert-link ml-1 p-0'
                      onClick={onCallInitiateKYC}
                    >
                      here
                    </button>
                  </>
                )}
              </span>
            </div>
          </>
        )}
    </>
  );
};

export default StatusBanner;
