import { Link } from 'react-router-dom';
import { ReactComponent as UploadCASFileIcon } from '../../../assets/UploadFileIcon.svg';

export const DefaultTile = () => {
  return (
    <div className='ExternalFunds__default_tile'>
      <div className='ExternalFunds__default_tile_wrapper'>
        <span className='ExternalFunds__default_tile_heading'>
          Import External Funds
        </span>
        <span className='ExternalFunds__default_tile_instructions'>
          Upload your CAS to bring all your investments on Elevo
        </span>
        {/* <Link to={'/externalfunds/download-cas'}>Download CAS</Link> */}
        <Link
          to={'/externalfunds/upload-cas'}
          className='ExternalFunds__default_tile_button'
        >
          <UploadCASFileIcon />
          <span className='ExternalFunds__default_tile_instructions'>
            I would like to upload CAS statement
          </span>
        </Link>
      </div>
    </div>
  );
};
