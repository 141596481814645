import React, {  useRef } from 'react';
import PropTypes from 'prop-types';
import SignaturePad from "react-signature-canvas";
import "./signCanvas.scss";
import {AiOutlineClose} from "react-icons/ai"
import { useHistory } from 'react-router-dom';
import Toast from '../../common/toast/Toast';
const SignPadModal = ({ onClose}) => {
  const sigCanvas = useRef({});
  const history = useHistory();
  let encodedData = window.location.search
  let code = encodedData.slice(1,encodedData.length-1)  
  let decodeData = decodeURIComponent(escape(window.atob( code )));
  let validTime = Date.parse(JSON.parse(decodeData).date)
  let currentTime = new Date().getTime()
  const clear = () => sigCanvas.current.clear();
  const save = () => {
    if (currentTime > validTime) {
      Toast.error("Link has been expired, Please resend the link again to complete your signature")
    } else {
    const url = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    history.push({pathname:"/digitalsignpreview",state:{url:url,decodeData}})
    }
  }
  const onCloseSign = () => {
    onClose()
  }
  return (
      <>
      <div className='border m-4'>
       <h4  className="font-weight-medium  mt-3 mb-3 sign-head">
        Draw your Signature below
       </h4>
       <button id="cross" onClick={onCloseSign}><AiOutlineClose  className='cross' /></button>
       <div className="mt-2">
          <>
          <SignaturePad
              ref={sigCanvas}
              penColor="blue"
           canvasProps={{width: 350, height: 200, className: 'sigCanvas'}} 
         />
          </>
        </div>
        <div className='text-center mb-4'>
         <button onClick={save} className='btn btn-outline-primary mr-3 w-40'>SUBMIT</button>
         <button onClick={clear} className="btn btn-primary ml-3 w-40">CLEAR PAD</button>
         </div>
      </div>
      </>
  );
};
SignPadModal.propTypes = {
  successText: PropTypes.string,
  size: PropTypes.string,
  show: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  errorIcon: PropTypes.bool,
  id: PropTypes.string,
  match: PropTypes.object
};
export default SignPadModal;
