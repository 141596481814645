import { useState } from 'react';
import { Folio, PortfolioItem } from '../../../types';
import { useAppSelector } from '../../../redux/hooks';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

function hasNumber(str: string) {
  return /\d/.test(str);
}

export const usePortfolio = () => {
  const queryClient = useQueryClient();
  const user = useAppSelector((s) => s.authReducer);
  const history = useHistory();

  const [addModalData, addModalDataFunc] = useState<any>({});
  const [directPlan, setDirectPlan] = useState(false);
  const [add, setAdd] = useState(false);
  const [reedemModalData, reedemModalDataFunc] = useState<any>({});

  const getFrequencyDates = (dates: string) => {
    let allowedDates = [];
    if (hasNumber(dates)) {
      dates.split('|').forEach((item) => {
        allowedDates.push(parseInt(item));
      });
    } else {
      for (let i = 1; i <= 28; i++) {
        allowedDates.push(i);
      }
    }
    return allowedDates;
  };

  const addFundFunc = (item: PortfolioItem) => {
    if (item.is_regular_plan) {
      addModalDataFunc({
        show: true,
        fundId: item.fund_id,
        folioList: item.folio_list.map((item: Folio) => item.folio_no),
        minAmountNew: item.pi_minimum_initial,
        minAmountCurrent: item.lumpsum_additional_min_amount,
        frequencyDates: item.iaip_aip?.[0]?.frequency_date
          ? getFrequencyDates(item.iaip_aip[0].frequency_date)
          : [],
        canInvestLumpsum:
          (item.bse_data?.lumpsum_allowed &&
            item.bse_data.lumpsum_allowed === 'Y') ||
          false,
        canInvestSip:
          (item.bse_data?.sip_allowed && item.bse_data.sip_allowed === 'Y') ||
          false,
        isEmpanelled: item.is_empanelled,
      });
    } else {
      setDirectPlan(true);
      setAdd(true);
    }
  };
  const reedemFundFunc = (item: PortfolioItem) => {
    if (item.is_regular_plan) {
      reedemModalDataFunc({
        show: true,
        options: {
          fundId: item.fund_id,
          folioList: item.folio_list,
          isRegular: item.is_regular_plan,
          exitLoad: item.defer_loads,
          units: item.units,
          total_investment: item.total_investment,
          name: item.fund_name,
          userId: user.user_id,
        },
      });
    } else {
      setDirectPlan(true);
      setAdd(false);
    }
  };

  const onAddFundModalClose = (flag: boolean) => {
    addModalDataFunc({ show: false });
    if (flag) {
      history.push('/order/summary');
    }
  };

  const onRedeemSubmit = (dataObj: any) => {
    reedemModalDataFunc({ show: false, options: {} });
    if (dataObj) {
      history.push({ pathname: '/redemptionconfirmation', state: { dataObj } });
    }
    queryClient.invalidateQueries(['PortfolioItems', user?.user_id]);
  };

  const onClosePopUp = () => {
    setDirectPlan(false);
  };

  return {
    addModalData,
    directPlan,
    add,
    reedemModalData,
    addFundFunc,
    reedemFundFunc,
    onAddFundModalClose,
    onRedeemSubmit,
    onClosePopUp,
  };
};
