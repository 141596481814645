import { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SidebarMenu from './SidebarMenu';

const Sidebar = ({ isCollapsed, toggleSidebar }) => {
  const history = useHistory();
  // const [profileData, setProfileData] = useState();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const {
    username,
    isMutualFundInScope,
    profileObj: profileData,
  } = useSelector((state) => state.authReducer);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const redirectProfile = () => {
    history.push('/profile/detail');
    if (windowDimensions?.width < 992) {
      toggleSidebar();
    }
  };

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <div className={`sidebar ${isCollapsed ? 'sidebar_collapsed' : ''}`}>
        <div className={'sidebar_profile'}>
          <div
            className={'sidebar_profile-img'}
            onClick={() => redirectProfile()}
          >
            <Avatar
              src={profileData?.user_profile_image}
              name={username}
              round='50px'
              color={'#08AEEA'}
            />
          </div>
          <div
            className={'sidebar_profile-details'}
            onClick={() => redirectProfile()}
          >
            <div className={'sidebar_profile-name'}>
              <span title={username}>{username}</span>
            </div>
            <div className={'sidebar_profile-type'}>
              {isMutualFundInScope && (
                <span>{profileData?.tax_status?.tax_status}</span>
              )}
            </div>
          </div>
        </div>
        <div className={'horizontal-line'} />
        <SidebarMenu toggleSidebar={toggleSidebar} />
      </div>
    </>
  );
};

export default Sidebar;
