import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { DropdownList, SwitchButton } from '../common/form';
import ToggleSwitch from '../component/toggle-switch/ToggleSwitch';
import { redeemAmountRegex } from '../utility/constant';
const ReedemFundModal = ({ show, onSubmit, options = {} }) => {
  const [type, setType] = useState('units');
  const [redeemVal, setredeemVal] = useState('');
  const [errorMsg, setError] = useState('');
  const [folioNumber, setFolioNumber] = useState({
    value: options.folioList[0].folio_no,
    label: options.folioList[0].folio_no,
    Obj: options.folioList[0],
  });
  const [redeemType, setRedeemType] = useState(false);
  const [checked, setCheck] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const onToggleChange = (value) => {
    setType(value);
    if (checked) {
      setCheck(false);
    }
    if (value === 'amount') {
      setRedeemType(true);
    }
    setredeemVal('');
    setError('');
  };
  const redeemValChange = (event) => {
    let { value } = event.target;
    let error = '';
    if (!value) {
      if (type === 'amount') {
        error = 'Please enter an amount to Redeem';
      } else if (type === 'units') {
        error = 'Please enter a unit to Redeem';
      }
    } else if (type === 'amount') {
      if (!redeemAmountRegex.test(value)) {
        error = 'Please enter a valid amount to Redeem';
      } else if (value > folioNumber.Obj.current_value) {
        error = `The Maximum Redeemable amount is ${folioNumber.Obj.current_value}`;
      } else if (!Number.isInteger(parseFloat(value))) {
        error = 'Please enter a non-fractional amount to Redeem';
      }
    } else if (type === 'units') {
      if (!redeemAmountRegex.test(value)) {
        error = 'Please enter a valid unit to Redeem';
      } else if (value > folioNumber.Obj.units) {
        error = `The Maximum Redeemable units is ${folioNumber.Obj.units}`;
      }
    }
    setError(error);
    setredeemVal(value);
  };

  const onredeemSubmit = () => {
    if (!redeemVal) {
      let error = '';
      if (type === 'amount') {
        error = 'Please enter an amount to Redeem';
      } else if (type === 'units') {
        error = 'Please enter a unit to Redeem';
      }
      setError(error);
      return false;
    }
    const { folio_no, folio_id, units, current_value } = folioNumber.Obj;
    onSubmit({
      name: options.name,
      userId: options.userId,
      fundId: options.fundId,
      units,
      current_value,
      folio_no,
      folio_id,
      redeemVal,
      type,
      exitLoad: options.exitLoad,
    });
  };

  const onFolioNumberChange = (event, object) => {
    event.preventDefault();
    setFolioNumber(object);
  };
  const changeCheck = () => {
    setCheck(!checked);
    if (!checked) {
      if (type === 'amount') {
        setredeemVal(folioNumber.Obj.current_value);
      } else {
        setredeemVal(folioNumber.Obj.units);
      }
    } else {
      setredeemVal('');
    }
  };
  return (
    <>
      {windowDimensions.width > 480 ? (
        <Modal
          show={show}
          size='sm'
          aria-labelledby='add-fund-modal'
          onHide={onSubmit}
          centered
          backdrop='static'
          keyboard={false}
        >
          <Modal.Header closeButton className='pb-2 align-items-center'>
            <h6 className='font-weight-medium m-0'>Redeem</h6>
          </Modal.Header>
          <Modal.Body className='pt-0'>
            <div className='d-flex'>
              <Form.Group className='my-2'>
                <Form.Label className='opacity-50 small mb-1'>
                  Select Folio
                </Form.Label>
                <DropdownList
                  selectedOption={folioNumber.value}
                  id='return-type'
                  classes={{ input: 'form-control py-2 mt-2 mr-2 br-light' }}
                  controlFunc={onFolioNumberChange}
                  options={options.folioList.map((item) => ({
                    value: item.folio_no,
                    label: item.folio_no,
                    Obj: item,
                  }))}
                />
              </Form.Group>
            </div>
            <div className='d-flex'>
              <Form.Group className='d-flex flex-column justify-content-between my-3'>
                <Form.Label className='opacity-50 small mb-1'>
                  Total Redeemable Units
                </Form.Label>
                <Form.Control
                  readOnly
                  type='number'
                  value={folioNumber.Obj.units}
                  id='Current-Value'
                  placeholder=''
                  className={'updated-input pl-0'}
                />
              </Form.Group>
              <Form.Group className='d-flex flex-column justify-content-between my-3 ml-2'>
                <Form.Label className='opacity-50 small mb-1'>
                  Total Redeemable Amount
                </Form.Label>
                <InputGroup className='font-weight-medium'>
                  <InputGroup.Prepend>
                    <InputGroup.Text className='font-weight-medium'>
                      &#8377;
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    readOnly
                    type='text'
                    value={Intl.NumberFormat('en-IN').format(
                      folioNumber.Obj.current_value
                    )}
                    id='Current-Value'
                    placeholder=''
                    className={'updated-input'}
                  />
                </InputGroup>
              </Form.Group>
            </div>
            <Form.Group className='my-3'>
              <Form.Label className='opacity-75'>
                <ToggleSwitch
                  id='newsletter'
                  onChange={onToggleChange}
                  selected='units'
                  optionLabels={['units', 'amount']}
                />
              </Form.Label>
              <SwitchButton
                id=''
                value=''
                isChecked={checked}
                className='opacity-75 small mb-1 mt-2'
                label={
                  type === 'amount' ? 'Redeem Full Amount' : 'Redeem all Units'
                }
                onChange={changeCheck}
              />
              <InputGroup className='mb-2 font-weight-medium'>
                <InputGroup.Prepend>
                  <InputGroup.Text className='font-weight-medium'>
                    {type === 'units' ? '' : '₹'}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  value={redeemVal}
                  maxValue={
                    redeemType
                      ? folioNumber.Obj.current_value
                      : folioNumber.Obj.units
                  }
                  onChange={redeemValChange}
                  type='text'
                  id='Current-Value'
                  placeholder=''
                  className={`updated-input ${errorMsg ? 'is-invalid' : ''}`}
                />
                <Form.Control.Feedback type='invalid'>
                  {errorMsg}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Modal.Body>
          <div className='btn-container text-center mb-3 p-3'>
            <Button
              onClick={onredeemSubmit}
              className='btn btn-primary w-100'
              disabled={errorMsg}
            >
              Reedem
            </Button>
          </div>
        </Modal>
      ) : (
        <Modal
          show={show}
          size='sm'
          className='p-4'
          aria-labelledby='add-fund-modal'
          onHide={onSubmit}
          centered
          backdrop='static'
          keyboard={false}
        >
          <Modal.Header closeButton className='pb-0 mx-2'>
            <h5 className='font-weight-medium m-0'>Redeem</h5>
          </Modal.Header>
          <Modal.Body className='pt-0 mx-2'>
            <div>
              <Form.Group className='my-2'>
                <Form.Label className='opacity-50 small mb-1 '>
                  Select Folio
                </Form.Label>
                <DropdownList
                  selectedOption={folioNumber.value}
                  id='return-type'
                  classes={{
                    input: 'ml-0 updated-input pl-0 font-weight-bold',
                  }}
                  controlFunc={onFolioNumberChange}
                  options={options.folioList.map((item) => ({
                    value: item.folio_no,
                    label: item.folio_no,
                    Obj: item,
                  }))}
                />
              </Form.Group>
            </div>
            <div>
              <Form.Group className='my-3'>
                <Form.Label className='opacity-50 small mb-1'>
                  Total Redeemable Units
                </Form.Label>
                <Form.Control
                  readOnly
                  type='number'
                  value={folioNumber.Obj.units}
                  id='Current-Value'
                  placeholder=''
                  className={'opacity-50 updated-input pl-0 font-weight-bold '}
                />
              </Form.Group>
              <Form.Group className='my-3 '>
                <Form.Label className='opacity-50 small mb-1'>
                  Total Redeemable Amount
                </Form.Label>
                <InputGroup className='mb-2 font-weight-medium ml-0 pl-0'>
                  <InputGroup.Prepend>
                    <InputGroup.Text className='font-weight-medium ml-0'>
                      &#8377;
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    readOnly
                    type='text'
                    value={Intl.NumberFormat('en-IN').format(
                      folioNumber.Obj.current_value
                    )}
                    id='Current-Value'
                    placeholder=''
                    className={'updated-input ml-0 font-weight-bold opacity-50'}
                  />
                </InputGroup>
              </Form.Group>
            </div>
            <Form.Group className='my-3'>
              <div className='text-center '>
                <Form.Label className='text-center my-2 opacity-75 w-100'>
                  <ToggleSwitch
                    id='newsletter'
                    className='w-50'
                    onChange={onToggleChange}
                    selected='units'
                    optionLabels={['units', 'amount']}
                  />
                </Form.Label>
              </div>
              <SwitchButton
                id=''
                value=''
                isChecked={checked}
                className='opacity-75 small mb-1 mt-2'
                label={
                  type === 'amount' ? 'Redeem Full Amount' : 'Redeem all Units'
                }
                onChange={changeCheck}
              />
              <InputGroup className='mb-2 font-weight-medium'>
                <InputGroup.Prepend>
                  <InputGroup.Text className='font-weight-medium ml-0'>
                    {type === 'units' ? '' : '₹'}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  value={redeemVal}
                  maxValue={
                    redeemType
                      ? folioNumber.Obj.current_value
                      : folioNumber.Obj.units
                  }
                  onChange={redeemValChange}
                  type='text'
                  id='Current-Value'
                  placeholder=''
                  className={`updated-input  font-weight-bold ${
                    errorMsg ? 'is-invalid' : ''
                  }`}
                />
                <Form.Control.Feedback type='invalid'>
                  {errorMsg}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Modal.Body>
          <div className='btn-container text-center mb-3 mt-0 pt-0 px-3 pb-3'>
            <Button
              onClick={onredeemSubmit}
              className='btn btn-primary w-80 py-2'
              disabled={errorMsg}
            >
              Reedem
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};
ReedemFundModal.propTypes = {
  show: PropTypes.bool,
  options: PropTypes.object,
  onSubmit: PropTypes.func,
};
export default ReedemFundModal;
