import { client } from '../client';

interface CreateUserGroupsPayloadInterface {
  name: string;
}

export interface UserGroupsInterface {
  name: string;
  id: string;
}

export const getUserGroups = async (
  search_text: string
): Promise<UserGroupsInterface[]> => {
  let url = '/user_groups';
  if (search_text) {
    url += `?search_text=${search_text}`;
  }
  const res = await client.get(url);
  return res.data;
};

export const patchUserGroup = async (
  group_id: string,
  input: CreateUserGroupsPayloadInterface
) => {
  const res = await client.patch(`/user_groups/${group_id}`, input);
  return res.data;
};

export const deleteUserFromGroup = async (
  user_id: string,
  group_id: string
) => {
  const res = await client.delete(`/user_groups/${group_id}/user/${user_id}`);
  return res.data;
};

export const createUserGroups = async (
  input: CreateUserGroupsPayloadInterface
) => {
  const res = await client.post('/user_groups', input);
  return res.data;
};

export const getUsersByGroupId = async (group_id: string) => {
  const res = await client.get(`user_groups/users/${group_id}`);
  return res.data;
};

const UserGroups = {
  getUserGroups,
  createUserGroups,
  getUsersByGroupId,
  deleteUserFromGroup,
  patchUserGroup,
};

export default UserGroups;
