import React from 'react';
import PropTypes from 'prop-types';
import { formatNumber, regexString } from '../../utility/utility';
import { useHistory } from 'react-router-dom';

const FundList = ({ fundItems }) => {
  let history = useHistory();
  const onButtonClick = (name,id) => {
    history.push({pathname:`/fund/detail/${name}/${id}`});
  };
  let section = -1;
  const getNextSection = () => {
    section += 1;
    return section;
  };
  return (
    <>
      {fundItems && fundItems.length
        ? fundItems.map((fundItem) => (
            <div key={'fund-list-' + fundItem.category_id} id={'fund-list-' + fundItem.category_id}>
              {fundItem.funds && fundItem.funds.length ? (
                <div className="fund-list-section border-bottom my-3">
                  <div className="fund-list-title mb-3 row font-weight-medium align-items-center">
                    <div className="col-sm-10">
                      <h5 className="c-blue">{fundItem.category}</h5>
                    </div>
                    {getNextSection() === 0 ? (
                      <>
                        <div className="col-sm-1">1Y</div>
                        <div className="col-sm-1">3Y</div>
                      </>
                    ) : null}
                  </div>

                  {fundItem.funds.map((item) => (
                    <div className="row fund-item-row mb-2" key={'fund-list-item-' + item.id} id={'fund-list-item-' + item.id}>
                      <div className="col-sm-8">
                        <span>{item.fscbi_legal_name}</span>
                      </div>
                      <div className="col-sm-2">
                        <button
                          type="button"
                          id={'fund-list-item-' + item.id}
                          className="btn btn-primary py-1 w-100"
                          onClick={() => {
                            onButtonClick(regexString(item.fscbi_legal_name),item.id);
                          }}>
                          Invest
                        </button>
                      </div>
                      <div className="col-sm-1">
                        <span>{formatNumber(item.ttr_return_1_yr, 1)}%</span>
                      </div>
                      <div className="col-sm-1">
                        <span>{formatNumber(item.ttr_return_3_yr, 1)}%</span>
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          ))
        : null}
    </>
  );
};
FundList.propTypes = {
  fundItems: PropTypes.array,
};
export default FundList;
