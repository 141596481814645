import React, { useEffect } from 'react';
import ProfileIcon from '../../assets/Profile.svg';
import ForwardArrowIcon from '../../assets/forward-arrow.svg';
import AddIcon from '../../assets/add-profile.svg';
import { uploadProfileDoc } from '../../api/profile';
import { UploadBox } from '../../common/form';
import { useDispatch, useSelector } from 'react-redux';
import { decryptData } from '../../utility/DecryptHelper';
import { updateProfile } from '../../redux/reducers/auth-reducer';
import Toast from '../../common/toast/Toast';
import { Link, useHistory } from 'react-router-dom';
import MobileNavigation from '../../common/footermobile/MobileNavigation';
import { getInvestorStatus } from '../../api/kyc';
import { updateinvestorStatus } from '../../redux/reducers/auth-reducer';

const MobileProfile = () => {
  const history = useHistory();
  const { user_profile_picture, user_name } = useSelector((state) => state.authReducer.rise);
  const { email } = useSelector((state) => state.authReducer);
  const { ready_to_invest } = useSelector((state) => state.authReducer);
  const userId = useSelector((state) => state.authReducer.user_id);
  const dispatch = useDispatch();
  const updateProfileDocument = (name, file) => {
    const data = new FormData();
    data.append(name, file);
    uploadProfileDoc(data).then((res) => {
      if (res && res.data) {
        if (res.data.status.message && res.data.status.code === 1) {
          Toast.success(res.data.status.message);
        }
        const profileDOcs = decryptData(res.data.data);
        if (name === 'profile_picture') {
          dispatch(updateProfile({ user_profile_picture: profileDOcs.data.user_profile_image }));
        }
      }
    });
  };

  useEffect(() => {
    getInvestorStatus(userId).then((res) => {
      if (res && res.data) {
        if (res?.data?.status === 'ready_to_invest') {
          dispatch(updateinvestorStatus(true));
        }
      }
    });
  }, []);

  const redirectToHome = () => {
    history.push('/home');
  };

  return (
    <>
      <div className="bg-light-grey">
        <div className="profile-container-mobile  ">
          <div className="row">
            <div className="col-sm-4 ">
              <div className=" font-weight-medium h-100">
                <div className=" py-4 text-center">
                  <div className="profile-pic-mobile rounded-circle d-inline-block">
                    <img
                      className="profile-mobile  rounded-circle"
                      src={user_profile_picture || ProfileIcon}
                      alt=""
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = ProfileIcon;
                      }}
                    />
                    <UploadBox
                      icon={true}
                      iconImg={AddIcon}
                      accept={['jpeg', 'jpg', 'png']}
                      onChange={(file) => {
                        updateProfileDocument('profile_picture', file);
                      }}
                    />
                  </div>
                  <div className=" text-capitalize  mt-3">{user_name}</div>
                  <div className=" font-weight-normal ">{email}</div>
                </div>
                <div className="flex-column">
                  <hr className="mx-5"></hr>
                  <div className="py-2  ">
                    <Link to="/mobileprofile/detail" className={' py-2 my-1 px-5 c-black '}>
                      <span className="ml-1">Profile</span>
                      <img src={ForwardArrowIcon} alt="forward icon" className="float-right mr-4 pr-4" />
                    </Link>
                  </div>
                  <hr className="mx-5"></hr>
                  <div className="py-2 ">
                    <Link to="/mobileprofile/bank-list" className={' py-2 my-1 px-5 c-black '}>
                      <span className="ml-1">Bank Details</span>
                      <img src={ForwardArrowIcon} alt="forward icon" className="float-right mr-4 pr-4" />
                    </Link>
                  </div>
                  <hr className="mx-5"></hr>
                  {ready_to_invest ? (
                    <div className="py-2 ">
                      <Link to="/mobileprofile/my-sips" className={' py-2 my-1 px-5 c-black '}>
                        <span className="ml-1">My SIPs</span>
                        <img src={ForwardArrowIcon} alt="forward icon" className="float-right mr-4 pr-4" />
                      </Link>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className={'w-100 mt-5 text-center'}>
                    <button className={'new-ui-btn'} onClick={redirectToHome}>
                      Go to Home
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MobileNavigation />
      </div>
    </>
  );
};
export default MobileProfile;
