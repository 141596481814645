import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ReactComponent as SuccessIcon } from '../../../assets/success.svg';
import { ReactComponent as FailureIcon } from '../../../assets/failure.svg';
import './ResultModal.scss';

interface Props {
  show: boolean;
  type?: 'success' | 'failure';
  onHide: () => void;
  message: string;
}

export const ResultModal = ({
  show,
  onHide,
  message,
  type = 'failure',
}: Props) => {
  const isSuccess = type === 'success';
  return (
    <Modal show={show} onHide={onHide} size='sm' backdrop='static' centered>
      <Modal.Body>
        <div className='ResultModal__body_wrapper'>
          {type === 'success' ? (
            <SuccessIcon width={80} height={80} />
          ) : (
            <FailureIcon width={80} height={80} />
          )}
          <span>{message}</span>
          {isSuccess && (
            <Link className='primary-button' to='/home'>
              Go to Dashboard
            </Link>
          )}
          {!isSuccess && (
            <button className='primary-button' onClick={onHide}>
              Retry
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
