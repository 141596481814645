import { AxiosError } from 'axios';
import kycApi, { EkycRedirectionLinkRes } from '../api/mfApi/kyc';
import { initiateKYCDetail } from '../utility/utility';
import { useMedia } from './useMedia';
import Toast from '../common/toast/Toast';
import { useAppSelector } from '../redux/hooks';

export const useKyc = () => {
  const isMobile = useMedia(['(min-width: 480px)'], [false], true);
  const { supportEmail } = useAppSelector((state) => state.whitelabelReducers);
  const { userDetailFromElevoBE, username } = useAppSelector(
    (state) => state.authReducer
  );

  const getEkycRedirectionLink = async (): Promise<
    EkycRedirectionLinkRes | undefined
  > => {
    const res = await kycApi.getEkycRedirectionUrl();
    if (res.data) {
      return res.data;
    }
    return undefined;
  };

  const initiateKyc = async (userDetail: any) => {
    getEkycRedirectionLink()
      .then((redirectionUrls) => {
        if (redirectionUrls)
          !isMobile
            ? (window.open(redirectionUrls.web_redirect_url))
            : (window.open(redirectionUrls.mobile_redirect_url));
      })
      .catch(async (err) => {
        const axiosError = err as AxiosError;
        if (axiosError?.response?.status === 404) {
          const res = await kycApi.createEkycRedirectionUrl(
            initiateKYCDetail({
              name:
                username ||
                userDetail?.data?.pan_name ||
                userDetail?.data?.email,
              pan: userDetail?.data?.pan,
              email: userDetail?.data?.email,
              mobile: userDetail?.data?.mobile_number,
            }),
            userDetailFromElevoBE?.id
          );
          !isMobile
            ? (window.open(res?.data?.web_redirect_url))
            : (window.open(res?.data?.mobile_redirect_url));
        } else {
        }
      })
      .catch(() => {
        Toast.error(
          `Not able to initiate the KYC. Please contact the ${
            supportEmail ? `us at ${supportEmail}` : 'Customer Support'
          }.`
        );
      });
  };
  return {
    initiateKyc,
  };
};
