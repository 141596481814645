import { Navbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { updateSelectedNav } from '../../redux/reducers/header-reducer';
import AppHeader from './AppHeader';

import Logo from '../../component/whitelable/Logo';
import config from '../../whitelabel-config';

const Header = () => {
  const { user_id, website_url } = useSelector((state) => state.authReducer);
  const { activeLink } = useSelector((state) => state.headerReducer);
  const dispatch = useDispatch();
  const updateActiveLink = (link) => {
    dispatch(updateSelectedNav(link));
  };

  return (
    <>
      {user_id ? (
        <AppHeader />
      ) : (
        <Navbar
          collapseOnSelect
          expand='lg'
          variant='light'
          className='sticky-top navbar-light bg-white mb-4 mx-4'
        >
          <Link className='navbar-brand' to='/'>
            <Logo />
          </Link>
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse className='font-weight-medium'>
            <ul className='navbar-nav ml-auto mt-2'>
              <li
                className={`nav-item mr-4 ${activeLink === 'mf' && 'active'}`}
                onClick={() => updateActiveLink('mf')}
              >
                <Link className='nav-link' smooth>
                  {config.APP_TEXT}
                </Link>
              </li>
              <li
                className={`nav-item mr-4 ${
                  activeLink === 'homepage' && 'active'
                }`}
                onClick={() => updateActiveLink('homepage')}
              >
                <Link className='nav-link' smooth to={website_url}>
                  {config.WEBSITE_TEXT}
                </Link>
              </li>
            </ul>
          </Navbar.Collapse>
        </Navbar>
      )}
    </>
  );
};
export default withRouter(Header);
