import React, { useState, useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { UploadBox } from '../form';
import { getDocumentNri } from '../../api/kyc';
import { useSelector } from 'react-redux';
import SignatureModal from '../../modal/SignatureModal';
import uploadIcon from '../../assets/upload.svg';
function isTouchDevice() {
  return (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0));
}
const DocumentUpload = React.forwardRef(({infoMsg="*All documents should be signed and stamped", successMessage, documentList, singleDocUpload, panStatus, kycData }, ref) => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [, setStatus] = useState('');
  const [signModal, setSignModal] = useState(false);
  const [error, setError] = useState();
  const userId = useSelector((state) => state.authReducer.user_id);
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  useEffect(() => {
    getUserDocumentNri();
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getUserDocumentNri = () => {
    getDocumentNri(userId).then((res) => {
      if (res && res.data && res.data.data && res.data.data.length) {
        setStatus('success');
      }
    });
  };
  const onDocumnetUpload = (file) => {
    singleDocUpload(file);
    setError(undefined)
  };
  const toggleSignModal = () => {
    setSignModal(!signModal);
  };
  const onSignatureSubmit = (image) => {
    singleDocUpload(image);
    toggleSignModal();
  };
  useImperativeHandle(ref, () => ({ validateDoc }));

  const validateDoc = (isSaveAsDraft) => {
    if(isSaveAsDraft || (!(successMessage.status || documentList?.first_applicant_signature))) {
      // setError("Documents should not be empty.")
      return false;
    }
    return true;
  };
  return (
    <>
      {windowDimensions.width > 480 ? (
        <div className="border">
          <div className="d-flex font-weight-bold align-items-center">
            <div className="col-sm-8 py-2  border-right">
              <span className="ml-2">Documents</span>
            </div>
            <div className="col-sm-2 py-2 border-right text-center">Upload</div>
            <div className="col-sm-2 text-center">Status</div>
          </div>
          <div className="d-flex border-top align-items-center" key={'Signature'}>
            {isTouchDevice() && (kycData.tax_status_tcs === '01' || kycData.tax_status_tcs === '02' || kycData.tax_status_tcs === '11') ? (
              <>
                <div className="col-sm-2 py-2  text-center">
                  <img src={uploadIcon} alt="upload" className="icon" onClick={toggleSignModal} />
                </div>
              </>
            ) : (
              <>
                <div className="col-sm-8 py-2  border-right">
                  <span className="ml-2">{'Signature'}</span>
                </div>
                <div className="col-sm-2 py-2 border-right text-center">
                  <UploadBox
                    icon={true}
                    accept={['pdf', 'jpeg', 'jpg', 'doc', 'png']}
                    onChange={(files) => {
                      onDocumnetUpload(files);
                    }}
                  />
                </div>
              </>
            )}
            <div className={'col-sm-2 c-green text-center text-capitalize'}>
              <span
                className={
                  successMessage.status || documentList?.first_applicant_signature
                    ? ' c-green text-center text-capitalize '
                    : ' c-red text-center text-capitalize'
                }>
                {successMessage.status || documentList?.first_applicant_signature ? 'Completed' : 'Pending'}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="">
          {/*{documentList &&*/}
          {/*  documentList.length &&*/}
          {/*  documentList.map((docObj) => (*/}
          <>
            <div className=" border-dashed justify-content-center align-items-center bg-light-navy pb-4" key={'Signature'}>
              {(isTouchDevice() && (kycData.tax_status_tcs === '01' || kycData.tax_status_tcs === '02' || kycData.tax_status_tcs === '11')) ? <>
                  <div className="col-sm-2 py-2  text-center">
                    <img src={uploadIcon} alt="upload" className="icon" onClick={toggleSignModal}/>
                  </div>
                </> :
                <>
                  <div
                    className="col-sm-8 py-2  ">{/*<img src={documentIcon} alt="documents" className="icon ml-2" />*/}</div>
                  <div className="col-sm-2 py-2  text-center">
                    <UploadBox
                      icon={true}
                      accept={['pdf', 'jpeg', 'jpg', 'doc', 'png']}
                      onChange={(files) => {
                        onDocumnetUpload(files);
                      }}
                    />
                  </div>
                </>
              }
            </div>
            <div className="my-4">
              <span className="ml-0 font-weight-bold">{'Signature'}</span>{' '}
              <span
                className={
                  successMessage.status || documentList?.first_applicant_signature
                    ? ' c-green text-center text-capitalize float-right'
                    : ' float-right  c-red text-center text-capitalize'
                }>
                {successMessage.status || documentList?.first_applicant_signature ? 'Completed' : 'Pending'}
              </span>
              <hr />
            </div>
          </>
          {/*))}*/}
        </div>
      )}
      {error && <div className={'invalid-feedback d-block'} style={{position: 'absolute'}}>{error}</div>}
      {/*<div className="c-blue my-3 small">{infoMsg}</div>*/}
      <SignatureModal show={signModal} onClose={toggleSignModal} onSubmit={onSignatureSubmit} />
    </>
  );
});
DocumentUpload.propTypes = {
  documentList: PropTypes.array,
  singleDocUpload: PropTypes.func,
  multiDocUpload: PropTypes.func,
  infoMsg: PropTypes.string,
  title: PropTypes.string,
  panStatus: PropTypes.string,
  successMessage: PropTypes.object,
};
export default DocumentUpload;
