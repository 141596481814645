import axios from 'axios';

const getWhitelabelDetails = async (): Promise<any> => {
  const res = await axios.get(
    `${process.env.REACT_APP_FD_API_URL}/config/assets`
  );
  return res.data;
};

const whitelabelServices = {
  getWhitelabelDetails,
};

export default whitelabelServices;
