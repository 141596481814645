import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import AnnexureBModal from '../../modal/AnnexureBModal';
const AnnexureBExamption = ({ onChange, value, err }) => {
  const [annexureB, setData] = useState({});
  const [showAnnexureModal, setModalFlag] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
   
  useEffect(() => {
    setData(value);
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [value]);
  const onNameChange = (event) => {
    const { value } = event.target;
    setData(value);
    if (onChange) {
      onChange(value);
    }
  };
  return (
    <>
    {windowDimensions.width>480?(
      <div className="border p-4 rounded w-100 my-3">
      <Form.Group className="w-100">
        <Form.Label className="opacity-75 mb-1 h6">
          Select your Non-Financial Entity Sub Category from{' '}
          <span
            className="c-blue cursor-pointer"
            onClick={() => {
              setModalFlag(true);
            }}>
            Annexure B
          </span>
        </Form.Label>
        <Form.Control
          type="text"
          id="annexureB"
          onChange={onNameChange}
          placeholder=""
          value={annexureB}
          className={'updated-input pl-0 form-control font-weight-medium col-sm-1 ' + (err ? 'is-invalid ' : '')}
        />
        <Form.Control.Feedback type="invalid">{err}</Form.Control.Feedback>
      </Form.Group>
      <AnnexureBModal
        show={showAnnexureModal}
        onSubmit={() => {
          setModalFlag(false);
        }}
      />
    </div>
    ):(
     <div className=" py-2 rounded w-100 my-3">
      <Form.Group className="w-100">
        <Form.Label className="opacity-75 mb-1 h6">
          Select your Non-Financial Entity Sub Category from{' '}
          <span
            className="c-blue cursor-pointer"
            onClick={() => {
              setModalFlag(true);
            }}>
            Annexure B
          </span>
        </Form.Label>
        <Form.Control
          type="text"
          id="annexureB"
          onChange={onNameChange}
          placeholder=""
          value={annexureB}
          className={'updated-input pl-0 form-control font-weight-medium col-sm-1 ' + (err ? 'is-invalid ' : '')}
        />
        <Form.Control.Feedback type="invalid">{err}</Form.Control.Feedback>
      </Form.Group>
      <AnnexureBModal
        show={showAnnexureModal}
        onSubmit={() => {
          setModalFlag(false);
        }}
      />
    </div>
    )}
    </>
  );
};
AnnexureBExamption.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};
export default AnnexureBExamption;
