import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RadioGroups } from '../form';
import { AnnualIncomeList } from '../../utility/utility';

const AnnualIncome = ({ onChange, value,title, err }) => {
  const [annualIncome, setData] = useState('');
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
    
  useEffect(() => {
    setData(value);
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [value]);
  const onRadioChange = (event) => {
    const { value } = event.target;
    setData(value);
    if (onChange) {
      onChange(value);
    }
  };
  return (
    <>
    {windowDimensions.width>480?(
      <div className="border p-4 rounded w-100 my-3">
      <RadioGroups title={title?title:"Annual Income"} id="annualIncome" radioList={AnnualIncomeList} selectedVal={annualIncome} onChange={onRadioChange} err={err} />
    </div>
    ):(
    <div className="py-2 rounded w-100 my-3">
      <RadioGroups title={title?title:"Annual Income"} id="annualIncome" radioList={AnnualIncomeList} selectedVal={annualIncome} onChange={onRadioChange} err={err} />
    </div>
    )}
    </>
  );
};
AnnualIncome.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  title: PropTypes.string,
};
export default AnnualIncome;
