import React, { useEffect, useState } from 'react';
import PaymentInitiate from '../../modal/PaymentInitiate';
import SelectBankModal from '../../modal/SelectBankModal';
import SelectPaymentModeModal from '../../modal/SelectPaymentModeModal';
import SuccessModal from '../../modal/SuccessModal';
import { paymentV2 } from '../../api/cart';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const Payment2 = ({ orders, totalAmount, userId, onpaymentCompleted }) => {
  const [accountObj, setAccountObj] = useState({});
  const [bankModal, setBankModalInfo] = useState({ show: false, data: null });
  const [paymentModeModal, setPaymentModal] = useState({ show: false, data: null });
  const [successfulModal, setSuccessfulModal] = useState({ show: false, data: null });
  const [payStartModal, setPayStartModal] = useState(false);
  const [upi] = useState('');
  const [utrNumber] = useState('');
  const [param, setParam] = useState('');
  const [order, setOrder] = useState();
  const [paymentMode, setPaymentMode] = useState('DIRECT');
  const history = useHistory();
  useEffect(() => {
    // validateOrderPaymentFunc(orders);
    setParam(btoa(JSON.stringify(orders)));
    setBankModalInfo({show: true})
    setOrder(orders.map(item => ({order_id: item})))
  }, [JSON.stringify(orders)]);
  const accountNoChange = () => {
    setPaymentModal({ show: false });
    setBankModalInfo({ show: true });
  };
  const closeBankModal = (accountObject) => {
    setAccountObj(accountObject);
    setBankModalInfo({ show: false });
    setPaymentModal({ show: true });
  };
  const closePaymenModeModal = (mode, paymentInfo) => {
    let curMode = ''
    if(mode === 'NEFT/RTGS') {
      curMode = 'neft_rtgs';
    } else if (mode === 'UPI') {
      curMode = 'upi'
    } else if (mode === 'DIRECT') {
      curMode = 'netbanking'
    }
    let payMode = mode === 'NEFT/RTGS' ? 'RTGS' : mode;
    setPaymentMode(payMode);
    if (mode !== 'DIRECT' && !paymentInfo) {
      return false;
    }
    let reqData = {
      payment_method: curMode,
      investor_id: userId,
      bank_id: accountObj.id,
      orders: order,
      amount: totalAmount,
    }
    if(curMode === 'neft_rtgs') {
      reqData.payment_reference = paymentInfo
    } else if (curMode === 'netbanking') {
      reqData.callback_url = window.location.origin + `/order/complete/${param}/${mode === 'NEFT/RTGS' ? 'RTGS' : mode}`;
    } else if (curMode === 'upi') {
      reqData.callback_url = window.location.origin + `/order/complete/${param}/${mode === 'NEFT/RTGS' ? 'RTGS' : mode}`;
      reqData.upi_id = paymentInfo;
    }

    paymentV2(reqData).then(response => {
      if(payMode === 'RTGS') {
        setTimeout(() => {
          onpaymentCompletedClose(payMode);
        }, 200)
      } else {
        window.location.href = response.data.redirect_url
      }
    }).catch(e => {
      onpaymentCompleted();
    });
  };

  const onpaymentCompletedClose = (payMode) => {
    onpaymentCompleted();
    setPayStartModal(false);
    history.push(`/order/complete/${param}/${payMode}`);
  };
  const closeSuccessFulModal = () => {
    onpaymentCompleted();
    setSuccessfulModal({ show: false });
    history.push(`/order/complete/${param}/${paymentMode}`);
  };
  return (
    <>
      {bankModal.show ? <SelectBankModal show={bankModal.show} onSubmit={closeBankModal} accountInfo={accountObj} /> : null}
      {paymentModeModal.show ? (
        <SelectPaymentModeModal
          show={paymentModeModal.show}
          accountNoChange={accountNoChange}
          onSubmit={closePaymenModeModal}
          accountObj={accountObj}
          upi={upi}
          utrNumber={utrNumber}
        />
      ) : null}
      {successfulModal.show ? (
        <SuccessModal show={successfulModal.show} onSubmit={closeSuccessFulModal} successText="Payment Successful" size="sm" />
      ) : null}
      {payStartModal ? (
        <PaymentInitiate show={payStartModal} onSubmit={onpaymentCompletedClose} userId={userId} orderIds={orders} isNetBanking={paymentMode === 'DIRECT'}/>
      ) : null}
    </>
  );
};
Payment2.propTypes = {
  history: PropTypes.object,
  totalAmount: PropTypes.number,
  userId: PropTypes.number,
  orders: PropTypes.array,
  onpaymentCompleted: PropTypes.func,
};
export default React.memo(Payment2);
