import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
const AnnexureAModal = ({ show, onSubmit }) => {
  return (
    <Modal show={show} size="lg" aria-labelledby="kyc-verified-modal" onHide={onSubmit} centered backdrop="static" keyboard={false}>
      <Modal.Header className="annexure" closeButton>
        <h2 className="font-weight-medium col-sm-12 text-center">Annexure A</h2>
      </Modal.Header>
      <Modal.Body className="small text-center">
        <div className="border px-3">
          <div className="row text-white bg-primary align-items-center">
            <div className="col-sm-1 border-right py-3">CODE</div>
            <div className="col-sm-11">PARTICULARS</div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-1 border-right py-3 c-blue">A</div>
            <div className="col-sm-11 text-left px-4">
              An organization exempt from tax under section 501(a) or any individual retirement plan as defined in section 7701(a)(37)
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-1 border-right py-3 c-blue">B</div>
            <div className="col-sm-11 text-left px-4">The United States or any of its agencies or instrumentalities</div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-1 border-right py-3 c-blue">C</div>
            <div className="col-sm-11 text-left px-4">
              A state, the District of Columbia, a possession of the United States, or any of their political subdivisions or instrumentalities
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-1 border-right py-3 c-blue">D</div>
            <div className="col-sm-11 text-left px-4">
              A corporation the stock of which is regularly traded on one or more established securities markets, as described in Reg. section
              1.1472-1(c)(1)(i)
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-1 border-right py-3 c-blue">E</div>
            <div className="col-sm-11 text-left px-4">
              A corporation that is a member of the same expanded affiliated group as a corporation described in Reg. section 1.1472-1(c)(1)(i)
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-1 border-right py-3 c-blue">F</div>
            <div className="col-sm-11 text-left px-4">
              A dealer in securities, commodities, or derivative financial instruments (including notional principal contracts, futures, forwards, and
              options) that is registered as such under the laws of the United States or any state
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-1 border-right py-3 c-blue">G</div>
            <div className="col-sm-11 text-left px-4">A real estate investment trust</div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-1 border-right py-3 c-blue">H</div>
            <div className="col-sm-11 text-left px-4">
              A regulated investment company as defined in section 851 or an entity registered at all times during the tax year under the Investment
              Company Act of 1940
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-1 border-right py-3 c-blue">I</div>
            <div className="col-sm-11 text-left px-4">A common trust fund as defined in section 584(a)</div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-1 border-right py-3 c-blue">J</div>
            <div className="col-sm-11 text-left px-4">A bank as defined in section 581</div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-1 border-right py-3 c-blue">K</div>
            <div className="col-sm-11 text-left px-4">A broker</div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-1 border-right py-3 c-blue">L</div>
            <div className="col-sm-11 text-left px-4"> A trust exempt from tax under section 664 or described in section 4947(a)(1)</div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-1 border-right py-3 c-blue">M</div>
            <div className="col-sm-11 text-left px-4">A tax exempt trust under a section 403(b) plan or section 457(g) plan</div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-1 border-right py-3 c-blue">N</div>
            <div className="col-sm-11 text-left px-4">Not Applicable</div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
AnnexureAModal.propTypes = {
  show: PropTypes.bool,
  onSubmit: PropTypes.func,
};
export default AnnexureAModal;
