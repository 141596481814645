import jwtDecode from 'jwt-decode';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { fdApi } from '../api/fdApi';
import { autoLogin } from '../api/login.api';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {
  setScopes,
  setUser,
  setUserDetailFromElevoBE,
  setUserPermissions,
  updateAuthData,
} from '../redux/reducers/auth-reducer';
import {
  setSupportEmail,
  setWhitelabelConfig,
} from '../redux/reducers/whitelabel-reducers';
import { Scope } from '../types';
import { UNAUTHORIZED_USER_ERR_MSG } from '../utility/constant';
import {
  TOKEN_KEY,
  getTenantName,
  redirectUserForAuthentication,
  removeToken,
  saveToken,
  setCookie,
} from '../utility/utility';
import { getAppConfig } from '../api/apps_url';
interface JWTWithScopes {
  scopes: Scope[];
}

const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
  // Earlier point where we can update the document's title
  let tenantName = getTenantName();
  if (tenantName === 'localhost') {
    tenantName = 'elevo';
  }
  document.title = tenantName.toUpperCase();

  const dispatch = useAppDispatch();
  const { mf } = useAppSelector((state) => state.whitelabelReducers);

  // Fetching wl config details
  useEffect(() => {
    fdApi.whitelabelServices
      .getWhitelabelDetails()
      .then((res) => {
        dispatch(setWhitelabelConfig(res));
      })

      .catch((err: any) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    getAppConfig().then((res: any) => {
      dispatch(setSupportEmail(res.data?.support_email));
    });
  }, []);

  // setting up title and favicon
  useEffect(() => {
    let tenantName = getTenantName();
    if (tenantName === 'localhost') {
      tenantName = 'elevo';
    }
    document.title = mf?.title ?? tenantName.toUpperCase() + ' | Auth';
    const faviconUrl =
      mf?.favicon ?? window.location.origin + '/' + tenantName + '/favicon.ico';
    document
      .getElementById('dynamic-app-favicon')!
      .setAttribute('href', faviconUrl);
  }, [mf]);

  const history = useHistory();
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    fdApi
      .getMe()
      .then((res) => {
        if (res?.permissions) {
          dispatch(setUserPermissions(res.permissions));
        }
        dispatch(setUserDetailFromElevoBE(res));
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    const access_token = localStorage.getItem(TOKEN_KEY);
    const loginToken = urlSearch.get('login');
    let code = urlSearch.get('code');
    let mandate_id = urlSearch.get('mandate_id');
    let status = urlSearch.get('status');

    if (!loginToken && !access_token && !code) {
      redirectUserForAuthentication(UNAUTHORIZED_USER_ERR_MSG);
    } else if (loginToken) {
      localStorage.removeItem('panData');
      autoLogin(loginToken)
        .then((response) => {
          if (response && response.data) {
            let decodeData = response.data;
            if (decodeData) {
              let uD = decodeData;
              if (uD) {
                saveToken(uD.token);
                const token: JWTWithScopes = jwtDecode(uD.token);
                dispatch(setScopes(token.scopes));
                dispatch(setUser(token));
                localStorage.setItem('lD', JSON.stringify(uD));
                dispatch(updateAuthData(uD));
              }
              redirect();
            }
          }
        })
        .catch((e) => {
          console.log(e);
          removeToken();
          localStorage.removeItem('lD');
          redirectUserForAuthentication(UNAUTHORIZED_USER_ERR_MSG);
        });
    } else if (mandate_id || status) {
      const uD = JSON.parse(localStorage.getItem('lD')!);
      if (uD) {
        const token: JWTWithScopes = jwtDecode(uD.token);
        dispatch(setScopes(token.scopes));
        dispatch(setUser(token));
        dispatch(updateAuthData(uD));
        setTimeout(() => {
          setAuthenticated(true);
        }, 200);
      } else {
        redirectUserForAuthentication(UNAUTHORIZED_USER_ERR_MSG);
      }
    } else if (code) {
      try {
        removeToken();
        localStorage.removeItem('lD');
        let decodeData = decodeURIComponent(escape(window.atob(code)));
        if (decodeData) {
          let uD = JSON.parse(decodeData);
          if (uD) {
            saveToken(uD.token);
            const token: JWTWithScopes = jwtDecode(uD.token);
            dispatch(setScopes(token.scopes));
            dispatch(setUser(token));

            localStorage.setItem('lD', JSON.stringify(uD));
            dispatch(updateAuthData(uD));
            redirect();
          }
        }
      } catch (e) {
        redirectUserForAuthentication(UNAUTHORIZED_USER_ERR_MSG);
      }
    } else if (access_token) {
      const token: JWTWithScopes = jwtDecode(access_token);
      dispatch(setScopes(token.scopes));
      dispatch(setUser(token));

      setCookie(TOKEN_KEY, access_token);
      const uD = JSON.parse(localStorage.getItem('lD')!);
      dispatch(updateAuthData(uD));
      redirect();
    } else {
      redirectUserForAuthentication(UNAUTHORIZED_USER_ERR_MSG);
    }
  }, []);

  const redirect = () => {
    urlSearch.delete('code');
    urlSearch.delete('mandate_id');
    urlSearch.delete('status');

    history.push({
      pathname: location.pathname,
      search: urlSearch.toString(),
    });

    setTimeout(() => {
      setAuthenticated(true);
    }, 200);
  };

  return authenticated ? children : null;
};

export default AuthProvider;
