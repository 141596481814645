import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { regexString } from '../../../../utility/utility';
import FundImage from '../../../assets/funds/fund-logo.png';
import PillButton from '../../../shared/component/PillButton';

const TopPicks = ({ topMutualFunds }) => {
  const history = useHistory();
  const [funds, setFunds] = useState([]);

  useEffect(() => {
    if (topMutualFunds) {
      setFunds(topMutualFunds);
    }
  }, []);

  const onInvestClick = (id, name) => {
    let url = `/fund/detail/${name}/${id}`;
    history.push({ pathname: url });
  };

  return (
    <>
      <div className={'top_picks_card d-none d-md-block'}>
        <div className={'section_title'}>
          <span style={{ color: '#464646' }}>Top Mutual Funds</span>
          <Link to='/funds/all' className={'top_picks__explore_all'}>
            Explore All
          </Link>
        </div>
        <div className={'top_picks_header'}>
          <div className={'header_field_names'}>Fund Name</div>
          <div className={'header_field_names'}>6 M Returns</div>
          <div className={'header_field_names'}>1 Y Returns</div>
          <div className={'header_field_names'}>3 Y Returns</div>
          <div className={'header_field_names'}></div>
        </div>
        <div
          style={{ maxHeight: 'auto', overflow: 'auto', paddingRight: '10px' }}
        >
          {funds.map((item, index) => {
            return (
              <>
                <div
                  key={`top-fund-${index}`}
                  className={'top-picks-row border-top'}
                >
                  <div
                    className={'top-picks-fund-detail justify-content-between'}
                  >
                    <div
                      className={'d-flex align-items-center'}
                      onClick={() => {
                        onInvestClick(item.id, regexString(item.name));
                      }}
                    >
                      <div className={'top-picks-fund-logo'}>
                        <img src={item.logo || FundImage} alt={'amc_image'} />
                      </div>
                      <div className={'top-picks-fund-title'}>{item.name}</div>
                    </div>
                  </div>
                  {item.ratings && (
                    <div className={'top-picks-fund-recom'}>
                      <span className={'label-text-light'}>
                        Prime Recommendation
                      </span>
                      <PillButton
                        text={formatRecom(
                          item.ratings[0]?.prime?.recommendation
                        )}
                        color={recomColor(
                          item.ratings[0]?.prime?.recommendation
                        )}
                      />
                    </div>
                  )}
                  {item.ratings && (
                    <div className={'top-picks-fund-rating'}>
                      <span className={'label-text-light'}>Prime Rating</span>
                      <StarRatings
                        rating={formatRating(item.ratings[0]?.prime?.rating)}
                        numberOfStars={5}
                        starRatedColor={'#ffb24c'}
                        starDimension={'12px'}
                        starSpacing={'1px'}
                      />
                    </div>
                  )}
                  <div className={'top-picks-fund-rating'}>
                    <span className={'label-return-per'}>
                      {item.return_6_mth?.toFixed(2)}%
                    </span>
                  </div>
                  <div className={'top-picks-fund-rating'}>
                    <span className={'label-return-per'}>
                      {item.return_1_yr?.toFixed(2)}%
                    </span>
                  </div>
                  <div className={'top-picks-fund-rating'}>
                    <span className={'label-return-per'}>
                      {item.return_3_yr?.toFixed(2)}%
                    </span>
                  </div>

                  <div className={'invest-button-div'}>
                    <button
                      className={'invest-now-btn'}
                      onClick={() => {
                        onInvestClick(item.id, regexString(item.name));
                      }}
                    >
                      Invest Now
                    </button>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
      <div className={'top_picks_card d-block d-md-none'}>
        <div className={'section_title'}>
          <span style={{ color: '#464646' }}>Top Mutual Funds</span>
          <Link to={'/funds/all'} className={'top_picks__explore_all'}>
            Explore All
          </Link>
        </div>

        <div
          style={{ maxHeight: 'auto', overflow: 'auto', paddingRight: '8px' }}
        >
          {funds.map((item, index) => (
            <div key={`top-fund-${index}`} className={'top-picks-row d-block'}>
              <div className={'top-picks-fund-detail'}>
                <div
                  className={'d-flex align-items-center'}
                  onClick={() => {
                    onInvestClick(item.id, regexString(item.name));
                  }}
                >
                  <div className={'top-picks-fund-logo'}>
                    <img src={item.logo || FundImage} alt={'amc_image'} />
                  </div>
                  <div className={'top-picks-fund-title'}>{item.name}</div>
                </div>
                <div className={'invest-btn-div mt-2'}>
                  <button
                    className={'invest-now-btn'}
                    onClick={() => {
                      onInvestClick(item.id, regexString(item.name));
                    }}
                  >
                    Invest
                  </button>
                </div>
                {item.ratings && (
                  <div className={'top-picks-fund-rating'}>
                    <span className={'label-text-light'}>Prime Rating</span>
                    <StarRatings
                      rating={formatRating(item.ratings[0]?.prime?.rating)}
                      numberOfStars={5}
                      starRatedColor={'#ffb24c'}
                      starDimension={'10px'}
                      starSpacing={'1px'}
                    />
                  </div>
                )}
              </div>
              <div className={'top-picks-mobile-vals'}>
                <div className={'top-picks-values'}>
                  <span>6 M Returns </span>
                  <div className={'value'}>{item.return_6_mth.toFixed(2)}%</div>
                </div>
                <div className={'top-picks-values'}>
                  <span>1 Y Returns </span>
                  <div className={'value'}>{item.return_1_yr.toFixed(2)}%</div>
                </div>
                <div className={'top-picks-values'}>
                  <span>3 Y Returns </span>
                  <div className={'value'}>{item.return_3_yr.toFixed(2)}%</div>
                </div>
                {item.ratings && (
                  <div className={'top-picks-values'}>
                    <span>Prime Recommendation </span>
                    <div className={'value'}>
                      <PillButton
                        text={formatRecom(
                          item.ratings[0]?.prime?.recommendation
                        )}
                        color={recomColor(
                          item.ratings[0]?.prime?.recommendation
                        )}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

function formatRecom(value) {
  if (value === 'No opinion') {
    return 'NA';
  }
  return value;
}
function formatRating(value) {
  if (!value || value === 'Unrated') {
    return 0;
  }
  return parseFloat(value);
}
function recomColor(value) {
  if (value.toUpperCase() === 'BUY') {
    return 'success';
  } else {
    return 'danger';
  }
}

export default TopPicks;
