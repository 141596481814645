import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SipState {
  banner_state: boolean;
}

const initialState: SipState = {
  banner_state: false,
};

const sipSlice = createSlice({
  name: 'sip',
  initialState,
  reducers: {
    updateBannerStateAction: (state, action: PayloadAction<boolean>) => {
      state.banner_state = action.payload;
    },
  },
});

export const { updateBannerStateAction } = sipSlice.actions;

export default sipSlice.reducer;
