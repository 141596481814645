import { IssuerConsentInDB } from '../../../models';
import { client } from '../client';

export interface IssuerOut {
  id: number;
  tarraki_id: string;
  name: string;
  logo: string;
  website: string;
  min_interest_rate: number;
  max_interest_rate: number;
  min_amount: number;
  max_amount: number;
  min_tenure: number;
  max_tenure: number;
  min_tenure_unit: string;
  max_tenure_unit: string;
  lock_in_min: number;
  lock_in_max: number;
  lock_in_min_unit: string;
  lock_in_max_unit: string;
  min_age_min: number;
  min_age_max: number;
  about?: string;
  senior_citizen_benefit?: string;
  tags: TagInDB[];
  faq: FAQInDB[];
  neft_rtgs_instructions: string;
}

export interface TagInDB {
  id: number;
  name: string;
}

export interface FAQInDB {
  id: number;
  question: string;
  answer: string;
}

const getIssuers = async () => {
  const res = await client.get<IssuerOut[]>('/issuers');
  return res.data;
};

const getIssuerById = async (id: number) => {
  const res = await client.get<IssuerOut>(`/issuers/${id}`);
  return res.data;
};

const getIssuerConsents = async (id: number) => {
  const res = await client.get<IssuerConsentInDB[]>(`/issuers/${id}/consents`);
  return res.data;
};

const api = {
  getIssuers,
  getIssuerById,
  getIssuerConsents,
};

export default api;
