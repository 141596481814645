import React from "react"
import successfulIcon from '../assets/successful.svg';
import failedIcon from '../assets/not-supported.svg';
import { Link, useLocation } from 'react-router-dom';
import {  Form, InputGroup, Button } from 'react-bootstrap';
import { regexString } from "../utility/utility";
const RedemptionStatus = () => {
  const location = useLocation()
  const data = location.state
    return (
        <>
        <div className="row justify-content-center align-items-center mb-4">
         <h2>Redemption Status</h2>
         </div>
         <div className="row justify-content-center align-items-center h-100">
         <div className="col-sm-7 px-0">
         <div className="p-5 colored-box mb-4 text-center">
            <div className="img-container mb-4 ">
              <img src={data.code === 1 ? successfulIcon : failedIcon} alt="successful icon" className="w-150" />
            </div>
            <p className="font-weight">{data.code === 1 ? "Your transaction has been successful" : "Unfortunately, your  transaction has been failed, try again"}</p>
         </div>    
         </div>
         <div className="col-sm-7 px-0">
         <div className="p-5 colored-box mb-4">
           <Link to={{pathname: data?.data?.dataObj ? `/fund/detail/${regexString(data?.data?.dataObj?.name)}/${data?.data?.dataObj?.fundId} ` : '/' }} className="h4">{data.data.dataObj.name}</Link>
           <Form.Group className="mb-3">
              <Form.Label className="font-weight mt-4 mb-0 opacity-50">{data?.data?.dataObj?.type === "units" ? "Units" : "Amount"}</Form.Label>
              <InputGroup className="mb-2 font-weight-medium">
              {data?.data?.dataObj?.type==="units"? null : 
              (<>
               <InputGroup.Prepend>
               <InputGroup.Text className="font-weight-medium">&#8377;</InputGroup.Text>
               </InputGroup.Prepend>
               </>)
              }
                <Form.Control
                 type="text"
                 id="Total-Investment"
                 placeholder=""
                 disabled
                 value={Intl.NumberFormat('en-IN').format(data.data.dataObj.redeemVal)}
                 className={'updated-input font-weight-medium'}
                 />
              </InputGroup>
           </Form.Group>
           <div className="mt-4 mb-4">        
           <span className="pt-2">Withdrawal sent to AMC.</span>  
           {data.code === 1 ? <Button variant="success" className="float-right" disabled>Successful</Button> : <Button variant="danger" className="float-right" disabled>Failed</Button>}
           </div>       
           {data.code === 1 ? null :  <hr />}
           {data.code === 1 ? null : "Remark:Failed, Order quantity is less than minimum specified for scheme"}
           </div>
         </div>    
         </div>
         <div className="btn-container text-center mb-5 px-5 ">
         <Link to="/my-portfolio"><Button className="btn btn-primary mt-4 w-300px" >Back to portfolio</Button></Link>
        </div>
        </>
    )
}
export default RedemptionStatus
