import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

interface ChipProps {
  label: ReactJSXElement;
  filledColor?: string;
  outlineMode?: boolean;
}

const Chip = ({
  label,
  filledColor = '#a7aaab',
  outlineMode = false,
}: ChipProps) => {
  const chipStyles = {
    backgroundColor: filledColor,
    border: outlineMode ? `1px solid ${filledColor}` : 'none',
    padding: '5px 10px',
    borderRadius: '20px',
    fontSize: '11px',
    fontWeight: '500',
    color: 'white',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'default',
    marginTop: '5px',
    marginInline: '5px',
  };
  return (
    <div className='' style={chipStyles}>
      {label}
    </div>
  );
};

export default Chip;
