import { Checkbox } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { MdOutlineContentCopy } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import { fdApi } from '../../../api/fdApi';
import { IssuerOut } from '../../../api/fdApi/services/issuer.service';
import Toast from '../../../common/toast/Toast';
import Amount from '../../../component/Amount/Amount';
import {
  ApplicationOut,
  CreatePaymentJsonBodyPaymentMethod,
  GetSchemesType,
  InterestPayoutFrequencyEnum,
  IssuerConsentInDB,
  PaymentStatusEnum,
} from '../../../models';
import { useAppSelector } from '../../../redux/hooks';
import {
  capitalize,
  constructFdAppURL,
  hasAdminAccess,
  hasAssistModePermission,
} from '../../../utility/utility';
import { MainLoader } from '../../shared/component/MainLoader';
import './FDApplicationReviewAndPayment.scss';

// For mocking purpose it is 44, user testtestnri@gmail.com, mobile 9809980998

export interface Consent extends IssuerConsentInDB {
  value: boolean;
}

export const FDApplicationReviewAndPayment = () => {
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const application_id = urlSearchParams.get('id')!;

  const [issuerConsents, setIssuerConsents] = useState<{
    [key: number]: Consent;
  }>({});
  const [consentError, setConsentError] = useState(false);
  const [application, setApplication] = useState<ApplicationOut | null>(null);
  const [issuer, setIssuer] = useState<IssuerOut>({} as IssuerOut);
  const [isLoading, setIsLoading] = useState(false);
  const payount_frequency_type =
    application?.interest_payout_frequency ===
    InterestPayoutFrequencyEnum.Maturity
      ? GetSchemesType.Cumulative
      : GetSchemesType.NonCumulative;

  useEffect(() => {
    if (application_id) {
      setIsLoading(true);
      fdApi.application
        .getApplication(+application_id)
        .then((res) => {
          setApplication(res);
          if (res.issuer_id) {
            fdApi.issuer
              .getIssuerById(res.issuer_id)
              .then((res) => {
                setIssuer(res);
              })
              .catch((err) => {
                console.error(err);
              });
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [application_id]);

  useEffect(() => {
    setIsLoading(true);
    if (application?.issuer_id) {
      fdApi.issuer
        .getIssuerConsents(application.issuer_id)
        .then((res) => {
          if (res) {
            setIssuerConsents(
              res.reduce(
                (
                  acc: {
                    [key: number]: Consent;
                  },
                  item
                ) => {
                  acc[item.id] = {
                    ...item,
                    value: true,
                  };

                  return acc;
                },
                {}
              )
            );
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [application?.issuer_id]);

  useEffect(() => {
    if (issuerConsents) {
      if (Object.values(issuerConsents).some((c) => !c.value)) {
        setConsentError(true);
      }
    }
  }, [issuerConsents]);

  if (isLoading) {
    return (
      <>
        <MainLoader />
      </>
    );
  }

  if (!Boolean(application) || !issuerConsents) {
    return (
      <>
        <h2>Not Found! - Application</h2>
      </>
    );
  }

  return (
    <>
      {application && (
        <>
          <h2 className='FDReview_container_title'>Application Summary</h2>
          <div className='FDReview_container'>
            <div className='FDReview_issuer_detail'>
              <div className='FDReview_issuer_logo'>
                <img src={issuer.logo} alt={issuer.name} />
              </div>
              <div className='FDReview_issuer_badges'></div>
            </div>
            <div className='FDReview_holder_container'>
              <div className='FDReview_holder_details'>
                <div className='FDReview_investment_detail_label'>
                  Investment Details
                </div>
                <div className='FDReview_investment_detail_wrapper'>
                  <div className='FDReview_investment_amount'>
                    <div className='font-medium text-gray-500'>
                      Investment Amount
                    </div>
                    <div className='font-bold'>
                      <Amount
                        className='FDReview_investment_amount_value'
                        value={application.amount}
                        options={{
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }}
                      />
                    </div>
                  </div>
                  <div className='flex items-center justify-between'>
                    <div className='font-medium text-gray-500'>Tenure</div>
                    <div className='font-bold'>{application.tenure} Months</div>
                  </div>
                  <div className='flex items-center justify-between'>
                    <div className='font-medium text-gray-500'>
                      Interest Rate
                    </div>
                    <div className='font-bold'>
                      {application.interest_rate}%&nbsp;p.a.
                    </div>
                  </div>
                  {application.interest_payout_frequency && (
                    <>
                      {' '}
                      <div className='flex items-center justify-between'>
                        <div className='font-medium text-gray-500'>
                          Pay out Frequency
                        </div>
                        <div className='font-bold'>
                          {capitalize(payount_frequency_type)}
                        </div>
                      </div>
                      <div className='flex items-center justify-between'>
                        <div className='font-medium text-gray-500'>Type</div>
                        <div className='font-bold'>
                          {capitalize(application.interest_payout_frequency)}
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {/* {children} */}
              </div>
              <div className='xl-block FDReview_web_screen'>
                <RightColumn
                  application={application}
                  issuerConsents={issuerConsents}
                  setIssuerConsents={setIssuerConsents}
                  consentError={consentError}
                  setConsentError={setConsentError}
                  issuer={issuer}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

interface RightColumnProps {
  application: ApplicationOut;
  issuerConsents: {
    [key: number]: Consent;
  };
  setIssuerConsents: React.Dispatch<
    React.SetStateAction<{
      [key: number]: Consent;
    }>
  >;
  consentError: boolean;
  setConsentError: React.Dispatch<React.SetStateAction<boolean>>;
  issuer: IssuerOut;
}

const SupportedPaymentModes = [
  { label: 'Netbanking', value: CreatePaymentJsonBodyPaymentMethod.Netbanking },
  { label: 'UPI', value: CreatePaymentJsonBodyPaymentMethod.Upi },
  {
    label: 'NEFT/RTGS',
    value: CreatePaymentJsonBodyPaymentMethod.NEFT_RTGS,
    show_in_assisted_mode: true,
  },
];

const RightColumn = ({
  application,
  consentError,
  setConsentError,
  setIssuerConsents,
  issuerConsents,
  issuer,
}: RightColumnProps) => {
  const { fdAppURL, user_id, permissions } = useAppSelector(
    (state) => state.authReducer
  );

  // Default value
  const defaultPaymentMethod = useMemo(() => {
    if (application?.payments?.length) {
      const len = application.payments?.length;
      const previous_payment_method =
        application.payments[len - 1]?.payment_method;
      return previous_payment_method;
    }

    return '' as CreatePaymentJsonBodyPaymentMethod;
  }, []);

  // Component Level States
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<CreatePaymentJsonBodyPaymentMethod>(defaultPaymentMethod);
  const [utrNumber, setUtrNumber] = useState('');
  const [showPaymentInstructionsModal, setShowPaymetInstructionsModal] =
    useState(false);
  const NEFT_RTGS_instructions = issuer.neft_rtgs_instructions;
  const canAccessAssistedMode =
    hasAdminAccess(permissions) || hasAssistModePermission(permissions);
  const isAssistedModeActivated =
    canAccessAssistedMode && Number(user_id) !== application.user_id;
  const [isPaymentCompleted, SetIsPaymentCompleted] = useState<{
    mode: string | null;
    status: boolean | null;
  }>({
    mode: null,
    status: null,
  });

  // APIs
  const payNowHandler = async () => {
    if (!application) {
      Toast.error('Something went Wrong!, Please try again after sometime.');
      return;
    }
    //
    if (
      selectedPaymentMethod &&
      selectedPaymentMethod === CreatePaymentJsonBodyPaymentMethod.NEFT_RTGS
    ) {
      fdApi.payment
        .postPayment({
          application_id: application.id,
          callback_url: constructFdAppURL(`${fdAppURL}/payment-result`),
          payment_method: CreatePaymentJsonBodyPaymentMethod.NEFT_RTGS,
          utr_number: utrNumber,
        })
        .then(() => {
          SetIsPaymentCompleted({
            mode: CreatePaymentJsonBodyPaymentMethod.NEFT_RTGS,
            status: true,
          });
          Toast.success('FD has been booked successfully.');
        })
        .catch((err) => {
          SetIsPaymentCompleted({
            mode: CreatePaymentJsonBodyPaymentMethod.NEFT_RTGS,
            status: false,
          });
          if (err?.response?.status >= 400 && err?.response?.status <= 499)
            Toast.error(err?.response?.data?.detail?.message);
        });
    }

    if (
      selectedPaymentMethod !== CreatePaymentJsonBodyPaymentMethod.NEFT_RTGS
    ) {
      fdApi.payment
        .postPayment({
          payment_method: selectedPaymentMethod,
          callback_url: constructFdAppURL(`${fdAppURL}/payment-result`),
          application_id: application.id,
        })
        .then((redirect_url) => {
          window.location.replace(redirect_url!);
        })
        .catch((err) => {
          if (err?.response?.status >= 400 && err?.response?.status <= 499)
            Toast.error(err?.response?.data?.detail?.message);
        });
    }
  };

  // Side effects

  useEffect(() => {
    if (isPaymentCompleted.mode && application) {
      fdApi.application
        .getApplication(application.id)
        .then((res: ApplicationOut) => {
          const length =
            res?.payments?.length > 0 ? res?.payments?.length - 1 : 0;
          const url = constructFdAppURL(`${fdAppURL}/payment-result`, {
            application_id: String(res?.id),
            payment_id: res?.payments[length]?.tarrakki_id,
            payment_status: isPaymentCompleted.status
              ? PaymentStatusEnum.Success
              : PaymentStatusEnum.Failed,
          });
          window.location.href = url;
        });
    }
  }, [isPaymentCompleted]);

  return (
    <>
      <div className='RightColumn_wrapper'>
        <div className='FDReview_investment_detail_label'>Checkout</div>
        <div className='RightColumn_container'>
          {application?.tarrakki_id && (
            <div className='FDReview_investment_amount'>
              <div className='font-bold FDReview_investment_label'>
                Application ID:
              </div>
              <div>{application.tarrakki_id as string}</div>
              <MdOutlineContentCopy
                size={20}
                onClick={() => {
                  navigator.clipboard.writeText(
                    application.tarrakki_id as string
                  );
                  Toast.success('Link copied to clipboard', '');
                }}
                style={{ cursor: 'pointer' }}
              />
            </div>
          )}
          <div className='FDReview_investment_amount'>
            <div className='font-bold'>Total Payable Amount</div>
            <div style={{ minWidth: 'max-content' }}>
              <Amount
                className='FDReview_investment_amount_value'
                value={application.amount}
                options={{
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }}
              />
            </div>
          </div>

          <div className='RightColumn_payment_methods_container'>
            <span className='RightColumn_payment_methods_label'>
              Select Payment Method:
            </span>
            <div className='RightColumn_payment_methods_radio_buttons'>
              {SupportedPaymentModes.map((mode) => {
                if (isAssistedModeActivated && !mode?.show_in_assisted_mode)
                  return null;
                return (
                  <>
                    <div
                      key={mode.value}
                      onClick={() => setSelectedPaymentMethod(mode.value)}
                    >
                      <input
                        type='radio'
                        id={mode.value}
                        value={mode.value}
                        defaultChecked={selectedPaymentMethod === mode.value}
                        name='payment_mode'
                      />
                      <label htmlFor={mode.value}>{mode.label}</label>
                    </div>
                  </>
                );
              })}
            </div>
          </div>

          {selectedPaymentMethod ===
            CreatePaymentJsonBodyPaymentMethod.NEFT_RTGS && (
            <>
              <div>
                <input
                  type='text'
                  value={utrNumber}
                  placeholder='Enter Payment Reference Number'
                  className='FDReview_payment_reference_input'
                  onChange={(e) => setUtrNumber(e.target.value)}
                />
              </div>
              <div className='FDReview_payment_instructions_text'>
                <span>
                  <span
                    className='cursor-pointer text-primary text-decoration-underline'
                    onClick={() => setShowPaymetInstructionsModal(true)}
                  >
                    Please follow the instructions
                  </span>
                  &nbsp;listed in this link to initiate an NEFT/RTGS transfer
                  and generate the UTR number. Then enter the UTR number below
                  to proceed.
                </span>
              </div>
            </>
          )}

          {Object.values(issuerConsents).map((c) => {
            return (
              <div className='FDReview_consents_container'>
                <Checkbox
                  id={`checkbox-option-${c.id}`}
                  onChange={(e) => {
                    setConsentError(false);
                    setIssuerConsents((prev) => ({
                      ...prev,
                      [c.id]: {
                        ...prev[c.id],
                        value: e.target.checked,
                      },
                    }));
                  }}
                  checked={issuerConsents[c.id].value}
                />
                <div>
                  <span className={`FDReview_consents_text`}>
                    {c.text}
                    <a href={c.link} className={`text-blue-500 underline`}>
                      {c.link_label}
                    </a>
                  </span>
                </div>
              </div>
            );
          })}

          <div className='mt-0 flex w-[100%]'>
            <button
              className='RightColumn_primary_button'
              onClick={payNowHandler}
              disabled={consentError ?? !Boolean(selectedPaymentMethod)}
            >
              <div>Pay now</div>
            </button>
          </div>
        </div>
      </div>
      <ShowInstructionsModal
        neft_rtgs_instructions={NEFT_RTGS_instructions}
        showModal={showPaymentInstructionsModal}
        setShowModal={setShowPaymetInstructionsModal}
      />
    </>
  );
};

interface InstructionsModalProps {
  neft_rtgs_instructions: string;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}

const ShowInstructionsModal = ({
  neft_rtgs_instructions,
  showModal,
  setShowModal,
}: InstructionsModalProps) => {
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Body>
        <div
          id='NEFT_RTGS_instructions'
          dangerouslySetInnerHTML={{ __html: neft_rtgs_instructions ?? '' }}
        />
      </Modal.Body>
    </Modal>
  );
}; 