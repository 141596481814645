import React, { useEffect, useState } from 'react';
import './MySips.scss';
import { getAllSips } from '../../api/sips';
import { decryptData } from '../../utility/DecryptHelper';
import { data } from 'browserslist';
import { formatDate } from '../../utility/utility';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/core.css';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { getBankUserMandate, stopSip } from '../../utility/sip';

const MySipsMobile = () => {
  const userId = useSelector((state) => state.authReducer.user_id);
  const [sipData, setsipData] = useState([]);
  const [callsipData, setCallSipData] = useState(false);
  const [apiData, setApiData] = useState({offset:0,limit:10});
  const [isStopSip,setIsStopApi] = useState(false)
  const [mandateData, setMandateData] = useState([]);
  
  const handleResponse = (data) => {
    setMandateData(data);
  };
  const setStopSip = (data) => {
    if(data){
      setTimeout(() => {
        setIsStopApi((prev) => !prev);
      }, 1000);
    }
  };
  const stopSipHandler = async (item) => {
    await stopSip(item, userId, mandateData,setStopSip)
  }
  
  useEffect(() => {
    getAllSips(userId,
      apiData.limit,
      apiData.offset,
      ).then((res) => {
      if (res && res.data) {
        let data = decryptData(res.data.data);
        if (data.offset) {
          setsipData([...sipData, ...data.data]);
        } else {
          setsipData(data.data);
        }
        setApiData({ total: data.total_count,...apiData });

      }
    });
    if (userId) {
       getBankUserMandate(userId, handleResponse);
    }
  }, [callsipData,userId]);
  useEffect(() => {
    getAllSips(userId,
      apiData.limit,
      apiData.offset,
      ).then((res) => {
      if (res && res.data) {
        let data = decryptData(res.data.data);      
        setsipData(data.data);        
        setApiData({ total: data.total_count,...apiData });
      }
    });
  }, [isStopSip]);


  const handlePagination = (e) => {
    e.preventDefault()
    setApiData({
      ...apiData,
      offset:apiData.offset+10
    })
    setTimeout(() => {      
      setCallSipData((prev) => !prev);
    }, 1000);
  };
  
  return (
    <>
      <h5 className="font-weight-bold text-center">My SIPs</h5>
      {sipData &&
        sipData.length > 0 &&
        sipData.map((item, index) => {
          return (
            <>
              <div className="m-3 row colored-box" key={data.id}>
                <div className="mobile-sip-card-wrapper col-sm-10  pt-2">
                  {item.trans_code === 'NEW' && (
                    <div className="display-menu">
                      <Menu
                        menuButton={
                          <MenuButton className="menu-display-icons">
                            <BsThreeDotsVertical />
                          </MenuButton>
                        }
                        transition>
                        <p className="display-menu-title-text">Do you want to stop the sip</p>
                        <MenuItem
                          className="display-menu-item"
                          onClick={() => {
                            stopSipHandler(item);
                          }}>
                          Yes
                        </MenuItem>
                        <MenuItem className="display-menu-item">No</MenuItem>
                      </Menu>
                    </div>
                  )}
                  <p>Start date:{formatDate(new Date(item.sip_start_date))}</p>
                  <p className="fund-title pt-2  c-blue">Fund: {item.fund_name}</p>
                  <p className="folio-number">Folio Number:{item.folio_number}</p>

                  {item.mandate_type === 'N' ? <p className="mandate">Mandate: E-NACH - {item.bank}</p> : ''}
                  {item.mandate_type === 'X' ? <p className="mandate">Mandate: NACH - {item.bank}</p> : ''}

                  <p className={`${item.trans_code === 'NEW' ? ' status-active' : ' status-inactive'}`}>
                    Status: {item.trans_code === 'NEW' ? 'ACTIVE' : 'STOPPED'}
                  </p>
                  <p className="invested-amount">Amount (in ₹): {item.amount.toLocaleString('en-US')} </p>
                </div>
              </div>
            </>
          );
        })}
         {apiData.total > apiData.offset + apiData.limit ? (
              <div className="pagination-container p-4 text-center">
                <Button type="button" variant="primary" id="loadMore" onClick={(e)=>handlePagination(e)}>
                  Load More
                </Button>
              </div>
              ):null}
      {sipData.length === 0 && (
        <div style={{ fontWeight: 'bold' }} className=" text-center c-blue">
          No Data Available
        </div>
      )}
    </>
  );
};

export default MySipsMobile;
