import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
const AlertModal = ({ show, onClose, message, header }) => {
  return (
    <Modal show={show} size="md" aria-labelledby="confirmation-modal" centered backdrop="static" keyboard={false}>
      {header && (
        <Modal.Header className="justify-content-center">
          <span className="font-weight-medium h5">{header}</span>
        </Modal.Header>
      )}
      <Modal.Body className="text-center">
        <span>{message || 'Are you Sure?'}</span>
      </Modal.Body>
      <Modal.Footer className="btn-container mb-3 justify-content-center py-0">
        <Button variant="primary" onClick={onClose}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
AlertModal.propTypes = {
  message: PropTypes.string,
  header: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
};
export default AlertModal;
