import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { fdApi } from '../../../api/fdApi';
import { UserGroupsInterface } from '../../../api/fdApi/services/user_groups.service';
import { MainLoader } from '../../shared/component/MainLoader';
import BrowserSearch from '../Transactions/icons/BrowserSearch.svg';

import { Link } from 'react-router-dom';
import { useDebounce } from '../../../hooks/useDebounce';
import { useAppSelector } from '../../../redux/hooks';
import { hasAssistModePermission } from '../../../utility/utility';
import './UserGroups.scss';

export const UserGroups = () => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [searchbarValue, setSearchBarValue] = useState<string>('');
  const [listOfGroups, setListOfGroups] = useState<UserGroupsInterface[]>(
    [] as UserGroupsInterface[]
  );
  const [isLoadingGroups, setIsLoadingGroups] = useState(false);
  const [selectedUserGroup, setSelectedUserGroup] = useState<any>(null);

  const { permissions } = useAppSelector((state: any) => state.authReducer);
  const canAccessUserGroups = hasAssistModePermission(permissions);

  const debouncedSearchText = useDebounce(searchbarValue);

  const createGroupHandler = () => {
    setSelectedUserGroup(null);
    setIsShowModal(true);
  };

  // API call to fetch groups
  const handleFetchUserGroups = (search_text: string) => {
    setIsLoadingGroups(true);
    fdApi.UserGroups.getUserGroups(search_text)
      .then((res) => {
        setListOfGroups(res);
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoadingGroups(false));
  };

  useEffect(() => {
    handleFetchUserGroups(debouncedSearchText);
  }, [debouncedSearchText]);

  // Methods
  const handleViewAction = (group: UserGroupsInterface) => {
    setSelectedUserGroup(group);
    setIsShowModal(true);
  };

  if (!canAccessUserGroups) {
    return (
      <>
        <div>
          <h1>Forbidden</h1>
        </div>
      </>
    );
  }

  return (
    <>
      <div className='Usergroups_header'>
        <span className='Usergroups_header_title'>User Groups</span>
        <div className='Usergroups_search_container'>
          <img src={BrowserSearch} alt='searchicon' />
          <input
            placeholder='Search'
            value={searchbarValue as string}
            onChange={(event) => setSearchBarValue(event.target.value)}
          />
        </div>
      </div>
      <div className='Usergroups_horizontal_divider'></div>
      <div className='Usergroups_button_container'>
        <button
          className='btn btn-primary Usergroups_button_create'
          onClick={createGroupHandler}
        >
          Create Group
        </button>
      </div>
      <div className='Usergroups_table_container'>
        <div className='Usergroups_table'>
          <div className='Usergroups_table_header'>
            <div key='Usergroups_name'>Name</div>
            <div key='Usergroups_actions'>Actions</div>
          </div>
          {!isLoadingGroups ? (
            <div className='Usergroups_table_rows'>
              {listOfGroups?.length > 0 ? (
                (listOfGroups ?? []).map((group) => {
                  return (
                    <>
                      <div className='Usergroups_table_row' key={group.id}>
                        <div>{group.name}</div>
                        <div>
                          <button
                            className='Usergroups_table_edit_button'
                            onClick={() => handleViewAction(group)}
                          >
                            &nbsp;Edit
                          </button>
                          <span className='Usergroups_vertical_divider'></span>
                          <Link
                            to={`/users?group-name=${group.name}`}
                            className='ml-2'
                          >
                            View Users
                          </Link>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <>
                  <span className='Investors_table_no_data'>
                    No Groups Found!
                  </span>
                </>
              )}
            </div>
          ) : (
            <MainLoader />
          )}
        </div>
      </div>
      {isShowModal && (
        <UserGroupDetailModal
          isShowModal={isShowModal}
          setIsShowModal={setIsShowModal}
          record={selectedUserGroup}
          onSubmit={() => handleFetchUserGroups('')}
        />
      )}
    </>
  );
};

interface UserGroupDetailModalProps {
  record: any;
  isShowModal: boolean;
  setIsShowModal: (value: boolean) => void;
  onSubmit: () => void;
}

const UserGroupDetailModal = ({
  record,
  isShowModal,
  onSubmit,
  setIsShowModal,
}: UserGroupDetailModalProps) => {
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [groupName, setGroupName] = useState<string>(
    record?.name ? record.name : ''
  );

  const inputValidation = (value: any) => {
    return value.length <= 50; // Max length of 20 characters
  };

  const submitButtonHandler = () => {
    setErrors({});
    if (!groupName) {
      return setErrors({ groupName: 'required' });
    }
    setIsLoading(true);
    if (record) {
      fdApi.UserGroups.patchUserGroup(record.id, { name: groupName })
        .then(() => {})
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setIsLoading(false);
          setIsShowModal(false);
          onSubmit();
        });
      return;
    }
    
    fdApi.UserGroups.createUserGroups({ name: groupName })
      .then(() => {})
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
        setIsShowModal(false);
        onSubmit();
      });
  };

  return (
    <>
      <Modal
        show={isShowModal}
        size='sm'
        aria-labelledby='confirmation-modal'
        centered
        backdrop='static'
        keyboard={false}
        onHide={() => setIsShowModal(false)}
      >
        <Modal.Header className='justify-content-center' closeButton>
          <span className='font-weight-medium h5'>User Group</span>
        </Modal.Header>
        <Modal.Body className=' '>
          <div className='d-flex flex-wrap w-100'>
            <div className='mb-2'>
              <label htmlFor='Group Name' className='opacity-50 mb-1 small'>
                Name
              </label>
              <input
                type='text'
                name='Group Name'
                className='w-100 input-group-sm rounded border px-2 py-1'
                value={groupName as string}
                onChange={(e) => {
                  setErrors({});
                  if (!inputValidation(e?.target?.value)) {
                    setErrors({
                      groupName: 'Group name should be less than 50 characters',
                    });
                  }
                  setGroupName(e?.target?.value);
                }}
              />
              {errors && errors?.groupName && (
                <p className='text-sm color-red'>{errors.groupName}</p>
              )}
            </div>
          </div>
          <div className='d-flex flex-row align-items-center column-gap-2 mt-3'>
            <Button
              variant='primary'
              size='sm'
              onClick={submitButtonHandler}
              className='align-self-stretch'
              disabled={isLoading ?? !groupName ?? Boolean(errors?.groupName)}
            >
              Save
            </Button>
            <Button
              variant='outline-primary'
              size='sm'
              onClick={() => setIsShowModal(false)}
              className='mx-1 align-self-stretch'
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
