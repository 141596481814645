import { getInvestorStatus } from '../api/kyc';
import { updateinvestorStatus, updateNomineeStatus } from '../redux/reducers/auth-reducer';

export const getInvestorsStatus = (userId, dispatch) => {
  if (userId !== undefined) {
    getInvestorStatus(userId).then((res) => {
      if (res && res.data) {
        if (res?.data?.status === 'ready_to_invest') {
          dispatch(updateinvestorStatus(true));
        } else {
          dispatch(updateinvestorStatus(false));
        }
        if (res?.data?.status === 'pending_nominee_authentication') {
          dispatch(updateNomineeStatus(true));
        } else {
          dispatch(updateNomineeStatus(false));
        }
      } else {
        dispatch(updateinvestorStatus(false));
        dispatch(updateNomineeStatus(false));
      }
    });
  }
};
