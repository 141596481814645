import axios from './axios';

const getTransactionSuccess = (userId, orders) => {
  return axios.get(`transactions/success/?user_id=${userId}&success_transaction_ids=${JSON.stringify(orders)}`);
};

const exportTransiction = (id, type, postData) => {
  let urlParams = [];
  if (postData.limit) {
    urlParams.push(`limit=${postData.limit}`);
  }
  if (postData.offset) {
    urlParams.push(`offset=${postData.offset}`);
  }
  if (postData.transaction_type) {
    urlParams.push(`transaction_type=${postData.transaction_type}`);
  }
  if (postData.search_by_name) {
    urlParams.push(`search_by_name=${postData.search_by_name}`);
  }
  if (postData.start_date || postData.end_date) {
    urlParams.push(`start=${postData.start_date}&end=${postData.end_date}`);
  }
  return axios.get(`tcs/${type}/${id}/?${urlParams.join('&')}`, {
    responseType: 'arraybuffer',
  });
};
export { getTransactionSuccess, exportTransiction };
