import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  BankListType,
  BankListTypeCurrent,
  BankListTypeIndividual,
  BankListTypeNre,
  BankListTypeNri,
  BankListTypeNro,
  formatInput,
} from '../../utility/utility';
import supportedIcon from '../../assets/supported.svg';
import notSupportedIcon from '../../assets/not-supported.svg';
import DropdownList from '../../common/form/dropdown-list/DropdownList';
import UploadBox from '../../common/form/upload-box/UploadBox';
import { getBankDetail, saveBankDetails, updateBankDetails } from '../../api/bank';
import { decryptData } from '../../utility/DecryptHelper';
import { loadBankList, updateKycInfo } from '../../redux/reducers/onboarding-reducer';
import { useHistory, useLocation } from 'react-router-dom';
import MobileNavigation from '../../common/footermobile/MobileNavigation';
import { getUserDetails } from '../../api/profile';

const BankAccountUpdateMobile = React.forwardRef(({ alignment = 'center', onBankAdd, bankDetail }, ref) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const user_id = useSelector((state) => state.authReducer.user_id);
  const bankList = useSelector((state) => state.onboardingReducer.bankList);
  const { pan_kyc_status } = useSelector((state) => state.onboardingReducer);
  const [bankNameObj, setBankName] = useState({});
  const [bankDetailId, setBankDetailId] = useState();
  const [bankTypeObj, setBankType] = useState({});
  const [bankDetailData, setBankDeailsData] = useState({});
  const [accountNo, setAccountNo] = useState('');
  const [confirmAccountNo, setConfirmAccountNo] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [file, setFile] = useState(null);
  const [verificationDoc, setDocName] = useState('');
  const [errData, setErrData] = useState({});
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [bankListOption, setBankListOption] = useState([]);
  const individual = '01';
  const minor = '02';
  const huf = '03';
  const nrinrenro = ['11', '21', '24'];
  const data = location.state;
  useEffect(() => {
    updatePanData();

    if (bankList && !bankList.length) {
      getBankDetail().then((res) => {
        if (res && res.data) {
          const bankListData = decryptData(res.data.data);
          dispatch(loadBankList(bankListData.data));
        }
      });
    }
  }, []);
  useEffect(() => {
    if (pan_kyc_status) {
      if (pan_kyc_status === individual) {
        setBankListOption(BankListTypeIndividual);
      } else if (pan_kyc_status === minor) {
        setBankListOption(BankListTypeIndividual);
      } else if (pan_kyc_status === huf) {
        setBankListOption(BankListType);
      } else if (nrinrenro.includes(pan_kyc_status)) {
        setBankListOption(getNriAccountTypeList());
      } else {
        setBankListOption(BankListTypeCurrent);
      }
    }
  }, [pan_kyc_status]);

  const updatePanData = () => {
    getUserDetails().then((res) => {
      const profileObj = decryptData(res.data.data);
      if (profileObj.data && profileObj.data.pan) {
        if (profileObj.data?.tax_status) {
          dispatch(updateKycInfo({ pan: profileObj.data.pan, pan_kyc_status: profileObj.data.tax_status.id }));
        }
      }
    });
  };

  useEffect(() => {
    if (bankDetail && bankDetail.account_number) {
      const bankType = BankListType?.find((item) => item.value === bankDetail.account_type_bse)?.label;
      const bankObj = bankList?.find((item) => item.bank_name === bankDetail.branch__bank_id__bank_name) || {};
      setAccountNo(bankDetail.account_number);
      setConfirmAccountNo(bankDetail.account_number);
      setIfscCode(bankDetail.branch__ifsc_code);
      setBankName({ label: bankDetail.branch__bank_id__bank_name, id: bankObj ? bankObj.id : '', payment_mode: bankObj.payment_mode });
      setBankType({ label: bankType, value: bankDetail.account_type_bse });
      setDocName(bankDetail.verification_document);
      setBankDetailId(bankDetail.id);
      setBankDeailsData(bankDetail);
    }
  }, [bankDetail, bankList]);

  useEffect(() => {
    if (bankList && data && data.bankDetail && data.bankDetail.account_number) {
      let { bankDetail } = data;
      const bankType = BankListType.find((item) => item.value === bankDetail.account_type_bse).label;
      const bankObj = bankList.find((item) => item.bank_name === bankDetail.branch__bank_id__bank_name) || {};
      setAccountNo(bankDetail.account_number);
      setConfirmAccountNo(bankDetail.account_number);
      setIfscCode(bankDetail.branch__ifsc_code);
      setBankName({ label: bankDetail.branch__bank_id__bank_name, id: bankObj ? bankObj.id : '', payment_mode: bankObj.payment_mode });
      setBankType({ label: bankType, value: bankDetail.account_type_bse });
      setDocName(bankDetail.verification_document);
      setFile(bankDetail.verification_document);
      setBankDetailId(bankDetail.id);
      setBankDeailsData(bankDetail);
    }
  }, [data, bankList]);

  const onBankTypeChange = (event, obj) => {
    event.preventDefault();
    setErrData({ type: '', err: '' });
    if (!obj) {
      setErrData({ err: 'Bank type should not be empty.', type: 'bankType' });
    }
    setBankType(obj);
    setIsDataUpdated(true);
  };
  const accountNumberChange = (event) => {
    const { value } = event.target,
      pattern = /^([a-zA-Z0-9]{0,20})?$/;
    setErrData({ type: '', err: '' });
    if (value && !value.trim()) {
      setErrData({ err: 'Bank account should not be empty.', type: 'account' });
    } else if (value && !value.match(pattern)) {
      return false;
    }
    setAccountNo(value);
    setIsDataUpdated(true);
  };
  const onConfirmAccountNo = (event) => {
    const { value } = event.target,
      pattern = /^([a-zA-Z0-9]{0,20})?$/;
    setErrData({ type: '', err: '' });
    if (value && !value.trim()) {
      setErrData({ err: 'Confirm bank account should not be empty.', type: 'confirmAccount' });
    } else if (value && !value.match(pattern)) {
      setErrData({ err: 'Account number and confirm account number do not match.', type: 'confirmAccount' });
      return false;
    } else if (value !== accountNo) {
      setErrData({ err: 'Account number and confirm account number do not match.', type: 'confirmAccount' });
    }
    setConfirmAccountNo(value);
    setIsDataUpdated(true);
  };
  const IfscCodeChange = (event) => {
    const panCardRegex = /[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/;
    const { value } = event.target;
    setErrData({ type: '', err: '' });
    if (value && !value.trim()) {
      setErrData({ err: 'Ifsc code cannot be empty.', type: 'ifscCode' });
    } else if (!panCardRegex.test(value)) {
      setErrData({ err: 'Invalid IFSC Code.', type: 'ifscCode' });
    }
    setIfscCode(value.toUpperCase());
    setIsDataUpdated(true);
  };
  const formValidator = () => {
    let flag = false;
    if (bankTypeObj && !bankTypeObj.value) {
      setErrData({ err: 'Bank type should not be empty.', type: 'bankType' });
    } else if (!accountNo) {
      setErrData({ err: 'Bank account should not be empty.', type: 'account' });
    } else if (!confirmAccountNo) {
      setErrData({ err: 'Confirm bank account should not be empty.', type: 'confirmAccount' });
    } else if (accountNo !== confirmAccountNo) {
      setErrData({ err: 'Account number and confirm account number do not match.', type: 'confirmAccount' });
    } else if (!ifscCode) {
      setErrData({ err: 'Invalid IFSC Code.', type: 'ifscCode' });
    } else if (errData.err) {
      flag = false;
    } else {
      flag = true;
    }
    return flag;
  };
  const addNewBank = () => {
    if (formValidator() && user_id) {
      const formData = new FormData();
      formData.append('account_number', accountNo);
      formData.append('ifsc_code', ifscCode);
      formData.append('account_type', bankTypeObj?.value);
      formData.append('bank_id', bankNameObj?.id);
      formData.append('user_id', user_id.toString());
      formData.append('verification_document', file);
      saveBankDetails(user_id, formData).then((res) => {
        if (res && res.data && res.data.status.code === 1) {
          history.push('/mobileprofile/bank-list');
        }
      });
    }
  };

  const updateNewBank = () => {
    if (formValidator() && isDataUpdated && bankDetailId) {
      const formData = new FormData();
      formData.append('account_number', accountNo);
      formData.append('ifsc_code', ifscCode);
      formData.append('account_type', bankTypeObj?.value);
      formData.append('bank_id', bankNameObj?.id);
      formData.append('user_id', user_id.toString());
      formData.append('verification_document', file);
      updateBankDetails(bankDetailId, formData).then((res) => {
        if (res && res.data && res.data.status.code === 1) {
          if (onBankAdd) onBankAdd(res.data.status.message);
          history.push('/mobileprofile/bank-list');
        }
      });
    } else {
      history.push('/mobileprofile/bank-list');
    }
  };
  useImperativeHandle(ref, () => ({ addNewBank, updateNewBank }));
  const onFileSelected = (file) => {
    setFile(file);
    setIsDataUpdated(true);
  };
  const addBankDetails = () => {
    addNewBank();
  };

  const getNriAccountTypeList = () => {
    let taxStatus = pan_kyc_status;
    return taxStatus ? (taxStatus === '21' ? BankListTypeNre : taxStatus === '24' ? BankListTypeNro : BankListTypeNri) : BankListTypeNri;
  };

  return (
    <div className="bank-account-container bg-light-grey p-4">
      <h4 className="font-weight-bold text-center">Add Bank Account</h4>
      <div className="colored-box p-4">
        <div className="row bank-account">
          <Form.Group className="col-sm-6 my-3">
            <Form.Label className=" opacity-50 mb-1">Account Type</Form.Label>
            <DropdownList
              selectedOption={bankTypeObj.label}
              id="account-type"
              classes={{ input: 'font-weight-medium updated-input form-control pl-0' }}
              placeholder=""
              controlFunc={onBankTypeChange}
              options={bankListOption}
            />
            <Form.Control.Feedback type="invalid" className={errData.type === 'bankType' ? 'd-flex' : ''}>
              {errData.err}
            </Form.Control.Feedback>
          </Form.Group>
          {bankNameObj.payment_mode === 'NODAL' && (
            <div className="c-red my-3 col-sm-12">
              The selected bank can take upto 2 working days to clear the funds when using Netbanking. NEFT/RTGS are preferred payment modes for this
              bank
            </div>
          )}
          <Form.Group className="col-sm-6 my-3">
            <Form.Label className=" opacity-50 mb-1">Account Number</Form.Label>
            <Form.Control
              type="text"
              id="accountNumber"
              placeholder=""
              onChange={accountNumberChange}
              onKeyDown={(e) => {
                formatInput(e);
              }}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
              value={accountNo}
              className={'updated-input font-weight-medium text-disc pl-0 ' + (errData.type === 'account' ? 'is-invalid' : '')}
            />
            <Form.Control.Feedback type="invalid">{errData.err}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="col-sm-6  my-3">
            <Form.Label className=" opacity-50 mb-1">Confirm Account Number</Form.Label>
            <Form.Control
              type="text"
              id="confirmAccountNo"
              placeholder=""
              onChange={onConfirmAccountNo}
              onKeyDown={(e) => {
                formatInput(e);
              }}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
              value={confirmAccountNo}
              className={'updated-input font-weight-medium pl-0 ' + (errData.type === 'confirmAccount' ? 'is-invalid' : '')}
            />
            <Form.Control.Feedback type="invalid">{errData.err}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="col-sm-6 my-3">
            <Form.Label className=" opacity-50 mb-1">IFSC Code</Form.Label>
            <Form.Control
              type="text"
              id="ifscCode"
              placeholder=""
              onChange={IfscCodeChange}
              value={ifscCode}
              className={'updated-input font-weight-medium pl-0 ' + (errData.type === 'ifscCode' ? 'is-invalid' : '')}
            />
            <Form.Control.Feedback type="invalid">{errData.err}</Form.Control.Feedback>
          </Form.Group>
          {(pan_kyc_status === '01' && bankDetailData?.status !== 'REJECTED') ||
          (!bankDetailData && !['02', '11', '21', '24'].includes(pan_kyc_status)) ? null : (
            <div className="col-sm-6 my-3">
              <UploadBox
                id="upload-canceled"
                title="Upload Cancelled Cheque or Bank Statement"
                onChange={onFileSelected}
                accept={['pdf', 'jpg', 'jpeg', 'png']}
                value={verificationDoc}
              />
            </div>
          )}
        </div>
        {bankNameObj && bankNameObj?.id && (
          <div className="pt-5">
            <div className="bank-status d-flex align-items-center py-2">
              <img src={!bankNameObj.payment_mode ? notSupportedIcon : supportedIcon} alt="forward icon" />
              <span className="ml-1 small">Netbanking Supported</span>
            </div>
            <div className="bank-status d-flex align-items-center py-2">
              <img src={!bankNameObj.payment_mode ? notSupportedIcon : supportedIcon} alt="forward icon" />
              <span className="ml-1 small">UPI Supported</span>
            </div>
            <div className="bank-status d-flex align-items-center py-2">
              <img src={supportedIcon} alt="forward icon" />
              <span className="ml-1 small">NEFT/RTGS Supported</span>
            </div>
          </div>
        )}
        <div className={'bank-actions text-' + alignment}>
          <button type="button" className="btn btn-primary mt-3 w-100" onClick={bankDetailId ? updateNewBank : addBankDetails}>
            submit
          </button>
        </div>
      </div>
      <MobileNavigation />
    </div>
  );
});
BankAccountUpdateMobile.propTypes = {
  buttonText: PropTypes.string,
  alignment: PropTypes.string,
  onBankAdd: PropTypes.func,
  bankDetail: PropTypes.object,
  show: PropTypes.bool,
};
BankAccountUpdateMobile.displayName = 'BankAccountUpdateMobile';
export default BankAccountUpdateMobile;
