import React, { useEffect, useState } from 'react';
import OrderCart from '../../component/order/OrderCart';
import PageHeader from '../../common/page-header/PageHeader';
import { formatPrice } from '../../utility/utility';
import { decryptData } from '../../utility/DecryptHelper';
import {
  deleteCartItem,
  getCartItems,
  orderConfirm,
  orderMandateConfirm,
  updateCartItems,
} from '../../api/cart';
import DataMsg from '../../common/data-msg/DataMsg';
import { useDispatch, useSelector } from 'react-redux';
import SuccessModal from '../../modal/SuccessModal';
import Payment from './Payment';
import AddFundTimeModal from '../../modal/AddFundTimeModal';
import MobileNavigation from '../../common/footermobile/MobileNavigation';
import { bankUserMandate } from '../../api/bank';
import SipBankSelect from './SipBankSelect';
import Payment2 from './Payment2';
import { getInvestorsStatus } from '../../utility/investorStatus';
import Toast from '../../common/toast/Toast';
import OTPVerificationModal from '../../modal/OTPVerificationModal';
import { sendOTP } from '../../api/otp';
import { useAppSelector } from '../../redux/hooks';

const OrderSummary = ({ history }) => {
  const userId = useSelector((state) => state.authReducer.user_id);
  const user = useSelector((state) => state.authReducer);
  const pan = useSelector((state) => state.onboardingReducer.pan);
  const { supportEmail } = useAppSelector((state) => state.whitelabelReducers);
  const otpId = localStorage.getItem('otpIdOrder');
  const dispatch = useDispatch();
  const [orderCartList, setList] = useState([]);
  const [cartData, setCartData] = useState(null);
  const [totalAmount, setAmount] = useState(0);
  const [totalLumpsumAmount, setLumpsumAmount] = useState(0);
  const [totalSipAmount, setTotalSipAmount] = useState(0);
  const [orders, setOrders] = useState([]);
  const [paymnetFlag, setPaymentFlag] = useState(false);
  const [paymentFlag2] = useState(false);
  const [successfulModal, setSuccessfulModal] = useState({
    show: false,
    data: null,
  });
  const [noticePopUp, setNoticePopUp] = useState(false);
  const [investorStatus] = useState('');
  const [fundName, setFundName] = useState({});
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [mandateData, setMandateData] = useState([]);
  const [showSipSelect, setShowSipSelect] = useState(false);
  const [selectedMandate, setSelectedMandate] = useState(null);
  const [firstOrderStatus, setFirstOrderStatus] = useState(true);
  const [showOtpModal, setShowOTPModal] = useState(false);
  const [isPlaceOrder, setIsPlaceOrder] = useState(true);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    getCartList();
    getInvestorsStatus(userId, dispatch);
    getBankUserMandate();
  }, []);

  const getCartList = () => {
    getCartItems().then((res) => {
      if (res && res.data) {
        const cartData = decryptData(res.data.data);
        setCartData(cartData.data);
        setList(cartData.data.order_lines);
        setAmount(cartData.data.total_lumpsum);
        setLumpsumAmount(cartData.data.total_lumpsum);
        setTotalSipAmount(cartData.data.total_sip);
        if (cartData?.data?.order_lines?.length === 0) {
          localStorage.removeItem('firstOrderNotToday');
        }
        if (!cartData.data.mandate_id) {
          if (cartData?.data?.total_sip) {
            setShowSipSelect(true);
          } else {
            setShowSipSelect(false);
            setSelectedMandate(undefined);
          }
        }
      }
    });
  };

  useEffect(() => {
    if (cartData && mandateData) {
      const selectedMandateData = mandateData.filter(
        (item) => item.id === cartData.mandate_id
      );
      const selectedMandate1 = selectedMandateData[0];
      if (selectedMandate1) {
        setSelectedMandate(selectedMandate1);
        let status = localStorage.getItem('firstOrderNotToday');
        if (status) {
          setFirstOrderStatus(false);
        } else {
          if (selectedMandate1.status === 'APPROVED') {
            if (firstOrderStatus) {
              let total = totalAmount + totalSipAmount;
              setAmount(total);
            }
          } else if (
            selectedMandate1.status === 'PENDING' ||
            selectedMandate1.status === 'UNDER PROCESSING' ||
            selectedMandate1.status === 'INITIAL REJECTION' ||
            selectedMandate1.status === 'REGISTERED BY MEMBER' ||
            selectedMandate1.status === 'SCAN IMAGE NOT UPLOADED' ||
            selectedMandate1.status === 'WAITING FOR CLIENT AUTHENTICATION'
          ) {
            let total = totalAmount + totalSipAmount;
            setAmount(total);
          }
        }
      }
    }
  }, [cartData, mandateData]);

  const deleteFunc = (cartOrderId) => {
    deleteCartItem(cartOrderId).then(() => {
      getCartList();
    });
  };
  const updateCart = (id, selectedFolio) => {
    const postObj = { folio_number: selectedFolio };
    updateCartItems(id, postObj).then(() => {
      getCartList();
    });
  };
  const validateCheckout = () => {
    let validateStatus = false;
    let redirectTo = null;
    if (investorStatus === 'incomplete_detail') {
      if (!pan) {
        Toast.error('Please complete your on-boarding to start investing');
        redirectTo = '/';
      } else if (user.is_kyc_verified) {
        Toast.error('Please complete your on-boarding to start investing');
        redirectTo = '/boarding';
      } else {
        Toast.warn(
          `Your KYC is not verified. Reach out to ${
            supportEmail ? `us at ${supportEmail}` : 'Customer Support'
          } to complete your KYC.`
        );
      }
    } else if (investorStatus === 'under_review') {
      Toast.warn(
        'Your application is submitted and is in verification process. It will be verified in next 24 hrs'
      );
    } else {
      validateStatus = true;
    }

    setTimeout(() => {
      if (redirectTo) history.push(redirectTo);
    }, 1500);

    return validateStatus;
  };

  const showBankModal = () => {
    if (validateCheckout()) {
      handleOtpModal(true);
      setIsPlaceOrder(false);
    }
  };
  const handleOtpModal = (flag) => {
    if (flag) {
      let detailData = cartData?.order_lines?.map((item, i) =>
        item.folio_number && item.fund_id__id
          ? { fund_id: (item.fund_id__id).toString(), folio: (item.folio_number).toString() }
          : item.fund_id__id.toString()
          ? { fund_id: (item.fund_id__id).toString() }
          : undefined
      );
      detailData = detailData.filter((item) => item !== undefined);
      const otpData =
        detailData.length > 0
          ? {
              otp_type: 'cart',
              investor_id: userId.toString(),
              detail: detailData,
            }
          : {
              otp_type: 'cart',
              investor_id: userId.toString(),
            };
      sendOTP(otpData).then((res) => {
        setShowOTPModal(flag);
        if (res?.data?.otp_id) {
          localStorage.removeItem('otpIdOrder');
          localStorage.setItem('otpIdOrder', res?.data?.otp_id);
        }
      });
    } else {
      setShowOTPModal(flag);
    }
  };

  const placeOrder = () => {
    if (validateCheckout()) {
      if (orderCartList && orderCartList.length) {
        const formData = new FormData();
        formData.append('user_id', userId);
        formData.append('otp_reference', otpId);
        orderConfirm(formData).then((res) => {
          localStorage.removeItem('firstOrderNotToday');
          handleOtpModal(false);
          if (res.data.data) {
            const orderData = decryptData(res.data.data);
            if (res.data.status.code === 2) {
              setNoticePopUp(true);
              if (orderData.data) {
                setFundName(orderData);
              }
            } else if (
              res.data.status.code === 1 &&
              orderData.data.orders &&
              orderData.data.orders.length
            ) {
              if (orderData.data.total_payable_amount > 0) {
                setOrders(orderData.data.orders);
                setAmount(orderData.data.total_payable_amount);
                setPaymentFlag(true);
              } else if (orderData.data.total_payable_amount === 0) {
                setSuccessfulModal({ show: true });
              }
            }
            if (
              res.data.status.code === 1 &&
              orderData.data.failed_transactions &&
              orderData.data.failed_transactions.length
            ) {
              setSuccessfulModal({ show: true, errorIcon: true });
            }
          }
        });
      }
    }
  };

  const onpaymentCompleted = () => {
    setPaymentFlag(false);
    getCartList();
  };
  const closeSuccessFulModal = () => {
    setSuccessfulModal({ show: false });
    getCartList();
    if (successfulModal && !successfulModal.errorIcon) {
      const orderIds = orders.map((item) => item.lumpsum_transaction_id);
      history.push(`/order/complete/${btoa(JSON.stringify(orderIds))}/DIRECT'`);
    }
  };
  const onClose = () => {
    setNoticePopUp(false);
  };

  const getBankUserMandate = () => {
    bankUserMandate(userId).then((res) => {
      if (res && res.data) {
        const mandate = decryptData(res.data.data);
        const data = mandate.data;
        setMandateData(data);
      }
    });
  };

  const onMandateConfirm = () => {
    const formData = new FormData();
    // formData.append('user_id', userId);
    if (selectedMandate) {
      formData.append('mandate_id', selectedMandate.id);
      formData.append('first_order_today', firstOrderStatus);
      if (!firstOrderStatus) {
        localStorage.setItem('firstOrderNotToday', true);
      }
    }
    orderMandateConfirm(cartData.id, formData).then((response) => {
      if (
        response.data &&
        response.data.status &&
        response.data.status.code === 1
      ) {
        setShowSipSelect(false);
        getCartList();
      }
    });
    if (selectedMandate) {
      if (selectedMandate.status === 'APPROVED') {
        if (firstOrderStatus) {
          let total = totalAmount + totalSipAmount;
          setAmount(total);
        }
      } else if (
        selectedMandate.status === 'PENDING' ||
        selectedMandate.status === 'UNDER PROCESSING' ||
        selectedMandate.status === 'INITIAL REJECTION' ||
        selectedMandate.status === 'REGISTERED BY MEMBER' ||
        selectedMandate.status === 'SCAN IMAGE NOT UPLOADED' ||
        selectedMandate.status === 'WAITING FOR CLIENT AUTHENTICATION'
      ) {
        let total = totalAmount + totalSipAmount;
        setAmount(total);
      }
    }
  };

  const firstOrderStatusChange = (status) => {
    setFirstOrderStatus(status);
  };

  const handleMandateSelect = (mandate) => {
    setSelectedMandate(mandate);
  };

  const getOrderIds = () => {
    let order = orders.map((item) => item.lumpsum_transaction_id);
    let sipOrder = orders.map((item) => item.sip_transaction_id);
    return [...order, ...sipOrder].filter((item) => item);
  };
  const handlePlaceOrder = () => {
    setIsPlaceOrder(true);
    handleOtpModal(true);
  };
  const handleSubmitOTP = () => {
    if (isPlaceOrder) {
      placeOrder();
    } else {
      if (mandateData && mandateData.length > 0 && totalSipAmount) {
        showOtpModal(false);
        setShowSipSelect(true);
      } else {
        placeOrder();
      }
    }
  };

  return showSipSelect ? (
    <SipBankSelect
      mandateData={mandateData}
      confirmMandate={onMandateConfirm}
      onFirstOderTodayStatusChange={firstOrderStatusChange}
      onMandateSelect={handleMandateSelect}
    />
  ) : (
    <>
      {windowDimensions.width > 480 ? (
        <div className='container'>
          <PageHeader headerName='Order Summary' />
          <div className='order-summary row'>
            <div className='col-sm-8'>
              {orderCartList && orderCartList.length ? (
                orderCartList.map((orderItem, index) => (
                  <div
                    key={orderItem.id}
                    className='colored-box mb-3 p-4 rounded'
                  >
                    <OrderCart
                      folioList={orderItem.folio_list}
                      key={orderItem.id}
                      updateFunc={(selectedFolioNo) => {
                        updateCart(orderItem.id, selectedFolioNo);
                      }}
                      fundId={orderItem.fund_id__id}
                      folioNumber={orderItem.folio_number}
                      orderId={orderItem.id}
                      fundName={orderItem.fund_id__fscbi_legal_name}
                      amount={orderItem.lumpsum_amount}
                      sipAmount={orderItem.sip_amount}
                      fundType={orderItem.fund_id__scheme_type}
                      deleteFunc={deleteFunc}
                      selectedSipDay={orderItem.day}
                    />
                  </div>
                ))
              ) : (
                <DataMsg
                  message='No Cart Item Available'
                  button='Invest'
                  url='/invest'
                />
              )}
            </div>
            <div className='col-sm-4 p-4 h-100 order-checkout-section'>
              {totalLumpsumAmount || !totalSipAmount ? (
                <div className='order-detail d-flex justify-content-between align-items-center'>
                  <span className=''>Total one-time Lumpsum investment</span>
                  <div className='font-weight-medium d-flex'>
                    <span>&#8377;</span>
                    <span className={'ml-1'}>
                      {formatPrice(
                        totalLumpsumAmount ? totalLumpsumAmount : ''
                      )}
                    </span>
                  </div>
                </div>
              ) : (
                ''
              )}
              <br />
              {(totalSipAmount || !totalLumpsumAmount) &&
              firstOrderStatus &&
              selectedMandate ? (
                <div className='order-detail d-flex justify-content-between align-items-center'>
                  <span className=''>Total Monthly investment (SIP)</span>
                  <div className='font-weight-medium d-flex'>
                    <span>&#8377;</span>
                    <span className={'ml-1'}>
                      {formatPrice(totalSipAmount ? totalSipAmount : '')}
                    </span>
                  </div>
                </div>
              ) : totalSipAmount || !totalLumpsumAmount ? (
                <div className='order-detail d-flex justify-content-between align-items-center'>
                  <span className=''>Total Monthly investment (SIP)</span>
                  <div className='font-weight-medium d-flex'>
                    <span>&#8377;</span>
                    <span className={'ml-1'}>{0}</span>
                  </div>
                </div>
              ) : (
                ''
              )}

              <br />
              <hr></hr>
              <div className=' order-detail d-flex justify-content-between align-items-center'>
                <span className=''>Total Payable Amount</span>
                <div className='font-weight-medium d-flex'>
                  <span>&#8377;</span>
                  <span className={'ml-1'}>
                    {formatPrice(totalAmount ? totalAmount : '')}{' '}
                  </span>
                </div>
              </div>

              <div className='btn-container text-center mt-2 pt-5'>
                {orderCartList && orderCartList.length > 0 && (
                  <>
                    {mandateData &&
                    mandateData.length > 0 &&
                    !selectedMandate ? (
                      <button
                        className='btn btn-primary w-75'
                        onClick={showBankModal}
                      >
                        Checkout
                      </button>
                    ) : (
                      <button
                        className='btn btn-primary w-75'
                        onClick={() => handlePlaceOrder()}
                      >
                        Checkout
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='container bg-light-grey p-4'>
          <h4 className='font-weight-bold text-center'>Order Summary</h4>
          <div className=' '>
            <div className=' colored-box '>
              {orderCartList && orderCartList.length ? (
                orderCartList.map((orderItem) => (
                  <div key={orderItem.id} className=' mb-3  rounded'>
                    <OrderCart
                      folioList={orderItem.folio_list}
                      key={orderItem.id}
                      updateFunc={(selectedFolioNo) => {
                        updateCart(orderItem.id, selectedFolioNo);
                      }}
                      fundId={orderItem.fund_id__id}
                      folioNumber={orderItem.folio_number}
                      orderId={orderItem.id}
                      fundName={orderItem.fund_id__fscbi_legal_name}
                      amount={orderItem.lumpsum_amount}
                      fundType={orderItem.fund_id__scheme_type}
                      deleteFunc={deleteFunc}
                      sipAmount={orderItem.sip_amount}
                      selectedSipDay={orderItem.day}
                    />
                  </div>
                ))
              ) : (
                <DataMsg
                  message='No Cart Item Available'
                  button='Invest'
                  url='/invest'
                />
              )}
            </div>
            <div className='col-sm-4  h-100 '>
              {selectedMandate && firstOrderStatus ? (
                <>
                  <h3>Total Payable Amount</h3>
                  <hr />
                  {totalSipAmount && (
                    <>
                      <div className='font-weight-medium d-flex'>
                        <span className={'m-0'}>&#8377;</span>
                        <span className={'ml-1'}>
                          {formatPrice(totalAmount ? totalAmount : '')}
                        </span>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  {totalLumpsumAmount || !totalSipAmount ? (
                    <div className='order-detail d-flex justify-content-between align-items-center'>
                      <span className='ml-0'>
                        Total one-time Lumpsum investment
                      </span>
                      <div className='font-weight-medium d-flex'>
                        <span>&#8377;</span>
                        <span className={'ml-1'}>
                          {formatPrice(
                            totalLumpsumAmount ? totalLumpsumAmount : ''
                          )}
                        </span>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <br />
                  {totalSipAmount || !totalLumpsumAmount ? (
                    <div className='order-detail d-flex justify-content-between align-items-center'>
                      <span className='ml-0'>Total Monthly Investment</span>
                      <div className='font-weight-medium d-flex'>
                        <span>&#8377;</span>
                        <span className={'ml-1'}>
                          {formatPrice(totalSipAmount ? totalSipAmount : '')}
                        </span>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <hr></hr>
                  <div className='order-detail d-flex justify-content-between align-items-center'>
                    <span className='ml-0'>Total Payable Amount</span>
                    <div className='font-weight-medium d-flex'>
                      <span>&#8377;</span>
                      <span className={'ml-1'}>
                        {formatPrice(totalAmount ? totalAmount : '')}
                      </span>
                    </div>
                  </div>
                  <hr></hr>
                </>
              )}

              <div className='btn-container text-center mt-5  pt-5'>
                {orderCartList && orderCartList.length > 0 && (
                  <>
                    {mandateData &&
                    mandateData.length > 0 &&
                    !selectedMandate ? (
                      <button
                        className='btn btn-primary w-75'
                        onClick={showBankModal}
                      >
                        Checkout
                      </button>
                    ) : (
                      <button
                        className='btn btn-primary w-75'
                        onClick={() => handleOtpModal(true)}
                      >
                        Checkout
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <MobileNavigation />
        </div>
      )}

      {successfulModal.show ? (
        <SuccessModal
          show={successfulModal.show}
          onSubmit={closeSuccessFulModal}
          errorIcon={successfulModal.errorIcon}
          successText={
            successfulModal.errorIcon ? 'Payment Failed' : 'Payment Successful'
          }
          size='sm'
        />
      ) : null}
      {paymnetFlag && orders.length && (
        <Payment
          orders={getOrderIds()}
          userId={userId}
          totalAmount={totalAmount}
          onpaymentCompleted={onpaymentCompleted}
        />
      )}
      {paymentFlag2 && (
        <Payment2
          orders={getOrderIds()}
          userId={userId}
          totalAmount={totalAmount}
          onpaymentCompleted={onpaymentCompleted}
        />
      )}
      {noticePopUp ? (
        <AddFundTimeModal
          show={noticePopUp}
          onClose={onClose}
          fundName={fundName}
        />
      ) : null}
      {showOtpModal && (
        <OTPVerificationModal
          show={showOtpModal}
          onSubmit={() => handleSubmitOTP()}
          otpId={otpId}
          payment
          setShowOTPModal={() => setShowOTPModal(false)}
        />
      )}
    </>
  );
};
export default OrderSummary;
