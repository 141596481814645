import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import InfoIcon from '../../assets/info-blue.svg';
const NatureBusiness = ({ onChange, value, err, infoIcon, infoMsg }) => {
  const [natureBusiness, setData] = useState('');
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
    
  useEffect(() => {
    setData(value);
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [value]);
  const onbussinessChange = (event) => {
    const { value } = event.target;
    setData(value);
    if (onChange) {
      onChange(value);
    }
  };
  return (
    <>
    {windowDimensions.width>480?(
      <div className="border p-4 rounded w-100 my-3">
      <Form.Group>
        <Form.Label className="opacity-75 h6 mb-1 d-flex align-items-center">
          <span>Nature of business</span>
          {infoIcon && (
            <OverlayTrigger
              placement="right-end"
              overlay={
                <Popover id="popover-recomandation">
                  <Popover.Content>{infoMsg}</Popover.Content>
                </Popover>
              }>
              <img src={InfoIcon} alt="info icon" className="small-icon ml-2" />
            </OverlayTrigger>
          )}
        </Form.Label>
        <Form.Control
          type="text"
          maxLength={40}
          id="onboarding-nature-bussiness"
          onChange={onbussinessChange}
          value={natureBusiness}
          className={'col-sm-3 updated-input font-weight-medium pl-0 ' + (err ? 'is-invalid ' : '')}
        />
        <Form.Control.Feedback type="invalid">{err}</Form.Control.Feedback>
      </Form.Group>
    </div>
    ):(
     <div className="py-2 rounded w-100 my-3">
      <Form.Group>
        <Form.Label className="opacity-75 h6 mb-1 d-flex align-items-center">
          <span className='ml-0'>Nature of business</span>
          {infoIcon && (
            <OverlayTrigger
              placement="right-end"
              overlay={
                <Popover id="popover-recomandation">
                  <Popover.Content>{infoMsg}</Popover.Content>
                </Popover>
              }>
              <img src={InfoIcon} alt="info icon" className="small-icon ml-2" />
            </OverlayTrigger>
          )}
        </Form.Label>
        <Form.Control
          type="text"
          maxLength={40}
          id="onboarding-nature-bussiness"
          onChange={onbussinessChange}
          value={natureBusiness}
          className={'col-sm-3 updated-input font-weight-medium pl-0 ' + (err ? 'is-invalid ' : '')}
        />
        <Form.Control.Feedback type="invalid">{err}</Form.Control.Feedback>
      </Form.Group>
    </div>
    )}
    </>
  );
};
NatureBusiness.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  err: PropTypes.string,
  infoIcon: PropTypes.bool,
  infoMsg: PropTypes.string,
};
export default NatureBusiness;
