import { useEffect, useState } from 'react';
import { HiOutlineClock } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../redux/hooks';
import {
  constructFdAppURL,
  getWindowDimensions,
} from '../../../utility/utility';
import { ReactComponent as BookAnFD } from '../../assets/DashboardV2/book_an_fd.svg';
import { ReactComponent as ImportFunds } from '../../assets/DashboardV2/import-funds.svg';
import StatusBanner from '../../shared/component/StatusBanner';
import './DashboardV2.scss';
import { DefaultDashboard } from './DefaultDashboard';
import { FDApplicationsSummaryTile } from './FDApplicationsSummaryTile';

export const DashboardV2 = () => {
  const user = useAppSelector((state: any) => state.authReducer);
  const isMutualFundInScope = user.isMutualFundInScope;

  const quicklinks = [
    {
      color: 'green',
      label: 'Book an FD',
      link: `${constructFdAppURL(user.fdAppURL, { activeTab: 'Deposits' })}`,
      icon: <BookAnFD />,
      redirect: true,
    },
    {
      color: 'blue',
      label: 'Mutual Funds',
      link: '',
      icon: <ImportFunds />,
      search: 'nfo=1',
    },
  ];

  // TODO: enable once permisssions to view Dashboard is available for staff user
  // Access/permissions checks
  // const canAccessAssistedMode = has_permission(
  //   'AssistedMode',
  //   user.permissions
  // );

  const canAccessAssistedMode = false;
  const showDefaultDashboard = !canAccessAssistedMode;
  const showStatusBanner = !canAccessAssistedMode && isMutualFundInScope;
  const showSummaryForOpsExec = canAccessAssistedMode;
  const showQuickLinksForOpsExec = canAccessAssistedMode;

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <div className='DashboardV2'>
        {showStatusBanner && windowDimensions.width < 992 && (
          <div className={'row mb-4'}>
            <div className={'w-100 d-flex  justify-content-center'}>
              <StatusBanner />
            </div>
          </div>
        )}
        <div className='DashboardV2__title_wrapper'>
          <div className='DashboardV2__title DashboardV2__heading mb-3 pb-3'>
            Dashboard
          </div>
        </div>
        <div className='DashboardV2__summary_quick_links_wrapper'>
          <div className='col-lg-7 col-12 d-flex'>
            {showSummaryForOpsExec && <FDApplicationsSummaryTile />}
          </div>
          <div className='col-lg-5 col-12 d-flex'>
            {showQuickLinksForOpsExec && (
              <div className='DashboardV2__quicklinks_wrapper'>
                <div className='DashboardV2__tile DashboardV2__quicklinks flex-grow-1'>
                  <div className='DashboardV2__title DashboardV2__quicklinksTitle'>
                    Quick Links
                  </div>
                  <div className='d-grid DashboardV2__quicklinksGrid'>
                    {quicklinks.map((c) => {
                      if (c.link && c.redirect) {
                        return (
                          <a
                            href={c.link}
                            className={`DashboardV2__${c.color}Tile DashboardV2__gridItem d-flex flex-column justify-content-center align-items-center p-3`}
                            key={c.label}
                          >
                            {c.icon}
                            <div className='mt-2 pt-1'>{c.label}</div>
                          </a>
                        );
                      }
                      if (c.link) {
                        return (
                          <Link
                            to={{ pathname: c.link, search: c.search || '' }}
                            className={`DashboardV2__${c.color}Tile DashboardV2__gridItem d-flex flex-column justify-content-center align-items-center p-3`}
                            key={c.label}
                          >
                            {c.icon}
                            <div className='mt-2 pt-1'>{c.label}</div>
                          </Link>
                        );
                      }
                      return (
                        <div
                          className={`DashboardV2__${c.color}Tile DashboardV2__gridItem d-flex flex-column justify-content-center align-items-center`}
                          key={c.label}
                        >
                          {c.icon}
                          <div className='mt-2 pt-1'>{c.label}</div>
                          <div className='DashboardV2__comingSoon'>
                            <HiOutlineClock /> Coming Soon
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {showDefaultDashboard && <DefaultDashboard />}
      </div>
    </>
  );
};
