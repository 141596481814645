import PropTypes from 'prop-types';
import { useState } from 'react';
import { Container, Navbar } from 'react-bootstrap';
import * as AiIcons from 'react-icons/ai';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { logOut } from '../../api/nav';
import ProfileIcon from '../../assets/Profile.svg';
import Logo from '../../component/whitelable/Logo';
import { updateSelectedNav } from '../../redux/reducers/header-reducer';
import {
  redirectUserForAuthentication,
  removeToken,
} from '../../utility/utility';
import './Navbar.scss';
import SidebarLoginData from './SidebarLoginData';

const Header = ({ history }) => {
  const { user_id } = useSelector((state) => state.authReducer);
  const { activeLink } = useSelector((state) => state.headerReducer);
  const dispatch = useDispatch();
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);
  // useEffect(() => {
  //    if(location.pathname && user_id){
  //      history.push('/')
  //    }
  // }, [])

  const updateActiveLink = (link) => {
    dispatch(updateSelectedNav(link));
  };

  const onLogoutClick = () => {
    logOut(user_id)
      .then(() => {
        localStorage.removeItem('panData');
        localStorage.removeItem('uD');
        localStorage.removeItem('lD');
        removeToken();
        redirectUserForAuthentication();
      })
      .finally(() => {
        localStorage.removeItem('panData');
        localStorage.removeItem('uD');
        localStorage.removeItem('lD');
        removeToken();
        redirectUserForAuthentication();
      });
  };

  const getTitle = (value) => {
    let name = value.replace(' ', '').toLowerCase();
    return name;
  };
  return (
    <>
      {user_id ? (
        <Navbar
          collapseOnSelect
          expand='lg'
          variant='light'
          className=' navbar-light bg-white mt-2 mb-4 mx-0'
        >
          <Container fluid style={{ display: '' }}>
            <div className='navbar'>
              <span style={{ fontSize: '1rem' }} className='m-0 '>
                <IoIosArrowBack />
              </span>
              <span className='m-0 mt-2' onClick={() => history.goBack()}>
                Back
              </span>
            </div>
            <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
              <ul className='nav-menu-items' onClick={showSidebar}>
                <Link className='navbar-brand mb-4 mt-4' to='/'>
                  <Logo />
                </Link>
                <Link
                  to='#'
                  className='menu-bars mr-4 mt-4'
                  style={{ float: 'right' }}
                >
                  <AiIcons.AiOutlineClose />
                </Link>
                {SidebarLoginData.map((item, index) => {
                  return (
                    <>
                      <li
                        key={index}
                        className={`${item.cName} nav-item mr-4 ${
                          activeLink === getTitle(item.title) && 'active'
                        }`}
                        onClick={() => updateActiveLink(getTitle(item.title))}
                      >
                        <Link to={item.path} className='nav-link' smooth>
                          <span>{item.title}</span>
                          <span style={{ float: 'right' }}>
                            <IoIosArrowForward />
                          </span>
                        </Link>
                      </li>
                    </>
                  );
                })}
                <li
                  className={`nav-text nav-item mr-4 ${
                    activeLink === 'logout' && 'active'
                  }`}
                >
                  <a href={'#'} className='nav-link' onClick={onLogoutClick}>
                    <span>Log out</span>
                    <span style={{ float: 'right' }}>
                      <IoIosArrowForward />
                    </span>
                  </a>
                </li>
              </ul>
            </nav>
            <Link className='navbar-brand m-0' to='/'>
              <Logo />
            </Link>
            <div className='navbar'>
              <Link to='#' className='menu-bars'>
                <img
                  src={ProfileIcon}
                  alt='profile icon'
                  className='rounded-circle header-profile-pic'
                  onClick={showSidebar}
                />
              </Link>
            </div>
          </Container>
        </Navbar>
      ) : null}
    </>
  );
};
Header.propTypes = {
  history: PropTypes.object,
};
export default withRouter(Header);
