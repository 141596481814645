import React, { useState,  useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { formatPrice, ordinal_suffix_of, regexString } from '../../utility/utility';
import closeIcon from '../../assets/close.svg';
import './order-component.scss';
import SelectFolioModal from '../../modal/SelectFolioModal';
import SipDateSelectModal from '../../page/fund/SipDateSelectModal';

const OrderCart = ({ orderId, fundName, amount,sipAmount, folioNumber, deleteFunc, updateFunc, folioList, fundId, fundType,selectedSipDay }) => {
  const [folioModal, setFolioModal] = useState({ show: false });
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [showSipModal,setShowSipModal] = useState(false);
  const [selectedSipDate, setSelectedSipDate] = useState(selectedSipDay);
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const onCLoseModal = (folioNo) => {
    setFolioModal({ show: false });
    updateFunc(folioNo);
  };
  const toggleSipModal = () => {
    setShowSipModal(!showSipModal);
    setSelectedSipDate(1)
  };
  const onSipDateSelect = (date) => {
    setSelectedSipDate(date)
  };
  const onSipDateSubmit = () => {
    setShowSipModal(false)
  };

  return (
    <>
    { windowDimensions.width > 480 ? (
      <div className="order-cart-section p-4 my-3">
      <div className="order-detail d-flex justify-content-between align-items-center">
        <Link to={{pathname:`/fund/detail/${regexString(fundName)}/${fundId}`}} className="h6 font-weight-bold">
          {fundName}
        </Link>
        <span>
          <div
          className="delete-button"
          onClick={() => {
            deleteFunc(orderId);
          }}>
          <img src={closeIcon} alt="delete icon" className="cursor-pointer" />
         </div>
        </span>
      </div>
      <div className="order-action d-flex justify-content-between align-items-center mt-4">
        <div>
          <div className="opacity-50">{'Folio'}</div>
          <div className="mt-2">{folioNumber ? folioNumber : 'New'}</div>
        </div>
        <div>
          <div className="opacity-50">{'Category'}</div>
          <div className="mt-2">{fundType}</div>
        </div>
      </div>

        {sipAmount ? <div className='order-action d-flex justify-content-between align-items-center mt-4'>
          <div>
            <div className="opacity-50">{'Monthly investment amount (SIP)'}</div>
            <div className="h6 font-weight-medium mt-2">&#8377; {formatPrice(sipAmount)} </div>
          </div>
          <div className="mt-2">
            <button className="btn btn-outline-dark"  onClick={()=> setShowSipModal(!showSipModal)} disabled>
              {ordinal_suffix_of(selectedSipDate) + " of every month"}
            </button>
            <SipDateSelectModal show={showSipModal} onClose={toggleSipModal} selectedDate={selectedSipDate} onDateSelect={onSipDateSelect} onSubmit={onSipDateSubmit}/>
          </div>
        </div> : ''
        }

        {amount ?
          <div className='order-action justify-content-between align-items-center mt-4'>
            <div className="opacity-50">{'One-time lumpsum amount'}</div>
            <div className="h6 font-weight-medium mt-2">&#8377; {formatPrice(amount)} </div>
          </div> : ''}
      <SelectFolioModal show={folioModal.show} onSubmit={onCLoseModal} folioList={folioList} />
    </div>
    ) : (
      <div className="order-cart-section p-4 my-3">
      <div className="order-detail d-flex justify-content-between align-items-center border-bottom pb-1">
        <Link to={{pathname:`/fund/detail/${regexString(fundName)}/${fundId}`}} className="h6 font-weight-bold c-black">
          {fundName}
        </Link>
        <span>
          <button
          className="btn br-light py-0  btn-danger"
          onClick={() => {
            deleteFunc(orderId);
          }}>
            Remove
         </button>
        </span>
      </div>
        <div className='mt-2'>
          <span className="ml-0 opacity-50">{'Folio :'}</span>
          <span className="mt-2 font-weight-bold">{folioNumber ? folioNumber : 'New'}</span>
        </div>
      <div className="p-0 col-xs-12 order-action d-flex justify-content-between align-items-center mt-3">
        <div className='p-0 col-xs-6 order-action justify-content-between align-items-center'>
          <div className="opacity-50 small">{'Category'}</div>
          <div className="mt-2 font-weight-bold">{fundType}</div>
        </div>
        <div className='p-0 col-xs-6 order-action justify-content-between align-items-center '>
          <div className="opacity-50 small">{'One-time lumpsum amount'}</div>
          <div className=" font-weight-bold mt-2">&#8377; {formatPrice(amount)} </div>
        </div>
      </div>
        {sipAmount ?
        <div className='p-0 col-xs-12 order-action d-flex justify-content-between align-items-center mt-3'>
          <div className="p-0 col-xs-6 order-action justify-content-between align-items-center">
            <div className="opacity-50">{'Monthly investment amount (SIP)'}</div>
            <div className="h6 font-weight-medium mt-2">&#8377; {formatPrice(sipAmount)} </div>
          </div>
          <div className="p-0 col-xs-6 order-action justify-content-between align-items-center">
            <button className="btn btn-outline-dark"  onClick={()=> setShowSipModal(!showSipModal)} disabled>
              {ordinal_suffix_of(selectedSipDate) + " of every month"}
            </button>
            <SipDateSelectModal show={showSipModal} onClose={toggleSipModal} selectedDate={selectedSipDate} onDateSelect={onSipDateSelect} onSubmit={onSipDateSubmit}/>
          </div>
        </div> : ''}

      <SelectFolioModal show={folioModal.show} onSubmit={onCLoseModal} folioList={folioList} />
    </div>
    )}
    
    </>
  );
};
OrderCart.propTypes = {
  orderId: PropTypes.number,
  folioNumber: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fundName: PropTypes.string,
  fundType: PropTypes.string,
  fundId: PropTypes.number,
  amount: PropTypes.number,
  sipAmount: PropTypes.number,
  deleteFunc: PropTypes.func,
  updateFunc: PropTypes.func,
  folioList: PropTypes.array,
  selectedSipDay:PropTypes.number,
};
export default OrderCart;
