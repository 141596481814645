import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import successfulIcon from '../../assets/successful.svg';
import failedIcon from '../../assets/not-supported.svg';
import { useHistory } from "react-router-dom"
const SignSuccessModal = ({ show , size, errorIcon }) => {
  const history = useHistory()
  const goToHome = () => {
      history.push('/home')
  }
  return (
    <Modal show={show} size={size} className="p-4 " aria-labelledby="add-fund-modal"  centered backdrop="static" keyboard={false}>
      <Modal.Body className="text-center my-4">
        <div className="img-container mb-4">
          <img src={errorIcon ? failedIcon : successfulIcon} alt="successful icon" className="w-30" />
        </div>
        <p className="font-weight-normal">Your digital signature is submitted</p>
        <button className='btn btn-primary mt-2' onClick={goToHome}>Close</button>
      </Modal.Body>
    </Modal>
  );
};
SignSuccessModal.propTypes = {
  successText: PropTypes.string,
  size: PropTypes.string,
  show: PropTypes.bool,
  errorIcon: PropTypes.bool,
};
export default SignSuccessModal;