export enum ReturnsTypeEnum {
  'Absolute' = 'absolute_return',
  'XIRR' = 'xirr',
}

export enum KycStatusEnum {
  processing = 'processing',
  pending = 'pending',
  initiated = 'initiated',
  registered = 'registered',
  validated = 'validated',
  rejected = 'rejected',
}