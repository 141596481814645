import React, { useEffect, useState } from 'react';
import Assessment from './Components/Assessment';
import { getAssessmentreport } from '../../../api/assessment';
import { useSelector } from 'react-redux';
import AssessmentResult from './Components/AssessmentResult';

const RiskAssessment = () => {
  const userId = useSelector((state) => state.authReducer.user_id);
  const [result, setResult] = useState();
  const [assessmentFlag, setAssessmentFlag] = useState(0);

  useEffect(() => {
    getUserRiskAssessmentReport();
  }, []);

  const getUserRiskAssessmentReport = () => {
    getAssessmentreport(userId).then((response) => {
      if (response.data.assessment_date) {
        setResult(response.data);
        setAssessmentFlag(2);
      } else {
        setAssessmentFlag(1);
      }
    }).catch(error => {
      setAssessmentFlag(1);
    });
  };

  const onSubmit = () => {
    getUserRiskAssessmentReport();
  };

  const retakeAssessment = () => {
    setAssessmentFlag(1)
  };

  return <>{assessmentFlag === 1 ? <Assessment onSubmit={onSubmit} /> : assessmentFlag === 2 ? <AssessmentResult report={result} retake={retakeAssessment} /> : ''}</>;
};

export default RiskAssessment;
