import axios from "./axios";
import elevo_apis from "./elevo_apis";

const getNominee = (id) => {
    return elevo_apis.get(`/investors/${id}/nominees`);
};
const removeNominee = (investorId, nomineeId) => {
    return elevo_apis.delete(`/investors/${investorId}/nominees/${nomineeId}`);
};
const addNominee = (investorId, data,token) => {
    return axios.post(`elevo/investors/${investorId}/nominees`, data,{headers:{
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`
    }});
};
const updateNominee = (investorId, data,token) => {
    return axios.put(`elevo/investors/${investorId}/nominees`, data,{headers:{
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`
    }});
};
const updateNomineeAuth = (investorId, nomineeId, data,token) => {
    return axios.patch(`elevo/investors/${investorId}/nominees/${nomineeId}`, data,{headers:{
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`
    }});
};

export { getNominee,
    removeNominee,
    addNominee,
    updateNominee,
    updateNomineeAuth
}