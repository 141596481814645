import { ApplicationOut } from '../../../models';
import { client } from '../client';

const getApplication = async (id: number): Promise<ApplicationOut> => {
  const res = await client.get<ApplicationOut>(`/applications/${id}`);
  return res.data;
};

const api = { getApplication };

export default api;
