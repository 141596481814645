import { useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { resendOTP, sendOTP, verifyOTP } from '../api/otp';
import { redeemConfirmation, redeemTransiction } from '../api/portfolio';
import OTPTimer from '../component/otp-component/Timer';
import OTPInput from '../component/otp-input-component';
import { decryptData } from '../utility/DecryptHelper';
import { regexString } from '../utility/utility';
import RedeemStatusMobile from './RedeemStatusMobile';

const RedeemConfirmationModal = () => {
  const userId = useSelector((state) => state.authReducer.user_id);
  const userData = JSON.parse(localStorage.getItem('lD'));
  const [fundData, setFundData] = useState('');
  const [statusMobile, setMobileStatus] = useState({});
  const history = useHistory();
  const location = useLocation();
  const data = location.state;
  const [otpId, setOtpId] = useState('');
  const [proceedBtnState, setProceedBtnState] = useState(false);
  const [showTimer, setShowTimer] = useState(true);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [showLoader, setShowLoader] = useState(false);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    redeemConfirm();
    sendOTPAPI('redemption');
  }, []);

  const sendOTPAPI = (type) => {
    const redemmptionApiData = {
      otp_type: type,
      investor_id: userId.toString(),
      folio: data?.dataObj?.folio_no,
      fund_id: data?.dataObj?.fundId.toString(),
    };
    setProceedBtnState(false);
    sendOTP(redemmptionApiData).then((response) => {
      const data = response?.data;
      if (data?.otp_id) {
        setOtpId(data?.otp_id);
      }
      setShowTimer(true);
    });
  };

  const handleReSendOTP = (otpId, voice) => {
    setProceedBtnState(false);
    resendOTP(otpId, voice).then((response) => {
      const data = response.data;
      setOtpId(data?.otp_id);
      setShowTimer(true);
    });
  };
  const redeemConfirm = () => {
    setShowLoader(true);
    redeemConfirmation(userId)
      .then((res) => {
        const confirmData = decryptData(res.data.data);
        setFundData(confirmData.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };
  const confirmRedemption = () => {
    const formData = new FormData();
    formData.append('user_id', data?.dataObj?.userId);
    formData.append('fund_id', data?.dataObj?.fundId);
    formData.append('folio_number', data?.dataObj?.folio_no);
    formData.append('folio_id', data?.dataObj?.folio_id);
    formData.append('otp_id', otpId);
    if (data?.dataObj?.type === 'units') {
      formData.append('qty', data?.dataObj?.redeemVal);
      formData.append('amount', '');
      formData.append(
        'all_redeem',
        data?.dataObj?.redeemVal === data?.dataObj?.units ? 'Y' : 'N'
      );
    } else {
      formData.append('amount', data?.dataObj?.redeemVal);
      formData.append('qty', '');
      formData.append(
        'all_redeem',
        data?.dataObj?.redeemVal === data?.dataObj?.current_value ? 'Y' : 'N'
      );
    }
    setShowLoader(true);
    redeemTransiction(formData)
      .then((res) => {
        const response = decryptData(res.data.data);
        let code = 0;
        code = response.data.bse_remarks ? code : 1;

        windowDimensions.width > 480
          ? history.push({
              pathname: '/redemptionstatus',
              state: { code: code, data },
            })
          : setMobileStatus({ show: true, options: { code, data } });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const otpChange = (event) => {
    let otp = event || '';
    if (otp.length === 6) {
      verifyOtp(otp, otpId);
    }
  };

  const resendOtp = () => {
    resend(false);
  };

  const resendOtpViaCall = () => {
    resend(true);
  };

  const resend = (call) => {
    if (call) {
      handleReSendOTP(otpId, true);
    } else {
      handleReSendOTP(otpId, false);
    }
  };

  const verifyOtp = (otp, otpId) => {
    const verifyRedeemtionData = {
      otp_type: 'redemption',
      otp: otp,
    };

    setShowLoader(true);
    verifyOTP(verifyRedeemtionData, otpId)
      .then((response) => {
        if (response.status === 201) {
          setProceedBtnState(true);
        } else {
          setProceedBtnState(false);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  return (
    <>
      {windowDimensions.width > 480 ? (
        <div>
          <div className='row justify-content-center align-items-center mb-4'>
            <h2>Redemption Confirmation</h2>
          </div>
          <div className='row justify-content-center align-items-center h-100'>
            <div className='col-sm-7 px-0'>
              <div className='p-5 colored-box mb-4'>
                <Link
                  to={{
                    pathname: data?.dataObj
                      ? `/fund/detail/${regexString(data?.dataObj?.name)}/${
                          data?.dataObj?.fundId
                        } `
                      : '/',
                  }}
                  className='h4 '
                >
                  {data?.dataObj?.name}
                </Link>
                <Form.Group className='mb-3'>
                  <Form.Label className='font-weight-normal mt-4 mb-0 opacity-50'>
                    {data?.dataObj?.type === 'units'
                      ? 'Units redeemed'
                      : 'Amount redeemed'}
                  </Form.Label>
                  <InputGroup className='mb-2 font-weight-medium'>
                    {data?.dataObj?.type === 'units' ? null : (
                      <>
                        <InputGroup.Prepend>
                          <InputGroup.Text className='font-weight-medium'>
                            &#8377;
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                      </>
                    )}
                    <Form.Control
                      type='text'
                      id='Total-Investment'
                      placeholder=''
                      disabled
                      value={data?.dataObj?.redeemVal}
                      className={'updated-input font-weight-medium'}
                    />
                  </InputGroup>
                </Form.Group>
                <p className='opacity-50 mb-0'>
                  Amount will be credited in 1-3 business days depending on the
                  AMC.
                </p>
              </div>
            </div>
            <div className='col-sm-7 px-0'>
              <div className=' p-5 colored-box mb-5'>
                <p id='select-bank-modal' className='mb-3'>
                  Withdraw to your bank account:
                </p>
                <div>
                  <div className='d-flex'>
                    <img
                      src={fundData.url}
                      className='h-10vh w-10vw'
                      alt={fundData.bank_name}
                    />
                    <div className='ml-3'>
                      <p className=' font-weight-medium mb-1'>
                        {fundData.bank_name}
                      </p>
                      <p className='mt-1 mb-0'>A/C:{fundData.account_number}</p>
                    </div>
                  </div>
                </div>
                <hr />
                <div className='d-flex'>
                  <p className='mb-0'>Exit load</p>
                  <p className='mb-0'>
                    {data?.dataObj?.exitLoad.map((data) => {
                      return (
                        <p
                          key=''
                          className='opacity-50 ml-2 mb-2'
                        >{`${data.value} % if redeemed within ${data.high_breakpoint} ${data.breakpoint_unit}`}</p>
                      );
                    })}
                  </p>
                </div>
                <hr />
                <p className='opacity-50'>
                  Final amount will depend on NAV applicable at the time of
                  redemption request
                </p>
              </div>
            </div>

            <div className='col-sm-7 px-0'>
              <div className=' p-5 colored-box mb-5'>
                <p id='otp-modal' className='h6 font-weight-bold text-center'>
                  Verification Code
                </p>
                <p className='text-center font-weight-light mt-8 opacity-50'>
                  Please enter the verification code that we have sent on your
                  mobile number or email id.
                </p>
                <div className='text-center mt-8 opacity-75'>
                  <OTPInput
                    autoFocus={true}
                    isNumberInput
                    length={6}
                    className='justify-content-center mt-8 w-25 d-inline-flex'
                    inputClassName='form-control otp-input'
                    onChangeOTP={otpChange}
                  />
                  {proceedBtnState ? (
                    <p className={'mt-4 font-weight-light text-success'}>
                      OTP Verified
                    </p>
                  ) : showTimer ? (
                    <p className={'mt-4 font-weight-light text-danger'}>
                      OTP expires in{' '}
                      <OTPTimer
                        className='enabled text-danger'
                        initialSeconds='60'
                        onComplete={setShowTimer}
                      />{' '}
                      seconds
                    </p>
                  ) : (
                    <p className={'mt-4 font-weight-light text-danger'}>
                      {' '}
                      OTP expired{' '}
                    </p>
                  )}
                  <p className='opacity-75'>
                    Didn't receive OTP ? &nbsp;
                    <button
                      type='button'
                      className={
                        showTimer ? 'btn link-disabled' : 'btn link-enabled'
                      }
                      style={showTimer ? { pointerEvents: 'none' } : null}
                      onClick={resendOtp}
                    >
                      Resend OTP
                    </button>
                    {userData?.mobile_number && (
                      <>
                        &nbsp; | &nbsp;
                        <button
                          type='button'
                          className={
                            showTimer ? 'btn link-disabled' : 'btn link-enabled'
                          }
                          style={showTimer ? { pointerEvents: 'none' } : null}
                          onClick={resendOtpViaCall}
                        >
                          {' '}
                          Send OTP Via Call
                        </button>
                      </>
                    )}
                  </p>
                  <div className='btn-container text-center mb-5 px-5 '>
                    <Button
                      className='btn btn-primary w-300px'
                      onClick={confirmRedemption}
                      disabled={!proceedBtnState || showLoader}
                    >
                      {showLoader ? <BeatLoader size={10} /> : 'Proceed'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='bg-light-grey pb-4 pt-4'>
          <div className='row justify-content-center align-items-center pb-1'>
            <h4 className='font-weight-medium'>Redemption Confirmation</h4>
          </div>
          <div className=' justify-content-center align-items-center h-100'>
            <div className='colored-box m-4  px-0'>
              <div className='p-4 '>
                <Link
                  to={{
                    pathname: data?.dataObj
                      ? `/fund/detail/${regexString(data?.dataObj?.name)}/${
                          data?.dataObj?.fundId
                        } `
                      : '/',
                  }}
                  className='h5 c-black'
                >
                  {data?.dataObj?.name}
                </Link>
                <div className='order-detail mt-3  d-flex justify-content-between align-items-center'>
                  <span className='ml-0 opacity-75'>
                    {data?.dataObj?.type === 'units'
                      ? 'Units redeemed'
                      : 'Amount redeemed'}
                  </span>
                  <span className='font-weight-bold mr-2'>
                    {' '}
                    {data?.dataObj?.type === 'units' ? null : (
                      <span>&#8377;</span>
                    )}{' '}
                    {data?.dataObj?.redeemVal}{' '}
                  </span>
                </div>
                <hr></hr>
                <p className='opacity-50 mb-0 c-blue font-italic'>
                  *Amount will be credited in 1-3 business days depending on the
                  AMC.
                </p>
              </div>
            </div>
            <div className='col-sm-7 px-0'>
              <h5 className='mb-3 text-center font-weight-bold'>
                Withdraw to your bank account:
              </h5>
              <div className='colored-box m-4  px-0'>
                <div className='p-4'>
                  <div className='d-flex'>
                    <img
                      src={fundData.url}
                      className='h-10vh w-10vw'
                      alt={fundData.bank_name}
                    />
                    <div className='ml-3'>
                      <p className=' font-weight-medium mb-1'>
                        {fundData.bank_name}
                      </p>
                      <span className='mt-1 mb-0 ml-0 opacity-50'>
                        Current A/C No :
                      </span>
                      <span className='ml-0 font-weight-bold'>
                        {fundData.account_number}
                      </span>
                    </div>
                  </div>

                  <hr />
                  <div className='d-flex'>
                    <p className='mb-0 font-weight-bold'>Exit load :</p>
                    <p className='mb-0'>
                      {data?.dataObj?.exitLoad.map((data) => {
                        return (
                          <p
                            key=''
                            className=' ml-2 mb-2'
                          >{`${data.value} % if redeemed within ${data.high_breakpoint} ${data.breakpoint_unit}`}</p>
                        );
                      })}
                    </p>
                  </div>
                  <hr />
                  <p className='opacity-50'>
                    *Final amount will depend on NAV applicable at the time of
                    redemption request
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='col-sm-7 px-0'>
            <div className=' p-4 colored-box m-4  px-0'>
              <p id='otp-modal' className='h6 font-weight-bold text-center'>
                Verification Code
              </p>
              <p className='h6 small text-center font-weight-light mt-8 opacity-50'>
                Please enter the verification code that we have sent on your
                mobile number or email id.
              </p>
              <div className='text-center mt-8 opacity-75'>
                <OTPInput
                  autoFocus={true}
                  isNumberInput
                  length={6}
                  className='justify-content-center mt-8 w-25 d-inline-flex'
                  inputClassName='form-control otp-input'
                  onChangeOTP={otpChange}
                />
                {proceedBtnState ? (
                  <p className={'mt-4 font-weight-light text-success'}>
                    OTP Verified
                  </p>
                ) : showTimer ? (
                  <p className={'mt-4 font-weight-light text-danger'}>
                    OTP expires in{' '}
                    <OTPTimer
                      className='enabled text-danger'
                      initialSeconds='60'
                      onComplete={setShowTimer}
                    />{' '}
                    seconds
                  </p>
                ) : (
                  <p className={'mt-4 font-weight-light text-danger'}>
                    {' '}
                    OTP expired{' '}
                  </p>
                )}
                <p className='col-sm-8 h6 small opacity-75'>
                  Didn't receive OTP ? &nbsp;
                  <button
                    type='button'
                    className={
                      showTimer
                        ? 'btn-sm btn link-disabled'
                        : 'btn-sm btn link-enabled'
                    }
                    style={showTimer ? { pointerEvents: 'none' } : null}
                    onClick={resendOtp}
                  >
                    Resend OTP
                  </button>
                  {userData?.mobile_number && (
                    <>
                      &nbsp; | &nbsp;
                      <button
                        type='button'
                        className={
                          showTimer
                            ? 'btn-sm btn link-disabled'
                            : 'btn-sm btn link-enabled'
                        }
                        style={showTimer ? { pointerEvents: 'none' } : null}
                        onClick={resendOtpViaCall}
                      >
                        {' '}
                        Send OTP Via Call
                      </button>
                    </>
                  )}
                </p>
                <div className='btn-container text-center mb-5 '>
                  <Button
                    className='w-300px'
                    onClick={confirmRedemption}
                    disabled={!proceedBtnState || showLoader}
                  >
                    {showLoader ? (
                      <BeatLoader size={10} color='#ffffff' />
                    ) : (
                      'Proceed'
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {statusMobile.show ? (
            <RedeemStatusMobile
              show={statusMobile.show}
              options={statusMobile.options}
            />
          ) : null}
        </div>
      )}
      {/* {showLoader && <MainLoader />} */}
    </>
  );
};
export default RedeemConfirmationModal;
