import React, { useEffect, useState } from 'react';
import Switch from 'react-ios-switch';
import Form from 'react-bootstrap/Form';
import './SipBankSelect.scss';

  const SipBankSelect = ({onMandateSelect, confirmMandate, onFirstOderTodayStatusChange, mandateData}) => {
  const [checked, setChecked] = useState(true);
  const [selectedMandate, setSelectedMandate] = useState(null);

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(()=>{
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  },[])

  useEffect(() => {
    setSelectedMandate(mandateData && mandateData[0])
  }, [JSON.stringify(mandateData)]);

  useEffect(() => {
    onMandateSelect(selectedMandate);
    setChecked(true)
  }, [selectedMandate]);

  const getStatus = (status) => {
    let curStatus = { status: 'Approved', class: 'c-green' };
    if (status === 'FAILED') {
      curStatus = { status: 'Failed', class: 'c-red' };
    } else if (status === 'REJECTED') {
      curStatus = { status: 'Rejected', class: 'c-red' };
    } else if (status === 'UPLOADED') {
      curStatus = { status: 'Pending', class: 'c-yellow' };
    } else if (status === 'UNDER PROCESSING') {
      curStatus = { status: 'Pending', class: 'c-yellow' };
    } else if (status === 'REGISTERED BY MEMBER') {
      curStatus = { status: 'Pending', class: 'c-yellow' };
    } else if (status === 'SCAN IMAGE NOT UPLOADED') {
      curStatus = { status: 'Pending', class: 'c-yellow' };
    } else if (status === 'INITIAL REJECTION') {
      curStatus = { status: 'Pending', class: 'c-yellow' };
    } else if (status === 'UPLOADEDTOBSE') {
      curStatus = { status: 'Verified', class: 'c-green' };
    } else if (status === 'VERIFIEDBYADMIN') {
      curStatus = { status: 'Verified', class: 'c-green' };
    } else if (status === 'WAITING FOR CLIENT AUTHENTICATION') {
      curStatus = { status: 'Pending', class: 'c-yellow' };
    }
    return curStatus;
  };

  const handleCheck = (status) => {
    setChecked(status)
    onFirstOderTodayStatusChange(status)
  };

  const handleConfirmMandate = () => {
    confirmMandate()
  };

  return (
    <>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-12'}>
          <h4 className={'text-center'}>Select bank mandate for Autopay</h4>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col-12 p-4'}>
            {mandateData.map(item => {
              const statusObj = getStatus(item.status);
              return ( (statusObj.status === "Approved" || statusObj.status === "Pending") &&
              <>
                <div className={`mandate-card row ${item?.id === selectedMandate?.id ? 'selected' : ''}`} onClick={() => setSelectedMandate(item)}>
                  <div className={"col-1 center-radio-box align-items-start"}>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check className={"sip-radio"} type="radio" label="" checked={item?.id === selectedMandate?.id} />
                    </Form.Group>
                  </div>
                  <div className={"col-10 col-sm-11"}>
                    <div className={'flex-class'}>
                      <div>
                          <div>{item.bank_details.bank_name}</div>
                            <div className={'small mt-2'}>
                              <span className={'m-0 opacity-50'}>Account Number: </span> {item.bank_details.account_number}
                            </div>
                            <div className={'small mt-2'}>
                              <span className={'m-0 opacity-50 text-right'}>Mandate Type: </span>{' '}
                              <span className={statusObj.class}>{item.mandate_type === 'N' ? 'E-NACH' : ''}</span>
                              <span className={statusObj.class}>{item.mandate_type === 'X' ? 'NACH' : ''}</span>
                            </div>
                            <div className={'small mt-2'}>
                              <span className={'m-0 opacity-50 text-right'}>Autodebit limit: </span>{' '}
                              <span className={statusObj.class}>{item.amount}</span>
                            </div>
                      </div>
                          <div className={windowDimensions.width > 480 ? 'small ml-auto text-right' : 'small mt-2'}>
                            <span className={'m-0 opacity-50 text-right'}>Status: </span> <span className={statusObj.class}>{statusObj.status}</span>
                         </div>
                    </div>
                    {(item?.id === selectedMandate?.id) && statusObj.status === "Approved" &&
                    <>
                      <hr/>
                      <div>
                        Do you want to pay your first SIP installment today?
                        <div className={'d-md-flex align-items-center mt-3'}>
                          <div className={`m-0 cursor-pointer small ${!checked ? 'c-blue' : 'opacity-50'}`}
                               onClick={() => handleCheck(false)}>
                            No, Please start the SIP as scheduled.
                          </div>
                          <Switch
                            checked={checked}
                            className={`switch ${checked && 'checked'}`}
                            onChange={(checked) => {
                              handleCheck(checked);
                            }}
                            offColor={'#707070'}
                            onColor={'#00b2e6'}
                          />
                          <div className={`m-0 cursor-pointer small ${checked ? 'c-blue' : 'opacity-50'}`}
                               onClick={() => handleCheck(true)}>
                            Yes, I will pay the SIP amount today.
                          </div>
                        </div>
                      </div>
                    </>
                    }
                  </div>
                </div>
                {(item?.id === selectedMandate?.id) && statusObj.status === "Pending" && <p className={"c-red text-center small"}>As the selected bank mandate is pending for approval, it is mandatory to pay your first installment today.</p>}
              </>
            )})}
          </div>
        </div>
        <div className={"text-center mt-4"}>
          <button className={"btn btn-primary sip-next"} onClick={handleConfirmMandate}>Next</button>
        </div>
      </div>
    </>
  );
};

export default SipBankSelect;
