import React from 'react';
import './Banner.scss';
import { BsXCircle } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { updateBannerStateAction } from '../../redux/reducers/sip-reducer';

const Banner = () => {
  const bannerState = useSelector((state) => state.sipReducer.banner_state);
  const dispatch = useDispatch();
  const BannerCloseHandler = () => {
    dispatch(updateBannerStateAction(false));
  };

  return (
    <>
      {bannerState ? (
        <div className="Banner-wrapper">
          <span>Your SIP has successfully been stopped</span>
          <BsXCircle
            className="bsx-circle"
            onClick={() => {
              BannerCloseHandler();
            }}
          />
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default Banner;
