import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import { RadioGroups } from '../form';
const PoliticallyExposed = ({ onChange, err, politicallyExposedValue }) => {
  const [inputData, setData] = useState({});
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  useEffect(()=>{
    setData({ value: politicallyExposedValue, err: '' })

  },[politicallyExposedValue])
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  },[])
  const onRadioChange = (event) => {
    const { value } = event.target;
    const tempData = { value: value, err: '' };
    setData(tempData);
    onChange(tempData);
  };
  return (
    <>
    {windowDimensions.width>480?(
      <div className="border p-4 rounded w-100 my-3">
      <RadioGroups
        title="Is the entity politically exposed person or related to a politically exposed person?"
        id="politicallyExposed"
        radioList={[
          { value: 'Yes', label: 'Yes' },
          { value: 'No', label: 'No' },
        ]}
        selectedVal={inputData.value}
        onChange={onRadioChange}
      />
        {err && <div className={'invalid-feedback d-block'}>{err}</div>}
    </div>
    ):(
     <div className="py-2 rounded  w-100 my-3">
      <RadioGroups
        title="Is the entity politically exposed person or related to a politically exposed person?"
        classes={"font-weight-medium "}
        id="politicallyExposed"
        radioList={[
          { value: 'Yes', label: 'Yes' },
          { value: 'No', label: 'No' },
        ]}
        selectedVal={inputData.value}
        onChange={onRadioChange}
      />
       {err && <div className={'invalid-feedback d-block'}>{err}</div>}
    </div>
    )}
    </>
  );
};
PoliticallyExposed.propTypes = {
  onChange: PropTypes.func,
};
export default PoliticallyExposed;
