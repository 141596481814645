import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Asset } from '../../api/fdApi/services/asset.service';
import { useAppSelector } from '../../redux/hooks';
import { FDPortfolioDetails, PortfolioDetails } from '../../types';
import { constructFdAppURL } from '../../utility/utility';
import { ReactComponent as Assets } from '../../v2/assets/DashboardV2/assets.svg';
import { ReactComponent as InvestmentsIcon } from '../../v2/assets/DashboardV2/investments.svg';
import Amount from '../Amount/Amount';
import { DonutPieChart } from './DonutPieChart';
import './Investments.scss';
import { Link } from 'react-router-dom';

interface Props {
  portfolio: PortfolioDetails | undefined;
  fdPortfolio: FDPortfolioDetails | undefined;
  assets: Asset[] | undefined;
  className?: string;
  type?: 'portfolio';
  showImportExternalFundsOption?: boolean;
}

/**
 * When we can say user has made no investments?
 * 1. only MF
 *    (hasMutualFunds && !hasFixedDeposits  && portfolio?.total_investment == 0)
 *
 * 2. only FD
 *    (hasFixedDeposits && !hasMutualFunds && fdPortfolio?.total_investment == 0)
 *
 * 3. MF + FD
 *     (hasMutualFunds && hasFixedDeposits && portfolio?.total_investment == 0 && fdPortfolio?.total_investment == 0)
 *
 */

interface combinedAssets {
  name: string;
  value: number;
}

const COLORS = ['#FFA4B5', '#84C8F3', '#FFE19A'];

export const Investments = ({
  portfolio,
  fdPortfolio,
  assets,
  className = 'col-xl-7',
  type,
  showImportExternalFundsOption = true,
}: Props) => {
  const user = useAppSelector((state: any) => state.authReducer);
  const { fdAppURL } = user;
  const [combinedAssets, setCombinedAssets] = useState<{
    types: combinedAssets[];
  }>({ types: [] });
  const [totalInvestment, setTotalInvestments] = useState(0);
  const hasDeposists = user.isFixedDepositsInScope;
  const hasMutualFunds = user.isMutualFundInScope;

  const NoInvestementsChecker = () => {
    if (
      hasDeposists &&
      hasMutualFunds &&
      portfolio?.total_investment === 0 &&
      fdPortfolio?.total_investment === 0
    )
      return true;
    if (hasDeposists && !hasMutualFunds && fdPortfolio?.total_investment === 0)
      return true;
    if (hasMutualFunds && !hasDeposists && portfolio?.total_investment === 0)
      return true;
  };

  // Calculating total investment from assets and portfolioDeposits
  useEffect(() => {
    let total_investment = 0;
    const combinedAssetsBuffer: {
      types: combinedAssets[];
    } = { types: [] };
    if (portfolio?.total_investment) {
      total_investment += portfolio.total_investment;
    }

    if (fdPortfolio?.total_investment) {
      total_investment += fdPortfolio.total_investment;
      combinedAssetsBuffer.types.push({
        name: 'Deposits',
        value: Math.round(
          (fdPortfolio?.total_investment / total_investment) * 100
        ),
      });
    }

    if (portfolio?.total_investment) {
      combinedAssetsBuffer.types.push({
        name: 'Mutual Funds',
        value: Math.round(
          (portfolio?.total_investment / total_investment) * 100
        ),
      });
    }

    setTotalInvestments(total_investment);
    setCombinedAssets(combinedAssetsBuffer);
  }, [fdPortfolio, portfolio]);

  if (NoInvestementsChecker()) {
    return (
      <div
        className={`${className} col-12 DashboardV2__col flex-grow-1 d-flex flex-column pb-3 mb-1 Investments__empty `}
      >
        <div className='DashboardV2__tile Investments__emptyTile flex-grow-1 d-flex flex-column align-items-center justify-content-center'>
          <InvestmentsIcon />
          <div
            className='mt-3 pt-1 text-center mb-4 pb-1'
            style={{
              whiteSpace: 'pre-line',
            }}
          >
            {
              'You have not made any investments with us.\nStart investing today.'
            }
          </div>
          <div className='Investments__button_container'>
            <a
              className='Investments__emptyBtn border-0 outline-0 d-inline-block'
              href={constructFdAppURL(`${fdAppURL}`, {
                activeTab:
                  hasDeposists && !hasMutualFunds ? 'Deposits' : 'Mutual Funds',
              })}
            >
              Explore Investments
            </a>

            {showImportExternalFundsOption && (
              <>
                <span>OR</span>
                <Link
                  to='/externalfunds/upload-cas'
                  className='Investments__transparentBtn'
                >
                  Import External Funds
                </Link>{' '}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  const renderColumn = () => {
    if (hasDeposists && hasMutualFunds) {
      return (
        <div className='col-12 col-md-6 DashboardV2__col d-flex flex-column'>
          {(!portfolio || !fdPortfolio) && (
            <div className='Investments__overlay_chart'>
              <span>
                Chart cannot be displayed, contact customer support, if the
                issue persists
              </span>
            </div>
          )}
          <div
            className={`${
              !portfolio || (!fdPortfolio && 'DashboardV2__not_available_blur')
            } DashboardV2__tile DashboardV2__assetTile overflow-hidden flex-grow-1`}
          >
            <div className='DashboardV2__title DashboardV2__assetTitle mb-3'>
              Asset Types
            </div>
            <div className='mt-n4'>
              <DonutPieChart
                className={classNames({
                  'DonutPieChart-vertical DonutPieChart-portfolio':
                    type === 'portfolio',
                })}
                data={combinedAssets.types.map((asset, i) => ({
                  name: asset.name,
                  y: asset.value,
                  color: COLORS[i],
                }))}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  if (!hasMutualFunds || !hasDeposists) {
    return null;
  }

  return (
    <div
      className={`col-12 DashboardV2__col flex-grow-1 d-flex flex-column justify-content-between pb-3 mb-1 ${className}`}
      style={{
        maxWidth: hasDeposists && hasMutualFunds ? 702 : 536,
      }}
    >
      <div className='row flex-wrap DashboardV2__row flex-grow-1'>
        {hasMutualFunds && (
          <div
            className={classNames(
              'col-12 DashboardV2__col d-flex flex-column mb-3 mb-md-0',
              {
                'col-md-6': hasDeposists && hasMutualFunds,
                'col-md-7':
                  (!hasDeposists && hasMutualFunds) ||
                  (hasDeposists && !hasMutualFunds),
              }
            )}
          >
            {!assets && (
              <div className='Investments__overlay_chart'>
                <span>
                  Chart cannot be displayed, contact customer support, if the
                  issue persists
                </span>
              </div>
            )}
            <div
              className={`${
                !assets && 'DashboardV2__not_available_blur'
              } DashboardV2__tile DashboardV2__assetTile overflow-hidden flex-grow-1`}
            >
              <div className='DashboardV2__title DashboardV2__title DashboardV2__assetTitle mb-3'>
                Asset Classes
              </div>
              <div className='mt-n4'>
                {assets && (
                  <DonutPieChart
                    className={classNames({
                      'DonutPieChart-vertical':
                        type === 'portfolio' ||
                        (!hasDeposists && hasMutualFunds) ||
                        (hasDeposists && !hasMutualFunds),
                      'DonutPieChart-portfolio': type === 'portfolio',
                    })}
                    data={assets?.map((asset, i) => ({
                      name: asset.asset_class,
                      y: asset.investment_in_percentage,
                      color: COLORS[i],
                    }))}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {renderColumn()}
      </div>
      {hasDeposists && hasMutualFunds && (
        <div className='pt-3 mt-1'>
          <div className='DashboardV2__tile py-3 d-flex align-items-center justify-content-center'>
            <div
              className='py-1 d-flex align-items-center justify-content-center'
              style={{
                lineHeight: 1,
              }}
            >
              <Assets
                style={{
                  marginTop: -1,
                  marginRight: 2,
                }}
              />
              <span className='DashboardV2__investment px-2'>
                Total Investment :
              </span>
              <div className='DashboardV2__investmentAmount'>
                <Amount value={totalInvestment || 0} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
