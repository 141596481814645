import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RadioGroups } from '../form';

const NonFinancialCategory = ({ options, onChange, value, err }) => {
  const [nonFincCat, setData] = useState(value);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
    
  useEffect(() => {
    setData(value);
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [value]);
  const onRadioChange = (event) => {
    const { value } = event.target;
    setData(value);
    if (onChange) {
      onChange(value);
    }
  };
  return (
    <>
    {windowDimensions.width>480?(
      <div className="border p-4 rounded w-100 my-3">
      <RadioGroups
        title="Non-Financial Entity Category"
        id="nonfinancialCategory"
        radioList={options}
        selectedVal={nonFincCat}
        onChange={onRadioChange}
      />
        {err && <div className={'invalid-feedback d-block'}>{err}</div>}
    </div>
    ):(
     <div className="py-2 rounded w-100 my-3">
      <RadioGroups
        title="Non-Financial Entity Category"
        classes={"font-weight-medium "}
        id="nonfinancialCategory"
        radioList={options}
        selectedVal={nonFincCat}
        onChange={onRadioChange}
      />
       {err && <div className={'invalid-feedback d-block'}>{err}</div>}
    </div>
    )}
    </>
  );
};
NonFinancialCategory.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};
export default NonFinancialCategory;
