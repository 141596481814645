import { useMemo } from 'react';
import { Folio } from '../../../types';
import { ReactComponent as ArrowUp } from '../../assets/PortfolioMutualFunds/arrow-up.svg';
import { ReactComponent as RedColoredArrow } from '../../assets/PortfolioMutualFunds/red-colored-arrow.svg';
import { ReturnsTypeEnum } from '../../../enums';
import { useMedia } from '../../../hooks/useMedia';

interface Props {
  folios: Folio[];
  returnType: keyof typeof ReturnsTypeEnum;
  children?: JSX.Element[] | JSX.Element;
}

export const ReturnsCell = ({ folios, returnType, children }: Props) => {
  const isMobile = useMedia(['(min-width: 768px)'], [false], true);

  const average = useMemo(() => {
    const totalAbsolute = folios?.reduce((acc, item) => {
      return (acc += item.absolute_return);
    }, 0);

    const totalXIRR = folios?.reduce((acc, item) => {
      return (acc += item.xirr);
    }, 0);

    return {
      absolute_return: totalAbsolute / folios?.length,
      xirr: totalXIRR / folios?.length,
    };
  }, [folios]);

  if (isMobile && isMobile === true) {
    return (
      <>
        <div className='fw-500 d-flex flex-row w-100 align-items-start justify-content-around'>
          <span>Returns:</span>
          <div className='d-flex flex-row'>
            {average[ReturnsTypeEnum[returnType]].toFixed(2)}%{' '}
            {average[ReturnsTypeEnum[returnType]] >= 0 ? (
              <ArrowUp
                className='ml-1'
                style={{
                  marginTop: -2,
                }}
              />
            ) : (
              <RedColoredArrow className='ml-1' />
            )}
          </div>
          {children}
        </div>
      </>
    );
  }

  return (
    <div className='fw-500 d-flex align-items-center justify-content-center'>
      {average[ReturnsTypeEnum[returnType]].toFixed(2)}%{' '}
      <ArrowUp
        className='ml-1'
        style={{
          marginTop: -2,
        }}
      />
    </div>
  );
};
