import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const DataMsg = ({ message, button, url }) => {
  return (
    <div className="data-msg py-4 text-center">
      <h4 className="font-weight-medium c-blue">{message}</h4>
      {button && (
        <Link to={url} className="btn btn-primary mt-4">
          {button}
        </Link>
      )}
    </div>
  );
};
DataMsg.propTypes = {
  message: PropTypes.string,
  button: PropTypes.string,
  url: PropTypes.string,
};

export default DataMsg;
