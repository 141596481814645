import { client } from '../client';

export const getAllPermissions = async () => {
  const res = await client.get('/permissions');
  return res.data;
};

const api = {
  getAllPermissions,
};

export default api;
