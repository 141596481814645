import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import React, { useEffect, useState } from 'react';
import { BiLogOut } from 'react-icons/bi';
import { HiOutlineUserCircle } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { logOut } from '../../../api/nav';
import Logo from '../../../component/whitelable/Logo';
import { updateHeaderOption } from '../../../redux/reducers/header-reducer';
import {
  redirectUserForAuthentication,
  removeToken,
} from '../../../utility/utility';
import ToggleIcon from '../../assets/layout/burger_menu.svg';
import StatusBanner from '../../shared/component/StatusBanner';

const Header = ({ toggleSidebar, menuVisible }) => {
  const history = useHistory();
  const location = useLocation();
  const { user_id, isMutualFundInScope } = useSelector(
    (state) => state.authReducer
  );
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const dispatch = useDispatch();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const redirectToProfile = () => {
    setOpen(false);
    if (windowDimensions?.width < 992) {
      if (!menuVisible) {
        toggleSidebar();
      }
      history.push('/userprofile');
    } else {
      history.push('/profile/detail');
    }
  };

  const logout = () => {
    dispatch(
      updateHeaderOption({
        showProfileIcon: false,
        showPages: false,
        showCart: false,
        showProfile: false,
      })
    );
    logOut(user_id)
      .then(() => {
        localStorage.removeItem('panData');
        localStorage.removeItem('uD');
        localStorage.removeItem('lD');
        removeToken();
        redirectUserForAuthentication();
      })
      .finally(() => {
        localStorage.removeItem('panData');
        localStorage.removeItem('uD');
        localStorage.removeItem('lD');
        removeToken();
        redirectUserForAuthentication();
      });
  };

  return (
    <>
      <div className={'header'}>
        <div className={'header_content'}>
          {menuVisible && (
            <div className={'header_menu_toggle'} style={{ zIndex: '1' }}>
              <img src={ToggleIcon} alt={'menu'} onClick={toggleSidebar} />
            </div>
          )}
          <div className={'header_app_icon'}>
            <Logo />
          </div>
          {isMutualFundInScope &&
            windowDimensions.width > 991 &&
            location.pathname.includes('/home') && (
              <div
                style={{
                  position: 'absolute',
                  width: '99vw',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <StatusBanner />
              </div>
            )}
          {isMutualFundInScope &&
            windowDimensions.width > 991 &&
            location.pathname.includes('kyc') && (
              <div
                style={{
                  position: 'absolute',
                  width: '99vw',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <StatusBanner />
              </div>
            )}
          <div
            className={'header-other-menu'}
            style={{ zIndex: '1', position: 'absolute', right: '10px' }}
          >
            <div
              className={'header-icon-profile'}
              ref={anchorRef}
              onClick={handleToggle}
            >
              <HiOutlineUserCircle />
            </div>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              placement='bottom-start'
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom-start' ? 'left top' : 'left bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id='composition-menu'
                        aria-labelledby='composition-button'
                        onKeyDown={handleListKeyDown}
                        className={'profile-menu'}
                      >
                        {isMutualFundInScope && (
                          <MenuItem
                            selected={false}
                            onClick={redirectToProfile}
                          >
                            <HiOutlineUserCircle /> Profile
                          </MenuItem>
                        )}
                        <MenuItem onClick={logout}>
                          <BiLogOut /> Logout
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
