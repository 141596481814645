import React from 'react';
import { isValidElementType } from 'react-is';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { updateHeaderOption } from '../redux/reducers/header-reducer';
import Toast from '../common/toast/Toast';

const ProtectedRouter = ({ component: Component, location, isKycVerified, token, ...rest }) => {
  console.log('rest', rest, location);
  const dispatch = useDispatch();
  Toast.destroy();
  const renderer = (props) => {
    if (location) {
      if (location.pathname === '/panstatus') {
        dispatch(updateHeaderOption({ showProfileIcon: true, showPages: true, showCart: true, showProfile: true }));
      } else if (location.pathname === '/boarding') {
        dispatch(updateHeaderOption({ showPages: false, showCart: false, showProfile: false }));
      } else if (location.pathname === '/' && !isKycVerified) {
        dispatch(updateHeaderOption({ showProfileIcon: true, showPages: false, showCart: false, showProfile: false }));
      } else if (!token) {
        dispatch(updateHeaderOption({ showProfileIcon: false, showPages: false, showCart: false, showProfile: false }));
      } else {
        dispatch(updateHeaderOption({ showCart: true, showPages: true, showProfile: true, showIcon: true, showProfileIcon: true }));
      }
    }
    return token ? <Component {...props} /> : <Redirect to={{ pathname: window.location.pathname, state: { from: location } }} />;
  };

  return <Route {...rest} render={renderer} />;
};
ProtectedRouter.propTypes = {
  location: PropTypes.object,
  authed: PropTypes.string,
  token: PropTypes.string,
  component: (props, propName) => {
    if (props[propName] && !isValidElementType(props[propName])) {
      return new Error(`Invalid prop 'component' supplied to 'Route': the prop is not a valid React component`);
    }
  },
};
export default ProtectedRouter;
