import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updateFatcaData } from '../../api/kyc';
import { addNominee, updateNominee } from '../../api/nominee';
import { InputBox, SelectBox } from '../../common/form';
import { AnnualIncome } from '../../common/on-boarding';
import {
  nomineeNameRegex,
  otherRelationRegex,
  placeofbirthRegex,
} from '../../utility/constant';
import { getInvestorsStatus } from '../../utility/investorStatus';
import { TOKEN_KEY, wealthSourceList } from '../../utility/utility';
import NominationField from '../nomination-field';

const OnBoardingIndivisuals = ({
  fatcaData,
  history,
  onSubmit,
  onFatcaSubmitted,
  errors,
  setErrors,
  handleErrorsBank,
}) => {
  const userId = useSelector((state) => state.authReducer.user_id);
  const dispatch = useDispatch();
  const [annualIncome, setAnnualIncome] = useState('');
  const [placeOfBirth, setPlace] = useState('');
  const [wealthSource, setWealthSource] = useState('');
  const [birthCountry, setBirthCountry] = useState('India');
  const [placeOfBirthErr, setPlaceOfBirthErr] = useState('');
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [checked, setChecked] = useState(false);
  const [notPoliticallyExposedCheck, setNotPoliticallyExposedCheck] =
    useState(false);
  const [mobileDeclaration, setMobileDeclaration] = useState(false);
  const [emailDeclaration, setEmailDeclaration] = useState(false);
  const [declarationError, setDeclarationError] = useState();
  const [politicallyExposedError, setPoliticallyExposedError] = useState();
  const [nomineeDetails, setNomineeDetails] = useState();
  const [nomineeErrors, setNomineeErrors] = useState([]);
  const [commonNomineeError, setCommonNomineeError] = useState();
  const [, setFatcaSubmited] = useState(false);
  const [nomineeSubmited, setNomineeSubmited] = useState(false);
  const [, setDaveDraft] = useState(false);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    setfatcaData(fatcaData);
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [fatcaData]);

  const setfatcaData = (data) => {
    setAnnualIncome(data.income_slab);
    setPlace(data.place_of_birth);
    setWealthSource(data.wealth_src);
    setNotPoliticallyExposedCheck(!data.is_entity_politically_exposed_person);
    if (
      data.mobile_number &&
      data.email_declaration &&
      data.mobile_declaration
    ) {
      setChecked(true);
      setMobileDeclaration(true);
      setEmailDeclaration(true);
    }
    if (!data.mobile_number && data.email_declaration) {
      setChecked(true);
      setEmailDeclaration(true);
    }
  };
  const onBirthPlaceChange = (event) => {
    let inputValue = event.target.value || '',
      placeErr = '';
    if (!inputValue) {
      setPlace('');
    } else if (!placeofbirthRegex.test(inputValue)) {
      placeErr =
        'Please enter a Place of birth without spaces or characters like("-", "&", "@", etc)';
    } else {
      placeErr = '';
      setPlace(inputValue);
    }
    setPlaceOfBirthErr(placeErr);
  };

  const saveFormData = () => {
    setDaveDraft(true);
    if (validateNomineeDetails()) {
      const formData = new FormData();
      formData.append('income_slab', annualIncome);
      formData.append('place_of_birth', placeOfBirth);
      formData.append('country_of_birth', birthCountry);
      if (wealthSource !== undefined || !wealthSource) {
        formData.append('wealth_src', wealthSource);
      }
      formData.append('mobile_declaration', mobileDeclaration);
      formData.append('email_declaration', emailDeclaration);
      formData.append('is_draft', true);
      formData.append(
        'is_entity_politically_exposed_person',
        !notPoliticallyExposedCheck
      );
      updateFatcaData(formData).then((res) => {
        if (res.data.status.code === 1) {
          history.push('/home');
        }
      });
      onSubmit(true);
      localStorage.setItem('saveAsDraft', true);
      localStorage.setItem('emailDeclaration', emailDeclaration);
      saveNomineeDetails();
    }
  };
  const submitFormData = () => {
    setDaveDraft(false);
    let isNomineeValid = validateNomineeDetails(true);
    if (validateData() && isNomineeValid) {
      const formData = new FormData();
      formData.append('income_slab', annualIncome);
      formData.append('place_of_birth', placeOfBirth);
      formData.append('country_of_birth', birthCountry);
      formData.append('wealth_src', wealthSource);
      formData.append('is_submit', true);
      formData.append('is_draft', false);
      formData.append('mobile_declaration', mobileDeclaration);
      formData.append('email_declaration', emailDeclaration);
      formData.append(
        'is_entity_politically_exposed_person',
        !notPoliticallyExposedCheck
      );
      if (!notPoliticallyExposedCheck) {
        setPoliticallyExposedError(
          'Please confirm you are not politically exposed'
        );
      }
      if (!emailDeclaration) {
        setDeclarationError(
          `Please confirm your Email ${
            fatcaData.mobile_number ? 'and Mobile ' : ''
          }Declaration.`
        );
      }
      if (emailDeclaration && notPoliticallyExposedCheck) {
        updateFatcaData(formData).then((res) => {
          if (res?.data?.status?.code === 1) {
            // history.push('/home');
            onFatcaSubmitted();
            setFatcaSubmited(true);
          }
        });
        onSubmit();
        localStorage.setItem('saveAsDraft', false);
        localStorage.setItem('emailDeclaration', false);

        submitNomineeDetails();
      }
    }
  };
  const validateData = () => {
    let errors = [];

    if (!wealthSource || wealthSource.length === 0) {
      errors['wealthSource'] = 'Wealth Source is required.';
    }
    if (!birthCountry || birthCountry.length === 0) {
      errors['birthCountry'] = 'Country of Birth is required.';
    }
    if (!placeOfBirth || placeOfBirth.length === 0) {
      errors['placeOfBirth'] = 'Place of birth is required.';
      setPlaceOfBirthErr('Place of Birth is required.');
    }
    setErrors(errors);
    let status = handleErrorsBank();
    if ((errors && Object.keys(errors).length > 0) || !status) {
      return false;
    }
    return true;
  };

  const validateNomineeDetails = (isSubmit) => {
    let isValid = true;
    nomineeDetails &&
      nomineeDetails.length > 0 &&
      nomineeDetails.forEach((item, index) => {
        nomineeErrors[index] = {};
        if (item.name) {
          let name = item.name?.trim();
          if (!item.relation) {
            isValid = false;
            nomineeErrors[index].relation = 'Relation is required';
          }
          if (!item.share) {
            isValid = false;
            nomineeErrors[index].share = 'Share is required';
          }
          if (item.share && parseInt(item.share) === 0) {
            isValid = false;
            nomineeErrors[index].share = 'Share must be valid Number';
          }
          if (!name.match(nomineeNameRegex)) {
            isValid = false;
            nomineeErrors[index].name =
              'Please ensure that the nominee name consists only of alphabets and spaces.';
          }
          if (name?.length !== 0 && (name?.length > 40 || name?.length < 3)) {
            isValid = false;
            nomineeErrors[index].name =
              'Nominee name not allowed more than 40 character and less than 3 character';
          }
          if (name?.length === 0 && isSubmit) {
            isValid = false;
            nomineeErrors[index].name = 'Nominee name is required';
          }
          if (item.others && !item.otherRelation) {
            isValid = false;
            nomineeErrors[index].otherRelation =
              'Please specify the relationship';
          }
          if (
            item.otherRelation &&
            !item.otherRelation.match(otherRelationRegex)
          ) {
            isValid = false;
            nomineeErrors[index].otherRelation =
              'Please ensure that the relationship consists only of alphabets and spaces.';
          }
        } else {
          if (isSubmit) {
            isValid = false;
            nomineeErrors[index].name = 'Nominee name is required';
          }
          if (item.relation || item.share) {
            isValid = false;
            nomineeErrors[index].name = 'Nominee name is required';
            if (!item.relation) {
              nomineeErrors[index].relation = 'Relation is required';
            }
            if (!item.share) {
              nomineeErrors[index].share = 'Share is required';
            }
          }
          if (item.others && !item.otherRelation) {
            isValid = false;
            nomineeErrors[index].otherRelation =
              'Please specify the relationship';
          }
          if (
            item.otherRelation &&
            !item.otherRelation.match(otherRelationRegex)
          ) {
            isValid = false;
            nomineeErrors[index].otherRelation =
              'Please ensure that the relationship consists only of alphabets and spaces.';
          }
        }
      });
    setNomineeErrors([...nomineeErrors]);
    if (getNomineeTotal() > 100) {
      setCommonNomineeError(
        'Total of share for all nominee should be not be greater than 100%'
      );
      isValid = false;
    }
    if (isSubmit && getNomineeTotal() < 100) {
      setCommonNomineeError('Total of share for all nominee should be 100%');
      isValid = false;
    }
    return isValid;
  };

  const checkboxHandleChange = (event) => {
    const { checked } = event.target;
    setChecked(checked);
    if (checked) {
      setEmailDeclaration(true);
      if (fatcaData.mobile_number) {
        setMobileDeclaration(true);
      }
      setDeclarationError(undefined);
    } else {
      setEmailDeclaration(false);
      setMobileDeclaration(false);
    }
  };

  const handleWealthSourceChange = (value) => {
    setWealthSource(value);
    errors['wealthSource'] = '';
    setErrors(errors);
  };

  const politicallyExposedChange = (event) => {
    const { checked } = event.target;
    setNotPoliticallyExposedCheck(checked);
    if (checked) {
      setPoliticallyExposedError(undefined);
    }
  };

  const handleNomineeDetailChange = (data) => {
    setNomineeDetails([...data]);
    nomineeErrors.forEach((err, index) => {
      if (err && err.relation) {
        if (data[index].relation) {
          err.relation = undefined;
        }
      }
      if (err && err.share) {
        if (data[index].share) {
          err.share = undefined;
        }
      }
      if (err && err.name) {
        if (data[index]?.name?.trim().match(nomineeNameRegex)) {
          err.name = undefined;
        }
      }
      if (err && err.otherRelation) {
        if (data[index]?.otherRelation?.trim().match(otherRelationRegex)) {
          err.otherRelation = undefined;
        }
      }
    });
    setCommonNomineeError(undefined);
    setNomineeErrors([...nomineeErrors]);
  };

  const submitNomineeDetails = () => {
    let shareCount = 0;
    nomineeDetails.forEach((item) => {
      shareCount = shareCount + item.share;
    });
    if (shareCount < 100) {
      setCommonNomineeError('Total of share for all nominee should be 100%');
    } else {
      saveNomineeDetails();
    }
  };

  const getNomineeTotal = () => {
    let shareCount = 0;
    nomineeDetails.forEach((item) => {
      shareCount = shareCount + parseInt(item.share);
    });
    return shareCount;
  };

  const saveNomineeDetails = () => {
    let shareCount = 0;
    nomineeDetails.forEach((item) => {
      shareCount = shareCount + parseInt(item.share);
    });
    if (shareCount > 100) {
      setCommonNomineeError(
        'Total of share for all nominee should be not be greater than 100%'
      );
    } else {
      let addNomineeData = [];
      let updateNomineeData = [];
      let flag = false;
      for (let i in nomineeDetails) {
        if (nomineeDetails[i].id) {
          flag = true;
        } else {
          let data = {};
          if (!flag && nomineeDetails[i].name) {
            data = {
              name: nomineeDetails[i].name.trim(),
              relation: nomineeDetails[i].relation,
              share: parseInt(nomineeDetails[i].share),
              minor: false,
            };
            if (nomineeDetails[i].others) {
              data['other_relation'] = nomineeDetails[i].otherRelation.trim();
            }
            addNomineeData.push(data);
          }
        }
      }
      if (flag) {
        updateNomineeData = nomineeDetails.map((item, i) => {
          const data = {
            name: item.name.trim(),
            relation: item.relation,
            share: parseInt(item.share),
            minor: false,
          };
          if (item.others) {
            data['other_relation'] = nomineeDetails[i].otherRelation.trim();
          }
          return data;
        });
      }
      if (updateNomineeData.length > 0) {
        updateNomineeDetails(updateNomineeData);
      } else if (addNomineeData.length > 0) {
        addNomineeDetails(addNomineeData);
      }
    }
  };

  const updateNomineeDetails = (data) => {
    if (data) {
      let request = {
        nominees: data,
      };
      updateNominee(userId, request, localStorage.getItem(TOKEN_KEY)).then(
        (response) => {
          setNomineeSubmited(true);
          getInvestorsStatus(userId, dispatch);
        }
      );
    } else {
      setNomineeSubmited(true);
      getInvestorsStatus(userId, dispatch);
    }
  };

  const addNomineeDetails = (data) => {
    let request = {
      nominees: data,
    };
    addNominee(userId, request, localStorage.getItem(TOKEN_KEY)).then(
      (response) => {
        setNomineeSubmited(true);
        getInvestorsStatus(userId, dispatch);
      }
    );
  };

  return (
    <section className='col-sm-12'>
      <h6 className='font-weight-bold mt-4'>Details for FATCA</h6>
      {windowDimensions.width > 480 ? (
        <>
          <div className='border p-4 rounded w-100 my-3'>
            <SelectBox
              title='Wealth Source'
              value={wealthSource}
              id='WealthSource'
              classes={{ root: 'col-sm-3 px-0 ' }}
              onUpdate={handleWealthSourceChange}
              options={wealthSourceList}
              placeholder='Select wealth source'
              err={errors && errors['wealthSource']}
            />
          </div>
          <AnnualIncome value={annualIncome} onChange={setAnnualIncome} />
          <div className='border p-4 rounded w-100 my-3'>
            <Form.Group className={''}>
              <Form.Label
                className={'opacity-50 mb-1 opacity-75 h6'}
                srOnly={false}
              >
                Place of Birth
              </Form.Label>
              <Form.Control
                id='PlaceofBirth'
                className='col-sm-5'
                onChange={onBirthPlaceChange}
                value={placeOfBirth}
                type='text'
                maxLength={40}
                data-cy='placeOfBirth'
                err={placeOfBirthErr}
              />

              {placeOfBirthErr && (
                <div className={'invalid-feedback d-block ml-0'}>
                  {placeOfBirthErr}
                </div>
              )}
            </Form.Group>
          </div>
          <div className='border p-4 rounded w-100 my-3'>
            <NominationField
              nomineeDetailChange={handleNomineeDetailChange}
              errors={nomineeErrors}
              commonError={commonNomineeError}
              nomineeSubmited={nomineeSubmited}
            />
          </div>
          <div className='border p-4 rounded w-100 my-3'>
            <SelectBox
              title='Country of Birth'
              value={birthCountry}
              id='countryBirth'
              classes={{ root: 'col-sm-3 px-0' }}
              onUpdate={setBirthCountry}
              options={[{ label: 'India', value: 'India' }]}
              placeholder='Select country of birth'
              err={errors && errors['birthCountry']}
            />
          </div>
          <div className='border p-4 rounded w-100 my-3'>
            <Form.Check>
              <Form.Check.Input
                id='declaration'
                type='checkbox'
                checked={checked}
                onChange={checkboxHandleChange}
                style={{ cursor: 'pointer' }}
              />
              <Form.Check.Label
                for='declaration'
                onChange={checkboxHandleChange}
                style={{ cursor: 'pointer' }}
              >
                {fatcaData.mobile_number
                  ? 'I confirm that mobile number ' +
                    fatcaData.mobile_number +
                    ' and email ' +
                    fatcaData.email +
                    ' belong to ' +
                    fatcaData.pan_name
                  : 'I confirm that the email id ' +
                    fatcaData.email +
                    ' belong to ' +
                    fatcaData.pan_name}
              </Form.Check.Label>
            </Form.Check>
            {declarationError && (
              <div className={'invalid-feedback d-block mt-2'}>
                {declarationError}
              </div>
            )}
            <Form.Check className={'mt-3'}>
              <Form.Check.Input
                id='politicallyExposed'
                type='checkbox'
                checked={notPoliticallyExposedCheck}
                onChange={politicallyExposedChange}
                style={{ cursor: 'pointer' }}
              />
              <Form.Check.Label
                for='politicallyExposed'
                onChange={politicallyExposedChange}
                style={{ cursor: 'pointer' }}
              >
                {'I confirm I am not politically exposed'}
              </Form.Check.Label>
            </Form.Check>
            {politicallyExposedError && (
              <div className={'invalid-feedback d-block mt-2'}>
                {politicallyExposedError}
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className='pb-2 rounded w-100 my-3'>
            <SelectBox
              title='Wealth Source'
              value={wealthSource}
              id='WealthSource'
              classes={{ root: 'col-sm-3 px-0 ' }}
              onUpdate={setWealthSource}
              options={wealthSourceList}
              placeholder='Select wealth source'
              err={errors && errors['wealthSource']}
            />
          </div>
          <AnnualIncome value={annualIncome} onChange={setAnnualIncome} />
          <div className='rounded w-100 my-3'>
            <InputBox
              id='PlaceofBirth'
              className='col-sm-5'
              labelClass='opacity-75 h6'
              onChange={onBirthPlaceChange}
              title='Place of Birth'
              value={placeOfBirth}
              type='text'
              maxLength={40}
              err={placeOfBirthErr}
              data-cy='placeOfBirth'
              required
            />
          </div>
          <div className='py-2 rounded w-100'>
            <NominationField
              nomineeDetailChange={handleNomineeDetailChange}
              errors={nomineeErrors}
              commonError={commonNomineeError}
              nomineeSubmited={nomineeSubmited}
            />
          </div>
          <div className='py-2 rounded w-100 '>
            <SelectBox
              title='Country of Birth'
              value={birthCountry}
              id='countryBirth'
              classes={{ root: 'col-sm-3 px-0' }}
              onUpdate={setBirthCountry}
              options={[{ label: 'India', value: 'India' }]}
              placeholder='Select country of birth'
              err={errors && errors['birthCountry']}
            />
          </div>
          <div className='py-2 rounded w-100 '>
            <Form.Check>
              <Form.Check.Input
                id='declaration'
                type='checkbox'
                checked={checked}
                onChange={checkboxHandleChange}
                style={{ cursor: 'pointer' }}
              />
              <Form.Check.Label
                for='declaration'
                onChange={checkboxHandleChange}
                style={{ cursor: 'pointer' }}
              >
                {fatcaData.mobile_number
                  ? 'I confirm that mobile number ' +
                    fatcaData.mobile_number +
                    ' and email ' +
                    fatcaData.email +
                    ' belong to ' +
                    fatcaData.pan_name
                  : 'I confirm that the email id ' +
                    fatcaData.email +
                    ' belong to ' +
                    fatcaData.pan_name}
              </Form.Check.Label>
            </Form.Check>
            {declarationError && (
              <div className={'invalid-feedback d-block mt-2'}>
                {declarationError}
              </div>
            )}
            <Form.Check className={'mt-2'}>
              <Form.Check.Input
                id='politicallyExposed'
                type='checkbox'
                checked={notPoliticallyExposedCheck}
                onChange={politicallyExposedChange}
                style={{ cursor: 'pointer' }}
              />
              <Form.Check.Label
                for='politicallyExposed'
                onChange={politicallyExposedChange}
                style={{ cursor: 'pointer' }}
              >
                {'I confirm I am not politically exposed'}
              </Form.Check.Label>
            </Form.Check>
            {politicallyExposedError && (
              <div className={'invalid-feedback d-block mt-2'}>
                {politicallyExposedError}
              </div>
            )}
          </div>
        </>
      )}

      <div className=' btn-container text-center my-4  col-sm-12'>
        <button
          type='button'
          data-cy='saveDraftOnBoarding'
          className='btn btn-outline-primary m-2'
          onClick={saveFormData}
        >
          Save As Draft
        </button>
        <button
          type='button'
          data-cy='submitOnBoarding'
          className='btn btn-primary m-2 btn-width'
          onClick={submitFormData}
        >
          Submit
        </button>
      </div>
    </section>
  );
};
export default OnBoardingIndivisuals;
