import ReactHighcharts from 'react-highcharts';
import './DonutPieChart.scss';
export interface ChartDataItem {
  name: string;
  y: number;
  color: string;
}

interface Props {
  data: ChartDataItem[] | undefined;
  className?: string;
}


export const DonutPieChart = ({ data, className = '' }: Props) => {
  const Placeholder_data_chart = [
    {
      name: 'No Data',
      y: 100,
      color: data && data?.length === 0 ? '#cacaca80' : '#FFA4B5',
    },
  ];
  const chartData = data && data?.length > 0 ? data : Placeholder_data_chart;
  return (
    <div className={`d-flex align-items-center DonutPieChart ${className}`}>
      <div className='DonutPieChart__chart d-flex align-items-center justify-content-center'>
        <ReactHighcharts
          isPureConfig
          config={{
            responsive: {
              rules: [
                {
                  condition: {
                    minWidth: 216,
                  },
                  chartOptions: {
                    chart: {
                      width: 175,
                      height: 175,
                    },
                  },
                },
                {
                  condition: {
                    minWidth: 160,
                  },
                  chartOptions: {
                    chart: {
                      width: 150,
                      height: 150,
                    },
                  },
                },
              ],
            },
            chart: {
              animation: false,
              type: 'pie',
            },
            title: {
              text: '',
            },
            tooltip: {
              enabled: false,
            },
            plotOptions: {
              pie: {
                innerSize: '50%',
                dataLabels: {
                  enabled: false,
                },
                colors: chartData?.map((d) => d.color),
              },
            },
            lang: {
              noData: 'No data to display',
            },
            noData: {
              style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030',
              },
            },
            series: [
              {
                name: 'Properties',
                data: chartData,
              } as any,
            ],
          }}
        ></ReactHighcharts>
      </div>
      <div className='flex-grow-1 DonutPieChart__legend pr-2'>
        {chartData.map((dataPoint, index) => {
          return (
            <div key={index} className='d-flex DonutPieChart__legendItem'>
              <div
                style={{
                  paddingTop: 6,
                }}
              >
                <div
                  className='rounded-circle'
                  style={{
                    width: 8,
                    height: 8,
                    marginRight: 10,
                    backgroundColor: dataPoint.color,
                  }}
                />
              </div>
              <div>
                <div className='DonutPieChart__label'>{dataPoint.name}</div>
                {dataPoint.name !== 'No Data' && (
                  <div className='font-weight-bold'>{dataPoint.y}%</div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
