import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './header';
import Sidebar from './sidebar';

const LayoutWrapper = ({ ...props }) => {
  const [sidebarCollapse, setSidebarCollapse] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => {
    setSidebarCollapse(!sidebarCollapse);
  };

  useEffect(() => {
    const dim = getWindowDimensions();
    if (dim.width < 992) {
      setSidebarCollapse(true);
    }
  }, []);
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  return (
    <>
      {location.pathname.includes('/onboarding1') ||
      location.pathname.includes('/panstatus1') ? (
        <div className={'app-v2 pt-5'}>
          <Header toggleSidebar={toggleSidebar} menuVisible={false} />
          <div className={'mt-5'}>{props.children}</div>
        </div>
      ) : (
        <div className={'app-v2'}>
          <Header toggleSidebar={toggleSidebar} menuVisible={sidebarCollapse} />
          <div className={'d-flex'}>
            <Sidebar
              isCollapsed={sidebarCollapse}
              toggleSidebar={toggleSidebar}
            />
            <div
              className={`app-container ${
                sidebarCollapse ? 'sidebar_collapsed' : ''
              }`}
            >
              {props.children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LayoutWrapper;
