import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
const SelectFolioModal = ({ show, onSubmit, folioList }) => {
  const [folioNo, setFolioNo] = useState('');
  return (
    <Modal show={show} size="lg" aria-labelledby="select-bank-modal" centered backdrop="static" keyboard={false}>
      <Modal.Header className="border-bottom d-block text-center">
        <h5 id="select-bank-modal" className="font-weight-medium mt-3">
          Choose Your Folio For
        </h5>
        <h6 className="font-weight-medium mt-2">Tata Asset Management Limited Direct Plan</h6>
      </Modal.Header>
      <Modal.Body className="d-flex  modal-body align-items-center flex-column">
        <div className="my-3 font-weight-medium opacity-75">Select Folio Number</div>
        <div className="border folio-selector px-4 py-3">
          {folioList &&
            folioList.length &&
            folioList.map((value) => (
              <div className="folio-check-con form-check mb-3" key={value}>
                <input
                  className="form-check-input mr-4"
                  type="radio"
                  name="folio-radio"
                  id={value}
                  onChange={() => {
                    setFolioNo(value);
                  }}
                />
                <label className={'form-check-label'} htmlFor={value}>
                  {value}
                </label>
              </div>
            ))}
        </div>
      </Modal.Body>
      <div className="btn-container text-center mb-3 p-3">
        <Button
          variant="outline-primary"
          onClick={() => {
            onSubmit(folioNo);
          }}
          className="">
          Continue
        </Button>
      </div>
    </Modal>
  );
};
SelectFolioModal.propTypes = {
  show: PropTypes.bool,
  onSubmit: PropTypes.func,
  folioList: PropTypes.array,
};
export default SelectFolioModal;
