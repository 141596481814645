import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import './custom-modal.scss';
import { formatInput } from '../utility/utility';
import ShowInstructionsModal from './ShowInstructionsModal';

const SelectPaymentModeModal = ({ show, onSubmit, accountObj, accountNoChange, upi, utrNumber, closeModal }) => {
  const [mode, setMode] = useState('DIRECT');
  const [paymentInfo, setPaymentInfo] = useState('');
  const [instructions, setInstructions] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const updatePaymentMode = (event) => {
    const { value } = event.target;
    setMode(value.trim());
    if (value === 'UPI') {
      setPaymentInfo(upi);
    } else if (value === 'NEFT/RTGS') {
      setPaymentInfo(utrNumber);
    } else {
      setPaymentInfo('');
    }
  };
  const updatePaymentInfo = (event) => {
    const { id, value } = event.target;
    if (id === 'upi-address') {
      setPaymentInfo(value);
    } else {
      const curVal = value?.split('-')?.join('');
      setPaymentInfo(curVal?.match(/.{1,3}/g)?.join('-'));
    }
  };
  const getShortAccountNo = (accountNo) => {
    return accountNo?.substring(accountNo?.length - 4, accountNo?.length);
  };
  const clearPaymentInfo = () => {
    setPaymentInfo('');
  };
  const showInstructions = () => {
    setInstructions(true)
  }
  const onCloseInstructions = () => {
    setInstructions(false)
  }
  return (
    <>
      <Modal
        show={instructions ? false : show}
        onHide={() => closeModal('payment')}
        size="md"
        className={windowDimensions.width > 480 ? null : 'p-4'}
        aria-labelledby="select-bank-modal"
        centered
        backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton>
          <h6 id="select-payment-mode-modal" className="mt-3 ml-2 font-weight-medium">
            Select payment mode
          </h6>
        </Modal.Header>
        <Modal.Body className="pb-4 pl-4 pr-4 pt-1">
          <div className="payment-mode form-check mb-3">
            <input
              className="form-check-input mr-4"
              type="radio"
              checked={mode === 'DIRECT'}
              name="payment-mode"
              id="netBanking"
              value="DIRECT"
              onChange={updatePaymentMode}
            />
            <label className={'form-check-label ' + (mode === 'DIRECT' ? 'c-blue' : null)} htmlFor="netBanking">
              Netbanking
            </label>
          </div>
          <div className="payment-mode  form-check  mb-3">
            <input
              className="form-check-input mr-4"
              type="radio"
              checked={mode === 'UPI'}
              name="payment-mode"
              id="UPI"
              value="UPI"
              onChange={updatePaymentMode}
            />
            <label className={'form-check-label ' + (mode === 'UPI' ? 'c-blue' : null)} htmlFor="UPI">
              UPI
            </label>
            {mode === 'UPI' ? (
              <div className="upi-section">
                <div className="form-group">
                  <label htmlFor="upi-address" className="opacity-75 small c-blue">
                    Enter your UPI ID
                  </label>
                  <div className="buttonIn">
                    <input
                      type="text"
                      className="form-control font-weight-bold small"
                      id="upi-address"
                      placeholder="abc123@okicici"
                      value={paymentInfo}
                      onChange={updatePaymentInfo}
                    />
                    <button id="clearButton" className="clear-btn btn btn-outline-secondary small" onClick={clearPaymentInfo}>
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="payment-mode form-check  mb-3">
            <input
              className="form-check-input mr-4"
              checked={mode === 'NEFT/RTGS'}
              type="radio"
              name="payment-mode"
              id="NEFT-RTGS"
              value="NEFT/RTGS"
              onChange={updatePaymentMode}
            />
            <label className={'form-check-label ' + (mode === 'NEFT/RTGS' ? 'c-blue' : null)} htmlFor="NEFT-RTGS">
              NEFT/RTGS
            </label>
            {mode === 'NEFT/RTGS' ? (
              <div className="NEFT/RTGS-section">
                <div className="accountList form-check small py-2 border-bottom mb-2s pb-3">
                  <input
                    className="form-check-input mr-4"
                    value={accountObj.account_number}
                    checked={true}
                    type="radio"
                    name="bank-account-no"
                    readOnly={true}
                    id={'account-' + accountObj.id}
                  />
                  <label className="form-check-label c-blue" htmlFor={'account-' + accountObj.id}>
                    {(accountObj.branch__bank_id__bank_name || accountObj.bank_name) + ' •••• ' + getShortAccountNo(accountObj.account_number)}
                  </label>
                  <button className="btn btn-outline-secondary btn-sm float-right  mr-2" onClick={accountNoChange}>
                    Change
                  </button>
                </div>

                <p className="small mt-2">
                  <span className="c-blue mr-1 cursor-pointer" onClick={showInstructions}>
                    Please follow the instructions{' '}
                  </span>
                  <span>
                    listed in this link to initiate an NEFT/RTGS transfer and generate the UTR number. Then enter the UTR number below to proceed.
                  </span>
                </p>
                <div className="form-group">
                  <label htmlFor="upi-address" className="opacity-75 small mb-1">
                    Enter UTR Number
                  </label>
                  <input
                    type="text"
                    className="form-control updated-input font-weight-bold small pl-0"
                    id="utr-number"
                    value={paymentInfo}
                    onKeyDown={(e) => {
                      formatInput(e);
                    }}
                    onChange={updatePaymentInfo}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </Modal.Body>
        <div className="btn-container text-center mb-4 px-3">
          <Button
            onClick={() => {
              onSubmit(mode, paymentInfo);
            }}
            className="btn btn-primary w-50">
            Proceed
          </Button>
        </div>
      </Modal>

      {instructions ? <ShowInstructionsModal show={instructions} onClose={onCloseInstructions} /> : null}
    </>
  );
};
SelectPaymentModeModal.propTypes = {
  show: PropTypes.bool,
  utrNumber: PropTypes.string,
  upi: PropTypes.string,
  onSubmit: PropTypes.func,
  accountNoChange: PropTypes.func,
  accountObj: PropTypes.object,
};
export default SelectPaymentModeModal;
