import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
const CompanyCategory = ({ onChange, value, err }) => {
  const [companyCat, setData] = useState('');
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  useEffect(() => {
    setData(value);
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [value]);
  const onNameChange = (event) => {
    const { value } = event.target;
    setData(value);
    if (onChange) {
      onChange(value);
    }
  };
  return (
    <>
    {windowDimensions.width>480?(
      <div className="border p-4 rounded w-100 my-3">
      <fieldset>
        <Form.Group as="div">
          <Form.Label as="legend" className="h6 opacity-75 mb-3">
            Does your company belong to any of the below mentioned categories?
          </Form.Label>
          <Form.Check
            type="radio"
            label="FFI - Foreign Financial Institute"
            name="companyCategoryRadio"
            className="my-2"
            checked={companyCat === '01'}
            id="companyCategory1"
            onChange={onNameChange}
            value="01"
          />
          <Form.Check
            type="radio"
            label="DRNFE - Direct Reporting Non-Financial Foreign Entities"
            name="companyCategoryRadio"
            className="my-2"
            checked={companyCat === '02'}
            id="companyCategory2"
            onChange={onNameChange}
            value="02"
          />
          <Form.Check
            type="radio"
            label="Not Applicable"
            checked={companyCat === '03'}
            name="companyCategoryRadio"
            className="my-2"
            id="companyCategory4"
            onChange={onNameChange}
            value="03"
          />
        </Form.Group>
      </fieldset>
        {err && <div className={'invalid-feedback d-block'}>{err}</div>}
    </div>
    ):(
    <div className="py-2 rounded w-100 my-3">
      <fieldset>
        <Form.Group as="div">
          <Form.Label as="legend" className="h6 opacity-75 mb-3">
            Does your company belong to any of the below mentioned categories?
          </Form.Label>
          <Form.Check
            type="radio"
            label="FFI - Foreign Financial Institute"
            name="companyCategoryRadio"
            className="my-2"
            checked={companyCat === '01'}
            id="companyCategory1"
            onChange={onNameChange}
            value="01"
          />
          <Form.Check
            type="radio"
            label="DRNFE - Direct Reporting Non-Financial Foreign Entities"
            name="companyCategoryRadio"
            className="my-2"
            checked={companyCat === '02'}
            id="companyCategory2"
            onChange={onNameChange}
            value="02"
          />
          <Form.Check
            type="radio"
            label="Not Applicable"
            checked={companyCat === '03'}
            name="companyCategoryRadio"
            className="my-2"
            id="companyCategory4"
            onChange={onNameChange}
            value="03"
          />
        </Form.Group>
      </fieldset>
      {err && <div className={'invalid-feedback d-block'}>{err}</div>}
    </div>
    )}
    </>
  );
};
CompanyCategory.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};
export default CompanyCategory;
