import React, { useState, useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import uploadIcon from '../../assets/upload.svg';
import documentIcon from '../../assets/Documents.svg';
import DocUploadModal from '../../modal/DocUploadModal';
import { UploadBox } from '../form';
import SignatureModal from '../../modal/SignatureModal';
function isTouchDevice() {
  return (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0));
}
const DocumentUpload = React.forwardRef(({
  documentList,
  singleDocUpload,
  multiDocUpload,
  panStatus,
  infoMsg = '*All documents should be signed and stamped',
  title = 'Number of HUF members',
  kycData
}, ref) => {
  const [showModal, setModal] = useState(false);
  const [signModal, setSignModal] = useState(false);
  const [currentDoc, setCurrentDoc] = useState({});
  const [error] = useState('');
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const showUploadModal = () => {
    setModal(true);
  };
  const closeUploadModal = () => {
    multiDocUpload();
    setModal(false);
  };
  const onDocumnetUpload = (item, file) => {
    singleDocUpload(item, file);
  };
  const openSignModal = (docObj) => {
    setSignModal(true)
    setCurrentDoc(docObj)
  };
  const toggleSignModal = () => {
    setSignModal(!signModal)
  };
  const onSignatureSubmit = (image) => {
    singleDocUpload(currentDoc, image)
    toggleSignModal()
  };
  useImperativeHandle(ref, () => ({ validateDoc }));

  const validateDoc = (isSaveAsDraft) => {
    let errMsg;
    if(!isSaveAsDraft){

      documentList.forEach(item => {
        if(!item.is_uploaded) {
          errMsg = "Documents should not be empty.";
        }
      });
    }
    return !errMsg;
  };

  return (
    <>
    { windowDimensions.width > 480 ?(
      <div className="border">
        <div className="d-flex font-weight-bold align-items-center">
          <div className="col-sm-8 py-2  border-right">
            <span className="ml-2">Documents</span>
          </div>
          <div className="col-sm-2 py-2 border-right text-center">Upload</div>
          <div className="col-sm-2 text-center">Status</div>
        </div>
        {documentList &&
          documentList.length &&
          documentList.map((docObj) => (
            <div className="d-flex border-top align-items-center" key={docObj.name}>
              <div className="col-sm-8 py-2  border-right">
                <span className="ml-2">{docObj.name}</span>
                {docObj.icon ? <img src={documentIcon} alt="documents" className="icon ml-2" /> : null}
              </div>
              {(docObj.id === '06' && isTouchDevice() && (kycData.tax_status_tcs === '01' || kycData.tax_status_tcs === '02' || kycData.tax_status_tcs === '11')) ? <>
                  <div className="col-sm-2 py-2 border-right text-center">
                    <img src={uploadIcon} alt="upload" className="icon" onClick={() => openSignModal(docObj)}/>
                  </div>
                </> :
              <div className="col-sm-2 py-2 border-right text-center">
                {docObj.is_multiple_upload ? (
                  <img src={uploadIcon} alt="upload" className="icon" onClick={showUploadModal} />
                ) : (
                  <UploadBox
                    icon={true}
                    accept={['pdf', 'jpeg', 'jpg', 'doc', 'png']}
                    onChange={(files) => {
                      onDocumnetUpload(docObj, files);
                    }}
                  />
                )}
              </div>}
              <div className={docObj.is_uploaded ? 'col-sm-2 c-green text-center text-capitalize' : 'col-sm-2 c-red text-center text-capitalize'}>
                {docObj.is_uploaded ? 'Completed' : 'Pending'}
              </div>
            </div>
          ))}
      </div>
    ):(
      <div className="">
        {documentList &&
          documentList.length &&
          documentList.map((docObj) => (
             <>
            <div className=" border-dashed justify-content-center align-items-center bg-light-navy pb-4" key={docObj.name}>
              <div className="col-sm-8 py-2  ">
                {docObj.icon ? <img src={documentIcon} alt="documents" className="icon ml-2" /> : null}
              </div>
              {(docObj.id === '06' && isTouchDevice() && (kycData.tax_status_tcs === '01' || kycData.tax_status_tcs === '02' || kycData.tax_status_tcs === '11')) ? <>
                <div className="col-sm-2 py-2  text-center">
                  <img src={uploadIcon} alt="upload" className="icon" onClick={() => openSignModal(docObj)}/>
                </div>
                </> :
                <div className="col-sm-2 py-2  text-center">
                  {docObj.is_multiple_upload ? (
                    <img src={uploadIcon} alt="upload" className="icon" onClick={showUploadModal}/>
                  ) : (
                    <UploadBox
                      icon={true}
                      accept={['pdf', 'jpeg', 'jpg', 'doc', 'png']}
                      onChange={(files) => {
                        onDocumnetUpload(docObj, files);
                      }}
                    />
                  )}
                </div>
              }
              </div>
              <div className='my-4'>
               <span className='ml-0 font-weight-bold'>{docObj.name}</span> <span className={docObj.is_uploaded ? ' c-green text-center text-capitalize float-right' : ' float-right  c-red text-center text-capitalize'}>{docObj.is_uploaded ? 'Completed' : 'Pending'}</span>
               <hr className='custom-hrtag-onboarding-detail' />
              </div>

              </>
          ))}
      </div>
    )}
      {error && <div className={'invalid-feedback d-block'} style={{position: 'absolute'}}>{error}</div>}
      <div className="c-blue my-3 small">{panStatus ==="01"|| panStatus === "02"? null : infoMsg}</div>
      <DocUploadModal show={showModal} onSubmit={closeUploadModal} title={title} />
      <SignatureModal show={signModal} onClose={toggleSignModal} onSubmit={onSignatureSubmit}/>
    </>
  );
});
DocumentUpload.propTypes = {
  documentList: PropTypes.array,
  singleDocUpload: PropTypes.func,
  multiDocUpload: PropTypes.func,
  infoMsg: PropTypes.string,
  title: PropTypes.string,
  panStatus: PropTypes.string
};
export default DocumentUpload;
