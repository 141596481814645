import React, { useEffect, useState } from 'react';
import './MySips.scss';
import { getAllSips } from '../../api/sips';
import { decryptData } from '../../utility/DecryptHelper';
import { data } from 'browserslist';
import { formatDate } from '../../utility/utility';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/core.css';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { getBankUserMandate, stopSip } from '../../utility/sip';

const MySips = () => {
  const userId = useSelector((state) => state.authReducer.user_id);
  const [sipData, setsipData] = useState([]);
  const [callsipData, setCallSipData] = useState(false);
  const [apiData, setApiData] = useState({offset:0,limit:10});
  const [windowDimensions] = useState(getWindowDimensions());
  const [isStopSip,setIsStopApi] = useState(false)
  const [mandateData, setMandateData] = useState([]);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const handleResponse = (data) => {
    setMandateData(data);
  };
  const setStopSip = (data) => {
    if(data){
      setTimeout(() => {
        setIsStopApi((prev) => !prev);
      }, 1000);
    }
  };
  const stopSipHandler = async(item) => {
    await stopSip(item, userId, mandateData,setStopSip)
  }
     

  useEffect(() => {
    getAllSips(userId,
      apiData.limit,
      apiData.offset,
      ).then((res) => {
      if (res && res.data) {
        let data = decryptData(res.data.data);
        if (data.offset) {
          setsipData([...sipData, ...data.data]);
        } else {
          setsipData(data.data);
        }
        setApiData({ total: data.total_count,...apiData });

      }
    });
    if (userId) {
      getBankUserMandate(userId, handleResponse);
    }

  }, [callsipData,userId]);
  useEffect(() => {
    getAllSips(userId,
      apiData.limit,
      apiData.offset,
      ).then((res) => {
      if (res && res.data) {
        let data = decryptData(res.data.data);      
        setsipData(data.data);        
        setApiData({ total: data.total_count,...apiData });
      }
    });
  }, [isStopSip]);
  
  const handlePagination = () => {
    setApiData({
      ...apiData,
      offset:apiData.offset+10
    })
    setTimeout(() => {      
      setCallSipData((prev) => !prev);
    }, 1000);
  };

  return (
    <>
      {windowDimensions.width > 480 ? (
        <>
          <h5 className="font-weight-bold text-left">My SIPs</h5>
          {
            <div className="font-weight-medium py-2 text-align-center border-bottom mt-3 mx-0">
              <div className={"row"}>
                <span className="col-sm-2 mx-0 d-flex align-items-end justify-content-center ">Start date</span>
                <span className="col-sm-55 mx-0 d-flex align-items-end">Fund</span>
                <span className="col-sm-2 mx-0 text-center d-flex align-items-end justify-content-center">Status</span>
                <div className={"col-sm-25 ml-0 text-right"}>
                  <span>Amount <span style={{whiteSpace: 'nowrap'}}>(in ₹)</span></span>
                </div>
              </div>
            </div>
          }
          {sipData && sipData.length > 0 ? (
            <div className="my-sips-list position-relative">
              {sipData.map((item, index) => {
                return (
                  <div className="tx_details-row row" key={item.toString()}>
                    <div className="col-sm-2 border-right pt-3 text-center">{formatDate(new Date(item.sip_start_date))}</div>
                    <div className="fund-wrapper col-sm-55  border-right pt-2">
                      {item.trans_code === 'NEW' && (
                        <div className="display-menu">
                          <Menu
                            menuButton={
                              <MenuButton className="menu-display-icons">
                                <BsThreeDotsVertical />
                              </MenuButton>
                            }
                            transition>
                            <p className="display-menu-title-text">Do you want to stop the sip</p>
                            <MenuItem
                              className="display-menu-item"
                              onClick={() => {
                                stopSipHandler(item);
                              }}>
                              Yes
                            </MenuItem>
                            <MenuItem className="display-menu-item">No</MenuItem>
                          </Menu>
                        </div>
                      )}

                      <div className="fund-title pt-2 c-blue">{item.fund_name}</div>

                      <p className="folio-number">Folio Number: {item.folio_number}</p>

                      {item.mandate_type === 'N' ? <p className="mandate">Mandate: E-NACH - {item.bank}</p> : ''}
                      {item.mandate_type === 'X' ? <p className="mandate">Mandate: NACH - {item.bank}</p> : ''}
                    </div>
                    <div
                      className={`col-sm-2 text-center border-right pt-3 ${item.trans_code === 'NEW' ? ' status-active' : ' status-inactive'}`}>
                      {item.trans_code === 'NEW' ? 'ACTIVE' : 'STOPPED'}
                    </div>
                    <div className="col-sm-25 text-right  pt-3 pl-0 invested-amount">{item.amount.toLocaleString('en-US')}</div>
                  </div>
                );
              })}
              {apiData.total > apiData.offset + apiData.limit ? (
              <div className="pagination-container p-4 text-center">
                <Button type="button" variant="primary" id="loadMore" onClick={()=>handlePagination()}>
                  Load More
                </Button>
              </div>
              ):null}
            </div>
          ) : (
            <>
              <div className="no-data-available c-blue">No Data Available</div>
            </>
          )}
        </>
      ) : (
        <>
          {sipData &&
            sipData.length > 0 &&
            sipData.map((item, index) => {
              return (
                <div className="m-3 row colored-box" key={data.id}>
                  <div className="mobile-sip-card-wrapper col-sm-10  pt-2">
                    {item.trans_code === 'NEW' && (
                      <div className="display-menu">
                        <Menu
                          menuButton={
                            <MenuButton className="menu-display-icons">
                              <BsThreeDotsVertical />
                            </MenuButton>
                          }
                          transition>
                          <p className="display-menu-title-text">Do you want to stop the sip</p>
                          <MenuItem
                            className="display-menu-item"
                            onClick={() => {
                              stopSipHandler(item);
                            }}>
                            Yes
                          </MenuItem>
                          <MenuItem className="display-menu-item">No</MenuItem>
                        </Menu>
                      </div>
                    )}
                    <p>Date:{formatDate(new Date(item.sip_start_date))}</p>
                    <p className="fund-title pt-2  c-blue">Fund: {item.fund_name}</p>
                    <p className="folio-number">Folio Number: {item.folio_number}</p>
                    <p className="mandate">Mandate: {item.mandate_type}</p>
                    <p className={`${item.trans_code === 'NEW' ? ' status-active' : ' status-inactive'}`}>
                      Status: {item.trans_code === 'NEW' ? 'ACTIVE' : 'STOPPED'}
                    </p>
                    <p className="invested-amount">Amount (in ₹): {item.amount?.toLocaleString('en-US')} </p>
                  </div>
                </div>
              );
            })}
        </>
      )}
    </>
  );
};

export default MySips;
