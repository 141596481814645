import { client } from './client';
import application from './services/application.service';
import asset from './services/asset.service';
import holdings from './services/holdings.service';
import investors from './services/investors.service';
import issuer from './services/issuer.service';
import payment from './services/payment.service';
import permissionsService from './services/permissions.service';
import transactions from './services/transactions.service';
import UserGroups from './services/user_groups.service';
import whitelabelServices from './services/whitelabel.service';

const getMe = async () => {
  const res = await client.get('/me');
  return res.data;
};

export const fdApi = {
  issuer,
  asset,
  payment,
  transactions,
  investors,
  application,
  whitelabelServices,
  holdings,
  UserGroups,
  permissionsService,
  getMe,
};
