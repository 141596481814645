import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

const OptionsType2 = ({
  options,
  question,
  updateAnswerJson,
  errors,
  step,
  value,
}) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [amount, setAmount] = useState();

  useEffect(() => {
    if (value) {
      const optionIds = value?.options?.map((item) => parseInt(item.option_id));
      setSelectedValues(optionIds ? [...optionIds] : []);
      setAmount((value?.inputs[0]?.value?.toString()));
    }
  }, [value]);

  const handleCheckBoxChecked = (option) => {
    const values = selectedValues;
    // Spread convert string type to numbers type, that's why have to covert type
    if (values.indexOf(Number(option.option_id)) === -1) {
      values.push(Number(option.option_id));
      setSelectedValues([...values]);
    } else {
      values.splice(values.indexOf(Number(option.option_id)), 1);
      setSelectedValues([...values]);
    }
    updateAnswer(values, amount);
  };

  const handleAmountChange = (value) => {
    // const value = event.target.value;
    setAmount(value?.toString());
    updateAnswer(selectedValues, value);
  };

  const updateAnswer = (option, inputValue) => {
    const answer = {
      question_id: question.question_id?.toString(),
      options: option.map((item) => ({ option_id: item?.toString() })),
      inputs: [
        {
          name: 'amount',
          value: inputValue ? parseFloat(inputValue).toString() : undefined,
        },
      ],
    };
    updateAnswerJson(answer);
  };

  return (
    <>
      <div className={'options mt-2'}>
        <div className={'option-group type-2 d-flex flex-wrap'}>
          {options?.map((item, index) => (
            <div className={'check-box-option mt-3'}>
              <label
                className={'w-100 text-left'}
                htmlFor={`checkbox-option-${index}`}
              >
                {/*<Form.Group className="mb-0" controlId="formBasicCheckbox">*/}
                {/*  <Form.Check id={`checkbox-option-${index}`} className={'option-checkbox pt-0'} type="checkbox" label="" />*/}
                {/*</Form.Group>*/}
                <Checkbox
                  id={`checkbox-option-${index}`}
                  onChange={() => handleCheckBoxChecked(item)}
                  checked={
                    selectedValues.indexOf(Number(item.option_id)) !== -1
                  }
                />
                <div className={'option-icon'}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24.487'
                    height='25.914'
                    viewBox='0 0 24.487 25.914'
                  >
                    <path
                      id='banking_and_psu_3'
                      d='M9.568,33.789H33.32V29.709H31.787V18.4H33.32V16.029l.117-.008.239-1.032L21.443,7.875l-11.8,6.858-.457.319.319,1.01h.06V18.4H11.1V29.709H9.568Zm22.439-1.312H10.88V31.02H32.007Zm-3.439-2.768h1.908V18.4H28.568Zm-1.314,0h-11.7V18.4h11.7Zm-14.839,0h1.828V18.4H12.416ZM11.1,17.086H10.88V16.062H32.007v1.024H11.1Zm1.126-2.336H30.66L21.443,9.392Zm7.332,9.645q0,.012,2.223,2.6h1q-2.282-2.663-2.282-2.7v-.007a2.45,2.45,0,0,0,1.057-.265,1.555,1.555,0,0,0,.886-1.188h.7c.006,0,.129-.182.368-.543,0-.008-.006-.013-.019-.017H22.42a1.31,1.31,0,0,0-.284-.594H23.15a6.286,6.286,0,0,0,.377-.552v-.007H19.741c-.01,0-.133.18-.375.539v.02h1a1.833,1.833,0,0,1,.565.1,1.048,1.048,0,0,1,.6.484v.007H19.741c-.006,0-.133.182-.383.543a.015.015,0,0,0,.017.017h2.251a.661.661,0,0,1-.047.243q-.3.808-1.547.808a3.271,3.271,0,0,1-.461-.028l-.01.02Z'
                      transform='translate(-9.189 -7.875)'
                      fill='#08aeea'
                      fill-rule='evenodd'
                    />
                  </svg>
                </div>
                <div className={'option-text'}>{item.option_value}</div>
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className={'mt-3'}>
        <span className={'input-symbol'}>₹</span>
        <CurrencyInput
          id='amount-input'
          name='amount'
          className={'form-control option-input'}
          placeholder='Enter Estimated total value or Amount'
          value={amount}
          decimalsLimit={0}
          allowDecimals={false}
          min={1}
          intlConfig={{ locale: 'en-IN' }}
          allowNegativeValue={false}
          // disabled={checkAll || !switchFromFund}
          onValueChange={handleAmountChange}
        />
      </div>
      {errors && errors[step] && (
        <div className={'invalid-feedback d-block mt-4'}>{errors[step]}</div>
      )}
    </>
  );
};

export default OptionsType2;
