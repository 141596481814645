import { InputBox, RadioGroups } from '../../../common/form';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  AnnexureAExamption, AnnexureBExamption,
  CompanyCategory,
  DocumentUpload, NatureBusiness,
  NetWorth, NonFinancialCategory,
  PoliticallyExposed,
} from '../../../common/on-boarding';
import EditBankAccount from '../../../component/bank-accounts/EditBankAccount';
import { updateFatcaData, uploadSingleDocument } from '../../../api/kyc';
import Toast from '../../../common/toast/Toast';
import { decryptData } from '../../../utility/DecryptHelper';
import taxStatus from '../../../utility/taxStatus';
import {
  AnnualIncomeListNri,
  NonFinancallyListNew,
} from '../../../utility/utility';
import { getInvestorDetails, saveInvestorDetails, updateInvestorDetails } from '../../../api/onboarding.api';
import Occupation from './Occupation';

const NonIndividualOnboarding = ({kycData, setKycData}) => {;
  const history = useHistory();
  const { pan, pan_kyc_status } = useSelector((state) => state.onboardingReducer);
  const userId = useSelector((state) => state.authReducer.user_id);
  const [entityName, setEntityName] = useState();
  const [updateEntityName, setUpdateEntityName] = useState(false);
  const [fatcaUpdated, setFatcaUpdated] = useState(false);
  const [bankUpdated, setBankUpdated] = useState(false);
  const [errors, setErrors] = useState({});
  const [annualIncome, setAnnualIncome] = useState();
  const [netWorth, setNetWorth] = useState({});
  const [netWorthInr, setNetWorthInr] = useState();
  const [occupation, setOccupation] = useState();
  const [occupationCode, setOccupationCode] = useState();
  const [compCategory, setCompCategory] = useState();
  const [politicallyExposed, setPoliticallyExposed] = useState();
  const [annexureA, setAnnexureA] = useState();
  const [natureBusiness, setNatureBusiness] = useState();
  const [nonFincailCat, setNonFincailCat] = useState();
  const [annexureB, setAnnexureB] = useState();
  const [investoryType, setInvestorType] = useState();
  const [doi, setDoi] = useState();

  const childRef = useRef();
  const childRefDoc = useRef();

  useEffect(() => {
    setfatcaData(kycData);
    getKycData()
  }, [kycData]);

  const getKycData = () => {
    getInvestorDetails(userId).then(res => {
      setInvestorType(res.data.investor_type)
      setDoi(res.data.doi)
      setAnnualIncome(res.data.fatca_detail.income_slab);
    })
  }


  const handleChange = (event) => {
    setEntityName(event.target.value);
  };
  const getTitle = () => {
    if (pan_kyc_status === taxStatus.partnership || pan_kyc_status === taxStatus.llp) {
      return 'Number of Partners';
    } else if (pan_kyc_status === taxStatus.huf) {
      return 'Number of HUF members';
    } else if (pan_kyc_status === taxStatus.trust) {
      return 'Number of Trustees';
    } else if (pan_kyc_status === taxStatus.privatelimited || pan_kyc_status === taxStatus.limited || pan_kyc_status === taxStatus.bank_cooperative_bank) {
      return 'Number of Directors';
    }
  };

  const handleEntityClick = () => {
    setUpdateEntityName(true);
  };
  const uploadSingleDocuments = (docObj, files) => {
    const postObj = new FormData();
    postObj.append('doc', files);
    postObj.append('doc_type', docObj.id);
    uploadSingleDocument(postObj).then((res) => {
      if (res && res.data) {
        if (res.data.status.message && res.data.status.code === 1) {
          Toast.success(res.data.status.message);
        }
        const data = decryptData(res.data.data);
        if (data && data.data && data.data.length) {
          setKycData(data.data[0]);
        }
      }
    });
  };

  const setfatcaData = (data) => {
    // setAnnualIncome(data.income_slab);
    let netWorthYear= data?.net_worth_as_on_date?.split('-');
    setNetWorth({ value: netWorthYear?netWorthYear[0]:''});
    setNetWorthInr(data.net_worth_inr);
    setOccupation(data.occ_type);
    setOccupationCode(data.occ_code_other);
    setCompCategory(data.company_category);
    setPoliticallyExposed(data.is_entity_politically_exposed_person ? 'Yes' : 'No');
    setAnnexureA(data.exemption_code);
    setNatureBusiness(data.nature_of_bussiness);
    setNonFincailCat(data.non_financial_entity_category);
    setAnnexureB(data.non_financial_entity_sub_category);
  };
  const onPoliticallyChange = (value) => {
    setPoliticallyExposed(value);
    errors['politicallyExposed'] = undefined;
    setErrors(errors);
  };
  const saveFormData = () => {
    const formData = new FormData();
    formData.append('income_slab', annualIncome);
    formData.append('net_worth_inr', netWorthInr);
    formData.append('occ_type', occupation);
    formData.append('occ_code_other', occupationCode);
    formData.append('company_category', compCategory);
    formData.append('is_entity_politically_exposed_person', politicallyExposed === 'Yes' ? true : false);
    formData.append('exemption_code', annexureA);
    formData.append('nature_of_bussiness', natureBusiness);
    formData.append('non_financial_entity_category', nonFincailCat);
    formData.append('non_financial_entity_sub_category', annexureB);
    formData.append('full_name', entityName);
    if(netWorth.value && netWorth.value!==''){
      formData.append('net_worth_as_on_date', netWorth.value && netWorth.value!==''?`${netWorth.value}-03-31`:'');
    }
    updateFatcaData(formData).then((res) => {
      if (res.data.status.code === 1) {
        history.push('/home');
      }
    });
    let req = {
      "investor_type": investoryType,
      "name": kycData?.full_name,
      "pan": pan,
      "email": kycData?.email,
      "mobile": kycData?.email,
      "doi": doi,
      "fatca_detail": {
        "income_slab": annualIncome,
        "net_worth_as_on_date": netWorth.value,
        "net_worth": netWorthInr,
        "occupation": occupation,
        "occupation_other": occupationCode,
        "ffi_drnfe": compCategory,
        // "giin_no": "",
        "politically_exposed_entity": politicallyExposed === 'Yes' ? true : false,
        "exemption_code": annexureA,
        "nature_of_business": natureBusiness,
        "non_financial_entity": nonFincailCat,
        "non_financial_sub_category": annexureB
      }
    }

    saveInvestorDetails(userId, req).then(response => {
      history.push('/home');
    }).catch(error => {
      console.error(error)
    })


    onSubmit();
  };

  const submitFormData = () => {
    if (validate()) {
      const formData = new FormData();
      formData.append('income_slab', annualIncome);
      formData.append('net_worth_inr', netWorthInr);
      formData.append('occ_type', occupation);
      formData.append('occ_code_other', occupationCode);
      formData.append('company_category', compCategory);
      formData.append('is_entity_politically_exposed_person', politicallyExposed === 'Yes' ? true : false);
      formData.append('exemption_code', annexureA);
      formData.append('nature_of_bussiness', natureBusiness);
      formData.append('non_financial_entity_category', nonFincailCat);
      formData.append('non_financial_entity_sub_category', annexureB);
      formData.append('full_name', entityName);
      formData.append('is_submit', true);
      if(netWorth.value && netWorth.value!==''){
        formData.append('net_worth_as_on_date', netWorth.value && netWorth.value!==''?`${netWorth.value}-03-31`:'');
      }
      // updateFatcaData(formData).then((res) => {
      //   if (res.data.status.code === 1) {
      //     // history.push('/home');
      //     setFatcaUpdated(true);
      //   }
      // });

      let req = {
        "investor_type": investoryType,
        "name": kycData?.full_name,
        "pan": pan,
        "email": kycData?.email,
        "mobile": kycData?.email,
        "doi": doi,
        "fatca_detail": {
          "income_slab": annualIncome,
          "net_worth_as_on_date": netWorth.value,
          "net_worth": netWorthInr,
          "occupation": occupation,
          "occupation_other": occupationCode,
          "ffi_drnfe": compCategory,
          // "giin_no": "",
          "politically_exposed_entity": politicallyExposed === 'Yes' ? true : false,
          "exemption_code": annexureA,
          "nature_of_business": natureBusiness,
          "non_financial_entity": nonFincailCat,
          "non_financial_sub_category": annexureB
        }
      }

      updateInvestorDetails(userId, req).then(response => {
        setFatcaUpdated(true);
      }).catch(error => {
        console.error(error)
      })
      onSubmit();
    }
  };

  const validate = () => {
    let err = [];
    if (!netWorth || !netWorth?.value || !netWorthInr || netWorthInr?.length === 0) {
      err['netWorth'] = 'Net Worth should not be empty.';
    }
    if (!occupation || occupation?.length === 0) {
      err['occupation'] = 'Please select Occupation.';
    } else if (occupation === 'O' && (!occupationCode || occupationCode.length === 0)) {
      err['occupation'] = 'Occupation should not be empty';
    }
    if (!compCategory || compCategory?.length === 0) {
      err['compCategory'] = 'Please select Company Category.';
    }
    if (!politicallyExposed || !politicallyExposed.value) {
      err['politicallyExposed'] = 'Please select your answer.';
    }
    if (!annexureA || annexureA.length === 0) {
      err['annexureA'] = 'Annexure A should not be empty;';
    }
    if (!natureBusiness || natureBusiness.length === 0) {
      err['natureBusiness'] = 'Nature of Business should not be empty;';
    }
    if (!nonFincailCat || nonFincailCat.length === 0) {
      err['nonFincailCat'] = 'Financialshould not be empty;';
    }
    if (!annexureB || annexureB.length === 0) {
      err['annexureB'] = 'Annexure B should not be empty;';
    }

    setErrors(err);
    let status = handleErrorsBank();
    return !((err && Object.keys(err).length > 0) || !status);
  };

  const handleErrorsBank = () => {
    let status = childRef.current.validate()
    let status1 = childRefDoc.current.validateDoc()
    if(status && status1) {
      return true
    }
    return false;
  };

  useEffect(() => {
    if (netWorth?.value && netWorthInr?.length > 0) {
      errors['netWorth'] = undefined;
      setErrors(errors);
    }
  }, [netWorth, netWorthInr]);

  useEffect(() => {
    if (occupation?.length > 0) {
      if (occupation === 'O') {
        if (occupationCode?.length > 0) {
          errors['occupation'] = undefined;
          setErrors(errors);
        }
      } else {
        errors['occupation'] = undefined;
        setErrors(errors);
      }
    }
  }, [occupation, occupationCode]);

  const handleOccupationChange = (value) => {
    setOccupation(value);
    if (value !== 'O') {
      errors['occupation'] = undefined;
      setErrors(errors);
    }
  };

  const handleAnnexureAChange = (value) => {
    setAnnexureA(value);
    errors['annexureA'] = undefined;
    setErrors(errors);
  };

  const handleAnnexureBChange = (value) => {
    setAnnexureB(value);
    errors['annexureB'] = undefined;
    setErrors(errors);
  };

  const handleNatureOfBusinessChange = (value) => {
    setNatureBusiness(value);
    errors['natureBusiness'] = undefined;
    setErrors(errors);
  };

  const handleNonFinCategoryChange = (value) => {
    setNonFincailCat(value);
    errors['nonFincailCat'] = undefined;
    setErrors(errors);
  };

  const handleCompCategoryChange = (value) => {
    setCompCategory(value);
    errors['compCategory'] = undefined;
    setErrors(errors);
  };

  const onSubmit = () => {
    if (kycData.bank_details.id) {
      childRef.current.updateNewBank();
    } else {
      childRef.current.addNewBank();
    }
  };

  const multiDocUpload = () => {
    // getUserKycData();
  };

  const onBankAdd = () => {
    // getUserKycData();
  };

  const onBankSubmitted = () => {
    setBankUpdated(true);
  };

  useEffect(() => {
    if (fatcaUpdated && bankUpdated) {
      history.push('/home');
    }
  }, [fatcaUpdated, bankUpdated]);

  const onRadioChange = (event) => {
    const { value } = event.target;
    setAnnualIncome(value);
  };

  return (
    <>
      <div className="col-sm-12">
        <InputBox
          id="onboarding-name"
          title={pan_kyc_status === taxStatus.proprietorship
                ? 'Name of the Proprietor'
                : 'Name of the Entity'
          }
          value={kycData.pan_name}
          disabled
        />
      </div>
      {kycData.tax_status_tcs === taxStatus.proprietorship ? (
        <div className="col-sm-12">
          {updateEntityName ? (
            <InputBox
              id="onboarding-proprietor"
              title={'Name of the Entity'}
              placeholder="Enter the Entity name"
              value={entityName}
              onChange={handleChange}
            />
          ) : (
            <InputBox
              id="onboarding-proprietor"
              title={'Name of the Entity'}
              placeholder="Enter the entity name"
              value={kycData.full_name}
              onClick={handleEntityClick}
            />
          )}
        </div>
      ) : null}
      <div className="col-sm-12 mt-3">
        <InputBox
          id="onboarding-address"
          title={pan_kyc_status === taxStatus.individual || pan_kyc_status ===taxStatus.minor ? 'Address' : 'Address of the entity'}
          value={kycData.address}
          disabled
        />
      </div>
      <div className="col-sm-12 mt-3">
        <h6 className="font-weight-bold my-4">Documents</h6>
        <DocumentUpload
          kycData={kycData}
          documentList={kycData.document_list}
          panStatus={pan_kyc_status}
          singleDocUpload={uploadSingleDocuments}
          multiDocUpload={multiDocUpload}
          title={getTitle()}
          ref={childRefDoc}
        />
      </div>
      <section className="col-sm-12">
        <h6 className="font-weight-bold my-4">Bank Details</h6>
        <div className="border p-4 rounded">
          <EditBankAccount
            alignment="center"
            ref={childRef}
            buttonText="Submit"
            panStatus={pan_kyc_status}
            bankDetail={kycData.bank_details}
            onBankAdd={onBankAdd}
            onBankSubmitted={onBankSubmitted}
            onboarding={true}
          />
        </div>
      </section>
      <section className="col-sm-12">
        <h6 className="font-weight-bold mt-4">Details for FATCA</h6>
        <div className="border p-4 rounded w-100 my-3">
          <RadioGroups
            title="Annual Income"
            id="annualIncome"
            radioList={AnnualIncomeListNri}
            selectedVal={annualIncome}
            onChange={onRadioChange}
            err={errors && errors['annualIncome']}
          />
        </div>
        <NetWorth
          networthObj={netWorth}
          amount={netWorthInr}
          onNetWorthChange={(event, data) => {
            setNetWorth(data);
          }}
          onNetWorthInrChange={setNetWorthInr}
          err={errors && errors['netWorth']}
        />
        <Occupation
          value={occupation}
          otherValue={occupationCode}
          onRadioChange={handleOccupationChange}
          onInputChange={setOccupationCode}
          err={errors && errors['occupation']}
        />
        <CompanyCategory value={compCategory} onChange={handleCompCategoryChange} err={errors && errors['compCategory']} />
        <PoliticallyExposed onChange={onPoliticallyChange} err={errors && errors['politicallyExposed']} />
        <AnnexureAExamption value={annexureA} onChange={handleAnnexureAChange} err={errors && errors['annexureA']} />
        <NatureBusiness
          value={natureBusiness}
          onChange={handleNatureOfBusinessChange}
          infoIcon={false}
          infoMsg="Ex: Manufacturing, Trading, Service Industry"
          err={errors && errors['natureBusiness']}
        />
        <NonFinancialCategory options={NonFinancallyListNew} value={nonFincailCat} onChange={handleNonFinCategoryChange} err={errors && errors['nonFincailCat']} />
        <AnnexureBExamption value={annexureB} onChange={handleAnnexureBChange} err={errors && errors['annexureB']} />
        <div className="btn-container text-center my-4 col-sm-12">
          <button type="button" className="btn btn-outline-primary m-2" onClick={saveFormData}>
            Save As Draft
          </button>
          <button type="button" className="btn btn-primary m-2 btn-width" onClick={submitFormData}>
            Submit
          </button>
        </div>
      </section>
    </>
  )
};

export default NonIndividualOnboarding;