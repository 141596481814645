/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getInvestData } from '../../api/nav';
import { decryptData } from '../../utility/DecryptHelper';
import MobileNavigation from '../../common/footermobile/MobileNavigation';
const InvestPage = ({ history }) => {
  const [investPageData, setInvestData] = useState({});
  const redirectLinks = (event, path, title) => {
    event.preventDefault();
    history.push('/topfunds/' + title);
  };
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  useEffect(() => {
    getInvestData().then((res) => {
      if (res && res.data) {
        const investData = decryptData(res.data.data);
        setInvestData(investData);
      }
    });
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <>
      {windowDimensions.width > 480 ? (
        <div className="container">
          <div className="row">
            {investPageData &&
              investPageData.categories &&
              investPageData.categories.length &&
              investPageData.categories.map((link) => 
              {
                if (link.display_on_site) {
                  return (
                    <div className="col-sm-3 py-2 cursor-pointer d-grid" key={link.id}>
                      <section
                        className="invest-section p-3"
                        onClick={(e) => {
                          redirectLinks(e, link.redirectUrl, link.name);
                        }}>
                        <div className="img-container">
                          <img src={link.display_image} alt="" />
                        </div>
                        <h5>{link.name}</h5>
                      </section>
                    </div>
                  );
                }
              })}
          </div>
          <div className="btn-container my-4 text-center">
            <Link className="btn btn-primary" to="/funds/all">
              Explore All Funds
            </Link>
          </div>
        </div>
      ) : (
        <div className="container pl-3 bg-light-grey p-0 pt-3">
          <h3 className="font-weight-medium text-center">Investment</h3>
          <div className="row " id="invest-row">
            {investPageData &&
              investPageData.categories &&
              investPageData.categories.length &&
              investPageData.categories.map((link) => {
                if (link.display_on_site) {
                  return (
                    <div className="w-40 col-sm-6 py-2 cursor-pointer " key={link.id}>
                      <section
                        className="br-10 bg-light-blue text-center p-3 "
                        onClick={(e) => {
                          redirectLinks(e, link.redirectUrl, link.name);
                        }}>
                        <div className="img-container">
                          <img src={link.display_image} alt="" />
                        </div>
                        <h6 className="font-weight-medium invest-font">{link.name}</h6>
                      </section>
                    </div>
                  );
                }
              })}
          </div>
          <div className="text-center py-4">
            <Link className="btn btn-primary" to="/funds/all">
              Explore All Funds
            </Link>
          </div>
          <MobileNavigation />
        </div>
      )}
    </>
  );
};
InvestPage.propTypes = {
  history: PropTypes.object,
};
export default InvestPage;
