import { client } from '../client';
import { UserGroupsInterface } from './user_groups.service';

export interface UsersOut {
  pan: string;
  id: string;
  email: string;
  mobile: string;
  username: string;
  user_group: UserGroupsInterface[];
}

export interface UpdateInvestorsPayloadType {
  group_ids: String[];
}

export interface GrantPermissionsInterface {
  id: string;
  user_group_ids: string[];
}

export interface GrantedPermissionsInterface {
  [key: string]: GrantPermissionsInterface[];
}

const getInvestors = async (
  search_text: string | null
): Promise<UsersOut[]> => {
  let endpoint_url = '/users';
  if (search_text) {
    endpoint_url += `?search_text=${search_text}`;
  }
  const res = await client.get(endpoint_url);
  return res.data;
};

const updateInvestor = async (
  user_id: string,
  input: UpdateInvestorsPayloadType
) => {
  const res = await client.patch(`/users/${user_id}`, input);
  return res.data;
};

export const getGrantedPermissions = async (user_id: string): Promise<any> => {
  const res = await client.get(`/users/${user_id}/permissions`);
  return res.data;
};

export const getGroupsByUserId = async (user_id: string) => {
  const res = await client.get(`/users/${user_id}/groups`);
  return res.data;
};

export const postPermissionsToBeGranted = async (
  user_id: string,
  input: GrantPermissionsInterface
) => {
  const res = await client.post(`/users/${user_id}/permissions`, input);
  return res.data;
};



const api = {
  getInvestors,
  getGrantedPermissions,
  getGroupsByUserId,
  updateInvestor,
  postPermissionsToBeGranted,
};

export default api; 
