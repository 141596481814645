import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { formatNumber, formatPrice, regexString } from '../../utility/utility';
import AddFundIcon from '../../assets/add.svg';
import RedeemFundIcon from '../../assets/redeem.svg';
import './protfolio-list.scss';
import { Button } from 'react-bootstrap';
const PortfolioFundList = ({ fundId, fundName, folioList, addFundFunc, minAmountNew, minAmountCurrent, returnType, reedemFundFunc, isRegular, exitLoad, fundData }) => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  return (
    <>
    { windowDimensions.width > 480 ? (
      <div className="protfolio-items py-4" id={'protfolio-item-' + fundId}>
      <Link to={{pathname:`/fund/detail/${regexString(fundName)}/${fundId}`}} className="protfolio-item-header mb-3 h5 c-blue">
        {fundName}
      </Link>
      {folioList && folioList.length
        ? folioList.map((item) => (
            <div className="row protfolio-item-list mb-3" key={'fund-detail-' + item.folio_id}>
              <div className="col-sm-6">
                <span>Folio No: {item.folio_no}</span>
              </div>
              <div className="col-sm-2 text-right">
                <span>{formatPrice(formatNumber(item.total_investment, 2))}</span>
              </div>
              <div className="col-sm-2 text-right">
                <span>{formatPrice(formatNumber(item.current_value, 2))}</span>
              </div>
              <div className="col-sm-2 text-right">
                <span className="mr-3">{parseFloat(item[returnType]).toFixed(2)}%</span>
              </div>
            </div>
          ))
        : null}
      <div className="protfolio-item-action d-flex">
        <a
          href="www.elevo.money"
          className="d-flex align-items-center"
          onClick={(e) => {
            addFundFunc(e, fundId, folioList, minAmountNew, minAmountCurrent, isRegular, fundData);
          }}>
          <img src={AddFundIcon} alt="add fund" className="icon" />
          <span className="ml-2">Add</span>
        </a>
        <a
          href="www.elevo.money"
          className="ml-4 d-flex align-items-center"
          onClick={(e) => {
            reedemFundFunc(e, fundId, folioList, isRegular, exitLoad);
          }}>
          <img src={RedeemFundIcon} alt="redeem fund" className="icon" />
          <span className="ml-2">Redeem</span>
        </a>
      </div>
    </div>
    ) : (
      <div className='bg-light-grey py-1' id="wrapper" >
      <div className="colored-box m-4  p-2 py-3" id={'protfolio-item-' + fundId}>
      <div className='d-block d-md-flex '>
        <div className='ml-3'>
      <Link to={{pathname:`/fund/detail/${regexString(fundName)}/${fundId}`}} className="protfolio-item-header mb-3 h5 c-black">
        {fundName}
      </Link>
      </div>
      <div className="d-flex mt-2 ml-3 m-md-0">
       <span>
        <Button
          variant="outline-secondary"
          className="btn py-1 br-light ml-1"
          onClick={(e) => {
            reedemFundFunc(e, fundId, folioList, isRegular, exitLoad);
          }}>
         Redeem
        </Button>
       </span>
       <span>
        <Button
          variant="success"
          className="btn py-1 br-light ml-2"
          onClick={(e) => {
            addFundFunc(e, fundId, folioList, minAmountNew, minAmountCurrent, isRegular, fundData);
          }}>
         +Add
        </Button>
        </span>
      </div>
      </div>
      <hr className='w-90'></hr>
      {folioList && folioList.length
        ? folioList.map((item) => (
          <div className={"mb-4"}>
              <div className="col-sm-6">
                <span className='opacity-50 ml-0'>Folio No: </span>
                <span className='font-weight-bold ml-0'>{item.folio_no}</span>
              </div>
              <div className='d-flex col-sm-12 px-0 text-center mt-1'>
              <div className="col-sm-4 px-0">
                <div className='opacity-50 small'>Total Invested</div>
                <div className='font-weight-bold '>{formatPrice(formatNumber(item.total_investment, 2))}</div>
              </div>
              <div className=" col-sm-4 px-0">
                <div className='opacity-50 small'>Current Value</div>
                <div className='font-weight-bold '>{formatPrice(formatNumber(item.current_value, 2))}</div>
              </div>
              <div className=" col-sm-4 px-0">
                <div className='opacity-50 small'>Returns (%)</div>
                <div className='font-weight-bold '>{parseFloat(item[returnType]).toFixed(2)}%</div>
              </div>
              </div>
              </div>
          ))
        : null}
    </div>
    </div>
     )}
    </>
  );
};
PortfolioFundList.propTypes = {
  returnType: PropTypes.string,
  fundName: PropTypes.string,
  minAmountNew: PropTypes.number,
  minAmountCurrent: PropTypes.number,
  fundId: PropTypes.number,
  folioList: PropTypes.array,
  exitLoad: PropTypes.array,
  addFundFunc: PropTypes.func,
  reedemFundFunc: PropTypes.func,
  isRegular: PropTypes.bool
};
export default PortfolioFundList;
