import { ApiRes } from '../../types';
import { client } from '../fdApi/client';

export interface CreateEkycRedirectionUrlInput {
  name: string;
  pan: string;
  email: string;
  mobile: string;
  callback_url: string;
}

export interface EkycRedirectionLinkRes {
  web_redirect_url: string;
  mobile_redirect_url: string;
}

const getEkycRedirectionUrl = (): ApiRes<EkycRedirectionLinkRes> =>
  client.get('/ekyc');

const createEkycRedirectionUrl = (
  input: CreateEkycRedirectionUrlInput,
  userId: string
): ApiRes<EkycRedirectionLinkRes> =>
  client.post('/ekyc', { ...input, user_id: userId });

const kycApi = {
  getEkycRedirectionUrl,
  createEkycRedirectionUrl,
};

export default kycApi;
