import { elevoApiClient } from './elevo_apis';

interface UploadCASPayload {
  file: File;
  password: string;
}

interface UploadCASRes {
  cas_id: string;
}

// Error will handle at the component level for this particular API
const customErrorHandler = () => {};

export const uploadCasApi = (
  investorId: string,
  payload: UploadCASPayload
): Promise<UploadCASRes> => {
  const formData = new FormData();
  formData.append('file', payload.file);
  formData.append('password', payload.password);

  return elevoApiClient(customErrorHandler).post(
    `/investors/${investorId}/cas`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};
