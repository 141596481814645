import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AuthState } from '../../../redux/reducers/auth-reducer';

// Icons and Stylesheet
import ExternalFundTransfer from '../../../assets/images/ExternalFundTransfer.svg';
import './ExternalFunds.scss';

// Components
import { DefaultTile } from './DefaultTile';
import { UploadCASTile } from './UploadCASTile';
import { NotReadyToInvestModal } from './NotReadyToInvestModal';

const paramsToComponentMapping: {
  [key: string]: JSX.Element;
} = {
  'upload-cas': <UploadCASTile />,
};

type ExternalFundParams = {
  step?: string;
};

export const ExternalFunds = () => {
  const params = useParams<ExternalFundParams>();
  const authState: AuthState = useSelector((state: any) => state.authReducer);

  const computedTile: JSX.Element = params.step ? (
    paramsToComponentMapping[params.step]
  ) : (
    <DefaultTile />
  );

  if (!authState.ready_to_invest) {
    return <NotReadyToInvestModal />;
  }

  return (
    <>
      <div className={'ExternalFunds__section_title'}>
        <span>Dashboard&nbsp;|&nbsp;</span> <span>Import External Funds</span>
      </div>
      <div className={'row external-fund-card'}>
        <div className='col-md-6 col-lg-6 col-sm-12 col-xs-12 order-md-first order-lg-first order-last p-0 external-fund-dynamic-tile'>
          {/* Inject Section Dynamically based on route params */}
          {computedTile}
        </div>
        <div
          className={'col-md-6 col-lg-6 col-sm-12 col-xs-12 external-fund-info'}
        >
          <div className={'external-fund-info-details'}>
            <div className={'external-fund-info-icon'}>
              <img src={ExternalFundTransfer} alt='Import External Funds' />
            </div>
            <div className={'external-fund-info-title'}>
              Invested elsewhere in mutual funds ?
            </div>
            <div className={'external-fund-info-description'}>
              Import all your mutual fund investments on Elevo to track, redeem
              and switch at one place.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
