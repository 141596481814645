

 const SidebarLoginData = [
   {
     title: 'Dashboard',
     path: '/',
     cName: 'nav-text',
   },
   {
     title: 'Investment',
     path: '/invest',
     cName: 'nav-text',
   },
   {
     title: 'Portfolio',
     path: '/portfolio',
     cName: 'nav-text',
   },
   {
     title: 'Cart',
     path: '/order/summary',
     cName: 'nav-text',
   },
   {
     title: 'Transactions',
     path: '/transactionsmobile',
     cName: 'nav-text',
   },
   {
     title: 'My Profile',
     path: '/userprofile',
     cName: 'nav-text',
   },
 ];
  
  export default SidebarLoginData;