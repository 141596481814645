import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
const ShowInstructionsModal = ({ show ,onClose }) => {
  return (
    <Modal show={show} size="lg" aria-labelledby="confirmation-modal" onHide={onClose} centered backdrop="static" keyboard={false} className="">
        <Modal.Header  closeButton className="justify-content-center m-0 p-0 mr-3 mt-1">
        </Modal.Header>
      <Modal.Body className="text-align-justify p-4 ml-3">
        <span className="h5 text-center mb-4">NEFT/RTGS Details</span>
        <div className="opacity-50 mt-3">
        <p className="">Add the ICCL account in your bank as a beneficiary for NEFT/RTGS payments. </p>   
        <p className="">Upon setting up ICCL as the beneficiary, you can place your order and make the payment by following the steps below. </p>     
        <ul>
          <li>Login to your bank and initiate your NEFT/RTGS transfer to ICCL for the total amount; this will give you a Unique Transaction Reference (UTR) number. </li>
          <li>On the payment page, enter the UTR number in the specified field. </li>
        </ul>
        </div>
        <span className=" h5 text-center">Beneficiary Details that need to be added for the NEFT/RTGS transaction. </span>
        <div className="d-flex opacity-50 mt-3">
           <div>
               <p>Beneficiary Name:</p>
               <p>Account Type:</p>
               <p>Account Number:</p>
               <p>Bank Name:</p>
               <p>IFSC Code:</p>
               <p>Branch Details:</p>
           </div>
           <div className="ml-4">
               <p>Indian Clearing Corporation Limited</p>
               <p>Current</p>
               <p>3922</p>
               <p>ICICI Bank</p>
               <p>ICIC0000104</p>
               <p>CMS Branch, Lower Parel, Mumbai</p>
           </div>
        </div>
        <p className="c-red">Please do not transfer using IMPS, UPI, or other methods. Only NEFT/RTGS transactions are accepted. </p>
        <p className=" h5 ">Note.</p>
        <div className="opacity-50">
        <p>Users who use an ICICI Bank Account: Even though Indian Clearing Corporation Limited also has an account with ICICI Bank, you will need to use the Other Bank Payee mode in order to register ICCL as a Payee. Please DO NOT use the ICICI Bank Payee option on the Internet Banking facility. </p>
        <p>Please add ICCL as a Payee under the Other Bank Payee mode (not under ICICI Bank Payee mode). </p>
        <p>Steps to Follow: Login Navigate to Payments  Transfers  Click on Manage Payees  Add Payee  Other Bank Payee  Enter Details. </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};
ShowInstructionsModal.propTypes = {
  message: PropTypes.string,
  header: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  fundName: PropTypes.string,
};
export default ShowInstructionsModal;
