import { PropsWithChildren } from 'react';

export const FolioCell = ({
  label,
  children,
  className = '',
}: PropsWithChildren<{
  label: string | number;
  className?: string;
}>) => {
  return (
    <div className={`fw-500 d-flex align-items-center flex-wrap ${className}`}>
      <span className='PortfolioMutualFunds__gray'>{label}&nbsp;:</span>
      {/* &nbsp; */}
      {children}
    </div>
  );
};
