import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatNumber, regexString } from '../../utility/utility';
import {Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DataMsg from '../../common/data-msg/DataMsg';
const InvestFundList = ({ invesrFundItems, loadMore, sortBy }) => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  return (
    <>
      {windowDimensions.width > 480 ? (
        <>
          {invesrFundItems && invesrFundItems.length ? (
            invesrFundItems.map((fundItem) => (
              <Link
                to={{
                  pathname: `/fund/detail/${regexString(fundItem.name)}/${
                    fundItem.id
                  }`,
                }}
                className='fund-section border-top py-3 d-flex'
                key={'invest-fund-list-' + fundItem.id}
              >
                <div className='invest-fund-img-sec col-sm-1'>
                  <img className='w-50px' src={fundItem.logo} alt={'img'} />
                </div>
                <div className=' col-sm-11  text-center'>
                  <div className='row'>
                    <div className='col-sm-7 font-weight-bold h6 text-left'>
                      {fundItem.name}
                    </div>
                    <div className='col-sm-2 font-weight-bold px-1'>
                      {fundItem.dp_day_end_nav
                        ? '₹' + formatNumber(fundItem.dp_day_end_nav, 2)
                        : 'NA'}
                    </div>
                    <div className='col-sm-1 px-1'>
                      {fundItem.ttr_return_6_mth !== 0
                        ? formatNumber(fundItem.ttr_return_6_mth) + '%'
                        : 'NA'}
                    </div>
                    <div className='col-sm-1 px-1'>
                      {fundItem.ttr_return_1_yr !== 0
                        ? formatNumber(fundItem.ttr_return_1_yr, 2) + '%'
                        : 'NA'}
                    </div>
                    <div className='col-sm-1 px-1'>
                      {fundItem.ttr_return_3_yr !== 0
                        ? formatNumber(fundItem.ttr_return_3_yr, 2) + '%'
                        : 'NA'}
                    </div>
                  </div>
                  <div className='row small mt-2 opacity-50'>
                    <div className='col-sm-7 text-left text-capitalize'>
                      {fundItem.risk_level} |{' '}
                      {fundItem.scheme_type.toLowerCase()}
                    </div>
                    <div className='col-sm-2 px-1'>NAV</div>
                    <div className='col-sm-1 px-1'>6 M</div>
                    <div className='col-sm-1 px-1'>1 Y</div>
                    <div className='col-sm-1 px-1'>3 Y</div>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <DataMsg message='No Fund Available' />
          )}
        </>
      ) : (
        <>
          <div className='mx-2 py-3'>
            {invesrFundItems && invesrFundItems.length ? (
              invesrFundItems.map((fundItem) => (
                <div className='' key={'invest-fund-list-' + fundItem.id}>
                  <Link
                    to={{
                      pathname: `/fund/detail/${regexString(fundItem.name)}/${
                        fundItem.id
                      }`,
                    }}
                    className='fund-section border-top py-3 '
                    key={'invest-fund-list-' + fundItem.id}
                  >
                    <div className='d-flex my-4 col-sm-12 '>
                      <div className='invest-fund-img-sec '>
                        <img
                          className='w-50px'
                          src={fundItem.logo}
                          alt={'img'}
                        />
                      </div>
                      <div className='mx-2'>
                        <div className='font-weight-medium  h6 text-left'>
                          {fundItem.name}
                        </div>
                        <div className=' text-capitalize  opacity-50'>
                          {fundItem.risk_level} |{' '}
                          {fundItem.scheme_type.toLowerCase()}
                        </div>
                      </div>
                      <div className='float-right font-weight-bold px-1 '>
                        {sortBy === 'ttr_return_3_yr' &&
                          (fundItem.ttr_return_3_yr !== 0
                            ? formatNumber(fundItem.ttr_return_3_yr, 2) + '%'
                            : 'NA')}
                        {sortBy === 'ttr_return_1_yr' &&
                          (fundItem.ttr_return_1_yr !== 0
                            ? formatNumber(fundItem.ttr_return_1_yr, 2) + '%'
                            : 'NA')}
                        {sortBy === 'ttr_return_6_mth' &&
                          (fundItem.ttr_return_6_mth !== 0
                            ? formatNumber(fundItem.ttr_return_6_mth, 2) + '%'
                            : 'NA')}
                        {/* {fundItem.dp_day_end_nav ? "₹"+formatNumber(fundItem.dp_day_end_nav, 2) : "NA"} */}
                      </div>
                    </div>
                  </Link>
                  <hr className='mx-3'></hr>
                </div>
              ))
            ) : (
              <DataMsg message='No Fund Available' />
            )}
          </div>
        </>
      )}
      {loadMore ? (
        <div className='pagination-container  p-4 text-center'>
          <Button
            type='button'
            variant='primary'
            className={windowDimensions.width > 480 ? null : 'w-80 '}
            id='loadMore'
            onClick={loadMore}
          >
            Load More
          </Button>
        </div>
      ) : null}
    </>
  );
};
InvestFundList.propTypes = {
  invesrFundItems: PropTypes.array,
  loadMore: PropTypes.func
};
export default InvestFundList;
