import * as Sentry from '@sentry/react';
import axios from 'axios';

import Toast from '../common/toast/Toast';
import { getInfoWithKey } from '../page/store/LocalStorageInfoManager';
import {
  TOKEN_KEY,
  abortController,
  getLoader,
  redirectUserForAuthentication,
  removeLoader,
  removeToken,
} from '../utility/utility';
import { API_ERROR_MESSAGES } from '../utility/constant';

const fetchClient = (version) => {
  const headers = { 'api-key': atob(process.env.REACT_APP_API_KEY) };
  const instance = axios.create({
    baseURL: process.env.REACT_APP_TARRAKKI_LEGACY_API_URL + version,
    headers: headers,
    signal: abortController.signal,
  });
  // Add a request interceptor
  instance.interceptors.request.use((config) => {
    document.body.appendChild(getLoader());
    const uD = getInfoWithKey('uD');
    const newToken = localStorage.getItem(TOKEN_KEY);
    if (newToken) {
      config.headers.Authorization = `Bearer ${newToken}`;
    }
    const token = uD && uD.data ? uD.data?.token : null;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  // Add a response interceptor
  instance.interceptors.response.use(
    (res) => {
      removeLoader();
      if (res && res.data && res.data.status) {
        if (res.data.status.message && res.data.status.code === 0) {
          if (!res.config.url.includes('/tcs/tax-status')) {
            Toast.error(res.data.status.message);
          }
        }
      }
      return res;
    },
    (error) => {
      removeLoader();
      const statusCode = error?.response?.status;
      if (statusCode !== 401) {
        Sentry.withScope(function (scope) {
          scope.setLevel('error');
          scope.setFingerprint([`http_status:${statusCode}`]);
          scope.setTransactionName(
            `Request failed with status code ${statusCode}`
          );
          Sentry.captureException({
            error: error,
            response: error?.response,
            status_message: error?.response?.data,
          });
        });
      }
      if (!abortController.signal.aborted && statusCode === 401) {
        Toast.error(API_ERROR_MESSAGES.UNAUTHORIZED_USER_ERR_MSG);
        localStorage.removeItem('uD');
        localStorage.removeItem('lD');
        removeToken();
        abortController.abort();
        redirectUserForAuthentication();
      } else if (statusCode >= 400 && statusCode <= 499) {
        if (!error.response?.data?.message) {
          Toast.error(API_ERROR_MESSAGES.CLIENT_ERROR);
        }
        if (typeof error.response.data.message === 'string') {
          Toast.error(error.response.data.message);
        } else if (
          error.response?.data?.message[0] &&
          Object.values(error.response.data.message[0])[0][0]
        ) {
          const errorObj = Object.values(error.response.data.message[0])[0][0];
          Toast.error(errorObj);
        }
      } else if (statusCode >= 500 && statusCode <= 599) {
        Toast.error(API_ERROR_MESSAGES.SERVER_ERROR);
      } else if (error.message) {
        Toast.error(error.message);
      }
      return Promise.reject(error);
    }
  );
  return instance;
};
export default fetchClient('v7');
