import { IssuerOut } from '../../api/fdApi/services/issuer.service';
import { useAppSelector } from '../../redux/hooks';
import {
  constructFdAppURL,
  get_issuer_tenure_string,
} from '../../utility/utility';
import Amount from '../Amount/Amount';
import './FDRow.scss';
interface Props {
  issuer: IssuerOut;
  isMobile: boolean;
}

export const FDRow = ({ issuer, isMobile }: Props) => {
  const { fdAppURL } = useAppSelector((state) => state.authReducer);
  const interestRateString = `${issuer.min_interest_rate}% - ${issuer.max_interest_rate}%`;
  const tenureString = get_issuer_tenure_string(issuer);

  // Fields structure for mobile screen
  const FDMobileRowFieldsJSX = (
    <>
      <div className='d-flex flex-column'>
        <span className='FDRow__fields_mobile__heading interest_rate'>
          Interest Rate
        </span>
        <span className='FDRow__fields_mobile___tenureString'>
          {interestRateString}
        </span>
      </div>
      <div className='d-flex flex-column'>
        <span className='FDRow__fields_mobile__heading'>Tenure</span>
        <span className='FDRow__fields_mobile__tenureString'>
          {tenureString}
        </span>
      </div>
      <div className='d-flex flex-column '>
        <span className='FDRow__fields_mobile__heading'>Min. Deposit</span>
        <Amount
          value={issuer.min_amount}
          options={{
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }}
        />
      </div>
      <div className='d-flex flex-column '>
        <span className='FDRow__fields_mobile__heading'>Max. Deposit</span>
        <Amount
          value={issuer.max_amount}
          options={{
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }}
        />
      </div>
    </>
  );

  // Fields structure for Larger screen
  const FDRowFieldsJSX = (
    <>
      <div className='d-flex align-items-center interest_rate'>
        {interestRateString}
      </div>
      <div className='d-flex align-items-center'>{tenureString}</div>
      <div className='d-flex align-items-center'>
        <Amount
          value={issuer.min_amount}
          options={{
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }}
        />
      </div>
      <div className='d-flex align-items-center'>
        <Amount
          value={issuer.max_amount}
          options={{
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }}
        />
      </div>
    </>
  );

  return (
    <div className='FDRow d-grid py-3 border-top px-2'>
      <div className='FDRow__name d-flex align-items-center'>
        <div className='FDRow__logo rounded-circle d-flex'>
          <img src={issuer.logo} alt={issuer.name} className='w-100' />
        </div>
        <div>{issuer.name}</div>
      </div>
      {!isMobile && FDRowFieldsJSX}

      <div className='d-flex align-items-center justify-content-end justify-md-content-center'>
        <a
          href={constructFdAppURL(`${fdAppURL}/issuers/${issuer.id}`)}
          className='FDRow__btn rounded border bg-white px-3 py-2 text-center text-sm text-blue-500 transition hover:opacity-70 d-xl-inline-block'
        >
          {isMobile ? 'Invest' : 'Invest Now'}
        </a>
      </div>
      {isMobile && (
        <div className='FDRow__fields_mobile'>{FDMobileRowFieldsJSX}</div>
      )}
    </div>
  );
};
