import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Collapse, Form, InputGroup, ProgressBar } from 'react-bootstrap';
import ReactSpeedometer from 'react-d3-speedometer';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { bankUserMandate } from '../../api/bank';
import { getFundDetail } from '../../api/fund';
import downArrowIcon from '../../assets/arrow-round-down.svg';
import upArrowIcon from '../../assets/arrow-round-up.svg';
import forwardArrowIcon from '../../assets/forward-arrow.svg';
import MobileNavigation from '../../common/footermobile/MobileNavigation';
import Toast from '../../common/toast/Toast';
import FundGraph from '../../component/funds/FundGraph';
import AddFundModal from '../../modal/AddFundModal';
import { decryptData } from '../../utility/DecryptHelper';
import {
  RiskometerList,
  formatDate,
  formatNumber,
  formatPrice,
  getReskoMeterValue,
  getRiskoMeterColor,
} from '../../utility/utility';
import { MainLoader } from '../../v2/shared/component/MainLoader';

function hasNumber(myString) {
  return /\d/.test(myString);
}

const FundDetail = ({ history }) => {
  const userId = useSelector((state) => state.authReducer.user_id);
  const { id } = useParams();
  const [curValue, setValue] = useState('');
  const [sipValue, setSipValue] = useState('');
  const [fundDetail, setFundDetail] = useState({});
  const [minAmount, setMinAmount] = useState(0);
  const [minSipAmount, setSipMinAmount] = useState(0);
  const [frequencyDates, setFrequencyDates] = useState([]);
  const [addModalData, setAddModalData] = useState({ show: false });
  const [canInvestLumpsum, setCanInvestLumpsum] = useState(false);
  const [canInvestSip, setCanInvestSip] = useState(false);
  const [showMinAmount, setShowMinAmount] = useState(false);
  const [showSipMinAmount, setShowSipMinAmount] = useState(false);
  const [primeStatus, setPrimeStatus] = useState('');
  const [showReturns, setShowReturns] = useState(false);
  const [showObjective, setShowObjective] = useState(false);
  const [showInformation, setShowInformation] = useState(false);
  const [showHoldings, setShowHoldings] = useState(false);
  const [showRisks, setShowRisks] = useState(false);
  const [mandateData, setMandateData] = useState();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [isLoadingFundDetailPage, setIsLoadingFundDetailPage] = useState(false);
  const appConfig = JSON.parse(localStorage.getItem('app-config'));
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  useEffect(() => {
    if (id) {
      setIsLoadingFundDetailPage(true);
      getFundDetail(id, userId)
        .then((res) => {
          if (res && res.data) {
            const fundData = decryptData(res.data.data);
            setFundDetail(fundData);
            setSipMinAmount(fundData?.funds_details?.iaip_aip[0]?.min_amount);
            getFrequencyDates(
              fundData?.funds_details?.iaip_aip[0]?.frequency_date
            );
            if (fundData.funds_details) {
              if (fundData.folio_list && fundData.folio_list.length) {
                setMinAmount(fundData.lumpsum_additional_min_amount);
              } else {
                setMinAmount(fundData.funds_details.pi_minimum_initial);
              }
            }
            if (
              fundData?.bse_data &&
              fundData?.bse_data?.lumpsum_allowed === 'Y'
            ) {
              setCanInvestLumpsum(true);
            } else {
              setCanInvestLumpsum(false);
            }
            if (fundData?.bse_data && fundData?.bse_data?.sip_allowed === 'Y') {
              setCanInvestSip(true);
            } else {
              setCanInvestSip(false);
            }
            if (fundData.prime_rating) {
              getPrimeStatus(fundData.prime_rating.fund_mf_review_call);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setIsLoadingFundDetailPage(false);
        });
    }
    getBankUserMandate();
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getBankUserMandate = () => {
    bankUserMandate(userId)
      .then((res) => {
        if (res && res.data) {
          const mandate = decryptData(res.data.data);
          const data = mandate.data;
          setMandateData(data);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {});
  };

  const getFrequencyDates = (dates) => {
    if (dates?.length > 0) {
      let allowedDates = [];
      if (hasNumber(dates)) {
        dates.split('|').forEach((item) => {
          allowedDates.push(parseInt(item));
        });
      } else {
        for (let i = 1; i <= 28; i++) {
          allowedDates.push(i);
        }
      }
      setFrequencyDates(allowedDates);
    } else {
      setFrequencyDates([]);
    }
  };
  const getTwoDecimalPlaceVal = (value) => {
    if (value) {
      return parseFloat(value).toFixed(2);
    }
    return 'N/A';
  };
  const getBenchmarks = (benchmarkArr) => {
    if (benchmarkArr && benchmarkArr.length) {
      return benchmarkArr.map((obj) => obj.index_name).join(', ');
    }
    return '';
  };
  const getMangersName = (mangerArr) => {
    if (mangerArr && mangerArr.length) {
      return mangerArr.map((obj) => obj.name).join(', ');
    }
    return '';
  };
  const updateCurValue = (event) => {
    const { value = '' } = event.target;
    const val = value.trim();
    if (val.length && val < minAmount) {
      setShowMinAmount(true);
    } else {
      setShowMinAmount(false);
    }
    setValue(value.trim());
  };
  const updateSipValue = (event) => {
    const { value = '' } = event.target;
    const val = value.trim();
    if (val.length && val < minSipAmount) {
      setShowSipMinAmount(true);
    } else {
      setShowSipMinAmount(false);
    }
    setSipValue(value.trim());
  };

  const addToCartHandler = (event) => {
    event.preventDefault();
    if (!curValue && showMinAmount) {
      return;
    }
    if (sipValue && (!mandateData || mandateData.length === 0)) {
      Toast.error('Kindly set up a Bank mandate to place an SIP order');
      setTimeout(() => {
        history.push('/profile/bank-list');
      }, 2000);
      return;
    }
    if (fundDetail.folio_list && fundDetail.folio_list.length) {
      setAddModalData({
        show: true,
        minAmountCurrent: fundDetail.lumpsum_additional_min_amount,
        minAmountNew: fundDetail.funds_details.pi_minimum_initial,
        minSipAmount: minSipAmount,
        fundId: fundDetail.funds_details.id,
        lumsumamount: curValue,
        sipamount: sipValue,
        folioList: fundDetail.folio_list,
      });
    } else {
      setAddModalData({
        show: true,
        minAmountCurrent: fundDetail.lumpsum_additional_min_amount,
        minAmountNew: fundDetail.funds_details.pi_minimum_initial,
        minSipAmount: minSipAmount,
        fundId: fundDetail.funds_details.id,
        lumsumamount: curValue,
        sipamount: sipValue,
        folioList: [],
      });
    }
  };
  const onAddFundPopupClose = (flag) => {
    setAddModalData({ show: false });
    if (flag) {
      history.push('/order/summary');
    }
  };

  const getFundDetailHoldings = (str) => {
    if (str) {
      return eval(str);
    } else {
      return [];
    }
  };
  const getPrimeStatus = (fundData) => {
    if (fundData === 'Buy') {
      setPrimeStatus('#58BB60');
    } else if (fundData === 'Sell') {
      setPrimeStatus('#EE2929');
    } else if (fundData === 'Hold') {
      setPrimeStatus('#EF7C40');
    } else if (fundData === 'Unrated') {
      setPrimeStatus('#F4A343');
    } else if (fundData === 'No opinion') {
      setPrimeStatus('#F4A343');
    }
  };

  return (
    <>
      {isLoadingFundDetailPage && <MainLoader />}
      {fundDetail && fundDetail.funds_details ? (
        <>
          {windowDimensions.width > 480 ? (
            <div
              className='container fund-detail-container'
              id={fundDetail.funds_details.id}
            >
              <div className='row'>
                <div className='col-sm-9'>
                  <div className='fund-detail-header'>
                    <div className='top-header row font-weight-bold'>
                      <div className='col-sm-7 h3 p-0'>
                        {fundDetail.funds_details.fscbi_legal_name}
                      </div>
                      <div className='right-section col-sm-5 p-0'>
                        <div className='d-flex align-items-baseline h3 justify-content-end funddetail m-0'>
                          <span>
                            &#8377;
                            {formatNumber(
                              fundDetail.funds_details.dp_day_end_nav,
                              2
                            )}
                          </span>
                          <img
                            src={
                              fundDetail.funds_details.one_day_return > 0
                                ? upArrowIcon
                                : downArrowIcon
                            }
                            alt='up and down arrow'
                            className={
                              'icon mx-1 ' +
                              (fundDetail.funds_details.one_day_return > 0
                                ? 'rotate-180'
                                : '')
                            }
                          />
                          <span>
                            {fundDetail.funds_details.rise_or_fall}
                            <span
                              className={
                                'ml-1 ' +
                                (fundDetail.funds_details.one_day_return > 0
                                  ? 'c-green'
                                  : 'c-red')
                              }
                            >
                              ({fundDetail.funds_details.one_day_return}%)
                            </span>
                          </span>
                        </div>
                        <div className='opacity-50 small text-right'>
                          NAV as on{' '}
                          {formatDate(
                            fundDetail.funds_details.ts_day_end_nav_date
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='sub-heading font-weight-medium'>
                      {fundDetail?.prime_rating &&
                        fundDetail?.prime_rating?.fund_prime_rating &&
                        appConfig.show_prime_ratings &&
                        fundDetail.prime_rating.fund_prime_rating !==
                          'Unrated' && (
                          <div className='d-flex align-items-center'>
                            <span className='mr-1 mt-2 ml-0'>
                              Prime Rating:
                            </span>
                            <StarRatings
                              rating={
                                fundDetail.prime_rating?.fund_prime_rating
                                  ? parseFloat(
                                      fundDetail.prime_rating.fund_prime_rating
                                    )
                                  : 0
                              }
                              starRatedColor='#ffb61b'
                              starDimension='16px'
                              starSpacing='4px'
                              numberOfStars={Math.ceil(
                                fundDetail.prime_rating?.fund_prime_rating
                              )}
                            />
                          </div>
                        )}
                      {appConfig.show_prime_ratings &&
                        fundDetail.prime_rating.fund_prime_rating ===
                          'Unrated' && (
                          <p>
                            Prime Rating:{' '}
                            <span className='ml-1 c-orange'>
                              {fundDetail.prime_rating.fund_prime_rating}
                            </span>
                          </p>
                        )}
                      {fundDetail?.prime_rating &&
                        appConfig.show_prime_ratings &&
                        fundDetail?.prime_rating?.fund_mf_review_call !==
                          'Unrated' && (
                          <p>
                            Prime Recommendation:{' '}
                            <span
                              className='ml-1 '
                              style={{ color: primeStatus }}
                            >
                              {fundDetail.prime_rating &&
                                fundDetail.prime_rating.fund_mf_review_call}
                            </span>
                          </p>
                        )}
                      {/* <p>
                     Prime Recommendation:
                     <OverlayTrigger
                       placement="right-end"
                       overlay={
                         <Popover id="popover-recomandation" style={{ maxWidth: '500px' }}>
                           <Popover.Content>Please complete your registration to unlock this feature</Popover.Content>
                         </Popover>
                       }>
                       <img className="ml-1 small-icon" src={lockIcon} alt="lock" />
                     </OverlayTrigger>
                   </p> */}
                    </div>
                  </div>
                  <div className='fund-stock-graph'>
                    <FundGraph
                      graphData={getFundDetailHoldings(
                        fundDetail.funds_details.total_return_index
                      )}
                    />
                  </div>
                  <div className='py-4 fund-objective'>
                    <h5 className='c-blue font-weight-medium mb-3'>
                      Returns(%)
                    </h5>
                    <div className='fund-return-row d-flex text-center border-bottom-0'>
                      <div className='return-item'>1 M</div>
                      <div className='return-item'>3 M</div>
                      <div className='return-item'>6 M</div>
                      <div className='return-item'>1 Y</div>
                      <div className='return-item'>3 Y</div>
                      <div className='return-item'>5 y</div>
                      <div className='return-item-2'>Since Inception</div>
                    </div>
                    <div className='fund-return-row d-flex text-center'>
                      <div className='return-item'>
                        {getTwoDecimalPlaceVal(
                          fundDetail.funds_details.ttr_return_1_mth
                        )}
                      </div>
                      <div className='return-item'>
                        {getTwoDecimalPlaceVal(
                          fundDetail.funds_details.ttr_return_3_mth
                        )}
                      </div>
                      <div className='return-item'>
                        {getTwoDecimalPlaceVal(
                          fundDetail.funds_details.ttr_return_6_mth
                        )}
                      </div>
                      <div className='return-item'>
                        {getTwoDecimalPlaceVal(
                          fundDetail.funds_details.ttr_return_1_yr
                        )}
                      </div>
                      <div className='return-item'>
                        {getTwoDecimalPlaceVal(
                          fundDetail.funds_details.ttr_return_3_yr
                        )}
                      </div>
                      <div className='return-item'>
                        {getTwoDecimalPlaceVal(
                          fundDetail.funds_details.ttr_return_5_yr
                        )}
                      </div>
                      <div className='return-item-2'>
                        {getTwoDecimalPlaceVal(
                          fundDetail.funds_details.ttr_return_since_inception
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='py-4 fund-objective'>
                    <h5 className='c-blue font-weight-medium mb-2'>
                      Fund Objective
                    </h5>
                    <p>{fundDetail.funds_details.investment_strategy}</p>
                  </div>
                  <div className='py-4 fund-key-information'>
                    <h5 className='c-blue font-weight-medium mb-3'>
                      Key Information
                    </h5>
                    <div className='info-row row'>
                      <div className='info-section col-sm-9'>
                        <div className='d-flex justify-content-between py-2'>
                          <span>AMC Name</span>
                          <span>{fundDetail.funds_details.amc_name}</span>
                        </div>
                        <div className='d-flex justify-content-between py-2'>
                          <span>Fund Type</span>
                          <span>
                            {fundDetail.funds_details.fscbi_legal_structure}
                          </span>
                        </div>
                        <div className='d-flex justify-content-between py-2'>
                          <span>Investment Plan</span>
                          <p>
                            {fundDetail.funds_details?.purchase_mode === '1'
                              ? 'REGULAR'
                              : fundDetail.funds_details?.purchase_mode === '2'
                              ? 'DIRECT'
                              : 'UNKNOWN'}
                          </p>
                        </div>
                        <div className='d-flex justify-content-between py-2'>
                          <span>Launch Date</span>
                          <span>
                            {formatDate(
                              fundDetail.funds_details.kd_performance_start_date
                            )}
                          </span>
                        </div>
                        <div className='d-flex justify-content-between py-2'>
                          <span>Benchmark</span>
                          <span>
                            {getBenchmarks(fundDetail.funds_details.benchmarks)}
                          </span>
                        </div>
                        <div className='d-flex justify-content-between py-2'>
                          <span>Assets Size (₹ cr)</span>
                          <span>
                            &#8377;{' '}
                            {formatPrice(
                              parseFloat(
                                fundDetail.funds_details.fna_aum / 10000000
                              ).toFixed(2)
                            )}{' '}
                            cr
                          </span>
                        </div>
                        <div className='d-flex justify-content-between py-2'>
                          <span>Asset Date</span>
                          <span>
                            {formatDate(
                              fundDetail.funds_details
                                .fna_surveyed_fund_net_assets_date
                            )}
                          </span>
                        </div>
                        <div className='d-flex justify-content-between py-2'>
                          <span>Minimum Investment Lumpsum</span>
                          <span>
                            &#8377;{fundDetail.funds_details.pi_minimum_initial}
                          </span>
                        </div>
                        <div className='d-flex justify-content-between py-2'>
                          <span>Minimum Additional Investment Lumpsum</span>
                          <span>
                            &#8377;
                            {fundDetail.funds_details.pi_minimum_subsequent}
                          </span>
                        </div>
                        <div className='d-flex justify-content-between py-2'>
                          <span>Expense Ratio</span>
                          <span>
                            {getTwoDecimalPlaceVal(
                              fundDetail.funds_details.interim_net_expense_ratio
                            )}
                            %
                          </span>
                        </div>
                        <div className='d-flex justify-content-between py-2'>
                          <span>Fund Manger</span>
                          <span>
                            {getMangersName(fundDetail.funds_details.managers)}
                          </span>
                        </div>
                        <div className='d-flex justify-content-between py-2'>
                          <span>Exit Load</span>
                          <span>
                            {fundDetail.funds_details.defer_loads.map(
                              (data) => {
                                if (data.value > 0) {
                                  return (
                                    <p key=''>{`${data.value} % if redeemed within ${data.high_breakpoint} ${data.breakpoint_unit}`}</p>
                                  );
                                } else {
                                  return 'NIL';
                                }
                              }
                            )}
                          </span>
                        </div>
                        <div className='d-flex justify-content-between py-2'>
                          <span>Standard Deviation (5 yr)</span>
                          <span>
                            {getTwoDecimalPlaceVal(
                              fundDetail.funds_details.standard_deviation_5_yr
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='py-4 fund-objective'>
                    <h5 className='c-blue font-weight-medium mb-2'>
                      {fundDetail.top_ten_holdings
                        ? 'Top Holdings'
                        : 'Top 10 holdings unavailable'}
                    </h5>
                    {fundDetail.top_ten_holdings &&
                      getFundDetailHoldings(fundDetail.top_ten_holdings).map(
                        (topHolder, index) => (
                          <div
                            className='d-flex justify-content-between py-2 align-items-center '
                            key={'top-holder-' + index}
                          >
                            <div className='col-sm-7'>{topHolder.Name}</div>
                            <div className='col-sm-1'>
                              {formatNumber(topHolder.Weighting, 1)}%
                            </div>
                            <div className='col-sm-4'>
                              <ProgressBar now={topHolder.Weighting} />
                            </div>
                          </div>
                        )
                      )}
                  </div>
                  <div className='py-4 fund-riskometer'>
                    <h5 className='c-blue font-weight-medium mb-2'>
                      Mutual Fund Riskometer
                    </h5>
                    <div className='d-flex'>
                      <div className='riskometer col-sm-6'>
                        <ReactSpeedometer
                          maxValue={600}
                          needleColor={'#202020'}
                          currentValueText='RISK'
                          needleHeightRatio={0.7}
                          maxSegmentLabels={0}
                          segments={6}
                          height={200}
                          segmentColors={getRiskoMeterColor()}
                          value={getReskoMeterValue(
                            fundDetail.funds_details.risk_level_id
                          )}
                          valueTextFontSize={'24px'}
                          valueTextFontWeight={'500'}
                        />
                      </div>
                      <div className='riskometer-color-code'>
                        {RiskometerList.map((colorItems) => (
                          <div
                            className='mt-1 d-flex align-items-center'
                            key={colorItems.color}
                          >
                            <span
                              className='riskoMeter-box'
                              style={{ backgroundColor: colorItems.color }}
                            ></span>
                            <span className='riskoMeter-label ml-3'>
                              {colorItems.label}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className='py-4 fund-Scheme'>
                    <a
                      href={fundDetail.funds_details.amc_link}
                      target='_blank'
                      rel='noreferrer'
                      className='c-blue font-weight-medium'
                    >
                      <h5 className='mb-2  d-flex align-items-center'>
                        <img
                          src={forwardArrowIcon}
                          alt='forwardArrow'
                          className='mr-2 small-icon'
                        />
                        <span>Scheme Document</span>
                      </h5>
                    </a>
                  </div>
                </div>
                <div className='col-sm-3 mt-5 pt-5'>
                  <div className='col-sm-12 px-0 pt-5'>
                    <Form.Group className='mt-3 mb-4'>
                      <Form.Label className=''>Enter lumpsum Amount</Form.Label>
                      <InputGroup className='mb-2 font-weight-medium'>
                        <InputGroup.Prepend>
                          <InputGroup.Text
                            className={
                              !canInvestLumpsum ? 'lightgray-backround' : ''
                            }
                          >
                            &#8377;
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          type='number'
                          id='Current-Value'
                          onWheel={(event) => event.currentTarget.blur()}
                          placeholder={
                            canInvestLumpsum
                              ? ''
                              : 'Lumpsum investments temporarily restricted by AMC'
                          }
                          onChange={updateCurValue}
                          value={curValue}
                          className={
                            canInvestLumpsum
                              ? 'updated-input'
                              : 'disabled-input'
                          }
                          disabled={!canInvestLumpsum}
                        />
                      </InputGroup>
                    </Form.Group>
                    {showMinAmount ? (
                      <p className='c-red small mb-2'>
                        Minimum amount should be {minAmount}
                      </p>
                    ) : null}
                    <Form.Group className='mt-3 mb-4'>
                      <Form.Label className=''>Enter SIP Amount</Form.Label>
                      <InputGroup className='mb-2 font-weight-medium'>
                        <InputGroup.Prepend>
                          <InputGroup.Text
                            className={
                              !canInvestSip ? 'lightgray-backround' : ''
                            }
                          >
                            &#8377;
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          type='number'
                          id='Current-Value'
                          onWheel={(event) => event.currentTarget.blur()}
                          placeholder={
                            canInvestSip
                              ? ''
                              : 'Sip investments temporarily restricted by AMC'
                          }
                          onChange={updateSipValue}
                          value={sipValue}
                          className={
                            canInvestSip ? 'updated-input' : 'disabled-input'
                          }
                          disabled={!canInvestSip}
                        />
                      </InputGroup>
                    </Form.Group>
                    {showSipMinAmount ? (
                      <p className='c-red small mb-2'>
                        Minimum amount should be {minSipAmount}
                      </p>
                    ) : null}
                    <div className='btn-container'>
                      <button
                        type='button'
                        className='btn btn-primary w-100'
                        onClick={addToCartHandler}
                        disabled={
                          showMinAmount ||
                          showSipMinAmount ||
                          curValue === '0' ||
                          sipValue === '0' ||
                          (!curValue && !sipValue)
                        }
                      >
                        Add to cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className='fund-detail-container p-4 bg-light-grey '
              id={fundDetail.funds_details.id}
            >
              <div className='d-flex top-header  font-weight-bold'>
                <div className='h4'>
                  {fundDetail.funds_details.fscbi_legal_name}
                </div>
                <div className=' align-items-baseline h4 '>
                  <span>
                    {formatNumber(fundDetail.funds_details.rise_or_fall, 2)}
                  </span>
                </div>
              </div>
              <div className=''>
                <div className='ml-2 float-right '>
                  <span>
                    <img
                      src={
                        fundDetail.funds_details.rise_or_fall > 0
                          ? upArrowIcon
                          : downArrowIcon
                      }
                      alt='up and down arrow'
                      className={
                        'small-icon mx-1 ' +
                        (fundDetail.funds_details.rise_or_fall > 0
                          ? 'rotate-180'
                          : '')
                      }
                    />
                  </span>
                  <span
                    className={
                      'ml-1 ' +
                      (fundDetail.funds_details.rise_or_fall > 0
                        ? 'c-green'
                        : 'c-red')
                    }
                  >
                    ({fundDetail.funds_details.one_day_return}%)
                  </span>
                </div>
              </div>
              <div className='fund-stock-graph '>
                <FundGraph
                  graphData={getFundDetailHoldings(
                    fundDetail.funds_details.total_return_index
                  )}
                />
              </div>
              <div className='my-4 mb-5'>
                <div className='d-flex col-sm-12 px-0 mt-2 mb-3'>
                  <div className='col-sm-6 px-0'>
                    <p className='opacity-50 small mb-2'>
                      NAV as on{' '}
                      {formatDate(fundDetail.funds_details.ts_day_end_nav_date)}
                    </p>
                    <span className='h5 ml-0'>
                      &#8377;
                      {formatNumber(fundDetail.funds_details.dp_day_end_nav, 2)}
                    </span>
                  </div>
                </div>
                <div className='d-flex col-sm-12 px-0'>
                  <div className='col-sm-6 px-0'>
                    {fundDetail?.prime_rating &&
                      fundDetail?.prime_rating?.fund_prime_rating &&
                      appConfig.show_prime_ratings &&
                      fundDetail.prime_rating.fund_prime_rating !==
                        'Unrated' && (
                        <>
                          <p className='opacity-50 small mb-0'>Rating</p>
                          <div className='d-flex align-items-center'>
                            <StarRatings
                              rating={
                                fundDetail.prime_rating?.fund_prime_rating
                                  ? parseFloat(
                                      fundDetail.prime_rating.fund_prime_rating
                                    )
                                  : 0
                              }
                              starRatedColor='#ffb61b'
                              starDimension='16px'
                              starSpacing='4px'
                              numberOfStars={Math.ceil(
                                fundDetail.prime_rating?.fund_prime_rating
                              )}
                            />
                          </div>
                        </>
                      )}
                    {appConfig.show_prime_ratings &&
                      fundDetail.prime_rating.fund_prime_rating ===
                        'Unrated' && (
                        <p>
                          Prime Rating:{' '}
                          <span className='ml-1 c-orange'>
                            {fundDetail.prime_rating.fund_prime_rating}
                          </span>
                        </p>
                      )}
                  </div>
                  <div className='col-sm-6 px-0'>
                    {fundDetail?.prime_rating &&
                      appConfig.show_prime_ratings &&
                      fundDetail?.prime_rating?.fund_mf_review_call !==
                        'Unrated' && (
                        <>
                          <p className='opacity-50 small mb-0 pb-2'>
                            Prime Recommendation:{' '}
                          </p>
                          <span
                            className='ml-1 mt-4'
                            style={{ color: primeStatus }}
                          >
                            {fundDetail.prime_rating &&
                              fundDetail.prime_rating.fund_mf_review_call}
                          </span>
                        </>
                      )}
                  </div>
                </div>
              </div>
              <div
                onClick={() => setShowReturns(!showReturns)}
                aria-controls='example-collapse-text'
                aria-expanded={showReturns}
                className='border-bottom border-top mt-4 pt-4 pb-3 fund-objective'
              >
                <span className='h5 ml-0'>Returns(%)</span>
                <span className='float-right mr-4'>
                  {showReturns ? (
                    <MdKeyboardArrowUp size={30} />
                  ) : (
                    <MdKeyboardArrowDown size={30} />
                  )}
                </span>
              </div>
              <Collapse in={showReturns}>
                <div id='example-collapse-text my-3'>
                  <div className='fund-return-row d-flex text-center border-bottom-0'>
                    <div className='return-item'>1 M</div>
                    <div className='return-item'>3 M</div>
                    <div className='return-item'>6 M</div>
                    <div className='return-item'>1 Y</div>
                    <div className='return-item'>3 Y</div>
                    <div className='return-item'>5 y</div>
                    <div className='return-item-2'>Since Inception</div>
                  </div>
                  <div className='fund-return-row d-flex text-center'>
                    <div className='return-item'>
                      {getTwoDecimalPlaceVal(
                        fundDetail.funds_details.ttr_return_1_mth
                      )}
                    </div>
                    <div className='return-item'>
                      {getTwoDecimalPlaceVal(
                        fundDetail.funds_details.ttr_return_3_mth
                      )}
                    </div>
                    <div className='return-item'>
                      {getTwoDecimalPlaceVal(
                        fundDetail.funds_details.ttr_return_6_mth
                      )}
                    </div>
                    <div className='return-item'>
                      {getTwoDecimalPlaceVal(
                        fundDetail.funds_details.ttr_return_1_yr
                      )}
                    </div>
                    <div className='return-item'>
                      {getTwoDecimalPlaceVal(
                        fundDetail.funds_details.ttr_return_3_yr
                      )}
                    </div>
                    <div className='return-item'>
                      {getTwoDecimalPlaceVal(
                        fundDetail.funds_details.ttr_return_5_yr
                      )}
                    </div>
                    <div className='return-item-2'>
                      {getTwoDecimalPlaceVal(
                        fundDetail.funds_details.ttr_return_since_inception
                      )}
                    </div>
                  </div>
                </div>
              </Collapse>
              <div
                onClick={() => setShowObjective(!showObjective)}
                aria-controls='example-collapse-text'
                aria-expanded={showObjective}
                className={`${
                  showObjective ? null : 'border-bottom'
                } mt-3 pb-3 fund-objective`}
              >
                <span className='h5 ml-0'>Fund Objective</span>
                <span className='float-right mr-4'>
                  {showObjective ? (
                    <MdKeyboardArrowUp size={30} />
                  ) : (
                    <MdKeyboardArrowDown size={30} />
                  )}
                </span>
              </div>
              <Collapse in={showObjective}>
                <div id='example-collapse-text'>
                  <p className='opacity-75'>
                    {fundDetail.funds_details.investment_strategy}
                  </p>
                </div>
              </Collapse>
              <div
                onClick={() => setShowInformation(!showInformation)}
                aria-controls='example-collapse-text'
                aria-expanded={showInformation}
                className={`${
                  showInformation ? null : 'border-bottom'
                } mt-3 pb-3 fund-key-information`}
              >
                <span className='h5 ml-0'>Key Information</span>
                <span className='float-right mr-4'>
                  {showInformation ? (
                    <MdKeyboardArrowUp size={30} />
                  ) : (
                    <MdKeyboardArrowDown size={30} />
                  )}
                </span>
              </div>
              <Collapse in={showInformation}>
                <div className='info-row row'>
                  <div className='info-section col-sm-9'>
                    <div className='justify-content-between px-2 opacity-75 my-2'>
                      <p className='mb-1 opacity-75'>AMC Name</p>
                      <p>{fundDetail.funds_details.amc_name}</p>
                    </div>
                    <div className='justify-content-between px-2 opacity-75 '>
                      <p className='mb-1 opacity-75'>Fund Type</p>
                      <p>{fundDetail.funds_details.fscbi_legal_structure}</p>
                    </div>
                    <div className='justify-content-between px-2 opacity-75'>
                      <p className='mb-1 opacity-75' pan>
                        Investment Plan
                      </p>
                      <p>
                        {fundDetail.funds_details?.purchase_mode === '1'
                          ? 'REGULAR'
                          : fundDetail.funds_details?.purchase_mode === '2'
                          ? 'DIRECT'
                          : 'UNKNOWN'}
                      </p>
                    </div>
                    <div className='justify-content-between px-2 opacity-75'>
                      <p className='mb-1 opacity-75'>Launch Date</p>
                      <p>
                        {formatDate(
                          fundDetail.funds_details.kd_performance_start_date
                        )}
                      </p>
                    </div>
                    <div className='justify-content-between px-2 opacity-75'>
                      <p className='mb-1 opacity-75'>Benchmark</p>
                      <p>
                        {getBenchmarks(fundDetail.funds_details.benchmarks)}
                      </p>
                    </div>
                    <div className='justify-content-between px-2 opacity-75'>
                      <p className='mb-1 opacity-75'>Fund Size</p>
                      <p>
                        &#8377;{' '}
                        {formatPrice(
                          parseFloat(
                            fundDetail.funds_details.fna_aum / 10000000
                          ).toFixed(2)
                        )}{' '}
                        cr
                      </p>
                    </div>
                    <div className='justify-content-between px-2 opacity-75'>
                      <p className='mb-1 opacity-75'>Asset Date</p>
                      <p>
                        {formatDate(
                          fundDetail.funds_details
                            .fna_surveyed_fund_net_assets_date
                        )}
                      </p>
                    </div>
                    <div className='justify-content-between px-2 opacity-75'>
                      <p className='mb-1 opacity-75'>
                        Minimum Investment Lumpsum
                      </p>
                      <p>
                        &#8377;{fundDetail.funds_details.pi_minimum_initial}
                      </p>
                    </div>
                    <div className='justify-content-between px-2 opacity-75'>
                      <p className='mb-1 opacity-75'>
                        Minimum Additional Investment Lumpsum
                      </p>
                      <p>
                        &#8377;{fundDetail.funds_details.pi_minimum_subsequent}
                      </p>
                    </div>
                    <div className='justify-content-between px-2 opacity-75'>
                      <p className='mb-1 opacity-75'>Expense Ratio</p>
                      <p>
                        {getTwoDecimalPlaceVal(
                          fundDetail.funds_details.interim_net_expense_ratio
                        )}
                        %
                      </p>
                    </div>
                    <div className='justify-content-between px-2 opacity-75'>
                      <p className='mb-1 opacity-75'>Fund Manger</p>
                      <p>{getMangersName(fundDetail.funds_details.managers)}</p>
                    </div>
                    <div className='justify-content-between px-2 opacity-75'>
                      <p className='mb-1 opacity-75'>Exit Load</p>
                      <p>
                        {fundDetail.funds_details.defer_loads.map((data) => {
                          if (data.value > 0) {
                            return (
                              <p key=''>{`${data.value} % if redeemed within ${data.high_breakpoint} ${data.breakpoint_unit}`}</p>
                            );
                          } else {
                            return 'NIL';
                          }
                        })}
                      </p>
                    </div>
                    <div className='justify-content-between px-2 opacity-75'>
                      <p className='mb-1 opacity-75'>
                        Standard Deviation (5 yr)
                      </p>
                      <p>
                        {getTwoDecimalPlaceVal(
                          fundDetail.funds_details.standard_deviation_5_yr
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </Collapse>
              <div
                onClick={() => setShowHoldings(!showHoldings)}
                aria-controls='example-collapse-text'
                aria-expanded={showHoldings}
                className={`${
                  showHoldings ? null : 'border-bottom'
                } mt-3 pb-3 fund-objective`}
              >
                <span className='h5 ml-0'>
                  {fundDetail.top_ten_holdings
                    ? 'Top Holdings'
                    : 'Top 10 holdings unavailable'}
                </span>
                <span className='float-right mr-4'>
                  {showHoldings ? (
                    <MdKeyboardArrowUp size={30} />
                  ) : (
                    <MdKeyboardArrowDown size={30} />
                  )}
                </span>
              </div>
              <Collapse in={showHoldings}>
                <div>
                  {fundDetail.top_ten_holdings &&
                    getFundDetailHoldings(fundDetail.top_ten_holdings).map(
                      (topHolder, index) => (
                        <div
                          className='d-flex justify-content-between py-2 align-items-center  col-sm-12'
                          key={'top-holder-' + index}
                        >
                          <div className='opacity-75 px-0'>
                            {topHolder.Name}
                          </div>
                          <div className='font-weight-medium pr-2'>
                            {formatNumber(topHolder.Weighting, 1)}%
                          </div>
                        </div>
                      )
                    )}
                </div>
              </Collapse>
              <div
                onClick={() => setShowRisks(!showRisks)}
                aria-controls='example-collapse-text'
                aria-expanded={showRisks}
                className={`${
                  showRisks ? null : 'border-bottom'
                } mt-3 pb-3 fund-riskometer`}
              >
                <span className='h5 ml-0'>Mutual Fund Riskometer</span>
                <span className='float-right mr-4'>
                  {showRisks ? (
                    <MdKeyboardArrowUp size={30} />
                  ) : (
                    <MdKeyboardArrowDown size={30} />
                  )}
                </span>
              </div>
              <Collapse in={showRisks}>
                <div className='py-4 fund-riskometer'>
                  <div className='riskometer col-sm-6'>
                    <ReactSpeedometer
                      maxValue={600}
                      needleColor={'#202020'}
                      currentValueText='RISK'
                      needleHeightRatio={0.7}
                      maxSegmentLabels={0}
                      segments={6}
                      height={200}
                      segmentColors={getRiskoMeterColor()}
                      value={getReskoMeterValue(
                        fundDetail.funds_details.risk_level_id
                      )}
                      valueTextFontSize={'24px'}
                      valueTextFontWeight={'500'}
                    />
                  </div>
                  <div className='riskometer-color-code  col-sm-12'>
                    {RiskometerList.map((colorItems) => (
                      <div
                        className='mt-1 d-flex align-items-center'
                        key={colorItems.color}
                      >
                        <span
                          className='riskoMeter-box'
                          style={{ backgroundColor: colorItems.color }}
                        ></span>
                        <span className='riskoMeter-label ml-3'>
                          {colorItems.label}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </Collapse>
              <div
                className={`border-bottom mt-3 pb-3 fund-riskometer cursor-pointer `}
              >
                <a
                  href={fundDetail.funds_details.amc_link}
                  target='_blank'
                  rel='noreferrer'
                  className='font-weight-medium c-black'
                >
                  <span className='h5 ml-0'>Scheme Document</span>
                  <span className='float-right mr-4'>
                    <MdKeyboardArrowDown size={30} />
                  </span>
                </a>
              </div>
              <div className=' '>
                <div className=' px-0 pt-4'>
                  <Form.Group className='mt-2 mb-2'>
                    <Form.Label className='ml-3'>
                      Enter lumpsum Amount
                    </Form.Label>
                    <InputGroup className='mb-2 font-weight-medium ml-0'>
                      <InputGroup.Prepend>
                        <InputGroup.Text
                          className={
                            !canInvestLumpsum ? 'lightgray-backround ' : ''
                          }
                        >
                          &#8377;
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type='number'
                        id='Current-Value'
                        placeholder={
                          canInvestLumpsum
                            ? ''
                            : 'Lumpsum investments temporarily restricted by AMC'
                        }
                        onChange={updateCurValue}
                        onWheel={(event) => event.currentTarget.blur()}
                        value={curValue}
                        className={
                          canInvestLumpsum
                            ? 'updated-input bg-light-grey mr-3'
                            : 'disabled-input bg-light-grey'
                        }
                        disabled={!canInvestLumpsum}
                      />
                    </InputGroup>
                  </Form.Group>
                  {showMinAmount ? (
                    <p className='c-red small pb-2 ml-3 '>
                      Minimum amount should be {minAmount}
                    </p>
                  ) : null}
                  <Form.Group className='mt-2 mb-2'>
                    <Form.Label className='ml-3'>Enter Sip Amount</Form.Label>
                    <InputGroup className='mb-2 font-weight-medium ml-0'>
                      <InputGroup.Prepend>
                        <InputGroup.Text
                          className={
                            !canInvestSip ? 'lightgray-backround ' : ''
                          }
                        >
                          &#8377;
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type='number'
                        id='Current-Value'
                        onWheel={(event) => event.currentTarget.blur()}
                        placeholder={
                          canInvestSip
                            ? ''
                            : 'Sip investments temporarily restricted by AMC'
                        }
                        onChange={updateSipValue}
                        value={sipValue}
                        className={
                          canInvestSip
                            ? 'updated-input bg-light-grey mr-3'
                            : 'disabled-input bg-light-grey'
                        }
                        disabled={!canInvestSip}
                      />
                    </InputGroup>
                  </Form.Group>
                  {showSipMinAmount ? (
                    <p className='c-red small mb-2'>
                      Minimum amount should be {minSipAmount}
                    </p>
                  ) : null}
                  {/*{showMinAmount ? <p className="c-red small pb-2 ml-3 ">Minimum amount should be {minAmount}</p> : null}*/}
                  <div className='btn-container mb-4 col-sm-12'>
                    <button
                      type='button'
                      className='btn btn-primary w-100 py-2'
                      onClick={addToCartHandler}
                      disabled={
                        showMinAmount ||
                        showSipMinAmount ||
                        curValue === '0' ||
                        sipValue === '0' ||
                        (!curValue && !sipValue)
                      }
                    >
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
              <MobileNavigation />
            </div>
          )}
        </>
      ) : null}
      {addModalData.show ? (
        <AddFundModal
          show={addModalData.show}
          minAmountCurrent={addModalData.minAmountCurrent}
          minSipAmountCurrent={addModalData.minSipAmount}
          minAmountNew={addModalData.minAmountNew}
          folioList={addModalData.folioList}
          onSubmit={onAddFundPopupClose}
          fundId={addModalData.fundId}
          lumsumamount={addModalData.lumsumamount}
          sipamount={addModalData.sipamount}
          frequencyDates={frequencyDates}
          canInvestLumpsum={canInvestLumpsum}
          canInvestSip={canInvestSip}
        />
      ) : null}
    </>
  );
};

FundDetail.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};
export default FundDetail;
