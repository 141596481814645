import 'react-datepicker/dist/react-datepicker.css';
import { BrowserView, MobileView } from 'react-device-detect';
import './App.scss';
import Header from './common/header/Header';
import HeaderMobile from './common/header/HeaderMobile';
import Routers from './routers/Routers';

const App = () => {
  document.body.classList.remove('v2');
  return (
    <>
      <BrowserView>
        <div className='container-fluid'>
          <Header />
          <Routers />
        </div>
      </BrowserView>
      <MobileView>
        <>
          <HeaderMobile />
          <Routers />
        </>
      </MobileView>
    </>
  );
};

export default App;
