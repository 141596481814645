import React from 'react';
import { Nav } from 'react-bootstrap';
import { FiHome } from 'react-icons/fi';
import { BsBriefcase, BsCart3, BsCashStack } from 'react-icons/bs';
import { RiArrowLeftRightFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedNav } from '../../redux/reducers/header-reducer';
import { Link } from 'react-router-dom';

const MobileNavigation = () => {
  const { activeLink } = useSelector((state) => state.headerReducer);
  const dispatch = useDispatch();
  const updateActiveLink = (link) => {
    dispatch(updateSelectedNav(link));
  };
  return window.location.href.includes('funds/all') ? null : (
    <div className="my-4 py-4 mx-4 d-none">
      <nav className="border-tl border-tr navbar fixed-bottom navbar-light bg-white colored-top sticky-bottom" role="navigation">
        <Nav className="w-100">
          <div className=" mb-2 d-flex flex-row w-100" style={{ overflow: 'auto' }}>
            <>
              <li className={`nav-item c-black text-center `} onClick={() => updateActiveLink('home')}>
                <Link className={`nav-link px-1 ${activeLink === 'home' ? 'c-blue' : 'c-grey'} `} to="/">
                  <FiHome size={25} />
                  Dashboard
                </Link>
              </li>
              <li className={`nav-item  text-center `} onClick={() => updateActiveLink('invest')}>
                <Link className={`nav-link px-2 ${activeLink === 'invest' ? 'c-blue' : 'c-grey'}`} to="/invest">
                  <BsCashStack size={25} />
                  Investment
                </Link>
              </li>
              <li className={`nav-item text-center `} onClick={() => updateActiveLink('portfolio')}>
                <Link className={`nav-link px-2 ${activeLink === 'portfolio' ? 'c-blue' : 'c-grey'}`} to="/portfolio">
                  <BsBriefcase size={25} />
                  Portfolio
                </Link>
              </li>
              <li className={`nav-item text-center`} onClick={() => updateActiveLink('cart')}>
                <Link className={`nav-link px-2 ${activeLink === 'cart' ? 'c-blue' : 'c-grey'}`} to="/order/summary">
                  <BsCart3 size={25} />
                  Cart
                </Link>
              </li>
              <li className={`nav-item text-center`} onClick={() => updateActiveLink('transactions')}>
                <Link className={`nav-link px-1 ${activeLink === 'transactions' ? 'c-blue' : 'c-grey'}`} to="/transactionsmobile">
                  <RiArrowLeftRightFill size={25} />
                  Transactions
                </Link>
              </li>
            </>
          </div>
        </Nav>
      </nav>
    </div>
  );
};

export default MobileNavigation;
