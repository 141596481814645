import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { saveCartItems } from '../api/cart';
import { DropdownList } from '../common/form';
import SipDateSelectModal from '../page/fund/SipDateSelectModal';
import { ordinal_suffix_of } from '../utility/utility';

const AddFundModal = ({ show, fundId, folioList = [], onSubmit, minAmountCurrent,minSipAmountCurrent, minAmountNew, canInvestSip, canInvestLumpsum, frequencyDates, lumsumamount = 0, sipamount = 0 , isEmpanelled,manageAmc}) => {
  const [amount, setAmount] = useState(lumsumamount);
  const [sipAmount, setSipAmount] = useState(sipamount);
  const [folioObj, setFolioNumber] = useState({});
  const [folioType, setFolioType] = useState(folioList.length? 'current': 'new');
  const [error, setError] = useState('');
  const [showMinAmount, setShowMinAmount] = useState(false);
  const [showMinSipAmount, setShowMinSipAmount] = useState(false);
  const [minAmount, setMinAmount] = useState(0);
  const [minSipAmount, setMinSipAmount] = useState(0);
  const [selectedSipDate, setSelectedSipDate] = useState(frequencyDates[0] || 1);
  const [showSipModal,setShowSipModal] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setMinAmount(minAmountCurrent);
    setMinSipAmount(minSipAmountCurrent);
  },[])

  const updateFolioType = (event) => {
    const { value } = event.target;
    if (value === 'new') {
      setFolioNumber('');
      setError('');
      setMinAmount(minAmountNew)
      checkMinAmountValidation(amount, minAmountNew)
    } else {
      setMinAmount(minAmountCurrent)
      checkMinAmountValidation(amount, minAmountCurrent)
    }
    setFolioType(value);
  };
  const updateFolioNumber = (event, data) => {
    event.preventDefault();
    setFolioNumber(data);
  };
  const updateAmount = (event) => {
    const { value = '' } = event.target;
    checkMinAmountValidation(value, minAmount)
    setAmount(value.trim());
  };
  const updateSipAmount = (event) => {
    const { value = '' } = event.target;
    checkMinSipAmountValidation(value, minSipAmount);
    setSipAmount(value.trim());
  };
  const checkMinAmountValidation = (amount, minAmount) => {
    if ((amount.length && amount < minAmount)) {
      setShowMinAmount(true)
    } else {
      setShowMinAmount(false)
    }
  };

  const checkMinSipAmountValidation = (amount, minAmount) => {
    if ((amount.length && amount < minAmount)) {
      setShowMinSipAmount(true)
    } else {
      setShowMinSipAmount(false)
    }
  }

  const AddToCart = () => {
    if (folioType === 'current' && !(folioObj && folioObj.label)) {
      setError('Please select atlease one folio');
      return false;
    }
    const formData = new FormData();
    formData.append('lumpsum_amount', amount || 0);
    formData.append('fund_id', fundId);
    formData.append('folio_number', folioType === 'current' ? folioObj.label : '');
    formData.append('sip_amount', sipAmount || 0);
    formData.append('day', selectedSipDate);
    saveCartItems(formData).then((res) => {
      if (res.data.status.code === 1) {
        onSubmit(true);
      }
    });
  };

  const onSipDateSelect = (date) => {
    setSelectedSipDate(date)
  };

  const toggleSipModal = () => {
    setShowSipModal(!showSipModal);
    setSelectedSipDate(frequencyDates[0] || 1)
  };

  const onSipDateSubmit = () => {
    setShowSipModal(false)
  }

  return (
    <>
    <Modal show={show} size="sm" className={windowDimensions.width>480?null:"p-4"} aria-labelledby="add-fund-modal" onHide={onSubmit} centered backdrop="static" keyboard={false}>
      <Modal.Header className="pb-0 " closeButton>
        <h5 className="font-weight-medium m-0">Add in this fund</h5>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <Form.Group className="d-flex my-3">
          <Form.Label as="div" className={windowDimensions.width > 480 ? "mr-3 opacity-50":"font-weight-bold mr-2"}>
            Folio type:
          </Form.Label>
          {folioList.length ? <Form.Check
            type="radio"
            label="Current"
            checked={folioType === 'current'}
            value="current"
            name="fundtype"
            id="currentfundtype"
            className={`mr-3 ${folioType === 'current' ? 'c-blue' : ''}`}
            onChange={updateFolioType}
          />: null}
          <Form.Check
            type="radio"
            label="New"
            checked={folioType === 'new'}
            value="new"
            name="fundtype"
            className={`${folioType === 'new' ? 'c-blue' : ''}`}
            id="newfundtype"
            onChange={updateFolioType}
          />
        </Form.Group>
        {folioList.length ?
          <Form.Group className="my-3">
            <Form.Label className="opacity-50 mb-1 small">Current folio</Form.Label>
            <DropdownList
              selectedOption={folioObj.label}
              id="folio-selection"
              placeholder={'Select Folio'}
              disabled={folioType === 'new'}
              classes={windowDimensions.width > 480 ? (folioType === 'new' ? ({input: 'opacity-50 dropdown-grey-bg updated-input pl-0'}):({input: 'updated-input pl-0'})) : (folioType === 'new' ? ({ input: 'opacity-50 dropdown-grey-bg updated-input pl-0 font-weight-bold'}) : ({ input: 'updated-input pl-0 font-weight-bold'}) )}
              controlFunc={updateFolioNumber}
              options={folioList.map((item) => ({ label: item, value: item }))}
            />
            {error && <p className="c-red small mb-2">{error}</p>}
          </Form.Group>
          : null
        }
        <Form.Group className="my-3">
          <Form.Label className="opacity-50 small">Enter one-time lumpsum amount</Form.Label>
          <InputGroup className="mb-2 font-weight-medium">
            <InputGroup.Prepend>
              <InputGroup.Text className={!canInvestLumpsum ? 'lightgray-backround ' : ''}>&#8377;</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control type="number"
                          onWheel={ event => event.currentTarget.blur() }
                          id="Current-Value"
                          placeholder={canInvestLumpsum ? '' : 'Sip investments temporarily restricted by AMC'}
                          disabled={!canInvestLumpsum}
                          className={`${canInvestLumpsum ? 'updated-input bg-light-grey mr-3' : 'bg-light-grey disabled-input '} ${windowDimensions.width > 480 ? '' : 'font-weight-bold' }`}
                          value={amount} onChange={updateAmount} />
          </InputGroup>
        </Form.Group>
        {showMinAmount ? <p className="c-red small mb-2">Minimum amount should be {minAmount}</p> : null }
        <Form.Group className="my-3">
          <Form.Label className="opacity-50 small">Monthly investment amount (SIP)</Form.Label>
          <InputGroup className="mb-2 font-weight-medium">
            <InputGroup.Prepend>
              <InputGroup.Text className={!canInvestSip ? 'lightgray-backround ' : ''}>&#8377;</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control type="number"
                          onWheel={ event => event.currentTarget.blur() }
                          id="Sip-Value"
                          placeholder={canInvestSip ? '' : 'Sip investments temporarily restricted by AMC'}
                          disabled={!canInvestSip}
                          className={`${canInvestSip ? 'updated-input bg-light-grey mr-3' : 'bg-light-grey disabled-input '} ${windowDimensions.width > 480 ? '' : 'font-weight-bold' }`}
                          value={sipAmount} onChange={updateSipAmount} />
          </InputGroup>
        </Form.Group>
        {showMinSipAmount? <p className="c-red small mb-2">Minimum amount should be {minSipAmount}</p> : null}
        <button className="btn btn-outline-dark my-3 mb-2 font-weight-lighter w-100"  onClick={()=> setShowSipModal(!showSipModal)} disabled={!canInvestSip}>
          <div className="opacity-75">{ordinal_suffix_of(selectedSipDate) + " of every month"}</div>
        </button>
      </Modal.Body>
      <div className="btn-container text-center mb-3 p-3">
        <Button onClick={AddToCart} className={windowDimensions.width > 480 ? "btn btn-primary w-100" : "btn btn-primary w-80 py-2"} disabled={showMinAmount || showMinSipAmount || amount === '0' || sipAmount === '0' || (!amount && !sipAmount) || (manageAmc && !isEmpanelled)}>
          Add to cart
        </Button>
      </div>
      {manageAmc && !isEmpanelled && <p className='text-center'>This AMC is not available</p>}
    </Modal>
      <SipDateSelectModal frequencyDates={frequencyDates} show={showSipModal} onClose={toggleSipModal} selectedDate={selectedSipDate} onDateSelect={onSipDateSelect} onSubmit={onSipDateSubmit}/>
    </>
  );
};
AddFundModal.propTypes = {
  show: PropTypes.bool,
  fundId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minAmountNew: PropTypes.number, 
  minAmountCurrent: PropTypes.number,
  minSipAmountCurrent:PropTypes.number,
  lumsumamount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  folioList: PropTypes.array,
  onSubmit: PropTypes.func,
};
export default AddFundModal;
