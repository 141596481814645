import { client } from '../client';

export interface Asset {
  asset_class: string;
  investment_in_percentage: number;
}

export interface AssetsResponse {
  types: Asset[];
  calsses: Asset[];
}

export interface TagInDB {
  id: number;
  name: string;
}

export interface FAQInDB {
  id: number;
  question: string;
  answer: string;
}

const getAssets = async () => {
  const res = await client.get<Asset[]>('/holdings/asset-class');
  return res.data;
};

const api = {
  getAssets,
};

export default api;
