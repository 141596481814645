import React, { useState } from 'react';
import Amount from '../Amount/Amount';
import { PureDropdown } from '../PureDropdown/PureDropdown';
import { PortfolioDetails } from '../../types';

const OPTIONS = ['Absolute', 'XIRR'];

interface Props {
  className?: string;
  data: PortfolioDetails | undefined;
}

export const MutualFundsTile = ({ className, data }: Props) => {
  const [value, setValue] = useState('Absolute');
  return (
    <div className={`d-flex flex-column ${className}`}>
      <div className='DashboardV2__tile DashboardV2__assetTile Investments__deposits pr-2 flex-grow-1 '>
        <div className='DashboardV2__title DashboardV2__assetTitle mb-3 pl-0'>
          Mutual Funds
        </div>
        <div className='MutualFundsTile__container'>
          <div className='Investments__cell'>
            <div className='Investments__label'>Invested Value</div>
            <Amount
              value={data?.total_investment || 0}
              className='Investments__amount'
            />
          </div>
          <div className='Investments__cell'>
            <div className='Investments__label'>Current Value</div>
            <Amount
              value={data?.current_value || 0}
              className='Investments__amount'
            />
          </div>
          <div className='Investments__cell'>
            <div className='d-flex flex-column flex-md-row align-items-start md:align-items-center'>
              <div className='Investments__label mr-2'>Returns: </div>
              <PureDropdown
                value={value}
                items={OPTIONS}
                onClick={(v: string) => {
                  setValue(v);
                }}
              />
            </div>
            <div className='Investments__amount d-flex align-items-center'>
              {(value === 'Absolute'
                ? data?.absolute_return
                : data?.xirr
              )?.toFixed(2) || 0}
              %
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
