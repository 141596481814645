import { AxiosPromise } from 'axios';
import { PostPaymentInput } from '../../../models';
import { client } from '../client';

const postPayment = async (input: PostPaymentInput) => {
  const res = await client.post<any, AxiosPromise<string>>(`/payments`, input);
  return res.data;
};

const patchPayment = async (tarrakki_id: string, input: any) => {
  const res = await client.patch<any, AxiosPromise<any>>(
    `/payments/${tarrakki_id}`,
    input
  );
  return res.data;
};

const getPayment = async (tarrakki_id: string) => {
  const res = await client.get<any>(`/payments/${tarrakki_id}`);
  return res.data;
};

const api = {
  postPayment,
  patchPayment,
  getPayment,
};

export default api;
