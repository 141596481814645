import React, { useEffect, useState } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CurrencyInput from 'react-currency-input-field';
import { BsPlusCircle, BsDashCircle } from 'react-icons/bs';
import { HiOutlinePencil, HiCheck } from 'react-icons/hi';
import { Form } from 'react-bootstrap';

const OptionsType5 = ({ options, question, updateAnswerJson, value, errors, step }) => {
  const [years, setYears] = useState();
  const [amount, setAmount] = useState('');
  const [selectedGoal, setSelectedGoal] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [rows, setRows] = useState([]);
  const [addRowFlag, setAddRowFlag] = useState(false);
  const [otherStatus, setOtherStatus] = useState([]);
  const [otherGoalTemp, setOtherGoalTemp] = useState();
  const [yearOpen, setYearOpen] = useState(false);
  const [goalOpen, setGoalOpen] = useState(false);
  const [error, setError] = useState();

  const handleGoalSelect = (event) => {
    setSelectedGoal(event.target.value);
  };

  const handleYearSelect = (event) => {
    setSelectedYear((event.target.value).toString());
  };

  useEffect(() => {
    let yearList = [];
    let year = new Date().getFullYear();
    for (let i = 0; i < 50; i++) {
      yearList.push(year);
      year++;
    }
    setYears([...yearList]);
  }, []);

  useEffect(() => {
    if (value && years) {
      let rowData = [];
      value.options.forEach((item) => {
        const row = {};
        row.goal = options[options.map((option) => option.option_id).indexOf(parseInt(item.option_id))];
        row.year = item?.inputs[0].value?.toString();
        row.amount = item?.inputs[1].value?.toString();
        row.otherGoal = item?.inputs[2]?.value;
        rowData.push(row);
      });
      setRows([...rowData]);
    }
  }, [value, JSON.stringify(years)]);

  const validate = () => {
    let isValid = true;
    if (!selectedGoal) {
      isValid = false;
    }
    if (!selectedYear) {
      isValid = false;
    }
    if (!amount || amount.length === 0) {
      isValid = false;
    }
    if (amount && (amount === 0 || amount === "0")) {
      isValid = false;
      setError("This amount can not be zero")
    }
    return isValid;
  };

  const addRow = () => {
    if (validate()) {
      setError(undefined)
      setAddRowFlag(true);
      let rowData = {};
      rowData.goal = selectedGoal;
      rowData.year = selectedYear.toString();
      rowData.amount = amount ? parseFloat(amount).toString() : undefined; 
      if(selectedGoal?.option_value?.toLowerCase() === 'other') {
        rowData.otherGoal = ' ';
      }
      rows.push(rowData);
      setRows([...rows]);

      // setSelectedGoal(undefined)
      // setSelectedYear(undefined)
      // setAmount('')
      updateAnswer(rows);
    }
  };

  const updateAnswer = (rowsData) => {
    const answer = {
      question_id: question.question_id?.toString(),
    };
    const optionArr = [];
    rowsData.forEach((item) => {
      const optionObj = {};
      optionObj.option_id = item?.goal?.option_id?.toString();
      optionObj.inputs = [
        {
          name: 'target_year',
          value: (item.year).toString(),
        },
        {
          name: 'amount',
          value: (item.amount).toString(),
        },
      ];
      if (item.otherGoal || item?.goal?.option_value?.toLowerCase() === "other") {
        optionObj.inputs = [
          ...optionObj.inputs,
          {
            name: 'other_goal',
            value: item?.otherGoal,
          },
        ];
      }
      optionArr.push(optionObj);
    });
    answer.options = optionArr;
    updateAnswerJson(answer);
  };

  const removeRow = (index) => {
    rows.splice(index, 1);
    setRows([...rows]);
    updateAnswer(rows);
  };

  const handleAmountChange = (value) => {
    setError(undefined)
    setAmount(value.toString());
  };

  useEffect(() => {
    setSelectedGoal('');
    setSelectedYear('');
    setAmount('');
    setAddRowFlag(false);
  }, [addRowFlag]);

  const handleOtherGoalChange = (event) => {
    // setOtherGoalTemp(setOtherGoalTemp)
    if(new RegExp(/^[a-zA-Z ]*$/).test(event.target.value)) {
      setOtherGoalTemp(event.target.value);
    } else {
      if(!otherGoalTemp) {
        setOtherGoalTemp('');
      }
    }
  };

  const editOtherField = (index) => {
    setOtherStatus([]);
    otherStatus[index] = true;
    setOtherStatus([...otherStatus]);
    setOtherGoalTemp(rows[index]?.otherGoal)
  };

  const saveOtherField = (index) => {
    setOtherStatus([]);
    otherStatus[index] = false;
    setOtherStatus([...otherStatus]);
    rows[index].otherGoal = otherGoalTemp || ' ';
    setRows([...rows]);
    updateAnswer(rows);
    setOtherGoalTemp('');
  };

  const onYearOpen = () => {
    setYearOpen(true);
  };
  const onYearClose = () => {
    setTimeout(() => {
      setYearOpen(false);
    }, 200)
  };
  const onGoalOpen = () => {
    setGoalOpen(true);
  };
  const onGoalClose = () => {
    setTimeout(() => {
      setGoalOpen(false);
    }, 200);
  };

  return (
    <>
      <div className={'mt-2 multi-options'}>
        <div className={'d-flex flex-wrap'}>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            className={'option-select mr-3'}
            onClose={onGoalClose}
            onOpen={onGoalOpen}
            displayEmpty={true}
            MenuProps={{
              className: 'goal-menu',
            }}
            style={{ width: '370px' }}
            value={selectedGoal}
            onChange={handleGoalSelect}>
            {!goalOpen && (
              <MenuItem disabled selected={true} value={''}>
                <span className={'mui-placeholder'}>Select your financial goal</span>
              </MenuItem>
            )}
            {options && options.map((item) => <MenuItem value={item}>{item.option_value}</MenuItem>)}
          </Select>
          <div className={'d-flex mt-3 mt-md-0'}>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              MenuProps={{
                className: 'year-menu',
              }}
              displayEmpty={true}
              onClose={onYearClose}
              onOpen={onYearOpen}
              className={'option-select year-select mr-3 ml-md-3'}
              style={{ width: '270px' }}
              onChange={handleYearSelect}
              value={selectedYear}>
              {!yearOpen && (
                <MenuItem disabled selected={true} value={''}>
                  <span className={'mui-placeholder'}>Target year</span>
                </MenuItem>
              )}
              {years && years.map((item) => <MenuItem value={item}>{item}</MenuItem>)}
            </Select>

            <div className={'ml-3 mr-md-3'}>
              <span className={'input-symbol'}>₹</span>
              <CurrencyInput
                id="amount-input"
                name="amount"
                className={'form-control multi-option-input'}
                placeholder="Enter Amount"
                value={amount || ''}
                allowDecimals={false}
                decimalsLimit={0}
                min={0}
                allowNegativeValue={false}
                intlConfig={{ locale: 'en-IN' }}
                // disabled={checkAll || !switchFromFund}
                onValueChange={handleAmountChange}
              />
              {error && <div className={'invalid-feedback d-block mt-1'}>{error}</div>}
            </div>
            <div className={'d-flex align-items-center ml-md-3 option-add-icon'} onClick={addRow}>
              <BsPlusCircle style={{ height: '25px', width: '25px' }} />
            </div>
          </div>
        </div>

        <div className={'option-rows'}>
          {rows.map((item, index) => (
            <div className={'d-md-flex option-row'}>
              {item?.goal?.option_value?.toLowerCase() === 'other' ? (
                <div className={`other-goal ${otherStatus[index] ? 'other-editing' : ''} mr-3`}>
                  <Form.Control
                    type="text"
                    id="Current-Value"
                    placeholder="Write your goal here"
                    defaultValue={item?.otherGoal}
                    value={otherStatus[index] ? otherGoalTemp : item?.otherGoal?.trim()}
                    disabled={!otherStatus[index]}
                    onChange={handleOtherGoalChange}
                    className={'goal-input mr-3'}
                  />
                  {otherStatus[index] ? (
                    <div onClick={() => saveOtherField(index)} title={'Save'}>
                      <HiCheck />
                    </div>
                  ) : (
                    <div onClick={() => editOtherField(index)} title={'Edit'}>
                      <HiOutlinePencil />
                    </div>
                  )}
                </div>
              ) : (
                <Form.Control type="text" id="Current-Value" placeholder="" value={item?.goal?.option_value} disabled className={'goal-input mr-3'} />
              )}
              <div className={'d-flex mt-3 mt-md-0'}>
                <Form.Control type="text" id="Current-Value" placeholder="" value={item?.year} className={'year-input ml-md-3 mr-3'} />
                <div className={'ml-3 mr-md-3'}>
                  <span className={'input-symbol'}>₹</span>
                  <CurrencyInput
                    id="amount-input"
                    name="amount"
                    value={item?.amount}
                    intlConfig={{ locale: 'en-IN' }}
                    className={'form-control currency-input'}
                    // placeholder="Enter Amount"
                    disabled
                    // value={switchAmountUnit || ''}
                    decimalsLimit={0}
                    allowDecimals={false}
                    // disabled={checkAll || !switchFromFund}
                    // onValueChange={handleAmountChange}
                  />
                </div>

                <div className={'d-flex align-items-center ml-md-3 option-remove-icon'} onClick={() => removeRow(index)}>
                  <BsDashCircle style={{ height: '25px', width: '25px' }} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {errors && errors[step] && <div className={'invalid-feedback d-block mt-4'}>{errors[step]}</div>}
    </>
  );
};

export default OptionsType5;
