import React from 'react';
import { BsFillCircleFill, BsCheckLg } from 'react-icons/bs';

const Stepper = ({ currentStep, questions }) => {
  return (
    <div className={'row'}>
      <div className={'col-12'}>
        <div className="progresses px-md-5">
          {questions?.map((item, index) => (
            <>
              {index <= currentStep ? (
                <div className="step active">
                  <span>
                    {index === currentStep ? (
                      <BsFillCircleFill style={{ height: '6px', width: '6px', minWidth: '6px' }} />
                    ) : (
                      <BsCheckLg style={{ height: '10px', width: '10px', marginRight: '1px' }} />
                    )}
                  </span>
                </div>
              ) : (
                <div className="step">
                  <span></span>
                </div>
              )}
              {index !== 9 && <span className={`line${currentStep > index ? ' completed' : ''}`} />}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Stepper;
