import React, { useEffect, useState } from 'react';
import IndividualOnboarding from './components/InvdividualOnboarding';
import { getUserDetails } from '../../api/profile';
import { decryptData } from '../../utility/DecryptHelper';
import taxStatus from '../../utility/taxStatus';
import { updateKycInfo } from '../../redux/reducers/onboarding-reducer';
import { useDispatch, useSelector } from 'react-redux';
import { getKycData } from '../../api/kyc';
import NriOnboarding from './components/NriOnboarding';
import MinorOnboarding from './components/MinorOnboarding';
import NonIndividualOnboarding from './components/NonIndividualOnboarding';

const Onboarding2 = () => {
  const dispatch = useDispatch();
  const { pan, pan_kyc_status } = useSelector((state) => state.onboardingReducer);
  const kycStatus = useSelector((state) => state.authReducer.rise.tax_status);
  const [kycData, setKycData] = useState({});

  useEffect(() => {
    updatePanData();
  }, []);

  const updatePanData = () => {
    getUserDetails().then((res) => {
      const profileObj = decryptData(res.data.data);
      if (profileObj.data && profileObj.data.pan) {
        if (profileObj.data?.tax_status) {
          dispatch(updateKycInfo({ pan: profileObj.data.pan, pan_kyc_status: profileObj.data.tax_status.id }));
        }
      }
    });
  };

  useEffect(() => {
    getUserKycData();
  }, [pan_kyc_status]);

  const getUserKycData = () => {
    const data = new FormData();
    data.append('pan', pan);
    if (pan_kyc_status) {
      data.append('tax_status', pan_kyc_status);
    } else if (kycStatus && kycStatus.id) {
      data.append('tax_status', kycStatus);
    } else {
      data.append('tax_status', '');
    }
    if (pan) {
      getKycData(data).then((res) => {
        if (res && res.data) {
          const data = decryptData(res.data.data);
          if (data && data.data && data.data.length) {
            setKycData(data.data[0]);
          }
        }
      });
    }
  };

  return (
    <>
      <div className="container onboarding-container colored-box p-md-5">
        {kycData.tax_status_tcs === taxStatus.nri || kycData.tax_status_tcs === taxStatus.nre || kycData.tax_status_tcs === taxStatus.nro ? (
          <>
            <NriOnboarding kycData={kycData} setKycData={setKycData} />
          </>
        ) : kycData.tax_status_tcs === taxStatus.minor ? (
          <>
            <MinorOnboarding kycData={kycData} setKycData={setKycData} />
          </>
        ) : kycData.tax_status_tcs === taxStatus.individual ? (
          <>
            <IndividualOnboarding kycData={kycData} setKycData={setKycData} />
          </>
        ) : (
          <NonIndividualOnboarding kycData={kycData} setKycData={setKycData} />
        )}
      </div>
    </>
  );
};

export default Onboarding2;
