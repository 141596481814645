import axios from './axios';
import elevo_apis from './elevo_apis';

const getUserBanks = (id) => {
  return axios.get(`banks/user-banks/${id}/`);
};
const getUserBanksNri = (id) => {
  return elevo_apis.get(`investors/${id}/banks`);
};
const getBankDetail = () => {
  return axios.get('banks');
};
const saveBankDetails = (id, postData) => {
  return axios.post(`banks/user-banks/`, postData);
};
const updateBankDetails = (id, postData) => {
  return axios.put(`/banks/user-banks/${id}/`, postData);
};
const saveBankDetailsNri = (id, postData) => {
  return elevo_apis.post(`/investors/${id}/banks`, postData);
};
const updateBankDetailsNri = (id, bankId, postData) => {
  return elevo_apis.put(`/investors/${id}/banks/${bankId}`, postData);
};
const setDefaultBank = (id, postData) => {
  return axios.patch(`banks/set-default-account/${id}/`, postData);
};

const bankUserMandate = (investorId) => {
  return axios.get(`/banks/user-mandate/${investorId}/`);
};

const createBankMandate = (postData) => {
  return elevo_apis.post(`/bank-mandates`, postData);
};

const userMandateDetails = (mandateId) => {
  return axios.get(`/banks/user-mandate/get/${mandateId}/`);
};

const downloadMandateDetails = (mandateId) => {
  return elevo_apis.get(`/bank-mandates/${mandateId}/nach`);
};

const uploadMandateDetails = (mandateId) => {
  return elevo_apis.post(`/bank-mandates/${mandateId}/nach`);
};

const getUserMandateDetails = (mandateId) => {
  return elevo_apis.get(`/bank-mandates/${mandateId}`);
};
const getMandateById = (mandateId) => {
  return elevo_apis.get(`/bank-mandates/${mandateId}`);
};

export {
  getUserBanks,
  getUserBanksNri,
  getBankDetail,
  saveBankDetails,
  setDefaultBank,
  updateBankDetails,
  saveBankDetailsNri,
  updateBankDetailsNri,
  bankUserMandate,
  createBankMandate,
  userMandateDetails,
  downloadMandateDetails,
  uploadMandateDetails,
  getUserMandateDetails,
  getMandateById
};
