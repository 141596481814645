import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ReactComponent as AlertIcon } from '../../../assets/alert.svg';

export const NotReadyToInvestModal = () => {
  return (
    <Modal show={true} size='sm' centered>
      <Modal.Body>
        <div className='ResultModal__body_wrapper'>
          <div className='alert-icon-wrapper'>
            <AlertIcon />
          </div>
          <span>Please complete your registration to use this feature</span>
          <Link to={'/home'} className='primary-button'>
            Ok
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  );
};
