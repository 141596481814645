/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updateFatcaData } from '../../api/kyc';
import { addNominee, updateNominee } from '../../api/nominee';
import {
  AnnexureAExamption,
  AnnexureBExamption,
  AnnualIncome,
  CompanyCategory,
  GINNumber,
  NatureBusiness,
  NetWorth,
  NonFinancialCategory,
  Occupation,
  PoliticallyExposed,
} from '../../common/on-boarding';
import {
  annexureARegex,
  annexureBRegex,
  entityNameRegex,
  natureOfBusinessRegex,
  nomineeNameRegex,
  otherRelationRegex,
} from '../../utility/constant';
import { getInvestorsStatus } from '../../utility/investorStatus';
import { NonFinancallyList, TOKEN_KEY } from '../../utility/utility';
import NominationField from '../nomination-field';

const OnBoardingForNonIndivisuals = ({
  fatcaData,
  history,
  entityName,
  onSubmit,
  onFatcaSubmitted,
  handleErrorsBank,
  isPropriter,
  setCommanError,
  commanErrors,
}) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.authReducer.user_id);
  const [annualIncome, setAnnualIncome] = useState('');
  const [netWorth, setNetWorth] = useState({});
  const [netWorthInr, setNetWorthInr] = useState('');
  const [occupation, setOccupation] = useState('');
  const [occupationCode, setOccupationCode] = useState('');
  const [compCategory, setCompCategory] = useState('');
  const [politicallyExposed, setPoliticallyExposed] = useState('');
  const [annexureA, setAnnexureA] = useState('');
  const [natureBusiness, setNatureBusiness] = useState('');
  const [giinNumber, setGIINNumber] = useState('');
  const [nonFincailCat, setNonFincailCat] = useState('');
  const [annexureB, setAnnexureB] = useState('');
  const [errors, setErrors] = useState([]);
  const [nomineeDetails, setNomineeDetails] = useState();
  const [nomineeErrors, setNomineeErrors] = useState([]);
  const [commonNomineeError, setCommonNomineeError] = useState();
  const [nomineeSubmited, setNomineeSubmited] = useState(false);
  const [declarationError, setDeclarationError] = useState();
  const [checked, setChecked] = useState(false);
  const [mobileDeclaration, setMobileDeclaration] = useState(false);
  const [emailDeclaration, setEmailDeclaration] = useState(false);
  const ACTIVENONFINANCIAL = '03';
  const PASSIVENONFINANCIAL = '04';
  useEffect(() => {
    setfatcaData(fatcaData);
  }, [fatcaData]);

  const setfatcaData = (data) => {
    setAnnualIncome(data.income_slab);
    let netWorthYear = data?.net_worth_as_on_date?.split('-');
    setNetWorth({ value: netWorthYear ? netWorthYear[0] : '' });
    setNetWorthInr(data.net_worth_inr);
    setOccupation(data.occ_type);
    setOccupationCode(data.occ_code_other);
    setCompCategory(data.company_category);
    setPoliticallyExposed(
      data.is_entity_politically_exposed_person ? 'Yes' : 'No'
    );
    setAnnexureA(data.exemption_code);
    setNatureBusiness(data.nature_of_bussiness);
    setGIINNumber(data?.giin_number);
    setNonFincailCat(data.non_financial_entity_category);
    setAnnexureB(data.non_financial_entity_sub_category);
    if (
      data.mobile_number &&
      data.email_declaration &&
      data.mobile_declaration
    ) {
      setChecked(true);
      setMobileDeclaration(true);
      setEmailDeclaration(true);
    }
    if (!data.mobile_number && data.email_declaration) {
      setChecked(true);
      setEmailDeclaration(true);
    }
  };

  const onPoliticallyChange = (value) => {
    setPoliticallyExposed(value.value);
    errors['politicallyExposed'] = undefined;
    setErrors(errors);
  };
  const checkCommanValidation = () => {
    let err = [];
    let isValid = true;
    if (netWorthInr.length > 14) {
      isValid = false;
      err['netWorth'] = 'Net worth INR should not exceed 14 digits.';
    }
    if (annexureA && !annexureA.match(annexureARegex)) {
      isValid = false;
      err['annexureA'] = '"AnnexureA" Should be between A to N';
    }
    if (annexureB && !annexureB.match(annexureBRegex)) {
      isValid = false;
      err['annexureB'] =
        'The "AnnexureB" field should only accept values from 01 to 07, or the values "NA" or "N"';
    }
    if (natureBusiness && !natureOfBusinessRegex.test(natureBusiness)) {
      isValid = false;
      err['natureBusiness'] =
        'Nature of Business should allowed only alphabets';
    }
    setErrors(err);
    return isValid;
  };
  const saveFormData = () => {
    if ((isPropriter && validateNomineeDetails()) || !isPropriter) {
      const formData = new FormData();
      if (checkCommanValidation()) {
        formData.append('income_slab', annualIncome);
        formData.append('net_worth_inr', netWorthInr);
        formData.append('occ_type', occupation);
        formData.append('occ_code_other', occupationCode);
        formData.append('company_category', compCategory);
        formData.append(
          'is_entity_politically_exposed_person',
          politicallyExposed === 'Yes' ? true : false
        );
        formData.append('exemption_code', annexureA);
        formData.append('nature_of_bussiness', natureBusiness);
        formData.append('giin_number', giinNumber);
        formData.append('non_financial_entity_category', nonFincailCat);
        formData.append('non_financial_entity_sub_category', annexureB);
        if (entityName) {
          formData.append('full_name', entityName);
        }
        formData.append('is_draft', true);
        if (isPropriter) {
          formData.append('mobile_declaration', mobileDeclaration);
          formData.append('email_declaration', emailDeclaration);
        }
        if (netWorth.value && netWorth.value !== '') {
          formData.append(
            'net_worth_as_on_date',
            netWorth.value && netWorth.value !== ''
              ? `${netWorth.value}-03-31`
              : ''
          );
        }
        updateFatcaData(formData).then((res) => {
          if (res.data.status.code === 1) {
            history.push('/home');
            getInvestorsStatus(userId, dispatch);
          }
        });
        localStorage.setItem('saveAsDraft', true);
        localStorage.setItem(
          'emailDeclaration',
          isPropriter ? emailDeclaration : true
        );
        onSubmit();
        if (isPropriter) {
          saveNomineeDetails();
        }
      }
    }
  };
  const getNomineeTotal = () => {
    let shareCount = 0;
    nomineeDetails.forEach((item) => {
      shareCount = shareCount + parseInt(item.share);
    });
    return shareCount;
  };
  const validateNomineeDetails = (isSubmit) => {
    let isValid = true;
    if (isPropriter) {
      nomineeDetails.forEach((item, index) => {
        let name = item.name?.trim();
        nomineeErrors[index] = {};
        console.log('item.share', item.share);
        if (item.name) {
          if (!item.relation) {
            isValid = false;
            nomineeErrors[index].relation = 'Relation is required';
          }
          if (!item.share) {
            isValid = false;
            nomineeErrors[index].share = 'Share is required';
          }
          if (item.share && parseInt(item.share) === 0) {
            isValid = false;
            nomineeErrors[index].share = 'Share must be valid Number';
          }
          if (!name.match(nomineeNameRegex)) {
            isValid = false;
            nomineeErrors[index].name =
              'Please ensure that the nominee name consists only of alphabets and spaces.';
          }
          if (name?.length !== 0 && (name?.length > 40 || name?.length < 3)) {
            isValid = false;
            nomineeErrors[index].name =
              'Nominee name not allowed more than 40 character and less than 3 character';
          }
          if (name?.length === 0 && isSubmit) {
            isValid = false;
            nomineeErrors[index].name = 'Nominee name is required';
          }
          if (item.others && !item.otherRelation) {
            isValid = false;
            nomineeErrors[index].otherRelation =
              'Please specify the relationship';
          }
          if (
            item.otherRelation &&
            !item.otherRelation.match(otherRelationRegex)
          ) {
            isValid = false;
            nomineeErrors[index].otherRelation =
              'Please ensure that the relationship consists only of alphabets and spaces.';
          }
        } else {
          if (isSubmit) {
            isValid = false;
            nomineeErrors[index].name = 'Nominee name is required';
          }
          if (item.relation || item.share) {
            isValid = false;
            nomineeErrors[index].name = 'Nominee name is required';
            if (!item.relation) {
              nomineeErrors[index].relation = 'Relation is required';
            }
            if (!item.share) {
              nomineeErrors[index].share = 'Share is required';
            }
          }
          if (item.others && !item.otherRelation) {
            isValid = false;
            nomineeErrors[index].otherRelation =
              'Please specify the relationship';
          }
          if (
            item.otherRelation &&
            !item.otherRelation.match(otherRelationRegex)
          ) {
            isValid = false;
            nomineeErrors[index].otherRelation =
              'Please ensure that the relationship consists only of alphabets and spaces.';
          }
        }
      });
      setNomineeErrors([...nomineeErrors]);
      if (getNomineeTotal() > 100) {
        setCommonNomineeError(
          'Total of share for all nominee should be not be greater than 100%'
        );
        isValid = false;
      } else {
        if (isSubmit && getNomineeTotal() < 100) {
          setCommonNomineeError(
            'Total of share for all nominee should be 100%'
          );
          isValid = false;
        }
      }
      if (entityName && !entityName.match(entityNameRegex)) {
        isValid = false;
        let tempErrror = [...commanErrors];
        tempErrror['entityName'] =
          'Please ensure that the entity name consists only of alphabets and spaces.';
        setCommanError(tempErrror);
      }
    }

    return isValid;
  };
  const updateNomineeDetails = (data) => {
    if (data) {
      let request = {
        nominees: data,
      };
      updateNominee(userId, request, localStorage.getItem(TOKEN_KEY)).then(
        (response) => {
          setNomineeSubmited(true);
          getInvestorsStatus(userId, dispatch);
        }
      );
    } else {
      setNomineeSubmited(true);
      getInvestorsStatus(userId, dispatch);
    }
  };
  const addNomineeDetails = (data) => {
    let request = {
      nominees: data,
    };
    addNominee(userId, request, localStorage.getItem(TOKEN_KEY)).then(
      (response) => {
        setNomineeSubmited(true);
        getInvestorsStatus(userId, dispatch);
      }
    );
  };
  const saveNomineeDetails = () => {
    let shareCount = 0;
    nomineeDetails.forEach((item) => {
      shareCount = shareCount + parseInt(item.share);
    });
    if (shareCount > 100) {
      setCommonNomineeError(
        'Total of share for all nominee should be not be greater than 100%'
      );
    } else {
      let addNomineeData = [];
      let updateNomineeData = [];
      let flag = false;
      for (let i in nomineeDetails) {
        if (nomineeDetails[i].id) {
          flag = true;
        } else {
          let data = {};
          if (!flag && nomineeDetails[i].name) {
            data = {
              name: nomineeDetails[i].name.trim(),
              relation: nomineeDetails[i].relation,
              share: parseInt(nomineeDetails[i].share),
              minor: false,
            };
            if (nomineeDetails[i].others) {
              data['other_relation'] = nomineeDetails[i].otherRelation.trim();
            }
            addNomineeData.push(data);
          }
        }
      }
      if (flag) {
        updateNomineeData = nomineeDetails.map((item, i) => {
          const data = {
            name: item.name.trim(),
            relation: item.relation,
            share: parseInt(item.share),
            minor: false,
          };
          if (item.others) {
            data['other_relation'] = nomineeDetails[i].otherRelation.trim();
          }
          return data;
        });
      }
      if (updateNomineeData.length > 0) {
        updateNomineeDetails(updateNomineeData);
      } else if (addNomineeData.length > 0) {
        addNomineeDetails(addNomineeData);
      }
    }
  };

  const submitFormData = () => {
    let isNomineeValid = validateNomineeDetails(true);
    if (validate() && isNomineeValid) {
      const formData = new FormData();
      formData.append('income_slab', annualIncome);
      formData.append('net_worth_inr', netWorthInr);
      formData.append('occ_type', occupation);
      formData.append('occ_code_other', occupationCode);
      formData.append('company_category', compCategory);
      formData.append(
        'is_entity_politically_exposed_person',
        politicallyExposed === 'Yes' ? true : false
      );
      formData.append('exemption_code', annexureA);
      formData.append('nature_of_bussiness', natureBusiness);
      formData.append('giin_number', giinNumber);
      formData.append('non_financial_entity_category', nonFincailCat);
      formData.append('non_financial_entity_sub_category', annexureB);
      formData.append('full_name', entityName);
      formData.append('is_submit', true);
      formData.append('is_draft', false);
      if (isPropriter) {
        formData.append('mobile_declaration', mobileDeclaration);
        formData.append('email_declaration', emailDeclaration);
      }
      if (netWorth.value && netWorth.value !== '') {
        formData.append(
          'net_worth_as_on_date',
          netWorth.value && netWorth.value !== ''
            ? `${netWorth.value}-03-31`
            : ''
        );
      }
      if (isPropriter && !emailDeclaration) {
        setDeclarationError(
          `Please confirm your Email ${
            fatcaData.mobile_number ? 'and Mobile ' : ''
          }Declaration.`
        );
      }
      if ((isPropriter && emailDeclaration) || !isPropriter) {
        updateFatcaData(formData).then((res) => {
          if (res.data.status.code === 1) {
            onFatcaSubmitted();
            getInvestorsStatus();
          }
        });
        onSubmit();
        localStorage.setItem('saveAsDraft', false);
        localStorage.setItem('emailDeclaration', false);
        if (isPropriter) {
          saveNomineeDetails();
        }
      }
    }
  };

  const validate = () => {
    let err = [];
    if (
      !netWorth ||
      !netWorth?.value ||
      !netWorthInr ||
      netWorthInr?.length === 0
    ) {
      err['netWorth'] = 'Net Worth should not be empty.';
    }
    if (netWorthInr.length > 14) {
      err['netWorth'] = 'The net worth INR should not exceed 14 digits.';
    }
    if (!occupation || occupation?.length === 0) {
      err['occupation'] = 'Please select Occupation.';
    } else if (
      occupation === 'O' &&
      (!occupationCode || occupationCode.length === 0)
    ) {
      err['occupation'] = 'Occupation should not be empty';
    }
    if (!compCategory || compCategory?.length === 0) {
      err['compCategory'] = 'Please select Company Category.';
    }
    if (!politicallyExposed) {
      err['politicallyExposed'] = 'Please select your answer.';
    }
    if (!annexureA || annexureA.length === 0) {
      err['annexureA'] = 'Annexure A should not be empty;';
    }
    if (
      nonFincailCat &&
      [ACTIVENONFINANCIAL, PASSIVENONFINANCIAL].includes(nonFincailCat) &&
      (!natureBusiness || natureBusiness.length === 0)
    ) {
      err['natureBusiness'] = 'Nature of Business should not be empty;';
    }
    if (!nonFincailCat || nonFincailCat.length === 0) {
      err['nonFincailCat'] = 'Financialshould not be empty;';
    }
    if (!annexureB || annexureB.length === 0) {
      err['annexureB'] = 'Annexure B should not be empty;';
    }
    if (isPropriter && !entityName.match(entityNameRegex)) {
      let tempErrror = [...commanErrors];
      tempErrror['entityName'] =
        'Please ensure that the entity name consists only of alphabets and spaces.';
      setCommanError(tempErrror);
    }
    checkCommanValidation();

    setErrors(err);
    let status = handleErrorsBank();
    return !((err && Object.keys(err).length > 0) || !status);
  };

  useEffect(() => {
    if (netWorth?.value && netWorthInr?.length > 0) {
      errors['netWorth'] = undefined;
      setErrors(errors);
    }
  }, [netWorth, netWorthInr]);

  useEffect(() => {
    if (occupation?.length > 0) {
      if (occupation === 'O') {
        if (occupationCode?.length > 0) {
          errors['occupation'] = undefined;
          setErrors(errors);
        }
      } else {
        errors['occupation'] = undefined;
        setErrors(errors);
      }
    }
  }, [occupation, occupationCode]);

  const handleOccupationChange = (value) => {
    setOccupation(value);
    if (value !== 'O') {
      errors['occupation'] = undefined;
      setErrors(errors);
    }
  };

  const handleAnnexureAChange = (value) => {
    setAnnexureA(value);
    errors['annexureA'] = undefined;
    setErrors(errors);
  };

  const handleAnnexureBChange = (value) => {
    setAnnexureB(value);
    errors['annexureB'] = undefined;
    setErrors(errors);
  };

  const handleNatureOfBusinessChange = (value) => {
    setNatureBusiness(value);
    errors['natureBusiness'] = undefined;
    setErrors(errors);
  };
  const handleGINNumberChange = (value) => {
    setGIINNumber(value?.trim());
    errors['giinNumber'] = undefined;
    setErrors(errors);
  };

  const handleNonFinCategoryChange = (value) => {
    setNonFincailCat(value);
    errors['nonFincailCat'] = undefined;
    setErrors(errors);
  };

  const handleCompCategoryChange = (value) => {
    setCompCategory(value);
    errors['compCategory'] = undefined;
    setErrors(errors);
  };
  const handleNomineeDetailChange = (data) => {
    setNomineeDetails([...data]);
    nomineeErrors.forEach((err, index) => {
      if (err && err.relation) {
        if (data[index].relation) {
          err.relation = undefined;
        }
      }
      if (err && err?.share) {
        if (data[index].share) {
          err.share = undefined;
        }
      }
      if (err && err.name) {
        if (data[index]?.name?.trim().match(nomineeNameRegex)) {
          err.name = undefined;
        }
      }
      if (err && err.otherRelation) {
        if (data[index]?.otherRelation?.trim().match(otherRelationRegex)) {
          err.otherRelation = undefined;
        }
      }
    });
    setCommonNomineeError(undefined);
    setNomineeErrors([...nomineeErrors]);
  };
  const checkboxHandleChange = (event) => {
    const { checked } = event.target;
    setChecked(checked);
    if (checked) {
      setEmailDeclaration(true);
      if (fatcaData.mobile_number) {
        setMobileDeclaration(true);
      }
      setDeclarationError(undefined);
    } else {
      setEmailDeclaration(false);
      setMobileDeclaration(false);
    }
  };
  const onNetWorthInrChange = (value) => {
    setNetWorthInr(value);
    errors['netWorth'] = undefined;
    setErrors(errors);
  };
  return (
    <section className='col-sm-12'>
      <h6 className='font-weight-bold mt-4'>Details for FATCA</h6>
      <AnnualIncome
        value={annualIncome}
        onChange={setAnnualIncome}
        err={errors && errors['annualIncome']}
      />
      <NetWorth
        networthObj={netWorth}
        amount={netWorthInr}
        onNetWorthChange={(event, data) => {
          setNetWorth(data);
        }}
        onNetWorthInrChange={onNetWorthInrChange}
        err={errors && errors['netWorth']}
      />
      <Occupation
        value={occupation}
        otherValue={occupationCode}
        onRadioChange={handleOccupationChange}
        onInputChange={setOccupationCode}
        err={errors && errors['occupation']}
      />
      <CompanyCategory
        value={compCategory}
        onChange={handleCompCategoryChange}
        err={errors && errors['compCategory']}
      />
      <PoliticallyExposed
        politicallyExposedValue={politicallyExposed}
        onChange={onPoliticallyChange}
        err={errors && errors['politicallyExposed']}
      />
      <AnnexureAExamption
        value={annexureA}
        onChange={handleAnnexureAChange}
        err={errors && errors['annexureA']}
      />
      <NatureBusiness
        value={natureBusiness}
        onChange={handleNatureOfBusinessChange}
        infoIcon={true}
        infoMsg=' Explain in one word or two what is your business about.'
        err={errors && errors['natureBusiness']}
      />
      <GINNumber
        value={giinNumber}
        onChange={handleGINNumberChange}
        infoIcon={false}
        infoMsg=''
        err={errors && errors['giinNumber']}
      />
      <NonFinancialCategory
        options={NonFinancallyList}
        value={nonFincailCat}
        onChange={handleNonFinCategoryChange}
        err={errors && errors['nonFincailCat']}
      />
      <AnnexureBExamption
        value={annexureB}
        onChange={handleAnnexureBChange}
        err={errors && errors['annexureB']}
      />
      {isPropriter && (
        <>
          <div className='border p-4 rounded w-100 my-3'>
            <NominationField
              nomineeDetailChange={handleNomineeDetailChange}
              errors={nomineeErrors}
              commonError={commonNomineeError}
              nomineeSubmited={nomineeSubmited}
            />
          </div>
          <div className='py-2 rounded w-100 '>
            <Form.Check>
              <Form.Check.Input
                id='declaration'
                type='checkbox'
                checked={checked}
                onChange={checkboxHandleChange}
                style={{ cursor: 'pointer' }}
              />
              <Form.Check.Label
                for='declaration'
                onChange={checkboxHandleChange}
                style={{ cursor: 'pointer' }}
              >
                {fatcaData.mobile_number
                  ? 'I confirm that mobile number ' +
                    fatcaData.mobile_number +
                    ' and email ' +
                    fatcaData.email +
                    ' belong to ' +
                    fatcaData.pan_name
                  : 'I confirm that the email id ' +
                    fatcaData.email +
                    ' belong to ' +
                    fatcaData.pan_name}
              </Form.Check.Label>
            </Form.Check>
            {declarationError && (
              <div className={'invalid-feedback d-block mt-2'}>
                {declarationError}
              </div>
            )}
          </div>
        </>
      )}
      <div className='btn-container text-center my-4 col-sm-12'>
        <button
          type='button'
          className='btn btn-outline-primary m-2'
          onClick={saveFormData}
        >
          Save As Draft
        </button>
        <button
          type='button'
          className='btn btn-primary m-2 btn-width'
          onClick={submitFormData}
        >
          Submit
        </button>
      </div>
    </section>
  );
};
export default OnBoardingForNonIndivisuals;
