import React from 'react';
import './Tooltip.scss';
const Tooltip = ({ text, src }) => {
  return (
    <div
      style={{
        textAlign: 'center',
        margin: '10%',
      }}>
      <div class="custom-tooltip">
        <img className="info-image" alt="" src={src} />
        <span class="custom-tooltip-text">{text}</span>
      </div>
    </div>
  );
};

export default Tooltip;
