import axios from 'axios';

const headersForLegacyClient: { [key: string]: string } = {
  'api-key': atob(process.env.REACT_APP_API_KEY!),
};

export const getAppConfig = () => {
  return axios.get(
    process.env.REACT_APP_TARRAKKI_LEGACY_API_URL + `v7/elevo/app-config`,
    {
      method: 'GET',
      headers: headersForLegacyClient,
    }
  );
};
