import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import successfulIcon from '../assets/successful.svg';
import failedIcon from '../assets/not-supported.svg';
import { Link } from 'react-router-dom';
import { regexString } from "../utility/utility";
const RedeemStatusMobile = ({ show,  options }) => {
  return (
    <Modal show={show} size="md" className='p-4' aria-labelledby="confirmation-modal" centered backdrop="static" keyboard={false}>
      <Modal.Body className="p-4">
         <div className="row justify-content-center align-items-center h-100">
         <div className="col-sm-7 px-0">
         <div className=" mb-4 text-center">
            <div className="img-container mb-4 ">
              <img src={options.code === 1 ? successfulIcon : failedIcon} alt="successful icon" className="w-30" />
            </div>
            <h5 className="font-weight">{options.code === 1 ? "Your transaction has been successful" : "Unfortunately, your  transaction has been failed, try again"}</h5>
         </div>    
         </div>
         <div className="col-sm-7 px-0 ">
         <div className="border-top border-bottom mx-3 pt-3">
           <Link to={{pathname:`/fund/detail/${regexString(options.data.dataObj.name)}/${options.data.dataObj.fundId}`}} className="h6">{options.data.dataObj.name}</Link>
           <div className="my-2 opacity-75">
              <span className="ml-0 ">{options?.data?.dataObj?.type==="units"? "Units redeemed :" :"Amount redeemed :" }</span>
              <span className="font-weight-bold ml-0"> {options?.data?.dataObj?.type==="units"? null :<span>&#8377;</span>} {options.data.dataObj.redeemVal} </span>
            </div>
           <div className=" mb-4">        
           <span className="pt-2 opacity-75 ml-0">Withdrawal sent to AMC.</span>  
           {options.code === 1 ? <Button variant="success" className="float-right br-light py-0 " disabled>Successful</Button> : <Button variant="danger" className="float-right br-light py-0" disabled>Failed</Button>}
           </div>       
           {options.code === 1 ? null :  <hr />}
           {options.code === 1 ? null : <span className='ml-0 c-red'>{"Remark:Failed, Order quantity is less than minimum specified for scheme"}</span>}
           </div>
         </div>    
         </div>
         <div className="text-center  px-5 ">
         <Link to="/my-portfolio"><Button className="btn btn-primary w-80 py-2 mt-3" >Back to portfolio</Button></Link>
        </div>
      </Modal.Body>
    </Modal>
  );
};
RedeemStatusMobile.propTypes = {
  message: PropTypes.string,
  header: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  options: PropTypes.object
};
export default RedeemStatusMobile;
