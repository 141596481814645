import React, { useRef, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import {AiOutlineClose} from "react-icons/ai"
import SignaturePad from "react-signature-canvas";
import "../component/digital-signature/signCanvas.scss";

const SignatureModal = ({ show, onSubmit, onClose, message, header }) => {
  const sigCanvas = useRef({});
  const [rect, setRect] = useState();
  const [err,setErr]= useState()
  const clear = () => {
    sigCanvas.current.clear()
  }
  const save = () => {

    if(sigCanvas.current._sigPad._data.length>0) {
      setErr("")
      const url = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
      console.log('url',url)
      let file = Math.random().toString(36).slice(2, 7)
      const fileName = file+".jpg";
      fetch(url)
        .then(async response => {
          const contentType = response.headers.get('content-type')
          const blob = await response.blob()
          const file = new File([blob], fileName, { contentType })
          onSubmit(file)
        })
    }
    else {
      setErr("Signature can't be blank")
    }
 
  };

  useEffect(() => {
    const elem = document.getElementById("dignature-box-div")
    if(elem) {
      const elementRect = elem.getBoundingClientRect();
      setRect(elementRect)
    }
  })

  return (
    <Modal show={show} size="md" aria-labelledby="confirmation-modal" centered backdrop="static" keyboard={false} onHide={onClose}>
        <Modal.Header closeButton>
          <h4  className="font-weight-medium  mt-3 mb-3 sign-head">
            Draw your Signature below
          </h4>
        </Modal.Header>
      <Modal.Body className="py-0">
        <div className='border m-0' id={"dignature-box-div"}>

          <button id="cross" onClick={clear}><AiOutlineClose  className='cross' /></button>
          <div className="mt-2" style={{minHeight: '200px', maxHeight: '200px'}}>
            <>
              {rect &&
                <SignaturePad
                  ref={sigCanvas}
                  penColor="blue"
                  style={{position: 'absolute', top: 0}}
                  canvasProps={{ width: rect.width, height: rect.height, className: 'sigCanvas' }}
                />
              }
            </>
          </div>
        </div>
        {err && (
          <span>
            <p className="error-message">{err}</p>
          </span>
        )}
      </Modal.Body>
      <Modal.Footer className="btn-container mb-3 text-center justify-content-center">
        <div className='d-flex justify-content-center text-center mb-4'>
          <button onClick={clear} className="btn btn-primary mr-3">CLEAR</button>
          <button onClick={save} className='btn btn-outline-primary ml-3 w-40'>SUBMIT</button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default SignatureModal;
