import React, { useEffect, useRef, useState } from 'react';
import { uploadSingleDocument } from '../../../api/kyc';
import {addNominee, updateNominee } from '../../../api/nominee';
import { decryptData } from '../../../utility/DecryptHelper';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { InputBox, RadioGroups, SelectBox } from '../../../common/form';
import { DocumentUpload } from '../../../common/on-boarding';
import Toast from '../../../common/toast/Toast';
import EditBankAccount from '../../../component/bank-accounts/EditBankAccount';
import NominationField from '../../../component/nomination-field';
import { Form } from 'react-bootstrap';
import { placeofbirthRegex } from '../../../utility/constant';
import {
  getInvestorDetails,
  saveInvestorDetails,
  updateInvestorDetails,
} from '../../../api/onboarding.api';
import { AnnualIncomeListNri, wealthSourceListNri } from '../../../utility/utility';



const IndividualOnboarding = ({kycData, setKycData}) => {
  const history = useHistory();
  const {pan_kyc_status } = useSelector((state) => state.onboardingReducer);
  const userId = useSelector((state) => state.authReducer.user_id);
  const [annualIncome, setAnnualIncome] = useState();
  const [placeOfBirth, setPlace] = useState();
  const [wealthSource, setWealthSource] = useState();
  const [birthCountry, setBirthCountry] = useState('India');
  const [placeOfBirthErr, setPlaceOfBirthErr] = useState();
  const [checked, setChecked] = useState(false);
  const [notPoliticallyExposedCheck, setNotPoliticallyExposedCheck] = useState(false);
  const [mobileDeclaration, setMobileDeclaration] = useState(false);
  const [emailDeclaration, setEmailDeclaration] = useState(false);
  const [declarationError, setDeclarationError] = useState();
  const [politicallyExposedError, setPoliticallyExposedError] = useState();
  const [nomineeDetails, setNomineeDetails] = useState();
  const [nomineeErrors, setNomineeErrors] = useState([]);
  const [commonNomineeError, setCommonNomineeError] = useState();
  const [fatcaSubmited, setFatcaSubmited] = useState(false);
  const [nomineeSubmited, setNomineeSubmited] = useState(false);
  const [bankUpdated, setBankUpdated] = useState(false);
  const [errors, setErrors] = useState({});

  const childRef = useRef();
  const childRefDoc = useRef();

  useEffect(() => {
    setfatcaData(kycData);
  }, [kycData])

  useEffect(() => {
    getKycData();
  }, []);

  const getKycData = () => {
    getInvestorDetails(userId).then(res => {
      setAnnualIncome(res.data.fatca_detail.income_slab);
      setPlace(res.data.fatca_detail.birth_place);
      setWealthSource(res.data.fatca_detail.income_source);
      setBirthCountry(res.data.fatca_detail.birth_country);
    })
  };

  const uploadSingleDocuments = (docObj, files) => {
    const postObj = new FormData();
    postObj.append('doc', files);
    postObj.append('doc_type', docObj.id);
    uploadSingleDocument(postObj).then((res) => {
      if (res && res.data) {
        if (res.data.status.message && res.data.status.code === 1) {
          Toast.success(res.data.status.message);
        }
        const data = decryptData(res.data.data);
        if (data && data.data && data.data.length) {
          setKycData(data.data[0]);
        }
      }
    });
  };

  const setfatcaData = (data) => {
    console.log('DAT ', data);
    setNotPoliticallyExposedCheck(!data.is_entity_politically_exposed_person);
    if (data.mobile_number && data.email_declaration && data.mobile_declaration) {
      setChecked(true);
      setMobileDeclaration(true);
      setEmailDeclaration(true);
    }
    if (!data.mobile_number && data.email_declaration) {
      setChecked(true);
      setEmailDeclaration(true);
    }
  };

  const multiDocUpload = () => {
    // getUserKycData();
  };


  const onBankSubmitted = () => {
    console.log('UPDATED BANK');
    setBankUpdated(true);
  };

  const onBirthPlaceChange = (event) => {
    let inputValue = event.target.value || '',
      placeErr = '';
    if (!inputValue) {
      setPlace('');
    } else if (!placeofbirthRegex.test(inputValue)) {
      placeErr = 'Please enter a Place of birth without spaces or characters like("-", "&", "@", etc)';
    } else {
      placeErr = '';
      setPlace(inputValue);
    }
    setPlaceOfBirthErr(placeErr);
  };

  const saveFormData = () => {
    if (validateNomineeDetails()) {
      const formData = new FormData();
      formData.append('income_slab', annualIncome);
      formData.append('place_of_birth', placeOfBirth);
      formData.append('country_of_birth', birthCountry);
      if (wealthSource !== undefined || !wealthSource) {
        formData.append('wealth_src', wealthSource);
      }
      formData.append('mobile_declaration', mobileDeclaration);
      formData.append('email_declaration', emailDeclaration);
      formData.append('is_entity_politically_exposed_person', !notPoliticallyExposedCheck);
      // updateFatcaData(formData).then((res) => {
      //   if (res.data.status.code === 1) {
      //     history.push('/home');
      //   }
      // });

      const req = {
        "investor_type": "individual",
        "first_name": kycData.pan_name,
        "pan": kycData.pan,
        "email": kycData.email,
        "mobile": kycData.mobile_number,
        "email_declaration": emailDeclaration ? 'self' : undefined,
        "mobile_declaration": mobileDeclaration ? 'self' : undefined,
        "fatca_detail": {
          "income_source": wealthSource,
          "income_slab": annualIncome,
          "birth_place": placeOfBirth,
          "birth_country": birthCountry
        }
      }
      saveInvestorDetails(userId, req).then(response => {
        console.log("response:: ", response)
        history.push('/home');
      }).catch(error => {
        console.error(error)
      })

      onSubmit();
      saveNomineeDetails();
    }
  };
  const onSubmit = () => {
    if (kycData.bank_details.id) {
      childRef.current.updateNewBank();
    } else {
      childRef.current.addNewBank();
    }
  };

  const submitFormData = () => {
    let isNomineeValid = validateNomineeDetails(true);
    if (validateData() && isNomineeValid) {
      console.log('validated');
      const req = {
        "investor_type": "individual",
        "first_name": kycData.pan_name,
        "pan": kycData.pan,
        "email": kycData.email,
        "mobile": kycData.mobile_number,
        "email_declaration": emailDeclaration ? 'self' : '',
        "mobile_declaration": mobileDeclaration ? 'self' : '',
        "fatca_detail": {
          "income_source": wealthSource,
          "income_slab": annualIncome,
          "birth_place": placeOfBirth,
          "birth_country": birthCountry
        }
      }
      const formData = new FormData();
      formData.append('income_slab', annualIncome);
      formData.append('place_of_birth', placeOfBirth);
      formData.append('country_of_birth', birthCountry);
      formData.append('wealth_src', wealthSource);
      formData.append('is_submit', true);
      formData.append('mobile_declaration', mobileDeclaration);
      formData.append('email_declaration', emailDeclaration);
      formData.append('is_entity_politically_exposed_person', !notPoliticallyExposedCheck);
      if (!notPoliticallyExposedCheck) {
        setPoliticallyExposedError('Please confirm you are not politically exposed');
      }
      if (!emailDeclaration) {
        setDeclarationError(`Please confirm your Email ${kycData.mobile_number ? 'and Mobile ' : ''}Declaration.`);
      }
      console.log("REQ BODY ::: ", req)
      if (emailDeclaration && notPoliticallyExposedCheck) {

        updateInvestorDetails(userId, req).then(response => {
          console.log("response:: ", response)
          setFatcaSubmited(true);
          onSubmit();
          submitNomineeDetails();
        }).catch(error => {
          console.error(error)
        })


        // updateFatcaData(formData).then((res) => {
        //   if (res.data.status.code === 1) {
        //     setFatcaSubmited(true);
        //   }
        // });
        onSubmit();
        submitNomineeDetails();
      }
    }
  };

  useEffect(() => {
    if (fatcaSubmited && bankUpdated) {
      history.push('/home');
    }
  }, [fatcaSubmited, bankUpdated]);

  const validateData = () => {
    let errors = [];
    console.log('validate ', wealthSource, wealthSource.length, birthCountry);

    if (!wealthSource || wealthSource.length === 0) {
      errors['wealthSource'] = 'Wealth Source is required.';
    }
    if (!birthCountry || birthCountry.length === 0) {
      errors['birthCountry'] = 'Country of Birth is required.';
    }
    if (!placeOfBirth || placeOfBirth.length === 0) {
      setPlaceOfBirthErr('Place of Birth is required.');
    }
    setErrors(errors);
    let status = handleErrorsBank();
    if ((errors && Object.keys(errors).length > 0) || !status) {
      return false;
    }
    return true;
  };
  const handleErrorsBank = () => {
    let status = childRef.current.validate()
    let status1 = childRefDoc.current.validateDoc()
    if(status && status1) {
      return true
    }
    return false;;
  }

  const validateNomineeDetails = (isSubmit) => {
    let isValid = true;
    console.log('Nomination Errors validate', nomineeDetails);
    nomineeDetails.forEach((item, index) => {
      console.log('Nomination Errors validate for', item, index);
      nomineeErrors[index] = {};
      if (item.name) {
        if (!item.relation) {
          isValid = false;
          nomineeErrors[index].relation = 'Relation is required';
        }
        if (!item.relation) {
          isValid = false;
          nomineeErrors[index].share = 'Relation is required';
        }
      }
    });
    console.log('Nomination Errors errorrr', nomineeErrors);
    setNomineeErrors([...nomineeErrors]);
    if (getNomineeTotal() > 100) {
      setCommonNomineeError('Total of share for all nominee should be not be greater than 100%');
      isValid = false;
    }
    if (isSubmit && getNomineeTotal() < 100) {
      setCommonNomineeError('Total of share for all nominee should be 100%');
      isValid = false;
    }
    return isValid;
  };

  const checkboxHandleChange = (event) => {
    const { checked } = event.target;
    setChecked(checked);
    if (checked) {
      setEmailDeclaration(true);
      if (kycData.mobile_number) {
        setMobileDeclaration(true);
      }
      setDeclarationError(undefined);
    } else {
      setEmailDeclaration(false);
      setMobileDeclaration(false);
    }
  };

  const handleWealthSourceChange = (value) => {
    setWealthSource(value);
    errors['wealthSource'] = '';
    setErrors(errors);
  };

  const politicallyExposedChange = (event) => {
    const { checked } = event.target;
    setNotPoliticallyExposedCheck(checked);
    if (checked) {
      setPoliticallyExposedError(undefined);
    }
  };

  const handleNomineeDetailChange = (data) => {
    setNomineeDetails([...data]);
    nomineeErrors.forEach((err, index) => {
      if (err && err.relation) {
        if (data[index].relation) {
          err.relation = undefined;
        }
      }
      if (err && err.share) {
        if (data[index].share) {
          err.share = undefined;
        }
      }
    });
    setCommonNomineeError(undefined);
    setNomineeErrors([...nomineeErrors]);
  };

  const submitNomineeDetails = () => {
    let shareCount = 0;
    nomineeDetails.forEach((item) => {
      shareCount = shareCount + item.share;
    });
    if (shareCount < 100) {
      setCommonNomineeError('Total of share for all nominee should be 100%');
    } else {
      saveNomineeDetails();
    }
  };

  const getNomineeTotal = () => {
    let shareCount = 0;
    nomineeDetails.forEach((item) => {
      shareCount = shareCount + parseInt(item.share);
    });
    return shareCount;
  };

  const saveNomineeDetails = () => {
    let shareCount = 0;
    nomineeDetails.forEach((item) => {
      shareCount = shareCount + parseInt(item.share);
    });
    console.log('SHARE COUNT ', shareCount);
    if (shareCount > 100) {
      setCommonNomineeError('Total of share for all nominee should be not be greater than 100%');
    } else {
      for (let i in nomineeDetails) {
        if (nomineeDetails[i].id) {
          console.log('loop NomineeDetails', nomineeDetails[i]);
          updateNomineeDetails(nomineeDetails[i]);
        } else {
          addNomineeDetails(nomineeDetails[i]);
        }
      }
    }
  };

  const updateNomineeDetails = (data) => {
    console.log('updateNomineeDetails : ', data);
    if (data?.name) {
      let request = {
        name: data.name,
        relation: data.relation,
        share: parseInt(data.share),
        minor: false,
      };
      updateNominee(userId, data.id, request).then((response) => {
        setNomineeSubmited(true);
      });
    } else {
      setNomineeSubmited(true);
    }
  };

  const addNomineeDetails = (data) => {
    if (data?.name) {
      let request = {
        name: data.name,
        relation: data.relation,
        share: parseInt(data.share),
        minor: false,
      };
      addNominee(userId, request).then((response) => {
        setNomineeSubmited(true);
      });
    } else {
      setNomineeSubmited(true);
    }
  };

  const onRadioChange = (event) => {
    const { value } = event.target;
    setAnnualIncome(value);
  };

  return(
    <div>
      <div className="col-sm-12">
        <InputBox
          id="onboarding-name"
          title={'Full Name'}
          value={kycData?.pan_name}
          disabled
        />
      </div>
      <div className="col-sm-12 mt-3">
        <InputBox
          id="onboarding-address"
          title={'Address'}
          value={kycData.address}
          disabled
        />
      </div>
      <div className="col-sm-12 mt-3">
        <h6 className="font-weight-bold my-4">Documents</h6>
        <DocumentUpload
          kycData={kycData}
          documentList={kycData?.document_list}
          panStatus={pan_kyc_status}
          singleDocUpload={uploadSingleDocuments}
          multiDocUpload={multiDocUpload}
          ref={childRefDoc}
        />
      </div>
      <section className="col-sm-12">
        <h6 className="font-weight-bold my-4">Bank Details</h6>
        <div className="border p-4 rounded">
          {kycData.bank_details && (
            <EditBankAccount
              alignment="center"
              ref={childRef}
              buttonText="Submit"
              panStatus={pan_kyc_status}
              bankDetail={kycData.bank_details}
              onBankSubmitted={onBankSubmitted}
              onboarding={true}
            />
          )}
        </div>
      </section>
      <section className="col-sm-12">
        {console.log('Nomination Errors details', nomineeDetails)}
        <h6 className="font-weight-bold mt-4">Details for FATCA</h6>
      <>
        <div className="border p-4 rounded w-100 my-3">
          <SelectBox
            title="Wealth Source"
            value={wealthSource}
            id="WealthSource"
            classes={{ root: 'col-sm-3 px-0 ' }}
            onUpdate={handleWealthSourceChange}
            options={wealthSourceListNri}
            placeholder="Select wealth source"
            err={errors && errors['wealthSource']}
          />
        </div>
        {/*<AnnualIncome value={annualIncome} onChange={setAnnualIncome} />*/}
        <div className="border p-4 rounded w-100 my-3">
          <RadioGroups
            title="Annual Income"
            id="annualIncome"
            radioList={AnnualIncomeListNri}
            selectedVal={annualIncome}
            onChange={onRadioChange}
          />
        </div>
        <div className="border p-4 rounded w-100 my-3">
          <InputBox
            id="PlaceofBirth"
            className="col-sm-5"
            labelClass="opacity-75 h6"
            onChange={onBirthPlaceChange}
            title="Place of Birth"
            value={placeOfBirth}
            type="text"
            maxLength={40}
            err={placeOfBirthErr}
          />
        </div>
        <div className="border p-4 rounded w-100 my-3">
          <NominationField
            nomineeDetailChange={handleNomineeDetailChange}
            errors={nomineeErrors}
            commonError={commonNomineeError}
            nomineeSubmited={nomineeSubmited}
          />
        </div>
        <div className="border p-4 rounded w-100 my-3">
          <SelectBox
            title="Country of Birth"
            value={birthCountry}
            id="countryBirth"
            classes={{ root: 'col-sm-3 px-0' }}
            onUpdate={setBirthCountry}
            options={[{ label: 'India', value: 'India' }]}
            placeholder="Select country of birth"
            err={errors && errors['birthCountry']}
          />
        </div>
        {console.log('FATCAA ::: ', kycData)}
        <div className="border p-4 rounded w-100 my-3">
          <Form.Check>
            <Form.Check.Input id="declaration" type="checkbox" checked={checked} onChange={checkboxHandleChange} style={{ cursor: 'pointer' }} />
            <Form.Check.Label for="declaration" onChange={checkboxHandleChange} style={{ cursor: 'pointer' }}>
              {kycData.mobile_number
                ? 'I confirm that mobile number ' + kycData.mobile_number + ' and email ' + kycData.email + ' belong to ' + kycData.pan_name
                : 'I confirm that the email id ' + kycData.email + ' belong to ' + kycData.pan_name}
            </Form.Check.Label>
          </Form.Check>
          {declarationError && <div className={'invalid-feedback d-block mt-2'}>{declarationError}</div>}
          <Form.Check className={'mt-3'}>
            <Form.Check.Input
              id="politicallyExposed"
              type="checkbox"
              checked={notPoliticallyExposedCheck}
              onChange={politicallyExposedChange}
              style={{ cursor: 'pointer' }}
            />
            <Form.Check.Label for="politicallyExposed" onChange={politicallyExposedChange} style={{ cursor: 'pointer' }}>
              {'I confirm I am not politically exposed'}
            </Form.Check.Label>
          </Form.Check>
          {politicallyExposedError && <div className={'invalid-feedback d-block mt-2'}>{politicallyExposedError}</div>}
        </div>
        <div className=" btn-container text-center my-4  col-sm-12">
          <button type="button" className="btn btn-outline-primary m-2" onClick={saveFormData}>
            Save As Draft
          </button>
          <button type="button" className="btn btn-primary m-2 btn-width" onClick={submitFormData}>
            Submit
          </button>
        </div>
      </>
      </section>
    </div>
  )
};

export default IndividualOnboarding;

