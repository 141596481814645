import React from 'react';
import PropTypes from 'prop-types';
import SwitchButton from './SwitchButton';
const CheckboxGroups = ({ onCheckboxUpdate, selectedValues, checkboxList, id, keyId, keyValue }) => {
  const onCheckboxChange = (event) => {
    const { value, checked } = event;
    if (checked) {
      selectedValues.push(value);
    } else {
      const index = selectedValues.indexOf(value);
      selectedValues.splice(index, 1);
    }
    if (onCheckboxUpdate) {
      onCheckboxUpdate(selectedValues);
    }
  };

  return (
    <fieldset>
      {checkboxList &&
        checkboxList.length &&
        checkboxList.map((item, index) => (
          <SwitchButton
            key={id + index}
            label={keyValue ? item[keyValue] : item.value}
            isChecked={selectedValues.indexOf((item[keyId] || item.label).toString()) > -1}
            className="my-2"
            value={keyId ? item[keyId] : item.label}
            id={id + index}
            onChange={onCheckboxChange}
          />
        ))}
    </fieldset>
  );
};
CheckboxGroups.propTypes = {
  id: PropTypes.string,
  selectedValues: PropTypes.array,
  checkboxList: PropTypes.array,
  onCheckboxUpdate: PropTypes.func,
  keyId: PropTypes.string,
  keyValue: PropTypes.string,
};
export default CheckboxGroups;
