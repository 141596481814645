import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MandateState {
  bank_id: string;
  autopay_limit: string;
}

const initialState: MandateState = {
  bank_id: '',
  autopay_limit: '',
};

const mandateSlice = createSlice({
  name: 'mandate',
  initialState,
  reducers: {
    updateBankIDAction: (state, action: PayloadAction<string>) => {
      state.bank_id = action.payload;
    },
    updateAutoPayLimitAction: (state, action: PayloadAction<string>) => {
      state.autopay_limit = action.payload;
    },
  },
});

export const { updateBankIDAction, updateAutoPayLimitAction } = mandateSlice.actions;

export default mandateSlice.reducer;
