import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { getMonth, getYear } from 'date-fns';
import range from "lodash/range";

const InputDate = ({ onChange, id, title, containerClass = '', labelClass = '', value = '',maxDate = '', err = '', className = '', placeholder = '', onClick = '', onSelect = '', srOnly = false, disabled }) => {
 
  let minorAge = new Date();
  minorAge = minorAge.setFullYear(minorAge?.getFullYear()-18);
  let minorDate =new Date(minorAge);
  const years = range(1990, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
 
  return (
    <Form.Group className={containerClass}>
      <Form.Label className={'opacity-50 mb-1 ' + labelClass} srOnly={srOnly}>
        {title}
      </Form.Label>
      <DatePicker
      id={id}
      selected={value}
      onChange={onChange}
      onClick={onClick}
      onSelect={onSelect}
      maxDate={maxDate}
      minDate={minorDate}
      dateFormat="dd/MM/yyyy"
      placeholderText={placeholder}
      autoComplete="off"
      disabled={disabled}
      className={className + ' updated-input font-weight-medium pl-0' + (err ? 'is-invalid ' : '')}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        decreaseYear,
        increaseYear,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
        prevYearButtonDisabled,
        nextYearButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button onClick={decreaseYear} disabled={prevYearButtonDisabled}>
            {"<"}
          </button>
          <select
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <button onClick={increaseYear} disabled={nextYearButtonDisabled}>
            {">"}
          </button>
          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {"<"}
          </button>
          <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {">"}
          </button>
        </div>
      )}
     />
     <Form.Control.Feedback type="invalid">{err}</Form.Control.Feedback>
    </Form.Group>
  );
};
InputDate.propTypes = {
  srOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  containerClass: PropTypes.string,
  className: PropTypes.string,
  err: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  selected: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChangeRaw: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onSelect: PropTypes.func,
  labelClass: PropTypes.string,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
};
export default InputDate;
