import axios from './axios';
import elevo_apis from './elevo_apis';
const getFundDetail = (id, user_id) => {
  return axios.get(`fund-details/${id}/?user_id=${user_id}`);
};
const getFunds = (limit, offset, searchByName, sortBy, filter, searchByAmcName) => {
  const arrParams = [];
  if (limit != null) {
    arrParams.push('limit=' + limit);
  }
  if (offset != null) {
    arrParams.push('offset=' + offset);
  }
  if (searchByName) {
    arrParams.push('search_by_name=' + searchByName);
  }
  if (sortBy) {
    arrParams.push('sort_by=' + sortBy);
  }
  arrParams.push('organization=3');
  if (filter) {
    arrParams.push('filters=' + filter);
  }
  if (searchByAmcName) {
    arrParams.push('search_by_amc_name=' + searchByAmcName);
  }
  const strParams = arrParams.join('&');
  return axios.get('fund-details/get_fund/?' + strParams);
};
const getInvestmentCategoryFund = (categoryName) => {
  return elevo_apis.get(`/funds?sub_category=${categoryName}`);
};

const getTopFunds = (limit, offset) => {
  return axios.get('/elevo/funds', {
    params: {
      limit: limit ?? 10,
      offset: offset ?? 0,
      top_picks: true,
    },
  });
}

export { getFundDetail, getFunds, getInvestmentCategoryFund, getTopFunds };
