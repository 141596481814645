import axios from './axios';
import elevo_apis from './elevo_apis';
const getCartItems = () => {
  return axios.get('cart/');
};
const deleteCartItem = (id) => {
  return axios.delete('cart/add_to_cart/' + id+"/");
};
const saveCartItems = (postData) => {
  return axios.post('cart/add_to_cart/', postData);
};
const updateCartItems = (id, postData) => {
  return axios.patch('cart/add_to_cart/' + id, postData);
};

const orderConfirm = (postData) => {
  return axios.post(`cart/confirm/order/`, postData);
};
const orderMandateConfirm = (id, postData) => {
  return axios.put(`cart/confirm/order/${id}`, postData);
};

const orderPaymentNew = (postData) => {
  return elevo_apis.post(`payments`, postData);
};
const orderPayment = (postData) => {
  return axios.post(`cart/checkout/`, postData);
};
const validateOrderPayment = (userId, postData) => {
  return axios.post(`transactions/validate-order-payment/${userId}/`, postData);
};
const checkPaymentStatus = (userId, postData) => {
  return axios.post(`transactions/get-payment-status/${userId}/`, postData);
};

const paymentV2 = (reqData) => {
  return elevo_apis.post(`/payments`, reqData);
}
export { getCartItems, deleteCartItem, saveCartItems, updateCartItems, orderPayment,orderPaymentNew, orderConfirm, validateOrderPayment, checkPaymentStatus, orderMandateConfirm, paymentV2 };
