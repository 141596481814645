import { useEffect, useState } from 'react';
import { MdGroups } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../redux/hooks';
import {
  constructFdAppURL,
  hasAdminAccess,
  hasAssistModePermission,
} from '../../../utility/utility';
import SvgIcon from '../../shared/component/Icon';

const MENU_LIST = [
  {
    id: 'home',
    title: 'Dashboard',
    path: '/home',
  },
  {
    id: 'investment',
    title: 'Invest',
    path: '/funds/all',
  },
  {
    id: 'portfolio',
    title: 'My Portfolio',
    path: '/my-portfolio',
  },
  {
    id: 'cart',
    title: 'Cart',
    path: '/cart',
  },
  {
    id: 'transaction',
    title: 'Transactions',
    path: '/transactions',
  },
  {
    id: 'users',
    title: 'Users',
    path: '/users',
  },
  {
    id: 'user_groups',
    title: 'User Groups',
    path: '/user-groups',
  },
];

const MENU_LIST_MOBILE = [
  {
    id: 'home',
    title: 'Dashboard',
    path: '/home',
  },
  {
    id: 'investment',
    title: 'Invest',
    path: '/funds/all',
  },
  {
    id: 'portfolio',
    title: 'My Portfolio',
    path: '/my-portfolio',
  },
  {
    id: 'cart',
    title: 'Cart',
    path: '/cart',
  },
  {
    id: 'transaction',
    title: 'Transactions',
    path: '/transactionsmobile',
  },
  {
    id: 'users',
    title: 'Users',
    path: '/users',
  },
  {
    id: 'user_groups',
    title: 'User Groups',
    path: '/user-groups',
  },
];

const SidebarMenu = ({ toggleSidebar }) => {
  const { fdAppURL, isMutualFundInScope, permissions } = useAppSelector(
    (state) => state.authReducer
  );
  const location = useLocation();
  const [menuList, setMenuList] = useState(MENU_LIST);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const canViewUser = hasAssistModePermission(permissions);
  const canAccessUserGroups = hasAdminAccess(permissions);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (windowDimensions && windowDimensions?.width < 992) {
      setMenuList(MENU_LIST_MOBILE);
    } else {
      setMenuList(MENU_LIST);
    }
  }, [windowDimensions]);

  const checkActivePage = (path) => {
    return location.pathname.includes(path);
  };

  const pageChange = () => {
    if (windowDimensions?.width < 992) {
      toggleSidebar();
    }
  };

  return (
    <>
      <div className={'sidebar-menu-list'}>
        {menuList.map((link, index) => {
          const isActive = checkActivePage(link.path);
          if (!isMutualFundInScope && link.id === 'cart') {
            return null;
          }
          if (!canViewUser && link.id === 'users') {
            return null;
          }
          if (!canAccessUserGroups && link.id === 'user_groups') {
            return null;
          }
          if (link.id === 'investment') {
            return (
              <a
                key={`sidebar-item-${index}`}
                className={`sidebar-menu-item sidebar-menu-link ${
                  isActive ? 'link-active' : ''
                }`}
                href={constructFdAppURL(`${fdAppURL}`)}
                title={link.title}
                onClick={pageChange}
              >
                <div className={'ml-0 mb-1'}>
                  <SvgIcon id={link.id} color={isActive && '#262E4A'} />
                </div>
                <span className={'menu-title'}>{link.title}</span>
              </a>
            );
          }
          return (
            <Link
              key={`sidebar-item-${index}`}
              className={`sidebar-menu-item sidebar-menu-link ${
                isActive ? 'link-active' : ''
              }`}
              to={link.path}
              title={link.title}
              onClick={pageChange}
            >
              <div className={'ml-0 mb-1'}>
                {link.id === 'user_groups' ? (
                  <MdGroups size={24} />
                ) : (
                  <SvgIcon id={link.id} color={isActive && '#262E4A'} />
                )}
              </div>
              <span className={'menu-title'}>{link.title}</span>
            </Link>
          );
        })}
      </div>
    </>
  );
};

export default SidebarMenu;
