import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './ToggleSwitch.scss';
import { Button } from 'react-bootstrap';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function. The props name, small, disabled
and optionLabels are optional.
Usage: <ToggleSwitch id="id" checked={value} onChange={checked => setValue(checked)}} />
*/

const ToggleSwitch = ({ id, selected, onChange, optionLabels }) => {
  const [selectVal, setSelection] = useState('');
  useEffect(() => {
    if (selected) {
      setSelection(selected);
    } else if (optionLabels && optionLabels.length) {
      setSelection(optionLabels[0]);
    }
  }, [selected]);

  const handleClick = (event) => {
    const { id } = event.target;
    setSelection(id);
    onChange(id);
  };

  return (
    <div className={'toggle-switch d-flex align-items-center'} id={id}>
      {optionLabels.map((item) => (
        <Button
          variant={selectVal === item ? 'primary button-selected' : 'link'}
          key={item}
          id={item}
          className="toggle-button w-50"
          onClick={handleClick}>
          {item}
        </Button>
      ))}
    </div>
  );
};

ToggleSwitch.defaultProps = {
  optionLabels: ['Yes', 'No'],
};

ToggleSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  selected: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  optionLabels: PropTypes.array,
};

export default ToggleSwitch;
