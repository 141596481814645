import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
const ConfirmationModal = ({ show, onConfirm, onClose, message, header }) => {
  return (
    <Modal show={show} size="md" aria-labelledby="confirmation-modal" centered backdrop="static" keyboard={false}>
    {header && (
      <Modal.Header>
        <span className="font-weight-medium h5">{header}</span>
      </Modal.Header>
    )}
  <Modal.Body className="py-0">
    <span>{message || 'Are you Sure?'}</span>
  </Modal.Body>
  <Modal.Footer className="btn-container mb-3 text-right">
    <Button variant="primary" onClick={onConfirm} className="btn-sm">
    Yes
    </Button>
  <Button variant="danger" onClick={onClose} className="btn-sm ml-3">
    No
  </Button>
  </Modal.Footer>
</Modal>
  );
};
ConfirmationModal.propTypes = {
  message: PropTypes.string,
  header: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};
export default ConfirmationModal;
