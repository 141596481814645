import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
const InputBox = ({ onChange, id, title,type,maxLength,onKeyPress, containerClass = '', labelClass = '', value = '', err = '', className = '', placeholder = '', srOnly = false, onClick='', autoComplete, disabled }) => {
  return (
    <Form.Group className={containerClass}>
      <Form.Label className={'opacity-50 mb-1 ' + labelClass} srOnly={srOnly}>
        {title}
      </Form.Label>
      <Form.Control
        type={type}
        id={id}
        onChange={onChange}
        placeholder={placeholder}
        autoComplete={autoComplete}
        value={value}
        disabled={disabled}
        className={className + ' updated-input font-weight-medium pl-0 ' + (err ? 'is-invalid ' : '')}
        onClick={onClick}
        maxLength={maxLength}
        onKeyPress={onKeyPress}
        />
      <Form.Control.Feedback type="invalid">{err}</Form.Control.Feedback>
    </Form.Group>
  );
};
InputBox.propTypes = {
  srOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.bool,
  id: PropTypes.string,
  containerClass: PropTypes.string,
  className: PropTypes.string,
  err: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  labelClass: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  onKeyPress: PropTypes.func,
};
export default InputBox;