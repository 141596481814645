import { client } from '../client';

interface GetHoldings {
  detail: boolean;
  summary: boolean;
}

const getFdHoldings = async (input: GetHoldings) => {
  const { detail = false, summary = true } = input;
  const res = await client.get(`/holdings?summary=${summary}&detail=${detail}`);
  return res.data;
};

const holdings = {
  getFdHoldings,
};

export default holdings;
