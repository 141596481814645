import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
const AnnexureBModal = ({ show, onSubmit }) => {
  return (
    <Modal show={show} size="lg" aria-labelledby="kyc-verified-modal" onHide={onSubmit} centered backdrop="static" keyboard={false}>
      <Modal.Header className="annexure" closeButton>
        <h2 className="font-weight-medium col-sm-12 text-center">Annexure B</h2>
      </Modal.Header>
      <Modal.Body className="small text-center">
        <div className="border px-3">
          <div className="row text-white bg-primary align-items-center">
            <div className="col-sm-1">CODE</div>
            <div className="col-sm-11 py-3 border-left">PARTICULARS</div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-1 c-blue">01</div>
            <div className="col-sm-11 text-left px-4 py-3 border-left">
              Less than 50 percent of the NFEs gross income for the preceding financial yearis passive income and less than 50 percent of the assets
              held by theNFE during the preceding financial year are assets that produce or are held for the production of passive income;
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-1 c-blue">02</div>
            <div className="col-sm-11 text-left px-4 py-3 border-left">
              The NFE is a Governmental Entity, an International Organization, a Central Bank , or an entity wholly owned by one or more of the
              foregoing;
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-1 c-blue">03</div>
            <div className="col-sm-11 text-left px-4 py-3 border-left">
              Substantially all of the activities of the NFE consist of holding (in whole or in part) the outstanding stock of, or providing financing
              and services to, one or more subsidiaries that engage in trades or businesses other than the business of a Financial Institution, except
              that an entity shall not qualify for this status if the entity functions as an investment fund, such as a private equity fund, venture
              capital fund, leveraged buyout fund, or any investment vehicle whose purpose is to acquire or fund companies and then hold interests in
              those companies as capital assets for investment purposes;
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-1 c-blue">04</div>
            <div className="col-sm-11 text-left px-4 py-3 border-left">
              The NFE is not yet operating a business and has no prior operating history, but is investing capital into assets with the intent to
              operate a business other than that of a Financial Institution, provided that the NFE shall not qualify for this exception after the date
              that is 24 months after the date of the initial organization of the NFE;
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-1 c-blue">05</div>
            <div className="col-sm-11 text-left px-4 py-3 border-left">
              The NFE was not a Financial Institution in the past five years, and is in the process of liquidating its assets or is reorganizing with
              the intent to continue or recommence operations in a business other than that of a Financial Institution;
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-1 c-blue">06</div>
            <div className="col-sm-11 text-left px-4 py-3 border-left">
              The NFE primarily engages in financing and hedging transactions with, or for, Related Entities that are not Financial Institutions, and
              does not provide financing or hedging services to any Entity that is not a Related Entity, provided that the group of any such Related
              Entities is primarily engaged in a business other than that of a Financial Institution;
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-1  c-blue">07</div>
            <div className="col-sm-11 text-left px-4 py-3 border-left">
              Any NFE that fulfills all of the following requirements: * It is established and operated in India exclusively for religious,
              charitable, scientific, artistic, cultural, athletic, or educational purposes; or it is established and operated in India and it is a
              professional organization, business league, chamber of commerce, labor organization, agricultural or horticultural organization, civic
              league or an organization operated exclusively for the promotion of social welfare; * It is exempt from income tax in India; * It has no
              shareholders or members who have a proprietary or beneficial interest in its income or assets; The applicable laws of the NFEs country
              or territory of residence or the NFEs formation documents do not permit any income or assets of the NFE to be distributed to, or applied
              for the benefit of, a private person or non-charitable Entity other than pursuant to the conduct of the NFEs charitable activities, or
              as payment of reasonable compensation for services rendered, or as payment representing the fair market value of property which the NFE
              has purchased; and The applicable laws of the NFEs country or territory of residence or the NFEs formation documents require that, upon
              the NFEs liquidation or dissolution, all of its assets be distributed to a governmental entity or other non-profit organization, or
              escheat to the government of the NFEs country or territory of residence or any political subdivision thereof. Explanation.- For the
              purpose of this sub-clause, the following shall be treated as fulfilling the criteria provided in the said sub-clause, namely:- (I) an
              Investor Protection Fund referred to in clause (23EA); (II) a Credit Guarantee Fund Trust for Small Industries referred to in clause
              23EB; and (III) an Investor Protection Fund referred to in clause (23EC), of section 10 of the Act;
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-1 c-blue">NA</div>
            <div className="col-sm-11 text-left px-4 py-3 border-left">
              Not Applicable (If you do not fall in any of the abovementioned code you can mention NA or N)
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
AnnexureBModal.propTypes = {
  show: PropTypes.bool,
  onSubmit: PropTypes.func,
};
export default AnnexureBModal;
