import { useEffect, useState, useRef } from 'react';
import * as Sentry from '@sentry/react';
import { DocumentUpload } from '../../common/on-boarding';
import OnBoardingForNonIndivisuals from '../../component/onboarding-non-indivisuals';
import EditBankAccount from '../../component/bank-accounts/EditBankAccount';
import { decryptData } from '../../utility/DecryptHelper';
import {
  getKycData,
  getKycDataNri,
  uploadSingleDocument,
  uploadDocumentNri,
  getDocumentNri,
} from '../../api/kyc';
import { getUserBanksNri } from '../../api/bank';
import { useDispatch, useSelector } from 'react-redux';
import OnboardingMinor from '../../component/onboaring-minor-nri';
import { InputBox, InputDate } from '../../common/form';
import OnBoardingIndivisuals from '../../component/onboarding-indivisuals';
import OnBoardingNriNreNro from '../../component/onboarding-nri';
import Toast from '../../common/toast/Toast';
import NewBankNri from '../../component/bank-accounts/NewBankNri';
import DocumentUploadNri from '../../common/on-boarding/DocumentUploadNri';
import { BrowserView, MobileView } from 'react-device-detect';
import { getUserDetails } from '../../api/profile';
import { updateKycInfo } from '../../redux/reducers/onboarding-reducer';
import {
  cityPlaceRegex,
  pincodeRegex,
  stateNameRegex,
  nriaddressRegex,
  entityNameRegex,
} from '../../utility/constant';

const llp = '47';
const partnership = '06';
const proprietorship = '13';
const privatelimited = '52';
const limited = '04';
const huf = '03';
const trust = '08';
const minor = '02';
const individual = '01';
const nri = '11';
const nre = '21';
const nro = '24';
const bank_cooperative_bank = '75';

const OnboardingLLP = ({ history }) => {
  const dispatch = useDispatch();
  const { pan, pan_kyc_status } = useSelector(
    (state) => state.onboardingReducer
  );
  const userId = useSelector((state) => state.authReducer.user_id);
  const kycStatus = useSelector((state) => state.authReducer.rise.tax_status);
  const [kycData, setKycData] = useState({});
  const [kycDataNri, setKycDataNri] = useState({});
  const [entityName, setEntityName] = useState('');
  const [fullName, setFullName] = useState('');
  const [dobMinor, setDobMinor] = useState('');
  const [date, setDate] = useState('');
  const [updateFullName, setUpdateFullName] = useState(false);
  const [updateDob, setUpdateDob] = useState(false);
  const [updateEntityName, setUpdateEntityName] = useState(false);
  const [cityName, setCityName] = useState('');
  const [pinCode, setPinCode] = useState('');
  const [stateName, setStateName] = useState('');
  const [documentSuccessNri, setDocumentSuccessNri] = useState({});
  const [documentType, setDocumentType] = useState('signature');
  const [nriAddress, setNriUserAddress] = useState('');
  const [bankDetails, setBankDetails] = useState(false);
  const [fatcaUpdated, setFatcaUpdated] = useState(false);
  const [bankUpdated, setBankUpdated] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    let tempDate = dobMinor;
    if (tempDate !== null) {
      const date = new Date(tempDate);
      tempDate = date.toLocaleDateString('en-GB');
    }
    setDate(tempDate);
  }, [dobMinor]);

  useEffect(() => {
    updatePanData();
  }, []);

  useEffect(() => {
    if (pan_kyc_status !== 75) {
      getUserKycDataNri();
      getUserDocumentNri();
      getUserBankListNri();
    }
    if (pan_kyc_status === 11) {
      getUserKycDataNri();
    } else {
      getUserKycData();
    }
  }, [pan_kyc_status]);

  const updatePanData = () => {
    getUserDetails().then((res) => {
      const profileObj = decryptData(res.data.data);
      if (profileObj.data && profileObj.data.pan) {
        if (profileObj.data?.tax_status) {
          dispatch(
            updateKycInfo({
              pan: profileObj.data.pan,
              pan_kyc_status: profileObj.data.tax_status.id,
            })
          );
        }
      }
    });
  };

  const minorDetails = {
    fullName: fullName === '' ? kycData.full_name : fullName,
    dobMinor: dobMinor === '' ? kycData.dob_minor : date,
  };

  const nriUserAddress = {
    address: nriAddress,
    city: cityName,
    state: stateName,
    pincode: pinCode,
  };
  const childRef = useRef();
  const childRefDoc = useRef();
  const getUserKycData = () => {
    const data = new FormData();
    data.append('pan', pan);
    if (pan_kyc_status) {
      data.append('tax_status', pan_kyc_status);
    } else if (kycStatus && kycStatus.id) {
      data.append('tax_status', kycStatus);
    } else {
      data.append('tax_status', '');
    }
    if (pan) {
      getKycData(data).then((res) => {
        if (res && res.data) {
          const data = decryptData(res.data.data);
          if (data && data.data && data.data.length) {
            setKycData(data.data[0]);
          }
        }
      });
    }
  };
  const getUserKycDataNri = () => {
    getKycDataNri(userId).then((res) => {
      if (res && res.data) {
        let investorType = res.data.investor_type;
        if (
          investorType === nri ||
          investorType === nre ||
          investorType === nro
        ) {
          setKycDataNri(res.data);
          setNriUserAddress(res.data.contact_address.address);
          setCityName(res.data.contact_address.city);
          setPinCode(res.data.contact_address.pincode);
          setStateName(res.data.contact_address.state);
        }
      }
    });
  };

  const getUserDocumentNri = () => {
    getDocumentNri(userId).then((res) => {
      if (res && res.data && res.data.length) {
        setDocumentType(res.data[0].document_type);
      }
    });
  };
  const getUserBankListNri = () => {
    getUserBanksNri(userId).then((res) => {
      if (res && res.data && res.data.length) {
        setBankDetails(true);
      }
    });
  };
  const uploadSingleDocuments = (docObj, files) => {
    const postObj = new FormData();
    postObj.append('doc', files);
    postObj.append('doc_type', docObj.id);
    uploadSingleDocument(postObj).then((res) => {
      if (res && res.data) {
        if (res.data.status.message && res.data.status.code === 1) {
          Toast.success(res.data.status.message);
        }
        const data = decryptData(res.data.data);
        if (data && data.data && data.data.length) {
          setKycData(data.data[0]);
        }
      }
    });
  };
  const uploadSingleDocumentNri = (files) => {
    const postObj = new FormData();
    postObj.append('document_type', documentType);
    postObj.append('file', files);

    uploadDocumentNri(userId, postObj)
      .then(() => {
        setDocumentSuccessNri({ status: true });
      })
      .catch((error) => {
        console.error(error);
        Sentry.withScope(function (scope) {
          scope.setLevel('error');
          scope.setFingerprint([
            `http_status:${error?.response?.status} ${error?.request}`,
          ]);
          Sentry.captureException(error);
        });
      });
  };
  const checkStatusForIndivisuals = (value) => {
    if (value) {
      const groupArr = [
        '47',
        '06',
        '13',
        '52',
        '04',
        '03',
        '08',
        bank_cooperative_bank,
      ];
      return groupArr.indexOf(value) > -1;
    }
    return false;
  };
  const multiDocUpload = () => {
    getUserKycData();
  };
  const onBankAdd = () => {
    getUserKycData();
  };
  const onBankAddNri = () => {
    getUserKycDataNri();
  };
  const getTitle = () => {
    if (pan_kyc_status === partnership || pan_kyc_status === llp) {
      return 'Number of Partners';
    } else if (pan_kyc_status === huf) {
      return 'Number of HUF members';
    } else if (pan_kyc_status === trust) {
      return 'Number of Trustees';
    } else if (
      pan_kyc_status === privatelimited ||
      pan_kyc_status === limited ||
      pan_kyc_status === bank_cooperative_bank
    ) {
      return 'Number of Directors';
    }
  };
  const handleChangeFullName = (e) => {
    let value = e.target.value;
    setFullName(value);
  };
  const handleFullNameClick = () => {
    setUpdateFullName(true);
  };
  const handleChangeDOB = (date) => {
    setDobMinor(date);
  };
  const handleDobClick = () => {
    setUpdateDob(true);
  };
  const handleChange = (event) => {
    const { value } = event.target;
    if (value && !value.match(entityNameRegex)) {
      let tempErrror = [...errors];
      tempErrror['entityName'] =
        'Please ensure that the entity name consists only of alphabets and spaces.';
      setErrors(tempErrror);
    } else {
      errors['entityName'] = undefined;
    }
    setEntityName(event.target.value);
  };
  const handleEntityClick = () => {
    setUpdateEntityName(true);
  };
  const onNriAddressChange = (event) => {
    let inputValue = event.target.value || '';
    let NriAddressErr = '';
    if (!inputValue) {
      setNriUserAddress('');
    } else if (!nriaddressRegex.test(inputValue)) {
      NriAddressErr = 'Please enter an alphnumaric only';
    } else {
      NriAddressErr = undefined;
      setNriUserAddress(inputValue);
    }
    setErrors({ ...errors, nriAddress: NriAddressErr });
  };
  const onCityPlaceChange = (event) => {
    let inputValue = event.target.value || '';
    let CityErr = '';
    if (!inputValue) {
      setCityName('');
    } else if (!cityPlaceRegex.test(inputValue)) {
      CityErr = 'City name allowed alphabets only';
    } else {
      CityErr = undefined;
      setCityName(inputValue);
    }
    setErrors({ ...errors, cityName: CityErr });
  };
  const onPinCodeChange = (event) => {
    let inputValue = event.target.value || '';
    let pinCodeErr = '';
    let PinCodeValue = inputValue.replace(pincodeRegex);
    if (PinCodeValue.length <= 6) {
      setPinCode(PinCodeValue);
    } else {
      pinCodeErr = 'Pin code should be up to 6 digits only';
    }
    setErrors({ ...errors, pinCode: pinCodeErr });
  };
  const onStateNameChange = (event) => {
    let inputValue = event.target.value || '';
    let StateErr = '';
    if (!inputValue) {
      setStateName('');
    } else if (!stateNameRegex.test(inputValue)) {
      StateErr = 'State name allowed alphabets only';
    } else {
      StateErr = undefined;
      setStateName(inputValue);
    }
    setErrors({ ...errors, stateName: StateErr });
  };
  const onSubmit = (isSaveAsDraft) => {
    if (kycData.bank_details.id) {
      childRef.current.updateNewBank(isSaveAsDraft);
    } else {
      childRef.current.addNewBank(isSaveAsDraft);
    }
  };
  const onSubmitNri = (isSaveAsDraft) => {
    if (bankDetails) {
      childRef.current.updateNewBank(isSaveAsDraft);
    } else {
      childRef.current.addNewBank(isSaveAsDraft);
    }
  };

  const onFatcaSubmitted = () => {
    setFatcaUpdated(true);
  };
  const onBankSubmitted = () => {
    setBankUpdated(true);
  };

  useEffect(() => {
    if (fatcaUpdated && bankUpdated) {
      history.push('/home');
    }
  }, [fatcaUpdated, bankUpdated]);

  const handleErrorsBankNri = (flag) => {
    let status = true;
    let status1 = true;
    status = childRef.current.validate(flag);
    status1 = childRefDoc.current.validateDoc(flag);
    if (status && status1) {
      return true;
    }
    return false;
  };

  const handleErrorsBank = (flag) => {
    let status = childRef.current.validate(flag);
    let status1 = childRefDoc.current.validateDoc(flag);
    if (status && status1) {
      return true;
    }
    return false;
  };

  return (
    <>
      <BrowserView>
        <div className='container onboarding-container colored-box p-5'>
          <div className='row'>
            {kycData.tax_status_tcs === minor ? (
              <div className='col-sm-12'>
                {updateFullName ? (
                  <InputBox
                    id='onboarding-fullname'
                    title={"Minor's Name"}
                    placeholder="Enter Minor's Full Name"
                    autoComplete='none'
                    name='text'
                    value={fullName}
                    onChange={handleChangeFullName}
                  />
                ) : (
                  <InputBox
                    id='onboarding-fullname'
                    title={"Minor's Name"}
                    placeholder="Enter Minor's Full Name"
                    autoComplete='none'
                    name='text'
                    value={kycData.full_name}
                    onClick={handleFullNameClick}
                  />
                )}
              </div>
            ) : null}
            {kycData.tax_status_tcs === minor ? (
              <div className='col-sm-12'>
                {updateDob ? (
                  <>
                    <InputDate
                      id='date_picker_id'
                      title={"Minor's Date of Birth"}
                      placeholder='dd/mm/yyyy'
                      value={dobMinor}
                      onChange={handleChangeDOB}
                      maxDate={new Date()}
                    />
                  </>
                ) : (
                  <>
                    <InputDate
                      id='onboarding-dob'
                      title={"Minor's Date of Birth"}
                      placeholder='dd/mm/yyyy'
                      value={
                        kycData.dob_minor === ''
                          ? new Date()
                          : new Date(
                              kycData.dob_minor.replace(
                                /(\d\d)\/(\d\d)\/(\d{4})/,
                                '$3-$2-$1'
                              )
                            )
                      }
                      onClick={handleDobClick}
                      onSelect={handleDobClick}
                      onChange={handleChangeDOB}
                    />
                  </>
                )}
              </div>
            ) : null}
            <div className='col-sm-12'>
              <InputBox
                id='onboarding-name'
                title={
                  pan_kyc_status === proprietorship
                    ? 'Name of the Proprietor'
                    : 'Name of the Entity'
                }
                value={kycData.pan_name}
                disabled
              />
            </div>
            {kycData.tax_status_tcs === proprietorship ? (
              <div className='col-sm-12'>
                {updateEntityName ? (
                  <InputBox
                    id='onboarding-proprietor'
                    title={'Name of the Entity'}
                    placeholder='Enter the Entity name'
                    value={entityName}
                    onChange={handleChange}
                    err={errors['entityName']}
                  />
                ) : (
                  <InputBox
                    id='onboarding-proprietor'
                    title={'Name of the Entity'}
                    placeholder='Enter the Entity name'
                    value={kycData.full_name}
                    onClick={handleEntityClick}
                    err={errors['entityName']}
                  />
                )}
              </div>
            ) : null}
            {kycData.tax_status_tcs === nri ||
            kycData.tax_status_tcs === nre ||
            kycData.tax_status_tcs === nro ? (
              <>
                <div className='col-sm-12 mt-3'>
                  <InputBox
                    id='onboarding-address'
                    title={'Indian Contact Address'}
                    value={nriAddress}
                    onChange={onNriAddressChange}
                    type='text'
                    err={errors['nriAddress']}
                  />
                </div>
                <div className='d-flex'>
                  <div className=' rounded w-100 mx-3 my-3'>
                    <InputBox
                      id='City'
                      className='col-sm-12'
                      labelClass='opacity-75 h6 py-2'
                      onChange={onCityPlaceChange}
                      title='City'
                      value={cityName}
                      type='text'
                      err={errors['cityName']}
                    />
                  </div>
                  <div className='rounded w-100 mx-3 my-3'>
                    <InputBox
                      title='State'
                      value={stateName}
                      className='col-sm-12'
                      labelClass='opacity-75 h6 py-2'
                      id='countryBirth'
                      onChange={onStateNameChange}
                      type='text'
                      err={errors['stateName']}
                    />
                  </div>
                  <div className=' rounded w-100 mx-3 my-3'>
                    <InputBox
                      id='pincode'
                      className='col-sm-12'
                      labelClass='opacity-75 h6 py-2'
                      onChange={onPinCodeChange}
                      title='Pincode'
                      value={pinCode}
                      inputMode={'numeric'}
                      type='number'
                      err={errors['pinCode']}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className='col-sm-12 mt-3'>
                <InputBox
                  id='onboarding-address'
                  title={
                    pan_kyc_status === individual || pan_kyc_status === minor
                      ? 'Address'
                      : 'Address of the entity'
                  }
                  value={kycData.address}
                  disabled
                />
              </div>
            )}
            {kycData.tax_status_tcs === nri ||
            kycData.tax_status_tcs === nre ||
            kycData.tax_status_tcs === nro ? (
              <div className='col-sm-12 mt-3'>
                <h6 className='font-weight-bold my-4'>Documents</h6>
                <DocumentUploadNri
                  kycData={kycData}
                  documentList={kycData}
                  panStatus={pan_kyc_status}
                  singleDocUpload={uploadSingleDocumentNri}
                  successMessage={documentSuccessNri}
                  title={getTitle()}
                  ref={childRefDoc}
                />
              </div>
            ) : (
              <div className='col-sm-12 mt-3'>
                <h6 className='font-weight-bold my-4'>Documents</h6>
                <DocumentUpload
                  kycData={kycData}
                  documentList={kycData.document_list}
                  panStatus={pan_kyc_status}
                  singleDocUpload={uploadSingleDocuments}
                  multiDocUpload={multiDocUpload}
                  title={getTitle()}
                  ref={childRefDoc}
                />
              </div>
            )}

            <section className='col-sm-12'>
              <h6 className='font-weight-bold my-4'>
                {pan_kyc_status === minor
                  ? "Please add Minor's bank details"
                  : 'Bank Details'}
              </h6>
              {kycData.tax_status_tcs === nri ||
              kycData.tax_status_tcs === nre ||
              kycData.tax_status_tcs === nro ? (
                <div className='border p-4 rounded'>
                  {kycData.bank_details && (
                    <NewBankNri
                      alignment='center'
                      ref={childRef}
                      buttonText='Submit'
                      onBankAdd={onBankAddNri}
                      onBankSubmitted={onBankSubmitted}
                    />
                  )}
                </div>
              ) : (
                <div className='border p-4 rounded'>
                  {kycData?.bank_details ? (
                    <EditBankAccount
                      alignment='center'
                      ref={childRef}
                      buttonText='Submit'
                      panStatus={pan_kyc_status}
                      bankDetail={kycData.bank_details}
                      onBankAdd={onBankAdd}
                      onBankSubmitted={onBankSubmitted}
                      onboarding={true}
                    />
                  ) : null}
                </div>
              )}
            </section>
            {kycData.tax_status_tcs === minor ? (
              <OnboardingMinor
                userId={userId}
                fatcaData={kycData}
                history={history}
                minorDetails={minorDetails}
                onSubmit={onSubmit}
                onFatcaSubmitted={onFatcaSubmitted}
                errors={errors}
                setErrors={setErrors}
                handleErrorsBank={handleErrorsBank}
              />
            ) : null}
            {kycData.tax_status_tcs === nri ||
            kycData.tax_status_tcs === nre ||
            kycData.tax_status_tcs === nro ? (
              <OnBoardingNriNreNro
                userId={userId}
                fatcaData={kycDataNri}
                kycData={kycData}
                history={history}
                nriUserAddress={nriUserAddress}
                nriAddress={nriAddress}
                stateName={stateName}
                cityName={cityName}
                pinCode={pinCode}
                onSubmit={onSubmitNri}
                onFatcaSubmitted={onFatcaSubmitted}
                errors={errors}
                setErrors={setErrors}
                handleErrorsBankNri={handleErrorsBankNri}
              />
            ) : null}
            {kycData.tax_status_tcs === individual ? (
              <OnBoardingIndivisuals
                userId={userId}
                fatcaData={kycData}
                history={history}
                onSubmit={onSubmit}
                onFatcaSubmitted={onFatcaSubmitted}
                errors={errors}
                setErrors={setErrors}
                handleErrorsBank={handleErrorsBank}
              />
            ) : null}
            {checkStatusForIndivisuals(kycData.tax_status_tcs) ? (
              <OnBoardingForNonIndivisuals
                userId={userId}
                fatcaData={kycData}
                history={history}
                entityName={entityName === '' ? kycData.full_name : entityName}
                onSubmit={onSubmit}
                onFatcaSubmitted={onFatcaSubmitted}
                commanErrors={errors}
                setCommanError={setErrors}
                handleErrorsBank={handleErrorsBank}
                isPropriter={kycData.tax_status_tcs === proprietorship}
              />
            ) : null}
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div className='bg-light-grey py-2'>
          <div className='onboarding-container colored-box p-3 m-4'>
            {kycData.tax_status_tcs === minor ? (
              <div className='col-sm-12'>
                {updateFullName ? (
                  <InputBox
                    id='onboarding-fullname'
                    title={'Minor Full Name'}
                    placeholder='Enter your full name'
                    autoComplete='none'
                    name='text'
                    value={fullName}
                    onChange={handleChangeFullName}
                  />
                ) : (
                  <InputBox
                    id='onboarding-fullname'
                    title={'Minor Full Name'}
                    placeholder='Enter your full name'
                    autoComplete='none'
                    name='text'
                    value={kycData.full_name}
                    onClick={handleFullNameClick}
                  />
                )}
              </div>
            ) : null}
            {kycData.tax_status_tcs === minor ? (
              <div className='col-sm-12'>
                {updateDob ? (
                  <InputDate
                    id='date_picker_id'
                    title={'Minor Date of Birth'}
                    placeholder='dd/mm/yyyy'
                    value={dobMinor}
                    onChange={handleChangeDOB}
                    maxDate={new Date()}
                  />
                ) : (
                  <InputDate
                    id='onboarding-dob'
                    title={'Minor Date of Birth'}
                    placeholder='dd/mm/yyyy'
                    value={
                      kycData.dob_minor === ''
                        ? new Date()
                        : new Date(
                            kycData.dob_minor.replace(
                              /(\d\d)\/(\d\d)\/(\d{4})/,
                              '$3-$2-$1'
                            )
                          )
                    }
                    onClick={handleDobClick}
                    onSelect={handleDobClick}
                  />
                )}
              </div>
            ) : null}
            <div className='col-sm-12'>
              <InputBox
                id='onboarding-name'
                title={
                  pan_kyc_status === individual ||
                  pan_kyc_status === nri ||
                  pan_kyc_status === nre ||
                  pan_kyc_status === nro
                    ? 'Full Name'
                    : pan_kyc_status === minor
                    ? 'Guardian Full Name'
                    : pan_kyc_status === proprietorship
                    ? 'Name of the Proprietor'
                    : 'Name of the Entity'
                }
                value={kycData.pan_name}
                disabled
              />
            </div>
            {kycData.tax_status_tcs === proprietorship ? (
              <div className='col-sm-12'>
                {updateEntityName ? (
                  <InputBox
                    id='onboarding-proprietor'
                    title={'Name of the Entity'}
                    placeholder='Enter the Entity name'
                    value={entityName}
                    onChange={handleChange}
                  />
                ) : (
                  <InputBox
                    id='onboarding-proprietor'
                    title={'Name of the Entity'}
                    placeholder='Enter the entity name'
                    value={kycData.full_name}
                    onClick={handleEntityClick}
                  />
                )}
              </div>
            ) : null}
            {kycData.tax_status_tcs === nri ||
            kycData.tax_status_tcs === nre ||
            kycData.tax_status_tcs === nro ? (
              <>
                <div className='col-sm-12 mt-3'>
                  <InputBox
                    id='onboarding-address'
                    title={'Indian Contact Address'}
                    value={nriAddress}
                    onChange={onNriAddressChange}
                    type='text'
                    err={errors['nriAddress']}
                  />
                </div>
                <div className='col-sm-12 w-100'>
                  <InputBox
                    id='City'
                    className='col-sm-12'
                    labelClass='opacity-75 h6 py-2'
                    onChange={onCityPlaceChange}
                    title='City'
                    value={cityName}
                    type='text'
                    err={errors['cityName']}
                  />
                </div>
                <div className='col-sm-12 w-100'>
                  <InputBox
                    title='State'
                    value={stateName}
                    className='col-sm-12'
                    labelClass='opacity-75 h6 py-2'
                    id='countryBirth'
                    onChange={onStateNameChange}
                    type='text'
                    err={errors['stateName']}
                  />
                </div>
                <div className='col-sm-12 w-100'>
                  <InputBox
                    id='pincode'
                    className='col-sm-12'
                    labelClass='opacity-75 h6 py-2'
                    onChange={onPinCodeChange}
                    title='Pincode'
                    value={pinCode}
                    inputMode={'numeric'}
                    type='number'
                    err={errors['pinCode']}
                  />
                </div>
              </>
            ) : (
              <div className='col-sm-12 mt-3'>
                <InputBox
                  id='onboarding-address'
                  title={
                    pan_kyc_status === individual || pan_kyc_status === minor
                      ? 'Address'
                      : 'Address of the entity'
                  }
                  value={kycData.address}
                  disabled
                />
              </div>
            )}
            {kycData.tax_status_tcs === nri ||
            kycData.tax_status_tcs === nre ||
            kycData.tax_status_tcs === nro ? (
              <div className='col-sm-12 mt-3'>
                <h6 className='font-weight-bold my-4'>Documents</h6>
                <DocumentUploadNri
                  kycData={kycData}
                  documentList={kycData}
                  panStatus={pan_kyc_status}
                  singleDocUpload={uploadSingleDocumentNri}
                  successMessage={documentSuccessNri}
                  title={getTitle()}
                  ref={childRefDoc}
                />
              </div>
            ) : (
              <div className='col-sm-12 mt-3'>
                <h6 className='font-weight-bold my-4'>Documents</h6>
                <DocumentUpload
                  kycData={kycData}
                  documentList={kycData.document_list}
                  panStatus={pan_kyc_status}
                  singleDocUpload={uploadSingleDocuments}
                  multiDocUpload={multiDocUpload}
                  title={getTitle()}
                  ref={childRefDoc}
                />
              </div>
            )}
            <section className='col-sm-12'>
              <h6 className='font-weight-bold my-4'>
                {pan_kyc_status === minor
                  ? "Please add Minor's bank details"
                  : 'Bank Details'}
              </h6>
              {kycData.tax_status_tcs === nri ||
              kycData.tax_status_tcs === nre ||
              kycData.tax_status_tcs === nro ? (
                <div className='rounded'>
                  {kycData.bank_details && (
                    <NewBankNri
                      alignment='center'
                      ref={childRef}
                      buttonText='Submit'
                      bankDetail={kycData.bank_details}
                      onBankAdd={onBankAddNri}
                      onBankSubmitted={onBankSubmitted}
                    />
                  )}
                </div>
              ) : (
                <div className='rounded'>
                  {kycData?.bank_details ? (
                    <EditBankAccount
                      alignment='center'
                      ref={childRef}
                      buttonText='Submit'
                      panStatus={pan_kyc_status}
                      bankDetail={kycData.bank_details}
                      onBankAdd={onBankAdd}
                      onBankSubmitted={onBankSubmitted}
                      onboarding={true}
                    />
                  ) : null}
                </div>
              )}
            </section>
            {kycData.tax_status_tcs === minor ? (
              <OnboardingMinor
                userId={userId}
                fatcaData={kycData}
                history={history}
                minorDetails={minorDetails}
                onSubmit={onSubmit}
                onFatcaSubmitted={onFatcaSubmitted}
                errors={errors}
                setErrors={setErrors}
                handleErrorsBank={handleErrorsBank}
              />
            ) : null}
            {kycData.tax_status_tcs === nri ||
            kycData.tax_status_tcs === nre ||
            kycData.tax_status_tcs === nro ? (
              <OnBoardingNriNreNro
                userId={userId}
                fatcaData={kycDataNri}
                kycData={kycData}
                history={history}
                nriUserAddress={nriUserAddress}
                nriAddress={nriAddress}
                stateName={stateName}
                cityName={cityName}
                pinCode={pinCode}
                onSubmit={onSubmitNri}
                onFatcaSubmitted={onFatcaSubmitted}
                errors={errors}
                setErrors={setErrors}
                handleErrorsBankNri={handleErrorsBankNri}
              />
            ) : null}
            {kycData.tax_status_tcs === individual ? (
              <OnBoardingIndivisuals
                userId={userId}
                fatcaData={kycData}
                history={history}
                onSubmit={onSubmit}
                onFatcaSubmitted={onFatcaSubmitted}
                errors={errors}
                setErrors={setErrors}
                handleErrorsBank={handleErrorsBank}
              />
            ) : null}
            {checkStatusForIndivisuals(kycData.tax_status_tcs) ? (
              <OnBoardingForNonIndivisuals
                userId={userId}
                fatcaData={kycData}
                history={history}
                entityName={entityName === '' ? kycData.full_name : entityName}
                onSubmit={onSubmit}
                onFatcaSubmitted={onFatcaSubmitted}
                commanErrors={errors}
                setCommanError={setErrors}
                handleErrorsBank={handleErrorsBank}
                isPropriter={kycData.tax_status_tcs === proprietorship}
              />
            ) : null}
          </div>
        </div>
      </MobileView>
    </>
  );
};
export default OnboardingLLP;
