import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { propTypes } from 'react-bootstrap/esm/Image';
const PlanDirectModal = ({ show ,add, onClose }) => {
  return (
    <Modal show={show} size="sm" className="" aria-labelledby="confirmation-modal" onHide={onClose} centered backdrop="static" keyboard={false} >
      <Modal.Header  closeButton className="justify-content-center m-0 p-0 mr-3 mt-1">
        <span className="h5 ml-3 mt-2">Direct Plan</span>
      </Modal.Header>
      <Modal.Body className="pt-0 align-items-center">
       <span className="opacity-75" >{add ? 
       "This is a direct plan, Funds cannot be added to direct plans on Elevo.":
       "This fund is a direct plan, redemption cannot be made for direct plans on Elevo."
       }</span>        
      </Modal.Body>
    </Modal>
  );
};
PlanDirectModal.propTypes = {
  message: PropTypes.string,
  header: PropTypes.string,
  show: PropTypes.bool,
  add: propTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  fundName: PropTypes.string,
};
export default PlanDirectModal;
