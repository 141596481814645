import React from 'react';
import PropTypes from 'prop-types';
import { formatNumber, regexString } from '../../utility/utility';
import { useHistory } from 'react-router-dom';

const MobileFundList = ({ fundItems }) => {
  let history = useHistory();
  const onButtonClick = (name,id) => {
    history.push({pathname:`/fund/detail/${name}/${id}`});
  };
  
  return (
    <>
    <h3>Quick Links</h3>
      {fundItems && fundItems.length
        ? fundItems.map((fundItem) => (
            <div key={'fund-list-' + fundItem.category_id} id={'fund-list-' + fundItem.category_id}>
              {fundItem.funds && fundItem.funds.length ? (
                <div className="fund-list-section border-bottom my-3">
                  <div className="fund-list-title mb-3 row font-weight-medium align-items-center">
                    <div className="col-sm-10">
                      <h5 className="c-blue">{fundItem.category}</h5>
                    </div>
                  </div>
                 <section>
                <div>
              {fundItem.funds.map((item)=>(
              <div key={item.id} className='colored-box p-3 my-3'>
               <h5>{item.fscbi_legal_name}</h5>
                <div className='d-flex '>
                <div className='mx-2'>
                 <p className='opacity-50 mb-2'>1 Year Return</p>
                 <p className='font-weight-bold font-12 '>{formatNumber(item.ttr_return_1_yr, 1)}%</p>
               </div>
               <div className='mx-2'>
                 <p className='opacity-50 mb-2'>3 Year Return</p>
                 <p className='font-weight-bold font-12'>{formatNumber(item.ttr_return_3_yr, 1)}%</p>
                </div>
                <div className="w-30 mx-2 ">
                        <button
                          type="button"
                          id={'fund-list-item-' + item.id}
                          className="btn btn-primary py-1 w-100 br-light"
                          onClick={() => {
                            onButtonClick(regexString(item.fscbi_legal_name),item.id);
                          }}>
                          Invest
                        </button>
                      </div>
                </div>
                </div>
                 ))} 
            </div>
           </section>
                  </div>
              ) : null}
            </div>
          ))
        : null}
    </>
  );
};
MobileFundList.propTypes = {
  fundItems: PropTypes.array,
};
export default MobileFundList;
