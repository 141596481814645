import { OverlayTrigger, Popover } from 'react-bootstrap';
import { IoIosInformationCircle } from 'react-icons/io';
import { FDPortfolioDetails } from '../../types';
import Amount from '../Amount/Amount';

interface Props {
  className?: string;
  data: FDPortfolioDetails | undefined;
}

export const DepositsTile = ({ data, className }: Props) => {
  const interest_rates_value =
    data?.max_interest_rate === data?.min_interest_rate
      ? `${data?.min_interest_rate}%`
      : `${data?.min_interest_rate}% - ${data?.max_interest_rate}%`;
  return (
    <div className={`d-flex flex-column ${className}`}>
      <div className='DashboardV2__tile DashboardV2__assetTile Investments__deposits overflow-hidden pr-2 flex-grow-1'>
        <div className='DashboardV2__title DashboardV2__assetTitle mb-3 pl-0'>
          Deposits
        </div>
        <div className='DepositsTile-container'>
          <div className='Investments__cell'>
            <div className='Investments__label'>Invested Value</div>
            <Amount
              value={data?.total_investment || 0}
              className='Investments__amount'
            />
          </div>
          <div className='Investments__cell'>
            <div className='Investments__label'>Maturity Value</div>
            <div className='d-flex items-center '>
              <Amount
                value={data?.maturity_amount || 0}
                className='Investments__amount'
              />
              <OverlayTrigger
                trigger={['click']}
                rootClose
                placement='bottom'
                overlay={
                  <Popover id='popover-basic' title='Popover right '>
                    <div className='d-flex text-center p-1'>
                      Maturity value is approx. value. This info message will be
                      dismissed once we reflect actual value.
                    </div>
                  </Popover>
                }
              >
                <IoIosInformationCircle
                  color='#08AEEA'
                  className='ml-1 pe-auto'
                />
              </OverlayTrigger>
            </div>
          </div>
          <div className='Investments__cell'>
            <div className='Investments__label'>Returns: </div>
            <div className='Investments__amount'>
              {interest_rates_value !== 'undefined%'
                ? interest_rates_value
                : '0.00%'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
