import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface OnboardingState {
  pan: string | null;
  pan_kyc_status: boolean;
  bankList: any[]; // You can replace 'any' with a more specific type if necessary.
}

const initialState: OnboardingState = {
  pan: null,
  pan_kyc_status: false,
  bankList: [],
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    updateKycInfo: (state, action: PayloadAction<Partial<OnboardingState>>) => {
      Object.assign(state, action.payload);
    },
    loadBankList: (state, action: PayloadAction<any[]>) => {
      state.bankList = action.payload;
    },
  },
});

export const { updateKycInfo, loadBankList } = onboardingSlice.actions;

export default onboardingSlice.reducer;
