import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PaymentState {
  transaction_id: string | null;
  order_type: string | null;
  amount: number | null;
  payment_mode: string | null;
}

const initialState: PaymentState = {
  transaction_id: null,
  order_type: null,
  amount: null,
  payment_mode: null,
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    updatePaymentData: (state, action: PayloadAction<Partial<PaymentState>>) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { updatePaymentData } = paymentSlice.actions;

export default paymentSlice.reducer;
