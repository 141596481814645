import axios from './axios';
const getHomeData = () => {
  return axios.get('/tcs/home/');
};
const getInvestData = () => {
  return axios.get('/tcs/investment-category/');
};
const getUserTransictions = (id, postData = {}) => {
  let urlParams = [];
  if (postData.limit) {
    urlParams.push(`limit=${postData.limit}`);
  }
  if (postData.offset) {
    urlParams.push(`offset=${postData.offset}`);
  }
  if (postData.transaction_type) {
    urlParams.push(`transaction_type=${postData.transaction_type}`);
  }
  if (postData.search_by_name) {
    urlParams.push(`search_by_name=${postData.search_by_name}`);
  }
  if (postData.start_date && postData.end_date) {
    urlParams.push(`start=${postData.start_date}&end=${postData.end_date}`);
  }
  return axios.get(`tcs/user-transactions/${id}/?${urlParams.join('&')}`);
};
const logOut = (id) => {
  return axios.post(`/users/logout/${id}`);
};
export { getHomeData, getInvestData, getUserTransictions, logOut };
