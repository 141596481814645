import { format } from 'date-fns';
import Amount from '../../../component/Amount/Amount';
import { useMedia } from '../../../hooks/useMedia';
import { capitalize } from '../../../utility/utility';
import { FolioCell } from './FolioCell';
import './PortfolioDeposits.scss';
import { PortfolioDepositsCard } from './PortfolioDepositsCards';

interface Props {
  deposits: {
    [key: string]: any;
  };
}

export const PortfolioDeposits = ({ deposits }: Props) => {
  const isMobile = useMedia(['(min-width: 992px)'], [false], true);
  if (deposits?.length <= 0) {
    return null;
  }
  return (
    <div className='PortfolioDeposits__tile'>
      <div className='MutualFunds__header text-lg leading-tight mb-4'>
        Deposits
      </div>
      <div
        style={{
          padding: '0 20px',
        }}
      >
        <div className='PortfolioDeposits__grid PortfolioDeposits__header d-grid pb-3 '>
          <div className='PortfolioDeposits__gray fw-600 d-flex align-items-center'>
            Deposit Name
          </div>
          <div className='PortfolioDeposits__gray fw-600 d-flex align-items-center justify-content-center'>
            Invested Amount
          </div>
          <div className='PortfolioDeposits__gray fw-600 d-flex align-items-center justify-content-center'>
            Maturity Amount
          </div>
          <div className='PortfolioDeposits__gray fw-600 d-flex align-items-center justify-content-center'>
            Interest Rate
          </div>
          <div className='PortfolioDeposits__gray fw-600 d-flex align-items-center'>
            Tenure
          </div>
        </div>
      </div>
      {!isMobile &&
        (deposits || []).map((deposit: any) => {
          const order_type =
            deposit.interest_payout_frequency === 'maturity'
              ? 'cumulative'
              : 'non_cumulative';

          deposit.order_type = order_type
            .split('_')
            .map((str: any) => capitalize(str))
            .join(' ');
          return (
            <div
              className='PortfolioDeposits__row PortfolioDeposits__dark my-3'
              key={deposit.id}
            >
              <div className='PortfolioDeposits__row_grid d-grid pb-1 mb-2'>
                <div className='fw-500 d-flex align-items-center'>
                  <img
                    src={deposit?.issuer?.logo}
                    alt={deposit?.issuer?.name}
                    className='PortfolioDeposits__logo rounded-circle mr-3'
                  />
                  <div className='font-medium'>{deposit?.issuer?.name}</div>
                </div>
                <div className='fw-500 d-flex align-items-center justify-content-center'>
                  <Amount
                    className='text-nowrap'
                    value={deposit?.amount}
                    options={{
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    }}
                  />
                </div>
                <div className='fw-500 d-flex align-items-center justify-content-center'>
                  <Amount
                    className='text-nowrap'
                    value={deposit?.maturity_amount}
                    options={{
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    }}
                  />
                </div>
                <div className='fw-500 d-flex align-items-center justify-content-center'>
                  {deposit?.interest_rate}%
                </div>
                <div className='fw-500 d-flex align-items-center'>
                  {deposit?.tenure}&nbsp;Months
                </div>
              </div>
              <div className='PortfolioDeposits__folios'>
                <div className='PortfolioDeposits__itemGrid PortfolioDeposits__item d-grid bg-white rounded'>
                  <FolioCell label='Start Date'>
                    {format(new Date(deposit.created_at), 'dd/MM/yyyy')}
                  </FolioCell>

                  <FolioCell label='End Date'>
                    {deposit.maturity_date
                      ? format(new Date(deposit.maturity_date), 'dd/MM/yyyy')
                      : 'NA'}
                  </FolioCell>
                  <FolioCell label='Interest Payout'>
                    {deposit.interest_payout_frequency
                      .split('_')
                      .map((str: any) => capitalize(str))
                      .join(' ')}
                  </FolioCell>
                  <FolioCell label='Type'>{deposit.order_type}</FolioCell>
                </div>
              </div>
            </div>
          );
        })}
      {isMobile && <PortfolioDepositsCard deposits={deposits} />}
    </div>
  );
};
