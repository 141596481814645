import React, { useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';

const OptionsType6 = ({ options, question, updateAnswerJson, errors, step, value }) => {
  const [value1, setValue1] = useState();
  const [renderOptions, setRenderOptions] = useState([])

  const handleValueSelect = (val, option) => {
    setValue1(val)
    const answer = {
      question_id: question.question_id?.toString(),
      options: [
        {
          option_id: option.option_id?.toString()
        }
      ]
    }
    updateAnswerJson(answer)
  };

  useEffect(() => {
    setValue1(undefined)
    if(options) {
      const newOptions = []
      options.forEach(item => {
        const value = item.option_value;
        const types = value.split(',');
        const typeOptions = [];
        types.forEach(type => {
          const obj = {};
          const typeSplit = type.split(':');
          obj.percent = typeSplit[1].trim()
          obj.label = typeSplit[0].trim();
          typeOptions.push(obj);
        })
        newOptions.push(typeOptions)
      })
      setRenderOptions([...newOptions])
    }
  }, [...options])

  useEffect(() => {
    if(value) {
      options.forEach((item, index) => {
        if(item.option_id?.toString() === value?.options[0]?.option_id) {
          setValue1(index)
        }
      })
    }
  }, [value])

  return (
    <>
      <div className={'options mt-2'}>
        <div className={'option-group type-6'}>
          {renderOptions?.map((item, index) => (
            <label htmlFor={`radio-option-${index}`} className={"w-100 mt-2"}>
              <div className={`py-md-5 px-md-3 radio-option ${value1 === index ? 'selected' : ''}`} onClick={() => handleValueSelect(index, options[index])}>
                {/*<Form.Group className="mb-0" controlId="formBasicCheckbox">*/}
                {/*  <Form.Check name={"radio-option-3"} id={`radio-option-${index}`} className={'option-checkbox pt-0'} type="radio" />*/}
                {/*</Form.Group>*/}
                <Radio checked={value1 === index} value={index} name={"radio-option-3"}/>
                <div className={'option-div ml-3 d-block d-md-flex'}>
                  {item.map((type, index) => {
                    const colors = ['orange', 'purple', 'red']
                    return <div className={"option-div-sub"}>
                      <div className={`option-circle ${colors[index]}`}>
                        {type.percent}
                      </div>
                      <div className={"option-div-text"}>
                        {type.label}
                      </div>
                    </div>
                  })}
                </div>
              </div>
            </label>
          ))}
        </div>
        {errors && errors[step] && <div className={'invalid-feedback d-block mt-4'}>{errors[step]}</div>}
      </div>
    </>
  );
};

export default OptionsType6;
