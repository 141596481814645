import { ChangeEventHandler, DragEventHandler, useMemo, useRef } from 'react';
import { ReactComponent as UploadFile } from '../../../assets/images/UploadFile.svg';
import { getTruncatedStringFromEndToDisplay } from '../../../utility/utility';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface Props {
  onSelect: (file: File | null) => Promise<void>;
  src: File;
  preview?: boolean;
  accept: string[];
  error?: string;
}

export const FileUploader = ({ src, onSelect, accept, error }: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const previewContainerRefMobile = useRef<HTMLDivElement>(null);

  // File Input Methods
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files) return;
    onSelect(e.target.files ? Array.from(e.target.files)[0] : null);
  };

  const preventDefaultDragEvents: DragEventHandler<HTMLLabelElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDrop: DragEventHandler<HTMLLabelElement> = (e) => {
    preventDefaultDragEvents(e);
    onSelect(e.dataTransfer.files ? Array.from(e.dataTransfer.files)[0] : null);
  };

  const supportedExtnLabel = useMemo(() => {
    return `Supported file: ${accept
      .join(', ')
      .toLocaleLowerCase()} format, max size 2MB.`;
  }, []);

  const placeholder = src?.name ? (
    <div className='UploadCAS__upload_file_icon_wrapper'>
      <OverlayTrigger
        placement='top'
        delay={{ show: 100, hide: 200 }}
        overlay={<Tooltip id='edit-icon'>{src.name}</Tooltip>}
      >
        <span>{getTruncatedStringFromEndToDisplay(src.name)}</span>
      </OverlayTrigger>
    </div>
  ) : (
    <>
      <div className='UploadCAS__upload_file_icon_wrapper'>
        <UploadFile />
        <span>Click here to upload your CAS file</span>
      </div>
      <span className='UploadCAS__format_supported'>{supportedExtnLabel}</span>
    </>
  );

  return (
    <div className='UploadCAS__input_wrapper' ref={previewContainerRefMobile}>
      <label
        className='UploadCAS__input_label'
        htmlFor='upload-cas-file'
        onDrop={onDrop}
        onDragEnter={preventDefaultDragEvents}
        onDragLeave={preventDefaultDragEvents}
        onDragOver={preventDefaultDragEvents}
      >
        {placeholder}
        <input
          ref={ref}
          type='file'
          id='upload-cas-file'
          name='upload-cas-file'
          onChange={onChange}
          accept={accept.join(', ')}
          className='UploadCAS__input'
        />
      </label>
      {error && <div className='UploadCAS__error_text'>{error}</div>}
    </div>
  );
};
