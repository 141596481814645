import ICONS from './iconsData';

const SvgIcon = ({ id, color, auto }) => {
  return (
    <>
      {auto ? (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width={ICONS[id]?.width}
          height={ICONS[id]?.height}
          viewBox={ICONS[id]?.viewBox}
        >
          <path
            id={id}
            d={ICONS[id]?.path}
            transform={ICONS[id]?.transform}
            fill={ICONS[id]?.color || '#96a0b5'}
            stroke={ICONS[id]?.stroke || '#96a0b5'}
            fill-rule='evenodd'
            stroke-width='0.5'
          />
        </svg>
      ) : (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width={ICONS[id]?.width}
          height={ICONS[id]?.height}
          viewBox={ICONS[id]?.viewBox}
        >
          <path
            id={id}
            d={ICONS[id]?.path}
            transform={ICONS[id]?.transform}
            fill={color || '#96a0b5'}
          />
        </svg>
      )}
    </>
  );
};

export default SvgIcon;
