import { createSlice } from '@reduxjs/toolkit';

interface app_field {
  title: string;
  id: string;
  powered_by: boolean;
  favicon: string;
  logo: string;
  url: string;
}

interface WhitelabelSlice {
  mf: app_field | null;
  supportEmail: string | null;
}

const initialState: WhitelabelSlice = {
  mf: null,
  supportEmail: null,
};

const whitelabelSlice = createSlice({
  name: 'whitelabel',
  initialState: initialState,
  reducers: {
    setWhitelabelConfig: (state, action) => {
      state.mf = action.payload;
    },
    setSupportEmail: (state, action) => {
      state.supportEmail = action.payload;
    },
  },
});

export const { setWhitelabelConfig, setSupportEmail } = whitelabelSlice.actions;

// Export the reducer
export default whitelabelSlice.reducer;
