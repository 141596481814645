import React from 'react';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import arrowDown from '../../../assets/down_arrow.svg';
import './select.scss';
const DropdownList = ({ id, label, options, controlFunc, selectedOption, placeholder = '', classes, disabled }) => {
  return (
    <Dropdown className={classes.root + ' dropdown-list'}>
      <Dropdown.Toggle
        as="button"
        disabled={disabled}
        className={classes.input + ' d-flex w-100 align-items-center justify-content-between  bg-white text-left'}>
        <div className="col-sm-11 px-0">
          {label ? <span className="mr-1">{label}</span> : null}
          <span className={`text-wrap ${classes.selectedLabel}`}>{selectedOption ? selectedOption : placeholder}</span>
        </div>
        <img className="small-icon" src={arrowDown} alt="small-icon"/>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {!selectedOption || !placeholder ? null : (
          <Dropdown.Item
            className={'small py-2 ' + (selectedOption === placeholder ? 'c-blue' : '')}
            id={id + '- placeholder'}
            onClick={(e) => controlFunc(e, '')}>
            {placeholder}
          </Dropdown.Item>
        )}
        {options &&
          options.map((item, index) => {
            return (
              <Dropdown.Item
                className={'small py-2 ' + (selectedOption === item.label ? 'c-blue' : '')}
                key={id + '-' + index}
                id={id + '-' + index}
                onClick={(e) => controlFunc(e, item)}>
                {item.label}
              </Dropdown.Item>
            );
          })}
      </Dropdown.Menu>
    </Dropdown>
  );
};
DropdownList.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  classes: PropTypes.object,
  id: PropTypes.string,
  options: PropTypes.array.isRequired,
  selectedOption: PropTypes.string,
  controlFunc: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};
export default DropdownList;
