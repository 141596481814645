import authReducer from './auth-reducer';
import headerReducer from './header-reducer';
import mandateReducer from './mandate-reducer';
import onboardingReducer from './onboarding-reducer';
import paymentReducer from './payment-reducer';
import portfolioReducer from './portfolio-reducer';
import sipReducer from './sip-reducer';
import whitelabelReducers from './whitelabel-reducers';

const rootReducer = {
  authReducer,
  onboardingReducer,
  headerReducer,
  paymentReducer,
  mandateReducer,
  sipReducer,
  portfolioReducer,
  whitelabelReducers,
};

export default rootReducer;
