import { Redirect, Route } from 'react-router-dom';
import {} from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';

export const ProtectedMFRoute = ({
  component: Component,
  render,
  ...rest
}: any) => {
  const { isMutualFundInScope } = useAppSelector((state) => state.authReducer);
  return (
    <Route
      {...rest}
      render={(props) => {
        return isMutualFundInScope ? (
          Component ? (
            <Component {...props} />
          ) : (
            render(props)
          )
        ) : (
          <Redirect to='/home' />
        );
      }}
    ></Route>
  );
};
