import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import step1Icon from '../assets/step-1.svg';
import step2Icon from '../assets/step-2.svg';
import step3Icon from '../assets/step-3.svg';
import { Modal } from 'react-bootstrap';
import { checkPaymentStatus } from '../api/cart';
import { decryptData } from '../utility/DecryptHelper';
import ConfirmationModal from './ConfirmationModal';
import AlertModal from './AlertModal';
const PaymentInitiate = ({ show, onSubmit, userId, orderIds, isNetBanking }) => {
  const [time, setTime] = useState(300);
  const [confirmModalData, setConfirmModalData] = useState({ show: false });
  const [alertModalData, setAlertModalData] = useState({ show: false });
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    let timer = null;
    if (time >= 0) {
      timer = setInterval(() => {
        setTime((time) => time - 1);
      }, 1000);
      if (time === 0 || time % 5 === 0) {
        const formData = new FormData();
        formData.append('transaction_ids', JSON.stringify(orderIds));
        checkPaymentStatus(userId, formData).then((res) => {
          if (res && res.data) {
            const statusData = decryptData(res.data.data);
            if (statusData && statusData.data && statusData.data.payment) {
              clearInterval(timer);
              onSubmit();
            }
          }
        });
      }
    } else {
      setConfirmModalData({ header: 'Confirmation', show: true, message: 'Have you completed the payment from your UPI App?' });
    }
    return () => {
      clearInterval(timer);
    };
  }, [time]);

  const timeConvert = (num) => {
    const minutes = Math.floor(num / 60);
    const seconds = num % 60;
    return (minutes < 10 ? `0${minutes}` : minutes) + ':' + (seconds < 10 ? `0${seconds}` : seconds);
  };
  const handleConfrim = () => {
    setConfirmModalData({ show: false });
    setAlertModalData({ show: true, message: 'Thank you for the confirmation.' });
  };
  const handleClose = () => {
    setConfirmModalData({ show: false });
    setAlertModalData({ show: true, message: 'Thank you for the confirmation.' });
  };
  const handleAlertClose = () => {
    setAlertModalData({ show: false });
    onSubmit();
  };
  return (
    <>
      <Modal
        show={show}
        size="md"
        className={windowDimensions.width > 480 ? null : 'p-4'}
        aria-labelledby="select-bank-modal"
        onHide={onSubmit}
        centered
        backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center align-items-center flex-column mt-5">
            <div className=" p-5 colored-box text-center">
              <div className="page-title h5 font-weight-medium px-2">
                {isNetBanking ? 'Complete Payment on Netbanking page' : 'Follow the below steps to complete your payment'}
              </div>
              {isNetBanking ? null : (
                <>
                  <div className="payment-step payment-step-1">
                    <img src={step1Icon} alt="step 1" />
                    <span className="step-info">Open your UPI App</span>
                  </div>
                  <div className="payment-step payment-step-2">
                    <img src={step2Icon} alt="step 2" />
                    <span className="step-info">Accept the collect request sent from Indian Clearing Corporation (BSE Star MF India)</span>
                  </div>
                  <div className="payment-step payment-step-3">
                    <img src={step3Icon} alt="step 3" />
                    <span className="step-info">Upon acceptance, your payment will be mapped against your order</span>
                  </div>
                </>
              )}
              <div className="p-4">
                Please do not refresh the window until you accept the collect request valid for <span className="c-blue">{timeConvert(time)}</span>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {confirmModalData.show && (
        <ConfirmationModal
          show={confirmModalData.show}
          header={confirmModalData.header}
          message={confirmModalData.message}
          onConfirm={handleConfrim}
          onClose={handleClose}
        />
      )}
      {alertModalData.show && <AlertModal show={alertModalData.show} message={alertModalData.message} onClose={handleAlertClose} />}
    </>
  );
};
PaymentInitiate.propTypes = {
  show: PropTypes.bool,
  onSubmit: PropTypes.func,
  orderIds: PropTypes.array,
  userId: PropTypes.number,
  isNetBanking: PropTypes.bool,
};
export default PaymentInitiate;
