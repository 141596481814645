import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
const SwitchButton = ({ id, value, isChecked, onChange, label, className }) => {
  const [checked, setCheck] = useState(false);
  useEffect(() => {
    setCheck(isChecked);
  }, [isChecked]);
  const handleChange = (event) => {
    const { value, checked } = event.target;
    setCheck(checked);
    onChange({ value, checked });
  };
  return (
    <Form.Check
      className={className}
      name={id}
      type="checkbox"
      label={label}
      checked={checked}
      value={value}
      id={id + value}
      onChange={handleChange}
    />
  );
};
SwitchButton.propTypes = {
  id: PropTypes.string,
  value: PropTypes.any,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string,
};
export default SwitchButton;
