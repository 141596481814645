import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { logOut } from '../../api/nav';
import LogoutIcon from '../../assets/Logout.svg';
import ProfileIcon from '../../assets/Profile.svg';
import CartIcon from '../../assets/shopping-cart.svg';
import Logo from '../../component/whitelable/Logo';
import {
  updateHeaderOption,
  updateSelectedNav,
} from '../../redux/reducers/header-reducer';
import {
  redirectUserForAuthentication,
  removeToken,
} from '../../utility/utility';

const isLinkActive = (path) => {
  return window.location.pathname.includes(path);
};

const AppHeader = ({ history }) => {
  const { user_id, rise } = useSelector((state) => state.authReducer);
  const { showCart, showPages, showProfile, showProfileIcon } = useSelector(
    (state) => state.headerReducer
  );
  const dispatch = useDispatch();

  const onLinkSelection = (eventKey) => {
    if (eventKey === '1') {
      history.push('/profile/detail');
    } else if (eventKey === '2') {
      history.push('/order/summary');
    }
  };
  const updateActiveLink = (link) => {
    dispatch(updateSelectedNav(link));
  };

  const onLogoutClick = () => {
    dispatch(
      updateHeaderOption({
        showProfileIcon: false,
        showPages: false,
        showCart: false,
        showProfile: false,
      })
    );
    logOut(user_id)
      .then(() => {
        localStorage.removeItem('panData');
        localStorage.removeItem('uD');
        localStorage.removeItem('lD');
        removeToken();
        redirectUserForAuthentication();
      })
      .finally(() => {
        localStorage.removeItem('panData');
        localStorage.removeItem('uD');
        localStorage.removeItem('lD');
        removeToken();
        redirectUserForAuthentication();
      });
  };

  return (
    <nav className='navbar navbar-expand-lg sticky-top navbar-light bg-white mb-4 mx-4'>
      <Link className='navbar-brand' to='/'>
        <Logo />
      </Link>
      <button
        className='navbar-toggler'
        type='button'
        data-toggle='collapse'
        data-target='#tarakkiNavBar'
        aria-controls='tarakkiNavBar'
        aria-expanded='false'
        aria-label='Toggle navigation'
      >
        <span className='navbar-toggler-icon'></span>
      </button>

      <div
        className='collapse navbar-collapse font-weight-medium'
        id='tarakkiNavBar'
      >
        <ul className='navbar-nav ml-auto mt-2'>
          {showPages ? (
            <>
              <li
                className={`nav-item mr-4 ${isLinkActive('home') && 'active'}`}
                onClick={() => updateActiveLink('home')}
              >
                <Link className='nav-link' to='/home'>
                  Dashboard
                </Link>
              </li>
              <li
                className={`nav-item mr-4 ${
                  isLinkActive('invest') && 'active'
                }`}
                onClick={() => updateActiveLink('invest')}
              >
                <Link className='nav-link' to='/invest'>
                  Invest
                </Link>
              </li>
              <li
                className={`nav-item mr-4 ${
                  isLinkActive('portfolio') && 'active'
                }`}
                onClick={() => updateActiveLink('portfolio')}
              >
                <Link className='nav-link' to='/portfolio'>
                  Portfolio
                </Link>
              </li>
              <li
                className={`nav-item mr-4 ${
                  isLinkActive('transactions') && 'active'
                }`}
                onClick={() => updateActiveLink('transactions')}
              >
                <Link className='nav-link' to='/transactions'>
                  Transactions
                </Link>
              </li>
            </>
          ) : null}
          {showProfileIcon ? (
            <>
              <li
                className='nav-item'
                onClick={() => updateActiveLink('profile')}
              >
                <Dropdown className='text-right' onSelect={onLinkSelection}>
                  <Dropdown.Toggle
                    as='span'
                    id='dropdown-profile'
                    className='cursor-pointer'
                  >
                    <img
                      src={(rise && rise.user_profile_picture) || ProfileIcon}
                      className='rounded-circle header-profile-pic'
                      alt='profile pic'
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = ProfileIcon;
                      }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {showProfile ? (
                      <>
                        <Dropdown.Item eventKey='1'>
                          <div className='profile-list'>
                            <img
                              src={ProfileIcon}
                              alt='profile icon'
                              className='icon'
                            />
                            <span className='ml-3'>Profile</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Divider />
                      </>
                    ) : null}
                    {showCart ? (
                      <>
                        <Dropdown.Item
                          eventKey='2'
                          visibility={showCart ? '' : 'none'}
                        >
                          <div className='profile-list'>
                            <img
                              src={CartIcon}
                              alt='Cart icon'
                              className='icon'
                            />
                            <span className='ml-3'>Cart</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Divider />
                      </>
                    ) : null}
                    <Dropdown.Item eventKey='3'>
                      <div className='profile-list' onClick={onLogoutClick}>
                        <img
                          src={LogoutIcon}
                          alt='logout icon'
                          className='icon'
                        />
                        <span className='ml-3'>Logout</span>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </>
          ) : null}
        </ul>
      </div>
    </nav>
  );
};
AppHeader.propTypes = {
  history: PropTypes.object,
};

export default withRouter(AppHeader);
