import { createSlice } from '@reduxjs/toolkit';
import { PortfolioDetails } from '../../types';

interface PortfolioState {
  portfolioDetails: {
    portfolio_details: PortfolioDetails | undefined;
  };
}

const initialState: PortfolioState = {
  portfolioDetails: {
    portfolio_details: undefined,
  },
};

const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    setPortfolioDetails: (state, action) => {
      state.portfolioDetails = action.payload;
    },
  },
});

export const { setPortfolioDetails } = portfolioSlice.actions;

export default portfolioSlice.reducer;
