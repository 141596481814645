import React, { PropsWithChildren } from 'react';
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as Polygon } from '../../v2/assets/PureDropdown/polygon.svg';
import './PureDropdown.scss';

interface Props {
  value: string;
  onClick: (v: string) => void;
  items: string[];
}

interface CustomToggleProps {
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const CustomToggle = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<CustomToggleProps>
>(({ children, onClick }, ref) => (
  <div
    className='PureDropdown__trigger d-flex align-items-center user-select-none'
    onClick={onClick}
    ref={ref}
  >
    {children} <Polygon className='ml-2' />
  </div>
));

export const PureDropdown = ({ value, onClick, items }: Props) => {
  return (
    <Dropdown className='PureDropdown'>
      <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
        {value}
      </Dropdown.Toggle>
      <Dropdown.Menu className='PureDropdown__menu shadow-sm' align={'left'}>
        {items.map((v) => (
          <Dropdown.Item
            className='px-2 PureDropdown__item'
            key={v}
            onClick={() => {
              onClick(v);
            }}
          >
            {v}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
