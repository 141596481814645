import axios from './axios';
const getProtfolio = (id) => {
  return axios.get('portfolio/' + id +"/");
};
const redeemTransiction = (data) => {
  return axios.post('/transactions/redeem/', data);
};
const redeemConfirmation = (user_id) => {
  return axios.get(`/banks/get-default-bank/${user_id}`)
};
const generateOTP = (req) =>{
  return axios.post(`/mfapi/otp/`, req)
};
const verifyOTP = (req,otpId) =>{  
  return axios.post(`/mfapi/otp/${otpId}/verify/`,req);
};
const reSendOTP = (otpId) =>{
  return axios.post(`/mfapi/otp/${otpId}/resend/`);
};
const getUserPortfilio = (id) => {
  return axios.get(`/investors/${id}/portfolio/`);
}
export { getProtfolio, redeemTransiction, redeemConfirmation, generateOTP, verifyOTP, reSendOTP,getUserPortfilio };
