import { stopSelectedSip } from '../api/sips';
import Toast from '../common/toast/Toast';
const { bankUserMandate } = require('../api/bank');
const { decryptData } = require('./DecryptHelper');

export const stopSip = (item, userId, mandateData, setStopSip) => {
  const { id, fund_id, mandate_id, amount } = item;
  const selectedMandateData = mandateData?.filter((item) => item.mandate_id === mandate_id);
  const selectedMandate1 = selectedMandateData && selectedMandateData.length > 0 && selectedMandateData[0];
  if (selectedMandate1) {
    const data = {
      investor_id: userId.toString(),
      fund_id: fund_id.toString(),
      mandate_id: selectedMandate1?.id.toString(),
      amount: amount,
      state: 'cancel',
    };
    stopSelectedSip(data, id)
      .then((res) => {
        if (res.status === 200 && res.data) {
          Toast.success('Your SIP has successfully been stopped');
          setStopSip(true);
        } else {
          setStopSip(false);
        }
      })
      .catch((err) => setStopSip(false));
  }
};
export const getBankUserMandate = async (userId, handleResponse) => {
  await bankUserMandate(userId).then((res) => {
    if (res && res.data) {
      const mandate = decryptData(res.data.data);
      const data = mandate.data;
      handleResponse(data);
    }
  });
};
