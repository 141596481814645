import { format } from 'date-fns';
import Amount from '../../../component/Amount/Amount';
import { capitalize } from '../../../utility/utility';
import { FolioCell } from './FolioCell';
import './PortfolioDepositsCards.scss';

interface Props {
  deposits: {
    [key: string]: any;
  };
}

export const PortfolioDepositsCard = ({ deposits }: Props) => {
  return (
    <>
      {(deposits || []).map((deposit: any) => {
        const order_type =
          deposit.interest_payout_frequency === 'maturity'
            ? 'cumulative'
            : 'non_cumulative';

        deposit.order_type = order_type
          .split('_')
          .map((str: any) => capitalize(str))
          .join(' ');
        return (
          <div
            className='PortfolioDepositsCards__row PortfolioDepositsCards__dark my-3'
            key={deposit.id}
          >
            <div className='PortfolioDepositsCards__row_grid d-grid pb-1 mb-2'>
              <div className='PortfolioDepositsCards__isser_detail fw-500 d-flex align-items-center'>
                <img
                  src={deposit?.issuer?.logo}
                  alt={deposit?.issuer?.name}
                  className='PortfolioDepositsCards__logo rounded-circle mr-3'
                />
                <div className='font-medium'>{deposit?.issuer?.name}</div>
              </div>
              <FolioCell label='Start Date'>
                {format(new Date(deposit.created_at), 'dd/MM/yyyy')}
              </FolioCell>
              <FolioCell label='End Date'>
                {deposit?.maturity_date
                  ? format(new Date(deposit?.maturity_date), 'dd/MM/yyyy')
                  : 'NA'}
              </FolioCell>
              <FolioCell label='Invested Amount'>
                <Amount
                  className='text-nowrap'
                  value={deposit.amount}
                  options={{
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  }}
                />
              </FolioCell>
              <FolioCell label='Maturity Amount'>
                <Amount
                  className='text-nowrap'
                  value={deposit.maturity_amount}
                  options={{
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  }}
                />
              </FolioCell>
              <FolioCell label='Interest Rate'>
                <div className='fw-500 d-flex align-items-center justify-content-center'>
                  {deposit?.interest_rate}%
                </div>
              </FolioCell>
              <FolioCell label='Tenure'>
                <div className='fw-500 d-flex align-items-center'>
                  {deposit.tenure}&nbsp;Months
                </div>
              </FolioCell>
              <FolioCell label='Interest Payout'>
                {deposit.interest_payout_frequency
                  .split('_')
                  .map((str: any) => capitalize(str))
                  .join(' ')}
              </FolioCell>
              <FolioCell label='Type'>{deposit.order_type}</FolioCell>
            </div>
          </div>
        );
      })}
    </>
  );
};
