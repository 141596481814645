import React, { useEffect, useState } from 'react';
import { AnnualIncome } from '../../common/on-boarding';
import { InputBox, SelectBox } from '../../common/form';
import { wealthSourceList } from '../../utility/utility';
import { updateFatcaData } from '../../api/kyc';
import { placeofbirthRegex } from '../../utility/constant';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getInvestorsStatus } from '../../utility/investorStatus';
import { saveInvestorDetails } from '../../api/onboarding.api';

const guardian_relationship_list = [
  { label: 'Mother', value: 'mother' },
  { label: 'Father', value: 'father' },
];

const OnboardingMinor = ({
  fatcaData,
  history,
  minorDetails,
  onSubmit,
  onFatcaSubmitted,
  handleErrorsBank,
}) => {
  const userId = useSelector((state) => state.authReducer.user_id);
  const dispatch = useDispatch();
  const [placeOfBirth, setPlace] = useState('');
  const [annualIncome, setAnnualIncome] = useState('');
  const [wealthSource, setWealthSource] = useState('');
  const [birthCountry, setBirthCountry] = useState('India');
  const [guardianRelation, setGuardianRelation] = useState(undefined);
  const [placeOfBirthErr, setPlaceOfBirthErr] = useState('');
  const [errors, setErrors] = useState([]);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [checked, setChecked] = useState(false);
  const [mobileDeclaration, setMobileDeclaration] = useState(false);
  const [emailDeclaration, setEmailDeclaration] = useState(false);
  const [declarationError, setDeclarationError] = useState();
  const [notPoliticallyExposedCheck, setNotPoliticallyExposedCheck] =
    useState(false);
  const [politicallyExposedError, setPoliticallyExposedError] = useState();

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    setfatcaData(fatcaData);
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [fatcaData]);
  const setfatcaData = (data) => {
    setPlace(data.place_of_birth);
    setAnnualIncome(data.income_slab);
    setWealthSource(data.wealth_src);
    setBirthCountry(data.country_of_birth);
    setNotPoliticallyExposedCheck(!data.is_entity_politically_exposed_person);
    if (
      data.mobile_number &&
      data.mobile_declaration &&
      data.email_declaration
    ) {
      setChecked(true);
      setMobileDeclaration(true);
      setEmailDeclaration(true);
    }
    if (!data.mobile_number && data.email_declaration) {
      setChecked(true);
      setEmailDeclaration(true);
    }
  };
  const saveFormData = () => {
    if (validateData()) {
      const formData = new FormData();
      formData.append('place_of_birth', placeOfBirth);
      formData.append('country_of_birth', birthCountry);
      if (wealthSource !== undefined || !wealthSource) {
        formData.append('wealth_src', wealthSource);
      }
      formData.append('income_slab', annualIncome);
      if (minorDetails.fullName) {
        formData.append('full_name', minorDetails.fullName);
      }
      formData.append(
        'dob_minor',
        minorDetails.dobMinor ||
          new Date()
            .toLocaleDateString('en-GB', {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            })
            .split(' ')
            .join('/')
      );
      formData.append('mobile_declaration', mobileDeclaration);
      formData.append('email_declaration', emailDeclaration);
      formData.append('is_draft', true);
      formData.append(
        'is_entity_politically_exposed_person',
        !notPoliticallyExposedCheck
      );
      updateGuardianDetails(userId, guardianRelation);

      updateFatcaData(formData).then((res) => {
        if (res.data.status.code === 1) {
          history.push('/home');
          getInvestorsStatus(userId, dispatch);
        }
      });
      localStorage.setItem('saveAsDraft', true);
      localStorage.setItem('emailDeclaration', emailDeclaration);
      onSubmit(true);
    }
  };

  const updateGuardianDetails = (userId, guardianRelationship) => {
    if (!userId) {
      throw new Error('User ID is not available!');
    }
    saveInvestorDetails(userId, {
      guardian_detail: {
        relation: guardianRelationship,
      },
    });
  };

  const submitFormData = () => {
    // true for submit
    if (validateData(true)) {
      const formData = new FormData();
      formData.append('place_of_birth', placeOfBirth);
      formData.append('country_of_birth', birthCountry);
      formData.append('wealth_src', wealthSource);
      formData.append('income_slab', annualIncome);
      formData.append('full_name', minorDetails.fullName);
      formData.append(
        'dob_minor',
        minorDetails.dobMinor ||
          new Date()
            .toLocaleDateString('en-GB', {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            })
            .split(' ')
            .join('/')
      );
      formData.append('is_submit', true);
      formData.append('is_draft', false);
      formData.append('mobile_declaration', mobileDeclaration);
      formData.append('email_declaration', emailDeclaration);
      formData.append(
        'is_entity_politically_exposed_person',
        !notPoliticallyExposedCheck
      );
      if (!notPoliticallyExposedCheck) {
        setPoliticallyExposedError(
          'Please confirm you are not politically exposed'
        );
      }
      if (!emailDeclaration) {
        setDeclarationError(
          `Please confirm your Email ${
            fatcaData.mobile_number ? 'and Mobile ' : ''
          }Declaration.`
        );
      }
      // Update guardian's relationship
      updateGuardianDetails(userId, guardianRelation);

      if (emailDeclaration && notPoliticallyExposedCheck) {
        updateFatcaData(formData).then((res) => {
          if (res.data.status.code === 1) {
            // history.push('/home');
            onFatcaSubmitted();
            getInvestorsStatus();
          }
        });
        localStorage.setItem('saveAsDraft', false);
        localStorage.setItem('emailDeclaration', false);
        onSubmit();
      }
    }
  };

  const validateData = (isSubmit) => {
    let errors = [];

    if (isSubmit && !guardianRelation) {
      errors['guardianRelation'] = 'Guardian Relationship is required.';
    }

    if (isSubmit && (!wealthSource || wealthSource.length === 0)) {
      errors['wealthSource'] = 'Wealth Source is required.';
    }
    if (isSubmit && (!birthCountry || birthCountry.length === 0)) {
      errors['birthCountry'] = "Guardian's Country of Birth is required.";
    }
    if (isSubmit && (!placeOfBirth || placeOfBirth.length === 0)) {
      setPlaceOfBirthErr("Guardian's Place of Birth is required.");
    }
    setErrors(errors);
    let status = handleErrorsBank(!isSubmit);
    if (isSubmit && ((errors && Object.keys(errors).length > 0) || !status)) {
      return false;
    }
    return true;
  };

  const onBirthPlaceChange = (event) => {
    let inputValue = event.target.value || '',
      placeErr = '';
    if (!inputValue) {
      setPlace('');
    } else if (!placeofbirthRegex.test(inputValue)) {
      placeErr =
        "Please enter a Guardian's Place of Birth without spaces or characters like('-', '&', '@', etc)";
    } else {
      placeErr = '';
      setPlace(inputValue);
    }
    setPlaceOfBirthErr(placeErr);
  };

  const checkboxHandleChange = (event) => {
    const { checked } = event.target;
    setChecked(checked);
    if (checked) {
      setEmailDeclaration(true);
      if (fatcaData.mobile_number) {
        setMobileDeclaration(true);
      }
      setDeclarationError(undefined);
    } else {
      setEmailDeclaration(false);
      setMobileDeclaration(false);
    }
  };

  const handleGuardianRelationChange = (value) => {
    setGuardianRelation(value);
    errors['guardianRelation'] = '';
    setErrors(errors);
  };

  const countryOfBirthChange = (value) => {
    setBirthCountry(value);
    errors['birthCountry'] = '';
    setErrors(errors);
  };

  const wealthSourceChange = (value) => {
    setWealthSource(value);
    errors['wealthSource'] = '';
    setErrors(errors);
  };

  const politicallyExposedChange = (event) => {
    const { checked } = event.target;
    setNotPoliticallyExposedCheck(checked);
    if (checked) {
      setPoliticallyExposedError(undefined);
    }
  };

  return (
    <section className='col-sm-12'>
      <h6 className='font-weight-bold mt-4'>Details for FATCA</h6>
      {windowDimensions.width > 480 ? (
        <>
          <div className='border p-4 rounded w-100 my-3'>
            <SelectBox
              title="Guardian's Relation"
              value={guardianRelation}
              id='guardianRelation'
              classes={{ root: 'col-sm-3 px-0' }}
              onUpdate={handleGuardianRelationChange}
              options={guardian_relationship_list}
              err={errors && errors['guardianRelation']}
            />
          </div>
          <div className='border p-4 rounded w-100 my-3'>
            <InputBox
              id='PlaceofBirth'
              type='text'
              className='col-sm-5'
              onChange={onBirthPlaceChange}
              title="Guardian's Place of Birth"
              value={placeOfBirth}
              defaultValue={placeOfBirth}
              maxLength={40}
              err={placeOfBirthErr}
            />
          </div>
          <div className='border p-4 rounded w-100 my-3'>
            <SelectBox
              title="Guardian's Country of Birth"
              value={birthCountry}
              id='countryBirth'
              classes={{ root: 'col-sm-3 px-0' }}
              onUpdate={countryOfBirthChange}
              options={[{ label: 'India', value: 'India' }]}
              err={errors && errors['birthCountry']}
            />
          </div>
          <div className='border p-4 rounded w-100 my-3'>
            <SelectBox
              title="Guardian's Wealth Source"
              value={wealthSource}
              id='WealthSource'
              classes={{ root: 'col-sm-3 px-0' }}
              onUpdate={wealthSourceChange}
              options={wealthSourceList}
              placeholder='Select wealth Source'
              err={errors && errors['wealthSource']}
            />
          </div>
        </>
      ) : (
        <>
          <div className='  rounded w-100 my-3'>
            <SelectBox
              title="Guardian's Relation"
              value={guardianRelation}
              id='guardianRelation'
              classes={{ root: 'col-sm-3 px-0' }}
              onUpdate={handleGuardianRelationChange}
              options={guardian_relationship_list}
              err={errors && errors['guardianRelation']}
            />
          </div>
          <div className=' pt-2 rounded w-100 my-3'>
            <InputBox
              id='PlaceofBirth'
              type='text'
              className='col-sm-5'
              onChange={onBirthPlaceChange}
              title="Guardian's Place of Birth"
              value={placeOfBirth}
              defaultValue={placeOfBirth}
              maxLength={40}
              err={placeOfBirthErr}
            />
          </div>
          <div className='  rounded w-100 my-3'>
            <SelectBox
              title="Guardian's Country of Birth"
              value={birthCountry}
              id='countryBirth'
              classes={{ root: 'col-sm-3 px-0' }}
              onUpdate={countryOfBirthChange}
              options={[{ label: 'India', value: 'India' }]}
              err={errors && errors['birthCountry']}
            />
          </div>
          <div className='rounded w-100 my-3'>
            <SelectBox
              title="Guardian's Wealth Source"
              value={wealthSource}
              id='WealthSource'
              classes={{ root: 'col-sm-3 px-0' }}
              onUpdate={wealthSourceChange}
              options={wealthSourceList}
              placeholder="Select Guardian's Wealth Source"
              err={errors && errors['wealthSource']}
            />
          </div>
        </>
      )}
      <AnnualIncome
        value={annualIncome}
        onChange={setAnnualIncome}
        title={"Guardian's Annual Income"}
      />
      <div className='py-2 rounded w-100 '>
        <Form.Check>
          <Form.Check.Input
            id='declaration'
            type='checkbox'
            checked={checked}
            onChange={checkboxHandleChange}
            style={{ cursor: 'pointer' }}
          />
          <Form.Check.Label
            for='declaration'
            onChange={checkboxHandleChange}
            style={{ cursor: 'pointer' }}
          >
            {fatcaData.mobile_number
              ? 'I confirm that mobile number ' +
                fatcaData.mobile_number +
                ' and email ' +
                fatcaData.email +
                ' belong to ' +
                fatcaData.pan_name
              : 'I confirm that the email id ' +
                fatcaData.email +
                ' belong to ' +
                fatcaData.pan_name}
          </Form.Check.Label>
        </Form.Check>
        {declarationError && (
          <div className={'invalid-feedback d-block mt-2'}>
            {declarationError}
          </div>
        )}
        <Form.Check className={'mt-2'}>
          <Form.Check.Input
            id='politicallyExposed'
            type='checkbox'
            checked={notPoliticallyExposedCheck}
            onChange={politicallyExposedChange}
            style={{ cursor: 'pointer' }}
          />
          <Form.Check.Label
            for='politicallyExposed'
            onChange={politicallyExposedChange}
            style={{ cursor: 'pointer' }}
          >{`I ${fatcaData?.pan_name}, confirm that I am not politically exposed`}</Form.Check.Label>
        </Form.Check>
        {politicallyExposedError && (
          <div className={'invalid-feedback d-block mt-2'}>
            {politicallyExposedError}
          </div>
        )}
      </div>
      <div className='btn-container text-center my-4 col-sm-12'>
        <button
          type='button'
          className='btn btn-outline-primary m-2'
          onClick={saveFormData}
        >
          Save As Draft
        </button>
        <button
          type='button'
          className='btn btn-primary m-2 btn-width'
          onClick={submitFormData}
        >
          Submit
        </button>
      </div>
    </section>
  );
};
export default OnboardingMinor;
