import { ApplicantInDB } from '../models';

export interface MutualFundTransactionRecord {
  id: number;
  date: string;
  order_type: string;
  investor_id: number;
  fund_id: string;
  fund_name: string;
  amount: number;
  status: string;
  folio?: number;
  units?: number;
  nav?: number;
}

export interface MutualFundsTransactionsOut {
  results: MutualFundTransactionRecord[];
  limit?: number;
  next?: string;
  previous?: string;
  currPage?: number;
  count?: number;
}

export interface DepositsTransactionRecord {
  id: number;
  user_id: number;
  created_at: string;
  updated_at: string;
  tarrakki_id: string | null;
  payable_to: string | null;
  ip_address: string | null;
  tarrakki_status: string | null;
  amount: number;
  interest_rate: number;
  scheme_category: string;
  interest_payout_frequency: string;
  tenure: number;
  scheme_id: string;
  issuer_id: string;
  women_special: boolean;
  applicant_id: string | null;
  has_nominee: boolean | null;
  maturity_amount: number | null;
  maturity_date: string | null;
  issuer: {
    name: string;
  };
  applicant?: ApplicantInDB;
}

export interface DepositsTransactionsOut {
  limit?: number;
  next?: string;
  prev?: string;
  results: DepositsTransactionRecord[];
}

export interface TransactionRecord {
  id: number;
  user_id: number;
  date: string;
  record_id: string;
  asset_name: string;
  amount: number;
  order_status: string | null;
  order_type?: string;
  interest_rate?: number;
  issuer_id?: string;
  tenure?: number;
  folio?: number;
  units?: number;
  nav?: number;
  next_steps?: string | undefined;
  next_steps_link?: string | undefined;
  deposits_tarraki_id?: string | null;
  maturity_date?: string | null;
  maturity_amount?: number | null;
  applicant?: ApplicantInDB;
  asset_logo?: string;
}

export interface TransactionsOut {
  results: TransactionRecord[];
  limit?: number | null;
  next: string | null;
  previous: string | null;
  currPage?: number;
  count?: number;
}

export const OrderStatusValues: { [key: string]: string } = {
  // For Mutual Funds
  failed: 'Failed',
  success: 'In Progress',
  payment_not_initiated: 'Unpaid',
  awaiting_payment_confirmation: 'In Progress',
  payment_approved: 'In Progress',
  payment_rejected: 'Unpaid',
  complete: 'Completed',
  pending: 'Pending',
  cancelled: 'Cancelled',

  // For Fixed Deposits
  awaiting_documents: 'In Progress',
  awaiting_payment: 'Payment Pending',
  under_review: 'Under Review',
  active: 'Active',
  matured: 'Matured',
  rejected: 'Rejected',
};

export const OrderTypeValues: { [key: string]: string } = {
  switch: 'switch',
  stp: 'stp',
  buy: 'Purchase',
  sell: 'Redemption',
  sip: 'Purchase',
  swp: 'Redemption',

  // For Fixed Deposits
  cumulative: 'Cumulative',
  non_cumulative: 'Non Cumulative',
};

export enum AssetNameValues {
  'Mutual Funds' = 'Fund',
  'Deposits' = 'Issuer',
}

export default TransactionRecord;

export enum KRA_Enum {
  CAMSKRA = 'https://camskra.com/emvalidation.aspx',
  CVLKRA = 'https://validate.cvlindia.com/CVLKRAVerification_V1/',
  NDMLKRA = 'https://kra.ndml.in/ClientInitiatedKYC-webApp/#/ClientinitiatedKYC',
  DOTEXKRA = 'https://mfs.kfintech.com/Investor/General/ValidateKYC/',
  KARVYKRA = 'https://www.karvykra.com/KYC_Validation/Default.aspx',
}
