import { useQuery } from '@tanstack/react-query';
import { HiOutlineClock } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { fdApi } from '../../../api/fdApi';
import { getTopFunds } from '../../../api/fund';
import { DepositsTile } from '../../../component/DashboardV2/DepositsTile';
import { FixedDeposits } from '../../../component/DashboardV2/FixedDeposits';
import { Investments } from '../../../component/DashboardV2/Investments';
import { MutualFundsTile } from '../../../component/DashboardV2/MutualFundsTile';
import { useAppSelector } from '../../../redux/hooks';
import { TopFundsResponse } from '../../../types';
import { ReactComponent as ImportFunds } from '../../assets/DashboardV2/import-funds.svg';
import { ReactComponent as NewFunds } from '../../assets/DashboardV2/new-funds.svg';
import { ReactComponent as Risk } from '../../assets/DashboardV2/risk.svg';
import { ReactComponent as SwitchFunds } from '../../assets/DashboardV2/switch-funds.svg';
import { MainLoader } from '../../shared/component/MainLoader';
import TopPicks from '../Dashboard/Components/TopPicks';
import './DashboardV2.scss';

const quicklinks = [
  {
    color: 'orange',
    label: 'New Fund Offerings',
    link: '/funds/all',
    icon: <NewFunds />,
    search: 'nfo=1',
  },
  {
    color: 'purple',
    label: 'Switch Funds',
    link: '/switch-funds',
    icon: <SwitchFunds />,
  },
  {
    color: 'blue',
    label: 'Import External Funds',
    link: '/externalfunds/upload-cas',
    icon: <ImportFunds />,
  },
  {
    color: 'green',
    label: 'Risk Assessment',
    link: '/risk-assessment',
    icon: <Risk />,
  },
];

export const DefaultDashboard = () => {
  const user = useAppSelector((state) => state.authReducer);
  const { portfolioDetails } = useAppSelector(
    (state) => state.portfolioReducer
  );
  const isMutualFundInScope = user.isMutualFundInScope;
  const isFixedDepositsInScope = user.isFixedDepositsInScope;

  const { data: topMutualFunds, isFetching: isLoadingTopFunds } =
    useQuery<TopFundsResponse>({
      queryKey: ['topFunds', user?.user_id],
      queryFn: async () => {
        const res = await getTopFunds(4, 0);
        return res.data;
      },
      enabled: isMutualFundInScope,
    });

  const { data: assets, isFetching: isLoadingAssets } = useQuery({
    queryKey: ['Assets', user?.user_id],
    queryFn: fdApi.asset.getAssets,
    enabled: isMutualFundInScope,
  });

  const { data: issuers } = useQuery({
    queryKey: ['FixedDeposits'],
    queryFn: fdApi.issuer.getIssuers,
    enabled: isFixedDepositsInScope,
  });

  const { data: fDPortfolioDetails } = useQuery<any>({
    queryKey: ['FixedDepositsPortfolioDetails', user?.user_id],
    queryFn: async () => {
      const response = await fdApi.holdings.getFdHoldings({
        summary: true,
        detail: false,
      });
      const summary = response.summary;
      return {
        total_investment: summary.amount,
        maturity_amount: summary.maturity_amount,
        min_interest_rate: summary.min_interest_rate,
        max_interest_rate: summary.max_interest_rate,
      };
    },
    enabled: isFixedDepositsInScope,
  });

  // MAIN LOAODER

  if (isLoadingTopFunds || isLoadingAssets) return <MainLoader />;

  return (
    <>
      <div className='DashboardV2__row row'>
        {isFixedDepositsInScope ?? isMutualFundInScope ? (
          <Investments
            fdPortfolio={fDPortfolioDetails}
            portfolio={portfolioDetails.portfolio_details}
            assets={assets!}
            showImportExternalFundsOption={false}
          />
        ) : null}
        {isMutualFundInScope ? (
          <div
            className='col-12 col-xl-5 DashboardV2__col pb-3 mb-1 d-flex flex-column'
            style={{
              maxWidth: 458,
            }}
          >
            <div className='DashboardV2__tile DashboardV2__quicklinks flex-grow-1'>
              <div className='DashboardV2__title DashboardV2__quicklinksTitle'>
                Quick Links
              </div>
              <div className='d-grid DashboardV2__quicklinksGrid'>
                {quicklinks.map((c) => {
                  if (c.link) {
                    return (
                      <Link
                        to={{ pathname: c.link, search: c.search || '' }}
                        className={`DashboardV2__${c.color}Tile DashboardV2__gridItem d-flex flex-column justify-content-center align-items-center p-3`}
                        key={c.label}
                      >
                        {c.icon}
                        <div className='mt-2 pt-1'>{c.label}</div>
                      </Link>
                    );
                  }
                  return (
                    <div
                      className={`DashboardV2__${c.color}Tile DashboardV2__gridItem d-flex flex-column justify-content-center align-items-center`}
                      key={c.label}
                    >
                      {c.icon}
                      <div className='mt-2 pt-1'>{c.label}</div>
                      <div className='DashboardV2__comingSoon'>
                        <HiOutlineClock /> Coming Soon
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : null}
      </div>

      {/* scope can be mf, fd and mf+fd */}
      {
        <>
          <div className='DashboardV2__row row mb-3 pb-1'>
            {isMutualFundInScope && portfolioDetails && (
              <div className='col-12 col-lg-6 mb-3'>
                <MutualFundsTile
                  className='DashboardV2__investmentTile'
                  data={portfolioDetails?.portfolio_details}
                />
              </div>
            )}
            {isFixedDepositsInScope && (
              <div className='col-12 col-lg-6'>
                <DepositsTile
                  className='DashboardV2__investmentTile'
                  data={fDPortfolioDetails}
                />
              </div>
            )}
          </div>
        </>
      }

      {isMutualFundInScope && (
        <div className='mb-3 pb-1'>
          <TopPicks topMutualFunds={topMutualFunds?.results} />
          {/* <MutualFunds funds={topFunds?.results || []} /> */}
        </div>
      )}
      {isFixedDepositsInScope && (
        <div className='mb-3 pb-1'>
          <FixedDeposits issuers={issuers || []} />
        </div>
      )}
    </>
  );
};
