import { createPortal } from 'react-dom';
import styled from '@emotion/styled';

export const MainLoader = () => {
  return createPortal(
    <MainLoaderStyled className='MainLoader'>
      <MainLoaderContentStyled>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </MainLoaderContentStyled>
    </MainLoaderStyled>,
    document.getElementById('loaders')!
  );
};

const MainLoaderStyled = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
`;

const MainLoaderContentStyled = styled.div`
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #08aeea transparent transparent transparent;
  }
  & div:nth-of-type(1) {
    animation-delay: -0.45s;
  }
  & div:nth-of-type(2) {
    animation-delay: -0.3s;
  }
  & div:nth-of-type(3) {
    animation-delay: -0.15s;
  }
  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
