import { AxiosPromise } from 'axios';
import { client } from '../client';

interface GetDepositsTransactions {
  params: {
    [key: string]: any;
  };
}

const getDepositsTransactions = async ({
  params,
}: GetDepositsTransactions): Promise<any> => {
  let url = `${process.env.REACT_APP_FD_API_URL}/transactions?`;
  if (params) {
    const queryString = Object.entries(params)
      .filter(([, value]) => ![null, undefined, ''].includes(value))
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join('&');
    url += queryString;
  }
  const res = await client.get<AxiosPromise<any>>(url);
  return res;
};

const api = {
  getDepositsTransactions,
};

export default api;
