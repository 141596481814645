import React from 'react';
import OptionsType1 from './OptionsType1';
import OptionsType2 from './OptionsType2';
import OptionsType3 from './OptionsType3';
import OptionsType4 from './OptionsType4';
import OptionsType01 from './OptionsType01';
import OptionsType5 from './OptionsType5';
import OptionsType6 from './OptionsType6';


const Options = ( {currentStep, question, updateAnswerJson, errors, value} ) => {

  const getOptions = (type) => {
    switch (currentStep) {
      case 0:
        return <OptionsType1 step={currentStep} errors={errors} updateAnswerJson={updateAnswerJson} options={question?.options || []} question={question} value={value} />;
      case 1:
        return <OptionsType2 step={currentStep} errors={errors} updateAnswerJson={updateAnswerJson} options={question?.options || []} question={question} value={value} />;
      case 2:
        return <OptionsType3 step={currentStep} errors={errors} updateAnswerJson={updateAnswerJson} options={question?.options || []} question={question} value={value} />;
      case 3:
        return <OptionsType3 step={currentStep} errors={errors} updateAnswerJson={updateAnswerJson} options={question?.options || []} question={question} value={value} />;
      case 4:
        return <OptionsType01 step={currentStep} errors={errors} updateAnswerJson={updateAnswerJson} options={question?.options || []} question={question} value={value} />;
      case 5:
        return <OptionsType4 step={currentStep} errors={errors} updateAnswerJson={updateAnswerJson} options={question?.options || []} question={question} value={value} />;
      case 6:
        return <OptionsType3 step={currentStep} errors={errors} updateAnswerJson={updateAnswerJson} options={question?.options || []} question={question} value={value} />;
      case 7:
        return <OptionsType5 step={currentStep} errors={errors} updateAnswerJson={updateAnswerJson} options={question?.options || []} question={question} value={value} />;
      case 8:
        return <OptionsType3 step={currentStep} errors={errors} updateAnswerJson={updateAnswerJson} options={question?.options || []} question={question} value={value} />;
      case 9:
        return <OptionsType6 step={currentStep} errors={errors} updateAnswerJson={updateAnswerJson} options={question?.options || []} question={question} value={value} />;

        default:break;
    }

  }

  return (
    <>
      {getOptions(question)}
    </>
  )
};

export default Options;