import { useQuery } from '@tanstack/react-query';
import { fdApi } from '../../../api/fdApi';
import { getHomeData } from '../../../api/nav';
import { getProtfolio } from '../../../api/portfolio';
import { DepositsTile } from '../../../component/DashboardV2/DepositsTile';
import { Investments } from '../../../component/DashboardV2/Investments';
import { MutualFundsTile } from '../../../component/DashboardV2/MutualFundsTile';
import { useAppSelector } from '../../../redux/hooks';
import { PortfolioDetails, PortfolioItem } from '../../../types';
import { decryptData } from '../../../utility/DecryptHelper';
import { MainLoader } from '../../shared/component/MainLoader';
import { PortfolioDeposits } from './PortfolioDeposits';
import { PortfolioMutualFunds } from './PortfolioMutualFunds';

export const MyPortfolioV2 = () => {
  const user = useAppSelector((state) => state.authReducer);
  const isMutualFundInScope = user.isMutualFundInScope;
  const isFixedDepositsInScope = user.isFixedDepositsInScope;

  const { data: funds, isFetching: isLoadingfunds } = useQuery<PortfolioItem[]>(
    {
      queryKey: ['PortfolioItems', user?.user_id],
      queryFn: async () => {
        const res = await getProtfolio(user.user_id);
        const portfolioData: {
          [key: string]: {
            [key: string]: PortfolioItem[];
          };
        } = decryptData(res.data.data);
        return portfolioData?.data ? portfolioData.data?.investment : [];
      },
      enabled: isMutualFundInScope,
    }
  );

  const { data: fDPortfolioDetails } = useQuery<any>({
    queryKey: ['ActiveFDApplication', user?.user_id],
    queryFn: async () => {
      const response = await fdApi.holdings.getFdHoldings({
        summary: true,
        detail: true,
      });

      const summary = response.summary;
      const detail = response.detail;
      return {
        summary: {
          total_investment: summary?.amount ?? 0,
          maturity_amount: summary?.maturity_amount ?? 0,
          min_interest_rate: summary?.min_interest_rate ?? 0,
          max_interest_rate: summary?.max_interest_rate ?? 0,
        },
        detail,
      };
    },
    initialData: null,
    enabled: isFixedDepositsInScope,
  });

  const { data: assets, isFetching: isLoadingAssets } = useQuery({
    queryKey: ['Assets', user?.user_id],
    queryFn: fdApi.asset.getAssets,
    enabled: isMutualFundInScope,
  });

  const { data: portfolioDetails, isFetching: isLoadingPortfolioDetails } =
    useQuery<PortfolioDetails | undefined>({
      queryKey: ['PortfolioDetails', user?.user_id],
      queryFn: async () => {
        const res = await getHomeData();
        const homeData = decryptData(res.data.data);
        if (homeData.portfolio_details) {
          return homeData.portfolio_details;
        }
      },
      initialData: undefined,
      enabled: isMutualFundInScope,
    });

  if (isLoadingfunds || isLoadingPortfolioDetails || isLoadingAssets)
    return <MainLoader />;

  if (!portfolioDetails && !fDPortfolioDetails) {
    return (
      <>
        <div className='DashboardV2__title DashboardV2__heading mb-3 pb-3'>
          My Portfolio
        </div>
        <Investments
          fdPortfolio={fDPortfolioDetails?.summary}
          portfolio={portfolioDetails}
          assets={assets}
          className='w-100'
          type='portfolio'
        />
      </>
    );
  }

  return (
    <div className='DashboardV2'>
      <div className='DashboardV2__title DashboardV2__heading mb-3 pb-3'>
        My Portfolio
      </div>
      <div className='DashboardV2__row row'>
        {isMutualFundInScope && isFixedDepositsInScope ? (
          <Investments
            fdPortfolio={fDPortfolioDetails?.summary}
            portfolio={portfolioDetails}
            assets={assets}
            className='col-xl-6'
            type='portfolio'
          />
        ) : null}

        {portfolioDetails &&
        portfolioDetails?.total_investment <= 0 &&
        [0, undefined].includes(
          fDPortfolioDetails?.summary?.total_investment
        ) ? null : (
          <div
            className='col-12 col-xl-6 DashboardV2__col pb-3 mb-1 d-flex flex-column gap-3'
            style={{
              maxWidth: 580,
            }}
          >
            {isMutualFundInScope && portfolioDetails && (
              <div className='mb-3 pb-1'>
                <MutualFundsTile
                  className='DashboardV2__investmentTile'
                  data={portfolioDetails}
                />
              </div>
            )}

            {isFixedDepositsInScope &&
              fDPortfolioDetails?.summary?.total_investment > 0 && (
                <DepositsTile
                  className='DashboardV2__investmentTile'
                  data={fDPortfolioDetails?.summary}
                />
              )}
          </div>
        )}
      </div>
      {isMutualFundInScope && portfolioDetails && (
        <div className='mb-3 pb-1'>
          <PortfolioMutualFunds funds={funds || []} />
        </div>
      )}

      {isFixedDepositsInScope && fDPortfolioDetails?.detail && (
        <div className='mb-3 pb-1'>
          <PortfolioDeposits deposits={fDPortfolioDetails?.detail} />
        </div>
      )}
    </div>
  );
};
