import { useMemo, useState } from 'react';
import { FileUploader } from './FileUploader';
import './UploadCASTile.scss';
import { fileToBase64 } from '../../../utility/utility';
import Toast from '../../../common/toast/Toast';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { DownloadCASInstructionModal } from './DownloadCASInstructionModal';
import { MainLoader } from '../../shared/component/MainLoader';
import { ResultModal } from './ResultModal';
import { useSelector } from 'react-redux';
import { uploadCasApi } from '../../../api/externalFunds';
import { AuthState } from '../../../redux/reducers/auth-reducer';
import { OnboardingState } from '../../../redux/reducers/onboarding-reducer';

const ACCEPTED_EXT = ['PDF'];
const MAX_FILE_SIZE_IN_MB = 2;

interface UploadCASResultState {
  cas_id?: string;
  resultType?: 'success' | 'failure';
  remarks: string;
}

const PAGE_HEADING = `Please download your CAS statement received on your email and upload
it here`;
const PWD_FIELD_PLACEHOLDER = 'Enter password for CAS file';
const DOWNLOAD_CAS_INSTRUCTIONS_LABEL = 'How to generate CAS manually ?';
const SUCCESS_MSG =
  'CAS PDF is uploaded successfully. Please check your updated portfolio in sometime.';
const FAILURE_MSG = 'CAS upload is unsuccessful. Please re-generate your CAS';

export const UploadCASTile = () => {
  const authState: AuthState = useSelector((state: any) => state.authReducer);
  const onboardingState: OnboardingState = useSelector(
    (state: any) => state.onboardingReducer
  );

  // Component States
  const [src, setSrc] = useState<File>();
  const [fileInputError, setFileInputError] = useState('');
  const [passwordInputError, setPasswordInputError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showDownloadCASInstruction, setShowDownloadCASInstruction] =
    useState(false);
  const [displayResultModal, setDisplayResultModal] = useState(false);
  const [uploadCASResult, setUploadCASResult] = useState<UploadCASResultState>({
    cas_id: undefined,
    resultType: undefined,
    remarks: '',
  });

  const disableSubmitBtn = useMemo(() => {
    return !src || !password || Boolean(fileInputError);
  }, [src, password]);

  // Method
  const onSelectFile = async (file: File | null) => {
    if (!file) return;
    const fileNameParts = file.name.split('.');
    const fileExtension = fileNameParts[fileNameParts.length - 1];
    if (!ACCEPTED_EXT.includes(fileExtension.toLocaleUpperCase())) {
      setSrc(undefined);
      return setFileInputError('Supported format is pdf only.');
    }

    if (fileExtension === 'pdf') {
      try {
        setIsLoading(true);
        const pdfSrc = await fileToBase64(file);

        if (!pdfSrc) {
          setFileInputError('Unable to read the file.');
          return;
        }
        let error = '';

        // Valdate the file size
        const fileSizeMB = file.size / (1024 * 1024);
        if (fileSizeMB > MAX_FILE_SIZE_IN_MB) {
          error = 'File size should not exceed 2MB.';
        }

        setSrc(file);
        setFileInputError(error);
        return setIsLoading(false);
      } catch (error: any) {
        Toast.error("Can't convert pdf file");
        return setIsLoading(false);
      }
    }
    // Specify the acceptable extentions, error handling and all stuff
  };

  const validateInputs = (): boolean => {
    let fileError = '';
    let passwordError = '';

    if (!src) {
      fileError = 'required';
    }
    if (fileInputError) {
      fileError = fileInputError;
    }
    if (!password) {
      passwordError = 'required';
    }

    setFileInputError(fileError);
    setPasswordInputError(passwordError);
    if (fileError || passwordError) {
      return false;
    }

    return true;
  };

  const onSubmit = async () => {
    if (!validateInputs()) return;

    setIsLoading(true);
    if (!authState?.user_id || null) {
      throw new Error('User ID is not available');
    }

    uploadCasApi(authState.user_id, {
      file: src!,
      password: password,
    })
      .then((res) => {
        setUploadCASResult({
          cas_id: res.cas_id,
          resultType: 'success',
          remarks: SUCCESS_MSG,
        });
      })
      .catch((err) => {
        const errResponse = err?.response;
        if (errResponse.status >= 400 && errResponse.status <= 499) {
          setUploadCASResult({
            resultType: 'failure',
            remarks: errResponse.data.message,
          });
          return;
        }
        setUploadCASResult({
          resultType: 'failure',
          remarks: FAILURE_MSG,
        });
      })
      .finally(() => {
        setDisplayResultModal(true);
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading && <MainLoader />}
      <div className='UploadCAS__tile'>
        <div className='UploadCAS__tile_wrapper'>
          <span className='UploadCAS__heading'>{PAGE_HEADING}</span>
          <FileUploader
            onSelect={onSelectFile}
            src={src!}
            accept={ACCEPTED_EXT}
            error={fileInputError}
          />
          {/* Password Input */}
          <div className='UploadCAS__input_password_wrapper'>
            <div className='UploadCAS__input_and_icon'>
              <input
                type={showPassword ? 'text' : 'password'}
                name='cas-pdf-password'
                onChange={(e) => setPassword(e.target.value)}
                className={`UploadCAS__input_password`}
                placeholder={PWD_FIELD_PLACEHOLDER}
                autoComplete='off'
              />
              <div className='UploadCAS__toggle_for_password'>
                {showPassword ? (
                  <IoMdEyeOff
                    size={20}
                    onClick={() => setShowPassword((prev) => !prev)}
                  />
                ) : (
                  <IoMdEye
                    size={20}
                    onClick={() => setShowPassword((prev) => !prev)}
                  />
                )}
              </div>
            </div>
            {passwordInputError && (
              <div className='UploadCAS__error_text'>{passwordInputError}</div>
            )}
          </div>
          <button
            className='UploadCAS__submit_button'
            type='submit'
            onClick={onSubmit}
            disabled={disableSubmitBtn}
          >
            Submit
          </button>
          {/* Instruction to download CAS manually */}
          <span
            className='UploadCAS__instruction_model_text'
            onClick={() => setShowDownloadCASInstruction(true)}
          >
            {DOWNLOAD_CAS_INSTRUCTIONS_LABEL}
          </span>
          <DownloadCASInstructionModal
            show={showDownloadCASInstruction}
            onHide={() => setShowDownloadCASInstruction(false)}
            additionalData={{
              email: authState.email,
              pan: onboardingState.pan,
            }}
          />
          {/* Upload CAS API result modal */}
          <ResultModal
            type={uploadCASResult.resultType}
            show={displayResultModal}
            message={uploadCASResult.remarks}
            onHide={() => setDisplayResultModal(false)}
          />
        </div>
      </div>
    </>
  );
};
