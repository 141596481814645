import React from 'react';
import PropTypes from 'prop-types';

const PageHeader = ({ headerName, className }) => {
  return <div className={'page-header h3 font-weight-medium py-4 c-blue ' + className}>{headerName}</div>;
};
PageHeader.propTypes = {
  headerName: PropTypes.string,
  className: PropTypes.string,
};

export default PageHeader;
