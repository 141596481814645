import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import App from './App';
import AuthProvider from './Auth/AuthProvider';
import './index.scss';
import store from './redux';
import reportWebVitals from './reportWebVitals';
import AppV2 from './v2/App';

import { createBrowserHistory } from 'history';

import * as Sentry from '@sentry/react';
import { getTenantName } from './utility/utility';

// Custom sentry route
const SentryRoute = Sentry.withSentryRouting(Route);

const history = createBrowserHistory();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN_KEY,
  environment: `${process.env.REACT_APP_ENV}-${getTenantName()}-mf`,
  enabled: `${process.env.REACT_APP_ENV}` === 'development' ? false : true,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),

    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const container = document.getElementById('root');
const root = createRoot(container);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>
          <Switch>
            <SentryRoute path={'/'} render={(props) => <AppV2 {...props} />} />
            <SentryRoute path={'/'} render={(props) => <App {...props} />} />
          </Switch>
        </AuthProvider>
      </Router>
    </QueryClientProvider>
  </Provider>
);
reportWebVitals();
