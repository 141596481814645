import React from 'react';
import ReactHighcharts from 'react-highcharts/ReactHighstock.src';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useEffect } from 'react';
import { formatDate } from '../../utility/utility';

const FundGraph = ({ graphData }) => {
  const [filteredGraphData, setfilteredGraphData] = useState([]);
  const [selected, setSelection] = useState('1Y');
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  useEffect(() => {
    filter(graphData, '1Y');
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const configPrice = {
    tooltip: {
      formatter: function () {
        return `${formatDate(this.x)} <br/> <b>Nav: ${parseFloat(this.y).toFixed(2)}<b>`;
      },
      shared: true,
      backgroundColor: '#ffffff',
      borderColor: '#70707017',
      borderRadius: 5,
      crosshairs: false,
    },
    plotOptions: {
      series: {
        showInNavigator: true,
        compare: 'percent',
      },
    },
    credits: {
      enabled: false,
    },
    chart: {
      height: "70%",
      backgroundColor:`${windowDimensions.width>480?'#ffffff':'#f9f9f9'}`,
    },
    yAxis: {
      visible: false,
    },
    xAxis: {
      visible: false,
    },
    rangeSelector: {
      inputEnabled: false,
      buttonTheme: {
        visibility: 'hidden',
      },
      labelStyle: {
        visibility: 'hidden',
      },
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    series: [
      {
        type: 'spline',
        pointInterval: 30 * 24 * 3600 * 1000,
        data: filteredGraphData.map((item) => [Date.parse(item.d), parseFloat(item.v)]),
      },
    ],
  };

  const filter = (product_data, duration) => {
    const endDate = new Date();
    const startDate = new Date();
    if (duration === '1M') {
      startDate.setMonth(startDate.getMonth() - 1);
    } else if (duration === '6M') {
      startDate.setMonth(startDate.getMonth() - 6);
    } else if (duration === '1Y') {
      startDate.setFullYear(startDate.getFullYear() - 1);
    } else if (duration === '3Y') {
      startDate.setFullYear(startDate.getFullYear() - 3);
    } else if (duration === '5Y') {
      startDate.setFullYear(startDate.getFullYear() - 5);
    } else {
      startDate.setFullYear(startDate.getFullYear() - 100);
    }

    const resultProductData = product_data.filter( (a) => {
      const date = new Date(a.d);
      return date >= startDate && date <= endDate;
    });
    setfilteredGraphData(resultProductData);
  };

  const graphButtonClicked = (event) => {
    let text = event.target.innerText;
    filter(graphData, text);
    setSelection(text);
  };

  return (
    <div >
      {filteredGraphData && filteredGraphData.length ? (
        <>
          <ReactHighcharts config={configPrice}></ReactHighcharts>
          <div className="graph-buttons row justify-content-between  border-top py-3">
            <Button variant="link" onClick={graphButtonClicked} active={selected === 'SI'}>
              Since Inception
            </Button>
            <Button variant="link" onClick={graphButtonClicked} active={selected === '5Y'}>
              5Y
            </Button>
            <Button variant="link" onClick={graphButtonClicked} active={selected === '3Y'}>
              3Y
            </Button>
            <Button variant="link" onClick={graphButtonClicked} active={selected === '1Y'}>
              1Y
            </Button>
            <Button variant="link" onClick={graphButtonClicked} active={selected === '6M'}>
              6M
            </Button>
            <Button variant="link" onClick={graphButtonClicked} active={selected === '1M'}>
              1M
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
};
FundGraph.propTypes = {
  graphData: PropTypes.array,
};
export default FundGraph;
