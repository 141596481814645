import React, { useEffect, useState } from 'react';

const OptionsType1 = ({ options, question, updateAnswerJson, errors, step, value }) => {
  const [optionGroup, setOptionGroup] = useState({});
  const [selected, setSelected] = useState({});
  const [json, setJson] = useState(  {})

  useEffect(() => {
    let groups = [];
    options?.sort((a,b) => a.option_id - b.option_id).forEach((item) => {
      if (groups && groups[item.option_category]) {
        groups[item.option_category].push(item);
      } else {
        // groups = [];
        // groups.push(item.option_category)
        groups[item.option_category] = [];
        groups[item.option_category].push(item);
      }
    });
    setOptionGroup(groups);
  }, [...options]);

  useEffect(() => {
    if(value && optionGroup) {
      Object.keys(optionGroup).forEach((key, i) => {
        optionGroup[key].forEach((option, index) => {
          if(option.option_id?.toString() === value?.options[i]?.option_id) {
            selected[key] = index;
            setSelected({...selected})
          }
        })
      })
    }
  }, [value, optionGroup]);

  useEffect(() => {
  }, [optionGroup]);

  const handleSelect = (key, index, option) => {
    selected[key] = index;
    setSelected({...selected})
    const optionValues = json?.options || [];
    if(key === "Earning") {
      optionValues[0] = {
        option_id: option.option_id?.toString()
      }
    } else {
      optionValues[1] = {
        option_id: option.option_id?.toString()
      }
    }
    const answer = {
      question_id: question.question_id?.toString(),
      options: optionValues
    }
    setJson(answer)
    updateAnswerJson(answer)
  };

  return (
    <>
      <div className={'options mt-4 d-flex d-md-block'}>
        {Object.keys(optionGroup).map((key) => (
          <>
            <div className={'option-group d-md-flex align-items-md-center mb-4 w-50 w-md-100'}>
              <div className={'option-group-text'} style={{ width: '100px' }}>
                {key}
              </div>
              {optionGroup[key].map((option, index) => (
                <>
                  <button
                    className={`assessment-btn radio-btn ${selected[key] === index ? 'selected' : ''}`}
                    onClick={() => handleSelect(key, index, option)}>
                    {option.option_value}
                  </button>
                </>
              ))}
            </div>
          </>
        ))}
      </div>
      {errors && errors[step] && <div className={'invalid-feedback d-block'}>{errors[step]}</div>}
    </>
  );
};

export default OptionsType1;
