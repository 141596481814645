import React, { useState, useEffect } from 'react';
import { formatDate, formatPrice, formatNumber, handleFileDownload, regexString } from '../../utility/utility';
import { Button, Dropdown, InputGroup, FormControl, Form, Modal } from 'react-bootstrap';
import { decryptData } from '../../utility/DecryptHelper';
import { getUserTransictions } from '../../api/nav';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { exportTransiction } from '../../api/transactions';
import downloadIcon from '../../assets/download.svg';
import DataMsg from '../../common/data-msg/DataMsg';
import { DropdownList } from '../../common/form';
import serachIcon from '../../assets/search.svg';
import DatePicker from 'react-datepicker';
import closeIcon from '../../assets/close_without-circle.svg';
import Payment from '../order/Payment';
import MobileNavigation from '../../common/footermobile/MobileNavigation';

const TransactionsMobile = () => {
  const userId = useSelector((state) => state.authReducer.user_id);
  const [limit]= useState(5)
  const [transactions, setTransaction] = useState([]);
  const [transTypeObj, setTransactionVal] = useState({ value: 'all', label: 'All' });
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [paymentData, setPaymentData] = useState({ show: false });
  const [apiData, setApiData] = useState({});
  const [apiCall, setApiCall] = useState(false);
  const [show, setShow] = useState(false);
  const getTransiction = (transValue, startDate, endDate, offset) => {
    getUserTransictions(userId, {
      limit: limit,
      offset: offset,
      transaction_type: transValue,
      start_date: startDate,
      end_date: endDate,
      search_by_name: encodeURIComponent(searchValue),
    }).then((res) => {
      if (res && res.data) {
        const data = decryptData(res.data.data);
        if (offset) {
          setTransaction([...transactions, ...data.data]);
        } else {
          setTransaction(data.data);
        }
        setApiCall(false);
        setApiData({ total: data.total_count, offset: data.offset });
      }
    });
  };
  const getDateValues = () => {
    let primeDate = startDate;
    if (primeDate) {
      const date = new Date(primeDate);
      primeDate = date.toLocaleDateString('en-GB');
    }
    let secondDate = endDate;
    if (secondDate) {
      const date = new Date(secondDate);
      secondDate = date.toLocaleDateString('en-GB');
    }
    return { primeDate, secondDate };
  };
  useEffect(() => {
    let dates = getDateValues();
    getTransiction(transTypeObj.value, dates.primeDate, dates.secondDate);
  }, [transTypeObj.value, startDate, endDate]);

  const getStatus = (status) => {
    let curStatus = { status: 'Completed', class: 'c-green' };
    if (status === 'In Progress') {
      curStatus = { status: 'In Progress', class: 'c-yellow' };
    } else if (status === 'Failed') {
      curStatus = { status: 'Failed', class: 'c-red' };
    } else if (status === 'Completed') {
      curStatus = { status: 'Completed', class: 'c-green' };
    } else if (status === 'Unpaid') {
      curStatus = { status: 'Pending', class: 'c-yellow' };
    }
    return curStatus;
  };
  const onTransTypeChange = (event, object) => {
    event.preventDefault();
    setTransactionVal(object);
  };
  const onStartDateChange = (date) => {
    setStartDate(date);
  };
  const onEndDateChange = (dateValue) => {
    setEndDate(dateValue);
  };
  const onSearchChange = (event) => {
    const { value } = event.target;
    setSearchValue(value);
    setApiCall(true);
  };
  const enterHandler = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      onSearchClick();
      // onStartDateChange();
      // onEndDateChange();
    }
  };
  const onSearchClick = () => {
    if (apiCall) {
      let dates = getDateValues();
      getTransiction(transTypeObj.value, dates.primeDate, dates.secondDate);
    }
  };

  const handlePagination = () => {
    let dates = getDateValues();
    getTransiction(transTypeObj.value, dates.primeDate, dates.secondDate, apiData.offset + 5);
  };

  const downloadtransiction = (eventKey) => {
    let dates = getDateValues();
    exportTransiction(userId, `${eventKey}-gen`, {
      transaction_type: transTypeObj.value,
      start_date: dates.primeDate,
      end_date: dates.secondDate,
      search_by_name: searchValue,
      limit: limit,
      offset: apiData.offset,
    }).then((res) => {
      handleFileDownload(eventKey, res.data);
    });
  };
  // Pay now button payment process

  const showBankModal = (data) => {
    setPaymentData({ ...data, show: true });
  };

  const onpaymentCompleted = () => {
    setPaymentData({ show: false });
  };

  const onClose = () => {
    setShow(false);
  };

  return (
    <>
      <div className=" bg-light-grey">
        <div className="transaction-filter-section d-flex pb-2 pt-4 ml-4">
          <div>
            <button className="btn br-light btn-primary py-0 ml-2" onClick={() => setShow(true)}>
              SELECT DATE
            </button>
          </div>
          <Form.Group className="col-sm-2 mb-0 w-40 bg-light-grey">
            <Form.Label className="opacity-50" srOnly>
              select Type
            </Form.Label>
            <DropdownList
              selectedOption={transTypeObj.label}
              id="return-type"
              classes={{ input: 'form-control py-2 bg-light-grey transaction-download' }}
              controlFunc={onTransTypeChange}
              options={[
                { value: 'all', label: 'All' },
                { value: 'P', label: 'Purchase' },
                { value: 'R', label: 'Redeem' },
              ]}
            />
          </Form.Group>
          <div className="">
            <div className="right-align-2">
              <Dropdown className="ml-4" onSelect={downloadtransiction}>
                <Dropdown.Toggle
                  id="dropdown-profile"
                  className={'border-0  p-0 download-icon ' + (transactions && transactions.length ? 'cursor-pointer' : 'cursor-disabled')}
                  disabled={transactions && transactions.length ? false : true}>
                  <img src={downloadIcon} alt="downloadIcon" className="icon w-100 bg-light-grey " />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="csv">
                    <div className="profile-list">
                      <span className="ml-3">.CSV</span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey="pdf">
                    <div className="profile-list">
                      <span className="ml-3">.PDF</span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey="excel">
                    <div className="profile-list">
                      <span className="ml-3">.XLS</span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="ml-4 pl-4 mt-2 w-80">
          <InputGroup className="new-input-group">
            <img src={serachIcon} alt="searchIcon" className="search-input icon" />
            <FormControl
              aria-label="search term"
              className="pl-5 bg-light-grey"
              aria-describedby="basic-addon2"
              onChange={onSearchChange}
              onKeyDown={enterHandler}
            />
          </InputGroup>
        </div>
        <div className="m-2">
          <div>
            {transactions && transactions.length ? (
              transactions.map((data) => {
                const statusObj = getStatus(data.status);
                data = data.tx_details;
                return (
                  <div className="m-3 row colored-box" key={data.id}>
                    <div className="col-12 pt-2">
                      <div className="row mx-2 mt-2">
                        <div className="pl-0 col-12 col-sm-8">
                          <Link to={{ pathname: `/fund/detail/${regexString(data.name)}/${data.fund_id}` }} className=" c-black font-weight-medium">
                            {data.name}
                          </Link>
                          <div className="transaction-status-num small align-items-center py-1 d-flex mb-2">
                            <div className="status-container">
                              <span className="opacity-50 ml-0">Status:</span>{' '}
                              <span className={'ml-1 font-weight-bold ' + statusObj.class}>{statusObj.status}</span>
                            </div>
                            {statusObj.status === 'Pending' ? (
                              <div className="status-btn ml-2">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-primary br-light py-0"
                                  onClick={() => {
                                    showBankModal(data);
                                  }}>
                                  Pay Now
                                </button>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-12 col-sm-4 pl-0">
                          <div className="font-weight-bold">&#8377;{formatPrice(data.amount, 2)}</div>
                          <div className="small pt-1 font-weight-bold">{data.order_type}</div>
                          <div className="small opacity-50">{formatDate(new Date(data.created))}</div>
                        </div>
                      </div>
                      <div className="d-flex my-2" style={{overflow: 'auto'}}>
                        <div className="transaction-folio-num small py-2 px-2">
                          <div className="opacity-50">Folio Number</div>
                          <div className="font-weight-bold">{data.folio_number}</div>
                        </div>
                        <div className="transaction-order-num small py-2 px-3">
                          <div className="opacity-50">Order Number</div>
                          <div className="font-weight-bold">{data.order_number}</div>
                        </div>
                        <div className="transaction-order-num small py-2 px-3">
                          <div className="opacity-50">Units</div>
                          <div className="font-weight-bold">{formatNumber(data.units, 2)}</div>{' '}
                        </div>
                        <div className="transaction-order-num small py-2 px-2">
                          <div className="opacity-50">Nav</div>
                          <div className=" font-weight-bold">{formatNumber(data.nav, 2)}</div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-sm-1  px-0 text-center pt-2">{data.order_type}</div> */}
                  </div>
                );
              })
            ) : (
              <DataMsg message="No Transactaion Available" button="Invest" url="/invest" />
            )}
            {apiData.total > apiData.offset + limit ? (
              <div className="pagination-container p-4 text-center">
                <Button type="button" variant="primary" id="loadMore" className="w-100" onClick={handlePagination}>
                  Load More
                </Button>
              </div>
            ) : null}
          </div>
        </div>
        <MobileNavigation />
      </div>
      {paymentData.show ? (
        <Payment
          onpaymentCompleted={onpaymentCompleted}
          show={paymentData.show}
          userId={userId}
          totalAmount={paymentData.amount}
          orders={[paymentData.id]}
        />
      ) : null}
      <Modal show={show} size="md" aria-labelledby="date-select-modal" onHide={onClose} centered backdrop="static" keyboard={false}>
        <Modal.Header>
          <span className="font-weight-medium h5 text-center">SELECT DATE</span>
        </Modal.Header>
        <Modal.Body>
          <div className="transaction-filter-section row align-items-center mb-4 mt-2">
            <Form.Group className="col-12 mb-0 d-flex">
              <span className="opacity-75 mr-2 mt-2 w-25">From:</span>
              <DatePicker
                id="transictaionDate"
                selected={startDate}
                onChange={onStartDateChange}
                dateFormat="dd/MM/yyyy"
                placeholderText="dd/mm/yyyy"
                autoComplete="off"
                className={`form-control font-weight-medium ${startDate ? 'selected' : ''}`}
              />
              {startDate ? (
                <img
                  src={closeIcon}
                  alt="ClearIcon"
                  className="date-clear-icon cursor-pointer"
                  onClick={() => {
                    setStartDate('');
                  }}
                />
              ) : null}
            </Form.Group>
            <Form.Group className="col-12 mb-0 d-flex mt-3">
              <span className="opacity-75 mr-2 mt-2 w-25">To:</span>
              <DatePicker
                id="transictaionDate"
                selected={endDate}
                onChange={onEndDateChange}
                dateFormat="dd/MM/yyyy"
                placeholderText="dd/mm/yyyy"
                autoComplete="off"
                className={`form-control font-weight-medium ${endDate ? 'selected' : ''}`}
              />
              {endDate ? (
                <img
                  src={closeIcon}
                  alt="ClearIcon"
                  className="date-clear-icon cursor-pointer"
                  onClick={() => {
                    setEndDate('');
                  }}
                />
              ) : null}
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose}>Okay</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default TransactionsMobile;
