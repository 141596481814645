import React, { useEffect, useState } from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import Avatar from 'react-avatar';
import { useHistory } from 'react-router-dom';
import { getUserDetails } from '../../../../../api/profile';
import { decryptData } from '../../../../../utility/DecryptHelper';
import { getTopFunds } from '../../../../../api/fund';
import FundImage from '../../../../assets/funds/fund-logo.png';
import PillButton from '../../../../shared/component/PillButton';
import StarRatings from 'react-star-ratings';
import { regexString } from '../../../../../utility/utility';

const AssessmentResult = ({ report, retake }) => {
  const history = useHistory();
  const [userProfile, setUserProfile] = useState();
  const [funds, setFunds] = useState([]);

  useEffect(() => {
    getUserProfile();
    getTopPicksFund();
    // modifyGraphLabels();
  }, []);

  useEffect(() => {
    if (report) {
      modifyGraphLabels();
    }
  }, [{ ...report }]);

  const modifyGraphLabels = () => {
    const interval = setInterval(() => {
      console.log('INTERVALSSSSSSS');
      let G = document.querySelector('g.label');
      if (G && G.childNodes) {
        G.childNodes[1].innerHTML = '<tspan dy="-0.5cm">Moderately</tspan><tspan dx="-1.9cm" dy="0.5cm">Conservative</tspan>';
        G.childNodes[3].innerHTML = '<tspan dy="-0.5cm">Moderately</tspan><tspan dx="-1.9cm" dy="0.5cm">Aggressive</tspan>';
        clearInterval(interval);
      }
    }, 50);
  };

  const getUserProfile = () => {
    getUserDetails().then((response) => {
      if (response && response.data) {
        const profileObj = decryptData(response.data.data);
        setUserProfile(profileObj.data);
      }
    });
  };

  const getTopPicksFund = () => {
    getTopFunds(3, 0).then((response) => {
      setFunds(response.data.results);
    });
  };

  const onInvestClick = (id, name) => {
    console.log(`/fund/detail/${name}/${id}`);
    let url = `/fund/detail/${name}/${id}`;
    history.push({ pathname: url });
  };

  return (
    <>
      <div className={'section_title'}>Risk Assessment</div>
      <div className={'assessment-result mt-3 pb-4'}>
        <div className={'row'}>
          <div className={'col-12 col-md-6'}>
            <div className={'result-card'}>
              <div className={'d-flex w-100 align-items-center justify-content-center'} style={{ height: '280px' }}>
                <Avatar src={userProfile?.user_profile_image} name={userProfile?.pan_name} round="50px" color={'#08AEEA'} />
                <div className={'ml-4'}>
                  <div className={'user-name-title'}>{userProfile?.pan_name}</div>
                  <div className={'result-text-sub'}>Your risk assessment is complete !</div>
                  <div className={'result-date'}>as on {report?.assessment_date}</div>
                </div>
              </div>
            </div>
          </div>
          <div className={'col-12 col-md-6 mt-4 mt-md-0'}>
            <div className={'result-card'}>
              <div className={'speedometer-chart d-flex w-100 justify-content-center h-100'}>
                <ReactSpeedometer
                  segments={5}
                  width={380}
                  forceRender={true}
                  needleColor={'#1d3261'}
                  needleHeightRatio={0.7}
                  segmentColors={['#7cbf00', '#b4d337', '#d59e05', '#e56c01', '#c00d11']}
                  paddingHorizontal={5}
                  value={report?.risk_score ? parseFloat(report?.risk_score) : 0}
                  paddingVertical={5}
                  minValue={0}
                  maxValue={100}
                  customSegmentLabels={[
                    { text: 'Conservative', position: 'OUTSIDE', fontSize: '12px' },
                    { text: 'Moderately Conservative', position: 'OUTSIDE', fontSize: '12px' },
                    { text: 'Balanced', position: 'OUTSIDE', fontSize: '12px' },
                    { text: 'Moderately Aggressive', position: 'OUTSIDE', fontSize: '12px' },
                    { text: 'Aggressive', position: 'OUTSIDE', fontSize: '12px' },
                  ]}
                />
                <span className={'start-value'}>LOW</span>
                <span className={'end-value'}>HIGH</span>
              </div>
            </div>
          </div>
        </div>
        <div className={'section_title mt-4 pt-2'}>Top Funds to invest in</div>
        <div className={'row'}>
          <div className={'col-12'}>
            <div className={'top_picks_card d-none d-md-block'}>
              <div style={{ maxHeight: '450px', overflow: 'auto', paddingRight: '10px' }}>
                {funds.map((item) => {
                  return (
                    <>
                      <div className={'top-picks-row'}>
                        <div className={'top-picks-fund-detail'}>
                          <div
                            className={'d-flex align-items-center'}
                            onClick={() => {
                              onInvestClick(item.id, regexString(item.name));
                            }}>
                            <div className={'top-picks-fund-logo'}>
                              <img src={item.logo || FundImage} alt={'amc_image'} />
                            </div>
                            <div className={'top-picks-fund-title'}>{item.name}</div>
                          </div>
                        </div>
                        {item.ratings && (
                          <div className={'top-picks-fund-recom'}>
                            <span className={'label-text-light'}>Prime Recommendation</span>
                            <PillButton
                              text={formatRecom(item.ratings[0]?.prime?.recommendation)}
                              color={recomColor(item.ratings[0]?.prime?.recommendation)}
                            />
                          </div>
                        )}
                        {item.ratings && (
                          <div className={'top-picks-fund-rating'}>
                            <span className={'label-text-light'}>Prime Rating</span>
                            <StarRatings
                              rating={formatRating(item.ratings[0]?.prime?.rating)}
                              numberOfStars={5}
                              starRatedColor={'#ffb24c'}
                              starDimension={'12px'}
                              starSpacing={'1px'}
                            />
                          </div>
                        )}
                        <div className={'top-picks-fund-rating'}>
                          <span className={'label-text-light'}>3 Y Returns</span>
                          <span className={'label-return-per'}>{item.return_3_yr.toFixed(2)}%</span>
                        </div>
                        <div className={'invest-button-div'}>
                          <button
                            className={'invest-now-btn'}
                            onClick={() => {
                              onInvestClick(item.id, regexString(item.name));
                            }}>
                            Invest Now
                          </button>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div className={'top_picks_card d-block d-md-none'}>
              {funds.map((item) => (
                <div className={'top-picks-row d-block'}>
                  <div className={'top-picks-fund-detail'}>
                    <div
                      className={'d-flex align-items-center'}
                      onClick={() => {
                        onInvestClick(item.id, regexString(item.name));
                      }}>
                      <div className={'top-picks-fund-logo'}>
                        <img src={FundImage} alt={''} />
                      </div>
                      <div className={'top-picks-fund-title'}>{item.name}</div>
                    </div>
                    {item.ratings && (
                      <div className={'top-picks-fund-rating'}>
                        <span className={'label-text-light'}>Prime Rating</span>
                        <StarRatings rating={4.2} numberOfStars={5} starRatedColor={'#ffb24c'} starDimension={'10px'} starSpacing={'1px'} />
                      </div>
                    )}
                  </div>
                  <div className={'top-picks-mobile-vals'}>
                    <div className={'top-picks-values'}>
                      <span>3 Y Returns </span>
                      <div className={'value'}>{item.return_3_yr.toFixed(2)}</div>
                    </div>
                    {item.ratings && (
                      <div className={'top-picks-values'}>
                        <span>Prime Recommendation </span>
                        <div className={'value'}>
                          <PillButton
                            text={formatRecom(item.ratings[0]?.prime?.recommendation)}
                            color={recomColor(item.ratings[0]?.prime?.recommendation)}
                          />
                        </div>
                      </div>
                    )}
                    <div className={'invest-btn-div mt-2'}>
                      <button
                        className={'invest-now-btn'}
                        onClick={() => {
                          onInvestClick(item.id, regexString(item.name));
                        }}>
                        Invest Now
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={'section_title mt-4 pt-2'}>Observation</div>
        <div className={'row mt-3'}>
          <div className={'col-12'}>
            <div className={'result-card-1 py-4 px-4'}>
              {report?.observations?.map((item) => {
                return <div className={'observation mb-4'}>{item.observation}</div>;
              })}
            </div>
          </div>
        </div>
        <div className={'w-100 text-center mt-5'}>
          <button className={'retake-assessment-btn'} onClick={retake}>
            Retake Risk Assessment
          </button>
        </div>
      </div>
    </>
  );
};

function formatRecom(value) {
  if (value === 'No opinion') {
    return 'NA';
  }
  return value;
}
function formatRating(value) {
  if (value === 'Unrated') {
    return 0;
  }
  return parseFloat(value);
}
function recomColor(value) {
  if (value.toUpperCase() === 'BUY') {
    return 'success';
  } else {
    return 'danger';
  }
}
export default AssessmentResult;
