import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ordinal_suffix_of } from '../../utility/utility';

const SipDateSelectModal = ({show, onClose, onDateSelect, selectedDate, onSubmit, frequencyDates}) => {

  const getDates = () => {
    let a = [];
    for (let i = 1; i < 31; i++) {
      a.push(i)
    }
    return a;
  };

  const isDateAllowed = (date) => {
    if(!frequencyDates || frequencyDates.length === 0) {
      return false;
    } else {
      return frequencyDates.indexOf(date) === -1;
    }
  }

  return <Modal size="sm" className="p-4" show={show} onHide={onClose} centered backdrop="static" keyboard={false}>
    <Modal.Header className="pb-0 text-center" closeButton>
      <div className={"text-center"}>
        SIP Instalment Date
      </div>
    </Modal.Header>
    <Modal.Body className="pt-0 text-center">
      <div className={"mt-4"}>
        {ordinal_suffix_of(selectedDate)} of every Month
      </div>
      <div className={"d-flex justify-content-center mt-4"}>
        <div>
          {getDates().map((item) =>
            <Button className={"sip-btn"} variant={selectedDate === item ? "primary" : "light"} disabled={isDateAllowed(item)} onClick={() => onDateSelect(item)}>{item}</Button>
          )}
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={onSubmit} className={"w-100"}>
        Confirm
      </Button>
    </Modal.Footer>
  </Modal>
};



export default SipDateSelectModal;
