/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getProtfolio } from '../../api/portfolio';
import DataMsg from '../../common/data-msg/DataMsg';
import PageHeader from '../../common/page-header/PageHeader';
import PortfolioFundList from '../../component/portfolio-funds-list/PortfolioFundList';
import AddFundModal from '../../modal/AddFundModal';
import ReedemFundModal from '../../modal/ReedemFundModal';
import { decryptData } from '../../utility/DecryptHelper';
import PlanDirectModal from '../../modal/PlanDirectModal';
import MobileNavigation from '../../common/footermobile/MobileNavigation';
function hasNumber(myString) {
  return /\d/.test(myString);
}
const PortfolioPage = ({ history }) => {
  const userId = useSelector((state) => state.authReducer.user_id);
  const [protfolioData, setData] = useState([]);
  const [addModalData, addModalDataFunc] = useState({});
  const [reedemModalData, reedemModalDataFunc] = useState({});
  const [returnType, setReturnType] = useState('absolute_return');
  const [directPlan, setDirectPlan] = useState(false);
  const [add, setAdd] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    getPortfolioData();
  }, []);
  const getPortfolioData = () => {
    getProtfolio(userId).then((res) => {
      if (res && res.data) {
        const totalPortfolio = [],
          portfolioData = decryptData(res.data.data);
        if (portfolioData && portfolioData.data) {
          Object.values(portfolioData.data).map((dataObj) => totalPortfolio.push(...dataObj));
          setData(totalPortfolio);
        }
      }
    });
  };
  const getFrequencyDates = (dates) => {
    if (dates?.length > 0) {
      let allowedDates = [];
      if (hasNumber(dates)) {
        dates.split('|').forEach((item) => {
          allowedDates.push(parseInt(item));
        });
      } else {
        for (let i = 1; i <= 28; i++) {
          allowedDates.push(i);
        }
      }
      return allowedDates;
    } else {
      return [];
    }
  };
  const addFundFunc = (event, fundId, folioList, minAmountNew, minAmountCurrent, isRegular, fundData) => {
    event.preventDefault();
    if (isRegular) {
      let canInvestLumpsum = false
      let canInvestSip = false

      if (fundData?.bse_data && fundData?.bse_data?.lumpsum_allowed === 'Y') {
        canInvestLumpsum = true
      }
      if (fundData?.bse_data && fundData?.bse_data?.sip_allowed === 'Y') {
        canInvestSip = true
      }
      addModalDataFunc({
        show: true,
        fundId,
        folioList: folioList.map((item) => item.folio_no),
        minAmountNew,
        minAmountCurrent,
        frequencyDates: getFrequencyDates(fundData?.iaip_aip[0]?.frequency_date),
        canInvestLumpsum, canInvestSip
      });
    } else {
      setDirectPlan(true);
      setAdd(true);
    }
  };
  const reedemFundFunc = (event, obj) => {
    event.preventDefault();
    if (obj.isRegular) {
      reedemModalDataFunc({ show: true, options: { ...obj, userId } });
    } else {
      setDirectPlan(true);
      setAdd(false);
    }
  };
  const onClosePopUp = () => {
    setDirectPlan(false);
  };
  const onAddFundModalClose = (flag) => {
    addModalDataFunc({ show: false });
    if (flag) {
      history.push('/order/summary');
    }
  };
  const onRedeemSubmit = (dataObj) => {
    reedemModalDataFunc({ show: false, options: {} });
    if (dataObj) {
      history.push({ pathname: '/redemptionconfirmation', state: { dataObj } });
    }
    getPortfolioData();
  };
  const updateReturnType = (event, value) => {
    event.preventDefault();
    setReturnType(value);
  };
  return (
    <>
      {windowDimensions.width > 480 ? (
        <div className="container protfolio-container">
          <PageHeader headerName="Portfolio" />
          <div className="row h6 protfolio-header-row font-weight-medium">
            <div className="col-sm-6">Fund</div>
            <div className="col-sm-2 text-right">Total Invested</div>
            <div className="col-sm-2  text-right">Current Value</div>
            <Dropdown className="col-sm-2 text-right">
              <Dropdown.Toggle as="span" id="dropdown-return" className="cursor-pointer">
                Returns (%)
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  href="#"
                  onClick={(e) => {
                    updateReturnType(e, 'xirr');
                  }}>
                  XIRR
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  onClick={(e) => {
                    updateReturnType(e, 'absolute_return');
                  }}>
                  Absolute
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="protfolio-list-container">
            {protfolioData && protfolioData.length ? (
              protfolioData.map((data) => (
                <PortfolioFundList
                  key={data.fund_id}
                  fundId={data.fund_id}
                  isRegular={data.is_regular_plan}
                  returnType={returnType}
                  minAmountNew={data.pi_minimum_initial}
                  minAmountCurrent={data.lumpsum_additional_min_amount}
                  fundName={data.fund_name}
                  folioList={data.folio_list}
                  exitLoad={data.defer_loads}
                  fundData={data}
                  reedemFundFunc={(event, fundId, folioList, isRegular, exitLoad) => {
                    reedemFundFunc(event, {
                      folioList,
                      isRegular,
                      exitLoad,
                      fundId,
                      units: data.units,
                      total_investment: data.total_investment,
                      name: data.fund_name,
                    });
                  }}
                  addFundFunc={addFundFunc}
                />
              ))
            ) : (
              <DataMsg message="No Funds Available" />
            )}
          </div>
          {addModalData.show ? (
            <AddFundModal
              show={addModalData.show}
              minAmountNew={addModalData.minAmountNew}
              minAmountCurrent={addModalData.minAmountCurrent}
              folioList={addModalData.folioList}
              onSubmit={onAddFundModalClose}
              fundId={addModalData.fundId}
              status={addModalData.isRegular}
              frequencyDates={addModalData.frequencyDates}
              canInvestLumpsum={addModalData.canInvestLumpsum}
              canInvestSip={addModalData.canInvestSip}
            />
          ) : null}
          {reedemModalData.show ? <ReedemFundModal show={reedemModalData.show} onSubmit={onRedeemSubmit} options={reedemModalData.options} /> : null}
          {directPlan ? <PlanDirectModal show={directPlan} add={add} onClose={onClosePopUp} /> : null}
        </div>
      ) : (
        <div>
          <div>
            {protfolioData && protfolioData.length ? (
              protfolioData.map((data) => (
                <PortfolioFundList
                  key={data.fund_id}
                  fundId={data.fund_id}
                  isRegular={data.is_regular_plan}
                  returnType={returnType}
                  minAmountNew={data.pi_minimum_initial}
                  minAmountCurrent={data.lumpsum_additional_min_amount}
                  fundName={data.fund_name}
                  folioList={data.folio_list}
                  exitLoad={data.defer_loads}
                  fundData={data}
                  reedemFundFunc={(event, fundId, folioList, isRegular, exitLoad) => {
                    reedemFundFunc(event, {
                      folioList,
                      isRegular,
                      exitLoad,
                      fundId,
                      units: data.units,
                      total_investment: data.total_investment,
                      name: data.fund_name,
                    });
                  }}
                  addFundFunc={addFundFunc}
                />
              ))
            ) : (
              <DataMsg message="No Funds Available" />
            )}
          </div>
          {addModalData.show ? (
            <AddFundModal
              show={addModalData.show}
              minAmountNew={addModalData.minAmountNew}
              minAmountCurrent={addModalData.minAmountCurrent}
              folioList={addModalData.folioList}
              onSubmit={onAddFundModalClose}
              fundId={addModalData.fundId}
              status={addModalData.isRegular}
              frequencyDates={addModalData.frequencyDates}
              canInvestLumpsum={addModalData.canInvestLumpsum}
              canInvestSip={addModalData.canInvestSip}
            />
          ) : null}
          {reedemModalData.show ? <ReedemFundModal show={reedemModalData.show} onSubmit={onRedeemSubmit} options={reedemModalData.options} /> : null}
          {directPlan ? <PlanDirectModal show={directPlan} add={add} onClose={onClosePopUp} /> : null}
          <MobileNavigation />
        </div>
      )}
    </>
  );
};
export default PortfolioPage;
