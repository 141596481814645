import { getTenantName } from '../utility/utility';

const tenantFromHostname = getTenantName();
const tenantName =
  tenantFromHostname === 'localhost' ? 'elevo' : tenantFromHostname;

const config = {
  // Document title to be shown on title bar
  APP_TITLE: `${tenantName} - Mutual Funds`,

  // Path to the LOGO of the tenant and alt text if logo not found
  LOGO: process.env.PUBLIC_URL + `/${tenantName}/logo.svg`,
  LOGO_ALT_TEXT: `${tenantName} Logo`,

  // Header Links titles and redirect links
  APP_TEXT: `${tenantName} - Mutual Funds App`,

  WEBSITE_TEXT: `${tenantName} Website`,
  POWERED_BY_BADGE: tenantName === 'tarrakki' ? false : true,
};

export default config;
