import elevo_apis, { elevoApiClient } from './elevo_apis';
import Toast from '../common/toast/Toast';


function sendOTP(data) {
  const makeApiCall = elevoApiClient(sendOtpErrorHandler);
  return makeApiCall.post('/otp', data)
    .then((res) => res)
    .catch((error) => {
      console.error(error);
    });
}
function sendOtpErrorHandler(error) {
  if (error?.response?.status === 400 && error.response?.data.message !== 'OTP request is already under process, please try after some time') {
    Toast.error(error.response?.data.message);
  }
}
function verifyOTP(data,otpId) {
    return elevo_apis.post(`/otp/${otpId}/verify`, data);
}
const resendOTP = (otpId,voice) => {
  return elevo_apis.post(`/otp/${otpId}/resend?voice=${voice}`);
};

export { sendOTP,verifyOTP,resendOTP };