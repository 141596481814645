import React, { useEffect, useState } from 'react';
// Imports

import './BankAutoPayLimit.css';

import Infoicon from '../../assets/icons.png';
import Tooltip from '../tool-tip/Tooltip';
import { useHistory, useParams } from 'react-router-dom';
import { updateAutoPayLimitAction } from '../../redux/reducers/mandate-reducer';
import { useDispatch } from 'react-redux';
import { decryptData } from '../../utility/DecryptHelper';
import { getUserDetails } from '../../api/profile';

const BankAutoPayLimit = () => {
  // Initializing state and libraires
  const [debitAmount, setDebitAmount] = useState('₹ 1,00,000');
  const [error, setError] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isIndividual, setIsIndividual] = useState(false);
  const { bankId, userId } = useParams();
  const individual = '01';
  // change the value of text
  const textChangeHandler = (value) => {
    if (debitAmount.includes('₹ ')) {
      setDebitAmount(value);
    } else {
      if (debitAmount.includes('₹')) {
        setDebitAmount(value.replace('₹', '₹ '));
      } else {
        setDebitAmount(`₹ ${value}`);
      }
    }
    if (debitAmount !== '' && Number(debitAmount.slice(2).replaceAll(',', '')) > 0) {
      setError(false);
    }
  };

  useEffect(() => {
    getUserDetails(true).then((res) => {
      if (res && res.data) {
        const profileObj = decryptData(res.data.data);
        if (profileObj.data.tax_status && profileObj.data.tax_status.id === individual) setIsIndividual(true);
      }
    });
  }, []);

  // click event of box
  const boxClickHandler = (value) => {
    setDebitAmount(value);
  };

  const continuBtn = () => {
    if (debitAmount !== '' && Number(debitAmount.slice(2).replaceAll(',', '')) > 0) {
      dispatch(updateAutoPayLimitAction(debitAmount.slice(2).replaceAll(',', '')));
      history.push({ pathname: `/bank-mandate/${bankId}/${userId}`, state: { status: 'mandate-not-completed', individual: isIndividual } });
    } else {
      setError(true);
    }
  };

  return (
    <>
      <div className={'init'}>
        <p className={'bank_mandate'}> Bank Mandate</p>
        <div className={'details_card'}>
          <div className="paragragh-message-wrapper">
            <p className={'maximum_debit_limit-text '}> Maximum auto-debit limit </p>
            <div className="message-text">
              <Tooltip text={'Your mandate limit is maximum amount of money you can invest on any given day of month'} src={Infoicon} />
            </div>
          </div>
          <input
            inputMode={'numeric'}
            value={debitAmount}
            onChange={(e) => {
              textChangeHandler(e.target.value);
            }}
            className={'text-input'}
          />
          {error ? (
            <span>
              <p className="error-message">Please enter number greater than 0</p>
            </span>
          ) : (
            ''
          )}
          <div className="divider-autopay"></div>
          <div className="payment-wrapper">
            <div className="btn-box-one">
              <div
                className={debitAmount !== '₹ 1,00,000' ? 'btn-box-price' : 'btn-box-price-selected'}
                onClick={() => {
                  boxClickHandler('₹ 1,00,000');
                }}>
                ₹ 1,00,000
              </div>
              <div
                className={debitAmount !== '₹ 2,00,000' ? 'btn-box-price' : 'btn-box-price-selected'}
                onClick={() => {
                  boxClickHandler('₹ 2,00,000');
                }}>
                ₹ 2,00,000
              </div>
              <div
                className={debitAmount !== '₹ 3,00,000' ? 'btn-box-price' : 'btn-box-price-selected'}
                onClick={() => {
                  boxClickHandler('₹ 3,00,000');
                }}>
                ₹ 3,00,000
              </div>
            </div>
            <div className="btn-box-two">
              <div
                className={debitAmount !== '₹ 5,00,000' ? 'btn-box-price' : 'btn-box-price-selected'}
                onClick={() => {
                  boxClickHandler('₹ 5,00,000');
                }}>
                ₹ 5,00,000
              </div>
              <div
                className={debitAmount !== '₹ 10,00,000' ? 'btn-box-price' : 'btn-box-price-selected'}
                onClick={() => {
                  boxClickHandler('₹ 10,00,000');
                }}>
                ₹ 10,00,000
              </div>
            </div>
          </div>
        </div>
        <div
          className="continue-btn-autolimit"
          onClick={() => {
            continuBtn();
          }}>
          Continue
        </div>
      </div>
    </>
  );
};

export default BankAutoPayLimit;
