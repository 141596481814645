import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { decryptData } from '../utility/DecryptHelper';
import { getUserBanks } from '../api/bank';
import greenCheckIcon from '../assets/green-check-white.svg';
const SelectBankModal = ({ show, onSubmit, closeModal, accountInfo = {} }) => {
  const [userBanks, setUserBanks] = useState([]);
  const userId = useSelector((state) => state.authReducer.user_id);
  const [accountObj, setaccountObj] = useState(accountInfo);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    getUserBanks(userId).then((res) => {
      if (res && res.data) {
        const data = decryptData(res.data.data);
        setUserBanks(data.data.bank_details);
        if (accountInfo && !accountInfo.account_number) {
          const defaultBank = data.data.bank_details.find((item) => item.is_default);
          setaccountObj(defaultBank || {});
        }
      }
    });
  }, []);
  const getShortAccountNo = (accountNo) => {
    return accountNo.substring(accountNo.length - 4, accountNo.length);
  };

  const getAccountStatus = (status) => {
    let curStatus = { status: 'Verified', class: 'c-green' };
    if (status === 'REJECTED') {
      curStatus = { status: 'Rejected', class: 'c-red' };
    } else if (status === 'UPLOADED') {
      curStatus = { status: 'Pending', class: 'c-yellow' };
    } else if (status === 'UPLOADEDTOBSE') {
      curStatus = { status: 'Verified', class: 'c-green' };
    } else if (status === 'VERIFIEDBYADMIN') {
      curStatus = { status: 'Verified', class: 'c-green' };
    }
    return curStatus;
  };
  return (
    <Modal
      show={show}
      size="sm"
      onHide={() => closeModal('bank')}
      className={windowDimensions.width > 480 ? null : 'p-4'}
      aria-labelledby="select-bank-modal"
      centered
      backdrop="static"
      keyboard={false}>
      <Modal.Header closeButton>
        <h6 id="select-payment-mode-modal" className="mt-3 ml-2 font-weight-medium">
          Select bank account
        </h6>
      </Modal.Header>
      <Modal.Body className="pb-4 pl-4 pr-4 pt-1">
        {userBanks && userBanks.length
          ? userBanks.map((userBank) => {
              const statusObj = getAccountStatus(userBank.status);
              return (
                <div className="accountList form-check mb-3 " key={'account-' + userBank.id}>
                  {statusObj.status === 'Verified' ? (
                    <>
                      <input
                        className="form-check-input mr-4 custom-radio"
                        value={userBank.account_number}
                        checked={userBank.account_number === accountObj.account_number}
                        type="radio"
                        name="bank-account-no"
                        onChange={() => {
                          setaccountObj(userBank);
                        }}
                        id={'account-' + userBank.id}
                      />
                      <label
                        className={'form-check-label text-capitalize ' + (userBank.account_number === accountObj.account_number ? 'c-blue' : '')}
                        htmlFor={'account-' + userBank.id}>
                        {userBank.branch__bank_id__bank_name.toLowerCase() + ' •••• ' + getShortAccountNo(userBank.account_number)}
                      </label>
                    </>
                  ) : null}
                  {userBank.is_default ? (
                    <p className="mt-2 small d-flex align-items-center">
                      <img src={greenCheckIcon} className="small-icon" alt="account default" /> <span className="ml-1">Default Bank Account</span>
                    </p>
                  ) : null}
                  {userBank.payment_mode === 'NODAL' && userBank.account_number === accountObj.account_number && (
                    <div className="payment-mode-msg c-red small">
                      <p>
                        The selected bank does not offer instant clearing of funds when using Netbanking which might result in a fund confirmation
                        delay up to 2 working days resulting in a delayed unit allotment.
                      </p>
                      <p>Suggestion: Use NEFT/RTGS for this bank account.</p>
                    </div>
                  )}
                </div>
              );
            })
          : null}
      </Modal.Body>
      <div className="btn-container text-center mb-4 px-3">
        <Button
          onClick={() => {
            if (accountObj.account_number) {
              onSubmit(accountObj);
            } else {
              return false;
            }
          }}
          className={windowDimensions.width > 480 ? 'btn btn-primary w-100' : 'btn btn-primary w-50 py-2'}>
          Proceed
        </Button>
      </div>
    </Modal>
  );
};
SelectBankModal.propTypes = {
  show: PropTypes.bool,
  onSubmit: PropTypes.func,
  accountInfo: PropTypes.object,
};
export default SelectBankModal;
