import { useQuery } from '@tanstack/react-query';
import { PropsWithChildren, createContext, useEffect, useState } from 'react';
import { useFiltersForTransactions } from '../hooks/useFiltersForTransactions';
import { useAppSelector } from '../redux/hooks';
import { formatQueryParamsDateString } from '../utility/utility';
import getUserTransictions from '../v2/api/transactions';
import TransactionRecord, { TransactionsOut } from '../v2/types';

type Tab = 'Mutual Funds' | 'Deposits';
type DateValues = Date | null | undefined;

interface TransactionContextProps {
  fromDateValue: DateValues;
  toDateValue: DateValues;
  searchInputValue: string;
  datesErrorMsg: string | null;
  isDownloadEnable: boolean;
  activeTab: Tab;
  mutualFundTransactions: TransactionsOut;
  fdTransactions: TransactionsOut;
  isLoadingMFTransactions: boolean;
  isLoadingFDTransactions: boolean;
  recordsMF: TransactionRecord[];
  handleFromDateFilter: (date: Date | null) => void;
  handleToDateFilter: (date: Date | null) => void;
  handleTabChange: (currTab: Tab) => void;
  handleSearchInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handlePageChange: (pageUri: string | null) => void;
  handleItemsPerPage: (value: number) => void;
}

export const TransactionContext = createContext<TransactionContextProps>(
  {} as TransactionContextProps
);

export const TransactionProvider = ({ children }: PropsWithChildren<{}>) => {
  const {
    datesErrorMsg,
    mfSearchvalue,
    fdSearchValue,
    debounced_mf_search_value,
    debounced_fd_search_value,
    fdFromDate,
    mfFromDate,
    mfToDate,
    fdToDate,
    fromDateHandler,
    toDateHandler,
    searchInputHandler,
  } = useFiltersForTransactions();
  const { user_id, isMutualFundInScope, isFixedDepositsInScope } =
    useAppSelector((state: any) => state.authReducer);
  const [activeTab, setActiveTab] = useState<Tab>(
    isMutualFundInScope ? 'Mutual Funds' : 'Deposits'
  );

  const fromDateValue = activeTab === 'Mutual Funds' ? mfFromDate : fdFromDate;
  const toDateValue = activeTab === 'Mutual Funds' ? mfToDate : fdToDate;
  const searchInputValue =
    activeTab === 'Mutual Funds' ? mfSearchvalue : fdSearchValue;

  const [recordsMF, setRecordsMF] = useState<TransactionRecord[]>([]);
  const [isUpdateExistingRecordsMf, setIsUpdateExistingRecordsMf] =
    useState<boolean>(false);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [paginationUri, setPaginationUri] = useState<string | null>(null);
  const [isDownloadEnable, setIsDownloadEnalble] = useState<boolean>(false);

  // OPTIMIZED APPROACH with useQuery-------------------
  const { data: mutualFundTransactions, isFetching: isLoadingMFTransactions } =
    useQuery<any>({
      queryKey: [
        'MutualFundsTransactions',
        user_id,
        debounced_mf_search_value,
        mfFromDate,
        mfToDate,
        itemsPerPage,
        paginationUri,
      ],
      queryFn: async () => {
        const res = await getUserTransictions(
          'Mutual Funds',
          {
            search_text: debounced_mf_search_value,
            from_date: formatQueryParamsDateString(mfFromDate, 'yyyy-mm-dd'),
            to_date: formatQueryParamsDateString(mfToDate, 'yyyy-mm-dd'),
            limit: itemsPerPage,
          },
          paginationUri
        );
        return res;
      },
      keepPreviousData: true,
      enabled: isMutualFundInScope,
    });

  const { data: fdTransactions, isFetching: isLoadingFDTransactions } =
    useQuery<any>({
      queryKey: [
        'FixedDepositsTransactions',
        user_id,
        debounced_fd_search_value,
        fdFromDate,
        fdToDate,
      ],
      queryFn: async () => {
        const res = await getUserTransictions(
          'Deposits',
          {
            search_text: debounced_fd_search_value,
            from_date: formatQueryParamsDateString(fdFromDate, 'yyyy-mm-dd'),
            to_date: formatQueryParamsDateString(fdToDate, 'yyyy-mm-dd'),
            limit: undefined,
          },
          undefined
        );

        return res;
      },
      keepPreviousData: true,
      enabled: isFixedDepositsInScope,
    });
  // Set Records to provide pagination in Mobile Screens
  useEffect(() => {
    if (isUpdateExistingRecordsMf) {
      setRecordsMF((prev) => [...prev, ...mutualFundTransactions.results]);
    } else {
      setRecordsMF(mutualFundTransactions?.results);
    }
  }, [mutualFundTransactions]);

  // Is Download enabled or not.
  useEffect(() => {
    if (activeTab === 'Mutual Funds')
      setIsDownloadEnalble(
        mutualFundTransactions?.results?.length > 0 ? true : false
      );
    if (activeTab === 'Deposits')
      setIsDownloadEnalble(fdTransactions?.results?.length > 0 ? true : false);
  }, [mutualFundTransactions, fdTransactions]);

  const handleSearchInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setIsUpdateExistingRecordsMf(false);
    setPaginationUri(null);

    searchInputHandler(event.target.value, activeTab);
  };

  const handlePageChange = (pageUri: string | null) => {
    setPaginationUri(pageUri);
    setIsUpdateExistingRecordsMf(true);
  };

  const handleItemsPerPage = (value: number) => {
    setPaginationUri(null);
    setItemsPerPage(value);
  };

  const handleTabChange = (currTab: Tab) => {
    setActiveTab((prev) => {
      if (prev !== currTab) {
        setPaginationUri(null);
        setItemsPerPage(10);
      }
      return currTab;
    });
  };

  const handleFromDateFilter = (date: Date | null) => {
    setPaginationUri(null);
    setIsUpdateExistingRecordsMf(false);
    fromDateHandler(date, activeTab);
  };

  const handleToDateFilter = (date: Date | null) => {
    setPaginationUri(null);
    setIsUpdateExistingRecordsMf(false);
    toDateHandler(date, activeTab);
  };

  return (
    <>
      <TransactionContext.Provider
        value={{
          recordsMF,
          activeTab,
          fromDateValue,
          toDateValue,
          searchInputValue,
          isDownloadEnable,
          datesErrorMsg,
          mutualFundTransactions,
          fdTransactions,
          isLoadingMFTransactions,
          isLoadingFDTransactions,
          handleFromDateFilter,
          handleToDateFilter,
          handleTabChange,
          handleSearchInput,
          handleItemsPerPage,
          handlePageChange,
        }}
      >
        {children}
      </TransactionContext.Provider>
    </>
  );
};
