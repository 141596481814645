import { ButtonHTMLAttributes } from 'react';
import './FolioBtn.scss';

interface Props {
  icon: JSX.Element;
  className?: string;
}

export const FolioBtn = ({
  icon,
  children,
  className = '',
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement> & Props) => {
  return (
    <button
      className={`FolioBtn bg-transparent border-0 ${className}`}
      {...props}
    >
      {icon}
      <div
        style={{
          paddingTop: 6,
        }}
      >
        {children}
      </div>
    </button>
  );
};
