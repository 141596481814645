import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  getKycDataNri,
  setFatcaDataNri,
  updateFatcaDataNri,
} from '../../api/kyc';
import { addNominee, updateNominee } from '../../api/nominee';
import { InputBox, RadioGroups, SelectBox } from '../../common/form';
import Toast from '../../common/toast/Toast';
import {
  nomineeNameRegex,
  otherRelationRegex,
  placeofbirthRegex,
} from '../../utility/constant';
import { getInvestorsStatus } from '../../utility/investorStatus';
import {
  AnnualIncomeListNri,
  TOKEN_KEY,
  checkAddressDetails,
  wealthSourceListNri
} from '../../utility/utility';
import NominationField from '../nomination-field';

const OnBoardingNriNreNro = ({
  fatcaData,
  kycData,
  history,
  onSubmit,
  nriUserAddress,
  nriAddress,
  stateName,
  pinCode,
  cityName,
  onFatcaSubmitted,
  errors,
  setErrors,
  handleErrorsBankNri,
}) => {
  const userId = useSelector((state) => state.authReducer.user_id);
  const dispatch = useDispatch();
  const [annualIncome, setAnnualIncome] = useState();
  const [placeOfBirth, setPlace] = useState();
  const [wealthSource, setWealthSource] = useState();
  const [birthCountry, setBirthCountry] = useState('India');
  const [placeOfBirthErr, setPlaceOfBirthErr] = useState();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [checked, setChecked] = useState(false);
  const [mobileDeclaration, setMobileDeclaration] = useState(false);
  const [emailDeclaration, setEmailDeclaration] = useState(false);
  const [declarationError, setDeclarationError] = useState();
  const [nomineeDetails, setNomineeDetails] = useState();
  const [nomineeErrors, setNomineeErrors] = useState([]);
  const [commonNomineeError, setCommonNomineeError] = useState();
  const [, setFatcaSubmited] = useState(false);
  const [nomineeSubmited, setNomineeSubmited] = useState(false);
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    setfatcaData(fatcaData);
    getUserKycDataNri();
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [fatcaData]);

  const getUserKycDataNri = () => {
    getKycDataNri(userId).then((res) => {
      if (res && res.data) {
        setAnnualIncome(res.data.fatca_detail.income_slab);
        setPlace(res.data.fatca_detail.birth_place);
        setWealthSource(res.data.fatca_detail.income_source);
        setBirthCountry(res.data.fatca_detail.birth_country);
      }
    });
  };

  const setfatcaData = (data) => {
    setPlace(data?.birth_place);
    setWealthSource(data?.income_source);
    if (data.mobile && data.email_declaration && data.mobile_declaration) {
      setChecked(true);
    }
    setEmailDeclaration(data.email_declaration ? 'self' : false);
    setMobileDeclaration(data.mobile_declaration ? 'self' : false);
    if (!data.mobile && data.email_declaration) {
      setChecked(true);
    }
  };
  const onBirthPlaceChange = (event) => {
    let inputValue = event.target.value || '',
      placeErr = '';
    if (!inputValue) {
      setPlace('');
    } else if (!placeofbirthRegex.test(inputValue)) {
      placeErr =
        'Please enter a Place of birth without spaces or characters like("-", "&", "@", etc)';
    } else {
      placeErr = undefined;
      setPlace(inputValue);
    }
    setPlaceOfBirthErr(placeErr);
  };

  let fatcaDetails = {
    income_source: wealthSource,
    income_slab: annualIncome,
    birth_place: placeOfBirth,
    birth_country: birthCountry,
  };
  const onRadioChange = (event) => {
    const { value } = event.target;
    setAnnualIncome(value);
  };
  const saveFormData = () => {
    let uD = {};
    if (validateNomineeDetails()) {
      if (fatcaData.investor_type) {
        uD = {
          ...uD,
          investor_type: fatcaData.investor_type,
        };
      }
      if (fatcaData.first_name) {
        uD = {
          ...uD,
          first_name: fatcaData.first_name,
        };
      }
      if (fatcaData.last_name) {
        uD = {
          ...uD,
          last_name: fatcaData.last_name,
        };
      }
      if (fatcaData.pan) {
        uD = {
          ...uD,
          pan: fatcaData.pan,
        };
      }
      if (nriUserAddress.address) {
        uD = {
          ...uD,
          contact_address: {
            ...uD.contact_address,
            address: nriUserAddress.address,
          },
        };
      }
      if (nriUserAddress.city) {
        uD = {
          ...uD,
          contact_address: {
            ...uD.contact_address,
            city: nriUserAddress.city,
          },
        };
      }
      if (nriUserAddress.state) {
        uD = {
          ...uD,
          contact_address: {
            ...uD.contact_address,
            state: nriUserAddress.state,
          },
        };
      }
      if (nriUserAddress.pincode) {
        uD = {
          ...uD,
          contact_address: {
            ...uD.contact_address,
            pincode: nriUserAddress.pincode,
          },
        };
      }
      if (fatcaDetails.income_source) {
        uD = {
          ...uD,
          fatca_detail: {
            ...uD.fatca_detail,
            income_source: wealthSource,
          },
        };
      }
      if (fatcaDetails.income_slab) {
        uD = {
          ...uD,
          fatca_detail: {
            ...uD.fatca_detail,
            income_slab: annualIncome,
          },
        };
      }
      if (fatcaDetails.birth_place) {
        uD = {
          ...uD,
          fatca_detail: {
            ...uD.fatca_detail,
            birth_place: fatcaDetails.birth_place,
          },
        };
      }
      if (fatcaDetails.birth_country) {
        uD = {
          ...uD,
          fatca_detail: {
            ...uD.fatca_detail,
            birth_country: fatcaDetails.birth_country,
          },
        };
      }
      if (mobileDeclaration) {
        uD = {
          ...uD,
          mobile_declaration: 'self',
        };
      }
      if (emailDeclaration) {
        uD = {
          ...uD,
          email_declaration: 'self',
        };
      }
      uD = {
        ...uD,
        is_draft: true,
      };

      setFatcaDataNri(userId, uD).then((res) => {
        Toast.success(res.data.message);
        history.push('/home');
        onSubmit(true);
        saveNomineeDetails();
      });
      localStorage.setItem('saveAsDraft', true);
      localStorage.setItem('emailDeclaration', emailDeclaration ? true : false);
    }
  };
  const validateData = (isSubmit) => {
    let errors = [];
    if (isSubmit && (!wealthSource || wealthSource.length === 0)) {
      errors['wealthSource'] = 'Wealth Source is required.';
    }
    if (isSubmit && (!birthCountry || birthCountry.length === 0)) {
      errors['birthCountry'] = 'Country of Birth is required.';
    }
    if (pinCode) {
      if (pinCode.length !== 6) {
        errors['pinCode'] = 'Pincode must be exactly 6 digits long.';
      }
    }
    if (isSubmit && (!placeOfBirth || placeOfBirth.length === 0)) {
      errors['placeOfBirth'] = 'Place of Birth is required';
      setPlaceOfBirthErr('Place of Birth is required.');
    }
    setErrors(errors);
    let status = handleErrorsBankNri(!isSubmit);
    if (isSubmit && ((errors && Object.keys(errors).length > 0) || !status)) {
      return false;
    }
    return true;
  };

  const submitFormData = () => {
    let errStatus = validateData(true);
    let isNomineeVlaid = validateNomineeDetails(true);
    if (errStatus && isNomineeVlaid) {
      let uD = {
        investor_type: fatcaData.investor_type,
        first_name: fatcaData.first_name,
        last_name: fatcaData.last_name,
        pan: fatcaData.pan,
        fatca_detail: fatcaDetails,
        mobile_declaration: mobileDeclaration ? 'self' : null,
        email_declaration: emailDeclaration ? 'self' : null,
        is_draft: false,
      };
      if (Object.keys(checkAddressDetails(nriUserAddress)).length > 0) {
        uD['contact_address'] = checkAddressDetails(nriUserAddress);
      }
      if (emailDeclaration) {
        updateFatcaDataNri(userId, uD).then((res) => {
          Toast.success(res.data.message);
          onFatcaSubmitted();
          setFatcaSubmited(true);
          onSubmit();
          submitNomineeDetails();
        });
        localStorage.setItem('saveAsDraft', false);
        localStorage.setItem('emailDeclaration', false);
      } else {
        setDeclarationError(
          `Please confirm your Email ${
            fatcaData.mobile_number ? 'and Mobile ' : ''
          }Declaration.`
        );
      }
    }
  };

  const checkboxHandleChange = (event) => {
    const { checked } = event.target;
    setChecked(checked);
    if (checked) {
      setEmailDeclaration(true);
      if (fatcaData.mobile) {
        setMobileDeclaration(true);
      }
      setDeclarationError(undefined);
    } else {
      setEmailDeclaration(false);
      setMobileDeclaration(false);
    }
  };

  const handleWealthSourceChange = (value) => {
    setWealthSource(value);
    let error = errors;
    if (value && value.length) {
      error['wealthSource'] = '';
      setErrors(error);
    }
  };
  const handleBirthCountryChange = (value) => {
    setBirthCountry(value);
    let error = errors;
    if (value && value.length > 0) {
      error['birthCountry'] = '';
      setErrors(error);
    }
  };

  const validateNomineeDetails = (isSubmit) => {
    let isValid = true;
    nomineeDetails.forEach((item, index) => {
      nomineeErrors[index] = {};
      if (item.name) {
        let name = item.name?.trim();
        if (!item.relation) {
          isValid = false;
          nomineeErrors[index].relation = 'Relation is required';
        }
        if (!item.share) {
          isValid = false;
          nomineeErrors[index].share = 'Share is required';
        }
        if (item.share && parseInt(item.share) === 0) {
          isValid = false;
          nomineeErrors[index].share = 'Share must be valid Number';
        }
        if (!name.match(nomineeNameRegex)) {
          isValid = false;
          nomineeErrors[index].name =
            'Please ensure that the nominee name consists only of alphabets and spaces.';
        }
        if (name?.length !== 0 && (name?.length > 40 || name?.length < 3)) {
          isValid = false;
          nomineeErrors[index].name =
            'Nominee name not allowed more than 40 character and less than 3 character';
        }
        if (name?.length === 0 && isSubmit) {
          isValid = false;
          nomineeErrors[index].name = 'Nominee name is required';
        }
        if (item.others && !item.otherRelation) {
          isValid = false;
          nomineeErrors[index].otherRelation =
            'Please specify the relationship';
        }
        if (
          item.otherRelation &&
          !item.otherRelation.match(otherRelationRegex)
        ) {
          isValid = false;
          nomineeErrors[index].otherRelation =
            'Please ensure that the relationship consists only of alphabets and spaces.';
        }
      } else {
        if (isSubmit) {
          isValid = false;
          nomineeErrors[index].name = 'Nominee name is required';
        }
        if (item.relation || item.share) {
          isValid = false;
          nomineeErrors[index].name = 'Nominee name is required';
          if (!item.relation) {
            nomineeErrors[index].relation = 'Relation is required';
          }
          if (!item.share) {
            nomineeErrors[index].share = 'Share is required';
          }
          if (item.others && !item.otherRelation) {
            isValid = false;
            nomineeErrors[index].otherRelation =
              'Please specify the relationship';
          }
          if (
            item.otherRelation &&
            !item.otherRelation.match(otherRelationRegex)
          ) {
            isValid = false;
            nomineeErrors[index].otherRelation =
              'Please ensure that the relationship consists only of alphabets and spaces.';
          }
        }
      }
    });
    setNomineeErrors([...nomineeErrors]);
    if (getNomineeTotal() > 100) {
      setCommonNomineeError(
        'Total of share for all nominee should be not be greater than 100%'
      );
      isValid = false;
    }
    if (isSubmit && getNomineeTotal() < 100) {
      setCommonNomineeError('Total of share for all nominee should be 100%');
      isValid = false;
    }
    return isValid;
  };

  const handleNomineeDetailChange = (data) => {
    setNomineeDetails([...data]);
    nomineeErrors.forEach((err, index) => {
      if (err && err.relation) {
        if (data[index].relation) {
          err.relation = undefined;
        }
      }
      if (err && err.share) {
        if (data[index].share) {
          err.share = undefined;
        }
      }
      if (err && err.name) {
        if (data[index]?.name?.trim().match(nomineeNameRegex)) {
          err.name = undefined;
        }
      }
      if (err && err.otherRelation) {
        if (data[index]?.otherRelation?.trim().match(otherRelationRegex)) {
          err.otherRelation = undefined;
        }
      }
    });
    setCommonNomineeError(undefined);
    setNomineeErrors([...nomineeErrors]);
  };

  const getNomineeTotal = () => {
    let shareCount = 0;
    nomineeDetails.forEach((item) => {
      shareCount = shareCount + parseInt(item.share);
    });
    return shareCount;
  };

  const submitNomineeDetails = () => {
    let shareCount = 0;
    nomineeDetails.forEach((item) => {
      shareCount = shareCount + item.share;
    });
    if (shareCount < 100) {
      setCommonNomineeError('Total of share for all nominee should be 100%');
    } else {
      saveNomineeDetails();
    }
  };

  const saveNomineeDetails = () => {
    let shareCount = 0;
    nomineeDetails.forEach((item) => {
      shareCount = shareCount + parseInt(item.share);
    });
    if (shareCount > 100) {
      setCommonNomineeError(
        'Total of share for all nominee should be not be greater than 100%'
      );
    } else {
      let addNomineeData = [];
      let updateNomineeData = [];
      let flag = false;
      for (let i in nomineeDetails) {
        if (nomineeDetails[i].id) {
          flag = true;
        } else {
          let data = {};
          if (!flag && nomineeDetails[i].name) {
            data = {
              name: nomineeDetails[i].name.trim(),
              relation: nomineeDetails[i].relation,
              share: parseInt(nomineeDetails[i].share),
              minor: false,
            };
            if (nomineeDetails[i].others) {
              data['other_relation'] = nomineeDetails[i].otherRelation.trim();
            }
            addNomineeData.push(data);
          }
        }
      }
      if (flag) {
        updateNomineeData = nomineeDetails.map((item, i) => {
          const data = {
            name: item.name.trim(),
            relation: item.relation,
            share: parseInt(item.share),
            minor: false,
          };
          if (item.others) {
            data['other_relation'] = nomineeDetails[i].otherRelation.trim();
          }
          return data;
        });
      }
      console.log('updateNomineeData', updateNomineeData);
      if (updateNomineeData.length > 0) {
        updateNomineeDetails(updateNomineeData);
      } else if (addNomineeData.length > 0) {
        addNomineeDetails(addNomineeData);
      }
    }
  };
  const updateNomineeDetails = (data) => {
    if (data) {
      let request = {
        nominees: data,
      };
      updateNominee(userId, request, localStorage.getItem(TOKEN_KEY)).then(
        (response) => {
          setNomineeSubmited(true);
          getInvestorsStatus();
        }
      );
    } else {
      setNomineeSubmited(true);
      getInvestorsStatus();
    }
  };

  const addNomineeDetails = (data) => {
    let request = {
      nominees: data,
    };
    addNominee(userId, request, localStorage.getItem(TOKEN_KEY)).then(
      (response) => {
        setNomineeSubmited(true);
        getInvestorsStatus(userId, dispatch);
      }
    );
  };

  return (
    <section className='col-sm-12'>
      <h6 className='font-weight-bold mt-4'>Details for FATCA</h6>
      {windowDimensions.width > 480 ? (
        <>
          <div className='border p-4 rounded w-100 my-3'>
            <SelectBox
              title='Wealth Source'
              value={wealthSource}
              id='WealthSource'
              classes={{ root: 'col-sm-3 px-0 ' }}
              onUpdate={handleWealthSourceChange}
              options={wealthSourceListNri}
              placeholder='Select wealth source'
              err={errors && errors['wealthSource']}
            />
          </div>
          <div className='border p-4 rounded w-100 my-3'>
            <RadioGroups
              title='Annual Income'
              id='annualIncome'
              radioList={AnnualIncomeListNri}
              selectedVal={annualIncome}
              onChange={onRadioChange}
            />
          </div>
          <div className='border p-4 rounded w-100 my-3'>
            <InputBox
              id='PlaceofBirth'
              className='col-sm-5'
              labelClass='opacity-75 h6'
              onChange={onBirthPlaceChange}
              title='Place of Birth'
              value={placeOfBirth}
              type='text'
              maxLength={40}
              err={placeOfBirthErr}
            />
          </div>
          <div className='border p-4 rounded w-100 my-3'>
            <NominationField
              nomineeDetailChange={handleNomineeDetailChange}
              errors={nomineeErrors}
              commonError={commonNomineeError}
              nomineeSubmited={nomineeSubmited}
            />
          </div>
          <div className='border p-4 rounded w-100 my-3'>
            <SelectBox
              title='Country of Birth'
              value={birthCountry}
              id='countryBirth'
              classes={{ root: 'col-sm-3 px-0' }}
              onUpdate={handleBirthCountryChange}
              options={[{ label: 'India', value: 'India' }]}
              placeholder='Select country of birth'
              err={errors && errors['birthCountry']}
            />
          </div>
          <div className='border p-4 rounded w-100 my-3'>
            <Form.Check>
              <Form.Check.Input
                id='declaration'
                type='checkbox'
                checked={checked}
                onChange={checkboxHandleChange}
                style={{ cursor: 'pointer' }}
              />
              <Form.Check.Label
                for='declaration'
                onChange={checkboxHandleChange}
                style={{ cursor: 'pointer' }}
              >
                {fatcaData.mobile
                  ? 'I confirm that mobile number ' +
                    fatcaData.mobile +
                    ' and email ' +
                    fatcaData.email +
                    ' belong to ' +
                    kycData.pan_name
                  : 'I confirm that the email id ' +
                    fatcaData.email +
                    ' belong to ' +
                    kycData.pan_name}
              </Form.Check.Label>
            </Form.Check>
            {declarationError && (
              <div className={'invalid-feedback d-block mt-2'}>
                {declarationError}
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className='pb-2 rounded w-100 my-3'>
            <SelectBox
              title='Wealth Source'
              value={wealthSource}
              id='WealthSource'
              classes={{ root: 'col-sm-3 px-0 ' }}
              onUpdate={handleWealthSourceChange}
              options={wealthSourceListNri}
              placeholder='Select wealth source'
              err={errors && errors['wealthSource']}
            />
          </div>
          <div className=' rounded w-100 my-3'>
            <RadioGroups
              title='Annual Income'
              id='annualIncome'
              radioList={AnnualIncomeListNri}
              selectedVal={annualIncome}
              onChange={onRadioChange}
            />
          </div>
          <div className='rounded w-100 my-3'>
            <InputBox
              id='PlaceofBirth'
              className='col-sm-5'
              labelClass='opacity-75 h6'
              onChange={onBirthPlaceChange}
              title='Place of Birth'
              value={placeOfBirth}
              type='text'
              maxLength={40}
              err={placeOfBirthErr}
            />
          </div>
          <div className='py-2 rounded w-100'>
            <NominationField
              nomineeDetailChange={handleNomineeDetailChange}
              errors={nomineeErrors}
              commonError={commonNomineeError}
              nomineeSubmited={nomineeSubmited}
            />
          </div>
          <div className='py-2 rounded w-100 '>
            <SelectBox
              title='Country of Birth'
              value={birthCountry}
              id='countryBirth'
              classes={{ root: 'col-sm-3 px-0' }}
              onUpdate={handleBirthCountryChange}
              options={[{ label: 'India', value: 'India' }]}
              placeholder='Select country of birth'
              err={errors && errors['birthCountry']}
            />
          </div>
          <div className='py-2 rounded w-100 '>
            <Form.Check>
              <Form.Check.Input
                id='declaration'
                type='checkbox'
                checked={checked}
                onChange={checkboxHandleChange}
                style={{ cursor: 'pointer' }}
              />
              <Form.Check.Label
                for='declaration'
                onChange={checkboxHandleChange}
                style={{ cursor: 'pointer' }}
              >
                {fatcaData.mobile
                  ? 'I confirm that mobile number ' +
                    fatcaData.mobile +
                    ' and email ' +
                    fatcaData.email +
                    ' belong to ' +
                    kycData.pan_name
                  : 'I confirm that the email id ' +
                    fatcaData.email +
                    ' belong to ' +
                    kycData.pan_name}
              </Form.Check.Label>
            </Form.Check>
            {declarationError && (
              <div className={'invalid-feedback d-block mt-2'}>
                {declarationError}
              </div>
            )}
          </div>
        </>
      )}

      <div className=' btn-container text-center my-4  col-sm-12'>
        <button
          type='button'
          className='btn btn-outline-primary m-2'
          onClick={saveFormData}
        >
          Save As Draft
        </button>
        <button
          type='button'
          className='btn btn-primary m-2 btn-width'
          onClick={submitFormData}
        >
          Submit
        </button>
      </div>
    </section>
  );
};
export default OnBoardingNriNreNro;
